import { partnerRequest, publicRequest } from '../services/request.service'
import emptyCache from '../services/emptyCache';

const API_URL = window.env ? window.env.API_SERVER : '';
const NO_CONTENT = 2;
const REQUEST_FAILURE = 1;
const REQUEST_SUCCESS = 0;

const getReferencesSuccess = "GET_REFERENCES_SUCCESS";
const getReferencesFailure = "GET_REFERENCES_FAILURE";

const getCalendarListSuccess = "GET_CALENDAR_LIST_SUCCESS";
const getCalendarListFailure = "GET_CALENDAR_LIST_FAILURE";

const createCalendarSuccess = "CREATE_CALENDAR_SUCCESS";
const createCalendarFailure = "CREATE_CALENDAR_FAILURE";

const saveCalendarSuccess = "SAVE_CALENDAR_SUCCESS";
const saveCalendarFailure = "SAVE_CALENDAR_FAILURE";

const saveCalendarFileSuccess = "SAVE_CALENDAR_FILE_SUCCESS";
const saveCalendarFileFailure = "SAVE_CALENDAR_FILE_FAILURE";

const getCalendarSuccess = "GET_CALENDAR_SUCCESS";
const getCalendarFailure = "GET_CALENDAR_FAILURE"

const getDocketListSuccess = "GET_DOCKET_LIST_SUCCESS";
const getDocketListFailure = "GET_DOCKET_LIST_FAILURE";

const createDocketSuccess = "CREATE_DOCKET_SUCCESS";
const createDocketFailure = "CREATE_DOCKET_FAILURE";

const saveDocketSuccess = "SAVE_DOCKET_SUCCESS";
const saveDocketFailure = "SAVE_DOCKET_FAILURE";

const docketByIdRequest = "GET_DOCKET_BY_ID_REQUEST";
const docketByIdSuccess = "GET_DOCKET_BY_ID_SUCCESS";
const docketByIdFailure = "GET_DOCKET_BY_ID_FAILURE";

const patronListDocketByIdRequest = "GET_PATRON_LIST_DOCKET_BY_ID_REQUEST";
const patronListDocketByIdSuccess = "GET_PATRON_LIST_DOCKET_BY_ID_SUCCESS";
const patronListDocketByIdFailure = "GET_PATRON_LIST_DOCKET_BY_ID_FAILURE";

const getCalendarCategoriesReferencesSuccess = "GET_CALENDAR_CATEGORIES_REFERENCES_SUCCESS";
const getCalendarCategoriesReferencesFailure = "GET_CALENDAR_CATEGORIES_REFERENCES_FAILURE";

const getCalendarAssignmentsSuccess = "GET_CALENDAR_MAPPINGS_SUCCESS";
const getCalendarAssignmentsFailure = "GET_CALENDAR_MAPPINGS_FAILURE";

const getAdminCalendarAssignmentsSuccess = "GET_ADMIN_CALENDAR_MAPPINGS_SUCCESS";
const getAdminCalendarAssignmentsFailure = "GET_ADMIN_CALENDAR_MAPPINGS_FAILURE";

const saveCalendarAssignmentSuccess = "SAVE_CALENDAR_MAPPING_SUCCESS";
const saveCalendarAssignmentFailure = "SAVE_CALENDAR_MAPPING_FAILURE";

const saveCalendarCurrentItemSuccess = "SAVE_CALENDAR_CURRENT_ITEM_SUCCESS";
const saveCalendarCurrentItemFailure = "SAVE_CALENDAR_CURRENT_ITEM_FAILURE";

const replacePdfRequest = "REPLACE_PDF_REQUEST";

const sendDocketHtmlRequest = "SEND_HTML_REQUEST";

const sendCalendarHtmlRequest = "SEND_HTML_REQUEST";

const initialState = {
    references: [],
    getReferencesError: '',
    docketList: [],
    getDocketListError: '',
    calendarList: [],
    calendarListError: '',
    calendarCreate: '',
    calendarCreateError: '',
    calendarSave: '',
    calendarSaveError: '',
    calendarFileSave: '',
    calendarFileSaveError: '',
    calendarGet: '',
    calendarGetError: '',
    calendarCategoriesReferences: [],
    calendarCategoriesReferencesError: '',
    docketByIdData: '',
    docketSave: '',
    docketSaveError: '',
    replacePdfResponse: '',
    replacePdfError: '',
    patronListDocketByIdData: '',
    sendDocketHtmlError: '',
    sendCalendarHtmlError: '',
    sendDocketHtmlResponse: '',
    sendCalendarHtmlResponse: '',
    calendarAssignments: [],
    getCalendarAssignmentsError: '',
    adminCalendarAssignments: [],
    getAdminCalendarAssignmentsError: '',
    calendarAssignmentSave: [],
    saveCalendarAssignmentsError: ''
};

export const calendarActionCreators = {

    // GET CALENDAR BY ID FOR DOCKET MANAGEMENT (W/ PARTNER AUTH)
    requestDocketById: () => ({
        type: docketByIdRequest,
        isFetching: true
    }),

    receiveDocketById: responseObj => ({
        type: docketByIdSuccess,
        isFetching: false,
        docketByIdData: responseObj
    }),

    docketByIdError: message => ({
        type: docketByIdFailure,
        isFetching: false,
        message
    }),

    getDocketById: (params, partner) => {
        return dispatch => {
            dispatch(calendarActionCreators.requestDocketById());
            let headers = {
                'content-type': "application/json; charset=utf-8"
            }
            let request = '';
            if (partner) {
                request = partnerRequest((API_URL || process.env.REACT_APP_CALENDAR_API_URL) + '/Calendar/api/GetPartnerDocketsByIdAsync', 'GET', headers, '', params)
            } else {
                request = publicRequest((API_URL || process.env.REACT_APP_CALENDAR_API_URL) + '/Calendar/api/GetDocketsByIdAsync', 'GET', headers, '', params)
            }
            return request
                .then(result => {
                    if (result[0] === REQUEST_FAILURE) {
                        dispatch(calendarActionCreators.docketByIdError(result[1]))
                        return Promise.reject(result[1])
                    } else if (result[0] === REQUEST_SUCCESS) {
                        // Dispatch the success action
                        dispatch(calendarActionCreators.receiveDocketById(result[1].Dockets[0]))
                        return result[1].Dockets[0];
                    } else if (result[0] === NO_CONTENT) {
                        dispatch(calendarActionCreators.docketByIdError('No Content'))
                        return Promise.reject('No Content')
                    } else if (result.name === 'AbortError') {
                        return Promise.reject("Aborted");
                    }
                }).catch(err => Promise.reject(err));
        }
    },

    requestPatronListDocketById: () => ({
        type: patronListDocketByIdRequest,
        isFetching: true
    }),

    receivePatronListDocketById: responseObj => ({
        type: patronListDocketByIdSuccess,
        isFetching: false,
        patronListDocketByIdData: responseObj
    }),

    patronListDocketByIdError: message => ({
        type: patronListDocketByIdFailure,
        isFetching: false,
        message
    }),

    getPatronListDocketById: (params) => {
        return dispatch => {
            dispatch(calendarActionCreators.requestPatronListDocketById());
            let headers = {
                'content-type': "application/json; charset=utf-8"
            }

            return partnerRequest((API_URL || process.env.REACT_APP_CALENDAR_API_URL) + '/Calendar/api/GetCalendarPatronListAsync', 'GET', headers, '', params)
                .then(result => {
                    if (result[0] === REQUEST_FAILURE) {
                        dispatch(calendarActionCreators.patronListDocketByIdError(result[1]))
                        return Promise.reject(result[1])
                    } else if (result[0] === REQUEST_SUCCESS) {
                        // Dispatch the success action
                        dispatch(calendarActionCreators.receivePatronListDocketById(result[1].ListItems[0]))
                        return result.ListItems;
                    } else if (result[0] === NO_CONTENT) {
                        dispatch(calendarActionCreators.patronListDocketByIdError('No Content'))
                        return Promise.reject('No Content')
                    } else if (result.name === 'AbortError') {
                        return Promise.reject("Aborted");
                    }
                }).catch(err => Promise.reject(err));
        }
    },

    receiveReferences: (responseObj) => ({
        type: getReferencesSuccess,
        references: responseObj
    }),

    getReferencesError: error => ({
        type: getReferencesFailure,
        getReferencesError: error
    }),

    getReferences: (params) => {
        return dispatch => {
            let headers = {
                'content-type': "application/json; charset=utf-8"
            }
            return publicRequest((API_URL || process.env.REACT_APP_CALENDAR_API_URL) + '/Calendar/api/GetCalendarActionsReferenceAsync', 'GET', headers, '', params)
                .then(result => {
                    if (result[0] === REQUEST_FAILURE) {
                        dispatch(calendarActionCreators.getReferencesError(result[1]))
                        return Promise.reject(result[1])
                    } else if (result[0] === REQUEST_SUCCESS) {
                        // Dispatch the success action
                        dispatch(calendarActionCreators.receiveReferences(result[1].CalendarActions))
                    } else if (result[0] === NO_CONTENT) {
                        dispatch(calendarActionCreators.receiveReferences([]))
                    } else if (result.name === 'AbortError') {
                        return Promise.reject("Aborted");
                    }
                }).catch(err => Promise.reject(err))
        }
    },

    receiveDocketList: (responseObj) => ({
        type: getDocketListSuccess,
        docketList: responseObj
    }),

    getDocketListError: error => ({
        type: getDocketListFailure,
        getDocketListError: error
    }),

    getDocketList: (params, partner) => {
        return dispatch => {
            let headers = {
                'content-type': "application/json; charset=utf-8"
            }
            let request = '';
            if (partner) {
                request = partnerRequest((API_URL || process.env.REACT_APP_CALENDAR_API_URL) + '/Calendar/api/GetDocketPartnerListAsync', 'GET', headers, '', params)
            } else {
                request = publicRequest((API_URL || process.env.REACT_APP_CALENDAR_API_URL) + '/Calendar/api/GetDocketListAsync', 'GET', headers, '', params)
            }
            return request
                .then(result => {
                    if (result[0] === REQUEST_FAILURE) {
                        dispatch(calendarActionCreators.getDocketListError(result[1]))
                        return Promise.reject(result[1])
                    } else if (result[0] === REQUEST_SUCCESS) {
                        dispatch(calendarActionCreators.receiveDocketList(result[1].Dockets))
                    } else if (result[0] === NO_CONTENT) {
                        dispatch(calendarActionCreators.receiveDocketList([]))
                    } else if (result.name === 'AbortError') {
                        return Promise.reject("Aborted");
                    }
                }).catch(err => Promise.reject(err))
        }
    },

    receiveDocketCreate: (responseObj) => ({
        type: createDocketSuccess,
        docketCreate: responseObj
    }),

    createDocketError: error => ({
        type: createDocketFailure,
        createDocketError: error
    }),

    createDocket: (body) => {
        return dispatch => {
            let headers = {
                'content-type': "application/json; charset=utf-8"
            }
            return partnerRequest((API_URL || process.env.REACT_APP_CALENDAR_API_URL) + '/Calendar/api/CreateDocketsAsync', 'POST', headers, body, '')
                .then(result => {
                    if (result[0] === REQUEST_FAILURE) {
                        dispatch(calendarActionCreators.createDocketError(result[1]))
                        return Promise.reject(result[1])
                    } else if (result[0] === REQUEST_SUCCESS) {
                        // Dispatch the success action
                        dispatch(calendarActionCreators.receiveDocketCreate(result[1]))
                    } else if (result[0] === NO_CONTENT) {
                        dispatch(calendarActionCreators.createDocketError('No Content'))
                        return Promise.reject('No Content')
                    } else if (result.name === 'AbortError') {
                        return Promise.reject("Aborted");
                    }
                }).catch(err => Promise.reject(err))
        }
    },

    receiveDocketSave: (responseObj) => ({
        type: saveDocketSuccess,
        docketSave: responseObj
    }),

    docketSaveError: error => ({
        type: saveDocketFailure,
        docketSaveError: error
    }),

    saveDocket: (body) => {
        return dispatch => {
            let headers = {
                'content-type': "application/json; charset=utf-8"
            }
            return partnerRequest((API_URL || process.env.REACT_APP_CALENDAR_API_URL) + '/Calendar/api/SaveDocketsAsync', 'POST', headers, body, '')
                .then(result => {
                    if (result[0] === REQUEST_FAILURE) {
                        dispatch(calendarActionCreators.docketSaveError(result[1]))
                        return Promise.reject(result[1])
                    } else if (result[0] === REQUEST_SUCCESS) {
                        //If the response is an ID then the docket was deleted
                        if (parseInt(result[1])) {
                            dispatch(calendarActionCreators.receiveDocketSave(result[1]))
                        } else {
                            // Dispatch the success action                
                            dispatch(calendarActionCreators.receiveDocketSave(result[1].Dockets[0] || result[1].HouseAgendas[0]))
                        }
                    } else if (result[0] === NO_CONTENT) {
                        dispatch(calendarActionCreators.docketSaveError('No Content'))
                        return Promise.reject('No Content')
                    } else if (result.name === 'AbortError') {
                        return Promise.reject("Aborted");
                    }
                }).catch(err => Promise.reject(err))
        }
    },

    receiveCalendarCategoriesReferences: (responseObj, includeChairmansReportCategories) => ({
        type: getCalendarCategoriesReferencesSuccess,
        calendarCategoriesReferences: includeChairmansReportCategories ? responseObj.CalendarCategoryTypes : responseObj.CalendarCategoryTypes.filter(cc => cc.CategoryType !== 'Chairmans Report')
    }),

    getCalendarCategoriesReferencesError: error => ({
        type: getCalendarCategoriesReferencesFailure,
        getCalendarCategoriesReferencesError: error
    }),

    getCalendarCategoriesReferences: (params = '', includeChairmansReportCategories = false) => {
        return dispatch => {
            let headers = {
                'content-type': "application/json; charset=utf-8"
            }
            return publicRequest((API_URL || process.env.REACT_APP_CALENDAR_API_URL) + '/Calendar/api/GetCalendarCategoryTypesReferenceAsync', 'GET', headers, '', params)
                .then(result => {
                    if (result[0] === REQUEST_FAILURE) {
                        dispatch(calendarActionCreators.getCalendarCategoriesReferencesError(result[1]))
                        return Promise.reject(result[1])
                    } else if (result[0] === REQUEST_SUCCESS) {
                        // Dispatch the success action
                        dispatch(calendarActionCreators.receiveCalendarCategoriesReferences(result[1], includeChairmansReportCategories))
                    } else if (result[0] === NO_CONTENT) {
                        dispatch(calendarActionCreators.getCalendarCategoriesReferencesError('No Content'))
                        return Promise.reject('No Content')
                    } else if (result.name === 'AbortError') {
                        return Promise.reject("Aborted");
                    }
                }).catch(err => Promise.reject(err))
        }
    },

    receiveCalendarAssignments: (responseObj) => ({
        type: getCalendarAssignmentsSuccess,
        calendarAssignments: responseObj.CalendarAssignments
    }),

    getCalendarAssignmentsError: error => ({
        type: getCalendarAssignmentsFailure,
        getCalendarAssignmentsError: error
    }),

    getCalendarAssignments: () => {
        return dispatch => {
            let headers = {
                'content-type': "application/json; charset=utf-8"
            }
            return partnerRequest((API_URL || process.env.REACT_APP_CALENDAR_API_URL) + '/Calendar/api/GetCalendarAssignmentAsync', 'GET', headers, '', '')
                .then(result => {
                    if (result[0] === REQUEST_FAILURE) {
                        dispatch(calendarActionCreators.getCalendarAssignmentsError(result[1]))
                        return Promise.reject(result[1])
                    } else if (result[0] === REQUEST_SUCCESS) {
                        // Dispatch the success action
                        dispatch(calendarActionCreators.receiveCalendarAssignments(result[1]))
                    } else if (result[0] === NO_CONTENT) {
                        dispatch(calendarActionCreators.getCalendarAssignmentsError('No Content'))
                        return Promise.reject('No Content')
                    } else if (result.name === 'AbortError') {
                        return Promise.reject("Aborted");
                    }
                }).catch(err => Promise.reject(err))
        }
    },

    receiveAdminCalendarAssignments: (responseObj) => ({
        type: getAdminCalendarAssignmentsSuccess,
        adminCalendarAssignments: responseObj.CalendarAssignments
    }),

    getAdminCalendarAssignmentsError: error => ({
        type: getAdminCalendarAssignmentsFailure,
        getAdminCalendarAssignmentsError: error
    }),

    getAdminCalendarAssignments: () => {
        return dispatch => {
            let headers = {
                'content-type': "application/json; charset=utf-8"
            }
            return partnerRequest((API_URL || process.env.REACT_APP_CALENDAR_API_URL) + '/Calendar/api/GetAdminCalendarAssignmentAsync', 'GET', headers, '', '')
                .then(result => {
                    if (result[0] === REQUEST_FAILURE) {
                        dispatch(calendarActionCreators.getAdminCalendarAssignmentsError(result[1]))
                        return Promise.reject(result[1])
                    } else if (result[0] === REQUEST_SUCCESS) {
                        // Dispatch the success action
                        dispatch(calendarActionCreators.receiveAdminCalendarAssignments(result[1]))
                    } else if (result[0] === NO_CONTENT) {
                        dispatch(calendarActionCreators.getAdminCalendarAssignmentsError('No Content'))
                        return Promise.reject('No Content')
                    } else if (result.name === 'AbortError') {
                        return Promise.reject("Aborted");
                    }
                }).catch(err => Promise.reject(err))
        }
    },

    receiveCalendarAssignmentSave: (responseObj) => ({
        type: saveCalendarAssignmentSuccess,
        calendarAssignmentSave: responseObj
    }),

    saveCalendarAssignmentError: error => ({
        type: saveCalendarAssignmentFailure,
        saveCalendarAssignmentsError: error
    }),

    saveCalendarAssignment: (body) => {
        return dispatch => {
            let headers = {
                'content-type': "application/json; charset=utf-8"
            }
            return partnerRequest((API_URL || process.env.REACT_APP_CALENDAR_API_URL) + '/Calendar/api/SaveCalendarAssignmentAsync', 'POST', headers, body, '')
                .then(result => {
                    if (result[0] === REQUEST_FAILURE) {
                        dispatch(calendarActionCreators.saveCalendarAssignmentError(result[1]))
                        return Promise.reject(result[1])
                    } else if (result[0] === REQUEST_SUCCESS) {
                        // Dispatch the success action
                        dispatch(calendarActionCreators.receiveCalendarAssignmentSave(JSON.parse(result[1].Message)))
                    } else if (result[0] === NO_CONTENT) {
                        dispatch(calendarActionCreators.saveCalendarAssignmentError('No Content'))
                        return Promise.reject('No Content')
                    } else if (result.name === 'AbortError') {
                        return Promise.reject("Aborted");
                    }
                }).catch(err => Promise.reject(err))
        }
    },

    receiveCalendarList: (responseObj) => ({
        type: getCalendarListSuccess,
        calendarList: responseObj,

    }),

    calendarListError: error => ({
        type: getCalendarListFailure,
        calendarListError: error
    }),

    getCalendarList: (params, partner) => {
        return dispatch => {
            let headers = {
                'content-type': "application/json; charset=utf-8"
            }
            let request = '';
            if (partner) {
                request = partnerRequest((API_URL || process.env.REACT_APP_CALENDAR_API_URL) + '/Calendar/api/GetCalendarPartnerListAsync', 'GET', headers, '', params);
            } else {
                request = publicRequest((API_URL || process.env.REACT_APP_CALENDAR_API_URL) + '/Calendar/api/GetCalendarListAsync', 'GET', headers, '', params);
            }
            return request
                .then(result => {
                    if (result[0] === REQUEST_FAILURE) {
                        dispatch(calendarActionCreators.calendarListError(result[1]))
                        return Promise.reject(result[1])
                    } else if (result[0] === REQUEST_SUCCESS) {
                        // Dispatch the success action
                        dispatch(calendarActionCreators.receiveCalendarList(result[1].Calendars))
                    } else if (result[0] === NO_CONTENT) {
                        dispatch(calendarActionCreators.receiveCalendarList([]))
                    } else if (result.name === 'AbortError') {
                        return Promise.reject("Aborted");
                    }
                }).catch(err => Promise.reject(err))
        }
    },

    receiveCalendarCreate: (responseObj) => ({
        type: createCalendarSuccess,
        calendarCreate: responseObj,
    }),

    createCalendarError: error => ({
        type: createCalendarFailure,
        calendarCreateError: error
    }),

    createCalendar: (body) => {
        return dispatch => {
            let headers = {
                'content-type': "application/json; charset=utf-8"
            }
            return partnerRequest((API_URL || process.env.REACT_APP_CALENDAR_API_URL) + '/Calendar/api/CreateCalendarAsync', 'POST', headers, body, '')
                .then(result => {
                    if (result[0] === REQUEST_FAILURE) {
                        dispatch(calendarActionCreators.createCalendarError(result[1]))
                        return Promise.reject(result[1])
                    } else if (result[0] === REQUEST_SUCCESS) {
                        const createCalendarResponse = JSON.parse(result[1].Message);
                        dispatch(calendarActionCreators.receiveCalendarCreate(createCalendarResponse[0]))
                    } else if (result[0] === NO_CONTENT) {
                        dispatch(calendarActionCreators.createCalendarError('No Content'))
                        return Promise.reject('No Content')
                    } else {
                        dispatch(calendarActionCreators.createCalendarError('fail'));
                        return Promise.reject('fail');
                    }
                }).catch(err => Promise.reject(err))
        }
    },

    receiveCalendarSave: (responseObj) => ({
        type: saveCalendarSuccess,
        calendarSave: responseObj
    }),

    calendarSaveError: error => ({
        type: saveCalendarFailure,
        calendarSaveError: error
    }),

    saveCalendar: (body) => {
        return dispatch => {
            let headers = {
                'content-type': "application/json; charset=utf-8"
            }
            return partnerRequest((API_URL || process.env.REACT_APP_CALENDAR_API_URL) + '/Calendar/api/SaveCalendarAsync', 'POST', headers, body, '')
                .then(result => {
                    if (result[0] === REQUEST_FAILURE) {
                        dispatch(calendarActionCreators.calendarSaveError(result[1]))
                        return Promise.reject(result[1])
                    } else if (result[0] === REQUEST_SUCCESS) {
                        try {
                            const saveCalendarResponse = JSON.parse(result[1].Message);
                            dispatch(calendarActionCreators.receiveCalendarSave(saveCalendarResponse[0]));
                        } catch {
                            // Message does not contain json. 
                            dispatch(calendarActionCreators.receiveCalendarSave(result[1].Message));
                        }
                        // The calendar save can go fine but the failure message can contain information about the minutes save failing
                        if (result[1].FailureMessage) {
                            return result[1].FailureMessage;
                        }
                    } else if (result[0] === NO_CONTENT) {
                        dispatch(calendarActionCreators.calendarSaveError('No Content'))
                        return Promise.reject('No Content')
                    } else if (result.name === 'AbortError') {
                        return Promise.reject("Aborted");
                    }
                }).catch(err => Promise.reject(err))
        }
    },

    receiveCalendarFileSave: (responseObj) => ({
        type: saveCalendarFileSuccess,
        calendarFileSave: responseObj
    }),

    calendarFileSaveError: error => ({
        type: saveCalendarFileFailure,
        calendarFileSaveError: error
    }),

    saveCalendarFile: (params) => {
        return dispatch => {
            let headers = {
                'content-type': "application/json; charset=utf-8"
            }
            return partnerRequest((API_URL || process.env.REACT_APP_CALENDAR_FILE_API_URL) + '/CalendarFileGeneration/api/ProcessCalendarFileAsync', 'GET', headers, '', params, true)
                .then(result => {
                    if (result[0] === REQUEST_FAILURE) {
                        dispatch(calendarActionCreators.calendarFileSaveError(result[1]))
                        return Promise.reject(result[1])
                    } else if (result[0] === REQUEST_SUCCESS) {
                        // Dispatch the success action
                        dispatch(calendarActionCreators.receiveCalendarFileSave(result[1]))
                    } else if (result[0] === NO_CONTENT) {
                        dispatch(calendarActionCreators.calendarFileSaveError('No Content'))
                        return Promise.reject('No Content')
                    }
                }).catch(err => Promise.reject(err));
        }
    },

    receiveCalendarById: responseObj => ({
        type: getCalendarSuccess,
        calendarGet: responseObj
    }),

    calendarGetError: message => ({
        type: getCalendarFailure,
        calendarGetError: message
    }),

    getCalendarById: (params, partner) => {
        return dispatch => {
            let headers = {
                'content-type': "application/json; charset=utf-8"
            }
            let request = '';
            if (partner) {
                request = partnerRequest((API_URL || process.env.REACT_APP_CALENDAR_API_URL) + '/Calendar/api/GetPartnerCalendarsByIdAsync', 'GET', headers, '', params)
            } else {
                request = publicRequest((API_URL || process.env.REACT_APP_CALENDAR_API_URL) + '/Calendar/api/GetCalendarsByIdAsync', 'GET', headers, '', params)
            }
            return request
                .then(result => {
                    if (result[0] === REQUEST_FAILURE) {
                        dispatch(calendarActionCreators.calendarGetError(result[1]))
                        return Promise.reject(result[1])
                    } else if (result[0] === REQUEST_SUCCESS) {
                        dispatch(calendarActionCreators.receiveCalendarById(result[1].Calendars[0]))
                    } else if (result[0] === NO_CONTENT) {
                        dispatch(calendarActionCreators.calendarGetError('No Content'))
                        return Promise.reject('No Content')
                    } else if (result.name === 'AbortError') {
                        return Promise.reject("Aborted");
                    }
                }).catch(err => Promise.reject(err))
        }
    },

    receiveCalendarCurrentItem: responseObj => ({
        type: saveCalendarCurrentItemSuccess,
        calendarCurrentItem: responseObj
    }),

    calendarCurrentItemError: message => ({
        type: saveCalendarCurrentItemFailure,
        calendarCurrentItemError: message
    }),

    saveCalendarCurrentItem: body => {
        return dispatch => {
            let headers = {
                'content-type': "application/json; charset=utf-8"
            }
            return partnerRequest((API_URL || process.env.REACT_APP_CALENDAR_API_URL) + '/Calendar/api/SaveCalendarCurrentItemAsync', 'POST', headers, body, '')
                .then(result => {
                    if (result[0] === REQUEST_FAILURE) {
                        dispatch(calendarActionCreators.calendarCurrentItemError(result[1]))
                        return Promise.reject(result[1])
                    } else if (result[0] === REQUEST_SUCCESS) {
                        try {
                            const response = JSON.parse(result[1].Message);
                            dispatch(calendarActionCreators.receiveCalendarCurrentItem(response[0]))
                        } catch {
                            dispatch(calendarActionCreators.calendarCurrentItemError('Response malformed: ' + result[1]))
                            return Promise.reject('Response malformed: ' + result[1])
                        }
                    } else if (result[0] === NO_CONTENT) {
                        dispatch(calendarActionCreators.calendarCurrentItemError('No Content'))
                        return Promise.reject('No Content')
                    } else if (result.name === 'AbortError') {
                        return Promise.reject("Aborted");
                    }
                }).catch(err => Promise.reject(err))
        }
    },

    replacePdf: (params, body) => {
        return dispatch => {
            const headers = {
                'cache-control': 'no-cache'
            }
            let formData = new FormData();
            formData.append('replacementPdfFile', body);
            return partnerRequest((API_URL || process.env.REACT_APP_CALENDAR_FILE_API_URL) + '/CalendarFileGeneration/api/UploadReplacementCalendarFileAsync', 'POST', headers, formData, params)
                .then(result => {
                    if (result[0] === REQUEST_FAILURE) {
                        dispatch({
                            type: replacePdfRequest,
                            replacePdfError: result[1]
                        });
                        return Promise.reject(result[1])
                    } else if (result[0] === REQUEST_SUCCESS) {
                        dispatch({
                            type: replacePdfRequest,
                            replacePdfResponse: result[1]
                        });
                        emptyCache();
                    } else if (result[0] === NO_CONTENT) {
                        dispatch({
                            type: replacePdfRequest,
                            replacePdfError: "No Content"
                        });
                        return Promise.reject('No Content')
                    } else if (result.name === 'AbortError') {
                        return Promise.reject("Aborted");
                    }
                }).catch(err => Promise.reject(err))
        }
    },

    sendDocketHtml: (body) => {
        return dispatch => {
            const headers = {
                'content-type': "application/json; charset=utf-8"
            }

            return partnerRequest("https://apps.dlas.virginia.gov/senatevote/api/savedocketasync", 'POST', headers, body, '')
                .then(result => {
                    dispatch({
                        type: sendDocketHtmlRequest,
                        sendDocketHtmlResponse: result
                    });
                }).catch(err => Promise.reject(err))
        }
    },

    sendCalendarHtml: (body) => {
        return dispatch => {
            const headers = {
                'content-type': "application/json; charset=utf-8"
            }

            return partnerRequest("https://apps.dlas.virginia.gov/SenateVote/api/saveCalendarAsync", 'POST', headers, body, '')
                .then(result => {
                    dispatch({
                        type: sendCalendarHtmlRequest,
                        sendCalendarHtmlResponse: result
                    });
                }).catch(err => Promise.reject(err))
        }
    }
};

export const reducer = (state, action) => {
    state = state || initialState;

    switch (action.type) {
        case docketByIdRequest:
            return Object.assign({}, state, {
                isFetching: true
            });
        case docketByIdSuccess:
            return Object.assign({}, state, {
                isFetching: false,
                docketByIdData: action.docketByIdData,
                errorMessage: ''
            });
        case docketByIdFailure:
            return Object.assign({}, state, {
                isFetching: false,
                errorMessage: action.errorMessage
            });
        case patronListDocketByIdRequest:
            return Object.assign({}, state, {
                isFetching: true
            });
        case patronListDocketByIdSuccess:
            return Object.assign({}, state, {
                isFetching: false,
                patronListDocketByIdData: action.patronListDocketByIdData,
                errorMessage: ''
            });
        case patronListDocketByIdFailure:
            return Object.assign({}, state, {
                isFetching: false,
                errorMessage: action.errorMessage
            });
        case getReferencesFailure:
            return Object.assign({}, state, {
                getReferencesError: action.getReferencesError
            });
        case getReferencesSuccess:
            return Object.assign({}, state, {
                references: action.references,
                getReferencesError: ''
            });
        case getDocketListFailure:
            return Object.assign({}, state, {
                getDocketListError: action.getDocketListError
            });
        case getDocketListSuccess:
            return Object.assign({}, state, {
                docketList: action.docketList,
                getDocketListError: ''
            });
        case createDocketFailure:
            return Object.assign({}, state, {
                docketCreateError: action.docketCreateError
            });
        case createDocketSuccess:
            return Object.assign({}, state, {
                docketCreate: action.docketCreate,
                docketCreateError: ''
            });
        case saveDocketFailure:
            return Object.assign({}, state, {
                docketSaveError: action.docketSaveError
            });
        case saveDocketSuccess:
            return Object.assign({}, state, {
                docketSave: action.docketSave,
                docketSaveError: ''
            });
        case getCalendarCategoriesReferencesFailure:
            return Object.assign({}, state, {
                getCalendarCategoriesReferencesError: action.getCalendarCategoriesReferencesError
            });
        case getCalendarCategoriesReferencesSuccess:
            return Object.assign({}, state, {
                calendarCategoriesReferences: action.calendarCategoriesReferences,
                getCalendarCategoriesReferencesError: ''
            });
        case getCalendarAssignmentsFailure:
            return Object.assign({}, state, {
                getCalendarAssignmentsError: action.getCalendarAssignmentsError
            });
        case getCalendarAssignmentsSuccess:
            return Object.assign({}, state, {
                calendarAssignments: action.calendarAssignments,
                getCalendarAssignmentsError: ''
            });
        case getAdminCalendarAssignmentsFailure:
            return Object.assign({}, state, {
                getAdminCalendarAssignmentsError: action.getAdminCalendarAssignmentsError
            });
        case getAdminCalendarAssignmentsSuccess:
            return Object.assign({}, state, {
                adminCalendarAssignments: action.adminCalendarAssignments,
                getAdminCalendarAssignmentsError: ''
            });
        case saveCalendarAssignmentFailure:
            return Object.assign({}, state, {
                saveCalendarAssignmentsError: action.saveCalendarAssignmentsError
            });
        case saveCalendarAssignmentSuccess:
            return Object.assign({}, state, {
                calendarAssignmentSave: action.calendarAssignmentSave,
                saveCalendarAssignmentsError: ''
            });
        case getCalendarListFailure:
            return Object.assign({}, state, {
                calendarListError: action.calendarListError
            });
        case getCalendarListSuccess:
            return Object.assign({}, state, {
                calendarList: action.calendarList,
                calendarListError: ''
            });
        case createCalendarFailure:
            return Object.assign({}, state, {
                calendarCreateError: action.calendarCreateError
            });
        case createCalendarSuccess:
            return Object.assign({}, state, {
                calendarCreate: action.calendarCreate,
                calendarCreateError: ''
            });
        case saveCalendarFailure:
            return Object.assign({}, state, {
                calendarSaveError: action.calendarSaveError
            });
        case saveCalendarSuccess:
            return Object.assign({}, state, {
                calendarSave: action.calendarSave,
                calendarSaveError: ''
            });
        case saveCalendarFileFailure:
            return Object.assign({}, state, {
                calendarFileSaveError: action.calendarFileSaveError
            });
        case saveCalendarFileSuccess:
            return Object.assign({}, state, {
                calendarFileSave: action.calendarFileSave,
                calendarFileSaveError: ''
            });
        case getCalendarFailure:
            return Object.assign({}, state, {
                calendarGetError: action.calendarGetError
            });
        case getCalendarSuccess:
            return Object.assign({}, state, {
                calendarGet: action.calendarGet,
                calendarGetError: ''
            });
        case saveCalendarCurrentItemFailure:
            return Object.assign({}, state, {
                calendarCurrentItemError: action.calendarCurrentItemError
            });
        case saveCalendarCurrentItemSuccess:
            return Object.assign({}, state, {
                calendarCurrentItem: action.calendarCurrentItem,
                calendarCurrentItemError: ''
            });
        case replacePdfRequest:
            return Object.assign({}, state, {
                replacePdfResponse: action.replacePdfResponse || state.replacePdfResponse,
                replacePdfError: action.replacePdfError || ''
            });
        default:
            return state;
    }
};
