import React from 'react';
import * as Fields from '../../../lis-shared/lis-layout/components/lis-forms-component';
import DatePicker from 'react-datepicker';
import Select from 'react-select';
import moment from 'moment';
import { Link, Prompt } from 'react-router-dom';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { billActionCreators } from '../../../stores/lis-legislation-store';
import { sessionActionCreators } from '../../../stores/lis-session-store';
import { navActionCreators } from '../../../stores/lis-nav-store';
import 'react-datepicker/dist/react-datepicker.css';
import { cancelRequest } from '../../../services/request.service';

class SessionForm extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            sessionEvents: [],
            sessionData: '',
            selectedSessionType: '',
            selectedSessionEventType: '',
            sessionEventData: [],
            isSaving: false,
            isDirty: false
        };

        this.handleInputChange = this.handleInputChange.bind(this);
        this.handleEventChange = this.handleEventChange.bind(this);
        this.handleSessionEventTypeChange = this.handleSessionEventTypeChange.bind(this);
        this.handleCopyContinuedChange = this.handleCopyContinuedChange.bind(this);
        this.addSessionDate = this.addSessionDate.bind(this);
        this.saveForm = this.saveForm.bind(this);
    }

    handleInputChange(value, key) {
        let sessionData = { ...this.state.sessionData };
        if (key === 'SessionCode') {
            //we allow for more than just single-digit special session # (e.g. Special Session V) despite the fact that this will never (say never) happen
            value = value.replace(/[^0-9]/, "").substr(0, 6);
            const sessionCodeSuffix = value && value.length >= 5 ? value.substr(4) : null;
            if (sessionCodeSuffix) {
                sessionData['SessionYear'] = value.substr(0, 4);
                sessionData['SessionType'] = sessionCodeSuffix === '1' ? 'Regular' : 'Special';
                sessionData['DisplayName'] = sessionData['SessionType'] + ' Session';
                if (sessionData['SessionType'] === 'Special') {
                    sessionData['DisplayName'] += ' ' + this.romanize(Number(sessionCodeSuffix) - 1);
                }
                const sessionTypeData = Fields.sessionTypeOptions.find(type => type.label === sessionData['SessionType']);
                sessionData['SessionTypeID'] = sessionTypeData ? sessionTypeData.value : null; //null will fail but I think it's better than guessing
            } else {
                sessionData['DisplayName'] = null;
                sessionData['SessionYear'] = null;
                sessionData['SessionType'] = null;
                sessionData['SessionTypeID'] = null;
            }
        }
        sessionData[key] = value;
        this.setState({
            sessionData: sessionData,
            isDirty: true
        });
    }

    romanize(num) {
        if (isNaN(num))
            return NaN;
        var digits = String(+num).split(""),
            key = ["", "C", "CC", "CCC", "CD", "D", "DC", "DCC", "DCCC", "CM",
                "", "X", "XX", "XXX", "XL", "L", "LX", "LXX", "LXXX", "XC",
                "", "I", "II", "III", "IV", "V", "VI", "VII", "VIII", "IX"],
            roman = "",
            i = 3;
        while (i--)
            roman = (key[+digits.pop() + (i * 10)] || "") + roman;
        return Array(+digits.join("") + 1).join("M") + roman;
    }

    handleEventChange(value, index) {
        let sessionEventData = [...this.state.sessionEventData];
        if (value) {
            sessionEventData[index].ActualDate = value;
        } else {
            // If the user clicks the 'X' button on a date then remove it from the session event list
            sessionEventData.splice(index, 1);
        }
        this.setState({
            sessionEventData: sessionEventData
        });
    }

    handleSessionEventTypeChange(value) {
        this.setState({
            selectedSessionEventType: value
        });
    }

    handleCopyContinuedChange(value) {
        this.setState({
            copyContinuedLegislation: value,
            isDirty: true
        })
    }

    addSessionDate() {
        if (this.state.selectedSessionEventType) {
            // Set the correct values for the new event date
            let selectedSessionEventType = { ...this.state.selectedSessionEventType };
            selectedSessionEventType.DisplayName = selectedSessionEventType.Name;
            selectedSessionEventType.ActualDate = moment();
            // Add it to the session events 
            let sessionEventData = [...this.state.sessionEventData];
            sessionEventData.push(selectedSessionEventType);
            this.setState({
                sessionEventData: sessionEventData,
                selectedSessionEventType: '',
                isDirty: true
            });
        }

    }

    saveForm() {
        let sessionData = this.state.sessionData;
        let sessionEventData = this.state.sessionEventData;
        let startDate = sessionEventData.find(date => date.DisplayName === "Session Start");
        let prefileDate = sessionEventData.find(date => date.DisplayName === "Prefile Date");
        let prefileDueDate = sessionEventData.find(date => date.DisplayName === "Prefile Draft Due");
        // Lets do some validation
        let error = false;
        if (!sessionData.SessionCode || sessionData.SessionCode.length < 5) {
            this.setState({ codeError: true });
            error = true;
        }
        if (!startDate || !startDate.ActualDate) {
            this.setState({ startDateError: true });
            error = true;
        } else if (!prefileDate || !prefileDate.ActualDate) {
            this.setState({ prefileDateError: true });
            error = true;
        } else if (sessionData.SessionType === "Regular" && (!prefileDueDate || !prefileDueDate.ActualDate)) {
            this.setState({ prefileDueDateError: true });
            error = true;
        }
        if (!sessionData.DisplayName) {
            this.setState({ displayNameError: true });
            error = true;
        }
        if (!error) {
            if ((!this.props.match.params.id || Number(this.state.initialSessionCode) !== Number(sessionData.SessionCode)) && this.state.sessionList.find(session => Number(session.SessionCode) === Number(sessionData.SessionCode))) {
                this.props.actions.makeToast([{ message: "Session Code Already In Use", type: "failure" }]);
                return;
            }
            this.setState({
                isSaving: true
            });
            sessionData.SessionEvents = sessionEventData;
            sessionData.CopyContinuedLegislation = this.state.copyContinuedLegislation ? this.state.copyContinuedLegislation.value : false;
            let action = '';
            if (this.props.match.params.id) {
                action = this.props.actions.editSession(sessionData);
            } else {
                action = this.props.actions.createSession(sessionData);
            }
            action
                .then(() => {
                    this.setState({
                        isSaving: false,
                        isDirty: false
                    }, () => {
                        this.props.history.push('/session-management');
                        this.props.actions.makeToast([{ message: "Session Saved", type: "success" }]);
                        this.props.actions.reloadSessionSelector(true);
                    })
                }).catch(err => {
                    if (err === 'Aborted') {
                        return;
                    }
                    this.props.actions.makeToast([{ message: "Save Failed", type: "failure" }]);
                    this.setState({
                        isSaving: false,
                    });
                });
        }
    }

    componentDidMount() {
        //Get the session events
        this.props.actions.getSessionEventTypes()
            .then(() => {
                let sessionEventTypes = [...this.props.session.sessionEventTypes];
                sessionEventTypes.forEach(event => {
                    event.label = event.Name;
                    event.value = event.EventTypeID;
                })
                this.setState({
                    sessionEvents: sessionEventTypes,
                    sessionEventsIsLoaded: true
                });
            });
        this.props.actions.getSessionList()
            .then(() => {
                const sessionList = [...this.props.session.sessionList];
                this.setState({ sessionList, sessionsAreLoaded: true })
            })
        if (this.props.match.params.id) {
            // This is an existing session. Go ahead and get the data
            this.props.actions.getSessionById(this.props.match.params.id, true)
                .then(() => {
                    const selectedSession = this.props.session.selectedSession
                    const selectedSessionType = Fields.sessionTypeOptions.find(type => type.value == selectedSession.SessionTypeID);
                    this.setState({
                        sessionData: selectedSession,
                        initialSessionCode: selectedSession.SessionCode,
                        selectedSessionType: selectedSessionType || '',
                        sessionEventData: selectedSession.SessionEvents || [],
                        isLoaded: true
                    });
                }).catch(err => {
                    if (err === 'Aborted') {
                        return;
                    }
                    this.setState({
                        isLoaded: true,
                        message: err.toString()
                    });
                })
            //if session already has bills, do not allow session code change
            this.props.actions.getSessionBills('?sessionID= ' + this.props.match.params.id)
                .then(() => {
                    if (this.props.bills.sessionBills && this.props.bills.sessionBills.length > 0) {
                        this.setState({ sessionHasBills: true })
                    }
                    this.setState({ sessionBillsAreLoaded: true })
                }).catch(err => {
                    if (err === 'Aborted') {
                        return;
                    }
                    this.setState({
                        sessionBillsAreLoaded: true,
                        sessionHasBills: true //default to not allowing them to change the session code if the request fails..
                    });
                })
        } else {
            this.setState({
                isLoaded: true,
                sessionBillsAreLoaded: true
            });
        }
    }

    componentWillUnmount() {
        cancelRequest();
    }

    render() {
        const { sessionData, sessionEventData, sessionEvents, selectedSessionEventType, selectedSessionType, copyContinuedLegislation } = this.state;
        if (this.state.message) {
            <div className="fail">{this.state.message}</div>
        }
        const customStyles = {
            option: (base, state) => ({
                ...base,
                fontSize: '0.8em',
            }),
            control: (base) => ({
                ...base,
                padding: '1px',
                margin: 0,
                minHeight: 0,
                fontSize: '0.8em',
            }),
            singleValue: (base, state) => {
                return { ...base, };
            }
        }
        //Filter out the events that have already been added to the session
        const filteredSessionEvents = sessionEvents.filter(event => {
            if (sessionEventData.findIndex(eventData => eventData.EventTypeID === event.EventTypeID) < 0) {
                return event;
            }
        });
        if (this.state.isLoaded && this.state.sessionEventsIsLoaded && this.state.sessionsAreLoaded && this.state.sessionBillsAreLoaded) {
            return (
                <div className="user-forms">
                    <div>
                        <Prompt
                            when={this.state.isDirty}
                            message={`You have unsaved changes. Are you sure you would like to leave?`}
                        />
                    </div>
                    <div className="dlas-forms">
                        <form>
                            <legend>
                                <span>Session Setup</span>
                                {sessionData.SessionYear && sessionData.DisplayName &&
                                    <span style={{ float: 'right' }}>{sessionData.SessionYear + " " + sessionData.DisplayName}</span>
                                }
                            </legend>
                            <div className="multi-row fifth-even no-margin">
                                <div>
                                    <label className="label" htmlFor="session-code">Session Code</label>
                                    <input
                                        id="session-code"
                                        className={this.state.codeError && (!sessionData.SessionCode || sessionData.SessionCode.length < 5) ? 'input-error' : ''}
                                        type="text"
                                        placeholder="Session Code"
                                        disabled={this.state.sessionHasBills}
                                        value={sessionData.SessionCode || ''}
                                        onChange={(e) => this.handleInputChange(e.target.value, 'SessionCode')}
                                    />
                                </div>

                                {sessionEventData.map((event, eventIndex) =>
                                    <div key={eventIndex}>
                                        <label className="label">{event.DisplayName}</label>
                                        <DatePicker
                                            selected={event.ActualDate ? moment(event.ActualDate) : null}
                                            isClearable
                                            onChange={(value) => {
                                                this.handleEventChange(moment(value), eventIndex)
                                            }}
                                        />
                                    </div>

                                )}
                            </div>
                            <div className="multi-row fifth-even no-margin">
                                <div>
                                    {filteredSessionEvents.length > 0 &&
                                        <div>
                                            <label>Add Session Date</label>
                                            <Select
                                                styles={customStyles}
                                                className={(this.state.startDateError && !sessionEventData.find(date => date.DisplayName === "Session Start")) || (this.state.prefileDateError && !sessionEventData.find(it => it.DisplayName === "Prefile Date")) || (sessionData.SessionType === "Regular" && this.state.prefileDueDateError && !sessionEventData.find(it => it.DisplayName === "Prefile Draft Due")) ? 'input-error' : ''}
                                                id="SessionDateTypeSelector"
                                                value={selectedSessionEventType}
                                                options={filteredSessionEvents}
                                                onChange={this.handleSessionEventTypeChange}
                                            />
                                            {this.state.startDateError && !sessionEventData.find(date => date.DisplayName === "Session Start") &&
                                                <span className="input-feedback">Please select a Session Start Date</span>
                                            }
                                            {this.state.prefileDateError && !sessionEventData.find(it => it.DisplayName === "Prefile Date") &&
                                                <span className="input-feedback">Please select a Session Prefile Date</span>
                                            }
                                            {sessionData.SessionType === "Regular" && this.state.prefileDueDateError && !sessionEventData.find(it => it.DisplayName === "Prefile Draft Due") &&
                                                <span className="input-feedback">Please select a Session Prefile Due Date</span>
                                            }
                                        </div>
                                    }
                                </div>
                                <div>
                                    {filteredSessionEvents.length > 0 &&
                                        <div>
                                            <br />
                                            <button
                                                disabled={!selectedSessionEventType}
                                                className="button"
                                                type="button"
                                                onClick={this.addSessionDate}>
                                                Add</button>
                                        </div>
                                    }
                                </div>
                                <div>
                                    <label htmlFor="is-active-button" className="checkbox-label no-background" style={{ paddingLeft: '0px' }}>Session Active</label>
                                    <div className="toggle-switch" onClick={() => this.handleInputChange(!sessionData.IsActive, 'IsActive')}>
                                        <input id="is-active-button" checked={sessionData.IsActive} type="checkbox" />
                                        <span className="slider"></span>
                                    </div>
                                </div>
                                <div>
                                    <label htmlFor="is-default-button" className="checkbox-label no-background" style={{ paddingLeft: '0px' }}>Default Display Session</label>
                                    <div className="toggle-switch" onClick={() => this.handleInputChange(!sessionData.IsDefault, 'IsDefault')}>
                                        <input id="is-default-button" checked={sessionData.IsDefault} type="checkbox" />
                                        <span className="slider"></span>
                                    </div>
                                </div>
                                <div>
                                    <label htmlFor="CopyContinuedLegislation">Copy Continued Bills</label>
                                    <Select
                                        styles={customStyles}
                                        id="CopyContinuedLegislation"
                                        value={copyContinuedLegislation}
                                        options={[
                                            { label: "Select...", value: false },
                                            { label: "Yes", value: true },
                                        ]}
                                        onChange={this.handleCopyContinuedChange}
                                    />
                                </div>
                            </div>
                            <div className="button-bar">
                                <div>
                                </div>
                                <div className="align-right">
                                    <Link to="/session-management" className="button secondary">Cancel</Link>
                                    <button className="button" type="button" disabled={this.state.isSaving} onClick={this.saveForm}>Save</button>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            );
        } else {
            return (<div className="center-spinner spinner">Loading...</div>)
        }
    }
};

export default connect(
    (state) => {
        const { session, nav, bills } = state;
        return {
            nav,
            session,
            bills
        }
    },
    (dispatch) => {
        return {
            actions: bindActionCreators(Object.assign({}, billActionCreators, sessionActionCreators, navActionCreators), dispatch)
        }
    }
)(SessionForm)