import React from "react";
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { navActionCreators } from "../../stores/lis-nav-store";
import { Link } from "react-router-dom";
import renderHTML from 'react-render-html';
import { collectionActionCreators } from "../../stores/lis-collection-store";
import moment from "moment";
import { sessionActionCreators } from "../../stores/lis-session-store";
import { authActionCreators } from "../../stores/lis-auth-store";

class WatchlistsComponent extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            sessionOptions: [],
            watchlists: [],
            filteredWatchlists: [],
            filteredWatchlistsValue: "",
            nameSort: false,
            descSort: false,
            dateSort: false,
            billNumberSort: false,
            viewCrossReferenceList: false,
            expandedWatchlists: [],
            legislationList: [],
            expandedBills: [],
            filteredBills: [],
            filteredBillsValue: "",
            expandAllWatchlists: false,
            expandAllBills: false,
            loadingCollections: true,
            showPopup: false
        }

        this.getWatchlists = this.getWatchlists.bind(this);
        this.filterWatchlist = this.filterWatchlist.bind(this);
        this.filterBills = this.filterBills.bind(this);
        this.sortByName = this.sortByName.bind(this);
        this.sortByDesc = this.sortByDesc.bind(this);
        this.sortByDate = this.sortByDate.bind(this);
        this.sortByBillNumber = this.sortByBillNumber.bind(this);
        this.toggleBillList = this.toggleBillList.bind(this);
        this.toggleCrossReferenceList = this.toggleCrossReferenceList.bind(this);
        this.toggleWatchlistList = this.toggleWatchlistList.bind(this);
        this.toggleWatchlist = this.toggleWatchlist.bind(this);
        this.togglePopup = this.togglePopup.bind(this);
        this.deleteWatchlists = this.deleteWatchlists.bind(this);
        this.updateCrossReferenceList = this.updateCrossReferenceList.bind(this);
    }

    componentDidMount() {
        if (!this.props.session.sessionList || !this.props.session.sessionList.length) {
            this.props.actions.getSessionList()
                .then(() => {
                    this.setState({
                        sessionOptions: this.props.session.sessionList
                    }, () => {
                        this.getWatchlists();
                    })
                }).catch(err => {
                    if (err === 'Aborted') {
                        return;
                    }
                    console.log(err)
                });
        } else {
            this.setState({
                sessionOptions: this.props.session.sessionList
            }, () => {
                this.getWatchlists();
            })
        }
    }

    componentDidUpdate(prevProps) {
        if (prevProps.nav.session != this.props.nav.session) {
            this.setState({ loadingCollections: true }, () => {
                this.getWatchlists();
            })
        }
    }

    getWatchlists() {
        if (this.props.nav.session) {
            const selectedSession = this.state.sessionOptions.find(s => s.SessionCode === this.props.nav.session);
            if (selectedSession) {
                this.props.actions.getCollections("?SessionID=" + selectedSession.SessionID)
                    .then(() => {
                        this.setState({
                            watchlists: this.props.collection.collections,
                            filteredWatchlists: this.props.collection.collections,
                            filteredWatchlistsValue: ""
                        }, () => {
                            this.updateCrossReferenceList();
                        })
                    })
            }
        }
    }

    updateCrossReferenceList() {
        let legislationList = [];
        for (let i = 0; i < this.state.watchlists.filter(w => !w.DeletionDate).length; i++) {
            for (let j = 0; j < this.state.watchlists.filter(w => !w.DeletionDate)[i].WatchListLegislation.length; j++) {
                let legislationIndex = legislationList.findIndex(x => x.LegislationNumber === this.state.watchlists.filter(w => !w.DeletionDate)[i].WatchListLegislation[j].LegislationNumber);
                if (legislationIndex >= 0) {
                    legislationList[legislationIndex].watchlists.push(this.state.watchlists.filter(w => !w.DeletionDate)[i]);
                } else {
                    let newLegislation = JSON.parse(JSON.stringify(this.state.watchlists.filter(w => !w.DeletionDate)[i].WatchListLegislation[j]));
                    newLegislation.watchlists = [this.state.watchlists.filter(w => !w.DeletionDate)[i]];
                    legislationList.push(newLegislation);
                }
            }
        }

        this.setState({
            legislationList: legislationList,
            loadingCollections: false
        })
    }

    filterWatchlist(e) {
        const val = e.target.value.toLowerCase();
        this.setState({
            // Map is used to preserve the original index
            filteredWatchlists: this.state.watchlists.map(coll => {
                if ((coll.Description && coll.Description.toLowerCase().includes(val)) || (coll.Name && coll.Name.toLowerCase().includes(val))) {
                    return coll;
                }
            }),
            filteredWatchlistsValue: val
        })
    }

    filterBills(e) {
        const val = e.target.value.toLowerCase();
        this.setState({
            // Map is used to preserve the original index
            filteredBills: this.state.legislationList.map(b => {
                if ((b.LegislationNumber && b.LegislationNumber.toLowerCase().includes(val)) || (b.Description && renderHTML(b.Description.toLowerCase()).includes(val))) {
                    return b;
                }
            }),
            filteredBillsValue: val
        })
    }

    sortByName() {
        this.setState({
            filteredWatchlists: this.state.filteredWatchlists.sort((a, b) => a.Name > b.Name ? this.state.nameSort ? -1 : 1 : this.state.nameSort ? 1 : -1),
            nameSort: !this.state.nameSort,
            descSort: false,
            dateSort: false
        })
    }

    sortByDesc() {
        this.setState({
            filteredWatchlists: this.state.filteredWatchlists.sort((a, b) => a.Description > b.Description ? this.state.descSort ? -1 : 1 : this.state.descSort ? 1 : -1),
            descSort: !this.state.descSort,
            nameSort: false,
            dateSort: false
        })
    }

    sortByDate() {
        this.setState({
            filteredWatchlists: this.state.filteredWatchlists.sort((a, b) => moment(a.ModificationDate).isBefore(b.ModificationDate) ? this.state.dateSort ? -1 : 1 : this.state.dateSort ? 1 : -1),
            dateSort: !this.state.dateSort,
            nameSort: false,
            descSort: false
        })
    }

    sortByBillNumber() {
        this.setState({
            filteredBills: this.state.filteredBills.sort((a, b) => a.LegislationNumber > b.LegislationNumber ? this.state.billNumberSort ? -1 : 1 : this.state.billNumberSort ? 1 : -1),
            legislationList: this.state.legislationList.sort((a, b) => a.LegislationNumber > b.LegislationNumber ? this.state.billNumberSort ? -1 : 1 : this.state.billNumberSort ? 1 : -1),
            billNumberSort: !this.state.billNumberSort
        })
    }

    toggleBillList(watchListIDsParam, toggleAll) {
        let watchListIDs;

        if (!Array.isArray(watchListIDsParam)) {
            watchListIDs = [watchListIDsParam];
        } else {
            watchListIDs = watchListIDsParam;
        }

        let expandedWatchlists = [...this.state.expandedWatchlists];

        for (let i = 0; i < watchListIDs.length; i++) {
            if (!expandedWatchlists.find(x => x.WatchListID === watchListIDs[i])) {
                let watchlist = this.state.filteredWatchlists.find(x => x && x.WatchListID === watchListIDs[i]);
                watchlist.expanded = true;
                expandedWatchlists.push(watchlist);
            } else {
                expandedWatchlists.find(x => x.WatchListID === watchListIDs[i]).expanded = toggleAll !== undefined ? toggleAll : !expandedWatchlists.find(x => x.WatchListID === watchListIDs[i]).expanded;
            }
        }

        this.setState({
            expandedWatchlists: expandedWatchlists
        })
    }

    toggleCrossReferenceList() {
        this.setState({
            viewCrossReferenceList: !this.state.viewCrossReferenceList
        })
    }

    toggleWatchlist(watchlistIndex) {
        let watchlists = this.state.filteredWatchlistsValue.length > 0 ? this.state.filteredWatchlists.filter(x => x != undefined) : this.state.watchlists;
        watchlists[watchlistIndex].checked = !watchlists[watchlistIndex].checked;

        this.setState({ [this.state.filteredWatchlistsValue.length ? 'filteredWatchlistsValue' : 'watchlists']: watchlists })
    }

    togglePopup() {
        this.setState({
            showPopup: !this.state.showPopup
        });
    }

    deleteWatchlists() {
        let watchlists = this.state.filteredWatchlistsValue.length > 0 ? this.state.filteredWatchlists.filter(x => x != undefined) : this.state.watchlists;
        let watchlistsToDelete = watchlists.filter(w => w.checked);
        if (watchlistsToDelete.length) {
            this.setState({ deletingWatchlists: true }, () => {
                watchlistsToDelete.forEach(wl => wl.DeletionDate = moment());
                this.props.actions.saveCollections({ WatchLists: watchlistsToDelete })
                    .then(() => {
                        watchlists = watchlists.filter(wl => !wl.checked && !wl.DeletionDate);
                        this.props.actions.makeToast([{ message: "Delete Successful", type: "success" }]);
                        this.setState({
                            [this.state.filteredWatchlistsValue.length ? 'filteredWatchlistsValue' : 'watchlists']: watchlists,
                            deletingWatchlists: false,
                        }, () => {
                            this.togglePopup();
                            this.updateCrossReferenceList();
                        });
                    }).catch(err => {
                        if (err === 'Aborted') {
                            return;
                        }
                        this.setState({
                            deletingWatchlists: false
                        });
                        this.props.actions.makeToast([{ message: "Delete Failed", type: "failure" }]);
                    });
            })
        }
    }

    toggleWatchlistList(legislationNumbersParam, toggleAll) {
        let legislationNumbers;

        if (!Array.isArray(legislationNumbersParam)) {
            legislationNumbers = [legislationNumbersParam];
        } else {
            legislationNumbers = legislationNumbersParam;
        }

        let expandedBills = [...this.state.expandedBills];

        for (let i = 0; i < legislationNumbers.length; i++) {
            if (!expandedBills.find(x => x.LegislationNumber === legislationNumbers[i])) {
                let bill = this.state.legislationList.find(x => x.LegislationNumber === legislationNumbers[i]);
                bill.expanded = true;
                expandedBills.push(bill);
            } else {
                expandedBills.find(x => x.LegislationNumber === legislationNumbers[i]).expanded = toggleAll !== undefined ? toggleAll : !expandedBills.find(x => x.LegislationNumber === legislationNumbers[i]).expanded;
            }
        }

        this.setState({
            expandedBills: expandedBills
        })
    }

    toggleExpandAll(crossRefList) {
        if (crossRefList) {
            let legNumbers = [];
            this.state.legislationList.forEach(x => {
                if (x.watchlists.length > 0) {
                    legNumbers.push(x.LegislationNumber)
                }
            })
            this.setState({
                expandAllBills: !this.state.expandAllBills
            }, () => {
                this.toggleWatchlistList(legNumbers, this.state.expandAllBills)
            })
        } else {
            let watchlists = [];
            this.state.filteredWatchlists.forEach(x => {
                if (x && x.WatchListLegislation.length > 0) {
                    watchlists.push(x.WatchListID);
                }
            })
            this.setState({
                expandAllWatchlists: !this.state.expandAllWatchlists
            }, () => {
                this.toggleBillList(watchlists, this.state.expandAllWatchlists)
            })
        }
    }

    render() {
        let watchlists = this.state.filteredWatchlistsValue.length > 0 ? this.state.filteredWatchlists.filter(x => x != undefined) : this.state.watchlists;
        let bills = this.state.filteredBillsValue.length > 0 ? this.state.filteredBills.filter(x => x != undefined) : this.state.legislationList;
        let sessionCode = this.props.nav.session;

        const { isProfilePage } = this.props;

        return (
            <div className="homepage-links">
                {this.state.showPopup &&
                    <div className='popup'>
                        <div className='popup-inner'>
                            <p style={{ fontSize: '16px' }}>Are you sure you want to delete? Once a Watchlist is deleted it cannot be restored.</p>
                            <div className="inline-list">
                                <button className="button danger" disabled={this.state.deletingWatchlists} onClick={this.deleteWatchlists}>Yes</button>
                                <button className="button secondary" disabled={this.state.deletingWatchlists} onClick={this.togglePopup}>No</button>
                            </div>
                        </div>
                    </div>
                }
                {!isProfilePage && <h2>{this.state.viewCrossReferenceList ? "Your Lobbyist-in-a-Box Watchlists: Bill Cross Reference" : "Your Lobbyist-in-a-Box Watchlists"}</h2>}
                {this.state.loadingCollections ? <div className="spinner" /> :
                    <React.Fragment>
                        <div className="dlas-forms">
                            {!this.state.viewCrossReferenceList
                                ? <React.Fragment>
                                    <label htmlFor="sr-watchlist-find" className="screen-reader-only">Find a watchlist by name</label>
                                    <input
                                        id="sr-watchlist-find"
                                        type="text"
                                        placeholder="Find a watchlist"
                                        value={this.state.filteredWatchlistsValue}
                                        onChange={this.filterWatchlist}
                                    />
                                </React.Fragment>
                                : <React.Fragment>
                                    <label htmlFor="sr-bill-find" className="screen-reader-only">Find a bill by bill number or description</label>
                                    <input
                                        id="sr-bill-find"
                                        type="text"
                                        placeholder="Find a bill"
                                        value={this.state.filteredBillsValue}
                                        onChange={this.filterBills}
                                    />
                                </React.Fragment>
                            }
                            {this.state.legislationList.length > 0 && <button className="button primary float-right" type="button" onClick={() => this.toggleCrossReferenceList()}>{this.state.viewCrossReferenceList ? "View Watchlists" : "View Bill Cross Reference List"}</button>}
                        </div>
                        <br />
                        <div className="flex-row">
                            {!this.state.viewCrossReferenceList ? <button className="button primary float-right" type="button" disabled={!watchlists.find(w => w.checked) || this.state.deletingWatchlists} onClick={this.togglePopup}>{this.state.deletingWatchlists ? watchlists.filter(w => w.checked).length > 1 ? "Deleting Watchlists" : "Deleting Watchlist" : watchlists.filter(w => w.checked).length > 1 ? "Delete Watchlists" : "Delete Watchlist"}</button> : <span />}
                            {<button className="button primary float-right" type="button" onClick={() => this.toggleExpandAll(this.state.viewCrossReferenceList ? true : false)}>{this.state.viewCrossReferenceList ? this.state.expandAllBills ? "Collapse All" : "Expand All" : this.state.expandAllWatchlists ? "Collapse All" : "Expand All"}</button>}
                        </div>
                        <br />
                        <div className="quick-table">
                            {this.state.viewCrossReferenceList
                                ?
                                <div className="inner-grid small-one-util table-headers">
                                    <label onClick={() => this.sortByBillNumber()} style={{ cursor: "pointer" }}>Bill Number</label>
                                    <label>Bill Description</label>
                                    <label>Show Watchlists</label>
                                </div>
                                :
                                <div className="inner-grid one-two-one table-headers">
                                    <label />
                                    <label onClick={() => this.sortByName()} style={{ cursor: "pointer" }}>Watchlist Name</label>
                                    <label onClick={() => this.sortByDesc()} style={{ cursor: "pointer" }}>Description</label>
                                    <label onClick={() => this.sortByDate()} style={{ cursor: "pointer" }}>Last Modified</label>
                                    <label>Show Bills</label>
                                </div>
                            }
                            {!this.state.viewCrossReferenceList ?
                                <div className="table-body">
                                    {watchlists.map((w, i) => {
                                        return (
                                            <div className="table-row" key={i}>
                                                {w && <div className="inner-grid one-two-one">
                                                    <input id="sr-select-all" disabled={this.state.deletingWatchlists || (w.IsPaid && !Boolean(this.props.login.userClaims.claims.find(claim => claim.Scope === "Paid" && claim.Resource === "LegislationCollections" && claim.RoleName === "PaidLegislationCollectionsAuthor")) && !Boolean(this.props.login.userClaims.resources.find(resource => resource === "PaidLegislationCollectionsAuthor" || resource === "All")))} checked={w.checked} onChange={() => this.toggleWatchlist(i)} type="checkbox" style={{ verticalAlign: 'sub' }} />
                                                    {(w.IsPaid && !Boolean(this.props.login.userClaims.claims.find(claim => claim.Scope === "Paid" && claim.Resource === "LegislationCollections" && claim.RoleName === "PaidLegislationCollectionsAuthor")) && !Boolean(this.props.login.userClaims.resources.find(resource => resource === "PaidLegislationCollectionsAuthor" || resource === "All"))) ? <span className="txt-greyed">{w.Name} (PAID)</span> : <Link to={`/bill-search?collection=${w.WatchListID}`}>{w.Name}</Link>}
                                                    <label className={(w.IsPaid && !Boolean(this.props.login.userClaims.claims.find(claim => claim.Scope === "Paid" && claim.Resource === "LegislationCollections" && claim.RoleName === "PaidLegislationCollectionsAuthor")) && !Boolean(this.props.login.userClaims.resources.find(resource => resource === "PaidLegislationCollectionsAuthor" || resource === "All"))) ? "txt-greyed" : ""}>{w.Description}</label>
                                                    <label className={(w.IsPaid && !Boolean(this.props.login.userClaims.claims.find(claim => claim.Scope === "Paid" && claim.Resource === "LegislationCollections" && claim.RoleName === "PaidLegislationCollectionsAuthor")) && !Boolean(this.props.login.userClaims.resources.find(resource => resource === "PaidLegislationCollectionsAuthor" || resource === "All"))) ? "txt-greyed" : ""}>{moment(w.ModificationDate).format("MM/DD/YYYY hh:mm A")}</label>
                                                    {(w.IsPaid && !Boolean(this.props.login.userClaims.claims.find(claim => claim.Scope === "Paid" && claim.Resource === "LegislationCollections" && claim.RoleName === "PaidLegislationCollectionsAuthor")) && !Boolean(this.props.login.userClaims.resources.find(resource => resource === "PaidLegislationCollectionsAuthor" || resource === "All"))) ? null : w.WatchListLegislation.length > 0 && <button style={{ marginLeft: "20px" }} type="button" className={(this.state.expandedWatchlists.find(x => x.WatchListID === w.WatchListID) == undefined || !this.state.expandedWatchlists.find(x => x.WatchListID === w.WatchListID).expanded ? "arrow-down" : "arrow-up") + " float-right"} onClick={() => this.toggleBillList(w.WatchListID)}></button>}
                                                </div>
                                                }
                                                {this.state.expandedWatchlists.find(x => x.WatchListID === w.WatchListID) != undefined && this.state.expandedWatchlists.find(x => x.WatchListID === w.WatchListID).expanded &&
                                                    <ul>
                                                        {w.WatchListLegislation.map((l, i) => {
                                                            return (
                                                                <li className="inner-grid two-col-sidebar-tiny-backwards" style={{ marginBottom: '5px' }}>
                                                                    <a target="_blank" key={i} className="watchlist-ref-link" style={{ textAlign: 'right' }} href={"/bill-details/" + sessionCode + "/" + l.LegislationNumber}>{l.LegislationNumber}</a>
                                                                    <span>{l.Description}</span>
                                                                </li>
                                                            )
                                                        })}
                                                    </ul>
                                                }
                                            </div>
                                        )
                                    })}
                                </div>
                                :
                                <div className="table-body">
                                    {bills.map((l, i) => {
                                        return (
                                            <div className="table-row" key={i}>
                                                <div className="inner-grid small-one-util" key={i}>
                                                    {<Link to={"/bill-details/" + sessionCode + "/" + l.LegislationNumber}>{l.LegislationNumber}</Link>}
                                                    <label>{renderHTML(l.Description)}</label>
                                                    {l.watchlists.length > 0 && <button style={{ marginLeft: "40px" }} type="button" className={(this.state.expandedBills.find(x => x.LegislationNumber === l.LegislationNumber) != undefined && !this.state.expandedBills.find(x => x.LegislationNumber === l.LegislationNumber).expanded ? "arrow-down" : "arrow-up") + " float-right"} onClick={() => this.toggleWatchlistList(l.LegislationNumber)}></button>}
                                                </div>
                                                {this.state.expandedBills.find(x => x.LegislationNumber === l.LegislationNumber) != undefined && this.state.expandedBills.find(x => x.LegislationNumber === l.LegislationNumber).expanded &&
                                                    <ul>
                                                        {l.watchlists.map((w, i) => {
                                                            return (
                                                                <li>
                                                                    <a target="_blank" key={i} className="watchlist-ref-link" href={"/bill-search?collection=" + w.WatchListID}>{w.Name}</a> - <span>{w.Description}</span>
                                                                </li>
                                                            )
                                                        })}
                                                    </ul>
                                                }
                                            </div>
                                        )
                                    })}
                                </div>
                            }
                        </div>
                        <br />
                        {!isProfilePage && <Link to={"/bill-search"}>Return to Bill Search</Link>}
                    </React.Fragment>}
            </div >
        )
    }
}

export default connect(
    (state) => {
        const { login, nav, collection, session } = state;
        return {
            login,
            nav,
            collection,
            session
        }
    },
    (dispatch) => {
        return {
            actions: bindActionCreators(Object.assign({}, authActionCreators, navActionCreators, collectionActionCreators, sessionActionCreators), dispatch)
        }
    }
)(WatchlistsComponent)