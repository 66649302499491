import React from 'react';
import { Link } from 'react-router-dom';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { sessionActionCreators } from '../../stores/lis-session-store';

import { cancelRequest } from '../../services/request.service';

class SessionInformationComponent extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            data: []
        };
    }

    //When component has mounted, make the call to list the sessions
    componentDidMount() {
        this.props.getSessionList().then(() =>
            this.setState({
                data: this.props.sessionList
            })
        )
    }

    componentWillUnmount() {
        cancelRequest();
    }

    render() {
        const { data } = this.state;
        const listSessions = data.map((session, i) =>
            <li key={i}>
                <Link to={`/session-details/${session.SessionID}`}>{session.SessionYear} {session.DisplayName}</Link>
            </li>
        );
        return (
            <div className="public-list inner-grid three-and-one">
                <div>
                </div>
                <div>
                    <h3>Other Sessions</h3>
                    <ul>
                        {listSessions}
                    </ul>
                </div>
            </div>
            )
    }
}

const PublicSessionInformation = connect(
    state => state.session,
    dispatch => bindActionCreators(sessionActionCreators, dispatch)
)(SessionInformationComponent)

export default PublicSessionInformation;
