import React from 'react';
import ReactTable from "react-table";
import { Link } from 'react-router-dom';
import { bindActionCreators } from 'redux';
import * as Fields from '../../../lis-shared/lis-layout/components/lis-forms-component';
import { sessionActionCreators } from '../../../stores/lis-session-store';
import { calendarActionCreators } from '../../../stores/lis-calendar-store';
import { authActionCreators } from '../../../stores/lis-auth-store';
import { connect } from 'react-redux';
import moment from 'moment';
import DatePicker from "react-datepicker";
import { cancelRequest } from '../../../services/request.service';

class CalendarGrid extends React.Component {
    constructor(props) {
        super(props);
        let nextBusinessDay;
        if (moment().day() === 5 || moment().day() === 6) { // friday or saturday, show monday
            nextBusinessDay = moment().add(8 - moment().day(), 'days')
        } else { // other days, show next day
            nextBusinessDay = moment().add(1, 'days')
        }
        this.state = {
            isLoaded: true,
            selectedSession: '',
            sessionOptions: [],
            selectedChamber: '',
            chamberOptions: [
                { label: "House", value: "H" },
                { label: "Senate", value: "S" }
            ],
            calendarDate: nextBusinessDay,
            calendarList: [],
            showCreateSuccess: false,
            createFailureMessage: '',
            isAdding: false,
        }

        this.getCalendars = this.getCalendars.bind(this);
        this.createCalendar = this.createCalendar.bind(this);

        this.handleDateChange = this.handleDateChange.bind(this);
        this.handleSessionSelectChange = this.handleSessionSelectChange.bind(this);
        this.handleChamberChange = this.handleChamberChange.bind(this);
        this.deleteCalendar = this.deleteCalendar.bind(this);
    }

    handleSessionSelectChange(value) {
        //The session selectors value has been changed. 
        this.props.handleDropdownChange('selectedSession', value, () => this.getCalendars());
    }

    handleChamberChange(value) {
        //The chamber selectors value has been changed. 
        this.props.handleDropdownChange('selectedChamber', value, () => this.getCalendars());
    }

    handleDateChange(value) {
        //Used when inserting a new calendar
        this.setState({
            calendarDate: moment(value)
        });
    }

    createCalendar() {
        if (this.props.selectedSession && this.props.selectedChamber) {
            this.setState({
                isAdding: true
            }, () => {
                //See if there are any other calendars with the selected date
                const calendarWithSameDate = this.state.calendarList.filter(calendar => moment(this.state.calendarDate).isSame(moment(calendar.CalendarDate), 'day'));
                let calendarNumber = calendarWithSameDate.length + 1;
                let createCalendarObj = {
                    Calendars: [{
                        CalendarDate: this.state.calendarDate.format("MM/DD/YYYY"),
                        IsPublic: false,
                        CalendarType: "Chamber",
                        Comments: '',
                        Description: 'Calendar',
                        CalendarTypeID: 1,
                        SessionID: this.props.selectedSession.SessionID,
                        VoteRoomID: 1,
                        ChamberCode: this.props.selectedChamber.value,
                        CalendarNumber: calendarNumber
                    }]
                };
                this.props.actions.createCalendar(createCalendarObj)
                    .then(() => {
                        this.setState({
                            showCreateSuccess: true,
                            createFailureMessage: ''
                        });
                        this.getCalendars()
                        setTimeout(() => {
                            this.setState({
                                showCreateSuccess: false,
                                createFailureMessage: '',
                                isAdding: false
                            })
                        }, 3000);
                    }).catch(err => {
                        if (err === 'Aborted') {
                            return;
                        }
                        this.setState({
                            showCreateSuccess: false,
                            createFailureMessage: 'Calendar Creation Failed',
                        });
                        this.getCalendars()
                        setTimeout(() => {
                            this.setState({
                                showCreateSuccess: false,
                                createFailureMessage: '',
                                isAdding: false
                            })
                        }, 3000);
                    });
            });
        } else {
            this.setState({
                createFailureMessage: "Please select a session and chamber"
            });
            setTimeout(() => {
                this.setState({
                    createFailureMessage: '',
                });
            }, 3000);
        }
    }

    deleteCalendar(rowIndex) {
        this.setState({
            calendarBeingDeleted: rowIndex
        });
        // Before being able to delete the calendar we need to get the mod dates. So get the calendar
        this.props.actions.getCalendarById('?calendarId=' + this.state.calendarList[rowIndex].CalendarID, true)
            .then(() => {
                let calendarData = this.props.calendar.calendarGet;
                // Now that we have the calendar data add a deletion date then save it
                calendarData.DeletionDate = moment();
                return this.props.actions.saveCalendar({ Calendars: [calendarData] })
            }).then(() => {
                this.setState({
                    showDeleteSuccess: true,
                    calendarBeingDeleted: -1
                });
                this.getCalendars();
                setTimeout(() => {
                    this.setState({
                        showDeleteSuccess: false
                    })
                }, 3000);
            }).catch(err => {
                if (err === 'Aborted') {
                    return;
                }
                this.setState({
                    calendarBeingDeleted: -1,
                    deleteFailureMessage: 'Calendar failed to be deleted'
                });
                console.log(err)
                setTimeout(() => {
                    this.setState({
                        deleteFailureMessage: '',
                    });
                }, 3000);
            });
    }

    componentDidMount() {
        this.props.actions.getSessionShallowList('isActive=true').then(() => {
            if (this.props.session.sessionShallowList !== undefined) {
                let selectedSession = this.props.selectedSession;
                let selectedChamber = this.props.selectedChamber;
                //Format data for dropdown
                let sessionList = [...this.props.session.sessionShallowList];
                sessionList.forEach(session => {
                    session.label = session.SessionYear + ' ' + session.DisplayName;
                    session.value = session.SessionID;
                    if (session.IsDefault && !selectedSession) {
                        selectedSession = session;
                    }
                });
                //set the default chamber to house or senate depending on the user's role
                let disableChamberSelector = false;

                if (!this.props.login.userClaims.claims.find(x => x.RoleName === "Admin")) {
                    //Find if the user is a house calendar editor, if so select house, otherwise select senate, and disable the chamber selector
                    selectedChamber = this.props.login.userClaims.claims.find(x => x.RoleName === "HouseCalendarEditor") ? this.state.chamberOptions.find(chamber => chamber.label === "House") : this.state.chamberOptions.find(chamber => chamber.label === "Senate");
                    disableChamberSelector = true;
                }
                this.setState({
                    sessionOptions: sessionList,
                    disableChamberSelector: disableChamberSelector
                });
                this.props.handleDropdownChange('selectedSession', selectedSession, () => {
                    this.props.handleDropdownChange('selectedChamber', selectedChamber, () => this.getCalendars());
                });

            }
        });
    }

    componentWillUnmount() {
        cancelRequest();
    }

    getCalendars() {
        const { selectedSession, selectedChamber } = this.props;
        if (selectedSession && selectedChamber) {
            this.setState({
                isLoaded: false
            });
            //Get the calendars
            const calendarParams = "?isPublic=false&sessionID=" + selectedSession.SessionID + '&chamberCode=' + selectedChamber.value;
            //The second parameter lets the store know to use the partner request instead of the public
            this.props.actions.getCalendarList(calendarParams, true)
                .then(() => {
                    this.setState({
                        isLoaded: true,
                        calendarList: this.props.calendar.calendarList
                    });
                }).catch(err => {
                    if (err === 'Aborted') {
                        return
                    }
                    this.setState({
                        calendarListError: err.toString()
                    });
                });
        }
    }

    render() {
        return (
            <div>
                <div className="header-half">
                    <h1>Calendar Management</h1>
                    {this.props.login.userClaims.claims.find(c => c.RoleName === "Admin") &&
                        <div className="flex-row flex-vertical-center flex-end">
                            <a className="icon settings" href="/configurations-management/calendar" />
                        </div>
                    }
                </div>
                <p className="small-text message-error">{this.state.calendarListError}</p>
                <div className="toolbar inner-grid four">
                    <div>
                        <Fields.Dropdown
                            value={this.props.selectedChamber}
                            options={this.state.chamberOptions}
                            onChange={this.handleChamberChange}
                            isDisabled={this.state.disableChamberSelector}
                        />
                    </div>
                    <div>
                        <Fields.Dropdown
                            value={this.props.selectedSession}
                            options={this.state.sessionOptions}
                            id="sessionDropdown"
                            onChange={this.handleSessionSelectChange}
                        />
                    </div>
                </div>
                <ReactTable
                    resizable={false}
                    data={this.state.calendarList}
                    loading={!this.state.isLoaded}
                    ref={r => this.reactTable = r}
                    columns={[
                        {
                            Header: 'Calendars',
                            width: 240,
                            sortable: false,
                            filterable: false,
                            columns: [
                                {
                                    headerClassName: "calendar-datepicker-container",
                                    accessor: "CalendarDate",
                                    width: 240,
                                    sortable: false,
                                    Header: (
                                        <div className="dlas-forms datepicker-container">
                                            {!this.state.showCreateSuccess && !this.state.createFailureMessage && !this.state.showDeleteSuccess && !this.state.deleteFailureMessage &&
                                                <DatePicker
                                                    className="dnd-field"
                                                    selected={this.state.calendarDate}
                                                    onChange={this.handleDateChange}
                                                    placeholderText="Calendar Date"
                                                />
                                            }
                                            {this.state.showCreateSuccess &&
                                                <span className="insert-message">Calendar was created successfully!</span>
                                            }
                                            {this.state.createFailureMessage &&
                                                <span className="insert-message fail">{this.state.createFailureMessage}</span>
                                            }
                                            {this.state.showDeleteSuccess &&
                                                <span className="insert-message">Calendar was deleted successfully!</span>
                                            }
                                            {this.state.deleteFailureMessage &&
                                                <span className="insert-message fail">{this.state.deleteFailureMessage}</span>
                                            }
                                        </div>
                                    ),

                                    Cell: row => {
                                        return <span>{moment(row.value).format("MM/DD/YYYY")} {row.original.CalendarNumber > 1 && <React.Fragment>(Supp. {row.original.CalendarNumber - 1})</React.Fragment>}</span>
                                    }
                                }
                            ]
                        },
                        {
                            Header: 'Comments',
                            width: 'auto',
                            sortable: false,
                            filterable: false,
                            columns: [
                                {
                                    headerClassName: "docket-datepicker-container",
                                    accessor: "Comments",
                                    sortable: false,
                                    Header: (false),
                                    Cell: row => {
                                        return <span>{row.value}</span>
                                    }
                                }
                            ]
                        },
                        {
                            Header: 'Actions',
                            headerClassName: "submit-action",
                            sortable: false,
                            filterable: false,
                            columns: [
                                {
                                    Header: () => {
                                        return <span><button className="button" onClick={this.createCalendar} disabled={this.state.isAdding}>Create</button></span>
                                    },
                                    headerClassName: "header-action",
                                    accessor: "action",
                                    sortable: false,
                                    className: "action-column",
                                    width: 90,
                                    Cell: row => {
                                        if (this.state.calendarBeingDeleted === row.index) {
                                            return (
                                                <div className="small-spinner"></div>
                                            )
                                        }
                                        return (
                                            <div className="committee-action-column-two">
                                                <Link to={`/calendar-management/edit-calendar/${row.original.CalendarID}`} className="icon edit"></Link>
                                                {row.original.IsPublic ?
                                                    <Link target="_blank" to={`/session-details/${this.props.selectedSession.SessionCode}/calendar/${row.original.ReferenceNumber}`} className="icon book"></Link>
                                                    :
                                                    <span onClick={() => this.deleteCalendar(row.index)} className="icon delete"></span>}
                                            </div>
                                        );
                                    }
                                }
                            ]
                        }
                    ]}
                    defaultPageSize={15}
                    pageSizeOptions={[5, 10, 15, 20, 25, 50, 100]}
                    className="committee-table -striped -highlight"
                />
                <br />

            </div>
        );
    }
}

export default connect(
    (state) => {
        const { session, calendar, login } = state;
        return {
            session,
            calendar,
            login
        }
    },
    (dispatch) => {
        return {
            actions: bindActionCreators(Object.assign({}, sessionActionCreators, calendarActionCreators, authActionCreators), dispatch)
        }
    }
)(CalendarGrid)