import React from 'react';
import { Route } from 'react-router';
import SessionManagementGrid from './lis-session-grid';
import SessionManagementForm from './lis-session-form';


class SessionManagement extends React.Component {

    render() {       

        return (
            <div>
                <Route exact path={this.props.match.path} component={SessionManagementGrid} />
                <Route exact path={`${this.props.match.path}/edit-session`} component={SessionManagementForm} />
                <Route exact path={`${this.props.match.path}/edit-session/:id`} component={SessionManagementForm} />
            </div>
        )
    }
}

export default SessionManagement;