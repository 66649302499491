import React from "react";
import { Link } from "react-router-dom"
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { developersActionCreators } from "../../../stores/lis-developers-store";

class DevsPortal extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      docList: ''
    }
  }

  componentDidMount() {
    this.props.actions.getDocList("?IsPartner=false");
  }

  render() {
    return (
      <div>
        <h1>LIS Developers Portal</h1>
        <p>If you are a developer interested in obtaining data from LIS you may register for your own LIS API key.  An LIS API key will give you access to more than 40 APIs. To register for your own API key click the link below.</p>
        <Link to="/apiregistration" className="doc-link">Complete LIS API Key Registration here.</Link>
        <p>Below are links to the API documentation. We believe you will find the documentation transparent and convenient. To view the terms of service, <Link to="/tos" className="doc-link">click here</Link>. To view the documentation in Postman, <a href="https://documenter.getpostman.com/view/6722140/2sA3e4B9hg" className="doc-link">click here</a>.</p>
        <h3>Available LIS API Services</h3>
        {this.props.developers.docList.length > 0 && this.props.developers.docList.map((b, i) => {
          return (
            <React.Fragment key={i}><Link to={{ pathname: "/developers/" + b.ApiName }} className="doc-link">{b.ApiName}</Link><br /></React.Fragment>
          )
        })}
      </div>
    )
  }
}

export default connect(
  (state) => {
    const { developers } = state;
    return {
      developers
    }
  },
  (dispatch) => {
    return {
      actions: bindActionCreators(Object.assign({}, developersActionCreators), dispatch)
    }
  }
)(DevsPortal)