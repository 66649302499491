import React from "react";

class TermsOfService extends React.Component {
    constructor(props) {
        super(props)

    }

    render() {
        return (
            <div>
                <h1>LIS API Terms of Service</h1>
                <p>These Terms of Service (“Terms”) apply when you use any of its APIs (collectively, the “Services”) that links or refers to the Terms. These terms are a legal contract between you and the Division of Legislative Automated Systems (“DLAS,” “we” or “us”) so it is important that you review them carefully before using the Services. Your use of the Services indicates that you agree to follow and be bound by the Terms. If you do not agree to the Terms, do not access or use the Services.</p>
                <h3>1. General</h3>
                <p>We may change the Terms or modify any features of the Services at any time at our sole discretion. The most current version of the Terms can be viewed by clicking on the “Terms of Service” link at the top of the “For Developers” page. If you continue to use the Services after changes are posted you will be deemed to have accepted the change.</p>
                <h3>2. Compliance With Applicable Laws</h3>
                <p>As a condition of your access to and use of the Services, you agree that you will not use the Services for any purpose that is unlawful or prohibited by these Terms and that you will comply with all applicable laws and any conditions or restrictions imposed by these terms. The Services are offered for your personal and non-commercial use only, and you are prohibited from using, and are expressly not granted the right to use, the Services for any other purpose.</p>
                <h3>3. Privacy</h3>
                <p>By using the Services, you indicate that you understand the information collection, use, and disclosure practices described in the Privacy Policy.</p>
                <h3>4. Prohibited Conduct</h3>
                <p>You may not access or use, or attempt to access or use, the Services to take any action that could harm us or any third party, interfere with the operation of the Services, or use the Services in a manner that violates any laws. For example, and without limitation, you may not:</p>
                <ul>
                    <li>Make use of the contents of the Services in any manner that constitutes an infringement of our rights or the rights of other users or third parties.</li>
                    <li>Access parts of the Services to which you are not authorized, or attempt to circumvent any restrictions imposed on your use or access of the Services.</li>
                    <li>Take any action that imposes an unreasonable or disproportionately large load on our network or infrastructure.</li>
                    <li>Use any device, software, or routine to interfere or attempt to interfere with the proper working of the Services or any activity conducted on the Services.</li>
                    <li>Attempt to decipher, decompile, disassemble, or reverse-engineer any of the software comprising or in any way making up a part of the Services.</li>
                    <li>Engage in any other conduct that restricts or inhibits any other person from using the Services.</li>
                    <li>Take any action that violates or threatens our system or network security.</li>
                </ul>
                <p>Violations of these Terms may result in civil or criminal liability. We may investigate violations of these Terms and we may also work with law enforcement authorities to prosecute users who violate the Terms.</p>
                <h3>5. Registration and Security</h3>
                <p>To register for Services, you will create login credentials by providing an email address to us and by selecting a password. You also provide us certain information during the registration process. Each login is for a single user only. You are not allowed to share or disclose your login credentials with any other user or person. We may cancel or suspend your access to the Services if you share your credentials.</p>
                <p>You will be responsible for all usage and activity on your account, including use of the account by any third party authorized by you to use your login credentials, and for all charges for any goods or services. You are also responsible for all statements made or materials posted under your account, including liability for harm caused by such statements or materials. You may not transfer, sell, or otherwise assign your rights or obligations under these Terms.</p>
                <p>You must meet the minimum age of digital consent in your country (13 years old in the United States of America) to use the Services. Any fraudulent, abusive, or otherwise illegal activity may be grounds for termination of your account, at our sole discretion, and we may refer you to appropriate law enforcement agencies.</p>
                <p>DLAS maintains security standards and procedures regarding unauthorized access to customer information to prevent unauthorized removal or alteration of data. DLAS protects its records in accordance with its obligations as defined by applicable Virginia statutes, including, but not limited to, the Government Data Collection and Dissemination Practices Act, the Virginia Freedom of Information Act, and by any applicable U.S. federal laws.</p>
                <h3>6. Charges for Services</h3>
                <p>We may charge for access to portions of the Services or to the Services as a whole, and we reserve the right at any time to change the amount we charge for such access or subscriptions that include authorization to access the Services. In such an event, we will notify you in advance, and give you an opportunity to subscribe (or un-subscribe) to the Service(s). More information about any such subscriptions can be found on the appropriate locations of the Services. You shall pay all applicable taxes relating to use of the services, and recognize that any fees to third parties that may be required for you to receive the service (such as mobile data plans and text-message charges) are not included in the cost of the services.</p>
                <h3>7. Third-Party Content and Links to Third-Party Websites</h3>
                <p>The Services may contain links to websites that are not controlled, maintained, or regulated by DLAS or any organization affiliated with DLAS, and as such DLAS is NOT responsible for the content of those websites (“Linked Sites”). Use of information obtained from those web sites is voluntary, and reliance on that information should only be undertaken after an independent review of its accuracy. Reference at those web sites to any specific commercial product, process, or service by trade name, trademark, or otherwise DOES NOT constitute or imply endorsement, recommendation, or preference by DLAS. The agency tries to screen all sites included on its web site, but cannot control the content they possess.</p>
                <h3>8. Disclaimer of Warranty and Limitation of Liability</h3>
                <p>DLAS attempts to maintain the highest accuracy of content on its website. However, DLAS makes no claims, promises or guarantees about the accuracy, completeness, or adequacy of the contents of the Services and expressly disclaims all liability, whether direct or indirect, for any loss or damage arising out of use, reference to, or reliance on any information presented on or by the Services.</p>
                <p>DLAS makes no warranty, representations, express or implied (a) that the information provided through the Services will be free from error, omission, interruption, defect or delay in operation, or from technical inaccuracies or typographical errors, (b) that the services will be available at any particular time or location (c) that defects or errors in the services will be corrected, or (d) that the content on the services are free of viruses or other harmful components. Any information on the Services is subject to change without notice, and DLAS disclaims all responsibility for the Services. We reserve the right to correct any errors or omissions in the Services. Further, there is no warranty that the Services will meet your needs or requirements or the needs or requirements of any other person.</p>
                <h3>9. Termination</h3>
                <p>DLAS may terminate this agreement for any reason at any time. DLAS reserves the right, in its sole discretion, to restrict, suspend, or terminate your access to and use of the Service, with or without prior notice. Otherwise applicable sections of the Terms shall survive termination. In addition to any termination rights, we reserve the right to enforce and prosecute any violations of these Terms.</p>
            </div>
        )
    }
}

export default TermsOfService;