import React from 'react';
import Select from 'react-select';
import DatePicker from 'react-datepicker';
import { Link } from 'react-router-dom';
import { authActionCreators } from '../../stores/lis-auth-store';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

const singleStyles = {
    menuList: (base, state) => ({
        ...base,
        paddingTop: '0px',
    }),
    group: (base, state) => ({
        ...base,
        paddingTop: '0px',
    }),
    groupHeading: (base, state) => ({
        ...base,
        paddingTop: '7px',
        paddingBottom: '7px',
        marginBottom: '0px',
        position: 'sticky',
        top: '0px',
        backgroundColor: 'hsl(0,0%,100%)'
    }),
}

class BillCategories extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            selectedPatron: '',
            selectedCommittee: '',
            selectedSubject: '',
            selectedDate: null
        };
        this.handlePatronChange = this.handlePatronChange.bind(this);
        this.handleCommitteeChange = this.handleCommitteeChange.bind(this);
        this.handleSubjectChange = this.handleSubjectChange.bind(this);
        this.handleDateChange = this.handleDateChange.bind(this);
    }

    handlePatronChange(val) {
        this.setState({
            selectedPatron: val
        }, () => {
            //The next step is done after the state change to make sure the user sees what member was selected before going to the advanced search page
            const param = {
                selectedPatron: this.state.selectedPatron.MemberID,
                selectedSession: this.props.session.SessionID
            };
            const link = window.btoa(JSON.stringify(param));
            this.props.history.push(this.props.history.location.pathname + '?q=' + link);
        });
    }

    handleCommitteeChange(val) {
        this.setState({
            selectedCommittee: val
        }, () => {
            const param = {
                selectedCommittee: this.state.selectedCommittee.CommitteeID,
                selectedSession: this.props.session.SessionID,
                selectedBillStatusCategory: this.props.statusCategoriesList.find(category => category.Name === "In Committee").LegislationCategoryID
            };
            const link = window.btoa(JSON.stringify(param));
            this.props.history.push(this.props.history.location.pathname + '?q=' + link);
        });
    }

    handleSubjectChange(val) {
        this.setState({
            selectedSubject: val
        }, () => {
            //The next step is done after the state change to make sure the user sees what member was selected before going to the advanced search page
            const param = {
                selectedSubject: this.state.selectedSubject.SubjectIndexID,
                selectedSession: this.props.session.SessionID
            }
            const link = window.btoa(JSON.stringify(param));
            this.props.history.push(this.props.history.location.pathname + '?q=' + link);
        });
    }

    handleDateChange(val) {
        this.setState({
            selectedDate: val
        }, () => {
            const introDate = this.state.selectedDate.clone();
            introDate.hour(0);
            const param = {
                introductionDate: introDate.format(),
            };
            const link = window.btoa(JSON.stringify(param));
            this.props.history.push(this.props.history.location.pathname + '?q=' + link);
        });
    }

    makeLink(statusCategoryName, currentStatus, honorExcludeFailed) {
        let statusCategoryId;
        //try to find a status category with the name provided, if there is one (if there isn't one, it returns all legislation)
        if (statusCategoryName) {
            const statusCategory = this.props.statusCategoriesList.find(category => category.Name.toLowerCase() === statusCategoryName.toLowerCase());
            //if we do not find a status category, default to Id 99 - this makes it so no results are returned; clean, but also tells the user that something is wrong
            statusCategoryId = statusCategory ? statusCategory.LegislationCategoryID : 99;
        }

        const params = {
            selectedSession: this.props.session ? this.props.session.SessionID : '',
            selectedBillStatusCategory: statusCategoryId,
            currentStatus: currentStatus || false,
            excludeFailed: honorExcludeFailed ? this.props.excludeFailed : false,
            allLegislation: !statusCategoryName ? true : false,
            //This is used so if the user clicks a link and goes back to the bill categories screen and clicks the same link another search will be made
            //If this was omitted then the url wouldn't change and a new search wouldn't be made
            uniqueSearch: Math.random(),
        };
        const json = JSON.stringify(params);
        const query = window.btoa(json);
        return this.props.history.location.pathname + '?q=' + query;
    }

    render() {
        const { session } = this.props;
        const sessionHeader = <h2 id="search-selections-header">{session ? session.SessionYear + ' ' + session.DisplayName : ''}<span className="mobile-newline">:</span> Bills by Category</h2>;
        //The frequent link is a little different from the rest. MostFrequent needs to be true and there is not a status id
        const frequentParams = {
            selectedSession: session ? session.SessionID : '',
            mostFrequent: true,
            uniqueSearch: Math.random(),
            excludeFailed: this.props.excludeFailed
        }
        const frequentJson = JSON.stringify(frequentParams);
        const frequentQuery = window.btoa(frequentJson);
        const frequentLink = this.props.history.location.pathname + '?q=' + frequentQuery;

        return (
            <div className="lis-search-categories">
                <div>
                    {sessionHeader}
                    <hr className="faded-line" />
                </div>
                <div className="dlas-forms inner-grid four" style={{ marginBottom: '1em' }}>
                    <div>
                        <label htmlFor="sr-member" className={this.props.location.includes('bill-search') ? "txt-dark-blue" : ""}>Bills by Member</label>
                        <Select
                            id="sr-member"
                            options={this.props.patronList}
                            placeholder="Choose a Member"
                            value={this.state.selectedMember}
                            onChange={this.handlePatronChange}
                            className="grid-selector-dropdown"
                            classNamePrefix="grid-selector-dropdown"
                        />
                    </div>
                    <div>
                        <label htmlFor="sr-committee" className={this.props.location.includes('bill-search') ? "txt-dark-blue" : ""}>Bills by Committee</label>
                        <Select
                            id="sr-committee"
                            styles={singleStyles}
                            options={this.props.committeeList}
                            placeholder="Choose a Committee"
                            value={this.state.selectedCommittee}
                            onChange={this.handleCommitteeChange}
                            className="grid-selector-dropdown"
                            classNamePrefix="grid-selector-dropdown"
                        />
                    </div>
                    <div>
                        <label htmlFor="sr-subject" className={this.props.location.includes('bill-search') ? "txt-dark-blue" : ""}>Bills by Subject</label>
                        <Select
                            id="sr-subject"
                            options={this.props.subjectList}
                            placeholder="Choose a Subject"
                            value={this.state.selectedSubject}
                            onChange={this.handleSubjectChange}
                            className="grid-selector-dropdown"
                            classNamePrefix="grid-selector-dropdown"
                        />
                    </div>
                    <div>
                        <label htmlFor="sr-introduced" className={this.props.location.includes('bill-search') ? "txt-dark-blue" : ""}>Bills Introduced by Day</label>
                        <DatePicker
                            id="sr-introduced"
                            includeDates={this.props.introDateList}
                            selected={this.state.selectedDate}
                            onChange={this.handleDateChange}
                        />
                    </div>
                </div>
                <hr className="faded-line" />
                {
                    this.props.isLoading ?
                        <div className="spinner center-spinner"></div>
                        :
                        <div>
                            <div className="inner-grid four">
                                <div className="homepage-links">
                                    <h3 className={this.props.location.includes('bill-search') ? "txt-dark-blue" : ""}>Introduced</h3>
                                    <div>
                                        <Link to={this.makeLink("", false, true)}>All legislation</Link>
                                    </div>
                                    <div>
                                        <Link to={frequentLink}>Most frequently accessed</Link>
                                    </div>
                                </div>
                                <div className="homepage-links">
                                    <h3 className={this.props.location.includes('bill-search') ? "txt-dark-blue" : ""}>Passed</h3>
                                    <div>
                                        <Link to={this.makeLink("Passed House", false, true)}>Passed the House</Link>
                                    </div>
                                    <div>
                                        <Link to={this.makeLink("Passed Senate", false, true)}>Passed the Senate</Link>
                                    </div>
                                    <div>
                                        <Link to={this.makeLink("Passed", false, true)}>Passed</Link>
                                    </div>
                                    <div>
                                        <Link to={this.makeLink("Enrolled", false, true)}>Enrolled</Link>
                                    </div>
                                    <div>
                                        <Link to={this.makeLink("Signed by Speaker & President", false, true)}>Signed by Speaker &amp; President</Link>
                                    </div>
                                </div>
                                <div className="homepage-links">
                                    <h3 className={this.props.location.includes('bill-search') ? "txt-dark-blue" : ""}>Sent to Conference</h3>
                                    <div>
                                        <Link to={this.makeLink("In Conference", true, false)}>In Conference</Link>
                                    </div>
                                    <div>
                                        <Link to={this.makeLink("Conference Report Agreed to", false, true)}>Conference Report Agreed to</Link>
                                    </div>
                                    <div>
                                        <Link to={this.makeLink("Failed in Conference", false, false)}>Failed in Conference</Link>
                                    </div>
                                </div>
                                <div className="homepage-links">
                                    <h3 className={this.props.location.includes('bill-search') ? "txt-dark-blue" : ""}>Governor</h3>
                                    <div>
                                        <Link to={this.makeLink("Governor's Recommendations", false, true)}>Governor's recommendations</Link>
                                    </div>
                                    <div>
                                        <Link to={this.makeLink("Governor's Recommendation Adopted", false, true)}>Governor's recommendation adopted</Link>
                                    </div>
                                    <div>
                                        <Link to={this.makeLink("Approved or Enacted", false, false)}>Approved or enacted</Link>
                                    </div>
                                    <div>
                                        <Link to={this.makeLink("Vetoed", false, false)}>Vetoed</Link>
                                    </div>
                                    <div>
                                        <Link to={this.makeLink("Acts of Assembly chapters", false, false)}>Acts of Assembly chapters</Link>
                                    </div>
                                </div>
                            </div>
                            <div className="inner-grid four">
                                <div className="homepage-links">
                                    <h3 className={this.props.location.includes('bill-search') ? "txt-dark-blue" : ""}>Failed/Awaiting Action</h3>
                                    <div>
                                        <Link to={this.makeLink("Failed", false, false)}>Failed</Link>
                                    </div>
                                    <div>
                                        <Link to={this.makeLink("Pending", true, false)}>Pending</Link>
                                    </div>
                                    <div>
                                        <Link to={this.makeLink("Continued to next session", true, true)}>Continued to next session</Link>
                                    </div>
                                    <div>
                                        <Link to={this.makeLink("Continued from last session", false, true)}>Continued from last session</Link>
                                    </div>
                                </div>
                                <div className="homepage-links">
                                    <h3 className={this.props.location.includes('bill-search') ? "txt-dark-blue" : ""}>Incorporated</h3>
                                    <div>
                                        <Link to={this.makeLink("Incorporated", false, true)}>Incorporated</Link>
                                    </div>
                                </div>
                                {this.props.location.includes('bill-search') && this.props.login.userClaims.roles.find((x) => x === "Admin") &&
                                    <div className="homepage-links">
                                        <h3 className={this.props.location.includes('bill-search') ? "txt-dark-blue" : ""}>Parallel</h3>
                                        <div>
                                            <Link to={this.makeLink("Parallel", false, true)}>Parallel</Link>
                                        </div>
                                    </div>
                                }
                            </div>
                        </div>
                }
            </div>
        )
    }
}

export default connect(
    (state) => {
        const { login } = state;
        return {
            login,
        }
    },
    (dispatch) => {
        return {
            actions: bindActionCreators(Object.assign({}, authActionCreators), dispatch)
        }
    }
)(BillCategories)
