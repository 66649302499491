import React from 'react';
import { Route } from 'react-router';
import CommitteeManagementGrid from './lis-committee-grid';
import CommitteeManagementForm from './lis-committee-form'; 


class CommitteeManagement extends React.Component {
    render() {
        return (
            <div>
                <Route exact path={this.props.match.path} component={CommitteeManagementGrid} />
                <Route path={`${this.props.match.path}/edit-committee/:committeeid`} component={CommitteeManagementForm} />
                <Route path={`${this.props.match.path}/edit-subcommittee/:committeeid/:subcommitteeid`} component={CommitteeManagementForm} />
            </div>
        )
    }
}

export default CommitteeManagement;