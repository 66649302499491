import { partnerRequest, publicRequest } from '../services/request.service'

const API_URL = window.env ? window.env.API_SERVER : '';
const NO_CONTENT = 2;
const REQUEST_FAILURE = 1;
const REQUEST_SUCCESS = 0;

const journalListRequest = 'JOURNAL_LIST_REQUEST';
const journalListSuccess = 'JOURNAL_LIST_SUCCESS';
const journalListFailure = 'JOURNAL_LIST_FAILURE';

const journalByIdRequest = 'JOURNAL_BY_ID_REQUEST';
const journalByIdSuccess = 'JOURNAL_BY_ID_SUCCESS';
const journalByIdFailure = 'JOURNAL_BY_ID_FAILURE';

const journalBuildSuccess = 'JOURNAL_BUILD_SUCCESS';
const journalBuildFailure = 'JOURNAL_BUILD_FAILURE';

const journalGenerationSuccess = 'JOURNAL_GENERATION_SUCCESS';
const journalGenerationFailure = 'JOURNAL_GENERATION_FAILURE';

const journalSaveSuccess = 'JOURNAL_SAVE_SUCCESS';
const journalSaveFailure = 'JOURNAL_SAVE_FAILURE';

const initialState = {
    journalList: [],
    journalMessage: '',
    journalById: [],
    journalByIdMessage: '',
    journalBuild: [],
    journalBuildError: '',
    journalGeneration: [],
    journalGenerationError: '',
    journalSave: [],
    journalSaveError: ''
};

export const journalActionCreators = {
    // GET JOURNAL LIST FOR JOURNAL MGMT GRID
    requestJournalList: () => ({
        type: journalListRequest,
        journalList: [],
        isFetching: true
    }),

    receiveJournalList: (list, message) => ({
        type: journalListSuccess,
        isFetching: false,
        journalList: list,
        journalMessage: message
    }),

    journalListError: (message) => ({
        type: journalListFailure,
        isFetching: false,
        journalList: [],
        journalMessage: message
    }),

    getJournals: (params) => {
        return dispatch => {
            dispatch(journalActionCreators.requestJournalList());

            let headers = {
                'content-type': "application/json; charset=utf-8"
            }
            return partnerRequest((API_URL || process.env.REACT_APP_JOURNAL_API_URL) + '/JournalFileGeneration/api/GetJournalData?', 'GET', headers, '', params)
                .then(result => {
                    if (result[0] === REQUEST_FAILURE) {
                        dispatch(journalActionCreators.journalListError(result[1]))
                        return Promise.reject(result[1])
                    } else if (result[0] === REQUEST_SUCCESS) {
                        // Dispatch the success action
                        dispatch(journalActionCreators.receiveJournalList(result[1].Journals))
                    } else if (result[0] === NO_CONTENT) {
                        dispatch(journalActionCreators.receiveJournalList([]));
                    } else if (result.name === 'AbortError') {
                        return Promise.reject("Aborted");
                    }
                }).catch(err => Promise.reject(err))
        }
    },

    // GET JOURNAL BY ID FOR JOURNAL FORM
    requestJournalById: () => ({
        type: journalByIdRequest,
        journalById: [],
        isFetching: true
    }),

    receiveJournalById: (list, message) => ({
        type: journalByIdSuccess,
        isFetching: false,
        journalById: list,
        journalByIdMessage: message
    }),

    journalByIdError: (message) => ({
        type: journalByIdFailure,
        isFetching: false,
        journalById: [],
        journalByIdMessage: message
    }),

    getJournalById: (params) => {
        return dispatch => {
            dispatch(journalActionCreators.requestJournalById());

            let headers = {
                'content-type': "application/json; charset=utf-8"
            }
            return partnerRequest((API_URL || process.env.REACT_APP_JOURNAL_API_URL) + '/JournalFileGeneration/api/GetJournalDataById?', 'GET', headers, '', params)
                .then(result => {
                    if (result[0] === REQUEST_FAILURE) {
                        dispatch(journalActionCreators.journalByIdError(result[1]))
                        return Promise.reject(result[1])
                    } else if (result[0] === REQUEST_SUCCESS) {
                        // Dispatch the success action
                        dispatch(journalActionCreators.receiveJournalById(result[1].Journals))
                    } else if (result[0] === NO_CONTENT) {
                        dispatch(journalActionCreators.receiveJournalById([]));
                    } else if (result.name === 'AbortError') {
                        return Promise.reject("Aborted");
                    }
                }).catch(err => Promise.reject(err))
        }
    },

    //BUILD JOURNAL DAY
    receiveJournalBuild: (list) => ({
        type: journalBuildSuccess,
        journalBuild: list[0],
    }),

    journalBuildError: (message) => ({
        type: journalBuildFailure,
        journalBuild: [],
        journalBuildError: message
    }),

    buildJournal: (params) => {
        return dispatch => {
            let headers = {
                'content-type': "application/json; charset=utf-8"
            }
            return partnerRequest((API_URL || process.env.REACT_APP_JOURNAL_API_URL) + '/JournalFileGeneration/api/BuildSenateJournalDay', 'GET', headers, '', params)
                .then(result => {
                    if (result[0] === REQUEST_FAILURE) {
                        dispatch(journalActionCreators.journalBuildError(result[1]))
                        return Promise.reject(result[1])
                    } else if (result[0] === REQUEST_SUCCESS) {
                        // Dispatch the success action
                        dispatch(journalActionCreators.receiveJournalBuild(result[1]))
                    } else if (result[0] === NO_CONTENT) {
                        dispatch(journalActionCreators.journalBuildError('Journal Data not available'))
                        return Promise.reject('No Journal Data')
                    }
                }).catch(err => Promise.reject(err))
        }
    },

    //GENERATE JOURNAL DAY
    receiveJournalGeneration: (bytes) => ({
        type: journalGenerationSuccess,
        journalGeneration: bytes,
    }),

    journalGenerationError: (message) => ({
        type: journalGenerationFailure,
        journalGeneration: [],
        journalGenerationError: message
    }),

    generateJournal: (params) => {
        return dispatch => {
            let config = {
                method: "GET",
                headers: {
                    "Content-Type": "application/json; charset=utf-8",
                    "authorization": "Bearer " + localStorage.getItem('LIS_ID_TOKEN'),
                    "WebAPIKey": process.env.REACT_APP_API_KEY
                }
            }
            let url = (API_URL || process.env.REACT_APP_JOURNAL_API_URL) + '/JournalFileGeneration/api/GenerateSenateJournalDay' + params;
            return fetch(url, config)
                .then(async result => {
                    if (!result) {
                        dispatch(journalActionCreators.journalGenerationError("File not generated"));
                    } else if (result.status === 400) {
                        await result.body.getReader().read().then(({ value }) => {
                            const error = JSON.parse(Buffer.from(value.buffer).toString())
                            dispatch(journalActionCreators.journalGenerationError(error.Name || "Unknown Error"));
                        })
                    } else if (result.name === 'AbortError') {
                        return Promise.reject("Aborted");
                    } else {
                        const responseText = await result.text();
                        dispatch(journalActionCreators.receiveJournalGeneration(responseText));
                    }
                }).catch(err => Promise.reject(err));
        }
    },

    //SAVE JOURNAL
    receiveJournalSave: (list) => ({
        type: journalSaveSuccess,
        journalSave: list[0],
    }),

    journalSaveError: (message) => ({
        type: journalSaveFailure,
        journalSave: [],
        journalSaveError: message
    }),

    saveJournal: (body) => {
        return dispatch => {
            let headers = {
                'content-type': "application/json; charset=utf-8"
            }
            return partnerRequest((API_URL || process.env.REACT_APP_JOURNAL_API_URL) + '/JournalFileGeneration/api/SaveJournalData', 'POST', headers, body, '')
                .then(result => {
                    if (result[0] === REQUEST_FAILURE) {
                        dispatch(journalActionCreators.journalSaveError(result[1]))
                        return Promise.reject(result[1])
                    } else if (result[0] === REQUEST_SUCCESS) {
                        // Dispatch the success action
                        dispatch(journalActionCreators.receiveJournalSave(result[1]))
                    } else if (result[0] === NO_CONTENT) {
                        dispatch(journalActionCreators.journalSaveError('Journal Data not available'))
                        return Promise.reject('No Journal Data')
                    }
                }).catch(err => Promise.reject(err))
        }
    }
}

export const reducer = (state, action) => {

    state = state || initialState;

    switch (action.type) {

        case journalListRequest:
            return Object.assign({}, state, {
                isFetching: true
            })
        case journalListSuccess:
            return Object.assign({}, state, {
                isFetching: false,
                journalList: action.journalList,
                journalMessage: ''
            })
        case journalListFailure:
            return Object.assign({}, state, {
                isFetching: false,
                journalList: action.journalList,
                journalMessage: action.journalMessage
            })
        case journalByIdRequest:
            return Object.assign({}, state, {
                isFetching: true
            })
        case journalByIdSuccess:
            return Object.assign({}, state, {
                isFetching: false,
                journalById: action.journalById,
                journalByIdMessage: ''
            })
        case journalByIdFailure:
            return Object.assign({}, state, {
                isFetching: false,
                journalById: action.journalById,
                journalByIdMessage: action.journalByIdMessage
            })
        case journalBuildSuccess:
            return Object.assign({}, state, {
                journalBuild: action.journalBuild,
                journalBuildError: ''
            })
        case journalBuildFailure:
            return Object.assign({}, state, {
                journalBuildError: action.journalBuildError
            })
        case journalGenerationSuccess:
            return Object.assign({}, state, {
                journalGeneration: action.journalGeneration,
                journalGenerationError: ''
            })
        case journalGenerationFailure:
            return Object.assign({}, state, {
                journalGenerationError: action.journalGenerationError
            })
        case journalSaveSuccess:
            return Object.assign({}, state, {
                journalSave: action.journalSave,
                journalSaveError: ''
            })
        case journalSaveFailure:
            return Object.assign({}, state, {
                journalSaveError: action.journalSaveError
            })
        default:
            return state
    }
};