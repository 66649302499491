import { LogLevel } from "@azure/msal-browser";

export const msalConfig = {
    auth: {
        clientId: ["dev", "test", "qa"].includes(window.env.APP_ENV.toLowerCase()) ? "d0952a15-c248-42cb-a3d5-31a104e6fe94" : "370fbf66-8bed-4f65-8938-9d97d9ff8e07",
        authority: "https://login.microsoftonline.com/house.virginia.gov",
        redirectUri: ["dev", "test", "qa"].includes(window.env.APP_ENV.toLowerCase()) ? "https://" + window.env.APP_ENV + ".lis.virginia.gov" : "https://lis.virginia.gov",
        postLogoutRedirectUri: ["dev", "test", "qa"].includes(window.env.APP_ENV.toLowerCase()) ? "https://" + window.env.APP_ENV + ".lis.virginia.gov" : "https://lis.virginia.gov"
    },
    cache: {
        cacheLocation: "localStorage",
        storeAuthStateInCookie: false,
    },
    system: {
        loggerOptions: {
            loggerCallback: (level, message, containsPii) => {
                if (containsPii) {
                    return;
                }
                switch (level) {
                    case LogLevel.Error:
                        console.error(message);
                        return;
                    case LogLevel.Info:
                        console.info(message);
                        return;
                    case LogLevel.Verbose:
                        console.debug(message);
                        return;
                    case LogLevel.Warning:
                        console.warn(message);
                        return;
                    default:
                        return;
                }
            } 
        }
    }
};

export const loginRequest = {
    scopes: ["User.Read"]
};

export const logoutRequest = {
    scopes: ["User.Read"]
};

export const graphConfig = {
    graphMeEndpoint: "https://graph.microsoft.com/v1.0/me"
};