import { partnerRequest, publicRequest } from '../services/request.service'

const API_URL = window.env ? window.env.API_SERVER : '';
const NO_CONTENT = 2;
const REQUEST_FAILURE = 1;
const REQUEST_SUCCESS = 0;

const districtListSuccess = "GET_DISTRICTS_SUCCESS";
const districtListFailure = "GET_DISTRICTS_FAILURE";

const memberStatusesSuccess = "GET_STATUSES_SUCCESS";
const memberStatusesFailure = "GET_STATUSES_FAILURE";

const partyCodeListSuccess = "GET_PARTIES_SUCCESS";
const partyCodeListFailure = "GET_PARTIES_FAILURE";

const memberNumbersRequest = "GET_ALL_MEMBERS_REQUEST";
const memberNumbersSuccess = "GET_ALL_MEMBERS_SUCCESS";
const memberNumbersFailure = "GET_ALL_MEMBERS_FAILURE";

const memberListRequest = "GET_MEMBER_LIST_REQUEST";
const memberListSuccess = "GET_MEMBER_LIST_SUCCESS";
const memberListFailure = "GET_MEMBER_LIST_FAILURE";

const memberContactRequest = "GET_MEMBER_CONTACT_REQUEST";
const memberContactSuccess = "GET_MEMBER_CONTACT_SUCCESS";
const memberContactFailure = "GET_MEMBER_CONTACT_FAILURE";

const memberListContactRequest = "GET_MEMBER_LIST_CONTACT_REQUEST";
const memberListContactSuccess = "GET_MEMBER_LIST_CONTACT_SUCCESS";
const memberListContactFailure = "GET_MEMBER_LIST_CONTACT_FAILURE";

const memberByIdSuccess = "GET_MEMBER_BY_ID_SUCCESS";
const memberByIdFailure = "GET_MEMBER_BY_ID_FAILURE";

const membersByCommitteeRequest = "GET_MEMBERS_BY_COMMITTEE_REQUEST";
const membersByCommitteeSuccess = "GET_MEMBERS_BY_COMMITTEE_SUCCESS";
const membersByCommitteeFailure = "GET_MEMBERS_BY_COMMITTEE_FAILURE";

const committeeMemberRolesRequest = "GET_COMMITTEE_MEMBER_ROLES_REQUEST";
const committeeMemberRolesSuccess = "GET_COMMITTEE_MEMBER_ROLES_SUCCESS";
const committeeMemberRolesFailure = "GET_COMMITTEE_MEMBER_ROLES_FAILURE";

const memberCommitteeSaveSuccess = 'MEMBER_COMMITTEE_SAVE_SUCCESS';
const memberCommitteeSaveFailure = 'MEMBER_COMMITTEE_SAVE_FAILURE';

const memberCreateSuccess = 'MEMBER_CREATE_SUCCESS';
const memberCreateFailure = 'MEMBER_CREATE_FAILURE';

const memberSaveSuccess = 'MEMBER_SAVE_SUCCESS';
const memberSaveFailure = 'MEMBER_SAVE_FAILURE';

const memberLegislationSuccess = 'MEMBER_LEGISLATION_SUCCESS';
const memberLegislationFailure = 'MEMBER_LEGISLATION_FAILURE';

const memberVotesSuccess = 'MEMBER_VOTES_SUCCESS';
const memberVotesFailure = 'MEMBER_VOTES_FAILURE';

const memberConferenceBillsSuccess = 'MEMBER_CONFERENCE_BILLS_SUCCESS';
const memberConferenceBillsFailure = 'MEMBER_CONFERENCE_BILLS_FAILURE';

const initialState = {
    districtList: [],
    districtListError: '',
    memberStatuses: [],
    memberStatusesError: '',
    partyCodeList: [],
    partyCodeListError: '',
    memberList: [],
    memberData: [],
    memberContactData: [],
    memberListContactData: [],
    committeeHouseRoles: [],
    committeeSenateRoles: [],
    membersByCommittee: [],
    membersByCommitteeError: '',
    member: '',
    memberError: '',
    memberCommitteeSave: '',
    memberCommitteeSaveError: '',
    memberCreate: '',
    memberCreateError: '',
    memberSave: '',
    memberSaveError: null,
    memberLegislation: [],
    memberLegislationError: '',
    memberVotes: '',
    memberVotesError: '',
    memberConferenceBills: [],
    memberConferenceBillsError: '',
    memberListContactError: ''
};

export const memberActionCreators = {

    receiveDistricts: (responseObj) => ({
        type: districtListSuccess,
        districtList: responseObj
    }),

    districtListError: message => ({
        type: districtListFailure,
        districtListError: message
    }),

    getDistricts: () => {
        return dispatch => {
            let headers = {
                'content-type': "application/json; charset=utf-8"
            }
            return publicRequest((API_URL || process.env.REACT_APP_MEMBERS_API_URL) + '/Member/api/getdistrictreferencesasync', 'GET', headers, '', '')
                .then(result => {
                    if (result[0] === REQUEST_FAILURE) {
                        dispatch(memberActionCreators.districtListError(result[1]))
                        return Promise.reject(result[1])
                    } else if (result[0] === REQUEST_SUCCESS) {
                        dispatch(memberActionCreators.receiveDistricts(result[1].Districts))
                    } else if (result[0] === NO_CONTENT) {
                        dispatch(memberActionCreators.districtListError('No Content'))
                    } else if (result.name === 'AbortError') {
                        return Promise.reject("Aborted");
                    }
                }).catch(err => Promise.reject(err));
        }

    },

    receivePartyCodeList: (responseObj) => ({
        type: partyCodeListSuccess,
        partyCodeList: responseObj
    }),

    partyCodeListError: message => ({
        type: partyCodeListFailure,
        partyCodeListError: message
    }),

    getPartyCodeList: () => {
        return dispatch => {
            let headers = {
                'content-type': "application/json; charset=utf-8"
            }
            return publicRequest((API_URL || process.env.REACT_APP_MEMBERS_API_URL) + '/Member/api/getpartyreferencesasync', 'GET', headers, '', '')
                .then(result => {
                    if (result[0] === REQUEST_FAILURE) {
                        dispatch(memberActionCreators.partyCodeListError(result[1]))
                        return Promise.reject(result[1])
                    } else if (result[0] === REQUEST_SUCCESS) {
                        dispatch(memberActionCreators.receivePartyCodeList(result[1].Parties))
                    } else if (result[0] === NO_CONTENT) {
                        dispatch(memberActionCreators.partyCodeListError('No Content'))
                    } else if (result.name === 'AbortError') {
                        return Promise.reject("Aborted");
                    }
                }).catch(err => Promise.reject(err));
        }

    },

    receiveMemberStatuses: (responseObj) => ({
        type: memberStatusesSuccess,
        memberStatuses: responseObj
    }),

    memberStatusesError: message => ({
        type: memberStatusesFailure,
        memberStatusesError: message
    }),

    getMemberStatuses: () => {
        return dispatch => {
            let headers = {
                'content-type': "application/json; charset=utf-8"
            }
            return publicRequest((API_URL || process.env.REACT_APP_MEMBERS_API_URL) + '/Member/api/getmemberstatustypereferencesasync', 'GET', headers, '', '')
                .then(result => {
                    if (result[0] === REQUEST_FAILURE) {
                        dispatch(memberActionCreators.memberStatusesError(result[1]))
                        return Promise.reject(result[1])
                    } else if (result[0] === REQUEST_SUCCESS) {
                        dispatch(memberActionCreators.receiveMemberStatuses(result[1].MemberStatuses))
                    } else if (result[0] === NO_CONTENT) {
                        dispatch(memberActionCreators.memberStatusesError('No Content'))
                        throw 'No Content'
                    }
                }).catch(err => console.log(err))
        }

    },

    // GET MEMBERS BY COMMITTEE ID - PARTNER AUTH
    requestMembersByCommittee: () => ({
        type: membersByCommitteeRequest,
        isFetching: true
    }),

    receiveMembersByCommittee: (responseObj) => ({
        type: membersByCommitteeSuccess,
        isFetching: false,
        membersByCommittee: responseObj
    }),

    membersByCommitteeError: message => ({
        type: membersByCommitteeFailure,
        isFetching: false,
        membersByCommitteeError: message
    }),

    getMembersByCommittee: (params, partner) => {
        return dispatch => {
            dispatch(memberActionCreators.requestMembersByCommittee());
            let headers = {
                'content-type': "application/json; charset=utf-8"
            }
            let request = '';
            if (partner) {
                request = partnerRequest((API_URL || process.env.REACT_APP_MEMBERS_BY_COMMITTEE_API_URL) + '/MembersByCommittee/api/getPartnerMembersByCommitteeAsync?', 'GET', headers, '', params)
            } else {
                request = publicRequest((API_URL || process.env.REACT_APP_MEMBERS_BY_COMMITTEE_API_URL) + '/MembersByCommittee/api/GetCommitteeMembersListAsync?', 'GET', headers, '', params)
            }
            return request
                .then(result => {
                    if (result[0] === REQUEST_FAILURE) {
                        dispatch(memberActionCreators.membersByCommitteeError(result[1]))
                        return Promise.reject(result[1])
                    } else if (result[0] === REQUEST_SUCCESS) {
                        // Dispatch the success action
                        dispatch(memberActionCreators.receiveMembersByCommittee(result[1].MemberList))
                    } else if (result[0] === NO_CONTENT) {
                        dispatch(memberActionCreators.receiveMembersByCommittee([]))
                    } else if (result.name === 'AbortError') {
                        return Promise.reject("Aborted");
                    }
                }).catch(err => Promise.reject(err));
        }

    },

    // GET COMMITTEE MEMBER ROLES - USED ON COMM MGMT FORM DROPDOWN
    requestCommitteeMemberRoles: () => ({
        type: committeeMemberRolesRequest,
        isFetching: true
    }),

    receiveCommitteeMemberRoles: (houseRoles, senateRoles) => ({
        type: committeeMemberRolesSuccess,
        isFetching: false,
        committeeHouseRoles: houseRoles,
        committeeSenateRoles: senateRoles
    }),

    committteeMemberRolesError: message => ({
        type: committeeMemberRolesFailure,
        isFetching: false,
        message
    }),

    getCommitteeMemberRoles: () => {
        return dispatch => {
            dispatch(memberActionCreators.requestCommitteeMemberRoles());

            return publicRequest((API_URL || process.env.REACT_APP_MEMBERS_BY_COMMITTEE_API_URL) + '/MembersByCommittee/api/getCommitteeRolesAsync/', 'GET', '', '', '')
                .then(result => {
                    if (result[0] === REQUEST_FAILURE) {
                        dispatch(memberActionCreators.committeeMemberRolesError(result[1]))
                        return Promise.reject(result[1])
                    } else if (result[0] === REQUEST_SUCCESS) {
                        // Dispatch the success action
                        let members = result[1];
                        var committeeMembersArray = members.CommitteeRoles;
                        var committeeMemberSenateRoles = [];
                        var committeeMemberHouseRoles = [];
                        committeeMembersArray.forEach(function (element) {
                            var elemObj = { label: element.Title, value: element.CommitteeRoleID }
                            if (element.ChamberCode === 'H') {
                                committeeMemberHouseRoles.push(elemObj)
                            } else {
                                committeeMemberSenateRoles.push(elemObj)
                            }
                        })
                        dispatch(memberActionCreators.receiveCommitteeMemberRoles(committeeMemberHouseRoles, committeeMemberSenateRoles))
                    } else if (result[0] === NO_CONTENT) {
                        dispatch(memberActionCreators.committeeMemberRolesError('No Content'))
                    } else if (result.name === 'AbortError') {
                        return Promise.reject("Aborted");
                    }
                }).catch(err => Promise.reject(err));
        }
    },

    // GET MEMBER SHALLOW LIST
    requestMemberNumbers: () => ({
        type: memberNumbersRequest,
        isFetching: true
    }),

    receiveMemberNumbers: responseObj => ({
        type: memberNumbersSuccess,
        isFetching: false,
        memberNumbers: responseObj
    }),

    memberNumbersError: message => ({
        type: memberNumbersFailure,
        isFetching: false,
        memberNumbers: [],
        memberNumbersError: message
    }),

    getMemberNumbers: () => {
        return dispatch => {
            dispatch(memberActionCreators.requestMemberNumbers());

            return publicRequest((API_URL || process.env.REACT_APP_MEMBERS_API_URL) + '/Member/api/GetMemberNumberListAsync', 'GET', '', '', '')
                .then(result => {
                    if (result[0] === REQUEST_FAILURE) {
                        dispatch(memberActionCreators.memberNumbersError(result[1]))
                        return Promise.reject(result[1])
                    } else if (result[0] === REQUEST_SUCCESS) {
                        // Dispatch the success action
                        dispatch(memberActionCreators.receiveMemberNumbers(result[1].MemberNumberList))
                    } else if (result[0] === NO_CONTENT) {
                        dispatch(memberActionCreators.receiveMemberNumbers([]))
                    } else if (result.name === 'AbortError') {
                        return Promise.reject("Aborted");
                    }
                }).catch(err => Promise.reject(err));

        }
    },

    // GET MEMBER SHALLOW LIST
    requestMemberList: () => ({
        type: memberListRequest,
        isFetching: true
    }),

    receiveMemberList: responseObj => ({
        type: memberListSuccess,
        isFetching: false,
        memberList: responseObj
    }),

    memberListError: message => ({
        type: memberListFailure,
        isFetching: false,
        memberList: [],
        memberListError: message
    }),

    getMemberList: (params) => {
        return dispatch => {
            dispatch(memberActionCreators.requestMemberList());

            return publicRequest((API_URL || process.env.REACT_APP_MEMBERS_API_URL) + '/Member/api/GetMemberListAsync/?', 'GET', '', '', params)
                .then(result => {
                    if (result[0] === REQUEST_FAILURE) {
                        dispatch(memberActionCreators.memberListError(result[1]))
                        return Promise.reject(result[1])
                    } else if (result[0] === REQUEST_SUCCESS) {
                        // Dispatch the success action
                        dispatch(memberActionCreators.receiveMemberList(result[1].ShallowMembers))
                    } else if (result[0] === NO_CONTENT) {
                        dispatch(memberActionCreators.receiveMemberList([]))
                    } else if (result.name === 'AbortError') {
                        return Promise.reject("Aborted");
                    }
                }).catch(err => Promise.reject(err));

        }
    },

    receiveMember: responseObj => ({
        type: memberByIdSuccess,
        member: responseObj
    }),

    memberError: message => ({
        type: memberByIdFailure,
        memberError: message
    }),

    getMember: (params, partner) => {
        return dispatch => {
            let request = '';
            if (partner) {
                request = partnerRequest((API_URL || process.env.REACT_APP_MEMBERS_API_URL) + '/Member/api/GetPartnerMemberByIdAsync/', 'GET', '', '', params)
            } else {
                request = publicRequest((API_URL || process.env.REACT_APP_MEMBERS_API_URL) + '/Member/api/GetMemberByIdAsync/', 'GET', '', '', params)
            }
            return request
                .then(result => {
                    if (result[0] === REQUEST_FAILURE) {
                        dispatch(memberActionCreators.memberError(result[1]))
                        return Promise.reject(result[1])
                    } else if (result[0] === REQUEST_SUCCESS) {
                        dispatch(memberActionCreators.receiveMember(result[1].Members[0]))
                    } else if (result[0] === NO_CONTENT) {
                        dispatch(memberActionCreators.memberError('Member not found'))
                        return Promise.reject('No Content')
                    } else if (result.name === 'AbortError') {
                        return Promise.reject("Aborted");
                    }
                }).catch(err => Promise.reject(err));
        }
    },

    // GET MEMBER CONTACT INFO - USED ON MEMBER DETAILS PAGE
    requestMemberContact: () => ({
        type: memberContactRequest,
        isFetching: true
    }),

    receiveMemberContact: responseObj => ({
        type: memberContactSuccess,
        isFetching: false,
        memberContactData: responseObj
    }),

    memberContactError: message => ({
        type: memberContactFailure,
        isFetching: false,
        message
    }),

    getMemberContact: (params) => {
        return dispatch => {
            dispatch(memberActionCreators.requestMemberContact());

            return publicRequest((API_URL || process.env.REACT_APP_MEMBERS_API_URL) + '/Member/api/GetMembersContactInformationListAsync/?', 'GET', '', '', params)
                .then(result => {
                    if (result[0] === REQUEST_FAILURE) {
                        dispatch(memberActionCreators.memberContactError(result[1]))
                        return Promise.reject(result[1])
                    } else if (result[0] === REQUEST_SUCCESS) {
                        // Dispatch the success action
                        dispatch(memberActionCreators.receiveMemberContact(result[1].MemberContactInformationList))
                    } else if (result[0] === NO_CONTENT) {
                        dispatch(memberActionCreators.receiveMemberContact([]))
                    } else if (result.name === 'AbortError') {
                        return Promise.reject("Aborted");
                    }
                }).catch(err => Promise.reject(err))
        }
    },

    // GET MEMBER CONTACT INFO - USED ON MEMBER MANAGEMENT GRID
    requestMemberListContact: () => ({
        type: memberListContactRequest,
        isFetching: true
    }),

    receiveMemberListContact: responseObj => ({
        type: memberListContactSuccess,
        isFetching: false,
        memberListContactData: responseObj
    }),

    memberListContactError: message => ({
        type: memberListContactFailure,
        isFetching: false,
        message
    }),

    getMemberListContact: (params) => {
        return dispatch => {
            dispatch(memberActionCreators.requestMemberContact());

            return partnerRequest((API_URL || process.env.REACT_APP_MEMBERS_API_URL) + '/Member/api/GetPartnerMembersContactInformationListAsync/?', 'GET', '', '', params)
                .then(result => {
                    if (result[0] === REQUEST_FAILURE) {
                        dispatch(memberActionCreators.memberListContactError(result[1]))
                        return Promise.reject(result[1])
                    } else if (result[0] === REQUEST_SUCCESS) {
                        // Dispatch the success action
                        dispatch(memberActionCreators.receiveMemberListContact(result[1].MemberContactInformationList))
                    } else if (result[0] === NO_CONTENT) {
                        dispatch(memberActionCreators.receiveMemberListContact([]))
                    } else if (result.name === 'AbortError') {
                        return Promise.reject("Aborted");
                    }
                }).catch(err => Promise.reject(err))
        }
    },

    receiveMemberCommitteeSave: (message) => ({
        type: memberCommitteeSaveSuccess,
        memberCommitteeSave: message
    }),

    memberCommitteeSaveError: (message) => ({
        type: memberCommitteeSaveFailure,
        memberCommitteeSaveError: message
    }),

    saveMemberCommittee: (body) => {
        return dispatch => {
            let headers = {
                'content-type': "application/json; charset=utf-8"
            }
            return partnerRequest((API_URL || process.env.REACT_APP_MEMBERS_BY_COMMITTEE_API_URL) + '/MembersByCommittee/api/SaveMembersByCommitteeAsync/', 'PUT', headers, body, '')
                .then(result => {
                    if (result[0] === REQUEST_FAILURE) {
                        dispatch(memberActionCreators.memberCommitteeSaveError(result[1]))
                        return Promise.reject(result[1])
                    } else if (result[0] === REQUEST_SUCCESS) {
                        // Dispatch the success action
                        dispatch(memberActionCreators.receiveMemberCommitteeSave(result[1]))
                        return Promise.resolve(result[1])
                    } else if (result[0] === NO_CONTENT) {
                        dispatch(memberActionCreators.memberCommitteeSaveError('No Content'))
                        return Promise.reject('No Content')
                    } else if (result.name === 'AbortError') {
                        return Promise.reject("Aborted");
                    }
                }).catch(err => Promise.reject(err))
        }
    },

    receiveMemberCreate: (resObj) => ({
        type: memberCreateSuccess,
        memberCreate: resObj
    }),

    memberCreateError: (message) => ({
        type: memberCreateFailure,
        memberCreateError: message
    }),

    createMember: (body) => {
        return dispatch => {
            let headers = {
                'content-type': "application/json; charset=utf-8"
            }
            return partnerRequest((API_URL || process.env.REACT_APP_MEMBERS_API_URL) + '/Member/api/creatememberasync/', 'POST', headers, body, '')
                .then(result => {
                    if (result[0] === REQUEST_FAILURE) {
                        dispatch(memberActionCreators.memberCreateError(result[1]))
                        return Promise.reject(result[1])
                    } else if (result[0] === REQUEST_SUCCESS) {
                        dispatch(memberActionCreators.receiveMemberCreate(result[1][0]))
                        return Promise.resolve(result[1])
                    } else if (result[0] === NO_CONTENT) {
                        dispatch(memberActionCreators.memberCreateError('No Content'))
                        return Promise.reject('No Content')
                    } else if (result.name === 'AbortError') {
                        return Promise.reject("Aborted");
                    }
                }).catch(err => Promise.reject(err))
        }
    },

    receiveMemberSave: (resObj) => ({
        type: memberSaveSuccess,
        memberSave: resObj
    }),

    memberSaveError: (message) => ({
        type: memberSaveFailure,
        memberSaveError: message
    }),

    saveMember: (body) => {
        return dispatch => {
            let headers = {
                'content-type': "application/json; charset=utf-8"
            }
            return partnerRequest((API_URL || process.env.REACT_APP_MEMBERS_API_URL) + '/Member/api/savememberasync/', 'POST', headers, body, '')
                .then(result => {
                    if (result[0] === REQUEST_FAILURE) {
                        dispatch(memberActionCreators.memberSaveError(result[1]))
                        return Promise.reject(result[1])
                    } else if (result[0] === REQUEST_SUCCESS) {
                        dispatch(memberActionCreators.receiveMemberSave(result[1][0]))
                        return Promise.resolve(result[1])
                    } else if (result[0] === NO_CONTENT) {
                        dispatch(memberActionCreators.memberSaveError('No Content'))
                        return Promise.reject('No Content')
                    } else if (result.name === 'AbortError') {
                        return Promise.reject("Aborted");
                    }
                }).catch(err => Promise.reject(err))
        }
    },

    receiveMemberLegislation: (responseObj) => ({
        type: memberLegislationSuccess,
        memberLegislation: responseObj
    }),

    memberLegislationError: message => ({
        type: memberLegislationFailure,
        memberLegislationError: message
    }),

    getMemberLegislation: (params) => {
        return dispatch => {
            let headers = {
                'content-type': "application/json; charset=utf-8"
            }
            return publicRequest((API_URL || process.env.REACT_APP_LEGISLATION_PATRON_SERVICE) + '/LegislationPatron/api/GetLegislationPatronListAsync?', 'GET', headers, '', params)
                .then(result => {
                    if (result[0] === REQUEST_FAILURE) {
                        dispatch(memberActionCreators.memberLegislationError(result[1]))
                        return Promise.reject(result[1])
                    } else if (result[0] === REQUEST_SUCCESS) {
                        // Dispatch the success action
                        dispatch(memberActionCreators.receiveMemberLegislation(result[1].PatronLegislation))
                    } else if (result[0] === NO_CONTENT) {
                        dispatch(memberActionCreators.receiveMemberLegislation([]))
                    } else if (result.name === 'AbortError') {
                        return Promise.reject("Aborted");
                    }
                }).catch(err => Promise.reject(err))
        }

    },

    receiveMemberVotes: (responseObj) => ({
        type: memberVotesSuccess,
        memberVotes: responseObj
    }),

    memberVotesError: message => ({
        type: memberVotesFailure,
        memberVotesError: message
    }),

    getMemberVotes: (params) => {
        return dispatch => {
            let headers = {
                'content-type': "application/json; charset=utf-8"
            }
            return publicRequest((API_URL || process.env.REACT_APP_MEMBER_VOTE_SEARCH_SERVICE) + '/MemberVoteSearch/api/GetMemberVoteListAsync', 'GET', headers, '', params)
                .then(result => {
                    if (result[0] === REQUEST_FAILURE) {
                        dispatch(memberActionCreators.memberVotesError(result[1]))
                        return Promise.reject(result[1])
                    } else if (result[0] === REQUEST_SUCCESS) {
                        // Dispatch the success action
                        dispatch(memberActionCreators.receiveMemberVotes(result[1].MemberVoteList[0]))
                    } else if (result[0] === NO_CONTENT) {
                        dispatch(memberActionCreators.receiveMemberVotes([]));
                    } else if (result.name === 'AbortError') {
                        return Promise.reject("Aborted");
                    }
                }).catch(err => Promise.reject(err))
        }

    },

    receiveMemberConferenceBills: (responseObj) => ({
        type: memberConferenceBillsSuccess,
        memberConferenceBills: responseObj
    }),

    memberConferenceBillsError: message => ({
        type: memberConferenceBillsFailure,
        memberConferenceBillsError: message
    }),

    getMemberConferenceBills: (params) => {
        return dispatch => {
            let headers = {
                'content-type': "application/json; charset=utf-8"
            }
            return publicRequest((API_URL || process.env.REACT_APP_LEGISLATION_API_URL) + '/Legislation/api/GetConferenceLegislationListAsync', 'GET', headers, '', params)
                .then(result => {
                    if (result[0] === REQUEST_FAILURE) {
                        dispatch(memberActionCreators.memberConferenceBillsError(result[1]))
                        return Promise.reject(result[1])
                    } else if (result[0] === REQUEST_SUCCESS) {
                        // Dispatch the success action
                        dispatch(memberActionCreators.receiveMemberConferenceBills(result[1].ConferenceLegislationList))
                    } else if (result[0] === NO_CONTENT) {
                        dispatch(memberActionCreators.receiveMemberConferenceBills([]));
                    } else if (result.name === 'AbortError') {
                        return Promise.reject("Aborted");
                    }
                }).catch(err => Promise.reject(err))
        }

    }
};

export const reducer = (state, action) => {
    state = state || initialState;

    switch (action.type) {
        case districtListSuccess:
            return Object.assign({}, state, {
                districtList: action.districtList,
                districtListError: ''
            });
        case districtListFailure:
            return Object.assign({}, state, {
                districtListError: action.districtListError
            });
        case memberStatusesSuccess:
            return Object.assign({}, state, {
                memberStatuses: action.memberStatuses,
                memberStatusesError: ''
            });
        case memberStatusesFailure:
            return Object.assign({}, state, {
                memberStatusesError: action.memberStatusesError
            });
        case partyCodeListSuccess:
            return Object.assign({}, state, {
                partyCodeList: action.partyCodeList,
                partyCodeListError: ''
            });
        case partyCodeListFailure:
            return Object.assign({}, state, {
                partyCodeListError: action.partyCodeListError
            });
        case membersByCommitteeRequest:
            return Object.assign({}, state, {
                isFetching: true
            });
        case membersByCommitteeSuccess:
            return Object.assign({}, state, {
                isFetching: false,
                membersByCommittee: action.membersByCommittee,
                errorMessage: ''
            });
        case membersByCommitteeFailure:
            return Object.assign({}, state, {
                isFetching: false,
                errorMessage: action.message
            });
        case committeeMemberRolesRequest:
            return Object.assign({}, state, {
                isFetching: true
            });
        case committeeMemberRolesSuccess:
            return Object.assign({}, state, {
                isFetching: false,
                committeeSenateRoles: action.committeeSenateRoles,
                committeeHouseRoles: action.committeeHouseRoles,
                errorMessage: ''
            });
        case committeeMemberRolesFailure:
            return Object.assign({}, state, {
                isFetching: false,
                errorMessage: action.message
            });
        case memberNumbersRequest:
            return Object.assign({}, state, {
                isFetching: true
            });
        case memberNumbersSuccess:
            return Object.assign({}, state, {
                isFetching: false,
                memberNumbers: action.memberNumbers,
                errorMessage: ''
            });
        case memberNumbersFailure:
            return Object.assign({}, state, {
                isFetching: false,
                memberNumbersError: action.memberNumbersError
            });
        case memberListRequest:
            return Object.assign({}, state, {
                isFetching: true
            });
        case memberListSuccess:
            return Object.assign({}, state, {
                isFetching: false,
                memberList: action.memberList,
                errorMessage: ''
            });
        case memberListFailure:
            return Object.assign({}, state, {
                isFetching: false,
                memberListError: action.memberListError
            });
        case memberByIdSuccess:
            return Object.assign({}, state, {
                member: action.member,
                memberError: ''
            });
        case memberByIdFailure:
            return Object.assign({}, state, {
                memberError: action.memberError
            });
        case memberContactRequest:
            return Object.assign({}, state, {
                isFetching: true
            });
        case memberContactSuccess:
            return Object.assign({}, state, {
                isFetching: false,
                memberContactData: action.memberContactData,
                errorMessage: ''
            });
        case memberContactFailure:
            return Object.assign({}, state, {
                isFetching: false,
                errorMessage: action.message
            });
        case memberListContactRequest:
            return Object.assign({}, state, {
                isFetching: true
            });
        case memberListContactSuccess:
            return Object.assign({}, state, {
                isFetching: false,
                memberListContactData: action.memberListContactData,
                memberListContactError: ''
            });
        case memberListContactFailure:
            return Object.assign({}, state, {
                isFetching: false,
                memberListContactError: action.message
            });
        case memberCommitteeSaveSuccess:
            return Object.assign({}, state, {
                memberCommitteeSave: action.memberCommitteeSave,
                memberCommitteeSaveError: ''
            })
        case memberCommitteeSaveFailure:
            return Object.assign({}, state, {
                memberCommitteeSaveError: action.memberCommitteeSaveError
            })
        case memberCreateSuccess:
            return Object.assign({}, state, {
                memberCreate: action.memberCreate,
                memberCreateError: ''
            })
        case memberCreateFailure:
            return Object.assign({}, state, {
                memberCreateError: action.memberCreateError
            })
        case memberSaveSuccess:
            return Object.assign({}, state, {
                memberSave: action.memberSave,
                memberSaveError: null
            })
        case memberSaveFailure:
            return Object.assign({}, state, {
                memberSaveError: action.memberSaveError
            })
        case memberLegislationSuccess:
            return Object.assign({}, state, {
                memberLegislation: action.memberLegislation,
                memberLegislationError: ''
            })
        case memberLegislationFailure:
            return Object.assign({}, state, {
                memberLegislationError: action.memberLegislationError
            })
        case memberVotesSuccess:
            return Object.assign({}, state, {
                memberVotes: action.memberVotes,
                memberVotesError: ''
            })
        case memberVotesFailure:
            return Object.assign({}, state, {
                memberVotesError: action.memberVotesError
            })
        case memberConferenceBillsSuccess:
            return Object.assign({}, state, {
                memberConferenceBills: action.memberConferenceBills,
                memberConferenceBillsError: ''
            })
        case memberConferenceBillsFailure:
            return Object.assign({}, state, {
                memberConferenceBillsError: action.memberConferenceBillsError
            })
        default:
            return state;
    }
};
