import React from 'react';
import { bindActionCreators } from 'redux';
import { Link } from 'react-router-dom';
import ReactToPrint from "react-to-print";
import { connect } from 'react-redux';
import { sessionActionCreators } from '../../stores/lis-session-store';
import { navActionCreators } from '../../stores/lis-nav-store';
import { communicationsActionCreators } from '../../stores/lis-communications-store';
import { cancelRequest } from '../../services/request.service';

import renderHTML from 'react-render-html';
import CommunicationsDetailsComponent from '../../lis-shared/lis-communications/lis-communications-details';

class PublicCommunicationDetails extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            isLoaded: false,
            communication: '',
            communicationFile: '',
            clerkInfo: {}
        };
    }

    componentDidMount() {
        window.scrollTo(0, 0);
        const params = `?referenceNumber=${this.props.match.params.commid}&sessionCode=${this.props.match.params.sessioncode}`;
        this.props.actions.getComm(params)
        .then(() => {
            this.props.actions.getSessionById(this.props.communications.commGet.SessionID);
            const commGet = this.props.communications.commGet;
            if(commGet.CommunicationCategories.length === 0) {
                //If the communication does not have any categories then it is assumed that this is a legacy file and the data needs to be retrieved from file storage.
                const htmlFile = commGet.CommunicationFiles.find(file => file.FileURL.toUpperCase().includes('.HTM'));
                if(htmlFile) {
                    this.props.actions.getFile(htmlFile.FileURL)
                    .then(() => {
                        this.setState({
                            communicationFile: this.props.nav.file,
                            isLoaded: true
                        });
                    }).catch(err => {
                        if(err !== 'Aborted') {
                            throw err.toString();
                        }
                    });
                }
            }
            // Attempt to retrieve the json file in blob storage to get the signatures
            const jsonFile = commGet.CommunicationFiles.find(file => file.FileURL.toUpperCase().includes('.JSON'));
            if(jsonFile) {
                this.props.actions.getFile(jsonFile.FileURL)
                .then(() => {
                    const json = JSON.parse(this.props.nav.file);
                    this.setState({
                        clerkInfo: {
                            ClerkName: json.ClerkName,
                            ClerkTitle: json.ClerkTitle,
                            FooterSentence: json.FooterSentence
                        }
                    });
                })
                
            }
            this.setState({
                communication: commGet,
                isLoaded: true
            });
        }).catch(err => {
            if(err === 'Aborted') {
                return;
            }
            this.setState({
                message: 'communication not found: ' + err.toString(),
                isLoaded: true
            });
        });
    }

    componentWillUnmount() {
        cancelRequest();
    }

    render() {
        const { selectedSession } = this.props.session;
        const { isLoaded, message, clerkInfo } = this.state;
        let communication = {
            ...this.state.communication,
            ClerkName: clerkInfo.ClerkName,
            ClerkTitle: clerkInfo.ClerkTitle,
            FooterSentence: clerkInfo.FooterSentence
        }
        if (!isLoaded) {
            return (<div className="center-spinner spinner">Loading...</div>)
        }
        if (message) {
            return (<div className="center">{message}</div>)
        }
        return (
            <div className='generic-details-page'>
                <div>
                    <ReactToPrint
                        trigger={() => <button type="button" className="button print float-right"> Print</button>}
                        content={() => this.componentRef}
                        pageStyle={"break-inside: avoid"}                        
                    />
                </div>
                <div ref={el => (this.componentRef = el)}>
                    {this.state.communicationFile ?
                        <div className='details-page-data-container'>{renderHTML(this.state.communicationFile)}</div>
                        :
                        <CommunicationsDetailsComponent
                            session={selectedSession}
                            communication={communication}
                        />
                    }
                </div>
            </div>);
    }
}


export default connect(
    (state) => {
        const { session, nav, communications } = state;
        return {
            session,
            nav,
            communications
        }
    },
    (dispatch) => {
        return {
            actions: bindActionCreators(Object.assign({}, sessionActionCreators, navActionCreators, communicationsActionCreators), dispatch)
        }
    }
)(PublicCommunicationDetails)
