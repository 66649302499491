import React from "react";
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import { journalActionCreators } from "../../../stores/lis-journal-store";

const getListStyle = isDraggingOver => ({
    background: isDraggingOver ? '#8b9fb3' : '#666666',
    width: '100%',
});

class JournalVote extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
        };
        this.onEntryDragEnd = this.onEntryDragEnd.bind(this);
        this.toggleMenu = this.toggleMenu.bind(this);
        this.formInput = this.formInput.bind(this);

        this.infoBoxRef = React.createRef();
        this.menuBoxRef = React.createRef();
    }

    onEntryDragEnd(result) {
        if (!result.destination) {
            return;
        }
        if (result.destination.index === result.source.index) {
            return;
        }
        let entries = [...this.props.formData.JournalEntries];
        const [removed] = entries.splice(result.source.index, 1);
        entries.splice(result.destination.index, 0, removed);
        entries.forEach((element, index) => {
            element.Sequence = index + 1;
        });
        this.props.onMultipleEntryChange(entries, this.props.currentActivitySequence);
    }

    toggleMenu(entryIndex, e) {
        if (e) {
            //Do not hide the small motion form if a child of the form is focused
            if (e.currentTarget.contains(e.relatedTarget)) {
                return;
            }
        }
        this.setState({
            showMenu: entryIndex
        }, () => {
            if (this.menuBoxRef.current) {
                this.menuBoxRef.current.focus();
            }
        });
    }

    formInput(entry, entryIndex, provided = {}) {
        return (
            <div key={entryIndex}>
                <div className="journal-grid">
                    <div style={{ textAlign: 'center' }}>
                        <span {...provided.dragHandleProps} className="icon menu" onClick={(e) => this.toggleMenu(entryIndex)}></span>
                        {this.state.showMenu === entryIndex &&
                            <div style={{ textAlign: 'left', marginLeft: '-15px', width: 'auto' }} className="info-box blue" ref={this.menuBoxRef} onBlur={(e) => this.toggleMenu(-1, e)} contentEditable suppressContentEditableWarning={true}>
                                <div contentEditable={false}>
                                </div>
                            </div>
                        }
                    </div>
                    <div>
                        <span>{entry.EntryText}</span>
                    </div>
                </div>
            </div>
        );
    }

    render() {
        const entries = this.props.formData.JournalEntries;
        return (
            <div>
                <div className="journal-entry-grid">
                    <div>
                        <span></span>
                    </div>
                    <div>
                        <span>{this.props.formData.CategoryDescription}</span>
                    </div>
                </div>
                <div>
                    <DragDropContext onDragEnd={this.onEntryDragEnd}>
                        <Droppable droppableId={`entries-droppable`}>
                            {(provided, snapshot) =>
                                <div ref={provided.innerRef} style={getListStyle(snapshot.isDraggingOver)}>
                                    {entries.map((entry, entryIndex) => {
                                        if (entry) {
                                            return (
                                                <Draggable key={`${entry.JournalEntryID}-${entry.Sequence}`} draggableId={`${entry.JournalEntryID}-${entry.Sequence}`} index={entryIndex}>
                                                    {(provided, snapshot) =>
                                                        <div
                                                            ref={provided.innerRef}
                                                            {...provided.draggableProps}>
                                                            {this.formInput(entry, entryIndex, provided)}
                                                        </div>}
                                                </Draggable>
                                            );
                                        }
                                    })}
                                    {provided.placeholder}
                                </div>
                            }
                        </Droppable>
                    </DragDropContext>
                </div>
            </div>
        );
    }
}

export default connect(
    (state) => {
        const { journal } = state;
        return {
            journal
        }
    },
    (dispatch) => {
        return {
            actions: bindActionCreators(Object.assign({}, journalActionCreators), dispatch)
        }
    },
    null,
    {
        forwardRef: true
    }
)(JournalVote)