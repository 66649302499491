import { useState } from 'react';

const QuickAdd = props => {
    const [addBill, handleAddBillChange] = useState('')
    const [isAddingBill, toggleIsAddingBill] = useState(false)
    const quickAddBills = () => {
        if (!addBill.replace(/\s/g), "") {
            return;
        }
        toggleIsAddingBill(true);
        props.quickAddBills(addBill, err => {
            toggleIsAddingBill(false);
            if (!err) { handleAddBillChange('') };
        });
    }

    return (
        <>
            <div className="quick-add-container" style={{ marginRight: "0px" }}>
                {props.quickAddErr &&
                    <span className="small-text fail" style={{ paddingRight: '5px' }}>{props.quickAddErr}</span>
                }
                <label className="screen-reader-only" htmlFor="bill-quick-add">Bill quick add</label>
                <input
                    id="bill-quick-add"
                    type="text"
                    onKeyDown={e => {
                        if (e.key === "Enter") {
                            e.stopPropagation();
                            quickAddBills()
                        }
                    }}
                    autoFocus={props.autoFocus}
                    placeholder="Bill quick add"
                    value={addBill}
                    onChange={e => handleAddBillChange(e.target.value)}
                />
                {isAddingBill ?
                    <span className="small-spinner"></span>
                    :
                    <button style={{ marginLeft: "7px" }} type="button" disabled={props.collectionIsSaving} onClick={quickAddBills} id="bill-quick-add-button" className="small-button button">Add</button>
                }
            </div>
        </>
    );
}

export default QuickAdd;
