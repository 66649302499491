import React from "react";

class Misc extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            newEntries: [],
            editing: false
        };

        this.handleChange = this.handleChange.bind(this);
        this.toggleEdit = this.toggleEdit.bind(this);
        this.cancelEdit = this.cancelEdit.bind(this);
        this.saveChange = this.saveChange.bind(this);
    }

    handleChange(e) {
        //Update the local entry text without saving data to the parent
        let entries = [...this.state.newEntries];

        entries[0].EntryText = e.target.value;

        this.setState({
            newEntries: entries
        });
    }

    toggleEdit() {
        this.setState({
            editing: !this.state.editing,
        })
    }

    cancelEdit() {
        this.setState({
            editing: false,
            newEntries: this.props.formData.MinutesEntries
        })
    }

    saveChange() {
        this.props.handleSaveEntry(this.state.newEntries[0])
        this.toggleEdit();
    }

    componentDidMount() {
        this.setState({
            newEntries: this.props.formData ? this.props.formData.MinutesEntries : []
        })
    }

    componentDidUpdate(prevProps) {
        //If the entry has been saved or changed then swap out the entry with the latest
        if (prevProps.formData && this.props.formData && prevProps.formData.MinutesEntries[0].ModificationDate != this.props.formData.MinutesEntries[0].ModificationDate) {
            this.setState({
                newEntries: this.props.formData.MinutesEntries
            })
        }
    }

    render() {
        return (
            <React.Fragment>
                {this.state.newEntries.map((entry, i) => {
                    return (
                        <React.Fragment>
                            <div key={entry.MinutesEntryID}>
                                {this.state.editing
                                    ?
                                    <div className="grid-wrapper two-col-sidebar">
                                        <textarea value={entry.EntryText} onChange={(e) => this.handleChange(e)} />
                                        <div>
                                            <button onClick={() => this.saveChange()} type="button" className="icon save" style={{ marginRight: "10px" }}></button>
                                            <button onClick={() => this.cancelEdit()} type="button" className="icon delete"></button>
                                        </div>
                                    </div>
                                    :
                                    <div className="grid-wrapper two-col-sidebar">
                                        <p>{entry.EntryText}</p>
                                        <button onClick={() => this.toggleEdit()} type="button" className="icon edit"></button>
                                    </div>
                                }

                            </div>
                        </React.Fragment>
                    )
                })}
            </React.Fragment>
        );
    }
}

export default Misc