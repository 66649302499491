import React from "react";
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { RedocStandalone } from "redoc";
import { developersActionCreators } from "../../../stores/lis-developers-store";
import { navActionCreators } from "../../../stores/lis-nav-store";

class DocumentComponent extends React.Component {
    constructor(props) {
        super(props);

        this.backToList = this.backToList.bind(this);
    }

    componentDidMount() {
        this.props.actions.getDoc("?ApiName=" + this.props.match.params.docName, false);
    }

    backToList() {
        window.location.href = "/developers"
    }

    render() {
        return (
            <div style={{ marginTop: "20px" }}>
                <a onClick={this.backToList} style={{ cursor: "pointer" }}>Back to List</a>
                {this.props.developers.doc != '' ?
                    <RedocStandalone
                        spec={JSON.parse(this.props.developers.doc)}
                        options={{
                            nativeScrollbars: false,
                            theme: {
                                colors: {
                                    primary: {
                                        main: "#34495e"
                                    },
                                    http: {
                                        get: "#4BCA81"
                                    },
                                    responses: {
                                        error: {
                                            color: "#fb4f4f"
                                        },
                                        success: {
                                            color: "#4BCA81"
                                        }
                                    },
                                    success: {
                                        main: "#4BCA81"
                                    },
                                    error: {
                                        main: "#fb4f4f"
                                    }
                                },
                                typography: {
                                    fontFamily: "Lato",
                                    fontWeight: "400",
                                    fontSize: "14px",
                                    lineHeight: "1.4",
                                    color: "#333",
                                    code: {
                                        fontSize: "13px",
                                        fontFamily: "consolas"
                                    },
                                    headings: {
                                        fontFamily: "Lato",
                                        fontWeight: "400",
                                        color: "#34495e"
                                    },
                                    links: {
                                        color: "#3498db",
                                        visited: "#3498db",
                                        hover: "#196090"
                                    },
                                    optimizeSpeed: true
                                },
                                sidebar: {
                                    backgroundColor: "#F1F1F1"
                                },
                                rightPanel: {
                                    backgroundColor: "#34495e"
                                },
                                spacing: {
                                    sectionHorizontal: 8,
                                    sectionVertical: 4
                                }
                            },
                            lazyRendering: true
                        }}
                    />
                    :
                    <div><span className="small-spinner"></span></div>
                }
            </div>
        )
    }
}

export default connect(
    (state) => {
        const { developers, nav } = state;
        return {
            developers,
            nav
        }
    },
    (dispatch) => {
        return {
            actions: bindActionCreators(Object.assign({}, developersActionCreators, navActionCreators), dispatch)
        }
    }
)(DocumentComponent)