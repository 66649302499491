import React from 'react';

const HelpPage = () => {
    return (
        <div className="long-paragraph-text">
            <div>
                <h2 className="txt-dark-blue">Single Search Word</h2>
                <p>Example using Single Word: distilled</p>
                <p>A single search word is the simplest of all search statement constructions. For a document to qualify for the search, it must contain an occurrence of the exact character string specified as the search word. For example; the search word "distilled" only retrieves the documents that contain occurrences of the word "distilled". The words "distill" and "distillery", for example, do not qualify. </p>
            </div>
            <div>
                <h2 className="txt-dark-blue">Phrase Search</h2>
                <p>Phrase example: 'state child fatality review team'</p>
                <p>A search phrase is constructed of two or more search words. To perform a phrase search, surround the phrase with single or double quotes.</p>
            </div>
            <div>
                <h2 className="txt-dark-blue">Logic Conditions</h2>
                <p>Search statements can be entered using any of three logic conditions: "and", "or", and "and not". Use of a logic condition requires two search statements. For example: "A or B", or "A and (B or C)". The logic conditions must be positioned between two search words or statements.</p>
                <p>When you use "and" in your search statement (example: "A and B"), all documents that contain references of both A and B are displayed. Documents containing references of only A or only B will not qualify.</p>
                <p>When you use "or" in your search statement (example: "A or B"), all documents with references of either A or B will display. Unlike "and" it is not necessary for both statements to be present in a document for it to qualify, but documents containing both "A and B" will also qualify.</p>
                <p>When you use "and not" in your search statement ("A and not B"), documents must first contain references of A. Of the documents containing A, only those without references of B will qualify.</p>
                <p>With "and" or "or" the order of the statements will not affect the outcome of the search. When using "and not", the results of the search depend on the order of the statements. Compound search statements (those with more than one logic condition) can also be read several ways.</p>
                <p>Search statements are normally read from left to right. Simple statements such as the example "A or B" will not be misinterpreted. When additional logic conditions are added, the order and groupings of the statements may be defined with parentheses. </p>
            </div>
            <div>
                <h2 className="txt-dark-blue">Parentheses</h2>
                <p>Parentheses can be used to affect the order in which the statement is read. The examples below show how the placement of the parentheses can change the intent of the statement:</p>
                <p> 1 ==&gt; (A or B) and C:</p>
                <p>This statement reads: Display all documents containing references of either A or B that also contain references of C.</p>
                <p> 2 ==&gt; A or (B and C):</p>
                <p>This statement is the same as the one above. Only the placement of the parentheses is changed. The statement reads: Display documents with either references of both B and C or the documents with references of A.</p>
                <p>If additional parentheses are used, the statement contained in the deepest set of parentheses is resolved first. For example in "D and (B or (A and C))", "A and C" is resolved first, despite its rightmost position in the statement.</p>
                <p>Logic conditions can be combined with any kind of search words or search phrases to create a search statement as broad or specific as you desire. </p>
            </div>
        </div>
    );
}

export default HelpPage;