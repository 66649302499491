import { partnerRequest, publicRequest } from '../services/request.service'

const API_URL = window.env ? window.env.API_SERVER : '';
const NO_CONTENT = 2;
const REQUEST_FAILURE = 1;
const REQUEST_SUCCESS = 0;

const personnelListRequest = 'PERSONNEL_LIST_REQUEST';
const personnelListSuccess = 'PERSONNEL_LIST_SUCCESS';
const personnelListFailure = 'PERSONNEL_LIST_FAILURE';

const roleTypesRequest = 'ROLE_TYPES_REQUEST';
const roleTypesSuccess = 'ROLE_TYPES_SUCCESS';
const roleTypesFailure = 'ROLE_TYPES_FAILURE';

const personnelReferencesRequest = 'PERSONNEL_REFERENCES_REQUEST';
const personnelReferencesSuccess = 'PERSONNEL_REFERENCES_SUCCESS';
const personnelReferencesFailure = 'PERSONNEL_REFERENCES_FAILURE';

const personnelSaveRequest = 'PERSONNEL_SAVE_REQUEST';
const personnelSaveSuccess = 'PERSONNEL_SAVE_SUCCESS';
const personnelSaveFailure = 'PERSONNEL_SAVE_FAILURE';


const initialState = {
    personnelStaffList: [],
    personnelClerkList: [],
    personnelOtherList: [],
    personnelStaffReferences: [],
    personnelClerkReferences: [],
    personnelErrorSave: '',
    receivePersonnelSave: '',
    personnelStaffSave: '',
    personnelClerkSave: '',
    roleTypes: []
};

export const personnelActionCreators = {
    // GET PERSONNEL REFERENCES FOR COMM MGMT DROPDOWNS
    requestPersonnelReferences: () => ({
        type: personnelReferencesRequest,
        personnelReferences: [],
        isFetching: true
    }),

    receivePersonnelReferences: (clerkReferences, staffReferences, message) => ({
        type: personnelReferencesSuccess,
        isFetching: false,
        personnelStaffReferences: staffReferences,
        personnelClerkReferences: clerkReferences,
        message
    }),

    personnelReferencesError: (message) => ({
        type: personnelReferencesFailure,
        isFetching: false,
        personnelStaffReferences: [],
        personnelClerkReferences: [],
        message
    }),

    getPersonnelReferences: (params) => {
        return dispatch => {
            dispatch(personnelActionCreators.requestPersonnelReferences());

            return publicRequest((API_URL || process.env.REACT_APP_PERSONNEL_API_URL) + '/Personnel/api/GetReferencesAsync?', 'GET', '', '', params)
                .then(result => {
                    if (result[0] === REQUEST_FAILURE) {
                        dispatch(personnelActionCreators.personnelReferencesError(result[1]))
                        return Promise.reject(result[1])
                    } else if (result[0] === REQUEST_SUCCESS) {
                        // Dispatch the success action
                        let personnelReferences = result[1];
                        var personnelClerkReferences = [];
                        var personnelStaffReferences = [];

                        // STAFF = 2
                        // CLERK = 1
                        // FILTER OUT DUPLICATES----
                        for (var i = 0; i < personnelReferences.Personnels.length; i++) {
                            var personnelObj = { ...personnelReferences.Personnels[i], value: personnelReferences.Personnels[i].StaffID, label: personnelReferences.Personnels[i].FullName }

                            if (personnelReferences.Personnels[i].StaffRoleTypeID === 2) {
                                var isDuplicate = false;
                                personnelStaffReferences.forEach(element => {
                                    if (element.IdentityID === personnelObj.IdentityID) {
                                        isDuplicate = true;
                                    }
                                });
                                if (!isDuplicate) {
                                    personnelStaffReferences.push(personnelObj)
                                }
                            } else {
                                var isOtherDuplicate = false;
                                personnelClerkReferences.forEach(element => {
                                    if (element.IdentityID === personnelObj.IdentityID) {
                                        isOtherDuplicate = true;
                                    }
                                })
                                if (!isOtherDuplicate) {
                                    personnelClerkReferences.push(personnelObj);
                                }
                            }
                        }
                        dispatch(personnelActionCreators.receivePersonnelReferences(personnelClerkReferences, personnelStaffReferences))
                    } else if (result[0] === NO_CONTENT) {
                        dispatch(personnelActionCreators.personnelReferencesError('No Content'))
                        return Promise.reject('No Content')
                    } else if (result.name === 'AbortError') {
                        return Promise.reject("Aborted");
                    }
                }).catch(err => Promise.reject(err));
        }
    },


    // GET PERSONNEL STAFF AND CLERKS FOR COMM MGMT FORM GRID
    requestPersonnelList: () => ({
        type: personnelListRequest,
        personnelList: [],
        isFetching: true
    }),

    receivePersonnelList: (clerkList, staffList, otherList, message) => ({
        type: personnelListSuccess,
        isFetching: false,
        personnelStaffList: staffList,
        personnelClerkList: clerkList,
        personnelOtherList: otherList,
        message
    }),

    personnelListError: (message) => ({
        type: personnelListFailure,
        isFetching: false,
        personnelList: [],
        message
    }),

    getPersonnelList: (params, isPublic) => {

        return dispatch => {
            dispatch(personnelActionCreators.requestPersonnelList());
            let headers = {
                'content-type': "application/json; charset=utf-8"
            }
            let request = '';
            if (isPublic) {
                request = publicRequest((API_URL || process.env.REACT_APP_PERSONNEL_API_URL) + '/Personnel/api/getPersonnelListAsync?', 'GET', headers, '', params);
            } else {
                request = partnerRequest((API_URL || process.env.REACT_APP_PERSONNEL_API_URL) + '/Personnel/api/getPartnerPersonnelListAsync?', 'GET', headers, '', params);
            }
            return request
                .then(result => {
                    if (result[0] === REQUEST_FAILURE) {
                        dispatch(personnelActionCreators.personnelListError(result[1]))
                        return Promise.reject(result[1])
                    } else if (result[0] === REQUEST_SUCCESS) {
                        // Dispatch the success action
                        let personnelList = result[1]
                        const personnelClerkList = personnelList.Personnels.filter(personnel => personnel.StaffRoleTypeID === 1)
                        const personnelStaffList = personnelList.Personnels.filter(personnel => personnel.StaffRoleTypeID === 2)
                        const personnelOtherList = personnelList.Personnels.filter(personnel => personnel.StaffRoleTypeID !== 1 && personnel.StaffRoleTypeID !== 2)
                        dispatch(personnelActionCreators.receivePersonnelList(personnelClerkList, personnelStaffList, personnelOtherList))
                    } else if (result[0] === NO_CONTENT) {
                        dispatch(personnelActionCreators.receivePersonnelList([], []))
                    } else if (result.name === 'AbortError') {
                        return Promise.reject("Aborted");
                    }
                }).catch(err => Promise.reject(err));
        }
    },

    // GET PERSONNEL STAFF AND CLERKS FOR COMM MGMT FORM GRID
    requestRoleTypes: () => ({
        type: roleTypesRequest,
        roleTypes: [],
        isFetching: true
    }),

    receiveRoleTypes: (roleTypes, message) => ({
        type: roleTypesSuccess,
        isFetching: false,
        roleTypes: roleTypes,
        message
    }),

    roleTypesError: (message) => ({
        type: roleTypesFailure,
        isFetching: false,
        roleTypes: [],
        message
    }),

    getRoleTypes: (params) => {

        return dispatch => {
            dispatch(personnelActionCreators.requestRoleTypes());
            let headers = {
                'content-type': "application/json; charset=utf-8"
            }
            return publicRequest((API_URL || process.env.REACT_APP_PERSONNEL_API_URL) + '/Personnel/api/GetStaffRoleTypesAsync', 'GET', headers, '', '')
                .then(result => {
                    if (result[0] === REQUEST_FAILURE) {
                        dispatch(personnelActionCreators.roleTypesError(result[1]))
                        return Promise.reject(result[1])
                    } else if (result[0] === REQUEST_SUCCESS) {
                        dispatch(personnelActionCreators.receiveRoleTypes(result[1].StaffRoleTypes))
                    } else if (result[0] === NO_CONTENT) {
                        dispatch(personnelActionCreators.receiveRoleTypes([]))
                    } else if (result.name === 'AbortError') {
                        return Promise.reject("Aborted");
                    }
                }).catch(err => Promise.reject(err));
        }
    },

    requestPersonnelSave: () => ({
        type: personnelSaveRequest,
        isFetching: true
    }),

    receivePersonnelSave: (personnelClerkSave, personnelStaffSave) => ({
        type: personnelSaveSuccess,
        isFetching: false,
        personnelClerkSave: personnelClerkSave,
        personnelStaffSave: personnelStaffSave
    }),

    personnelErrorSave: (message) => ({
        type: personnelSaveFailure,
        isFetching: false,
        personnelErrorSave: message
    }),

    savePersonnel: (body) => {

        return dispatch => {
            dispatch(personnelActionCreators.requestPersonnelSave());
            let headers = {
                'content-type': "application/json; charset=utf-8"
            }
            return partnerRequest((API_URL || process.env.REACT_APP_PERSONNEL_API_URL) + '/Personnel/api/SavePersonnelAsync/', 'PUT', headers, body, '')
                .then(result => {
                    if (result[0] === REQUEST_FAILURE) {
                        dispatch(personnelActionCreators.personnelErrorSave(result[1]))
                        return Promise.reject(result[1])
                    } else if (result[0] === REQUEST_SUCCESS) {
                        // Dispatch the success action
                        const personnelList = result[1]
                        const personnelClerkList = personnelList.filter(personnel => personnel.StaffRoleTypeID === 1)
                        const personnelStaffList = personnelList.filter(personnel => personnel.StaffRoleTypeID === 2)
                        dispatch(personnelActionCreators.receivePersonnelSave(personnelClerkList, personnelStaffList))
                    } else if (result[0] === NO_CONTENT) {
                        dispatch(personnelActionCreators.receivePersonnelSave([], []))
                        throw 'No personnel found'
                    } else if (result.name === 'AbortError') {
                        return Promise.reject("Aborted");
                    }
                }).catch(err => Promise.reject(err));
        }
    }
};


export const reducer = (state, action) => {

    state = state || initialState;

    switch (action.type) {
        case personnelReferencesRequest:
            return Object.assign({}, state, {
                isFetching: true
            })
        case personnelReferencesSuccess:
            return Object.assign({}, state, {
                isFetching: false,
                personnelStaffReferences: action.personnelStaffReferences,
                personnelClerkReferences: action.personnelClerkReferences,
                message: ''
            })
        case personnelReferencesFailure:
            return Object.assign({}, state, {
                isFetching: false,
                message: action.message
            })
        case personnelListRequest:
            return Object.assign({}, state, {
                isFetching: true
            })
        case personnelListSuccess:
            return Object.assign({}, state, {
                isFetching: false,
                personnelStaffList: action.personnelStaffList,
                personnelClerkList: action.personnelClerkList,
                personnelOtherList: action.personnelOtherList,
                message: ''
            })
        case personnelListFailure:
            return Object.assign({}, state, {
                isFetching: false,
                message: action.message
            })
        case roleTypesRequest:
            return Object.assign({}, state, {
                isFetching: true
            })
        case roleTypesSuccess:
            return Object.assign({}, state, {
                isFetching: false,
                roleTypes: action.roleTypes,
                message: ''
            })
        case roleTypesFailure:
            return Object.assign({}, state, {
                isFetching: false,
                message: action.message
            })            
        case personnelSaveRequest:
            return Object.assign({}, state, {
                isFetching: true
            })
        case personnelSaveSuccess:
            return Object.assign({}, state, {
                isFetching: false,
                personnelStaffSave: action.personnelStaffSave,
                personnelClerkSave: action.personnelClerkSave,
                personnelErrorSave: ''
            })
        case personnelSaveFailure:
            return Object.assign({}, state, {
                isFetching: false,
                personnelErrorSave: action.personnelErrorSave
            })
        default:
            return state
    }
};