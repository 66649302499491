import { partnerRequest, publicRequest } from "../services/request.service";

const API_URL = window.env ? window.env.API_SERVER : '';
const NO_CONTENT = 2;
const REQUEST_FAILURE = 1;
const REQUEST_SUCCESS = 0;

const docListSuccess = 'DOC_LIST_SUCCESS';
const docListFailure = 'DOC_LIST_FAILURE';

const docSuccess = 'DOC_SUCCESS';
const docFailure = 'DOC_FAILURE';

const keyRegistrationSuccess = "KEY_REGISTRATION_SUCCESS";
const keyRegistrationFailure = "KEY_REGISTRATION_FAILURE";

const confirmKeyRegistrationSuccess = "CONFIRM_KEY_REGISTRATION_SUCCESS";
const confirmKeyRegistrationFailure = "CONFIRM_KEY_REGISTRATION_FAILURE";

const savePartnerSuccess = "SAVE_PARTNER_SUCCESS";
const savePartnerFailure = "SAVE_PARTNER_FAILURE";

const partnerListSuccess = 'PARTNER_LIST_SUCCESS';
const partnerListFailure = 'PARTNER_LIST_FAILURE';

const getPartnerSuccess = 'GET_PARTNER_SUCCESS';
const getPartnerFailure = 'GET_PARTNER_FAILURE';

const getPartnerKeySuccess = 'GET_PARTNER_KEY_SUCCESS';
const getPartnerKeyFailure = 'GET_PARTNER_KEY_FAILURE';

const initialState = {
    docList: [],
    doc: '',
    docListError: '',
    docError: '',
    savePartnerError: '',
    keyRegistrationError: '',
    partnerListError: '',
    partnerError: '',
    partnerKeyError: ''
};

export const developersActionCreators = {

    receiveDocList: (content) => ({
        type: docListSuccess,
        docList: content
    }),

    docListError: (message) => ({
        type: docListFailure,
        docListError: message
    }),

    getDocList: (params) => {
        return dispatch => {
            let headers = {
                'content-type': "application/json; charset=utf-8"
            };

            return publicRequest((API_URL || process.env.REACT_APP_USER_API_URL) + '/ReportFileGeneration/api/GetApiListAsync', 'GET', headers, '', params)
                .then(result => {
                    if (result[0] === REQUEST_FAILURE) {
                        dispatch(developersActionCreators.docListError(result[1]))
                        return Promise.reject(result[1])
                    } else if (result[0] === REQUEST_SUCCESS) {
                        dispatch(developersActionCreators.receiveDocList(result[1].ApiDocumentations))
                    } else if (result[0] === NO_CONTENT) {
                        dispatch(developersActionCreators.docListError('No Content'))
                        return Promise.reject('No Content')
                    } else if (result.name === 'AbortError') {
                        return Promise.reject("Aborted");
                    }
                }).catch(err => Promise.reject(err))
        }
    },

    receiveDoc: (content) => ({
        type: docSuccess,
        doc: content
    }),

    docError: (message) => ({
        type: docFailure,
        docError: message
    }),

    getDoc: (params, partner) => {
        return dispatch => {
            let headers = {
                'content-type': "application/json; charset=utf-8"
            };

            return partner ? partnerRequest((API_URL || process.env.REACT_APP_USER_API_URL) + '/ReportFileGeneration/api/getpartnerapidocumentationasync', 'GET', headers, '', params)
                .then(result => {
                    if (result[0] === REQUEST_FAILURE) {
                        dispatch(developersActionCreators.docError(result[1]))
                        return Promise.reject(result[1])
                    } else if (result[0] === REQUEST_SUCCESS) {
                        dispatch(developersActionCreators.receiveDoc(result[1].ApiDocumentations[0].Documentation))
                    } else if (result[0] === NO_CONTENT) {
                        dispatch(developersActionCreators.docError('No Content'))
                        return Promise.reject('No Content')
                    } else if (result.name === 'AbortError') {
                        return Promise.reject("Aborted");
                    }
                }).catch(err => Promise.reject(err))
                : publicRequest((API_URL || process.env.REACT_APP_USER_API_URL) + '/ReportFileGeneration/api/getapidocumentationasync', 'GET', headers, '', params)
                    .then(result => {
                        if (result[0] === REQUEST_FAILURE) {
                            dispatch(developersActionCreators.docError(result[1]))
                            return Promise.reject(result[1])
                        } else if (result[0] === REQUEST_SUCCESS) {
                            dispatch(developersActionCreators.receiveDoc(result[1].ApiDocumentations[0].Documentation))
                        } else if (result[0] === NO_CONTENT) {
                            dispatch(developersActionCreators.docError('No Content'))
                            return Promise.reject('No Content')
                        } else if (result.name === 'AbortError') {
                            return Promise.reject("Aborted");
                        }
                    }).catch(err => Promise.reject(err))
        }
    },

    receiveKeyRegistration: (content) => ({
        type: keyRegistrationSuccess
    }),

    keyRegistrationError: (message) => ({
        type: keyRegistrationFailure,
        keyRegistrationError: message
    }),

    submitKeyRegistration: (params) => {
        return dispatch => {
            let headers = {
                'content-type': "application/json; charset=utf-8"
            }
            return publicRequest((API_URL || process.env.REACT_APP_USER_API_URL) + '/PartnerAuthentication/api/RegisterPartnerAsync', 'POST', headers, '', params)
                .then(result => {
                    if (result[0] === REQUEST_FAILURE) {
                        dispatch(developersActionCreators.keyRegistrationError(result[1]))
                        return Promise.reject(result[1])
                    } else if (result[0] === REQUEST_SUCCESS) {
                        dispatch(developersActionCreators.receiveKeyRegistration(result[1]))
                    } else if (result[0] === NO_CONTENT) {
                        dispatch(developersActionCreators.keyRegistrationError('No Content'))
                        return Promise.reject('No Content')
                    } else if (result.name === 'AbortError') {
                        return Promise.reject("Aborted");
                    }
                }).catch(err => Promise.reject(err))
        }
    },

    receiveConfirmKeyRegistration: (content) => ({
        type: confirmKeyRegistrationSuccess
    }),

    confirmKeyRegistrationError: (message) => ({
        type: confirmKeyRegistrationFailure,
        confirmKeyRegistrationError: message
    }),

    submitConfirmKeyRegistration: (body) => {
        return dispatch => {
            let headers = {
                'content-type': "application/json; charset=utf-8"
            }
            return publicRequest((API_URL || process.env.REACT_APP_USER_API_URL) + '/PartnerAuthentication/api/CompletePartnerRegistrationAsync', 'POST', headers, body, '')
                .then(result => {
                    if (result[0] === REQUEST_FAILURE) {
                        dispatch(developersActionCreators.confirmKeyRegistrationError(result[1]))
                        return Promise.reject(result[1])
                    } else if (result[0] === REQUEST_SUCCESS) {
                        dispatch(developersActionCreators.receiveConfirmKeyRegistration(result[1]))
                    } else if (result[0] === NO_CONTENT) {
                        dispatch(developersActionCreators.confirmKeyRegistrationError('No Content'))
                        return Promise.reject('No Content')
                    } else if (result.name === 'AbortError') {
                        return Promise.reject("Aborted");
                    }
                }).catch(err => Promise.reject(err))
        }
    },

    receiveSavePartner: (content) => ({
        type: savePartnerSuccess
    }),

    savePartnerError: (message) => ({
        type: savePartnerFailure,
        savePartnerError: message
    }),

    savePartner: (params) => {
        return dispatch => {
            let headers = {
                'content-type': "application/json; charset=utf-8"
            }
            return partnerRequest((API_URL || process.env.REACT_APP_USER_API_URL) + '/PartnerAuthentication/api/SavePartnerAsync', 'POST', headers, '', params)
                .then(result => {
                    if (result[0] === REQUEST_FAILURE) {
                        dispatch(developersActionCreators.savePartnerError(result[1]))
                        return Promise.reject(result[1])
                    } else if (result[0] === REQUEST_SUCCESS) {
                        dispatch(developersActionCreators.receiveSavePartner(result[1]))
                    } else if (result[0] === NO_CONTENT) {
                        dispatch(developersActionCreators.savePartnerError('No Content'))
                        return Promise.reject('No Content')
                    } else if (result.name === 'AbortError') {
                        return Promise.reject("Aborted");
                    }
                }).catch(err => Promise.reject(err))
        }
    },

    receivePartnerList: (content) => ({
        type: partnerListSuccess,
        partnerList: content
    }),

    partnerListError: (message) => ({
        type: partnerListFailure,
        partnerListError: message
    }),

    getPartnerList: () => {
        return dispatch => {
            let headers = {
                'content-type': "application/json; charset=utf-8"
            }
            return partnerRequest((API_URL || process.env.REACT_APP_USER_API_URL) + '/PartnerAuthentication/api/GetPartnersAsync', 'GET', headers, '', '')
                .then(result => {
                    if (result[0] === REQUEST_FAILURE) {
                        dispatch(developersActionCreators.partnerListError(result));
                        return Promise.reject(result[1]);
                    } else if (result[0] === REQUEST_SUCCESS) {
                        dispatch(developersActionCreators.receivePartnerList(result[1].PartnerList));
                    } else if (result[0] === NO_CONTENT) {
                        dispatch(developersActionCreators.receivePartnerList([]));
                    } else if (result.name === 'AbortError') {
                        return Promise.reject("Aborted");
                    }
                }).catch(err => Promise.reject(err));
        }
    },

    receivePartner: (content) => ({
        type: getPartnerSuccess,
        partner: content
    }),

    getPartnerError: (message) => ({
        type: getPartnerFailure,
        partnerError: message
    }),

    getPartner: (params) => {
        return dispatch => {
            let headers = {
                'content-type': "application/json; charset=utf-8"
            }
            return partnerRequest((API_URL || process.env.REACT_APP_USER_API_URL) + '/PartnerAuthentication/api/GetPartnerAsync', 'GET', headers, '', params)
                .then(result => {
                    if (result[0] === REQUEST_FAILURE) {
                        dispatch(developersActionCreators.getPartnerError(result));
                        return Promise.reject(result[1]);
                    } else if (result[0] === REQUEST_SUCCESS) {
                        dispatch(developersActionCreators.receivePartner(result[1].PartnerList[0]));
                    } else if (result[0] === NO_CONTENT) {
                        dispatch(developersActionCreators.receivePartner([]));
                    } else if (result.name === 'AbortError') {
                        return Promise.reject("Aborted");
                    }
                }).catch(err => Promise.reject(err));
        }
    },

    receivePartnerKey: (content) => ({
        type: getPartnerKeySuccess,
        partnerKey: content
    }),

    getPartnerKeyError: (message) => ({
        type: getPartnerKeyFailure,
        partnerKeyError: message
    }),

    getPartnerKey: () => {
        return dispatch => {
            let headers = {
                'content-type': "application/json; charset=utf-8"
            }
            return partnerRequest((API_URL || process.env.REACT_APP_USER_API_URL) + '/PartnerAuthentication/api/CheckForPartnerKeyAsync', 'GET', headers, '', '')
                .then(result => {
                    if (result[0] === REQUEST_FAILURE) {
                        dispatch(developersActionCreators.getPartnerKeyError(result));
                        return Promise.reject(result[1]);
                    } else if (result[0] === REQUEST_SUCCESS) {
                        dispatch(developersActionCreators.receivePartnerKey(result[1]));
                    } else if (result[0] === NO_CONTENT) {
                        dispatch(developersActionCreators.receivePartnerKey([]));
                    } else if (result.name === 'AbortError') {
                        return Promise.reject("Aborted");
                    }
                }).catch(err => Promise.reject(err));
        }
    },
}

export const reducer = (state, action) => {

    state = state || initialState;
    switch (action.type) {
        case docListSuccess:
            return Object.assign({}, state, {
                docList: action.docList,
                docListError: ''
            })
        case docListFailure:
            return Object.assign({}, state, {
                docListError: action.docListError
            })
        case docSuccess:
            return Object.assign({}, state, {
                doc: action.doc,
                docError: ''
            })
        case docFailure:
            return Object.assign({}, state, {
                docError: action.docError
            })
        case keyRegistrationSuccess:
            return Object.assign({}, state, {
                keyRegistrationError: ''
            })
        case keyRegistrationFailure:
            return Object.assign({}, state, {
                keyRegistrationError: action.keyRegistrationError
            })
        case confirmKeyRegistrationSuccess:
            return Object.assign({}, state, {
                confirmKeyRegistrationError: ''
            })
        case confirmKeyRegistrationFailure:
            return Object.assign({}, state, {
                confirmKeyRegistrationError: action.confirmKeyRegistrationError
            })
        case savePartnerSuccess:
            return Object.assign({}, state, {
                savePartnerError: ''
            })
        case savePartnerFailure:
            return Object.assign({}, state, {
                savePartnerError: action.savePartnerError
            })
        case partnerListSuccess:
            return Object.assign({}, state, {
                partnerList: action.partnerList,
                partnerListError: ''
            })
        case partnerListFailure:
            return Object.assign({}, state, {
                partnerListError: action.partnerListError
            })
        case getPartnerSuccess:
            return Object.assign({}, state, {
                partner: action.partner,
                partnerError: ''
            })
        case getPartnerFailure:
            return Object.assign({}, state, {
                partnerError: action.partnerError
            })
        case getPartnerKeySuccess:
            return Object.assign({}, state, {
                partnerKey: action.partnerKey,
                partnerKeyError: ''
            })
        case getPartnerFailure:
            return Object.assign({}, state, {
                partnerKeyError: action.partnerKeyError
            })
        default:
            return state
    }
};