import React from 'react';
import { Link } from 'react-router-dom';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import Select from 'react-select';
import { memberActionCreators } from '../../stores/lis-members-store';
import { sessionActionCreators } from '../../stores/lis-session-store';
import { navActionCreators } from '../../stores/lis-nav-store';

import { cancelRequest } from '../../services/request.service';

class PublicMemberLegislation extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            isLoaded: false,
            chamberOptions: [
                { label: 'Delegates', value: 'H' },
                { label: 'Senators', value: 'S' }
            ],
            hideBills: [],
            collapseAll: false,
            showPrefileOnly: false
        };

        this.setupDoc = this.setupDoc.bind(this);
        this.handleChamberChange = this.handleChamberChange.bind(this);
        this.toggleAll = this.toggleAll.bind(this);
        this.toggleRow = this.toggleRow.bind(this);
        this.toggleShowPrefile = this.toggleShowPrefile.bind(this);
    }

    setupDoc(sessionCode) {
        this.setState({
            isLoaded: false
        });
        this.props.actions.getSessionByCode(sessionCode).then(() => {
            let chamberCode = this.props.match.params.chambercode;
            const sessionData = this.props.session.selectedSession;
            this.props.actions.getMemberLegislation('sessionID=' + sessionData.SessionID + '&chamberCode=' + chamberCode)
                .then(() => {
                    if (this.props.members.memberLegislationError) {
                        throw this.props.members.memberLegislationError;
                    }
                    if (this.props.members.memberLegislation.length === 0) {
                        throw 'No Legislation Found';
                    }
                    this.setState({
                        isLoaded: true,
                        hideBills: new Array(this.props.members.memberLegislation.length).fill(true),
                        collapseAll: true
                    });
                })
                .catch(err => {
                    if (err === 'Aborted') {
                        return;
                    }
                    this.setState({
                        isLoaded: true,
                        message: err.toString()
                    });
                    console.log(err.toString());
                });
        });
    }

    handleChamberChange(value) {
        this.props.history.push('/session-details/' + this.props.nav.session + '/member-legislation/' + value.value);
    }

    toggleAll() {
        const opposite = !this.state.collapseAll;
        let hideBills = [...this.state.hideBills];
        hideBills.fill(opposite);
        this.setState({
            collapseAll: opposite,
            hideBills: hideBills
        });
    }

    toggleRow(index) {
        let hideBills = [...this.state.hideBills];
        hideBills[index] = !hideBills[index];
        this.setState({
            hideBills: hideBills
        });
    }

    toggleShowPrefile() {
        this.setState(state => ({
            showPrefileOnly: !state.showPrefileOnly
        }));
    }

    componentDidUpdate(prevProps) {
        // Setup the page if the global selected session changes. Change the URL to reflect the session change
        if (prevProps.nav.session !== this.props.nav.session) {
            this.props.history.push('/session-details/' + this.props.nav.session + '/member-legislation/' + this.props.match.params.chambercode);
            this.setupDoc(this.props.nav.session);
        } else if (this.props.nav.session !== this.props.match.params.sessioncode) {
            // If the url changes then update the global selected session with whatever the url is
            if (this.props.match.params.sessioncode) {
                this.props.actions.changeSession(this.props.match.params.sessioncode);
            }
        } else if (this.props.match.params.chambercode !== prevProps.match.params.chambercode) {
            this.setupDoc(this.props.nav.session);
        }
    }

    componentDidMount() {
        //Update the session selector component, which will run componentDidUpdate and call setupDoc on its own
        const sessionCode = this.props.match.params.sessioncode
        if (sessionCode) {
            if (this.props.nav.session !== sessionCode) {
                this.props.actions.changeSession(sessionCode)
            } else {
                this.setupDoc(sessionCode);
            }
        }
    }

    componentWillUnmount() {
        cancelRequest();
    }

    render() {
        const { isLoaded, message, chamberOptions, hideBills, collapseAll } = this.state;
        const chamberCode = this.props.match.params.chambercode;
        if (message) {
            return (<div className="center">{message}</div>)
        }
        const filteredMembers = this.props.members.memberLegislation.filter(member => member.ChamberCode === chamberCode)
        return (
            <div>
                <br />
                <div className="toolbar minutes-toolbar dlas-forms flex-row">
                    <label htmlFor="chamber-dropdown" className="screen-reader-only">Chamber</label>
                    <Select
                        value={chamberOptions.find(chamber => chamber.value === this.props.match.params.chambercode)}
                        options={chamberOptions}
                        id="chamber-dropdown"
                        onChange={this.handleChamberChange}
                        isDisabled={!isLoaded}
                    />
                    <div className="checkbox">
                        <input id="sr-only-prefile" style={{ verticalAlign: 'bottom' }} onChange={this.toggleShowPrefile} type="checkbox" checked={this.state.showPrefileOnly} />
                        <label htmlFor="sr-only-prefile">Only Show Prefile</label>
                    </div>
                </div>
                <br />
                {isLoaded ?
                    <div className="collapsible-table">
                        <div className="flex-row">
                            <div></div>
                            <button type="button" onClick={this.toggleAll} className="button-link">{collapseAll ? "Show Bills" : "Hide Bills"} +/-</button>
                        </div>
                        {filteredMembers.map((member, memberIndex) => {
                            const totalBills = member.Legislation.filter(leg => leg.LegislationClass === "Legislation").length;
                            const prefiledBills = member.Legislation.filter(leg => leg.IsPrefile && leg.LegislationClass === "Legislation").length;
                            const nonPrefiledBills = member.Legislation.filter(leg => !leg.IsPrefile && leg.LegislationClass === "Legislation").length;
                            const memberBills = this.state.showPrefileOnly ? member.Legislation.filter(leg => leg.IsPrefile) : member.Legislation;
                            return (<div key={memberIndex}>
                                <div className={(!hideBills[memberIndex] ? "opened " : '') + "flex-row section-header"}>
                                    <div>
                                        <span>
                                            <Link to={`/session-details/${this.props.nav.session}/member-information/${member.MemberNumber}/member-details`}>
                                                {member.MemberDisplayName}
                                            </Link>
                                        </span>
                                    </div>
                                    <div>
                                        <div className="extra-info">
                                            <span>Prefiled: {prefiledBills}/Not Prefiled: {nonPrefiledBills}/Total: {totalBills}</span>
                                            <button aria-expanded={!!hideBills[memberIndex]} onClick={() => this.toggleRow(memberIndex)} className={hideBills[memberIndex] ? "arrow-down" : "arrow-up"}>Show bills</button>
                                        </div>
                                    </div>
                                </div>
                                {!hideBills[memberIndex] ?
                                    <div className="section-body">
                                        {memberBills.map((leg, legIndex) =>
                                            <div key={legIndex} className="section-row inner-grid small-two-one">
                                                <Link to={`/bill-details/${leg.SessionCode}/${leg.LegislationNumber}`}>{leg.LegislationNumber}</Link>
                                                <span>{leg.Description}</span>
                                                <span style={{ textAlign: 'right' }} className="txt-greyed">{leg.IsPrefile ? "Prefiled" : "Not Prefiled"}</span>
                                            </div>
                                        )}
                                    </div>
                                    :
                                    <div className="section-body">
                                        <div className="section-row"></div>
                                    </div>
                                }
                            </div>)
                        })}
                    </div>
                    :
                    <div className="center-spinner spinner">Loading...</div>
                }
            </div>
        );
    }
}

export default connect(
    (state) => {
        const { members, session, nav } = state;
        return {
            members,
            session,
            nav
        }
    },
    (dispatch) => {
        return {
            actions: bindActionCreators(Object.assign({}, memberActionCreators, sessionActionCreators, navActionCreators), dispatch)
        }
    }
)(PublicMemberLegislation)
