import { partnerRequest, publicRequest } from '../services/request.service'

const API_URL = window.env ? window.env.API_SERVER : '';
const NO_CONTENT = 2;
const REQUEST_FAILURE = 1;
const REQUEST_SUCCESS = 0;

const chamberStatsSuccess = 'CHAMBER_STATS_SUCCESS';
const chamberStatsFailure = 'CHAMBER_STATS_FAILURE';

const committeeStatsSuccess = 'COMMITTEE_STATS_SUCCESS';
const committeeStatsFailure = 'COMMITTEE_STATS_FAILURE';

const initialState = {
    stat: [],
    statsError: '',
    committeeStats: [],
    committeeStatsError: ''
};

export const statActionCreators = {

    receiveChamberStats: (content) => ({
        type: chamberStatsSuccess,
        chamberStats: content
    }),

    chamberStatsError: (message) => ({
        type: chamberStatsFailure,
        chamberStatsError: message
    }),

    getChamberStats: (params) => {
        return dispatch => {
            return publicRequest((API_URL || process.env.REACT_APP_STATS_API_URL) + '/Statistics/api/getchamberstatisticsasync', 'GET', '', '', params)
                .then(result => {
                    if (result[0] === REQUEST_FAILURE) {
                        dispatch(statActionCreators.chamberStatsError(result[1]));
                        return Promise.reject(result[1]);
                    } else if (result[0] === REQUEST_SUCCESS) {
                        dispatch(statActionCreators.receiveChamberStats(result[1].ChamberStatistics));
                    } else if (result[0] === NO_CONTENT) {
                        dispatch(statActionCreators.receiveChamberStats([]));
                    } else if (result.name === 'AbortError') {
                        return Promise.reject("Aborted");
                    }
                }).catch(err => Promise.reject(err));
        }
    },

    receiveCommitteeStats: (content) => ({
        type: committeeStatsSuccess,
        committeeStats: content
    }),

    committeeStatsError: (message) => ({
        type: committeeStatsFailure,
        committeeStatsError: message
    }),

    getCommitteeStats: (params) => {
        return dispatch => {
            return publicRequest((API_URL || process.env.REACT_APP_STATS_API_URL) + '/Statistics/api/getcommitteestatisticsasync', 'GET', '', '', params)
                .then(result => {
                    if (result[0] === REQUEST_FAILURE) {
                        dispatch(statActionCreators.committeeStatsError(result[1]));
                        return Promise.reject(result[1]);
                    } else if (result[0] === REQUEST_SUCCESS) {
                        dispatch(statActionCreators.receiveCommitteeStats(result[1].CommitteeStatistics));
                    } else if (result[0] === NO_CONTENT) {
                        dispatch(statActionCreators.receiveCommitteeStats([]));
                    } else if (result.name === 'AbortError') {
                        return Promise.reject("Aborted");
                    }
                }).catch(err => Promise.reject(err));
        }
    }
};


export const reducer = (state, action) => {

    state = state || initialState;
    switch (action.type) {
        case chamberStatsSuccess:
            return Object.assign({}, state, {
                chamberStats: action.chamberStats,
                chamberStatsError: ''
            })
        case chamberStatsFailure:
            return Object.assign({}, state, {
                chamberStatsError: action.statsError
            })
        case committeeStatsSuccess:
            return Object.assign({}, state, {
                committeeStats: action.committeeStats,
                committeeStatsError: ''
            })
        case committeeStatsFailure:
            return Object.assign({}, state, {
                committeeStatsError: action.statsError
            })
        default:
            return state
    }
};