import React from 'react';
import * as Fields from '../../../lis-shared/lis-layout/components/lis-forms-component';
import '../../../stylesheets/lis-member-management/member-management.css';
import Select from 'react-select';

const customStyles = {
    option: (base, state) => ({
        ...base,
        fontSize: '0.8em',
    }),
    control: (base) => ({
        ...base,
        padding: '1px',
        margin: 0,
        minHeight: 0,
        fontSize: '0.8em',
    }),
    singleValue: (base, state) => {
        return { ...base, };
    }
}

const ContactInformation = props => {
    const { contactList, contactOptions } = props;
    return (
        <div>
            <h3>Contact Information</h3>
            {contactList.map((contact, contactIndex) => {
                if (!contact.IsActive) {
                    return (<div key={contactIndex}></div>)
                }
                return(<div key={contactIndex}>
                    <div className="flex-row">
                        <div className="multi-row four no-margin"/>                            
                        <div>
                            <button type="button" onClick={() => props.removeContact(contactIndex)} className="button danger">Remove Contact</button>
                        </div>
                    </div>
                    <div className="inner-grid two">
                        <div>
                            <div>
                                <span className="member-contact-title">Mailing Address</span>
                            </div>
                            <div className="multi-row three no-margin">
                                <div>
                                    <label>Address Line 1</label>
                                    <input
                                        type="text"
                                        placeholder="Address Line 1"
                                        value={contact.Address1}
                                        onChange={(e) => props.changeContact(contactIndex, 'Address1', e.target.value)}
                                    />
                                </div>
                            </div>
                            <div className="multi-row three no-margin">
                                <div>
                                    <label>Address Line 2</label>
                                    <input
                                        type="text"
                                        placeholder="Address Line 2"
                                        value={contact.Address2}
                                        onChange={(e) => props.changeContact(contactIndex, 'Address2', e.target.value)}
                                    />
                                </div>
                            </div>
                            <div className="multi-row three no-margin">
                                <div>
                                    <label>City</label>
                                    <input
                                        type="text"
                                        placeholder="City"
                                        value={contact.City}
                                        onChange={(e) => props.changeContact(contactIndex, 'City', e.target.value)}
                                    />
                                </div>
                                <div>
                                    <label>State</label>
                                    <Select
                                        styles={customStyles}
                                        options={Fields.stateOptions}
                                        value={Fields.stateOptions.find(option => option.label === contact.StateCode)}
                                        onChange={(val) => props.changeContact(contactIndex, 'StateCode', val.label)}
                                    />
                                </div>
                                <div>
                                    <label>Zip Code</label>
                                    <input
                                        type="number"
                                        placeholder="Zip Code"
                                        value={contact.ZipCode}
                                        onChange={(e) => props.changeContact(contactIndex, 'ZipCode', e.target.value)}
                                    />
                                </div>
                            </div>
                        </div>
                        <div>
                            <div>
                                <span className="member-contact-title">Contact</span>
                            </div>
                            <div className="multi-row three no-margin">
                                <div>
                                    <label>Phone Number</label>
                                    <input
                                        type="text"
                                        placeholder="Phone Number"
                                        value={contact.PhoneNumber}
                                        onChange={(e) => props.changeContact(contactIndex, 'PhoneNumber', e.target.value)}
                                    />
                                </div>
                            </div>
                            <div className="multi-row three no-margin">
                                <div>
                                    <label>Email Address</label>
                                    <input
                                        type="text"
                                        placeholder="Email Address"
                                        value={contact.EmailAddress}
                                        onChange={(e) => props.changeContact(contactIndex, 'EmailAddress', e.target.value)}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                    <hr />
                </div>
            )})}
            <button type="button" className="button" onClick={props.addContact}>Add Contact</button>
        </div>
    );

}

export default ContactInformation;








