import React from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { sessionActionCreators } from '../../../stores/lis-session-store';
import { billActionCreators } from '../../../stores/lis-legislation-store';
import * as Fields from '../../../lis-shared/lis-layout/components/lis-forms-component';

class SelectorSection extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
        };

        this.handleSessionChange = this.handleSessionChange.bind(this);
        this.handleLegTypeChange = this.handleLegTypeChange.bind(this);
        this.handleLegDateChange = this.handleLegDateChange.bind(this);
        this.handleLegNumChange = this.handleLegNumChange.bind(this);
    }

    handleSessionChange(value) {
        this.props.onChange('selectedSession', value);
    }

    handleLegTypeChange(value) {
        this.props.onChange('selectedLegType', value);
    }

    handleLegDateChange(value) {
        this.props.onChange('selectedLegDate', value)
    }

    handleLegNumChange(value) {
        if (value === "") {
            value = "clear";
        }

        this.props.onChange('selectedLegNum', { value: value })
    }

    render() {
        return (
            <div className="inner-grid four">
                <div>
                    <Fields.Dropdown
                        isDisabled={this.props.disable}
                        value={this.props.selectedSession}
                        options={this.props.sessionOptions}
                        id="sessionDropdown"
                        onChange={this.handleSessionChange}
                        placeholder="Select Session"
                    />
                </div>
                <div>
                    <Fields.Dropdown
                        isDisabled={this.props.disable}
                        value={this.props.selectedLegType}
                        options={this.props.legOptions}
                        id="legDropdown"
                        onChange={this.handleLegTypeChange}
                        placeholder="Select Legislation Version"
                    />
                </div>
                <div>
                    <Fields.Dropdown
                        value={this.props.selectedLegDate}
                        placeholder="Filter by Date"
                        options={this.props.legDateOptions}
                        id="billDateSearch"
                        onChange={(selectedOption, triggeredAction) => {
                            if (triggeredAction.action === 'clear') {
                                this.handleLegDateChange({ value: "clear" })
                            } else {
                                this.handleLegDateChange(selectedOption)
                            }
                        }}
                        isDisabled={this.props.disable || this.props.selectedLegType === ""}
                        isSearchable
                        isClearable
                    />
                </div>
                <div style={{ display: "grid", gridTemplateColumns: "1fr 50px" }}>
                    <input
                        value={this.props.selectedLegNum.value === "clear" ? "" : this.props.selectedLegNum.value}
                        id="billNumSearch"
                        onChange={(e) => this.handleLegNumChange(e.target.value)}
                        disabled={this.props.disable || this.props.selectedLegType === ""}
                        style={{ height: "100%", width: "100%" }}
                        placeholder="Filter by Bill Number"
                    />
                    <a className='icon delete' style={{ marginTop: "10px", marginLeft: "15px" }} onClick={() => this.handleLegNumChange("clear")}></a>
                </div>
            </div>
        );
    }
}

export default connect(
    (state) => {
        const { session, bills } = state;
        return {
            session,
            bills
        }
    },
    (dispatch) => {
        return {
            actions: bindActionCreators(Object.assign({}, sessionActionCreators, billActionCreators), dispatch)
        }
    }
)(SelectorSection)