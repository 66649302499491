import jwtDecode from 'jwt-decode';

const API_URL = window.env ? window.env.API_SERVER : '';
const graphToken = 'GRAPH_ID_TOKEN';
const idToken = 'LIS_ID_TOKEN';
const refreshToken = 'LIS_REFRESH_TOKEN';
const refreshTokenExpiration = 'LIS_REFRESH_TOKEN_EXPIRATION';
const claims = "LIS_CLAIMS";

const loginRequest = 'LOGIN_REQUEST';
const loginSuccess = 'LOGIN_SUCCESS';
const loginFailure = 'LOGIN_FAILURE';

const logoutRequest = 'LOGOUT_REQUEST';
const logoutSuccess = 'LOGOUT_SUCCESS';

const refreshTokenRequest = 'REFRESH_TOKEN_REQUEST';
const refreshTokenSuccess = 'REFRESH_TOKEN_SUCCESS';
const refreshTokenFailure = 'REFRESH_TOKEN_FAILURE';

const houseLoginRequest = 'HOUSE_ACCESS_TOKEN_REQUEST';
const houseLoginSuccess = 'HOUSE_ACCESS_TOKEN_SUCCESS';
const houseLoginFailure = 'HOUSE_ACCESS_TOKEN_FAILURE';

//const initialState = {
//    loggedIn: false
//};

// Decode the token, if it exists

const decodedToken = () => {
    const jwtToken = localStorage.getItem(idToken);

    const userData = {
        initials: '',
        name: '',
        chamber: '',
        email: '',
        claims: [],
        roles: [],
        scopes: [],
        resources: []        
    };

    if (jwtToken !== null && jwtToken !== 'undefined') {
        const decode = jwtDecode(jwtToken);
        const stringToken = JSON.stringify(decode);
        const parsedToken = JSON.parse(stringToken);
        userData.initials = parsedToken.given_name.charAt(0) + parsedToken.family_name.charAt(0);
        userData.name = parsedToken.given_name + ' ' + parsedToken.family_name;
        userData.expr = parsedToken.exp;
        userData.email = parsedToken.email
    }
    return userData;
}

const isTokenExpired = () => {
    const jwtToken = localStorage.getItem(idToken);

    var expr = '';

    if (jwtToken !== null && jwtToken !== 'undefined') {
        const decode = jwtDecode(jwtToken);
        const stringToken = JSON.stringify(decode);
        const parsedToken = JSON.parse(stringToken);

        expr = parsedToken.exp;
        return expr < new Date().getTime() / 1000;
    } else {
        return true;
    }
}

const parsedClaims = () => {
    const claimsObject = JSON.parse(localStorage.getItem(claims));

    let userClaims = {
        scopes: [],
        roles: [],
        resources: [],
        claims: []
    };

    if (claimsObject)
        for (let i = 0; i < claimsObject.length; i++) {
            userClaims.scopes.push(claimsObject[i].Scope)
            userClaims.roles.push(claimsObject[i].RoleName)
            userClaims.resources.push(claimsObject[i].Resource)
            userClaims.claims.push(claimsObject[i])
        }

    return userClaims;
}

export const authActionCreators = {

    // LOGIN THE USER

    requestLogin: (creds) => ({
        type: loginRequest,
        isFetching: true,
        initiateRedirect: false,
        creds
    }),

    receiveLogin: (user) => ({
        type: loginSuccess,
        isFetching: false,
        initiateRedirect: true,
        isAuthenticated: true,
        isTokenExpired: isTokenExpired(user.AccessToken),
        userProfile: decodedToken(user.AccessToken),
        userClaims: parsedClaims()
    }),

    loginError: (message) => ({
        type: loginFailure,
        isFetching: false,
        isAuthenticated: false,
        message
    }),

    loginUser: (creds) => {
        let config = {
            method: 'POST',
            headers: { "Content-Type": "application/json; charset=utf-8", "WebAPIKey": process.env.REACT_APP_API_KEY },
            body: JSON.stringify({ UserID: creds.userId, Password: creds.userPassword })
        }
        return dispatch => {
            // dispatch requestLogin and kickoff the call to the API
            dispatch(authActionCreators.requestLogin(creds));

            return fetch((API_URL || process.env.REACT_APP_AUTH_API_URL) + '/Authentication/api/LoginAsync', config)
                .then(response =>
                    response.json().then(user => ({ user, response }))
                ).then(({ user, response }) => {
                    if (response.status >= 400) {
                        // If there was a problem, 
                        // dispatch the error condition
                        dispatch(authActionCreators.loginError(user.FailureMessage))
                        return Promise.reject(user)
                    } else {
                        // If login was successful, set the token in local storage
                        let roles = [];
                        if (user.Claims && user.Claims.Roles) {
                            roles = user.Claims.Roles;
                        }
                        localStorage.setItem(claims, JSON.stringify(roles));
                        localStorage.setItem(idToken, user.AccessToken);
                        localStorage.setItem(refreshToken, user.RefreshToken);
                        localStorage.setItem(graphToken, user.GraphToken);
                        //604800000 is 7 days in milliseconds
                        localStorage.setItem(refreshTokenExpiration, new Date().getTime() + 604800000);
                        // Dispatch the success action
                        dispatch(authActionCreators.receiveLogin(user))
                    }
                }).catch(err => Promise.reject(err))
        }
    },

    // LOGOUT THE USER

    requestLogout: () => ({
        type: logoutRequest,
        isFetching: true,
        initiateRedirect: false,
        isAuthenticated: true
    }),

    receiveLogout: () => ({
        type: logoutSuccess,
        isFetching: false,
        initiateRedirect: false,
        isAuthenticated: false,
        userProfile: false,
        userClaims: false
    }),

    logoutUser: () => {
        return dispatch => {
            dispatch(authActionCreators.requestLogout());
            localStorage.removeItem(idToken);
            localStorage.removeItem(refreshToken);
            localStorage.removeItem(refreshTokenExpiration);
            localStorage.removeItem(claims);
            localStorage.removeItem(graphToken);
            dispatch(authActionCreators.receiveLogout())
        }
    },

    // RERESH ACCESS TOKEN

    requestRefreshToken: () => ({
        type: refreshTokenRequest,
        isFetching: true
    }),

    receiveRefreshToken: (user) => ({
        type: refreshTokenSuccess,
        isFetching: false,
        userProfile: decodedToken(user.AccessToken),
        userClaims: parsedClaims(),
        isTokenExpired: isTokenExpired(user.AccessToken)
    }),

    refreshTokenError: (message) => ({
        type: refreshTokenFailure,
        message
    }),

    refreshAccessToken: () => {
        let rtoken = localStorage.getItem(refreshToken);

        let config = {
            method: 'POST',
            headers: {
                "authorization": rtoken,
                "content-type": "application/json; charset=utf-8",
                "WebAPIKey": process.env.REACT_APP_API_KEY
            }
        }

        return dispatch => {
            // dispatch requestLogin and kickoff the call to the API
            dispatch(authActionCreators.requestRefreshToken());

            return fetch((API_URL || process.env.REACT_APP_AUTH_API_URL) + '/Authentication/api/RefreshAccessTokenAsync', config)
                .then(response => {
                    if (response.status > 399) {
                        dispatch(authActionCreators.refreshTokenError(response));
                        return Promise.reject(response);
                    }
                    return response
                })
                .then(response =>
                    response.json().then(user => ({ user, response }))
                ).then(({ user, response }) => {
                    // If refresh was successful, set the tokens in local storage
                    let roles = [];
                    if (user.Claims && user.Claims.Roles) {
                        roles = user.Claims.Roles;
                    }
                    localStorage.setItem(claims, JSON.stringify(roles));        
                    localStorage.setItem(idToken, user.AccessToken);
                    localStorage.setItem(refreshToken, user.RefreshToken);
                    //604800000 is 7 days in milliseconds
                    localStorage.setItem(refreshTokenExpiration, new Date().getTime() + 604800000);
                    // Dispatch the success action
                    dispatch(authActionCreators.receiveRefreshToken(user))
                }).catch(err => console.log("Error: ", err))
        }
    },

    requestHouseLogin: () => ({
        type: houseLoginRequest,
        isFetching: true
    }),

    receiveHouseLogin: (user) => ({
        type: houseLoginSuccess,
        isFetching: false,
        initiateRedirect: true,
        isAuthenticated: true,
        isTokenExpired: isTokenExpired(user.AccessToken),
        userProfile: decodedToken(user.AccessToken),
        userClaims: parsedClaims()
    }),

    houseLoginFailure: (message) => ({
        isFetching: false,
        type: houseLoginFailure,
        message
    }),

    houseLogin: (authToken, refreshToken, expirationDate) => {
        let config = {
            method: 'POST',
            headers: { "Content-Type": "application/json; charset=utf-8", "WebAPIKey": process.env.REACT_APP_API_KEY },            
        }

        return dispatch => {
            dispatch(authActionCreators.requestHouseLogin());

            return fetch((API_URL || process.env.REACT_APP_AUTH_API_URL) + '/Authentication/api/GetAccessTokenAsync?accesstoken=' + authToken, config)
                .then(response =>
                    response.json().then(user => ({ user, response }))
                ).then(({ user, response }) => {
                    if (response.status >= 400) {                        
                        dispatch(authActionCreators.houseLoginFailure(user.FailureMessage))
                        return Promise.reject(user)
                    } else {
                        let roles = [];
                        if (user.Claims && user.Claims.Roles) {
                            roles = user.Claims.Roles;
                        }
                        localStorage.setItem(claims, JSON.stringify(roles));
                        localStorage.setItem(idToken, user.AccessToken);
                        localStorage.setItem(refreshToken, refreshToken);
                        localStorage.setItem(graphToken, user.GraphToken);
                        localStorage.setItem(refreshTokenExpiration, expirationDate.getTime() + 604800000);

                        dispatch(authActionCreators.receiveHouseLogin(user))
                    }
                }).catch(err => Promise.reject(err))
        }
    }
};

export const reducer = (state, action) => {

    state = {
        ...state,
        isFetching: false,
        initiateRedirect: false,
        isAuthenticated: isTokenExpired() ? false : true,
        userProfile: decodedToken(),
        userClaims: parsedClaims(),
        isTokenExpired: isTokenExpired()        
    }

    switch (action.type) {
        case loginRequest:
            return Object.assign({}, state, {
                isFetching: true,
                initiateRedirect: false,
                user: action.creds
            })
        case loginSuccess:
            return Object.assign({}, state, {
                isFetching: false,
                initiateRedirect: true,
                isAuthenticated: true,
                errorMessage: '',
                userProfile: action.userProfile,
                userClaims: action.userClaims,
                isTokenExpired: action.isTokenExpired
            })
        case loginFailure:
            return Object.assign({}, state, {
                isFetching: false,
                initiateRedirect: false,
                isAuthenticated: false,
                errorMessage: action.message
            })
        case refreshTokenRequest:
            return Object.assign({}, state, {
                isFetching: true
            })
        case refreshTokenSuccess:
            return Object.assign({}, state, {
                isFetching: false,
                userProfile: action.userProfile,
                userClaims: action.userClaims,
                isTokenExpired: action.isTokenExpired,
                errorMessage: ''
            })
        case refreshTokenFailure:
            return Object.assign({}, state, {
                isFetching: false,
                initiateRedirect: false,
                isAuthenticated: false,
                errorMessage: action.message
            })
        case logoutSuccess:
            return Object.assign({}, state, {
                isFetching: true,
                initiateRedirect: false,
                isAuthenticated: false
            })
        case houseLoginRequest:
            return Object.assign({}, state, {
                isFetching: true,
                initiateRedirect: false
            })
        case houseLoginFailure:
            return Object.assign({}, state, {
                isFetching: false,
                initiateRedirect: false,
                isAuthenticated: false,
                errorMessage: action.message
            })
        case houseLoginSuccess:
            return Object.assign({}, state, {
                isFetching: false,
                initiateRedirect: true,
                isAuthenticated: true,
                errorMessage: '',
                userProfile: action.userProfile,
                userClaims: action.userClaims,
                isTokenExpired: action.isTokenExpired
            })
        default:
            return state
    }
};