import { partnerRequest, publicRequest } from '../services/request.service'

const API_URL = window.env ? window.env.API_SERVER : '';
const NO_CONTENT = 2;
const REQUEST_FAILURE = 1;
const REQUEST_SUCCESS = 0;

const agencyListSuccess = 'AGENCY_LIST_SUCCESS';
const agencyListFailure = 'AGENCY_LIST_FAILURE';

const initialState = {
    agencyList: [],
    agencyListError: '',
};

export const orgActionCreators = {

    receiveAgencyList: (content) => ({
        type: agencyListSuccess,
        agencyList: content
    }),

    agencyListError: (message) => ({
        type: agencyListFailure,
        agencyListError: message
    }),

    getAgencyList: (params) => {
        return dispatch => {
            return publicRequest((API_URL || process.env.REACT_APP_STATS_API_URL) + '/Organization/api/GetAgencyReferencesAsync', 'GET', '', '', '')
                .then(result => {
                    if (result[0] === REQUEST_FAILURE) {
                        dispatch(orgActionCreators.agencyListError(result[1]));
                        return Promise.reject(result[1]);
                    } else if (result[0] === REQUEST_SUCCESS) {
                        dispatch(orgActionCreators.receiveAgencyList(result[1].Agencies));
                    } else if (result[0] === NO_CONTENT) {
                        dispatch(orgActionCreators.receiveAgencyList([]));
                    } else if (result.name === 'AbortError') {
                        return Promise.reject("Aborted");
                    }
                }).catch(err => Promise.reject(err));
        }
    },
}

export const reducer = (state, action) => {

    state = state || initialState;
    switch (action.type) {
        case agencyListSuccess:
            return Object.assign({}, state, {
                agencyList: action.agencyList,
                agencyListError: ''
            })
        case agencyListFailure:
            return Object.assign({}, state, {
                agencyListError: action.agencyListError
            })
        default:
            return state
    }
};