import React from 'react';

function LoaderComponent(props) {
    let loadingCheck = false;
    //typeof doesn't differentiate between an array and an object so we have to do this type of check
    switch (Object.prototype.toString.call(props.data)) {
        case '[object Array]':
            if (props.data.length > 0) loadingCheck = true;
            break;
        case '[object Object]':
            if (props.data) loadingCheck = true;
            break;
        default:
            if (props.data) loadingCheck = true;
    }
    return (
        <div className={`spinner-loader ${loadingCheck ? 'hide' : 'show'}`}>
            <div className={`${props.className} spinner`}>Loading results...</div>
        </div>
    )
}

export default (LoaderComponent);