import { partnerRequest, publicRequest } from '../services/request.service'

const API_URL = window.env ? window.env.API_SERVER : '';
const NO_CONTENT = 2;
const REQUEST_FAILURE = 1;
const REQUEST_SUCCESS = 0;

const commListSuccess = 'COMM_LIST_SUCCESS';
const commListFailure = 'COMM_LIST_FAILURE';

const commGetSuccess = 'COMM_GET_SUCCESS';
const commGetFailure = 'COMM_GET_FAILURE';

const commSaveSuccess = 'COMM_SAVE_SUCCESS';
const commSaveFailure = 'COMM_SAVE_FAILURE';

const commFileSaveSuccess = 'COMM_FILE_SAVE_SUCCESS';
const commFileSaveFailure = 'COMM_FILE_SAVE_FAILURE';

const commCreateSuccess = 'COMM_CREATE_SUCCESS';
const commCreateFailure = 'COMM_CREATE_FAILURE';

const commCategoriesSuccess = "COMM_CATEGORIES_SUCCESS";
const commCategoriesFailure = "COMM_CATEGORIES_FAILURE";

const getCommunicationAssignmentsSuccess = "GET_COMMUNICATION_MAPPINGS_SUCCESS";
const getCommunicationAssignmentsFailure = "GET_COMMUNICATION_MAPPINGS_FAILURE";

const getCommunicationSummaryListSuccess = "GET_COMMUNICATION_SUMMARY_LIST_SUCCESS";
const getCommunicationSummaryListFailure = "GET_COMMUNICATION_SUMMARY_LIST_FAILURE";

const getAdminCommunicationAssignmentsSuccess = "GET_ADMIN_COMMUNICATION_MAPPINGS_SUCCESS";
const getAdminCommunicationAssignmentsFailure = "GET_ADMIN_COMMUNICATION_MAPPINGS_FAILURE";

const saveCommunicationAssignmentSuccess = "SAVE_COMMUNICATION_MAPPING_SUCCESS";
const saveCommunicationAssignmentFailure = "SAVE_COMMUNICATION_MAPPING_FAILURE";

const joinCommFileSuccess = "JOIN_COMM_FILE_SUCCESS";
const joinCommFileFailure = "JOIN_COMM_FILE_FAILURE";

const toggleChamber = "COMM_TOGGLE_CHAMBER";

const initialState = {
    commList: [],
    commListError: '',
    commCreate: '',
    commCreateError: '',
    commGet: '',
    commGetError: '',
    commSave: '',
    commSaveError: '',
    commFileSave: '',
    commFileSaveError: '',
    commCategories: [],
    commCategoriesError: '',
    commChamber: '',
    joinCommFile: '',
    joinCommFileError: '',
    communicationSummaryList: [],
    communicationSummaryListError: '',
    communicationAssignments: [],
    getCommunicationAssignmentsError: '',
    adminCommunicationAssignments: [],
    getAdminCommunicationAssignmentsError: '',
    communicationAssignmentSave: [],
    saveCommunicationAssignmentsError: ''
};

export const communicationsActionCreators = {

    receiveCommList: (result) => ({
        type: commListSuccess,
        commList: result
    }),

    commListError: (message) => ({
        type: commListFailure,
        commList: [],
        commListError: message
    }),

    getCommList: (params, partner) => {
        return dispatch => {
            let headers = {
                'content-type': "application/json; charset=utf-8"
            }
            let request = '';
            if (partner) {
                request = partnerRequest((API_URL || process.env.REACT_APP_LEGISLATION_COMMUNICATIONS_API_URL) + '/LegislationCommunications/api/GetPartnerLegislationCommunicationsListAsync', 'GET', headers, '', params)
            } else {
                request = publicRequest((API_URL || process.env.REACT_APP_LEGISLATION_COMMUNICATIONS_API_URL) + '/LegislationCommunications/api/GetLegislationCommunicationsListAsync', 'GET', headers, '', params)
            }
            return request
                .then(result => {
                    if (result[0] === REQUEST_FAILURE) {
                        dispatch(communicationsActionCreators.commListError(result[1]))
                        return Promise.reject(result[1])
                    } else if (result[0] === REQUEST_SUCCESS) {
                        dispatch(communicationsActionCreators.receiveCommList(result[1].CommunicationsList))
                    } else if (result[0] === NO_CONTENT) {
                        dispatch(communicationsActionCreators.receiveCommList([]))
                    } else if (result.name === 'AbortError') {
                        return Promise.reject("Aborted");
                    }
                }).catch(err => Promise.reject(err))
        }
    },

    receiveCommSummaryList: (result) => ({
        type: getCommunicationSummaryListSuccess,
        communicationSummaryList: result
    }),

    commSummaryListError: (message) => ({
        type: getCommunicationSummaryListFailure,
        communicationSummaryList: [],
        communicationSummaryListError: message
    }),

    getCommunicationSummaryList: (params) => {
        return dispatch => {
            let headers = {
                'content-type': "application/json; charset=utf-8"
            }
            return partnerRequest((API_URL || process.env.REACT_APP_LEGISLATION_COMMUNICATIONS_API_URL) + '/LegislationCommunications/api/GetPartnerLegislationCommunicationSummaryListAsync?', 'GET', headers, '', params)
                .then(result => {
                    if (result[0] === REQUEST_FAILURE) {
                        dispatch(communicationsActionCreators.commSummaryListError(result[1]))
                        return Promise.reject(result[1])
                    } else if (result[0] === REQUEST_SUCCESS) {
                        dispatch(communicationsActionCreators.receiveCommSummaryList(result[1].CommunicationSummariesResponse))
                    } else if (result[0] === NO_CONTENT) {
                        dispatch(communicationsActionCreators.receiveCommSummaryList([]))
                    } else if (result.name === 'AbortError') {
                        return Promise.reject("Aborted");
                    }
                }).catch(err => Promise.reject(err))
        }
    },

    receiveCommGet: (result) => ({
        type: commGetSuccess,
        commGet: result
    }),

    commGetError: (message) => ({
        type: commGetFailure,
        commGetError: message
    }),

    getComm: (params, partner) => {
        return dispatch => {
            let headers = {
                'content-type': "application/json; charset=utf-8"
            }
            let request = '';
            if (partner) {
                request = partnerRequest((API_URL || process.env.REACT_APP_LEGISLATION_COMMUNICATIONS_API_URL) + '/LegislationCommunications/api/GetPartnerLegislationCommunicationsByIDAsync', 'GET', headers, '', params);
            } else {
                request = publicRequest((API_URL || process.env.REACT_APP_LEGISLATION_COMMUNICATIONS_API_URL) + '/LegislationCommunications/api/GetLegislationCommunicationsByIDAsync', 'GET', headers, '', params);
            }
            return request
                .then(result => {
                    if (result[0] === REQUEST_FAILURE) {
                        dispatch(communicationsActionCreators.commGetError(result[1]));
                        return Promise.reject(result[1])
                    } else if (result[0] === REQUEST_SUCCESS) {
                        // Dispatch the success action
                        dispatch(communicationsActionCreators.receiveCommGet(result[1].CommunicationResponse[0]));
                    } else if (result[0] === NO_CONTENT) {
                        dispatch(communicationsActionCreators.commGetError('No Content'))
                        return Promise.reject('No Content')
                    } else if (result.name === 'AbortError') {
                        return Promise.reject("Aborted");
                    }
                }).catch(err => Promise.reject(err));
        }
    },

    receiveCommCategories: (result) => ({
        type: commCategoriesSuccess,
        commCategories: result
    }),

    commCategoriesError: (message) => ({
        type: commCategoriesFailure,
        commCategoriesError: message
    }),

    getCommCategories: (params) => {
        return dispatch => {
            let headers = {
                'content-type': "application/json; charset=utf-8"
            }
            return publicRequest((API_URL || process.env.REACT_APP_LEGISLATION_COMMUNICATIONS_API_URL) + '/LegislationCommunications/api/GetCommunicationCategoryReferencesAsync', 'GET', headers, '', params)
                .then(result => {
                    if (result[0] === REQUEST_FAILURE) {
                        dispatch(communicationsActionCreators.commCategoriesError(result[1]));
                        return Promise.reject(result[1])
                    } else if (result[0] === REQUEST_SUCCESS) {
                        // Dispatch the success action
                        dispatch(communicationsActionCreators.receiveCommCategories(result[1].CommunicationCategoryTypesReference));
                    } else if (result[0] === NO_CONTENT) {
                        dispatch(communicationsActionCreators.commCategoriesError('No Content'))
                    } else if (result.name === 'AbortError') {
                        return Promise.reject("Aborted");
                    }
                }).catch(err => Promise.reject(err));
        }
    },

    receiveCommunicationAssignments: (responseObj) => ({
        type: getCommunicationAssignmentsSuccess,
        communicationAssignments: responseObj.CommunicationAssignments
    }),

    getCommunicationAssignmentsError: error => ({
        type: getCommunicationAssignmentsFailure,
        getCommunicationAssignmentsError: error
    }),

    getCommunicationAssignments: () => {
        return dispatch => {
            let headers = {
                'content-type': "application/json; charset=utf-8"
            }
            return partnerRequest((API_URL || process.env.REACT_APP_LEGISLATION_COMMUNICATIONS_API_URL) + '/LegislationCommunications/api/GetCommunicationAssignmentsAsync', 'GET', headers, '', '')
                .then(result => {
                    if (result[0] === REQUEST_FAILURE) {
                        dispatch(communicationsActionCreators.getCommunicationAssignmentsError(result[1]))
                        return Promise.reject(result[1])
                    } else if (result[0] === REQUEST_SUCCESS) {
                        // Dispatch the success action
                        dispatch(communicationsActionCreators.receiveCommunicationAssignments(result[1]))
                    } else if (result[0] === NO_CONTENT) {
                        dispatch(communicationsActionCreators.getCommunicationAssignmentsError('No Content'))
                        return Promise.reject('No Content')
                    } else if (result.name === 'AbortError') {
                        return Promise.reject("Aborted");
                    }
                }).catch(err => Promise.reject(err))
        }
    },

    receiveAdminCommunicationAssignments: (responseObj) => ({
        type: getAdminCommunicationAssignmentsSuccess,
        adminCommunicationAssignments: responseObj.CommunicationAssignments
    }),

    getAdminCommunicationAssignmentsError: error => ({
        type: getAdminCommunicationAssignmentsFailure,
        getAdminCommunicationAssignmentsError: error
    }),

    getAdminCommunicationAssignments: () => {
        return dispatch => {
            let headers = {
                'content-type': "application/json; charset=utf-8"
            }
            return partnerRequest((API_URL || process.env.REACT_APP_LEGISLATION_COMMUNICATIONS_API_URL) + '/LegislationCommunications/api/GetAdminCommunicationAssignmentsAsync', 'GET', headers, '', '')
                .then(result => {
                    if (result[0] === REQUEST_FAILURE) {
                        dispatch(communicationsActionCreators.getAdminCommunicationAssignmentsError(result[1]))
                        return Promise.reject(result[1])
                    } else if (result[0] === REQUEST_SUCCESS) {
                        // Dispatch the success action
                        dispatch(communicationsActionCreators.receiveAdminCommunicationAssignments(result[1]))
                    } else if (result[0] === NO_CONTENT) {
                        dispatch(communicationsActionCreators.getAdminCommunicationAssignmentsError('No Content'))
                        return Promise.reject('No Content')
                    } else if (result.name === 'AbortError') {
                        return Promise.reject("Aborted");
                    }
                }).catch(err => Promise.reject(err))
        }
    },

    receiveCommunicationAssignmentSave: (responseObj) => ({
        type: saveCommunicationAssignmentSuccess,
        communicationAssignmentSave: responseObj
    }),

    saveCommunicationAssignmentError: error => ({
        type: saveCommunicationAssignmentFailure,
        saveCommunicationAssignmentsError: error
    }),

    saveCommunicationAssignment: (body) => {
        return dispatch => {
            let headers = {
                'content-type': "application/json; charset=utf-8"
            }
            return partnerRequest((API_URL || process.env.REACT_APP_LEGISLATION_COMMUNICATIONS_API_URL) + '/LegislationCommunications/api/SaveCommunicationAssignmentAsync', 'POST', headers, body, '')
                .then(result => {
                    if (result[0] === REQUEST_FAILURE) {
                        dispatch(communicationsActionCreators.saveCommunicationAssignmentError(result[1]))
                        return Promise.reject(result[1])
                    } else if (result[0] === REQUEST_SUCCESS) {
                        // Dispatch the success action
                        dispatch(communicationsActionCreators.receiveCommunicationAssignmentSave(JSON.parse(result[1].Message)))
                    } else if (result[0] === NO_CONTENT) {
                        dispatch(communicationsActionCreators.saveCommunicationAssignmentError('No Content'))
                        return Promise.reject('No Content')
                    } else if (result.name === 'AbortError') {
                        return Promise.reject("Aborted");
                    }
                }).catch(err => Promise.reject(err))
        }
    },

    receiveCommSave: (result) => ({
        type: commSaveSuccess,
        commSave: result
    }),

    commSaveError: (message) => ({
        type: commSaveFailure,
        commSaveError: message
    }),

    saveComm: (body) => {
        return dispatch => {
            let headers = {
                'content-type': "application/json; charset=utf-8"
            }
            return partnerRequest((API_URL || process.env.REACT_APP_LEGISLATION_COMMUNICATIONS_API_URL) + '/LegislationCommunications/api/SaveLegislationCommunicationAsync', 'POST', headers, body, '')
                .then(result => {
                    if (result[0] === REQUEST_FAILURE) {
                        dispatch(communicationsActionCreators.saveCommError(result[1]));
                        return Promise.reject(result[1])
                    } else if (result[0] === REQUEST_SUCCESS) {
                        try {
                            const saveCommunicationResponse = JSON.parse(result[1].Message);
                            dispatch(communicationsActionCreators.receiveCommSave(saveCommunicationResponse[0]));
                        } catch {
                            // Message does not contain json. 
                            dispatch(communicationsActionCreators.receiveCommSave(result[1].Message));
                        }
                        // The save can go fine but the failure message can contain information about the minutes save failing
                        if (result[1].FailureMessage) {
                            return result[1].FailureMessage;
                        }
                    } else if (result[0] === NO_CONTENT) {
                        dispatch(communicationsActionCreators.saveCommError('No Content'))
                        return Promise.reject('No Content')
                    } else if (result.name === 'AbortError') {
                        return Promise.reject("Aborted");
                    }
                }).catch(err => Promise.reject(err));
        }
    },

    receiveCommFileSave: (result) => ({
        type: commFileSaveSuccess,
        commFileSave: result
    }),

    commFileSaveError: (message) => ({
        type: commFileSaveFailure,
        commFileSaveError: message
    }),

    saveCommFile: (params) => {
        return dispatch => {
            let headers = {
                'content-type': "application/json; charset=utf-8"
            }
            return partnerRequest((API_URL || process.env.REACT_APP_LEGISLATION_COMMUNICATIONS_FILE_GEN_API_URL) + '/CommunicationFileGeneration/api/ProcessCommunicationFilesAsync', 'POST', headers, '', params, true)
                .then(result => {
                    if (result[0] === REQUEST_FAILURE) {
                        dispatch(communicationsActionCreators.commFileSaveError(result[1]));
                        return Promise.reject(result[1])
                    } else if (result[0] === REQUEST_SUCCESS) {
                        // Dispatch the success action
                        dispatch(communicationsActionCreators.receiveCommFileSave(result[1]));
                    } else if (result[0] === NO_CONTENT) {
                        dispatch(communicationsActionCreators.commFileSaveError('No Content'));
                        return Promise.reject('No Content')
                    }
                }).catch(err => Promise.reject(err));
        }
    },

    receiveJoinCommFile: (result) => ({
        type: joinCommFileSuccess,
        joinCommFile: result
    }),

    joinCommFileError: (message) => ({
        type: joinCommFileFailure,
        joinCommFileError: message
    }),

    joinCommFile: (body) => {
        return dispatch => {
            let headers = {
                'content-type': "application/json; charset=utf-8"
            }
            return partnerRequest((API_URL || process.env.REACT_APP_LEGISLATION_FILE_GEN_API_URL) + '/LegislationFileGeneration/api/JoinLegislationCommunicationsAsync', 'POST', headers, body, '', true)
                .then(result => {
                    if (result[0] === REQUEST_FAILURE) {
                        dispatch(communicationsActionCreators.joinCommFileError(result[1]));
                        return Promise.reject(result[1]);
                    } else if (result[0] === REQUEST_SUCCESS) {
                        // Dispatch the success action
                        dispatch(communicationsActionCreators.receiveJoinCommFile(result[1]));
                    } else if (result[0] === NO_CONTENT) {
                        dispatch(communicationsActionCreators.joinCommFileError('No Content'));
                        return Promise.reject('No Content');
                    }
                }).catch(err => Promise.reject(err));
        }
    },

    receiveCommCreate: (result) => ({
        type: commCreateSuccess,
        commCreate: result
    }),

    commCreateError: (message) => ({
        type: commCreateFailure,
        commCreateError: message
    }),

    createComm: (body) => {
        return dispatch => {
            let headers = {
                'content-type': "application/json; charset=utf-8"
            }
            return partnerRequest((API_URL || process.env.REACT_APP_LEGISLATION_COMMUNICATIONS_API_URL) + '/LegislationCommunications/api/CreateLegislationCommunicationAsync', 'POST', headers, body, '')
                .then(result => {
                    if (result[0] === REQUEST_FAILURE) {
                        dispatch(communicationsActionCreators.commCreateError(result[1]));
                        return Promise.reject(result[1])
                    } else if (result[0] === REQUEST_SUCCESS) {
                        // Dispatch the success action
                        const createCommunicationResponse = JSON.parse(result[1].Message);
                        dispatch(communicationsActionCreators.receiveCommCreate(createCommunicationResponse[0]));
                    } else if (result[0] === NO_CONTENT) {
                        dispatch(communicationsActionCreators.commCreateError('No Content'))
                        return Promise.reject('No Content')
                    } else if (result.name === 'AbortError') {
                        return Promise.reject("Aborted");
                    }
                }).catch(err => Promise.reject(err));
        }
    },

    toggleChamber: (value) => ({
        type: toggleChamber,
        commChamber: value
    })
}

export const reducer = (state, action) => {

    state = state || initialState;

    switch (action.type) {
        case commListSuccess:
            return Object.assign({}, state, {
                commList: action.commList,
                commListError: ''
            })
        case commListFailure:
            return Object.assign({}, state, {
                commListError: action.commListError
            })
        case getCommunicationSummaryListSuccess:
            return Object.assign({}, state, {
                communicationSummaryList: action.communicationSummaryList,
                communicationSummaryListError: ''
            })
        case getCommunicationSummaryListFailure:
            return Object.assign({}, state, {
                communicationSummaryListError: action.communicationSummaryListError
            })
        case commGetSuccess:
            return Object.assign({}, state, {
                commGet: action.commGet,
                commGetError: ''
            })
        case commGetFailure:
            return Object.assign({}, state, {
                commGetError: action.commGetError
            })
        case commCategoriesSuccess:
            return Object.assign({}, state, {
                commCategories: action.commCategories,
                commCategoriesError: ''
            })
        case commCategoriesFailure:
            return Object.assign({}, state, {
                commCategoriesError: action.commCategoriesError
            })
        case getCommunicationAssignmentsFailure:
            return Object.assign({}, state, {
                getCommunicationAssignmentsError: action.getCommunicationAssignmentsError
            });
        case getCommunicationAssignmentsSuccess:
            return Object.assign({}, state, {
                communicationAssignments: action.communicationAssignments,
                getCommunicationAssignmentsError: ''
            });
        case getAdminCommunicationAssignmentsFailure:
            return Object.assign({}, state, {
                getAdminCommunicationAssignmentsError: action.getAdminCommunicationAssignmentsError
            });
        case getAdminCommunicationAssignmentsSuccess:
            return Object.assign({}, state, {
                adminCommunicationAssignments: action.adminCommunicationAssignments,
                getAdminCommunicationAssignmentsError: ''
            });
        case saveCommunicationAssignmentFailure:
            return Object.assign({}, state, {
                saveCommunicationAssignmentsError: action.saveCommunicationAssignmentsError
            });
        case saveCommunicationAssignmentSuccess:
            return Object.assign({}, state, {
                communicationAssignmentSave: action.communicationAssignmentSave,
                saveCommunicationAssignmentsError: ''
            });
        case commSaveSuccess:
            return Object.assign({}, state, {
                commSave: action.commSave,
                commSaveError: ''
            })
        case commSaveFailure:
            return Object.assign({}, state, {
                commSaveError: action.commSaveError
            })
        case commFileSaveSuccess:
            return Object.assign({}, state, {
                commFileSave: action.commFileSave,
                commFileSaveError: ''
            })
        case commFileSaveFailure:
            return Object.assign({}, state, {
                commFileSaveError: action.commFileSaveError
            })
        case joinCommFileSuccess:
            return Object.assign({}, state, {
                joinCommFile: action.joinCommFile,
                joinCommFileError: ''
            })
        case joinCommFileFailure:
            return Object.assign({}, state, {
                joinCommFileError: action.joinCommFileError
            })
        case commCreateSuccess:
            return Object.assign({}, state, {
                commCreate: action.commCreate,
                commCreateError: ''
            })
        case commCreateFailure:
            return Object.assign({}, state, {
                commCreateError: action.commCreateError
            })
        case toggleChamber:
            return Object.assign({}, state, {
                commChamber: action.commChamber
            })
        default:
            return state
    }
};