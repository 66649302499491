
import './stylesheets/shared/global.css';
import './stylesheets/lis-public-view/public-view.css';
import './stylesheets/shared/mobile.css';
import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { ConnectedRouter } from 'connected-react-router'
import App from './App';
import registerServiceWorker from './registerServiceWorker';
import configureStore, { history } from './configureStore';
import { ApplicationInsights } from '@microsoft/applicationinsights-web'
import { PublicClientApplication } from "@azure/msal-browser";
import { MsalProvider } from "@azure/msal-react";
import { msalConfig } from "./authConfig";

const msalInstance = new PublicClientApplication(msalConfig);

const store = configureStore();
const rootElement = document.getElementById('root');

//Setup the app to use Azure Application Insights.
let iKey = '';
if (window.env.API_SERVER === 'QA') {
    iKey = 'df7a0319-ce88-47d1-9283-fdf1153bff55';
} else if (window.env.API_SERVER === 'Test') {
    iKey = '0c3c8e03-65f5-4a51-8f94-21301445bf6d';
}

if (iKey) {
    const appInsights = new ApplicationInsights({
        config: {
            instrumentationKey: iKey
        }
    });
    appInsights.loadAppInsights();
}

window.store = store;
ReactDOM.render(
    <Provider store={store}>
        <ConnectedRouter history={history}>
            <MsalProvider instance={msalInstance}>
                <App />
            </MsalProvider>
        </ConnectedRouter>
    </Provider>,
    rootElement);

registerServiceWorker();
