import React from 'react';
import { checkToken } from '../../../services/request.service'

class Popup extends React.Component  {
    constructor(props) {
        super(props);
        this.state = {
        };
        this.closePopup = this.closePopup.bind(this);
        this.logOut = this.logOut.bind(this);
    }

    closePopup() {
        checkToken()
            .then(this.props.togglePopup());
    }

    logOut() {
        this.props.logOut();
        this.props.togglePopup();
    }

    componentDidMount() {
        if (this.props.isIdle) {
            this.timerHandle = setTimeout(() => {
                this.props.logOut();
                this.props.togglePopup();
                this.timerHandle = 0
            }, 60000 * 15);
        }
    }

    componentWillUnmount = () => {
        if (this.timerHandle) {
            clearTimeout(this.timerHandle);
            this.timerHandle = 0;
        }
    };

    render() {
        return (
            <div className='popup'>
                <div className='popup-inner'>
                    <p>{this.props.text}</p>

                        <div>
                            <button className="button primary" onClick={this.closePopup}>Yes</button>
                            <button className="button secondary" onClick={this.logOut}>No</button>
                        </div>
                    {/*this.props.deletionWarning &&
                        <div>
                        <button className="button primary" onClick={this.props.togglePopup}>Continue</button>
                            <button className="button secondary" onClick={this.props.logOut}>Cancel</button>
                        </div>
                    */}
                </div>
            </div>
        );
    }
}

export default Popup;