import React from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { userActionCreators } from '../../../stores/lis-user-store';
import { cancelRequest } from '../../../services/request.service';
import { Link } from 'react-router-dom';

const calculateNumberOfCriteriaMet = (password) => {
    let criteriaMet = 0;
    if (password.search(/[a-z]/) > -1) { criteriaMet++; }
    if (password.search(/[A-Z]/) > -1) { criteriaMet++; }
    if (password.search(/\d/) > -1) { criteriaMet++; }
    if (password.search(/[@^$!%*?=\-_+&\[\]{}|\\:'\,\.\?\/`~""\(\);<>]/) > -1) { criteriaMet++; }

    return criteriaMet;
}

class CreateAccount extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            firstname: '',
            lastname: '',
            password: '',
            passwordconfirm: '',
            showPassword: false
        };

        this.handleTextChange = this.handleTextChange.bind(this);
        this.toggleShowPassword = this.toggleShowPassword.bind(this);
        this.checkName = this.checkName.bind(this);
        this.checkPassword = this.checkPassword.bind(this);
        this.checkPasswordConfirm = this.checkPasswordConfirm.bind(this);
        this.toggleShowTooltip = this.toggleShowTooltip.bind(this);
        this.handleClickOutside = this.handleClickOutside.bind(this);
        this.createAccount = this.createAccount.bind(this);

        this.infoRef = React.createRef();
        this.infoButtonRef = React.createRef();
    }

    toggleShowPassword() {
        this.setState(state => ({
            showPassword: !state.showPassword
        }));
    }

    handleTextChange(e, key) {
        const val = e.target.value;
        this.setState({
            [key]: val
        });
    }

    checkName() {
        if (!this.state.firstname || !this.state.lastname) {
            this.setState({
                nameError: 'first and last name are required'
            });
            return true;
        }
        this.setState({
            nameError: false
        });
    }

    checkPassword() {
        this.setState({ showHelp: false })
        if (this.state.password.length < 10) {
            this.setState({ passwordError: 'password must be at least 10 characters long' });
            return true;
        }
        if (this.state.password.length > 256) {
            this.setState({ passwordError: 'password must be 256 characters or fewer' });
            return true;
        }
        if (this.state.password.search(/^(?:(?=.*[a-z])(?:(?=.*[A-Z])(?=.*[\d@$!%*?=\-_+&\[\]{}|\\:'\,\.\?\/`~""\(\);<>])|(?=.*[@^$!%*?=\-_+&\[\]{}|\\:'\,\.\?\/`~""\(\);<>])(?=.*\d))|(?=.*[@^$!%*?=\-_+&\[\]{}|\\:'\,\.\?\/`~""\(\);<>])(?=.*[A-Z])(?=.*\d)).{10,256}$/) === -1) {
            this.setState({ passwordError: 'invalid password', showHelp: true });
            return true;
        } else {
            this.setState({ showHelp: false })
        }
        if (this.state.password === this.state.currentpassword) {
            this.setState({ passwordError: 'new password cannot be the same as your current password' });
            return true;
        }
        this.setState({
            passwordError: ''
        });
    }

    checkPasswordConfirm() {
        if (this.state.password && this.state.passwordconfirm !== this.state.password) {
            this.setState({
                passwordConfirmError: 'passwords do not match'
            });
            return true;
        }
        this.setState({
            passwordConfirmError: ''
        });
    }

    toggleShowTooltip() {
        this.setState(state => ({
            toolTip: !state.toolTip
        }), () => {
            if (this.state.toolTip) {
                document.addEventListener('mousedown', this.handleClickOutside);
            }
        });
    }

    handleClickOutside(event) {
        if (this.infoRef && !this.infoRef.current.contains(event.target) && this.infoButtonRef && !this.infoButtonRef.current.contains(event.target)) {
            document.removeEventListener('mousedown', this.handleClickOutside);
            this.setState({ toolTip: false })
        }
    }

    createAccount(e) {
        e.preventDefault();
        let isError = false;
        isError = this.checkName() || isError;
        isError = this.checkPassword() || isError;
        isError = this.checkPasswordConfirm() || isError;
        if (isError) {
            return
        }

        this.setState({
            isCreating: true,
            message: ''
        });
        const params = {
            FirstName: this.state.firstname,
            LastName: this.state.lastname,
            Password: this.state.password,
            HashedWord: this.props.match.params.registerhash
        };
        this.props.actions.createPublicUser(params)
            .then(() => {
                this.setState({
                    isCreating: false,
                    accountCreated: true,
                    message: ''
                });
                setTimeout(() => {
                    this.props.history.push('/login');
                }, 5000)
            }).catch(err => {
                if (err === 'Aborted') {
                    return;
                }
                this.setState({
                    message: this.props.users.userCreatePublicError ? this.props.users.userCreatePublicError : err.toString(),
                    isCreating: false
                });
            })
    }

    componentWillUnmount() {
        cancelRequest();
    }

    render() {
        const { showPassword, firstname, lastname, password, passwordconfirm, passwordError, passwordConfirmError, nameError, isCreating, accountCreated } = this.state;
        if (accountCreated) {
            return (
                <div className="center">
                    <h2>You have finished creating your account</h2>
                    <h3>You will be redirected to the login form in 5 seconds</h3>
                    <div className="small-text">Click <Link to='/login'>Here</Link> to go there now</div>
                </div>)
        }

        const passwordHelp = (indicate) => (
            <React.Fragment>
                <span>Your password must meet the following criteria:</span>
                <ul>
                    <li> Must be between 10 and 256 characters  {indicate && <span className={`icon ${password.length < 10 || password.length > 256 ? 'delete' : 'save'}`} />}</li>
                    <li> Must contain at least three of the following four types of characters: {indicate && <span className={`icon ${calculateNumberOfCriteriaMet(password) < 3 ? 'delete' : 'save'}`} />}</li>
                    <ol>
                        <li> lowercase letter {indicate && <span className={`icon ${password.search(/[a-z]/) === -1 ? 'delete' : 'save'}`} />}</li>
                        <li> uppercase letter {indicate && <span className={`icon ${password.search(/[A-Z]/) === -1 ? 'delete' : 'save'}`} />}</li>
                        <li> number {indicate && <span className={`icon ${password.search(/\d/) === -1 ? 'delete' : 'save'}`} />}</li>
                        <li> special character {indicate && <span className={`icon ${password.search(/[@^$!%*?=\-_+&\[\]{}|\\:'\,\.\?\/`~""\(\);<>]/) === -1 ? 'delete' : 'save'}`} />}</li>
                    </ol>
                </ul>
            </React.Fragment>
        )

        return (
            <div className="dlas-forms create-account">
                <h1>Finish Account Creation</h1>
                <hr className="faded-line" />
                <div className="inner-grid two multi-row" style={{ gridGap: '50px' }}>
                    <form>
                        <br />
                        <div className="inner-grid two">
                            <div>
                                <label htmlFor="sr-first-name">First name</label>
                                <input id="sr-first-name" value={firstname} onChange={e => this.handleTextChange(e, "firstname")} type="text" />
                            </div>
                            <div>
                                <label htmlFor="sr-last-name">Last name</label>
                                <input id="sr-last-name" value={lastname} onChange={e => this.handleTextChange(e, "lastname")} type="text" />
                            </div>
                        </div>
                        <span className="small-text fail">{nameError}</span>
                        <div>
                            <label htmlFor="sr-password">Password</label>
                            <button onClick={this.toggleShowTooltip} type="button" className="button info" ref={this.infoButtonRef} > Info</button>
                            <div className="info-box" ref={this.infoRef} style={{ display: this.state.toolTip ? 'block' : 'none' }}>
                                {passwordHelp(false)}
                            </div>
                            <input id="sr-password" onBlur={this.checkPassword} value={password} onChange={e => this.handleTextChange(e, "password")} autoComplete="new-password" type={showPassword ? 'text' : 'password'} />
                            <span className="small-text fail">{passwordError}</span>
                            {this.state.showHelp && <React.Fragment><br />{passwordHelp(true)}</React.Fragment>}
                        </div>
                        <div>
                            <label htmlFor="sr-password-confirm">Password Confirmation</label>
                            <input id="sr-password-confirm" onBlur={this.checkPasswordConfirm} onChange={e => this.handleTextChange(e, "passwordconfirm")} value={passwordconfirm} type={showPassword ? 'text' : 'password'} />
                            <span className="small-text fail">{passwordConfirmError}</span>
                        </div>
                        <div className="checkbox flex-row flex-vertical-center flex-start">
                            <div className="toggle-switch" onClick={this.toggleShowPassword}>
                                <input id="sr-show-password" checked={showPassword} type="checkbox" style={{ width: "inherit" }} />
                                <span className="slider"></span>
                            </div>
                            <label htmlFor="sr-show-password" className="checkbox-label no-background">Show password</label>
                        </div>
                        <div>
                            <br />
                            {isCreating ?
                                <div><span className="small-spinner"></span></div>
                                :
                                <button onClick={this.createAccount} className="button" type="submit">Create Account</button>
                            }
                        </div>
                        <div className="small-text fail">{this.state.message}</div>
                    </form>
                </div>
            </div>
        );
    }
}

export default connect(
    (state) => {
        const { users } = state;
        return {
            users
        }
    },
    (dispatch) => {
        return {
            actions: bindActionCreators(Object.assign({}, userActionCreators), dispatch)
        }
    }
)(CreateAccount)