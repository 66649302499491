import React from 'react';
import { Route } from 'react-router';
import MinutesManagementGrid from './lis-minutes-grid';
import MinutesManagementForm from './lis-minutes-form';

class MinutesManagement extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            selectedSession: '',
        };
        this.handleSessionChange = this.handleSessionChange.bind(this);
    }

    handleSessionChange(value, callback) {
        this.setState({
            selectedSession: value
        }, () => callback());
    }

    render() {
        return (
            <div>
                <Route exact path={this.props.match.path} render={props => <MinutesManagementGrid
                    {...props}
                    selectedSession={this.state.selectedSession}
                    handleSessionChange={this.handleSessionChange}
                />} />
                <Route path={`${this.props.match.path}/edit-minutes/:minutesbookid`} component={MinutesManagementForm} />
            </div>
        )
    }
}

export default MinutesManagement;