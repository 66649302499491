import React from 'react';
import DatePicker from 'react-datepicker';
import moment from 'moment';

class MeetingsCalendarPreview extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            date: moment()
        }

        this.setDate = this.setDate.bind(this);
        this.submit = this.submit.bind(this);
    }

    setDate(value) {
        this.setState({
            date: value || ''
        })
    }

    submit() {
        this.props.getPreview(this.state.date);
    }

    render() {

        return (
            <div className="dlas-forms">
                <h3>Meetings Calendar Preview</h3>
                <p>You may use this form to generate a preview PDF of all meetings scheduled to appear on the calendar.</p>
                <div className="grid-wrapper meetings-preview">
                    <div>
                        <label>Start Date</label>
                        <DatePicker
                            selected={this.state.date ? moment(this.state.date) : null}
                            onChange={(value) => this.setDate(value)}
                            dateFormat="MM/DD/YYYY"
                        />
                    </div>
                    <button
                        type="button"
                        className="button"
                        style={{ height: "32px", marginTop: "22px" }}
                        disabled={this.state.date === "" || this.props.gettingPreview}
                        onClick={() => this.submit()}
                    >
                        Submit
                    </button>
                    <div />
                    <button
                        type="button"
                        className="button float-right"
                        style={{ marginRight: "10px", height: "32px", marginTop: "22px" }}
                        onClick={() => this.props.toggleMeetingsCalendarPreview()}
                    >Return to Schedule Management</button>
                </div>
                {this.props.gettingPreview ?
                    <div aria-label="Loading" className="center-spinner spinner"></div>
                    :
                    this.props.pdfSrc != "" &&
                    <div>
                        <div className="center full-height">
                            <iframe className="center" style={{ width: '100%', height: '1080px', marginTop: "20px" }} ref={el => this.pdfRef = el} src={this.props.pdfSrc}></iframe>
                        </div>
                    </div>
                }
            </div>
        )
    }
}

export default MeetingsCalendarPreview;