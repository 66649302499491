export const Button = ({
    icons,
    input,
    children,
    disabled,
    className,
    style,
    onClick,
    ...props
}) => {
    return (
        <button {...props} onClick={onClick} disabled={disabled} style={icons?.length ? { gridTemplateColumns: 'min-content' + icons.map(_ => ' min-content').join(''), ...style } : style} className={`${icons?.length ? 'button-icon-text-container ' : ''}${className}`}>
            {input}
            {icons?.map((icon, idx) => <div {...icon.props} key={idx} className={`icon ${icon.name}`}>{icon.children}</div>)}
            <div className="button-text">{children}</div>
        </button>
    );
};
