import React from 'react';
import { Route } from 'react-router';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import CalendarConfigurations from './lis-calendar-configurations';
import CommunicationsConfigurations from './lis-communications-configurations';
import ConfigurationsGrid from './lis-configurations-grid';
import { billActionCreators } from '../../stores/lis-legislation-store';
import { Link } from 'react-router-dom';

const customStyles = (categoryColumn) => ({
    option: (base, state) => ({
        ...base,
        fontSize: '0.8em',
    }),
    control: (base) => ({
        ...base,
        padding: '1px',
        margin: 0,
        minHeight: 0,
        fontSize: '0.8em',
    }),
    singleValue: (base, state) => {
        return { ...base, };
    },
    valueContainer: base => ({
        ...base,
        padding: '2px 4px'
    }),
    dropdownIndicator: base => ({
        ...base,
        padding: '2px'
    }),
    clearIndicator: base => ({
        ...base,
        padding: '0px',
    }),
    menu: base => ({
        ...base,
        width: categoryColumn ? 'auto' : '100%',
        maxWidth: '600px',
        textAlign: categoryColumn ? 'left' : 'center'
    })
})

const isAscending = (arr, header) => {
    return arr.every(function (x, i) {
        return i === 0 || [null, undefined].includes(x[header]) || [null, undefined].includes(arr[i - 1][header]) || x[header].toString().toLowerCase() >= arr[i - 1][header].toString().toLowerCase();
    });
}

class ConfigurationsManagement extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            billEventOptions: [],
            loadedBillEventOptions: false,
            billEventOptionsError: false
        };

        this.getEventReferences = this.getEventReferences.bind(this);
        this.sort = this.sort.bind(this);
    }

    componentDidMount() {
        this.getEventReferences();
    }

    getEventReferences() {
        this.setState({ loadedBillEventOptions: false }, () => {
            this.props.actions.getBillEventReferences("?isActive=true")
                .then(() => {
                    let billEventReferences = [...this.props.bills.billEventRef];

                    billEventReferences.forEach(ref => {
                        ref.label = ref.EventCode
                        ref.value = ref.EventCode
                    });

                    this.setState({
                        loadedBillEventOptions: true,
                        billEventOptions: billEventReferences
                    });
                }).catch(err => {
                    if (err === 'Aborted') {
                        return;
                    }
                    console.log(err);
                    this.setState({ loadedBillEventOptions: true, billEventOptions: [], billEventOptionsError: true })
                });
        })
    }

    sort(assignments, column) {
        const currentlyAsc = isAscending(assignments, column.id);
        assignments.sort((first, second) => {
            let a = first[column.id]
            let b = second[column.id]
            // force null and undefined to the bottom
            a = a === null || a === undefined ? Infinity : a
            if (a === Infinity) { return 1 }
            b = b === null || b === undefined ? Infinity : b
            if (b === Infinity) { return -1 }
            // force any string values to lowercase
            a = typeof a === 'string' ? a.toLowerCase() : a
            b = typeof b === 'string' ? b.toLowerCase() : b
            // Return either 1 or -1 to indicate a sort priority
            if (a > b) {
                return currentlyAsc ? -1 : 1;
            }
            if (a < b) {
                return currentlyAsc ? 1 : -1;
            }
            //secondarily sort by category code ascending
            if (first['CategoryCode'] && second['CategoryCode']) {
                if (first['CategoryCode'].toLowerCase() > second['CategoryCode'].toLowerCase()) {
                    return 1;
                }
                if (second['CategoryCode'].toLowerCase() > first['CategoryCode'].toLowerCase()) {
                    return -1;
                }
            }
            //tertiarily sort by event code ascending
            if (first['EventCode'] && second['EventCode']) {
                if (first['EventCode'].toLowerCase() > second['EventCode'].toLowerCase()) {
                    return 1;
                }
                if (second['EventCode'].toLowerCase() > first['EventCode'].toLowerCase()) {
                    return -1;
                }
            }
            // returning 0 or undefined will use any subsequent column sorting methods or the row index as a tiebreaker
            return 0
        })
        return assignments;
    }

    findDifferences(assignments, uniqueIdentifier, categoryChamberIdentifier, getAssociatedAdminAssignmentFn) {
        let assignmentMismatches = [];
        assignments.forEach(assignment => {
            const associatedAdminAssignment = getAssociatedAdminAssignmentFn(assignment);
            if (!associatedAdminAssignment) {
                assignmentMismatches.push(assignment[uniqueIdentifier]);
                return;
            }
            const { ModificationDate, [categoryChamberIdentifier]: value, ...originalWithoutCategoryChamberAndModificationDate } = assignment;
            if (this.isDifferentOrNonExistentInAdminDB(originalWithoutCategoryChamberAndModificationDate, associatedAdminAssignment)) {
                assignmentMismatches.push(assignment[uniqueIdentifier]);
            }
        });
        return assignmentMismatches;
    }

    isDifferentOrNonExistentInAdminDB(current, admin) {
        return (!admin || JSON.stringify(admin) !== JSON.stringify(current));
    }

    render() {
        const { loadedBillEventOptions, billEventOptions, billEventOptionsError } = this.state;
        return (
            <div style={{ paddingBottom: '40px' }}>
                <div>
                    <h1 className="no-margin-bottom">{this.props.location.pathname.endsWith('/configurations-management') ? "Configurations Management" : <Link className="no-margin-bottom" style={{ textDecoration: 'none' }} to="/configurations-management">Configurations Management</Link>}</h1>
                </div>
                <Route exact path={this.props.match.path} render={props => <ConfigurationsGrid
                    {...props}
                />} />
                <Route path={"/configurations-management/calendar"} render={props => <CalendarConfigurations
                    {...props}
                    loadedBillEventOptions={loadedBillEventOptions}
                    billEventOptions={billEventOptions}
                    billEventOptionsError={billEventOptionsError}
                    customStyles={customStyles}
                    isAscending={isAscending}
                    sort={this.sort}
                    findDifferences={this.findDifferences}
                    isDifferentOrNonExistentInAdminDB={this.isDifferentOrNonExistentInAdminDB}
                />} />
                <Route path={"/configurations-management/communications"} render={props => <CommunicationsConfigurations
                    {...props}
                    loadedBillEventOptions={loadedBillEventOptions}
                    billEventOptions={billEventOptions}
                    billEventOptionsError={billEventOptionsError}
                    customStyles={customStyles}
                    isAscending={isAscending}
                    sort={this.sort}
                    findDifferences={this.findDifferences}
                    isDifferentOrNonExistentInAdminDB={this.isDifferentOrNonExistentInAdminDB}
                />} />
            </div>
        )
    }
}

export default connect(
    (state) => {
        const { bills } = state;
        return {
            bills
        }
    },
    (dispatch) => {
        return {
            actions: bindActionCreators(Object.assign({}, billActionCreators), dispatch)
        }
    }
)(ConfigurationsManagement)

