import React from 'react';
import { Route } from 'react-router';
import CalendarGrid from './lis-calendar-grid';
import CalendarFormContainer from './lis-calendar-form-container';

class CalendarManagement extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            selectedChamber: '',
            selectedSession: ''
        }
        this.handleDropdownChange = this.handleDropdownChange.bind(this);
    }

    handleDropdownChange(key, val, callback) {
        this.setState({
            [key]: val
        }, () => callback());
    }

    render() {
        const { selectedChamber, selectedSession } = this.state;
        return (
            <div>
                <Route exact path={this.props.match.path} render={props => <CalendarGrid
                    {...props}
                    selectedChamber={selectedChamber}
                    selectedSession={selectedSession}
                    handleDropdownChange={this.handleDropdownChange}
                />} />
                <Route path={"/calendar-management/edit-calendar/:calendarid"} component={CalendarFormContainer} />
            </div>
        )
    }
}

export default CalendarManagement;