import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { committeeActionCreators } from '../../../stores/lis-committee-store';
import Popout from '../../../lis-shared/lis-layout/components/lis-popout-component';

class AdminDashboard extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            showPortal: false
        }
        this.togglePortal = this.togglePortal.bind(this);
    }


    togglePortal(state) {
        this.setState({
            showPortal: !state
        });
    }

    render() {
        return(
            <div>
                <h1>Dashboard</h1>
                <p>This is the user dashboard view.</p>
                <button onClick={() => this.togglePortal(this.state.showPortal)}>Test </button>
                {this.state.showPortal &&
                    <Popout togglePortal={this.togglePortal} windowTitle="test">
                        <p>Even though I render in a different window, I share state!</p>
                    </Popout>
                }
            </div>
        );
    }
}


export default connect(
    (state) => {
        const { committee } = state;
        return {
            committee
        }
    },
    (dispatch) => {
        return {
            actions: bindActionCreators(Object.assign({}, committeeActionCreators), dispatch)
        }
    }
)(AdminDashboard)