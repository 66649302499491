import React from 'react';
import moment from 'moment'
import { Link } from 'react-router-dom';

import renderHTML from 'react-render-html';

const Communication = props => {
    //Only display the legislation that is marked as active
    const onlyActive = props.comm.CommunicationLegislation.filter(leg => leg.IsActive);
    const legList = onlyActive.map(leg => {
        leg.ChamberCode = leg.ChamberCode ?? leg.LegislationNumber[0];
        leg.Patrons.sort((a, b) => a.ChamberCode !== b.ChamberCode ? a.ChamberCode !== leg.ChamberCode ? 1 : -1 : a.Sequence - b.Sequence || a.PatronTypeID - b.PatronTypeID || a.PatronDisplayName.localeCompare(b.PatronDisplayName));
        const patronList = leg.Patrons.map((patron, patronIndex) => {
            let sameAsPrevious = patronIndex === 0 ? true : (!leg.Patrons[patronIndex - 1] && patron.ChamberCode === leg.ChamberCode && !leg.Patrons.find(p => p.ChamberCode !== leg.ChamberCode)) || (leg.Patrons[patronIndex - 1] && patron.ChamberCode === leg.Patrons[patronIndex - 1].ChamberCode);
            let sameAsNext = leg.Patrons[patronIndex + 1] && patron.ChamberCode === leg.Patrons[patronIndex + 1].ChamberCode;
            let patronDisplayName = patron.PatronDisplayName

            return (
                <React.Fragment key={patronIndex}>
                    {patron.ChamberCode !== leg.ChamberCode
                        ? patronIndex !== leg.Patrons.length - 1
                            ? leg.ChamberCode === "S"
                                ? !sameAsPrevious ? (patronIndex !== 0 ? "; " : "") + "Delegates: " + patronDisplayName + ", " : sameAsNext ? patronDisplayName + ", " : patronDisplayName
                                : !sameAsPrevious ? (patronIndex !== 0 ? "; " : "") + "Senators: " + patronDisplayName + ", " : sameAsNext ? patronDisplayName + ", " : patronDisplayName
                            : leg.ChamberCode === "S"
                                ? !sameAsPrevious ? (patronIndex !== 0 ? "; " : "") + "Delegate: " + patronDisplayName : patronDisplayName
                                : !sameAsPrevious ? (patronIndex !== 0 ? "; " : "") + "Senator: " + patronDisplayName : patronDisplayName
                        : sameAsNext
                            ? patronDisplayName + ", " : patronDisplayName
                    }
                </React.Fragment>
            )
        })


        let legNumber = leg.LegislationNumber;
        //Add dots in between the characters because that is how they want it displayed
        const billType = legNumber.slice(0, 2);
        const numbers = legNumber.slice(2);
        legNumber = billType.split('').map(char => {
            char += '.'
            // Senate Joint and House Joint Resolutions are displayed as 'H.J.R.' and 'S.J.R.' on this page rather than 'HJ' and 'SJ'
            // If the bill has a 'J' in the bill number then it is a joint resolution
            if (char === 'J.') {
                char += 'R.'
            }
            return char;
        }).join('');
        legNumber += ' ' + numbers;

        let billLink = '<strong><a className="bill-number" target="_blank" href=/bill-details/' + props.session.SessionCode + '/' + leg.LegislationNumber + '>' + legNumber + '</a></strong>';
        let draftTitle = leg.DraftTitle;

        if (draftTitle && draftTitle.indexOf("<p class=ldtitle>") > -1) {
            draftTitle = draftTitle.slice(0, 17) + billLink + " " + draftTitle.slice(17, draftTitle.length + 1)
        }
        else if (draftTitle && draftTitle.indexOf("<p class=ldtitle>") == -1) {
            draftTitle = "<p class=ldtitle>" + billLink + " " + draftTitle + "</p>"
        }

        const removeLDNum = (draftTitle) => draftTitle.replace(/<p class=ldrt>\d+D<\/p>/, "");

        return (
            <div key={leg.LegislationNumber}>
                <div className="list-desc">
                    <div>
                        <div className="comm-leg-wrapper">
                            {!draftTitle &&
                                <strong>
                                    <Link className="bill-number" target="_blank" to={`/bill-details/${props.session.SessionCode}/${leg.LegislationNumber}`}>{legNumber}</Link>
                                </strong>
                            }
                            {draftTitle && draftTitle.indexOf(billLink) < 0 &&
                                <strong>
                                    <Link className="bill-number" target="_blank" to={`/bill-details/${props.session.SessionCode}/${leg.LegislationNumber}`}>{legNumber}</Link>
                                    {" "}
                                </strong>
                            }
                            {draftTitle &&
                                renderHTML(removeLDNum(draftTitle))
                            }
                        </div>
                    </div>
                    <div>
                        <p>{leg.EffectiveType === "Emergency" && "EMERGENCY"}</p>
                    </div>
                    <div>
                        <p>{leg.Patrons.length > 1 ? 'Patrons' : 'Patron'} - {patronList}</p>
                    </div>
                </div>
            </div>
        );
    });
    if (legList.length > 0) {
        const categoryStyle = {
            fontSize: '18px',
            color: '#315981'
        }
        return (
            <div>
                <br />
                {props.comm.Description ? <p style={categoryStyle}>{props.comm.Description}</p> :
                    <React.Fragment>
                        {legList.length === 1 ?
                            <p style={categoryStyle}>{props.comm.CategoryDescription}</p>
                            :
                            <p style={categoryStyle}>{props.comm.PluralDescription}</p>
                        }
                    </React.Fragment>
                }
                {legList}
            </div>
        );
    } else {
        return false;
    }
}

class CommunicationsDetailsComponent extends React.Component {

    render() {
        const { session, communication, refNum, authoring } = this.props;
        const chamberCode = communication.ChamberCode;
        const referenceNumber = refNum || communication.ReferenceNumber;
        const date = communication.CommunicationDate;
        const categories = communication.CommunicationCategories || [];

        const committeeHeader = "In the " + (chamberCode === "S" ? "Senate" : "House of Delegates");
        const sessionHeader = session.SessionYear + " " + session.DisplayName;
        const floatRightStyle = {
            float: 'right'
        };
        return (
            <div className="bill-theme public-details inner-grid">
                <div>
                    <h2>{sessionHeader}</h2>
                </div>
                <hr />
                <div className='details-page-data-container'>
                    <div className="communication-header-container">
                        <p>{referenceNumber}</p>
                        <div style={floatRightStyle}>
                            <p className="float-right">{committeeHeader}</p>
                            <p>{moment(date).format("MMMM DD, YYYY")}</p>
                        </div>
                    </div>
                    <div>
                        {categories.length > 0 && categories.map(comm => <Communication key={comm.CommunicationCategoryID} comm={comm} session={session} />)}
                    </div>
                    <br />
                    <div className="list-desc">
                        <p>{communication.ShowSignatureNote ? communication.FooterSentence : <pre>&emsp;</pre>}</p>
                    </div>
                    <div className="list-desc align-right">
                        {authoring ?
                            chamberCode === "H"
                                ? <div className="house-sig-plate" />
                                : <div className="senate-sig-plate" />
                            : null
                        }
                        <p>{communication.ClerkName}</p>
                        <p>{communication.ClerkTitle}</p>
                    </div>
                </div>
            </div>
        )
    }
}

export default CommunicationsDetailsComponent;
