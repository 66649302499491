import React from 'react';
import { Prompt } from 'react-router';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { billActionCreators } from '../../../stores/lis-legislation-store';
import { sessionActionCreators } from '../../../stores/lis-session-store';
import { navActionCreators } from '../../../stores/lis-nav-store';
import { cancelRequest } from '../../../services/request.service';
import moment from 'moment';
import ReactToPrint from 'react-to-print';
import Popout from '../../../lis-shared/lis-layout/components/lis-popout-component';
import DatePicker from 'react-datepicker';
import getHtml from '../../../services/json-to-html';

import BillGridSection from './lis-bill-grid';
import SelectorSection from './lis-selectors';
import AmendmentSection from './lis-amendments';
import ReviewSection from './lis-review';
import PreviewSection from './lis-bill-preview';
import TextList from './lis-text-list';

class VersionManagement extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            billFieldsetIsActive: true,
            amendmentFieldsetIsActive: false,
            reviewFieldsetIsActive: false,
            sessionOptions: [],
            legOptions: [],
            billVersionRef: [],
            selectedSession: '',
            selectedLegType: '',
            originalBillText: [],
            selectedBillText: '',
            selectedBillTextIndex: -1,
            isLoaded: true,
            billList: [],
            selectedBill: '',
            billText: '',
            amendments: [],
            isPreviewVisible: false,
            processingFile: false,
            showPortal: false,
            billDataIsLoading: null,
            showCompletedItems: false,
            versionDate: moment(),
            legDateOptions: [],
            selectedLegDate: '',
            selectedLegNum: '',
            showHeaders: true,
            headers: null,
            reviewKey: 1,
            previewContent: '',
            previewContentError: '',
            editorChanged: false,
            savedReviewContent: '',
            gettingIncompletePdf: false,
            showIncompletePdf: false,
            checkedBills: [],
            enrolledPrintQueue: [],
            useCurrentPublishDates: true,
            reviewContent: '',
            onReconvene: false,
            isDirty: false,
            showTextConfirmation: false,
            showBillConfirmation: false,
            changeCount: 0,
            storedTextIndex: 0,
            storedBill: "",
            storedBillIndex: 0,
            sessionBillsList: [],
            wasEmpty: false,
            replaced: false
        };

        //Ref used to scroll down to amendment section
        this.amendmentRef = React.createRef();
        this.reviewRef = React.createRef();

        this.togglePreview = this.togglePreview.bind(this);
        this.togglePortal = this.togglePortal.bind(this);
        this.toggleFieldsetCollapse = this.toggleFieldsetCollapse.bind(this);
        this.handleSelectorChange = this.handleSelectorChange.bind(this);
        this.checkHandleSelectorChange = this.checkHandleSelectorChange.bind(this);
        this.selectBill = this.selectBill.bind(this);
        this.selectBillIndex = this.selectBillIndex.bind(this);
        this.checkBill = this.checkBill.bind(this);
        this.batchPublish = this.batchPublish.bind(this);
        this.batchComplete = this.batchComplete.bind(this);
        this.togglePublishDatePreference = this.togglePublishDatePreference.bind(this);
        this.handlePublishDateChange = this.handlePublishDateChange.bind(this);
        this.toggleCompleteItems = this.toggleCompleteItems.bind(this);
        this.handleEditorChange = this.handleEditorChange.bind(this);
        this.handleSave = this.handleSave.bind(this);
        this.handleSaveCompletedItem = this.handleSaveCompletedItem.bind(this);
        this.handleVersionDateChange = this.handleVersionDateChange.bind(this);
        this.toggleHeaders = this.toggleHeaders.bind(this);
        this.resetWarning = this.resetWarning.bind(this);
        this.getJsonHeadersRequestString = this.getJsonHeadersRequestString.bind(this);
        this.getPreviewRequest = this.getPreviewRequest.bind(this);
        this.previewPdf = this.previewPdf.bind(this);
        this.toggleReprint = this.toggleReprint.bind(this);
        this.filterReprints = this.filterReprints.bind(this);
        this.setLegDateOptions = this.setLegDateOptions.bind(this);
        this.closeConfirmation = this.closeConfirmation.bind(this);
        this.updateBillListByLegislationID = this.updateBillListByLegislationID.bind(this);
        this.setWasEmpty = this.setWasEmpty.bind(this);
        this.setReplaced = this.setReplaced.bind(this);

        this.pdfRef = React.createRef();
    }

    toggleFieldsetCollapse(isActive) {
        this.setState(state => ({
            [isActive]: !state[isActive]
        }));
    }

    filterReprints() {
        this.setState({ reprintsFiltered: !this.state.reprintsFiltered }, () => {
            this.setLegDateOptions(this.state.billList);
        })
    }

    toggleReprint() {
        let billTexts = [...this.state.originalBillText];
        billTexts[this.state.selectedBillTextIndex].IsReprint = !billTexts[this.state.selectedBillTextIndex].IsReprint;
        this.setState({ billTexts });
    }

    setWasEmpty(val, callback) {
        this.setState({ wasEmpty: val }, () => {
            if (callback) {
                callback();
            }
        })
    }

    setReplaced(val, callback) {
        this.setState({ replaced: val }, () => {
            if (callback) {
                callback();
            }
        })
    }

    getPreviewRequest() {
        let previewRequest = { ...this.state.originalBillText[this.state.selectedBillTextIndex] };

        let newDraftText;
        let doc = document.createElement('div');
        //Use what's in the editor for completed items, otherwise use what's been saved
        doc.insertAdjacentHTML("afterbegin", this.state.showCompletedItems ? this.state.reviewContent : previewRequest.DraftText);
        if (!this.state.showCompletedItems) {
            //Remove ldtitle from document
            let docParagraphs = doc.getElementsByTagName('p');

            for (let j = 0; j < docParagraphs.length; j++) {
                //Do not remove the patronLine from the original text before requesting preview for incomplete items
                if (['section', 'enactstm'].includes(docParagraphs[j].classList[0])) {
                    break; //reached the actal body of the text, don't remove any more
                } else if (['ldtitle', 'ld'].includes(docParagraphs[j].classList[0])) {
                    doc.removeChild(docParagraphs[j])
                    j--
                }
            }
        } else {
            //remove header-content from document
            let docDivs = doc.getElementsByTagName('div');
            for (let j = 0; j < docDivs.length; j++) {
                //Do not remove the patronLine from the original text before requesting preview for incomplete items
                if (this.state.showCompletedItems) {
                    if (docDivs[j].id === 'header-content') {
                        doc.removeChild(docDivs[j]);
                    }
                }
            }
        }

        newDraftText = doc.innerHTML;

        previewRequest.DraftText = newDraftText;
        if (this.state.showCompletedItems) {
            //Try to find the draft title in the text and set it
            //For incomplete items, the text has to have already been saved before previewing, which means the DraftTitle attribute will already be set appropriately
            //but for completed items, they preview without saving, so the DraftTitle needs to be updated in the request we send to the API in order for the title in the headers (programmatically added) to be correct
            let searchForLDTitleContainer = document.createElement('div');
            searchForLDTitleContainer.innerHTML = this.state.reviewContent;
            //The first element in the content with this class is the draft title
            let draftLDTitle = searchForLDTitleContainer.querySelector('.ldtitle');
            if (draftLDTitle && draftLDTitle.innerHTML !== '') {
                previewRequest.DraftTitle = draftLDTitle.innerHTML;
                previewRequest.EditTitle = draftLDTitle.innerHTML;
            }
        }
        previewRequest.SessionCode = this.state.selectedSession.SessionCode;
        previewRequest.Chamber = previewRequest.LegislationChamberCode === "H" ? "House" : "Senate";
        previewRequest.VersionChamber = previewRequest.ChamberCode === "H" ? "House" : "Senate";
        previewRequest.LegislationKey = previewRequest.LegislationNumber.substr(2, previewRequest.LegislationNumber.length);
        previewRequest.OnReconvene = this.state.onReconvene;

        //Pull the ReferredCommittee out of the doc in case the text version hasn't been saved before and doesn't already have a ReferredCommittee
        //Only when authoring engrossed or renengrossed
        if (this.state.selectedLegType.LegislationVersionID === 6 || this.state.selectedLegType.LegislationVersionID === 2) {
            let headers = document.createElement('div');
            headers.insertAdjacentHTML("afterbegin", this.state.headers);

            if (headers.getElementsByClassName("CommitteeReferral")[0]) {
                previewRequest.ReferredCommittee = headers.getElementsByClassName("CommitteeReferral")[0].innerText.split("Committee on the ")[1]
                    ? headers.getElementsByClassName("CommitteeReferral")[0].innerText.split("Committee on the ")[1].trim()
                    : headers.getElementsByClassName("CommitteeReferral")[0].innerText.split("Committee for ")[1]
                        ? headers.getElementsByClassName("CommitteeReferral")[0].innerText.split("Committee for ")[1].trim()
                        : headers.getElementsByClassName("CommitteeReferral")[0].innerText.split("Committee on ")[1].trim();
            }
        }

        return previewRequest;
    }

    previewPdf() {
        if (!this.state.showCompletedItems) {
            this.setState({ showIncompletePdf: true, gettingIncompletePdf: true })
        } else {
            this.setState({ showIncompletePdf: false, gettingCompletedItemPreview: true })
        }
        const previewRequest = this.getPreviewRequest();

        this.props.actions.previewPdfFile(previewRequest)
            .then(() => {
                if (this.state.showCompletedItems) {
                    this.setState({
                        previewContent: 'true', //just makes it so the preview page will indeed show
                        isPreviewVisible: !this.state.isPreviewVisible,
                        gettingCompletedItemPreview: false
                    }, () => {
                        document.querySelector("iframe").src = this.props.bills.sasUrl;
                    })
                } else {
                    if (this.pdfRef && this.pdfRef.current) {
                        let pdfRef = this.pdfRef.current;
                        pdfRef.setAttribute('src', this.props.bills.sasUrl);
                        pdfRef.onload = () => {
                            this.setState({
                                gettingIncompletePdf: false
                            });
                        };
                    }
                }
            }).catch((e) => {
                console.log(e);
                this.setState({ gettingCompletedItemPreview: false, gettingIncompletePdf: false })
            })
    }

    togglePreview() {
        if (!this.state.isPreviewVisible) {
            if (this.state.showCompletedItems) {
                this.previewPdf();
            } else {
                const previewRequest = this.getPreviewRequest();
                this.props.actions.getHtmlPreview(previewRequest)
                    .then(() => {
                        if (this.props.bills.htmlPreview && this.props.bills.htmlPreview.body && !this.props.bills.htmlPreview.body.locked) {
                            //checking 'locked' prevents this  if-block from returning true because of an old htmlPreview - 
                            //for example, hitting preview on a bill that results in a valid htmlPreview obj, then switching bills to one that does not result in a valid htmlPreview would break here 
                            //because 'this.props.bills.htmlPreview.body' is still true, but getReader is invalid on it since it is locked
                            this.props.bills.htmlPreview.body.getReader().read().then(({ value }) => {
                                this.setState({
                                    isPreviewVisible: !this.state.isPreviewVisible,
                                    showIncompletePdf: false,
                                    previewContent: Buffer.from(value.buffer).toString(),
                                    previewContentError: ''
                                }, () => {
                                    if (document.getElementsByClassName("billpatron")[0] && this.state.selectedLegType.Name === "Engrossed" && this.state.patronageInfo) {
                                        document.getElementsByClassName("billpatron")[0].textContent = this.state.patronageInfo
                                    }
                                })
                            })
                        } else if (this.props.bills.htmlPreviewError) {
                            this.setState({
                                isPreviewVisible: !this.state.isPreviewVisible,
                                showIncompletePdf: false,
                                previewContent: '',
                                previewContentError: this.props.bills.htmlPreviewError
                            })
                        }
                    })

                if (this.state.selectedLegType.Name === "Enrolled") {
                    this.props.actions.previewPdfFile(previewRequest)
                        .then(() => {
                            //don't do anything; this just puts a non-public/SasURL-driven blob in blob storage that can later be used in print management
                        });
                }
            }
        } else {
            this.setState({
                isPreviewVisible: !this.state.isPreviewVisible,
                showIncompletePdf: false,
                previewContent: ""
            })
        }
    }

    togglePortal(state, e) {
        if (e) {
            e.stopPropagation();
        }
        this.setState({
            showPortal: !state
        });
    }

    handleSave(publish, isComplete) {
        this.setState({ isSaving: true, enrolledPrintQueue: [], checkedBills: [], useCurrentPublishDates: true, customPublishDate: null });

        //if publishing any of the below versions, skip all the processing and just use the batch publish endpoint (US10097)
        if (publish && ["Engrossed", "Reengrossed", "Enrolled", "Reenrolled", "Chaptered"].includes(this.state.selectedLegType.Name)) {
            this.props.actions.batchPublish({ "PublishDate": null, "legislationTextIDs": [this.state.originalBillText[this.state.selectedBillTextIndex].LegislationTextID], "legislationIDs": [this.state.selectedBill.LegislationID] })
                .then(() => {
                    this.props.actions.batchGenBillFile("?sessionID=" + this.state.selectedSession.SessionID, [this.state.originalBillText[this.state.selectedBillTextIndex].LegislationTextID])
                        .then(() => {
                            this.props.actions.makeToast([{ message: "Save Successful", type: "success" }])

                            this.setState({
                                isPreviewVisible: false,
                                selectedBill: '',
                                reviewContent: '',
                                savedReviewContent: '',
                                editorChanged: false,
                                selectedBillTextIndex: -1,
                                reviewFieldsetIsActive: false,
                                amendmentFieldsetIsActive: false,
                                billFieldsetIsActive: true,
                                reviewKey: 1,
                                isSaving: false,
                                isDirty: false
                            });
                            this.handleSelectorChange('selectedSession', this.state.selectedSession);
                        }).catch((err) => {
                            if (err === 'Aborted') {
                                return;
                            }
                            this.props.actions.makeToast([{ message: "File Generation Failed", type: "failure" }]);
                            this.setState({ isSaving: false });
                        })
                }).catch((err) => {
                    if (err === 'Aborted') {
                        return;
                    }
                    this.props.actions.makeToast([{ message: "Publish Failed", type: "failure" }]);
                    this.setState({ isSaving: false });
                })
        } else {
            let originalBillText = [...this.state.originalBillText];
            let selectedBillTextIndex = this.state.selectedBillTextIndex;
            if (!publish && ["Gov Recommendation", "Veto Explanation"].includes(this.state.selectedLegType.Name) && selectedBillTextIndex === -1) {
                //they manually added this bill, so we need to set a new object in the event that there wasn't a bill text object already
                originalBillText.push({
                    LegislationVersionID: this.state.selectedLegType.LegislationVersionID,
                    LegislationID: this.state.selectedBill.LegislationID,
                    DraftTitle: "",
                    EditTitle: "",
                    IsPublic: false,
                    isDirty: false
                });
                selectedBillTextIndex = originalBillText.length - 1;
            }
            // if the selected bill text is published or is not the correct version then a new version needs to be created.
            if (originalBillText[selectedBillTextIndex].IsPublic || this.state.selectedLegType.LegislationVersionID !== originalBillText[selectedBillTextIndex].LegislationVersionID) {
                let newBillText = { ...originalBillText[selectedBillTextIndex] };
                //Delete ID and mod date since this is a new text entry onto the bill
                delete newBillText.LegislationTextID;
                delete newBillText.LegislationTextActionID;
                delete newBillText.ModificationDate;
                delete newBillText.Description;
                delete newBillText.TextDisposition;
                delete newBillText.TextDispositionID;
                delete newBillText.IsComplete;
                delete newBillText.HTMLFile;
                delete newBillText.JSONFile;
                delete newBillText.PDFFile;
                delete newBillText.IsReprint;
                //Delete the document code so that the DB can construct it if it is not preengrossing; if it is preengrossing, we need to set it ourselves since we are the ones with knowledge of the base document
                if (this.state.selectedLegType.Name !== "Engrossed" || this.state.selectedBill.CandidateDate) {
                    delete newBillText.DocumentCode;
                } else {
                    if (newBillText.LegislationVersion === "Introduced") {
                        newBillText.DocumentCode = this.state.selectedBill.LegislationNumber + "E";
                    } else if (newBillText.LegislationVersion === "Substitute") {
                        const subNumber = newBillText.DocumentCode.replaceAll(/\D{2,3}\d+/g, ""); //e.g. HB8660H1 -> H1
                        newBillText.DocumentCode = this.state.selectedBill.LegislationNumber + "E" + subNumber;
                    } else {
                        //we were promised multiple times it would only ever be an introduced or substitute, but I'm going to delete the doc code if it isn't for whatever reason, and hope the DB sets it correctly
                        delete newBillText.DocumentCode;
                    }
                }
                newBillText.LegislationVersionID = this.state.selectedLegType.LegislationVersionID;
                delete newBillText.LegislationVersion;
                //delete the chambercode and set sponsor to Governor if Governor's Recommendation or Governor's Veto
                if (["Gov Recommendation", "Veto Explanation"].includes(this.state.selectedLegType.Name)) {
                    delete newBillText.ChamberCode;
                    newBillText.Sponsor = "Governor";
                    newBillText.SponsorTypeID = 1;
                } else if (this.state.selectedLegType.Name === "Engrossed") {
                    delete newBillText.Sponsor;
                    delete newBillText.SponsorTypeID;
                    delete newBillText.CommitteeID;
                    delete newBillText.Patrons;
                }

                originalBillText.push(newBillText);
                selectedBillTextIndex = originalBillText.length - 1;
            }
            // Set the session code. This must be done for file generation to properly work
            originalBillText[selectedBillTextIndex].SessionCode = this.state.selectedBill.SessionCode;
            // Set the chamber code to the legislation's chamber code if it is an enrolled/reenrolled version, and remove committee info
            if (["Enrolled", "Reenrolled"].includes(this.state.selectedLegType.Name)) {
                originalBillText[selectedBillTextIndex].ChamberCode = this.state.selectedBill.ChamberCode;
                delete originalBillText[selectedBillTextIndex].CommitteeID;
                delete originalBillText[selectedBillTextIndex].CommitteeName;
                delete originalBillText[selectedBillTextIndex].CommitteeNumber;
            }
            //Try to find the draft title in the text and set it
            let searchForLDTitleContainer = document.createElement('div');
            searchForLDTitleContainer.innerHTML = this.state.reviewContent;
            //The first element in the content with this class is the draft title
            let draftLDTitle = searchForLDTitleContainer.querySelector('.ldtitle');
            if (draftLDTitle && draftLDTitle.innerHTML !== '') {
                originalBillText[selectedBillTextIndex].DraftTitle = draftLDTitle.innerHTML;
                originalBillText[selectedBillTextIndex].EditTitle = draftLDTitle.innerHTML;
            }

            //add LegislationClassID/LegislationClass to the LegislationText obj
            originalBillText[selectedBillTextIndex].LegislationClass = this.state.selectedBill.LegislationClass
            originalBillText[selectedBillTextIndex].LegislationClassID = this.state.selectedBill.LegislationClassID

            if (isComplete) { originalBillText[selectedBillTextIndex].IsComplete = true }

            originalBillText[selectedBillTextIndex].VersionDate = this.state.versionDate;
            originalBillText[selectedBillTextIndex].DraftText = this.state.reviewContent;
            originalBillText[selectedBillTextIndex].IsActive = true;
            originalBillText[selectedBillTextIndex].IsPublic = publish;

            //Remove ldtitle from document if the header is present in the header and the header also contains the ldtitle
            let doc = document.createElement('div');
            doc.insertAdjacentHTML("afterbegin", originalBillText[selectedBillTextIndex].DraftText);

            //Remove any tags TinyMCE may have added when pasting from Word
            doc.childNodes.forEach(node => {
                if (node.nodeName !== "#text") {
                    node.removeAttribute('data-mce-fragment');
                    if (node.childNodes.length > 0) {
                        node.childNodes.forEach(childNode => {
                            if (childNode.nodeName !== "#text") {
                                childNode.removeAttribute('data-mce-fragment');
                            }
                        })
                    }
                }
            });

            let header = doc.getElementsByTagName('div');
            let newDraftText;

            if (header[0]) {
                //Store the header somewhere while we find and iterate over paragraphs in the rest of the doc
                let headerContainer = header[0].innerHTML;
                let headerParagraphs = header[0].getElementsByTagName('p');

                for (let i = 0; i < headerParagraphs.length; i++) {
                    if (['ldtitle', 'ld', 'patronLine', 'patctr'].includes(headerParagraphs[i].classList[0])) {
                        //Temporarily remove header                              
                        header[0].innerHTML = "";

                        //We found the ldtitle or patronage in the header, so iterate over the paragraphs to find any duplicate ldtitles or patronages and eliminate them
                        let docParagraphs = doc.getElementsByTagName('p');

                        for (let j = 0; j < docParagraphs.length; j++) {
                            if (['section', 'enactstm'].includes(docParagraphs[j].classList[0])) {
                                break; //reached the actal body of the text, don't remove any more
                            } else if (['ldtitle', 'ld', 'patronLine', 'patctr', 'center'].includes(docParagraphs[j].classList[0])) {
                                doc.removeChild(docParagraphs[j])
                                j--
                            }
                        }
                    }
                }
                //Reattach the header
                header[0].innerHTML = headerContainer;

                newDraftText = doc.innerHTML
            }

            if (newDraftText != undefined) {
                originalBillText[selectedBillTextIndex].DraftText = newDraftText
            }

            if (this.state.onReconvene) {
                originalBillText[selectedBillTextIndex].OnReconvene = true;
            }

            this.props.actions.saveBillText({ TextsList: [originalBillText[selectedBillTextIndex]] })
                .then(() => {
                    let billTextParams = "?legislationID=" + this.state.selectedBill.LegislationID;
                    this.props.actions.getBillText(billTextParams, true)
                        .then(() => {
                            const newbillTextList = [...this.props.bills.billText];
                            const priorBillText = originalBillText[selectedBillTextIndex];
                            // Need to figure out what the index of the bill text we saved was. It isn't always the same one that was sent.
                            if (priorBillText && priorBillText.LegislationTextID) {
                                selectedBillTextIndex = newbillTextList.findIndex(t => t.LegislationTextID === priorBillText.LegislationTextID)
                            } else {
                                // Hopefully this finds it, but since the API might change the draft text around the second findIndex function is used.
                                selectedBillTextIndex = newbillTextList.findIndex(t => t.DraftText === priorBillText.DraftText && t.LegislationVersionID === priorBillText.LegislationVersionID);
                                selectedBillTextIndex = selectedBillTextIndex !== -1 ? selectedBillTextIndex : newbillTextList.findIndex(t => t.LegislationVersionID === priorBillText.LegislationVersionID)
                            }
                            if (publish) {
                                this.props.actions.makeToast([{ message: "Save Successful", type: "success" }])
                                //Bill's status has changed. So move it out of the grid and of the review pane
                                this.setState({
                                    isSaving: false,
                                    isPreviewVisible: false,
                                    selectedBill: '',
                                    reviewContent: '',
                                    savedReviewContent: '',
                                    editorChanged: false,
                                    selectedBillTextIndex: -1,
                                    reviewFieldsetIsActive: false,
                                    amendmentFieldsetIsActive: false,
                                    billFieldsetIsActive: true,
                                    reviewKey: 1,
                                    isDirty: false
                                });
                                this.handleSelectorChange('selectedSession', this.state.selectedSession);
                                //Create the pdf version of the bill if the bill is published
                                const legFileGenParams = "?legislationID=" + newbillTextList[selectedBillTextIndex].LegislationID
                                    + "&legislationTextID=" + newbillTextList[selectedBillTextIndex].LegislationTextID
                                    + "&legislationVersionID=" + newbillTextList[selectedBillTextIndex].LegislationVersionID
                                    + "&sessionID=" + this.state.selectedSession.SessionID;
                                this.props.actions.genBillFile(legFileGenParams)
                            } else {
                                // Refresh the bill info in the billList state (billGridSection)
                                this.updateBillListByLegislationID(newbillTextList[selectedBillTextIndex].LegislationID);
                                // Get the text again to get the mod dates
                                this.setState(prevState => ({
                                    originalBillText: newbillTextList,
                                    selectedBillTextIndex: selectedBillTextIndex,
                                    savedReviewContent: prevState.reviewContent,
                                    editorChanged: true //have to do this to mimic the automatic editing the editor does when it is first initialized (see handleEditorChange method comments for more info)
                                }), () => {
                                    originalBillText = [...this.state.originalBillText];
                                    selectedBillTextIndex = this.state.selectedBillTextIndex;

                                    if (originalBillText[selectedBillTextIndex]) {
                                        let newDraftText, jsonPromise;

                                        //update headers; e.g. if chaptered, chapter number may have changed
                                        if (this.state.selectedLegType.Name === "Engrossed"
                                            || this.state.selectedLegType.Name === "Reengrossed"
                                            || this.state.selectedLegType.Name === "Chaptered"
                                            || this.state.selectedLegType.Name === "Enrolled"
                                            || this.state.selectedLegType.Name === "Reenrolled") {
                                            const billText = originalBillText[selectedBillTextIndex];
                                            const bill = this.state.selectedBill;
                                            const jsonHeadersRequestString = this.getJsonHeadersRequestString(billText.LegislationTextID, billText.DocumentCode);

                                            jsonPromise = this.props.actions.getJsonHeaders(jsonHeadersRequestString)
                                                .then(() => {
                                                    if (this.props.bills.jsonHeaders) {
                                                        let headers = getHtml(this.props.bills.jsonHeaders);

                                                        //Add the PassageDates to the draft text if this is a resolution and in the enrolled queue
                                                        if ((this.state.selectedLegType.Name === "Chaptered" || this.state.selectedLegType.Name === "Enrolled") && (bill.LegislationTypeCode === "J" || bill.LegislationTypeCode === "R")) {
                                                            headers = this.applyPassageDates(headers, billText, bill.LegislationTypeCode, bill.HousePassageDate, bill.SenatePassageDate);
                                                        }

                                                        let reviewContent = headers + billText.DraftText;

                                                        //Remove ldtitle from document if the header is present and the header also contains the ldtitle
                                                        let doc = document.createElement('div');
                                                        doc.insertAdjacentHTML("afterbegin", reviewContent);
                                                        let header = doc.getElementsByTagName('div');

                                                        if (header[0] != undefined) {
                                                            //Store the header somewhere while we find and iterate over paragraphs in the rest of the doc
                                                            let headerContainer = header[0].innerHTML;
                                                            let headerParagraphs = header[0].getElementsByTagName('p');

                                                            for (let i = 0; i < headerParagraphs.length; i++) {
                                                                if (['ldtitle', 'ld', 'patronLine', 'patctr'].includes(headerParagraphs[i].classList[0])) {
                                                                    //Temporarily remove header                              
                                                                    header[0].innerHTML = "";

                                                                    //We found the ldtitle or patronage in the header, so iterate over the paragraphs to find any duplicate ldtitles or patronages and eliminate them
                                                                    let docParagraphs = doc.getElementsByTagName('p');

                                                                    for (let j = 0; j < docParagraphs.length; j++) {
                                                                        if (billText.LegislationVersion === "Substitute" && ['pro', 'center', 'patronLine', 'patctr', 'textbl', 'ldtitle', 'ld'].includes(docParagraphs[j].classList[0])) {
                                                                            doc.removeChild(docParagraphs[j])
                                                                            j--;
                                                                        } else if (billText.LegislationVersion === "Substitute") {
                                                                            continue;
                                                                        }

                                                                        if (docParagraphs[j] && ['section', 'enactstm'].includes(docParagraphs[j].classList[0])) {
                                                                            break; //reached the actal body of the text, don't remove any more
                                                                        } else if (docParagraphs[j] && ['ldtitle', 'ld', 'patronLine', 'patctr', 'center'].includes(docParagraphs[j].classList[0])) {
                                                                            doc.removeChild(docParagraphs[j])
                                                                            j--
                                                                        }
                                                                    }
                                                                } else if (this.state.selectedLegType.Name === "Engrossed" && doc.getElementsByClassName("billpatron")[0]) {
                                                                    //Not needed on save, but part of copy-paste; If working on an engrossed version, store the headers' patronage info which is needed for the preview (the object returned in the GetLegislationTextById call is not always correct)
                                                                    //patronageInfo = doc.getElementsByClassName("billpatron")[0].textContent
                                                                }
                                                            }

                                                            if (this.state.selectedLegType.Name === "Reenrolled"
                                                                && this.props.session.sessionList.find(x => x.SessionID === this.state.selectedSession.SessionID).SessionEvents.find(x => x.DisplayName === "Reconvene")
                                                                && moment(this.props.session.sessionList.find(x => x.SessionID === this.state.selectedSession.SessionID).SessionEvents.find(x => x.DisplayName === "Reconvene").ActualDate).isSameOrBefore(moment(billText.VersionDate) ?? moment(), "day")) {
                                                                //Set the header to reconvened session and reattach the header 
                                                                let headerDoc = document.createElement('div');
                                                                headerDoc.insertAdjacentHTML("afterbegin", headerContainer);
                                                                let paragraphs = headerDoc.getElementsByTagName('p');
                                                                paragraphs[0].innerHTML = this.state.selectedSession.SessionYear + " RECONVENED SESSION";

                                                                header[0].appendChild(headerDoc);

                                                                this.setState({
                                                                    onReconvene: true
                                                                })
                                                            } else {
                                                                //Reattach the header
                                                                header[0].innerHTML = headerContainer;
                                                                this.setState({
                                                                    onReconvene: false
                                                                })
                                                            }

                                                            newDraftText = doc.innerHTML
                                                        }

                                                        if (newDraftText != undefined) {
                                                            this.setState({
                                                                reviewContent: newDraftText
                                                            })
                                                        }
                                                    }
                                                }).catch(e => {
                                                    console.log(e)
                                                    this.setState({ reviewContent: `<p class="message-error">${typeof e === 'object' && !Array.isArray ? JSON.parse(e).Name || "Unknown Error" : e}</p>` });
                                                })
                                        } else {
                                            //Remove ldtitle from document if the header is present and the header also contains the ldtitle
                                            let doc = document.createElement('div');
                                            doc.insertAdjacentHTML("afterbegin", originalBillText[selectedBillTextIndex].DraftText);
                                            let header = doc.getElementsByTagName('div');

                                            if (header[0]) {
                                                //Store the header somewhere while we find and iterate over paragraphs in the rest of the doc
                                                let headerContainer = header[0].innerHTML;
                                                let headerParagraphs = header[0].getElementsByTagName('p');

                                                for (let i = 0; i < headerParagraphs.length; i++) {
                                                    if (['ldtitle', 'ld', 'patronLine', 'patctr'].includes(headerParagraphs[i].classList[0])) {
                                                        //Temporarily remove header                              
                                                        header[0].innerHTML = "";

                                                        //We found the ldtitle or patronage in the header, so iterate over the paragraphs to find any duplicate ldtitles or patronages and eliminate them
                                                        let docParagraphs = doc.getElementsByTagName('p');

                                                        for (let j = 0; j < docParagraphs.length; j++) {
                                                            if (['section', 'enactstm'].includes(docParagraphs[j].classList[0])) {
                                                                break; //reached the actal body of the text, don't remove any more
                                                            } else if (['ldtitle', 'ld', 'patronLine', 'patctr', 'center'].includes(docParagraphs[j].classList[0])) {
                                                                doc.removeChild(docParagraphs[j])
                                                                j--
                                                            }
                                                        }
                                                    }
                                                }
                                                //Reattach the header
                                                header[0].innerHTML = headerContainer;

                                                newDraftText = doc.innerHTML;
                                            }

                                            if (newDraftText != undefined) {
                                                originalBillText[selectedBillTextIndex].DraftText = newDraftText;

                                                this.setState({
                                                    originalBillText: originalBillText,
                                                })
                                            }
                                        }

                                        Promise.all([jsonPromise]).finally(() => {
                                            this.setState(prevState => ({ isDirty: false, isSaving: false, savedReviewContent: prevState.reviewContent, editorChanged: false }));
                                            this.props.actions.makeToast([{ message: "Save Successful", type: "success" }])
                                        });
                                    }

                                });
                            }
                        })

                })
                .catch(err => {
                    if (err === 'Aborted') {
                        return;
                    }
                    this.props.actions.makeToast([{ message: "Save Failed", type: "failure" }]);
                    this.setState({
                        isSaving: false
                    });
                });
        }
    }

    handleSaveCompletedItem() {
        this.setState({ isSaving: true, enrolledPrintQueue: [], checkedBills: [], useCurrentPublishDates: true, customPublishDate: null });

        let billText = [...this.state.originalBillText];
        let selectedBillTextIndex = this.state.selectedBillTextIndex;
        billText[selectedBillTextIndex].DraftText = this.state.reviewContent;
        //Try to find the draft title in the text and set it
        let searchForLDTitleContainer = document.createElement('div');
        searchForLDTitleContainer.innerHTML = this.state.reviewContent;

        //Remove any tags TinyMCE may have added when pasting from Word (Borrowing the doc we're using for ldtitle search)
        searchForLDTitleContainer.childNodes.forEach(node => {
            if (node.nodeName !== "#text") {
                node.removeAttribute('data-mce-fragment');
                if (node.childNodes.length > 0) {
                    node.childNodes.forEach(childNode => {
                        if (childNode.nodeName !== "#text") {
                            childNode.removeAttribute('data-mce-fragment');
                        }
                    })
                }
            }
        });

        //The first element in the content with this class is the draft title
        let draftLDTitle = searchForLDTitleContainer.querySelector('.ldtitle');
        if (draftLDTitle && draftLDTitle.innerHTML !== '') {
            billText[selectedBillTextIndex].DraftTitle = draftLDTitle.innerHTML;
            billText[selectedBillTextIndex].EditTitle = draftLDTitle.innerHTML;
        }

        //add LegislationClassID/LegislationClass to the LegislationText obj
        billText.forEach(text => {
            text.LegislationClass = this.state.selectedBill.LegislationClass
            text.LegislationClassID = this.state.selectedBill.LegislationClassID
        })

        this.props.actions.saveBillText({ TextsList: billText })
            .then(() => {
                this.setState({ isSaving: false });
                this.props.actions.makeToast([{ message: "Save Successful", type: "success" }]);
                let billTextParams = "?legislationID=" + this.state.selectedBill.LegislationID;
                this.props.actions.getBillText(billTextParams, true)
                    .then(() => {
                        const newbillTextList = [...this.props.bills.billText];
                        const priorBillText = billText[selectedBillTextIndex];
                        // Need to figure out what the index of the bill text we saved was. It isn't always the same one that was sent.
                        if (priorBillText && priorBillText.LegislationTextID) {
                            selectedBillTextIndex = newbillTextList.findIndex(t => t.LegislationTextID === priorBillText.LegislationTextID)
                        } else {
                            // Hopefully this finds it, but since the API might change the draft text around the second findIndex function is used.
                            selectedBillTextIndex = newbillTextList.findIndex(t => t.DraftText === priorBillText.DraftText && t.LegislationVersionID === priorBillText.LegislationVersionID);
                            selectedBillTextIndex = selectedBillTextIndex !== -1 ? selectedBillTextIndex : newbillTextList.findIndex(t => t.LegislationVersionID === priorBillText.LegislationVersionID)
                        }

                        this.handleSelectorChange('selectedSession', this.state.selectedSession);
                        const legFileGenParams = "?legislationID=" + newbillTextList[selectedBillTextIndex].LegislationID
                            + "&legislationTextID=" + newbillTextList[selectedBillTextIndex].LegislationTextID
                            + "&legislationVersionID=" + newbillTextList[selectedBillTextIndex].LegislationVersionID
                            + "&sessionID=" + this.state.selectedSession.SessionID;
                        this.props.actions.genBillFile(legFileGenParams)

                        this.setState({
                            isPreviewVisible: false,
                            selectedBill: '',
                            reviewFieldsetIsActive: false,
                            amendmentFieldsetIsActive: false,
                            billFieldsetIsActive: true,
                            originalBillText: [],
                            billText: '',
                            reviewContent: '',
                            savedReviewContent: '',
                            editorChanged: false,
                            selectedBillTextIndex: -1,
                            reviewKey: 1,
                            isDirty: false
                        });
                    })
            })
            .catch(err => {
                if (err === 'Aborted') {
                    return;
                }
                this.props.actions.makeToast([{ message: "Save Failed", type: "failure" }]);
                this.setState({
                    isSaving: false
                });
            });
    }

    handleEditorChange(content) {
        let replacement = false;
        let doc = document.createElement('div');
        doc.insertAdjacentHTML("afterbegin", content);

        //Remove the header information from the dom so none of the <p> tags within get styled
        let header = doc.getElementsByTagName('div');
        this.setState({
            header: header[0] ? header[0].innerHTML : ""
        }, () => {
            if (header[0] !== undefined) {
                header[0].innerHTML = "";
            }

            let paragraphs = doc.getElementsByTagName('p');

            //Loop the paragraphs and add the section class to each paragraph with a class list length of 0
            for (let i = 0; i < paragraphs.length; i++) {
                if (paragraphs[i].classList.length === 0) {
                    paragraphs[i].classList.add("section");
                    replacement = true;
                }
            }

            if (header[0] !== undefined) {
                header[0].innerHTML = this.state.header;
            }

            //If we've added a section class to a classless <p> tag then replace the content with the dom HTML
            //Otherwise update normally
            //This check is done because updating reviewContent in this way was causing tinyMCE to move the cursor position back to the first line
            if (replacement) {
                this.setState(prevState => ({
                    reviewContent: doc.innerHTML,
                    savedReviewContent: !prevState.editorChanged ? doc.innerHTML : prevState.savedReviewContent, //see notes below
                    editorChanged: true,
                    changeCount: this.state.changeCount + 1,
                    isDirty: this.state.changeCount > 0 && this.state.reviewContent !== doc.innerHTML ? true : false
                }));
            } else {
                this.setState(prevState => ({
                    reviewContent: content,
                    savedReviewContent: !prevState.editorChanged ? content : prevState.savedReviewContent, //see notes below
                    editorChanged: true,
                    changeCount: this.state.changeCount + 1,
                    isDirty: this.state.changeCount > 0 && this.state.reviewContent !== doc.innerHTML ? true : false
                }))
            }
        })

        //I know changeCount is hack-y, using editorChanged wasn't working as expected -JGB

        //handleEditorChange gets called when the editor is initalized, as some of the content is modified slightly; 
        //we therefore need to update the savedReviewContent state on this FIRST call only, as this will be the comparison used to know if the editor has been changed by the user
    }

    setLegDateOptions(billList) {
        let legDates;
        if (this.state.showCompletedItems) {
            legDates = billList.map(x => { if (moment(x.VersionDate).isValid() && (!this.state.reprintsFiltered || x.IsReprint)) return { label: moment(x.VersionDate).format("MM/DD/YYYY"), value: x.VersionDate } })
        } else {
            legDates = billList.map(x => { if (moment(x.CandidateDate).isValid()) return { label: moment(x.CandidateDate).format("MM/DD/YYYY"), value: x.CandidateDate } })
        }
        let legDateOptions = legDates.filter((e, i) => legDates.findIndex(a => a && e && (a["label"] === e["label"])) === i);
        legDateOptions.sort((a, b) => { return moment(a.value) - moment(b.value) })

        this.setState({
            billList: billList,
            legDateOptions: legDateOptions
        });
    }

    checkHandleSelectorChange(key, value, confirmed) {
        if (this.state.isDirty && !confirmed) {
            this.setState({
                storedKey: key,
                storedValue: value,
                showCategoryConfirmation: true
            })
        } else if (confirmed) {
            this.handleSelectorChange(this.state.storedKey, this.state.storedValue)
        } else {
            this.handleSelectorChange(key, value)
        }
    }

    handleSelectorChange(key, value) {
        //An option in the Selector section has been changed.
        if (this.state[key] !== undefined) {
            this.setState({
                [key]: value,
                showCategoryConfirmation: false
            }, () => {
                if (['showCompletedItems', 'selectedSession', 'selectedLegType'].includes(key)) {
                    this.setState({
                        enrolledPrintQueue: [],
                        checkedBills: [],
                        useCurrentPublishDates: true,
                        customPublishDate: null,
                    });
                    //If switching between completed items and incomplete items, change the version type dropdown list
                    if (key === 'showCompletedItems') {
                        let legOptions = [...this.state.legOptions];
                        legOptions.forEach(version => {
                            version.label = this.state.showCompletedItems ? version.Name : version.AuthoringLabel;
                            this.setState({ legOptions })
                        })
                    }
                }
                //If both a session is selected and a legislation type then load a list of bills that adhere to the two selected criteria
                //Close the review and amendment field because the selected bill is no longer valid for the selected criteria
                if (this.state.selectedLegType && this.state.selectedSession) {
                    this.setState({
                        isLoaded: false,
                        selectedBill: '',
                        originalBillText: [],
                        reviewContent: '',
                        amendmentFieldsetIsActive: false,
                        reviewFieldsetIsActive: false,
                        reprintsFiltered: key === "selectedLegNum" || key === "selectedLegDate" ? this.state.reprintsFiltered : false,
                        reviewKey: 1
                    });

                    const params = "?sessionID=" + this.state.selectedSession.SessionID + "&legislationVersionID=" + this.state.selectedLegType.LegislationVersionID + "&IsComplete=" + (this.state.showCompletedItems ? 'true' : 'false');
                    this.props.actions.getBillManagementList(params)
                        .then(() => {
                            if (["Gov Recommendation", "Veto Explanation"].includes(this.state.selectedLegType.Name)) {
                                //Get bills in the session for the bill dropdown
                                this.getSessionBills();
                            }

                            let billList = [...this.props.bills.billManagementList];

                            if (this.state.showCompletedItems) { billList = billList.filter(text => !text.PendingChange) }

                            if (key === "selectedLegNum" || key === "selectedLegDate") {
                                if (key === "selectedLegNum" && value.value !== "clear") {
                                    billList = billList.filter(x => x.LegislationNumber.toLowerCase().includes(value.value.toLowerCase()))
                                }

                                if (key === "selectedLegDate" && value.value != "clear") {
                                    billList = billList.filter(x => moment(this.state.showCompletedItems ? x.VersionDate : x.CandidateDate).format("MM/DD/YYYY") === moment(value.value).format("MM/DD/YYYY"));
                                }

                                //Apply previously set filters if needed
                                if (key === "selectedLegDate" && this.state.selectedLegNum !== '' && this.state.selectedLegNum.value !== 'clear') {
                                    billList = billList.filter(x => x.LegislationNumber.toLowerCase().includes(this.state.selectedLegNum.value.toLowerCase()))
                                }

                                if (key === "selectedLegNum" && this.state.selectedLegDate !== '' && this.state.selectedLegDate.value !== "clear") {
                                    billList = billList.filter(x => moment(this.state.showCompletedItems ? x.VersionDate : x.CandidateDate).format("MM/DD/YYYY") === moment(this.state.selectedLegDate.value).format("MM/DD/YYYY"));
                                }
                            } else {
                                this.setState({
                                    selectedLegNum: '',
                                    selectedLegDate: ''
                                })
                            }

                            this.setLegDateOptions(billList);

                            this.setState({
                                isLoaded: true
                            });
                        }).catch(err => {
                            if (err === 'Aborted') {
                                return;
                            }
                            this.setState({
                                billList: [], // makes it apparent that something may have gone wrong but also prevents the list retaining old values
                                isLoaded: true
                            });
                        });

                }
            });
        }
    }

    updateBillListByLegislationID(legislationID) {
        const params = "?sessionID=" + this.state.selectedSession.SessionID + "&legislationID=" + legislationID + "&legislationVersionID=" + this.state.selectedLegType.LegislationVersionID + "&IsComplete=" + (this.state.showCompletedItems ? 'true' : 'false');
        this.props.actions.getBillManagementList(params)
            .then(() => {
                let billListGetResponse = [...this.props.bills.billManagementList];
                let billListInState = [...this.state.billList];

                const associatedBillInStateIndex = billListInState.findIndex(b => b.LegislationID === legislationID);
                if (associatedBillInStateIndex > -1) {
                    const associatedBillInGetResponse = billListGetResponse.find(b => b.LegislationID === legislationID);
                    if (associatedBillInGetResponse) {
                        billListInState[associatedBillInStateIndex] = associatedBillInGetResponse;
                        this.setState({ billList: billListInState })
                    }
                }
            }).catch(err => {
                if (err === 'Aborted') {
                    return;
                }
                this.setState({
                    billList: [] // makes it apparent that something may have gone wrong but also prevents the list retaining old values
                });
            });
    }

    handleVersionDateChange(value) {
        //Need to +1 the key to refresh the editor content
        let key = this.state.reviewKey + 1;

        this.setState({
            versionDate: value,
            reviewContent: this.updateDraftTextVersionDate(this.state.reviewContent, value),
            reviewKey: key
        }, () => {
            //If the bill is reenrolled check the version publish date against the reconvene date to decide whether to set the header to SESSION or RECONVENED SESSION
            if (this.state.selectedLegType.Name === "Reenrolled") {
                let doc = new DOMParser().parseFromString(this.state.reviewContent, "text/html");
                let paragraphs = doc.getElementsByTagName('p');

                if (this.props.session.sessionList.find(x => x.SessionID === this.state.selectedSession.SessionID).SessionEvents.find(x => x.DisplayName === "Reconvene")
                    && moment(this.props.session.sessionList.find(x => x.SessionID === this.state.selectedSession.SessionID).SessionEvents.find(x => x.DisplayName === "Reconvene").ActualDate).isSameOrBefore(this.state.versionDate, "day")) {
                    paragraphs[0].innerHTML = this.state.selectedSession.SessionYear + " RECONVENED SESSION";

                    this.setState({
                        reviewContent: doc.body.innerHTML,
                        onReconvene: true
                    })
                } else {
                    paragraphs[0].innerHTML = this.state.selectedSession.SessionYear + " SESSION";

                    this.setState({
                        reviewContent: doc.body.innerHTML,
                        onReconvene: false
                    })
                }
            }
        })
    }

    updateDraftTextVersionDate(draftText, versionDate) {
        let doc = new DOMParser().parseFromString(draftText, "text/html");
        let elements = doc.getElementsByClassName('versiondate');

        //elements is an array, may as well iterate over it, especially since some bad data has had multiple tags with class versiondate 
        for (var i = 0; i < elements.length; i++) {
            elements[i].innerText = moment(versionDate).format("LL");
        }

        return doc.body.innerHTML;
    }

    selectBill(bill, billIndex, confirmed, LDNumber, callback) {
        if (this.state.isDirty && !confirmed) {
            this.setState({
                showBillConfirmation: () => this.selectBill(bill, billIndex, true, LDNumber, callback),
                storedBill: bill,
                storedBillIndex: billIndex
            })
        } else {
            this.setState({
                showBillConfirmation: false,
                replaced: false,
                wasEmpty: false,
                isDirty: false,
                changeCount: 0
            }, () => {
                //A bill has been selected. Load the amendments and legislation text then scroll down to it
                let billTextParams = bill ? "?legislationID=" + (bill.LegislationID) : ('?LDNumber=' + LDNumber + '&sessionID=' + this.state.selectedSession.SessionID);
                this.setState({
                    billDataIsLoading: billIndex
                }, () => {
                    this.props.actions.getBillText(billTextParams, true)
                        .then(() => {
                            if (!bill) {
                                bill = (this.state.sessionBillsList.find(sbl => sbl.SessionID === this.state.selectedSession.SessionID) ? this.state.sessionBillsList.find(sbl => sbl.SessionID === this.state.selectedSession.SessionID).bills : []).find(b => b.LegislationID === this.props.bills.billText.find(bt => bt.LegislationID).LegislationID)
                            }
                            if (!bill) {
                                this.setState({
                                    billDataIsLoading: null
                                })
                                this.props.actions.makeToast([{ message: "Bill data not found for LD Number provided", type: "failure" }]);
                                return;
                            }
                            //The first step to deciding what text should show by default is to filter out all the amendments and conference reports
                            let billTextForReviewPane = this.props.bills.billText.filter(text => !["Amendment", "Conference Report"].includes(text.LegislationVersion) && (text.LegislationVersion !== "Gov Recommendation" || this.state.selectedLegType.Name === "Gov Recommendation"))
                            let billText;
                            if (!this.state.showCompletedItems && ["Gov Recommendation", "Veto Explanation"].includes(this.state.selectedLegType.Name) && isNaN(billIndex) && this.props.bills.billText.find(bt => bt.LegislationVersion === this.state.selectedLegType.Name)) {
                                //they are adding a bill to the queue via the session bill selector, but there is already a gov rec on their desired bill
                                //notify them as such, and if there is a public version, propose a link to red edit for them to edit/re-publish this version;
                                //otherwise, select the non-public version for editing
                                //if we allowed them to edit the existing public version (if present), it could result in the version being saved but not having file gen run (if they save but don't publish)
                                if (this.props.bills.billText.find(bt => bt.LegislationVersion === this.state.selectedLegType.Name && bt.IsPublic)) {
                                    this.setState({
                                        billDataIsLoading: null
                                    })
                                    if (callback) callback();
                                    let a = document.createElement('a');
                                    a.target = '_blank';
                                    a.href = `/bill-details/${this.state.selectedSession.SessionCode}/${bill.LegislationNumber}/text/${this.props.bills.billText.find(bt => bt.LegislationVersion === this.state.selectedLegType.Name)?.DocumentCode}`;
                                    document.body.appendChild(a);
                                    if (confirm(`This bill already has a PUBLISHED ${this.state.selectedLegType.Name === "Gov Recommendation" ? "Governor's Recommendation" : "Veto Explanation"}. Press OK to be taken to the red-edit page for this existing version.`)) {
                                        a.click();
                                    }
                                    document.body.removeChild(a);
                                    return;
                                } else {
                                    billText = this.props.bills.billText.find(bt => bt.LegislationVersion === this.state.selectedLegType.Name);
                                }
                            }
                            //If there is one that matches the legislation text id, use that one for the review pane
                            billText = billText ?? billTextForReviewPane.find(bt => bt.LegislationTextID === bill.LegislationTextID);
                            //If the search is by LD Number and there is only one returned, use that one
                            if (LDNumber && billTextForReviewPane.length === 1) {
                                billText = billTextForReviewPane[0];
                            }
                            //Otherwise, if there is an unpublished version, then set that one as the main text since unpublished changes are from a previous save                            
                            if (!billText) {
                                billText = !isNaN(billIndex) ? billTextForReviewPane.find(text => !text.IsPublic) : { DraftText: '' }; //if selecting a session bill, just use new object with only draft text
                            }
                            //If one isn't found then select the text that is active
                            if (!billText) {
                                billText = billTextForReviewPane.find(text => text.IsActive);
                            }
                            //If there still isn't one then select the first one in the list, or nothing if the list is empty
                            if (!billText) {
                                billText = billTextForReviewPane.length > 0 ? billTextForReviewPane[0] : { DraftText: '' }
                            }
                            //If the text is already of the version specified in the selector, then it has been previously saved (e.g. not just the base doc anymore)
                            //Therefore, for the version date, use the existing version date if it has already been saved, otherwise default to today's date
                            const billVersionDate = billText && this.state.selectedLegType.LegislationVersionID === billText.LegislationVersionID ? moment(billText.VersionDate) : moment();
                            //Now get the index of the selected bill text
                            const selectedBillTextIndex = this.props.bills.billText.findIndex(text => text.LegislationTextID === billText.LegislationTextID);
                            const amendments = this.props.bills.billText.filter(text => ["Amendment", "Conference Report", "Gov Recommendation"].includes(text.LegislationVersion));

                            // Get the json headers if the selected version queue is "Engrossed" and we are not in completed items
                            let jsonPromise, headers, reviewContent, patronageInfo;
                            let headerError = false;

                            if (this.state.selectedLegType.Name === "Engrossed"
                                || this.state.selectedLegType.Name === "Reengrossed"
                                || this.state.selectedLegType.Name === "Chaptered"
                                || this.state.selectedLegType.Name === "Enrolled"
                                || this.state.selectedLegType.Name === "Reenrolled") {

                                const jsonHeadersRequestString = this.getJsonHeadersRequestString(billText.LegislationTextID, bill.DocumentCode);

                                jsonPromise = this.props.actions.getJsonHeaders(jsonHeadersRequestString)
                                    .then(() => {
                                        if (this.props.bills.jsonHeaders) {
                                            headers = getHtml(this.props.bills.jsonHeaders);

                                            //Add the PassageDates to the draft text if this is a resolution and in the enrolled queue
                                            if ((this.state.selectedLegType.Name === "Chaptered" || this.state.selectedLegType.Name === "Enrolled") && (bill.LegislationTypeCode === "J" || bill.LegislationTypeCode === "R")) {
                                                headers = this.applyPassageDates(headers, billText, bill.LegislationTypeCode, bill.HousePassageDate, bill.SenatePassageDate);
                                            }

                                            reviewContent = headers + billText.DraftText;

                                            //Remove ldtitle from document if the header is present and the header also contains the ldtitle
                                            let doc = document.createElement('div');
                                            doc.insertAdjacentHTML("afterbegin", reviewContent);
                                            let header = doc.getElementsByTagName('div');
                                            let newDraftText;

                                            if (header[0] != undefined) {
                                                //Store the header somewhere while we find and iterate over paragraphs in the rest of the doc
                                                let headerContainer = header[0].innerHTML;
                                                let headerParagraphs = header[0].getElementsByTagName('p');

                                                for (let i = 0; i < headerParagraphs.length; i++) {
                                                    if (['ldtitle', 'ld', 'patronLine', 'patctr'].includes(headerParagraphs[i].classList[0])) {
                                                        //Temporarily remove header                              
                                                        header[0].innerHTML = "";

                                                        //We found the ldtitle or patronage in the header, so iterate over the paragraphs to find any duplicate ldtitles or patronages and eliminate them
                                                        let docParagraphs = doc.getElementsByTagName('p');

                                                        for (let j = 0; j < docParagraphs.length; j++) {
                                                            if (billText.LegislationVersion === "Substitute" && ['pro', 'center', 'patronLine', 'patctr', 'textbl', 'ldtitle', 'ld'].includes(docParagraphs[j].classList[0])) {
                                                                doc.removeChild(docParagraphs[j])
                                                                j--;
                                                            } else if (billText.LegislationVersion === "Substitute") {
                                                                continue;
                                                            }

                                                            if (docParagraphs[j] && ['section', 'enactstm'].includes(docParagraphs[j].classList[0])) {
                                                                break; //reached the actal body of the text, don't remove any more
                                                            } else if (docParagraphs[j] && ['ldtitle', 'ld', 'patronLine', 'patctr', 'center'].includes(docParagraphs[j].classList[0])) {
                                                                doc.removeChild(docParagraphs[j])
                                                                j--
                                                            }
                                                        }
                                                    } else if (this.state.selectedLegType.Name === "Engrossed" && doc.getElementsByClassName("billpatron")[0]) {
                                                        //If working on an engrossed version, store the headers' patronage info which is needed for the preview (the object returned in the GetLegislationTextById call is not always correct)
                                                        patronageInfo = doc.getElementsByClassName("billpatron")[0].textContent
                                                    }
                                                }

                                                if (this.state.selectedLegType.Name === "Reenrolled"
                                                    && this.props.session.sessionList.find(x => x.SessionID === this.state.selectedSession.SessionID).SessionEvents.find(x => x.DisplayName === "Reconvene")
                                                    && moment(this.props.session.sessionList.find(x => x.SessionID === this.state.selectedSession.SessionID).SessionEvents.find(x => x.DisplayName === "Reconvene").ActualDate).isSameOrBefore(billVersionDate, "day")) {
                                                    //Set the header to reconvened session and reattach the header 
                                                    let headerDoc = document.createElement('div');
                                                    headerDoc.insertAdjacentHTML("afterbegin", headerContainer);
                                                    let paragraphs = headerDoc.getElementsByTagName('p');
                                                    paragraphs[0].innerHTML = this.state.selectedSession.SessionYear + " RECONVENED SESSION";

                                                    header[0].appendChild(headerDoc);

                                                    this.setState({
                                                        onReconvene: true
                                                    })
                                                } else {
                                                    //Reattach the header
                                                    header[0].innerHTML = headerContainer;
                                                    this.setState({
                                                        onReconvene: false
                                                    })
                                                }

                                                newDraftText = doc.innerHTML
                                            }

                                            if (newDraftText != undefined) {
                                                reviewContent = newDraftText
                                            }
                                        }
                                    }).catch(e => {
                                        console.log(e)
                                        reviewContent = `<p class="message-error">${typeof e === 'object' && !Array.isArray ? JSON.parse(e).Name || "Unknown Error" : e}</p>`;
                                        headerError = true;
                                    })
                            } else {
                                reviewContent = billText.DraftText
                            }

                            Promise.all([jsonPromise])
                                .finally(() => {
                                    let storedBill;

                                    if (bill) {
                                        bill.SessionCode = this.state.selectedSession.SessionCode;
                                    } else {
                                        storedBill = { ...this.state.storedBill }
                                        storedBill.SessionCode = this.state.selectedSession.SessionCode;
                                    }

                                    //if this is a gov rec/veto version that has NOT already been saved, display the header info
                                    if (!this.state.showCompletedItems && ["Gov Recommendation", "Veto Explanation"].includes(this.state.selectedLegType.Name) && !(/^\<p class=['"].*['"]>\([HS][BJR]\d{1,5}\)\<\/p>\s{0,1}\<p class=['"].*['"]>GOVERNOR'S (RECOMMENDATION|VETO)\<\/p>/).test(reviewContent)) {
                                        //if it has bill number, but not the GOVERNOR'S RECOMMENDATION or GOVERNOR'S VETO line, remove the existing bill number line before we add both (to prevent duplicate bill # lines)
                                        //if it has no bill number, but does have the GOVERNOR'S RECOMMENDATION or GOVERNOR'S VETO line, remove the existing GOV line before we add both (to prevent duplicate GOV lines)
                                        reviewContent = reviewContent.replace(/^(\<p class=['"].*['"]>\([HS][BJR]\d{1,5}\)\<\/p>\s{0,1}|\<p class=['"].*['"]>GOVERNOR'S (RECOMMENDATION|VETO)\<\/p>\s{0,1})/, "");
                                        reviewContent = "<p class='textbl'>" + "(" + (bill.LegislationNumber) + ")</p><p class='textbl'>" + (this.state.selectedLegType.Name === "Gov Recommendation" ? "GOVERNOR'S RECOMMENDATION" : "GOVERNOR'S VETO") + "</p>" + this.updateDraftTextVersionDate(reviewContent, billVersionDate);
                                    } else {
                                        //default version date within the draft text if present
                                        reviewContent = this.updateDraftTextVersionDate(reviewContent, billVersionDate)
                                    }

                                    //remove selected bill from checkBills/enrolledPrintQueue arrays so they don't publish it from batch publish, or batch print, while they have maybe made unsaved changes in the tinymce editor
                                    let checkedBills = [...this.state.checkedBills];
                                    const checkedBillsIndex = checkedBills.map(b => b.LegislationID).indexOf((bill.LegislationID));
                                    if (checkedBillsIndex !== -1) {
                                        checkedBills.splice(checkedBillsIndex, 1);
                                    }
                                    let enrolledPrintQueue = [...this.state.enrolledPrintQueue];
                                    const enrolledPrintQueueIndex = enrolledPrintQueue.map(b => b.LegislationID).indexOf((bill.LegislationID));
                                    if (enrolledPrintQueueIndex !== -1) {
                                        enrolledPrintQueue.splice(enrolledPrintQueueIndex, 1);
                                    }

                                    this.setState({
                                        headers: headers,
                                        patronageInfo: patronageInfo,
                                        billDataIsLoading: null,
                                        selectedBill: bill ? bill : storedBill,
                                        originalBillText: this.props.bills.billText,
                                        amendments: amendments,
                                        amendmentFieldsetIsActive: true,
                                        reviewFieldsetIsActive: true,
                                        billFieldsetIsActive: false,
                                        reviewContent: reviewContent,
                                        savedReviewContent: reviewContent,
                                        editorChanged: false,
                                        checkedBills,
                                        enrolledPrintQueue,
                                        headerError: headerError,
                                        selectedBillTextIndex: selectedBillTextIndex,
                                        versionDate: billVersionDate,
                                        reviewKey: 1
                                    }, () => {
                                        //Get the size and position of the amendment or review form and scroll down to it
                                        const rect = this.state.showCompletedItems ? this.reviewRef.current.getBoundingClientRect() : this.amendmentRef.current.getBoundingClientRect();
                                        const windowY = window.scrollY;
                                        window.scrollTo({
                                            top: rect.top + windowY,
                                            behavior: 'smooth'
                                        });
                                        if (callback) callback();
                                    });
                                })
                        }).catch(err => {
                            if (err === 'Aborted') {
                                return;
                            }
                            console.log(err);
                            this.setState({
                                billDataIsLoading: null
                            });
                            this.props.actions.makeToast([{ message: "Failed to get Bill Text", type: "failure" }]);
                            if (callback) callback();
                        });
                });
            })
        }
    }

    closeConfirmation() {
        this.setState({
            showTextConfirmation: false,
            showBillConfirmation: false,
            showCategoryConfirmation: false
        })
    }

    selectBillIndex(index, confirmed) {
        if (this.state.isDirty && !confirmed) {
            this.setState({
                showTextConfirmation: true,
                storedTextIndex: index
            })
        } else {
            this.setState({
                showTextConfirmation: false,
                isDirty: false,
                wasEmpty: false,
                replaced: false,
                changeCount: 0,
                changingBillIndex: index
            }, () => {
                if (this.state.originalBillText[index]) {
                    const selectedBillText = this.state.originalBillText[index];

                    //If the text is already of the version specified in the selector, then it has been previously saved (e.g. not just the base doc anymore)
                    //Therefore, for the version date, use the existing version date if it has already been saved, otherwise default to today's date
                    const billVersionDate = this.state.selectedLegType.LegislationVersionID === selectedBillText.LegislationVersionID ? moment(selectedBillText.VersionDate) : moment();

                    let jsonPromise, headers, reviewContent, patronageInfo;
                    let headerError = false;
                    const bill = this.state.billList.find(it => it.LegislationID === this.state.originalBillText[index].LegislationID) || (this.state.sessionBillsList.find(sbl => sbl.SessionID === this.state.selectedSession.SessionID) && this.state.sessionBillsList.find(sbl => sbl.SessionID === this.state.selectedSession.SessionID).bills.find(it => it.LegislationID === this.state.originalBillText[index].LegislationID));

                    if (this.state.selectedLegType.Name === "Engrossed"
                        || this.state.selectedLegType.Name === "Reengrossed"
                        || this.state.selectedLegType.Name === "Chaptered"
                        || this.state.selectedLegType.Name === "Enrolled"
                        || this.state.selectedLegType.Name === "Reenrolled") {

                        const jsonHeadersRequestString = this.getJsonHeadersRequestString(this.state.originalBillText[index].LegislationTextID, bill.DocumentCode);

                        jsonPromise = this.props.actions.getJsonHeaders(jsonHeadersRequestString)
                            .then(() => {
                                if (this.props.bills.jsonHeaders) {
                                    headers = getHtml(this.props.bills.jsonHeaders);

                                    //Add the PassageDates to the draft text if this is a resolution and in the enrolled queue
                                    if (this.state.selectedLegType.Name === "Chaptered" && (bill.LegislationTypeCode === "J" || bill.LegislationTypeCode === "R")) {
                                        headers = this.applyPassageDates(headers, selectedBillText, bill.LegislationTypeCode, bill.HousePassageDate, bill.SenatePassageDate)
                                    }

                                    reviewContent = headers + this.state.originalBillText[index].DraftText;

                                    //Remove ldtitle from document if the header is present and the header also contains the ldtitle
                                    let doc = document.createElement('div');
                                    doc.insertAdjacentHTML("afterbegin", reviewContent);
                                    let header = doc.getElementsByTagName('div');
                                    let newDraftText;

                                    if (header[0] != undefined) {
                                        //Store the header somewhere while we find and iterate over paragraphs in the rest of the doc
                                        let headerContainer = header[0].innerHTML;
                                        let headerParagraphs = header[0].getElementsByTagName('p');

                                        for (let i = 0; i < headerParagraphs.length; i++) {
                                            if (['ldtitle', 'ld', 'patronLine', 'patctr'].includes(headerParagraphs[i].classList[0])) {
                                                //Temporarily remove header                              
                                                header[0].innerHTML = "";

                                                //We found the ldtitle or patronage in the header, so iterate over the paragraphs to find any duplicate ldtitles or patronages and eliminate them
                                                let docParagraphs = doc.getElementsByTagName('p');

                                                for (let j = 0; j < docParagraphs.length; j++) {
                                                    if (selectedBillText.LegislationVersion === "Substitute" && ['pro', 'center', 'patronLine', 'patctr', 'textbl', 'ldtitle', 'ld'].includes(docParagraphs[j].classList[0])) {
                                                        doc.removeChild(docParagraphs[j])
                                                        j--;
                                                    } else if (selectedBillText.LegislationVersion === "Substitute") {
                                                        continue;
                                                    }

                                                    if (docParagraphs[j] && ['section', 'enactstm'].includes(docParagraphs[j].classList[0])) {
                                                        break; //reached the actal body of the text, don't remove any more
                                                    } else if (docParagraphs[j] && ['ldtitle', 'ld', 'patronLine', 'patctr', 'center'].includes(docParagraphs[j].classList[0])) {
                                                        doc.removeChild(docParagraphs[j])
                                                        j--
                                                    }
                                                }
                                            } else if (this.state.selectedLegType.Name === "Engrossed" && doc.getElementsByClassName("billpatron")[0]) {
                                                //If working on an engrossed version, store the headers' patronage info which is needed for the preview (the object returned in the GetLegislationTextById call is not always correct)
                                                patronageInfo = doc.getElementsByClassName("billpatron")[0].textContent
                                            }
                                        }

                                        if (this.state.selectedLegType.Name === "Reenrolled"
                                            && this.props.session.sessionList.find(x => x.SessionID === this.state.selectedSession.SessionID).SessionEvents.find(x => x.DisplayName === "Reconvene")
                                            && moment(this.props.session.sessionList.find(x => x.SessionID === this.state.selectedSession.SessionID).SessionEvents.find(x => x.DisplayName === "Reconvene").ActualDate).isSameOrBefore(billVersionDate, "day")) {
                                            //Set the header to reconvened session and reattach the header 
                                            let headerDoc = document.createElement('div');
                                            headerDoc.insertAdjacentHTML("afterbegin", headerContainer);
                                            let paragraphs = headerDoc.getElementsByTagName('p');
                                            paragraphs[0].innerHTML = this.state.selectedSession.SessionYear + " RECONVENED SESSION";

                                            header[0].appendChild(headerDoc);

                                            this.setState({
                                                onReconvene: true
                                            })
                                        } else {
                                            //Reattach the header
                                            header[0].innerHTML = headerContainer;

                                            this.setState({
                                                onReconvene: false
                                            })
                                        }

                                        newDraftText = doc.innerHTML
                                    }

                                    if (newDraftText != undefined) {
                                        reviewContent = newDraftText
                                    }
                                }
                            }).catch(e => {
                                console.log(e)
                                reviewContent = `<p class="message-error">${typeof e === 'object' && !Array.isArray ? JSON.parse(e).Name || "Unknown Error" : e}</p>`;
                                headerError = true;
                            })
                    } else {
                        reviewContent = this.state.originalBillText[index].DraftText;
                    }

                    Promise.all([jsonPromise])
                        .finally(() => {
                            //if this is a gov rec/veto version that has NOT already been saved, display the header info
                            if (!this.state.showCompletedItems && ["Gov Recommendation", "Veto Explanation"].includes(this.state.selectedLegType.Name) && !["Gov Recommendation", "Veto Explanation"].includes(this.state.originalBillText[index].LegislationVersion)) {
                                reviewContent = "<p class='textbl'>" + "(" + bill.LegislationNumber + ")</p><p class='textbl'>" + (this.state.selectedLegType.Name === "Gov Recommendation" ? "GOVERNOR'S RECOMMENDATION" : "GOVERNOR'S VETO") + "</p><p></p>"
                            } else {
                                //default version date within the draft text if present
                                reviewContent = this.updateDraftTextVersionDate(reviewContent, billVersionDate)
                            }

                            this.setState({
                                headers: headers,
                                patronageInfo: patronageInfo,
                                selectedBillTextIndex: index,
                                reviewContent: reviewContent,
                                savedReviewContent: reviewContent,
                                editorChanged: false,
                                headerError: headerError,
                                versionDate: billVersionDate,
                                reviewKey: 1,
                                changingBillIndex: false
                            })
                        })
                }
            })
        }
    }

    checkBill(bill, enrolledPrintQueue) {
        let checkedBills = enrolledPrintQueue ? [...this.state.enrolledPrintQueue] : [...this.state.checkedBills];
        const billIndex = checkedBills.findIndex(b => b.LegislationTextID === bill.LegislationTextID)
        if (billIndex !== -1) {
            checkedBills.splice(billIndex, 1);
        } else {
            checkedBills.push(bill);
        }
        if (enrolledPrintQueue) {
            this.setState({ enrolledPrintQueue: checkedBills });
        } else {
            this.setState({ checkedBills }, () => {
                if (!this.state.checkedBills.length) {
                    this.setState({ customPublishDate: null, useCurrentPublishDates: true })
                }
            });
        }
    }

    togglePublishDatePreference() {
        this.setState({ useCurrentPublishDates: !this.state.useCurrentPublishDates }, () => {
            if (this.state.useCurrentPublishDates) {
                this.setState({ customPublishDate: null })
            }
        })
    }

    handlePublishDateChange(date) {
        this.setState({ customPublishDate: date })
    }

    getSessionBills() {
        if (!this.state.sessionBillsList || !this.state.sessionBillsList.length || !this.state.sessionBillsList.find(sbl => sbl.SessionID === this.state.selectedSession.SessionID))
            //Get bills in the session for the bill dropdown
            this.props.actions.getSessionBills('?sessionID= ' + this.state.selectedSession.SessionID).then(() => {
                let bills = [...this.props.bills.sessionBills].filter(b => b.LegislationTypeCode === "B" && (this.props.login.userClaims.roles.find((x) => x === "Admin") ? true : this.props.login.userClaims.roles.find((x) => x === "HouseLegislationAuthor") ? b.ChamberCode === "H" : b.ChamberCode === "S"));
                bills.forEach(bill => {
                    bill.label = bill.LegislationNumber
                    bill.value = bill.LegislationID
                });
                let sessionBillsList = [...this.state.sessionBillsList];
                sessionBillsList.push({ SessionID: this.state.selectedSession.SessionID, bills: bills })
                this.setState({
                    sessionBillsList
                })
            })
    }

    batchPublish() {
        this.setState({ isSaving: true }, () => {
            this.props.actions.batchPublish({ "PublishDate": !this.state.useCurrentPublishDates && this.state.customPublishDate ? this.state.customPublishDate : null, "legislationTextIDs": this.state.checkedBills.map(bills => bills.LegislationTextID), "legislationIDs": this.state.checkedBills.map(bills => bills.LegislationID) })
                .then(() => {
                    this.props.actions.batchGenBillFile("?sessionID=" + this.state.selectedSession.SessionID, this.state.checkedBills.map(bills => bills.LegislationTextID))
                        .then(() => {
                            this.props.actions.makeToast([{ message: "Publish Successful - Processing Files...", type: "success" }])

                            this.setState({
                                checkedBills: [],
                                enrolledPrintQueue: [],
                                useCurrentPublishDates: true,
                                customPublishDate: null,
                                isLoaded: false,
                                selectedBill: '',
                                originalBillText: [],
                                reviewContent: '',
                                amendmentFieldsetIsActive: false,
                                reviewFieldsetIsActive: false,
                                reviewKey: 1,
                                isSaving: false
                            }, () => {
                                const params = "?sessionID=" + this.state.selectedSession.SessionID + "&legislationVersionID=" + this.state.selectedLegType.LegislationVersionID + "&IsComplete=" + (this.state.showCompletedItems ? 'true' : 'false');
                                this.props.actions.getBillManagementList(params)
                                    .then(() => {
                                        let billList = [...this.props.bills.billManagementList];

                                        if (this.state.showCompletedItems) { billList = billList.filter(text => !text.PendingChange) }

                                        this.setState({
                                            selectedLegDate: '',
                                            selectedLegNum: ''
                                        })

                                        this.setLegDateOptions(billList);
                                        this.setState({
                                            isLoaded: true
                                        });
                                    }).catch(err => {
                                        if (err === 'Aborted') {
                                            return;
                                        }
                                        this.setState({
                                            billList: [], // makes it apparent that something may have gone wrong but also prevents the list retaining old values
                                            isLoaded: true
                                        });
                                    });
                            });
                        }).catch((err) => {
                            if (err === 'Aborted') {
                                return;
                            }
                            this.props.actions.makeToast([{ message: "File Generation Failed", type: "failure" }]);
                            this.setState({ isSaving: false });
                        })
                }).catch((err) => {
                    if (err === 'Aborted') {
                        return;
                    }
                    this.props.actions.makeToast([{ message: "Publish Failed", type: "failure" }]);
                    this.setState({ isSaving: false });
                })
        })
    }

    batchComplete() {
        this.setState({ isSaving: true }, () => {
            this.props.actions.batchComplete({ "IsComplete": true, "legislationTextIDs": this.state.enrolledPrintQueue.map(bills => ({ LegislationTextID: bills.LegislationTextID })) })
                .then(() => {
                    this.props.actions.makeToast([{ message: "Print Parchment Successful", type: "success" }])

                    this.setState({
                        checkedBills: [],
                        enrolledPrintQueue: [],
                        useCurrentPublishDates: true,
                        customPublishDate: null,
                        isLoaded: false,
                        selectedBill: '',
                        originalBillText: [],
                        reviewContent: '',
                        amendmentFieldsetIsActive: false,
                        reviewFieldsetIsActive: false,
                        reviewKey: 1,
                        isSaving: false
                    }, () => {
                        const params = "?sessionID=" + this.state.selectedSession.SessionID + "&legislationVersionID=" + this.state.selectedLegType.LegislationVersionID + "&IsComplete=" + (this.state.showCompletedItems ? 'true' : 'false');
                        this.props.actions.getBillManagementList(params)
                            .then(() => {
                                let billList = [...this.props.bills.billManagementList];

                                if (this.state.showCompletedItems) { billList = billList.filter(text => !text.PendingChange) }

                                this.setState({
                                    selectedLegDate: '',
                                    selectedLegNum: ''
                                })

                                this.setLegDateOptions(billList);
                                this.setState({
                                    isLoaded: true
                                });
                            }).catch(err => {
                                if (err === 'Aborted') {
                                    return;
                                }
                                this.setState({
                                    billList: [], // makes it apparent that something may have gone wrong but also prevents the list retaining old values
                                    isLoaded: true
                                });
                            });
                    });
                }).catch((err) => {
                    if (err === 'Aborted') {
                        return;
                    }
                    this.props.actions.makeToast([{ message: "Print Parchment Failed", type: "failure" }]);
                    this.setState({ isSaving: false });
                })
        })
    }

    applyPassageDates(headers, billText, legislationTypeCode, housePassageDate, senatePassageDate) {
        //Check the text of the header and doc to make sure the passage dates haven't already been added
        let preDoc = document.createElement('div');
        preDoc.insertAdjacentHTML("afterbegin", headers + billText.DraftText);
        let preDocParagraphs = preDoc.getElementsByTagName("p");
        let housePassage = false
        let senatePassage = false;

        for (let i = 0; i < preDocParagraphs.length; i++) {
            if (preDocParagraphs[i].classList && preDocParagraphs[i].classList[0] === "house-passage") {
                housePassage = true;
            }

            if (preDocParagraphs[i].classList && preDocParagraphs[i].classList[0] === "senate-passage") {
                senatePassage = true;
            }
        }

        //Add the passage dates if they exist in the data but aren't in the DraftText yet - add both chambers for joint resolutions, or just the originating chamber for normal resolutions
        if (billText.ChamberCode === "H") {
            headers = housePassageDate && housePassageDate != "" && !housePassage ? headers + "<p class='house-passage center'>Agreed to by the House of Delegates, " + moment(housePassageDate).format("LL") + "</p>" : headers;
            if (legislationTypeCode === "J") {
                headers = senatePassageDate && senatePassageDate != "" && !senatePassage ? headers + "<p class='senate-passage center'>Agreed to by the Senate, " + moment(senatePassageDate).format("LL") + "</p><p></p>" : headers;
            } else {
                headers = headers + "<p></p>";
            }
        } else {
            headers = senatePassageDate && senatePassageDate != "" && !senatePassage ? headers + "<p class='senate-passage center'>Agreed to by the Senate, " + moment(senatePassageDate).format("LL") + "</p>" : headers;
            if (legislationTypeCode === "J") {
                headers = housePassageDate && housePassageDate != "" && !housePassage ? headers + "<p class='house-passage center'>Agreed to by the House of Delegates, " + moment(housePassageDate).format("LL") + "</p><p></p>" : headers;
            } else {
                headers = headers + "<p></p>";
            }
        }

        return headers;
    }

    getJsonHeadersRequestString(legislationTextId, documentCode) {
        let overrideParam = this.state.selectedLegType.Name.toLowerCase();

        let jsonHeadersRequestString = "?legislationTextID=" + legislationTextId + "&sessionID=" + this.state.selectedSession.SessionID + "&overrideLegislationVersion=" + overrideParam + "&onlyHeader=true";
        if (this.state.selectedLegType.Name === "Chaptered") {
            //For chaptered legislation, add overrideDocumentCode param and use the future chapter number to be displayed in the headers
            jsonHeadersRequestString += "&overrideDocumentCode=" + documentCode;
        }

        return jsonHeadersRequestString
    }

    toggleCompleteItems() {
        this.handleSelectorChange('showCompletedItems', !this.state.showCompletedItems);
    }

    componentDidMount() {
        //Get the list of active sessions for the session dropdown
        this.props.actions.getSessionShallowList("IsActive=true")
            .then(() => {
                let sessionList = [...this.props.session.sessionShallowList];
                sessionList.forEach((session, index) => {
                    session.label = session.SessionYear + ' ' + session.DisplayName;
                    session.value = session.SessionID;
                    //Set the last session in the list as the selected session
                    if (session.IsDefault) {
                        this.handleSelectorChange('selectedSession', session);
                    }
                });
                this.setState({
                    sessionOptions: sessionList
                });
            });

        this.props.actions.getBillVersionRef()
            .then(() => {
                const billVersionRef = [...this.props.bills.billVersionRef];
                // Only list the important versions in the dropdown. AKA any version that has an AuthoringLabel is important 
                const billVersionsToUse = billVersionRef.filter(v => v.AuthoringLabel);
                let billVersionOptions = []
                billVersionsToUse.forEach(version => {
                    //Do not add Chaptered to the version list dropdown if Senate user
                    if (version.Name !== "Chaptered" || this.props.login.userClaims.claims.find(x => x.Resource === "LegislationText" && (x.Scope === "All" || x.Scope === "House"))) {
                        version.label = this.state.showCompletedItems ? version.Name : version.AuthoringLabel;
                        version.value = version.LegislationVersionID;
                        billVersionOptions.push(version);
                    }
                });
                this.setState({
                    billVersionRef: billVersionRef,
                    legOptions: billVersionOptions.sort((a, b) => a.label.localeCompare(b.label)),
                });
            });
    }

    toggleHeaders() {
        this.setState(prevState => ({
            showHeaders: !prevState.showHeaders
        }))
    }

    resetWarning() {
        this.setState({
            reviewKey: 1
        })
    }

    componentWillUnmount() {
        cancelRequest();
    }

    render() {
        const { selectedBill, originalBillText, headers } = this.state;
        // Bills that were chaptered, vetoed, or had recommendations added need the date that they were originally signed by the governor visible to the user
        const signedText = originalBillText.find(text =>
            text.LegislationVersion === 'Veto Explanation' ||
            text.LegislationVersion === 'Gov Recommendation' ||
            text.LegislationVersion === 'Chaptered');
        if (this.state.isPreviewVisible) {
            return (
                <div>
                    {this.state.showIncompletePdf &&
                        <Popout togglePortal={this.togglePortal} windowTitle="PDF Preview">
                            {this.state.gettingIncompletePdf &&
                                <div className="center-spinner spinner" />
                            }
                            <iframe style={{ display: this.state.gettingIncompletePdf ? 'none' : 'block', width: '100%', height: '100%' }} ref={this.pdfRef}></iframe>
                        </Popout>
                    }
                    <div className="flex-row align-center bottomline">
                        <h2>{this.state.selectedSession.SessionYear} {this.state.selectedSession.DisplayName} / {selectedBill.LegislationNumber}</h2>
                        <div>
                            {!this.state.showCompletedItems && ['chaptered', 'enrolled', 'reenrolled', 'engrossed', 'reengrossed'].includes(this.state.selectedLegType.Name.toLowerCase()) &&
                                <span><button type="button" className="button pdf" style={{ paddingTop: '5px' }} onClick={this.previewPdf}>PDF</button></span>
                            }
                            <ReactToPrint
                                trigger={() => <span><button type="button" className="button print">Print</button></span>}
                                content={() => this.componentRef}
                                pageStyle={"break-inside: avoid"}
                            />
                        </div>
                    </div>
                    {this.state.previewContent !== '' && this.state.showCompletedItems
                        ? <iframe ref={el => (this.componentRef = el)} src={this.state.previewContent} type="application/pdf" width="100%" height="1080px" style={{ marginBottom: "45px" }}></iframe>
                        : <PreviewSection ref={el => (this.componentRef = el)}
                            headers={headers}
                            selectedBill={selectedBill}
                            editorContent={this.state.previewContent}
                            error={this.state.previewContentError}
                            selectedLegType={this.state.selectedLegType}
                            billVersionRef={this.state.billVersionRef}
                        />
                    }
                    <div className={"align-right floating-button-bar inline-list" + (!this.state.showCompletedItems && this.state.selectedLegType.Name === 'Enrolled' ? " print-parchment" : "")}>
                        {
                            this.state.showCompletedItems ?
                                <button disabled={this.state.isSaving} onClick={this.togglePreview} className="button secondary">Edit</button>
                                : this.state.selectedLegType.Name === 'Enrolled' &&
                                <React.Fragment>
                                    <button disabled={this.state.isSaving} onClick={() => this.handleSave(false, true)} className="button">Print Parchment</button>
                                    <div />
                                </React.Fragment>
                        }
                        {this.state.showCompletedItems ?
                            <React.Fragment>
                                <div className="flex-row flex-vertical-center flex-end">
                                    <div className="toggle-switch" onClick={this.toggleReprint}>
                                        <input id="official-reprint-button" checked={this.state.originalBillText[this.state.selectedBillTextIndex] && this.state.originalBillText[this.state.selectedBillTextIndex].IsReprint} type="checkbox" style={{ width: "auto" }} />
                                        <span className="slider"></span>
                                    </div>
                                    <label htmlFor="official-reprint-button" className="checkbox-label no-background" style={{ whiteSpace: "no-wrap", marginTop: '0px', fontSize: '14px' }}>Mark As Official Reprint</label>
                                    <button disabled={this.state.isSaving || !this.state.selectedBill} onClick={this.handleSaveCompletedItem} className="button">{this.state.isSaving ? "Republishing..." : "Republish"}</button>
                                </div>
                            </React.Fragment>
                            :
                            <React.Fragment>
                                <button disabled={this.state.isSaving} onClick={this.togglePreview} className="button secondary">Edit</button>
                                {(this.state.selectedBill.CandidateDate || ([2, 3, 6, 7, 9, 10].includes(this.state.selectedLegType.LegislationVersionID) && !this.state.selectedBill.CandidateDate)) &&
                                    <button disabled={this.state.isSaving || !this.state.selectedBill || !this.state.originalBillText[this.state.selectedBillTextIndex].DocumentCode} onClick={() => this.handleSave(true)} className="button">{this.state.isSaving ? "Publishing..." : "Publish"}</button>
                                }
                            </React.Fragment>
                        }
                    </div>
                </div >
            );
        } else {
            return (
                <div>
                    {(this.state.showTextConfirmation || this.state.showBillConfirmation) &&
                        <div className="schedule-modal">
                            <div className="schedule-modal-content">
                                <p>You have unsaved changes. Are you sure you want to open a different document?</p>
                                <button className="button secondary float-right" onClick={() => this.closeConfirmation()}>No</button>
                                <button className="button primary float-right" onClick={() => this.state.showTextConfirmation ? this.selectBillIndex(this.state.storedTextIndex, true) : this.state.showBillConfirmation()} style={{ marginRight: "10px" }}>Yes</button>
                                <br />
                            </div>
                        </div>
                    }
                    {this.state.showCategoryConfirmation &&
                        <div className="schedule-modal">
                            <div className="schedule-modal-content">
                                <p>You have unsaved changes. Are you sure you want to select a different option?</p>
                                <button className="button secondary float-right" onClick={() => this.closeConfirmation()}>No</button>
                                <button className="button primary float-right" onClick={() => this.checkHandleSelectorChange(this.state.storedKey, this.state.storedValue, true)} style={{ marginRight: "10px" }}>Yes</button>
                                <br />
                            </div>
                        </div>
                    }
                    <Prompt
                        when={this.state.isDirty}
                        message={`You have unsaved changes. Are you sure you would like to leave?`}
                    />
                    <div className="header-half">
                        <h1>Version Authoring{this.state.showCompletedItems && ' (Published Versions)'}</h1>
                        <p>
                            <button onClick={() => this.toggleCompleteItems()} className="button" type="button">{this.state.showCompletedItems ? 'Back' : 'View Published Versions'}</button>
                        </p>
                    </div>
                    <div className="user-forms dlas-forms" style={{ paddingBottom: "40px" }}>
                        <fieldset className={this.state.billFieldsetIsActive ? 'fieldset-collapse fieldset-open' : 'fieldset-collapse fieldset-closed'} >
                            <legend onClick={this.toggleFieldsetCollapse.bind(this, 'billFieldsetIsActive')}>Bills {this.state.processingFile && <span> | <i>Creating file...</i></span>}</legend>
                            <div>
                                <SelectorSection
                                    onChange={this.checkHandleSelectorChange}
                                    sessionOptions={this.state.sessionOptions}
                                    legOptions={this.state.legOptions}
                                    selectedSession={this.state.selectedSession}
                                    selectedLegType={this.state.selectedLegType}
                                    selectedLegDate={this.state.selectedLegDate}
                                    selectedLegNum={this.state.selectedLegNum}
                                    legDateOptions={this.state.legDateOptions}
                                    disable={this.state.isSaving}
                                />
                                <span className="insert-message small-text fail">{this.props.bills.billManagementListError}</span>
                                <BillGridSection
                                    billList={this.state.billList.filter(b => !this.state.reprintsFiltered ? true : b.IsReprint)}
                                    selectBill={this.selectBill}
                                    selectedBill={this.state.selectedBill}
                                    checkBill={this.checkBill}
                                    checkedBills={this.state.checkedBills}
                                    enrolledPrintQueue={this.state.enrolledPrintQueue}
                                    batchPublish={this.batchPublish}
                                    batchComplete={this.batchComplete}
                                    isSaving={this.state.isSaving}
                                    isLoaded={this.state.isLoaded}
                                    billDataIsLoading={this.state.billDataIsLoading}
                                    completedItems={this.state.showCompletedItems}
                                    legislationVersionID={this.state.selectedLegType.LegislationVersionID}
                                    useCurrentPublishDates={this.state.useCurrentPublishDates}
                                    togglePublishDatePreference={this.togglePublishDatePreference}
                                    handlePublishDateChange={this.handlePublishDateChange}
                                    customPublishDate={this.state.customPublishDate}
                                    reprintsFiltered={this.state.reprintsFiltered}
                                    filterReprints={this.filterReprints}
                                    sessionBillsList={this.state.sessionBillsList.find(sbl => sbl.SessionID === this.state.selectedSession.SessionID) ? this.state.sessionBillsList.find(sbl => sbl.SessionID === this.state.selectedSession.SessionID).bills : []}
                                />
                            </div>
                        </fieldset>
                        {!this.state.showCompletedItems &&
                            <fieldset ref={this.amendmentRef} className={this.state.amendmentFieldsetIsActive ? 'fieldset-collapse fieldset-open' : 'fieldset-collapse fieldset-closed'} >
                                <legend onClick={this.toggleFieldsetCollapse.bind(this, 'amendmentFieldsetIsActive')}>
                                    Amendments {this.state.amendments.length > 0 && <span style={{ verticalAlign: 'bottom' }} className="icon popout" onClick={(e) => this.togglePortal(this.state.showPortal, e)}></span>}
                                    {this.state.showPortal &&
                                        <Popout togglePortal={this.togglePortal} windowTitle={this.state.selectedBill.LegislationNumber + ' Amendments'}>
                                            <AmendmentSection
                                                amendments={this.state.amendments}
                                            />
                                        </Popout>
                                    }
                                </legend>
                                <div>
                                    <AmendmentSection
                                        amendments={this.state.amendments}
                                    />
                                </div>
                            </fieldset>
                        }
                        <fieldset ref={this.reviewRef} className={this.state.reviewFieldsetIsActive ? 'fieldset-collapse fieldset-open' : 'fieldset-collapse fieldset-closed'} >
                            <legend onClick={this.toggleFieldsetCollapse.bind(this, 'reviewFieldsetIsActive')}><span>Review </span>
                                {selectedBill &&
                                    <span>
                                        | {selectedBill.LegislationNumber}
                                        {signedText && signedText.VersionDate &&
                                            <React.Fragment> | Signed: {moment(signedText.VersionDate).format('MM/DD/YYYY')} </React.Fragment>
                                        }
                                    </span>
                                }</legend>
                            {!this.state.showCompletedItems && selectedBill &&
                                <>
                                    <label htmlFor="version-date-picker">Version Publish Date</label>
                                    <DatePicker
                                        id="version-date-picker"
                                        selected={this.state.versionDate}
                                        onChange={this.handleVersionDateChange}
                                    />
                                </>
                            }
                            <div>
                                {!this.state.showCompletedItems &&
                                    <React.Fragment>
                                        <TextList
                                            originalBillText={this.state.originalBillText}
                                            selectedBillTextIndex={this.state.selectedBillTextIndex}
                                            selectBillIndex={this.selectBillIndex}
                                            selectedLegType={this.state.selectedLegType}
                                            changingBillIndex={this.state.changingBillIndex}
                                        />
                                    </React.Fragment>
                                }
                                <ReviewSection
                                    editorContent={this.state.reviewContent}
                                    handleEditorChange={this.handleEditorChange}
                                    sessionId={this.state.selectedSession.SessionID}
                                    reviewKey={this.state.reviewKey}
                                    resetWarning={this.resetWarning}
                                    setWasEmpty={this.setWasEmpty}
                                    wasEmpty={this.state.wasEmpty}
                                    setReplaced={this.setReplaced}
                                    replaced={this.state.replaced}
                                    billNumber={this.state.selectedBill.LegislationNumber}
                                    userChamber={this.props.login.userClaims.roles.find((x) => x === "Admin") ? "Admin" : this.props.login.userClaims.roles.find((x) => x === "HouseLegislationAuthor") ? "H" : "S"}
                                    allowAmendmentActions={this.state.selectedLegType.Name === "Gov Recommendation"}
                                    allowGovernorAmendmentSponsor={this.state.selectedLegType.Name === "Gov Recommendation"}
                                    amendmentOrigin={this.state.selectedLegType.Name === "Gov Recommendation" ? "G" : ""}
                                    overrideRemoveAgreeRejectButtons={true}
                                    legislationVersion={originalBillText[this.state.selectedBillTextIndex] && originalBillText[this.state.selectedBillTextIndex].LegislationVersion}
                                    pasteAsText={this.state.selectedLegType.Name !== "Gov Recommendation"}
                                    versionAuthoring={true}
                                />
                                {this.state.reviewContent != "" &&
                                    <div className="align-right floating-button-bar inline-list">
                                        {!this.state.showCompletedItems && <button type="button" className="button" disabled={this.state.isSaving || !this.state.selectedBill || this.state.headerError} onClick={() => this.handleSave(false)}>{this.state.isSaving ? "Saving..." : "Save"}</button>}
                                        <button type="button" className="button" disabled={this.state.gettingCompletedItemPreview || this.state.isSaving || !this.state.selectedBill || !this.state.originalBillText[this.state.selectedBillTextIndex] || this.state.headerError || (!this.state.showCompletedItems && (this.state.reviewContent !== this.state.savedReviewContent || this.state.selectedLegType && this.state.originalBillText[this.state.selectedBillTextIndex] && this.state.selectedLegType.LegislationVersionID !== this.state.originalBillText[this.state.selectedBillTextIndex].LegislationVersionID))} onClick={this.togglePreview}>{this.state.gettingCompletedItemPreview ? 'Getting Preview' : 'Preview'}</button>
                                    </div>
                                }
                            </div>
                        </fieldset>
                    </div>
                </div>
            );
        }
    }
}
export default connect(
    (state) => {
        const { bills, session, nav, login } = state;
        return {
            bills,
            session,
            nav,
            login
        }
    },
    (dispatch) => {
        return {
            actions: bindActionCreators(Object.assign({}, billActionCreators, sessionActionCreators, navActionCreators), dispatch)
        }
    }
)(VersionManagement)