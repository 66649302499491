import React from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom'
import { sessionActionCreators } from '../../stores/lis-session-store';
import { navActionCreators } from '../../stores/lis-nav-store';
import { calendarActionCreators } from '../../stores/lis-calendar-store';
import moment from 'moment';
import { cancelRequest } from '../../services/request.service';


class PublicCalendarList extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            session: '',
            senateIsLoaded: false,
            houseIsLoaded: false,
            senateCalendarList: [],
            houseCalendarList: [],
            showHouseChamber: true,
            showSenateChamber: true
        };

        this.toggleChamber = this.toggleChamber.bind(this);
    }

    toggleChamber(chamber) {
        this.setState(state => ({
            [chamber]: !state[chamber]
        }));
    }

    //When component has mounted, make the call to get session by code
    componentDidMount() {
        //Update the session selector component, which will run componentDidUpdate and call setupPage on its own
        const sessionCode = this.props.match.params.sessioncode
        if (this.props.nav.session !== sessionCode) {
            this.props.actions.changeSession(sessionCode)
        } else {
            this.setupPage(sessionCode);
        }
    }

    componentDidUpdate(prevProps) {
        // Setup the page if the global selected session changes. Change the URL to reflect the session change
        if (prevProps.nav.session !== this.props.nav.session) {
            this.props.history.push("/session-details/" + this.props.nav.session + "/calendar");
            this.setupPage(this.props.nav.session);
        } else if (this.props.nav.session !== this.props.match.params.sessioncode) {
            // If the url changes then update the global selected session with whatever the url is
            const sessionCode = this.props.match.params.sessioncode;
            if (!isNaN(sessionCode)) {
                this.props.actions.changeSession(sessionCode);
            }
        }
    }

    componentWillUnmount() {
        cancelRequest();
    }

    setupPage(sessionCode) {
        this.setState({
            senateIsLoaded: false,
            houseIsLoaded: false
        });
        //Get the session information
        this.props.actions.getSessionByCode(sessionCode)
            .then(() => {
                if (this.props.session.selectedSession) {
                    this.setState({
                        session: this.props.session.selectedSession
                    });
                }
            });
        //Get the list for the Senate
        this.props.actions.getCalendarList('?sessionCode=' + sessionCode + '&chamberCode=S')
            .then(() => {
                this.setState({
                    senateCalendarList: this.props.calendar.calendarList,
                    senateIsLoaded: true
                });
            });
        //Get the list for the House
        this.props.actions.getCalendarList('?sessionCode=' + sessionCode + '&chamberCode=H')
            .then(() => {
                this.setState({
                    houseCalendarList: this.props.calendar.calendarList,
                    houseIsLoaded: true
                });
            });
    }

    render() {
        const { senateCalendarList, houseCalendarList, senateIsLoaded, houseIsLoaded, session } = this.state;
        const sessionHeader = session ? session.SessionYear + " " + session.DisplayName : '';
        return (
            <div className="public-chamber-data">
                <div>
                    <h2>{sessionHeader}</h2>
                </div>
                <div className="public-list inner-grid two">
                    <div>
                        <h3 role="button" onClick={() => this.toggleChamber("showHouseChamber")} aria-expanded={this.state.showHouseChamber} className={`chamber-name ${this.state.showHouseChamber ? "opened" : ""}`}>House Calendar</h3>
                        {houseIsLoaded ?
                            <table className="line-table">
                                {this.state.showHouseChamber &&
                                    <tbody className="toggle-table">
                                        <div className="list-desc">
                                            {houseCalendarList.map(calendar =>
                                                <tr key={calendar.CalendarID} className="inner-grid two alternating-color-list">
                                                    <td>
                                                        <Link to={`/session-details/${session.SessionCode}/calendar/${calendar.ReferenceNumber}`}>
                                                            {moment(calendar.CalendarDate).format("MMMM D, YYYY") + " " + (calendar.CalendarNumber > 1 ? "(sup. " + (calendar.CalendarNumber - 1) + ")" : "")}
                                                        </Link>
                                                    </td>
                                                </tr>
                                            )}
                                        </div>
                                    </tbody>
                                }
                            </table>
                            :
                            <div aria-label="Loading" className="center-spinner spinner"></div>
                        }
                    </div>
                    <div>
                        <h3 role="button" onClick={() => this.toggleChamber("showSenateChamber")} aria-expanded={this.state.showSenateChamber} className={`chamber-name ${this.state.showSenateChamber ? "opened" : ""}`}>Senate Calendar</h3>
                        {senateIsLoaded ?
                            <table className="line-table">
                                {this.state.showSenateChamber &&
                                    <tbody className="toggle-table">
                                        <div className="list-desc">
                                            {senateCalendarList.map(calendar =>
                                                <tr key={calendar.CalendarID} className="inner-grid two alternating-color-list">
                                                    <td>
                                                        <Link to={`/session-details/${session.SessionCode}/calendar/${calendar.ReferenceNumber}`}>
                                                            {moment(calendar.CalendarDate).format("MMMM D, YYYY") + " " + (calendar.CalendarNumber > 1 ? "(sup. " + (calendar.CalendarNumber - 1) + ")" : "")}
                                                        </Link>
                                                    </td>
                                                </tr>
                                            )}
                                        </div>
                                    </tbody>
                                }
                            </table>
                            :
                            <div aria-label="Loading" className="center-spinner spinner"></div>
                        }
                    </div>
                </div>
            </div>
        );
    }
}

export default connect(
    (state) => {
        const { session, nav, calendar } = state;
        return {
            session,
            nav,
            calendar
        }
    },
    (dispatch) => {
        return {
            actions: bindActionCreators(Object.assign({}, sessionActionCreators, navActionCreators, calendarActionCreators), dispatch)
        }
    }
)(PublicCalendarList)
