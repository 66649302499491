import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { navActionCreators } from '../../../stores/lis-nav-store';
import { userActionCreators } from '../../../stores/lis-user-store';

class UserManagement extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            newUser: false,
            existingUser: false,
            person: {
                FirstName: "",
                LastName: "",
                EmailAddress: ""
            },
            searching: false,
            saving: false
        }

        this.cancel = this.cancel.bind(this);
        this.create = this.create.bind(this);
        this.handleInputChange = this.handleInputChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
        this.onSubmit = this.onSubmit.bind(this);
        this.save = this.save.bind(this);
    }

    cancel() {
        this.setState({
            newUser: false,
            existingUser: false,
            person: {
                EmailAddress: "",
                FirstName: "",
                LastName: ""
            }
        })
    }

    create() {
        this.setState({
            saving: true
        }, () => {
            let person = { ...this.state.person }

            person.Name = this.state.person.FirstName.trim() + " " + this.state.person.LastName.trim();
            delete person.FirstName;
            delete person.LastName;

            this.props.actions.createNewUser(person)
                .then(() => {
                    this.props.actions.getPerson(this.props.users.newUserCreate)
                        .then(() => {
                            let person = { ...this.props.users.person };
                            if (!person.FirstName && !person.LastName) {
                                let splitName = person.FullName.split(" ");
                                person.FirstName = splitName[0];
                                person.LastName = splitName[1];
                            }
                            this.setState({
                                person: person,
                                newUser: false,
                                existingUser: true,
                                saving: false
                            }, () => {
                                this.props.actions.makeToast([{ message: "Save Successful", type: "success" }]);
                            })
                        })
                })
        })
    }

    handleInputChange(e, state) {
        let person = { ...this.state.person };
        person[state] = e.target.value;
        this.setState({
            person
        })
    }

    handleSubmit() {
        this.setState({
            searching: true
        }, () => {
            this.props.actions.getUserByEmail("?emailAddress=" + this.state.person.EmailAddress.trim())
                .then(() => {
                    if (this.props.users.userByEmail != "") {
                        this.props.actions.getPerson(this.props.users.userByEmail.IdentityID)
                            .then(() => {
                                let person = { ...this.props.users.person };
                                if (!person.FirstName && !person.LastName) {
                                    let splitName = person.FullName.split(" ");
                                    person.FirstName = splitName[0];
                                    person.LastName = splitName[1];
                                }
                                this.setState({
                                    existingUser: true,
                                    newUser: false,
                                    person: person,
                                    searching: false
                                })
                            })
                    } else {
                        this.setState({
                            newUser: true,
                            existingUser: false,
                            searching: false
                        })
                    }
                })
        })
    }

    onSubmit(e) {
        e.preventDefault();
    }

    save() {
        this.setState({
            saving: true
        }, () => {
            let person = { ...this.state.person };
            person.FullName = (person.FirstName || "") + " " + (person.LastName || "");
            this.props.actions.savePerson(this.state.person)
                .then(() => {
                    this.props.actions.getPerson(this.state.person.IdentityID)
                        .then(() => {
                            let person = { ...this.props.users.person };
                            this.setState({
                                person: person,
                                saving: false
                            }, () => {
                                this.props.actions.makeToast([{ message: "Save Successful", type: "success" }]);
                            })
                        })
                })
        })
    }

    render() {
        return (
            <div>
                <h2>User Management</h2>
                <form className="dlas-forms" onSubmit={this.onSubmit}>
                    {!this.state.existingUser &&
                        <React.Fragment>
                            <label>Search for a user by email address</label>
                            <div className="inner-grid three-one-and-two">
                                <input onChange={(e) => this.handleInputChange(e, "EmailAddress")} value={this.state.person.EmailAddress} />
                                <button disabled={this.state.searching} className="button primary" type="submit" onClick={() => this.handleSubmit()}>{this.state.searching ? "Searching..." : "Search"}</button>
                            </div>
                        </React.Fragment>
                    }
                    {this.state.newUser &&
                        <React.Fragment>
                            <p>User not found. You may either search for a different email address, or enter a first name and last name then click create user to create a new user account for the provided email address.</p>
                            <div className="inner-grid six">
                                <input
                                    placeholder="First Name"
                                    onChange={(e) => this.handleInputChange(e, "FirstName")}
                                    value={this.state.person.FirstName}
                                />
                                <input
                                    placeholder="Last Name"
                                    onChange={(e) => this.handleInputChange(e, "LastName")}
                                    value={this.state.person.LastName}
                                />
                                <button disabled={this.state.saving} className="button primary" type="button" onClick={() => this.create()}>{this.state.saving ? "Saving..." : "Create User"}</button>
                            </div>
                        </React.Fragment>
                    }
                    {this.state.existingUser &&
                        <React.Fragment>
                            <div className="multi-row sixth-even no-margin">
                                <div>
                                    <label>Prefix</label>
                                    <input type="text"
                                        placeholder="Prefix"
                                        value={this.state.person.Prefix}
                                        onChange={(e) => this.handleInputChange(e, 'Prefix')}
                                    />
                                </div>
                                <div>
                                    <label>First Name</label>
                                    <input type="text"
                                        placeholder="First Name"
                                        value={this.state.person.FirstName}
                                        onChange={(e) => this.handleInputChange(e, 'FirstName')}
                                    />
                                </div>
                                <div>
                                    <label>Middle Name</label>
                                    <input type="text"
                                        placeholder="Middle Name"
                                        value={this.state.person.MiddleName}
                                        onChange={(e) => this.handleInputChange(e, 'MiddleName')}
                                    />
                                </div>
                                <div>
                                    <label>Nickname</label>
                                    <input type="text"
                                        placeholder="Nickname"
                                        value={this.state.person.AlternateName}
                                        onChange={(e) => this.handleInputChange(e, 'AlternateName')}
                                    />
                                </div>
                                <div>
                                    <label>Last Name</label>
                                    <input type="text"
                                        placeholder="Last Name"
                                        value={this.state.person.LastName}
                                        onChange={(e) => this.handleInputChange(e, 'LastName')}
                                    />
                                </div>
                                <div>
                                    <label>Suffix</label>
                                    <input type="text"
                                        placeholder="Suffix"
                                        value={this.state.person.Suffix}
                                        onChange={(e) => this.handleInputChange(e.target.value, 'Suffix')}
                                    />
                                </div>
                            </div>
                            <button className="button secondary float-right" type="button" onClick={() => this.cancel()}>Cancel</button>
                            <button disabled={this.state.saving} style={{ marginRight: "10px" }} className="button primary float-right" type="button" onClick={() => this.save()}>{this.state.saving ? "Saving..." : "Save"}</button>
                        </React.Fragment>
                    }
                </form>
            </div>
        );
    }
}

export default connect(
    (state) => {
        const { nav, users } = state;
        return {
            nav,
            users
        }
    },
    (dispatch) => {
        return {
            actions: bindActionCreators(Object.assign({}, navActionCreators, userActionCreators), dispatch)
        }
    }
)(UserManagement)