import React from "react";
import ReactTable from "react-table";
import { Link } from 'react-router-dom';
import '../../../stylesheets/shared/react-table.css';
import moment from 'moment';
import * as Fields from '../../../lis-shared/lis-layout/components/lis-forms-component';
import DatePicker from "react-datepicker";
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { minutesActionCreators } from '../../../stores/lis-minutes-store';
import { sessionActionCreators } from '../../../stores/lis-session-store';
import { cancelRequest } from '../../../services/request.service';


class MinutesGridComponent extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            showCreateSuccess: false,
            showCreateFailure: false,
            isSaving: false,
            minutesDate: moment(),
            isLoaded: false,
            selected: {},
            sessionList: [],
            chamberOptions: [{ value: 'H', label: 'House' }, {value: 'S', label: 'Senate' }],
            sessionDropdown: [],
            selectedSessionToolbar: '',
            selectedSessionGrid: '',
            selectAll: 0,
            filtered: [{ id: 'all', value: 'true' }],
            filterAll: 'true',
            selectedRow: []
        };
        this.toggleRow = this.toggleRow.bind(this);
        this.handleDateChange = this.handleDateChange.bind(this);
        this.filterAll = this.filterAll.bind(this);
    };

    //When component has mounted, make the call to the list users API
    componentDidMount() {
        let selectedSession = this.props.selectedSession;
        let defaultSession = '';
        this.props.actions.getSessionList().then(() => {
            let sessionList = [...this.props.session.sessionList].reverse();
            sessionList.forEach(session => {
                session.label = session.SessionYear + ' ' + session.DisplayName;
                session.value = session.SessionID;
                if (session.IsDefault) {
                    selectedSession = selectedSession || session;
                    defaultSession = session;
                }
            });
            this.setState({
                sessionDropdown: sessionList,
                sessionList: sessionList,
                selectedSessionGrid: defaultSession
            });
            this.props.handleSessionChange(selectedSession, () => this.getMinutesData());
        });
    }

    componentWillUnmount() {
        cancelRequest();
    }

    getMinutesData() {
        if (!this.props.selectedSession) {
            return
        }
        const params = 'sessionID=' + this.props.selectedSession.SessionID + '&chamberCode=S';
        this.setState({
            isLoaded: false
        });
        this.props.actions.getMinutesList(params, true).then(() => {
            this.setState({
                isLoaded: true,
                minutesList: this.props.minutes.minutesList
            });
        }).catch(err => {
            if(err === 'Aborted') {
                return;
            }
            this.setState({
                isLoaded: true,
                message: err.toString()
            });
        });
    }

    handleDateChange(date) {
        this.setState({
            minutesDate: moment(date)
        });
    }

    handleDropDownChange = (value, state) => {
        this.setState({
            [state]: value
        })
    }

    handleSessionSelectChange = value => {
        this.props.handleSessionChange(value, () => this.getMinutesData())
    }


    sendCreateDetails() {
        const minutesObj = {
            "SessionID": this.state.selectedSessionGrid.SessionID,
            "ChamberCode": "S",
            "MinutesDate": this.state.minutesDate
        }
        this.setState({
            isSaving: true
        });
        this.props.actions.createMinutes(minutesObj).then(() => {
            this.setState({
                showCreateSuccess: true,
                isSaving: false
            })
            setTimeout(function () {
                this.setState({ showCreateSuccess: false });
            }.bind(this), 5000);
            this.props.handleSessionChange(this.state.selectedSessionGrid, () => this.getMinutesData())
        }).catch(err => {
            if (err === 'Aborted') {
                return;
            }
            this.setState({
                showCreateFailure: true,
                isSaving: false
            });
            setTimeout(function () {
                this.setState({ showCreateFailure: false });
            }.bind(this), 5000);
        });
    }

    onFilteredChange(filtered) {
        if (filtered.length > 1 && this.state.filterAll.length) {
            // NOTE: this removes any FILTER ALL filter
            const filterAll = '';
            this.setState({ filtered: filtered.filter((item) => item.id !== 'all'), filterAll })
        }
        else
            this.setState({ filtered });
    }

    filterAll(value) {
        const filterAll = value;
        const filtered = [{ id: 'all', value: filterAll }];
        // NOTE: this completely clears any COLUMN filters
        this.setState({ filterAll, filtered });
    }

    toggleRow(minutesId) {
        const newSelected = Object.assign({}, this.state.selected);
        newSelected[minutesId] = !this.state.selected[minutesId];
        this.setState({
            selected: newSelected,
            selectAll: 2
        });
    }

    toggleSelectAll() {
        let newSelected = {};

        if (this.state.selectAll === 0) {
            this.state.minutesList.forEach(x => {
                newSelected[x.MinutesBookID] = true;
            });
        }

        this.setState({
            selected: newSelected,
            selectAll: this.state.selectAll === 0 ? 1 : 0
        });
    }

    getTrProps = (state, rowInfo, instance) => {
        if (rowInfo) {
            return {
                style: {
                    background: rowInfo.original.IsDefault ? 'rgb(202, 225, 241)' : null,
                }
            }
        }
        return {};
    }


    render() {
        return (
            <div>
                <div className="header-half">
                    <h1>Minutes Management</h1>
                </div>

                <div className="toolbar minutes-toolbar dlas-forms">
                    <div>
                        <Fields.Dropdown
                            value={this.props.selectedSession}
                            options={this.state.sessionDropdown}
                            id="sessionDropdown"
                            onChange={this.handleSessionSelectChange}
                        />
                    </div>
                </div>
                {this.state.message &&
                    <span className="fail small-text">{this.state.message}</span>
                }
                <ReactTable
                    resizable={false}
                    loading={!this.state.isLoaded}
                    data={this.state.minutesList}
                    ref={r => this.reactTable = r}
                    defaultFilterMethod={(filter, row) =>
                        String(row[filter.id]) === filter.value}
                    noDataText={this.props.minutes.minutesMessage}
                    onFilteredChange={this.onFilteredChange.bind(this)}
                    columns={[
                        {
                            Header: "Minutes Date",
                            columns: [
                                {
                                    id: "checkbox",
                                    className: "checkbox-column",
                                    headerClassName: "header-checkbox",
                                    accessor: "",
                                    Cell: ({ original, index }) => {
                                        return (
                                            <input
                                                id={'cm-checkbox-' + index}
                                                type="checkbox"
                                                className="checkbox"
                                                checked={this.state.selected[original.MinutesBookID] === true}
                                                onChange={() => this.toggleRow(original.MinutesBookID)}
                                            />
                                        );
                                    },
                                    Header: x => {
                                        return (
                                            <input
                                                id={'cm-checkbox-selectall'}
                                                type="checkbox"
                                                className="checkbox"
                                                checked={this.state.selectAll === 1}
                                                ref={input => {
                                                    if (input) {
                                                        input.indeterminate = this.state.selectAll === 2;
                                                    }
                                                }}
                                                onChange={() => this.toggleSelectAll()}
                                            />
                                        );
                                    },
                                    sortable: false,
                                    filterable: false,
                                    width: 45
                                },
                                {
                                    Header: (
                                        <div className="dlas-forms datepicker-container">
                                            {!this.state.showCreateSuccess && !this.state.showCreateFailure &&
                                                <DatePicker
                                                    id="minutesDate"
                                                    className="dnd-field"
                                                    selected={this.state.minutesDate}
                                                    onChange={this.handleDateChange}
                                                    placeholderText="Minutes Date"
                                                />
                                            }
                                        </div>
                                    ),
                                    headerClassName: "header-insert",
                                    accessor: "MinutesDate",
                                    sortable: false,
                                    filterAll: true,
                                    width: 200,
                                    Cell: row => {
                                        return (
                                            <span id={'cm-datecell-' + row.index}>{moment(row.original.MinutesDate).format('L')}</span>
                                        )
                                    }
                                }
                            ]
                        },
                        {
                            Header: "Session",
                            columns: [
                                {
                                    Header: (
                                        <div>
                                            {!this.state.showCreateSuccess && !this.state.showCreateFailure &&
                                                <Fields.Dropdown
                                                    value={this.state.selectedSessionGrid}
                                                    options={this.state.sessionList}
                                                    className="grid-selector-dropdown"
                                                    classNamePrefix="grid-selector-dropdown"
                                                    id="sessionDropdown"
                                                    onChange={(value) => { this.handleDropDownChange(value, 'selectedSessionGrid') }} />
                                            }
                                            {this.state.showCreateSuccess &&
                                                <span className="insert-message">Minutes created successfully</span>
                                            }
                                            {this.state.showCreateFailure &&
                                                <span className="insert-message fail">Minutes creation failed</span>
                                            }
                                        </div>
                                    ),
                                    headerClassName: "header-insert-dropdown",
                                    accessor: "SessionName",
                                    sortable: false,
                                    filterAll: true,
                                    Cell: row => {
                                        return <span id={'mm-session-' + row.index}>{row.value}</span>
                                    }
                                }
                            ]
                        },
                        {
                            Header: "Status",
                            columns: [
                                {
                                    Header: (
                                        <div>
                                            {!this.state.showCreateSuccess &&
                                                <span className="txt-greyed txt-grid-header">Pending</span>
                                            }
                                        </div>
                                    ),
                                    headerClassName: "header-insert",
                                    accessor: "Status",
                                    sortable: false,
                                    filterAll: true,
                                    Cell: row => {
                                        return <span className="txt-greyed" id={'mm-status-' + row.index}>{row.value}</span>
                                    }
                                }
                            ]
                        },
                        {
                            Header: "Actions",
                            headerClassName: "submit-action",
                            columns: [

                                {
                                    Header: (<span><button id="submit-new-minutes" onClick={this.sendCreateDetails.bind(this)} disabled={this.state.isSaving} className="button">Create</button></span>),
                                    headerClassName: "header-action",
                                    accessor: "action",
                                    sortable: false,
                                    className: "action-column",
                                    width: 50,
                                    Cell: row => {
                                        return (
                                            <Link id={'cm-edit-' + row.index} to={`/minutes-management/edit-minutes/${row.original.MinutesBookID}`} className="button edit">Edit</Link>
                                        )
                                    }
                                },
                                {
                                    Header: "",
                                    headerClassName: "flag-header",
                                    accessor: "PendingChange",
                                    width: 40,
                                    Cell: row => {
                                        if (row.original.PendingChange) {
                                            return <span id={'cm-flag-' + row.index} className="button flag">Flag</span>
                                        } else {
                                            return <span></span>;
                                        }
                                    }
                                },
                                {
                                    Header: "All",
                                    id: 'all',
                                    className: "all-column",
                                    headerClassName: "all-header",
                                    width: 0,
                                    resizable: false,
                                    sortable: false,
                                    Filter: () => { },
                                    getProps: () => {
                                        return {
                                            style: { padding: "0px" }
                                        }
                                    },
                                    filterMethod: (filter, rows) => {
                                        return rows.chamber === this.state.selectedChamber;
                                    }
                                }
                            ]
                        }
                    ]}
                    defaultSorted={[
                        {
                            id: "Name",
                            desc: false
                        }
                    ]}
                    defaultPageSize={15}
                    pageSizeOptions={[5, 10, 15, 20, 25, 50, 100]}
                    className="committee-table -striped -highlight"
                    getTrProps={(state, rowInfo) => {
                        // console.log('original:',rowInfo.original);
                        // console.log('row:',rowInfo.row);
                        // console.log('row.firstName:',rowInfo.row.firstName);
                        // console.log('original.children:',rowInfo.original.children);
                        return {}
                    }}
                />
                <br />

            </div>
        );
    }
}

const MinutesManagementGrid = connect(
    (state) => {
        const { minutes, session } = state;
        return {
            minutes,
            session
        }
    },
    (dispatch) => {
        return {
            actions: bindActionCreators(Object.assign({}, sessionActionCreators, minutesActionCreators), dispatch)
        }
    }
)(MinutesGridComponent)


export default MinutesManagementGrid;