import React from 'react';
import { Route } from 'react-router';
import JournalManagementGrid from './lis-journal-grid';
import JournalManagementForm from './lis-journal-form';

class JournalManagement extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            selectedSession: '',
        };
        this.handleSessionChange = this.handleSessionChange.bind(this);
    }

    handleSessionChange(value, callback) {
        this.setState({
            selectedSession: value
        }, () => callback());
    }

    render() {
        return (
            <div>
                <Route exact path={this.props.match.path} render={props => <JournalManagementGrid
                    {...props}
                    selectedSession={this.state.selectedSession}
                    handleSessionChange={this.handleSessionChange}
                />} />
                <Route path={`${this.props.match.path}/edit-journal/:journalid`} component={JournalManagementForm} />
            </div>
        )
    }
}

export default JournalManagement;