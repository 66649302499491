import tinymce from 'tinymce/tinymce';

tinymce.PluginManager.add('createamendment', function (editor) {
    let selections = {};
    let members = editor.getParam("members") || [];
    let committees = editor.getParam("committees") || [];
    let addAmendmentCallback = editor.getParam("addAmendmentCallback");
    let userChamber = editor.getParam("userChamber");
    let billNumber = editor.getParam("billNumber");
    let allowGovernorAmendmentSponsor = editor.getParam("allowGovernorAmendmentSponsor");
    let amendmentOrigin = editor.getParam("amendmentOrigin");
    if (amendmentOrigin && amendmentOrigin === "H") { amendmentOrigin = "HOUSE" }
    else if (amendmentOrigin && amendmentOrigin === "S") { amendmentOrigin = "SENATE" }
    else if (allowGovernorAmendmentSponsor && amendmentOrigin && amendmentOrigin === "G") { amendmentOrigin = "GOVERNOR" }
    let sponsor = editor.getParam("sponsor");
    let member = editor.getParam("member");
    let committee = editor.getParam("committee");

    let formItems = [];
    const placeholder = {
        text: "Select",
        value: ""
    }
    if (members.length > 0) {
        members.unshift(placeholder);
        formItems = formItems.concat({
            type: "selectbox",
            name: "member",
            label: "Choose a member",
            items: members
        });
    }
    if (committees.length > 0) {
        committees.unshift(placeholder);
        formItems = formItems.concat({
            type: "selectbox",
            name: "committee",
            label: "Choose a committee",
            items: committees
        });
    }
    editor.ui.registry.addButton('createamendment', {
        text: 'Add Amendment',
        onAction: () => {
            const dialog = editor.windowManager.open(initialPageConfig);
            if (billNumber) {
                dialog.setData({ billnumber: billNumber });
            }
            if (amendmentOrigin && ['HOUSE', 'SENATE', 'GOVERNOR'].includes(amendmentOrigin.toUpperCase())) {
                dialog.setData({ amendmentorigin: amendmentOrigin });
            }
        }
    })

    const initialPageConfig = {
        title: "Insert Amendment",
        body: {
            type: "panel",
            items: [
                {
                    type: "input",
                    name: "billnumber",
                    label: "Type in a bill number"
                },
                {
                    type: 'htmlpanel',
                    html: '<p class="small-text">Select the origin of the amendments and press Next to continue or Cancel to exit</p>'
                },
                {
                    type: "selectbox",
                    name: "amendmentorigin",
                    label: "Amendment origin",
                    items: userChamber === "Admin"
                        ? [
                            { value: "HOUSE", text: "House" },
                            { value: "SENATE", text: "Senate" },
                            ...(allowGovernorAmendmentSponsor ? [{ value: "GOVERNOR", text: "Governor" }] : [])
                        ]
                        : userChamber === "H"
                            ? [
                                { value: "HOUSE", text: "House" },
                                ...(allowGovernorAmendmentSponsor ? [{ value: "GOVERNOR", text: "Governor" }] : [])
                            ]
                            : [
                                { value: "SENATE", text: "Senate" },
                                ...(allowGovernorAmendmentSponsor ? [{ value: "GOVERNOR", text: "Governor" }] : [])
                            ]
                },
            ]
        },
        buttons: [
            {
                type: "cancel",
                name: "cancel",
                text: "Cancel"
            },
            {
                type: "custom",
                name: "next",
                text: "Next",
                disabled: !billNumber || billNumber.length <= 2
            }
        ],
        onChange: (dialogApi) => {
            const data = dialogApi.getData();
            // bill numbers will always have more than two characters
            if (data.billnumber.length > 2 && data.amendmentorigin) {
                dialogApi.enable('next')
            } else {
                dialogApi.disable('next')
            }
        },
        onAction: (dialogApi, details) => {
            if (details.name === 'next') {
                selections = { ...selections, ...dialogApi.getData() }
                if ((!selections.amendmentorigin || selections.amendmentorigin !== "GOVERNOR") && (members.length > 0 || committees.length > 0)) {
                    dialogApi.redial(committeeAndMemberConfig);
                    if (sponsor && sponsor === "Member") {
                        dialogApi.disable('committee')
                        if (member) {
                            dialogApi.setData({ member: member })
                        }
                    } else if (sponsor && sponsor === "Committee") {
                        dialogApi.disable('member')
                        if (committee) {
                            dialogApi.setData({ committee: committee })
                        }
                    }
                } else {
                    dialogApi.redial(amendmentTypeDialog);
                    if (selections.amendmentorigin === 'GOVERNOR' && !selections.versiontype) {
                        dialogApi.setData({ versiontype: 'enrolled' })
                    } else if (selections.versiontype) {
                        dialogApi.setData({ versiontype: selections.versiontype })
                    }
                }
            }
        }
    }

    const committeeAndMemberConfig = {
        title: "Insert Amendment",
        body: {
            type: "panel",
            items: formItems
        },
        buttons: [
            {
                type: "custom",
                name: "previous",
                text: "Previous"
            },
            {
                type: "custom",
                name: "next",
                text: "Next",
            }
        ],
        onChange: (dialogApi) => {
            const data = dialogApi.getData();
            if (data.member !== undefined) {
                if (data.member !== '') {
                    dialogApi.disable('committee')
                    dialogApi.setData({ amendmenttype: "floor" })
                } else {
                    dialogApi.enable('committee')
                    dialogApi.setData({ amendmenttype: "" })
                }
            }

            if (data.committee !== undefined) {
                if (data.committee !== '') {
                    dialogApi.disable('member')
                    dialogApi.setData({ amendmenttype: "committee" })
                } else {
                    dialogApi.enable('member')
                    dialogApi.setData({ amendmenttype: "" })
                }
            }
        },
        onAction: (dialogApi, details) => {
            selections = { ...selections, ...dialogApi.getData() }
            if (details.name === 'next') {
                dialogApi.redial(amendmentTypeDialog);
                if (selections.amendmentorigin === 'GOVERNOR' && !selections.versiontype) {
                    dialogApi.setData({ versiontype: 'enrolled' })
                } else if (selections.versiontype) {
                    dialogApi.setData({ versiontype: selections.versiontype })
                }
            }
            if (details.name === "previous") {
                dialogApi.redial(initialPageConfig);
                if (selections.billnumber) {
                    dialogApi.setData({ billnumber: selections.billnumber });
                } else if (billNumber) {
                    dialogApi.setData({ billnumber: billNumber });
                }
                if (selections.amendmentorigin) {
                    dialogApi.setData({ amendmentorigin: selections.amendmentorigin });
                } else if (amendmentOrigin && ['HOUSE', 'SENATE', 'GOVERNOR'].includes(amendmentOrigin.toUpperCase())) {
                    dialogApi.setData({ amendmentorigin: amendmentOrigin });
                }
            }
        }
    }

    let previousValue = "1";
    const amendmentTypeDialog = {
        title: "Insert Amendment",
        initialData: {
            amendmentstobeinserted: previousValue
        },
        body: {
            type: "panel",
            items: [
                {
                    type: "selectbox",
                    name: "versiontype",
                    label: "Version type",
                    items: [
                        { value: "introduced", text: "Introduced" },
                        { value: "substitute", text: "Substitute" },
                        { value: "engrossed", text: "Engrossed" },
                        { value: "enrolled", text: "Enrolled" }
                    ]
                },
                {
                    type: "input",
                    name: "amendmentstobeinserted",
                    label: "Number of amendments to be inserted",
                    width: 45,
                    class: "fake-class"
                }
            ]
        },
        buttons: [
            {
                type: "custom",
                name: "previous",
                text: "Previous"
            },
            {
                type: "submit",
                name: "submitbutton",
                text: "OK"
            }
        ],
        onChange: (dialogApi, details) => {
            if (details.name === "amendmentstobeinserted") {
                const data = dialogApi.getData();
                // The amount of amendments to be inserted need to be a number
                if (isNaN(data.amendmentstobeinserted)) {
                    dialogApi.setData({ amendmentstobeinserted: previousValue })
                } else {
                    previousValue = data.amendmentstobeinserted
                    if (data.amendmentstobeinserted) {
                        dialogApi.enable('submitbutton')
                    } else {
                        dialogApi.disable('submitbutton')
                    }
                }
            }
        },
        onAction: (dialogApi, details) => {
            if (details.name === 'previous') {
                selections = { ...selections, ...dialogApi.getData() }
                if ((!selections.amendmentorigin || selections.amendmentorigin !== "GOVERNOR") && (members.length > 0 || committees.length > 0)) {
                    dialogApi.redial(committeeAndMemberConfig);
                    if (selections.member) {
                        dialogApi.disable('committee')
                        dialogApi.setData({ member: selections.member })
                    } else if (selections.committee) {
                        dialogApi.disable('member')
                        dialogApi.setData({ committee: selections.committee })
                    } else if (sponsor && sponsor === "Member") {
                        dialogApi.disable('committee')
                        if (member) {
                            dialogApi.setData({ member: member })
                        }
                    } else if (sponsor && sponsor === "Committee") {
                        dialogApi.disable('member')
                        if (committee) {
                            dialogApi.setData({ committee: committee })
                        }
                    }
                } else {
                    dialogApi.redial(initialPageConfig);
                    if (selections.billnumber) {
                        dialogApi.setData({ billnumber: selections.billnumber });
                    } else if (billNumber) {
                        dialogApi.setData({ billnumber: billNumber });
                    }
                    if (selections.amendmentorigin) {
                        dialogApi.setData({ amendmentorigin: selections.amendmentorigin });
                    } else if (amendmentOrigin && ['HOUSE', 'SENATE', 'GOVERNOR'].includes(amendmentOrigin.toUpperCase())) {
                        dialogApi.setData({ amendmentorigin: amendmentOrigin });
                    }
                }
            }
        },
        onSubmit: dialogApi => {
            selections = { ...selections, ...dialogApi.getData() }
            const textToBeInserted = amendmentBuilder(selections);
            // Insert the text after whatever the current selection is
            const endNode = editor.selection.getEnd();
            editor.selection.select(endNode);
            editor.selection.collapse();
            editor.insertContent(textToBeInserted);

            if (addAmendmentCallback) {
                addAmendmentCallback(selections);
            }

            dialogApi.close();
        }
    }
});

const amendmentBuilder = formData => {
    const amendmentsToBeInserted = parseInt(formData.amendmentstobeinserted, 10)
    const billNumberSection = formData.amendmentorigin === 'GOVERNOR' ? '' : `<p class="textbl">(${formData.billnumber.toUpperCase()})</p>`;
    const titleSection = formData.amendmentorigin === 'GOVERNOR' ? '' : `<p class="textbl">AMENDMENT${amendmentsToBeInserted > 1 ? "S" : ""} PROPOSED BY ${formData.amendmentorigin !== "CONFERENCE" ? "THE" : ""} ${formData.amendmentorigin}</p>`
    const committeeSection = formData.committee ? `<p class='i0'>${formData.committee.toUpperCase()}</p>` : "";
    const patronSection = formData.member ? `<p class='i0'>${formData.member.toUpperCase()}</p>` : "";
    let lineSections = '';
    for (let i = 1; i <= amendmentsToBeInserted; i++) {
        const lineSection = (i > 1 ? `<p></p>` : '') + (patronSection || committeeSection) + `
            <p class='textbi'>${i}. Line #, ${formData.versiontype}, after </p>
            <p class='i10'>strike</p>
            <p class='i15'></p>
            <p class='i10'>insert</p>
            <p class='i15'></p>
        `;
        lineSections += lineSection
    }

    return billNumberSection + titleSection + lineSections;
}