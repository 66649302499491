export const generateCsv = (columns, rows) => {
    let doc = "";
    let newLine = "\r\n";

    doc = columns.join(",");
    doc = doc + newLine;

    for (let i = 0; i < rows.length; i++) {
        columns.forEach(column => {
            let col = column.replace(/ /g, "");
            if (rows[i][col] != undefined) {
                let rowText = rows[i][col].toString().replace(/(\r\n|\n|\r)/gm, "");
                if (!rowText.startsWith("=HYPERLINK("))
                    rowText = rowText.replaceAll('"', "'");
                rowText = `\"${rowText}\"`;
                doc = doc + rowText + ",";
            } else {
                doc = doc + " " + ",";
            }
        });

        doc = doc + newLine
    }
    return doc
}