import React from 'react';
import ReactTable from 'react-table';

class ConfigurationsTable extends React.Component {

    getTdProps = (state, rowInfo, instance) => {
        if (rowInfo) {
            //if we're showing differences for this row, get the associated admin assignment so we can compare accordingly
            let associatedAdminAssignment;
            const { uniqueIdentifier } = this.props;
            if (instance.id && this.props.adminAssignments && this.props.showDifferences && this.props.showDifferences[rowInfo.original[uniqueIdentifier]]) {
                associatedAdminAssignment = this.props.adminAssignments.find(aca => aca[uniqueIdentifier] === rowInfo.original[uniqueIdentifier]);
            }

            //If this is the CategoryCode column, we need to set the value for filtering to include the chamber in case they want to say "BRREF (H)" for example
            //otherwise, if they entered "BRREF (H)" as a filter, it will not return any rows since they are all techincally just "BRREF" whether H or S
            let originalValue = rowInfo.original[instance.id];
            let associatedAdminValue = associatedAdminAssignment && associatedAdminAssignment[instance.id] !== undefined ? associatedAdminAssignment[instance.id] : undefined;
            if (instance.id === "CategoryCode" && this.props.categories && this.props.uniqueCategoryIdentifier) {
                const associatedRowCategoryCodeHasMultipleChambers = this.props.categories.filter(c => c.CategoryCode === rowInfo.original.CategoryCode);
                if (associatedRowCategoryCodeHasMultipleChambers.length > 1) {
                    originalValue += " (" + associatedRowCategoryCodeHasMultipleChambers.find(c => c[this.props.uniqueCategoryIdentifier] === rowInfo.original[this.props.uniqueCategoryIdentifier]).ChamberCode + ")"
                }

                //if we have an associated admin assignment (which also means we are displaying both and comparing), then and only then also do the same logic as above for the associated admin value
                if (associatedAdminAssignment) {
                    const associatedRowAdminCategoryCodeHasMultipleChambers = this.props.categories.filter(c => c.CategoryCode === associatedAdminAssignment.CategoryCode);
                    if (associatedRowAdminCategoryCodeHasMultipleChambers.length > 1) {
                        associatedAdminValue += " (" + associatedRowAdminCategoryCodeHasMultipleChambers.find(c => c[this.props.uniqueCategoryIdentifier] === associatedAdminAssignment[this.props.uniqueCategoryIdentifier]).ChamberCode + ")"
                    }
                }
            }
            return {
                style: {
                    //if it's new, always keep it to the same color; otherwise, if we're comparing against the associated admin assignment, show red or green based on equality comparison result
                    'backgroundColor': rowInfo.original.new ? 'rgba(0,0,0,.025)' : this.props.showDifferences[rowInfo.original[uniqueIdentifier]] && instance.id ? associatedAdminValue !== originalValue ? '#ff9797' : 'rgba(91, 178, 114, 0.5)' : '',
                    //set the bottom border a little larger for the 'new' top row to make it appear differently than the rest of the rows
                    'borderBottomWidth': rowInfo.original.new ? '2px' : '1px',
                }
            }
        }
        return {};
    }

    render() {
        const { isLoaded, header, assignments, newAssignment, columns, sort, categories, uniqueCategoryIdentifier } = this.props;
        return (
            <div>
                <h3 className="no-margin-top margin-bottom-twentyfive">{header}</h3>
                <div>
                    <ReactTable
                        loading={!isLoaded}
                        resizable={false}
                        filterable={true}
                        data={newAssignment ? [newAssignment, ...assignments] : assignments}
                        columns={columns}
                        getTdProps={this.getTdProps}
                        getTheadProps={() => ({
                            style: { position: 'sticky', top: '0px', zIndex: 5 }
                        })}
                        getTheadFilterThProps={(finalState, rowInfo, column, instance) => {
                            if (column.Header !== finalState.columns[finalState.columns.length - 1].Header)
                                return ({ style: { borderRight: '1px solid #fff' } })
                            else
                                return {}
                        }}
                        getTheadThProps={(finalState, rowInfo, column, instance) => sort ? ({
                            onClick: () => sort(column)
                        }) : () => null}
                        defaultFilterMethod={(filter, row, column) => {
                            const id = filter.pivotId || filter.id
                            //always show the insertable/new assignment row
                            const isNewRow = newAssignment && row._original && row._original.new;
                            //allow filtering by chamber of category code if present
                            let rowValue = row[id];
                            if (id === "CategoryCode" && row._original && categories && uniqueCategoryIdentifier) {
                                const associatedRowCategoryCodeHasMultipleChambers = categories.filter(c => c.CategoryCode === row._original.CategoryCode);
                                if (associatedRowCategoryCodeHasMultipleChambers.length > 1) {
                                    rowValue += " (" + associatedRowCategoryCodeHasMultipleChambers.find(c => c[uniqueCategoryIdentifier] === row._original[uniqueCategoryIdentifier]).ChamberCode + ")"
                                }
                            }
                            return rowValue !== undefined ? isNewRow || String(rowValue).toLowerCase().includes(filter.value.toLowerCase()) : false
                        }}
                        defaultPageSize={20}
                        className="-striped -highlight overflow-visible"
                        style={{ overflow: 'visible' }}
                    />
                </div>
            </div>
        );
    }
}

export default ConfigurationsTable







