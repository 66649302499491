import React from 'react';
import Login from '../../lis-login/components/lis-login-form';
import UserProfile from './lis-profile-component';


class Submenu extends React.Component {

    render() {
        const {
            isAuth
        } = this.props;

        if (!isAuth) {
            return (
                <ul className="nav-submenu">
                    <Login
                        history={this.props.history}
                        isSubmenu={true}
                    />
                </ul>
            )
        }
        else {
            return (
                <ul className="nav-submenu">
                    <UserProfile permissions={this.props.permissions} />
                </ul>
            )
        }
    }
}

export default Submenu;