import React from 'react';
import { bindActionCreators } from 'redux';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { sessionActionCreators } from '../../stores/lis-session-store';
import { navActionCreators } from '../../stores/lis-nav-store';
import { minutesActionCreators } from '../../stores/lis-minutes-store';
import { cancelRequest } from '../../services/request.service';
import moment from 'moment';


class PublicMinutesList extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            session: '',
            senateIsLoaded: false,
            houseIsLoaded: false,
            senateMinutesList: [],
            houseMinutesList: [],
            showHouseChamber: true,
            showSenateChamber: true
        };

        this.toggleChamber = this.toggleChamber.bind(this);
    }

    toggleChamber(chamber) {
        this.setState(state => ({
            [chamber]: !state[chamber]
        }));
    }

    //When component has mounted, make the call to get session by code
    componentDidMount() {
        const sessionCode = this.props.match.params.sessioncode
        if (parseInt(sessionCode) > 0) {
            //Update the session selector component, which will run componentDidUpdate and call setupPage on its own
            if (this.props.nav.session !== sessionCode) {
                this.props.actions.changeSession(sessionCode)
            } else {
                this.setupPage(sessionCode);
            }
        }
    }

    componentDidUpdate(prevProps) {
        // Setup the page if the global selected session changes. Change the URL to reflect the session change
        if (prevProps.nav.session !== this.props.nav.session) {
            this.props.history.push("/session-details/" + this.props.nav.session + "/minutes-list/" + this.props.match.params.chamber);
            this.setupPage(this.props.nav.session);
        } else if (this.props.nav.session !== this.props.match.params.sessioncode) {
            // If the url changes then update the global selected session with whatever the url is
            const sessionCode = this.props.match.params.sessioncode;
            if (!isNaN(sessionCode)) {
                this.props.actions.changeSession(sessionCode);
            }
        }
    }

    componentWillUnmount() {
        cancelRequest();
    }

    setupPage(sessionCode) {
        this.setState({
            senateIsLoaded: false,
            houseIsLoaded: false
        }, () => {
            //Get the session information
            this.props.actions.getSessionByCode(sessionCode)
                .then(() => {
                    this.setState({
                        session: this.props.session.selectedSession,
                    });
                });
            if (this.props.match.params.chamber === "house") {
                //Get the list for the House
                this.props.actions.getMinutesList('sessionCode=' + sessionCode + '&chamberCode=H')
                    .then(() => {
                        this.setState({
                            houseMinutesList: this.props.minutes.minutesList,
                            houseIsLoaded: true
                        });
                    });
            } else {
                //Get the list for the Senate
                this.props.actions.getMinutesList('sessionCode=' + sessionCode + '&chamberCode=S')
                    .then(() => {
                        this.setState({
                            senateMinutesList: this.props.minutes.minutesList,
                            senateIsLoaded: true
                        });
                    });
            }
        });
    }

    render() {
        const { senateMinutesList, houseMinutesList, senateIsLoaded, houseIsLoaded, session } = this.state;
        const sessionHeader = (session ? session.SessionYear + " " + session.DisplayName : '') + (this.props.match.params.chamber === "house" ? " House" : " Senate") + " Minutes";
        return (
            <div className="public-chamber-data">
                <div>
                    <h2>{sessionHeader}</h2>
                </div>
                <div className="public-list">
                    {this.props.match.params.chamber === "house" ?
                        session && session.SessionYear < 2010 ?
                            <div>
                                {houseIsLoaded ?
                                    <table className="line-table">
                                        <tbody className="toggle-table">
                                            {houseMinutesList.map(minutes =>
                                                <tr key={minutes.MinutesBookID} className="inner-grid two alternating-color-list">
                                                    <td>
                                                        {minutes.MinutesFiles && minutes.MinutesFiles.length > 0 ?
                                                            <a target="_blank" href={minutes.MinutesFiles[0].FileURL}>
                                                                <span className="button paper">Minutes</span>
                                                                {moment(minutes.MinutesDate).format("MM/DD/YYYY")} {minutes.MinutesNumber > 1 && `(${minutes.MinutesNumber})`}
                                                            </a>
                                                            :
                                                            <div>
                                                                <span className="button paper">Minutes</span>
                                                                {moment(minutes.MinutesDate).format("MM/DD/YYYY")} {minutes.MinutesunicatilonNumber > 1 && `(${minutes.MinutesNumber})`}
                                                            </div>
                                                        }
                                                    </td>
                                                </tr>
                                            )}
                                        </tbody>
                                    </table>
                                    :
                                    <div aria-label="Loading" className="center-spinner spinner"></div>
                                }
                            </div>
                            :
                            <a href={"https://virginiageneralassembly.gov/house/minutes/list.php?ses=" + (session && session.SessionCode.toString().substr(2, 3))}>House Minutes</a>
                        :
                        <div>
                            {senateIsLoaded ?
                                <table className="line-table">
                                    <tbody className="toggle-table">
                                        {senateMinutesList.map(minutes =>
                                            <tr key={minutes.MinutesBookID} className="inner-grid two alternating-color-list">
                                                <td>
                                                    <Link to={`../minutes/${minutes.MinutesBookID}`}>
                                                        <span className="button paper">Minutes</span>
                                                        {moment(minutes.MinutesDate).format("MM/DD/YYYY")} {minutes.MinutesNumber > 1 && `(${minutes.MinutesNumber})`}
                                                    </Link>
                                                </td>
                                            </tr>
                                        )}
                                    </tbody>
                                </table>
                                :
                                <div aria-label="Loading" className="center-spinner spinner"></div>
                            }
                        </div>
                    }
                </div>
            </div>
        );
    }
}

export default connect(
    (state) => {
        const { session, nav, minutes } = state;
        return {
            session,
            nav,
            minutes
        }
    },
    (dispatch) => {
        return {
            actions: bindActionCreators(Object.assign({}, sessionActionCreators, navActionCreators, minutesActionCreators), dispatch)
        }
    }
)(PublicMinutesList)
