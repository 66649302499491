import React from 'react';
import { Link } from 'react-router-dom';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { sessionActionCreators } from '../../stores/lis-session-store';
import { voteActionCreators } from '../../stores/lis-votes-store';

import Select from 'react-select';
import { memberActionCreators } from '../../stores/lis-members-store';
import moment from 'moment';
import { cancelRequest } from '../../services/request.service';

class PublicVoteSearch extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            memberOptions: [],
            sessionOptions: [],
            voteTypeOptions: [
                { label: 'All', value: 'All' },
            ],
            selectedMembers: '',
            selectedSession: '',
            selectedVoteType: { label: 'All', value: 'All' },
            searchSelectedMember: '',
            searchSelectedSession: '',
            searchSelectedVoteType: '',
        };

        this.getMembers = this.getMembers.bind(this);
        this.handleDropdownChange = this.handleDropdownChange.bind(this);
        this.handleSearch = this.handleSearch.bind(this);
    }

    componentDidMount() {
        // Get the session list for the session dropdown
        this.props.actions.getSessionList()
            .then(() => {
                if (this.props.session.sessionListError) {
                    throw this.props.session.sessionListError.toString();
                }
                let selectedSession = '';
                let sessionList = [...this.props.session.sessionList];
                sessionList.forEach(session => {
                    session.value = session.SessionID;
                    session.label = session.SessionYear + ' ' + session.DisplayName;
                    if (session.SessionCode === this.props.match.params.sessioncode) {
                        selectedSession = session;
                    }
                });
                this.setState({
                    selectedSession: selectedSession,
                    sessionOptions: sessionList.reverse(),
                }, () => {
                    if (selectedSession) {
                        // Get the list of members for the member dropdown
                        // Use the selected session's start date
                        this.getMembers(selectedSession.SessionID);
                    } else {
                        this.setState({
                            isLoaded: true
                        })
                    }
                });


            });

        // Get the list of vote type options for the vote type dropdown
        this.props.actions.getVoteTypes()
            .then(() => {
                let voteTypeOptions = this.state.voteTypeOptions;
                this.props.votes.voteTypes.forEach(type => {
                    type.label = type.Name;
                    type.value = type.VoteTypeID;
                    voteTypeOptions.push(type);
                });
                this.setState({
                    voteTypeOptions: voteTypeOptions
                });
            });

    }

    componentWillUnmount() {
        cancelRequest();
    }

    getMembers(sessionId) {
        this.props.actions.getMemberList('sessionID=' + sessionId)
            .then(() => {
                if (this.props.members.memberListError) {
                    throw this.props.members.memberListError.toString();
                }
                let memberList = [...this.props.members.memberList];
                let selectedMember = '';
                memberList.forEach(member => {
                    member.label = `(${member.ChamberCode}) ${member.ListDisplayName}`;
                    member.value = member.MemberID;
                    if (member.MemberNumber === this.props.match.params.membernumber) {
                        selectedMember = member;
                    }
                });
                this.setState({
                    memberOptions: memberList,
                    selectedMember: selectedMember,
                    isLoaded: true
                }, () => {
                    // Do a search if there is a selected member and session
                    if (selectedMember) {
                        this.handleSearch();
                    }
                });
            }).catch(err => {
                if (err === 'Aborted') {
                    return;
                }
                console.log(err);
                this.setState({
                    message: err,
                    isLoaded: true
                });
            });
    }

    handleDropdownChange(val, key) {
        if (window.env && window.env.HISTORICAL_DATA_REDIRECT && key === 'selectedSession' && val && !val.IsDefault && val.LegacySessionID) {
            this.setState({
                historicalSessionRedirect: (redirect) => {
                    if (redirect) {
                        let a = document.createElement('a');
                        a.target = '_blank';
                        a.href = `https://legacylis.virginia.gov`;
                        document.body.appendChild(a);
                        a.click();
                        document.body.removeChild(a);
                    }
                    this.setState({ historicalSessionRedirect: false })
                }
            })
            return;
        }
        this.setState({
            [key]: val
        });
        if (key === 'selectedSession') {
            this.props.history.push(`/vote-search/${val.SessionCode}`);
            this.getMembers(val.SessionID);
            this.setState({
                selectedMember: ''
            })
        }
        if (key === 'selectedMember') {
            this.props.history.push(`/vote-search/${this.state.selectedSession.SessionCode}/${val.MemberNumber}`);
        }
    }

    handleSearch(e) {
        if (this.state.selectedMember && this.state.selectedSession) {
            let params = `?memberId=${this.state.selectedMember.MemberID}&sessionId=${this.state.selectedSession.SessionID}`
            if (this.state.selectedVoteType && this.state.selectedVoteType.value !== 'All') {
                params += `&voteTypeId=${this.state.selectedVoteType.VoteTypeID}`;
            }
            this.setState({
                isSearching: true
            });
            this.props.actions.getMemberVotes(params)
                .then(() => {
                    if (this.props.members.memberVotesError) {
                        throw this.props.members.memberVotesError.toString();
                    }
                    this.setState({
                        isSearching: false,
                        searchSelectedMember: this.state.selectedMember,
                        searchSelectedSession: this.state.selectedSession,
                        searchSelectedVoteType: this.state.selectedVoteType,
                        memberVotes: this.props.members.memberVotes
                    });
                }).catch(err => {
                    if (err === 'Aborted') {
                        return;
                    }
                    console.log(err);
                    this.setState({
                        message: err,
                        isSearching: false
                    })
                });
        }
        if (e) {
            e.preventDefault();
        }
    }

    render() {
        const customStyles = {
            container: base => ({
                ...base,
                lineHeight: 'normal',
            }),
            option: (base, state) => ({
                ...base,
                fontSize: '0.8em',
            }),
            control: (base) => ({
                ...base,
                padding: '1px',
                margin: 0,
                minHeight: 0,
                fontSize: '0.8em',
            }),
            singleValue: (base, state) => {
                return { ...base, };
            },
            dropdownIndicator: base => ({
                ...base,
                padding: '0px 8px'
            })
        }
        const { isSearching, memberOptions, sessionOptions, voteTypeOptions, selectedMember, selectedSession, selectedVoteType } = this.state;
        if (this.state.message) {
            return (<div className="center">{this.state.message}</div>)
        }
        if (!this.state.isLoaded) {
            return (<div className="center-spinner spinner">Loading...</div>)
        }
        return (
            <div>
                {this.state.historicalSessionRedirect &&
                    <div className="schedule-modal">
                        <div className="schedule-modal-content fit-content center-content">
                            <p>For historical data, please use <b>Legacy LIS</b>, available at <b><a href="https://legacylis.virginia.gov" target="_blank" rel="noreferrer">https://legacylis.virginia.gov</a></b>.</p>
                            <p>Click 'Go' to be taken to Legacy LIS, or 'Close' to remain here.</p>
                            <div className="inline-list">
                                <button className="button primary float-right" onClick={() => this.state.historicalSessionRedirect(true)}>Go</button>
                                <button className="button secondary float-right" onClick={() => this.state.historicalSessionRedirect(false)}>Close</button>
                            </div>
                            <br />
                        </div>
                    </div>
                }
                <div className="dlas-forms side-form">
                    <div className="header">
                        <h3 className="center">Find a Member's Votes</h3>
                    </div>
                    <form>
                        <div className="inner-grid four" style={{ backgroundColor: '#f3f5f5', padding: '1em' }}>
                            <div>
                                <label htmlFor="session-dropdown">Session</label>
                                <Select
                                    id="session-dropdown"
                                    styles={customStyles}
                                    options={sessionOptions}
                                    value={selectedSession}
                                    onChange={val => this.handleDropdownChange(val, 'selectedSession')}
                                />
                            </div>
                            <div>
                                <label htmlFor="member-dropdown">Member</label>
                                <Select
                                    id="member-dropdown"
                                    styles={customStyles}
                                    options={memberOptions}
                                    value={selectedMember}
                                    onChange={val => this.handleDropdownChange(val, 'selectedMember')}
                                />
                            </div>
                            <div>
                                <label htmlFor="vote-type-dropdown">Vote Type</label>
                                <Select
                                    id="vote-type-dropdown"
                                    styles={customStyles}
                                    options={voteTypeOptions}
                                    value={selectedVoteType}
                                    onChange={val => this.handleDropdownChange(val, 'selectedVoteType')}
                                />
                            </div>
                            <div>
                                <br />
                                <div>
                                    <button
                                        disabled={!selectedMember || !selectedSession || !selectedVoteType || isSearching}
                                        onClick={this.handleSearch}
                                        type="submit"
                                        className="button">Search</button>
                                </div>
                            </div>
                        </div>
                    </form>
                </div>
                <br />
                {isSearching ?
                    <div className="center-spinner spinner">Searching...</div>
                    :
                    <React.Fragment>
                        {this.state.memberVotes &&
                            <VoteSearchResults
                                memberVotes={this.state.memberVotes}
                                selectedMember={this.state.searchSelectedMember}
                                selectedSession={this.state.searchSelectedSession}
                                selectedVoteType={this.state.searchSelectedVoteType} />
                        }
                    </React.Fragment>
                }
            </div>);
    }
}

class VoteSearchResults extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            showVotes: [],
            hideAll: true
        }
        this.showVotes = this.showVotes.bind(this);
        this.toggleShow = this.toggleShow.bind(this);
    }

    showVotes(dateIndex) {
        let showVotes = [...this.state.showVotes];
        showVotes[dateIndex] = !showVotes[dateIndex];
        this.setState({
            showVotes: showVotes
        });
    }

    toggleShow() {
        // Votes are hidden by default. Toggle the value of hideAll and set all the values of showVotes to the current value of hideAll
        // So if hideAll is true, then set all of showVotes to true and switch hideAll to false
        const opposite = !this.state.hideAll
        let showVotes = [...this.state.showVotes];
        this.props.memberVotes.VoteResult.forEach((v, index) => {
            showVotes[index] = this.state.hideAll;
        });
        this.setState({
            hideAll: opposite,
            showVotes: showVotes
        })
    }

    organizeVotes(voteList) {
        // The votes need to be organized by date
        let organizedVotes = [];
        if (voteList) {
            voteList.sort((a, b) => {
                return new Date(b.VoteDate) - new Date(a.VoteDate);
            });
            let voteArray = [];
            let previousVote = voteList[0];
            voteList.forEach(vote => {
                if (moment(previousVote.VoteDate).format('L') !== moment(vote.VoteDate).format('L')) {
                    let newDateContainer = {
                        Votes: voteArray,
                        Date: previousVote.VoteDate,
                    };
                    organizedVotes.push(newDateContainer);
                    voteArray = [];
                    voteArray.push(vote);
                } else {
                    voteArray.push(vote);
                }
                previousVote = vote;
            });
            if (voteArray.length > 0) {
                let newDateContainer = {
                    Votes: voteArray,
                    Date: previousVote.VoteDate,
                };
                organizedVotes.push(newDateContainer);
            }
        }
        return organizedVotes;
    }

    shouldComponentUpdate(nextProps, nextState) {
        if (nextProps.memberVotes !== this.props.memberVotes) {
            return true;
        }
        if (nextState !== this.state) {
            return true;
        }
        return false;
    }

    render() {
        const { memberVotes, selectedMember, selectedSession, selectedVoteType } = this.props;
        let memberName = '';
        if (selectedMember.ChamberCode === 'S') {
            memberName = "Senator ";
        }
        if (selectedMember.ChamberCode === 'H') {
            memberName = "Delegate ";
        }
        memberName += memberVotes.MemberDisplayName;
        const organizedVotes = this.organizeVotes(memberVotes.VoteResult)

        return (
            <div id="vote-search">
                <div className="header-half">
                    <h2>{memberName}</h2>
                    <p>{`${selectedSession.label}: ${selectedVoteType.label} Votes`}</p>
                </div>
                <div className="collapsible-table">
                    <div className="vote-search">
                        {organizedVotes.length === 0 ?
                            <span className="center">No Votes Found</span>
                            :
                            <React.Fragment>
                                <div className="flex-row">
                                    <div></div>
                                    {this.state.hideAll ?
                                        <button onClick={this.toggleShow} type="button" className="button-link">Show All +/-</button>
                                        :
                                        <button onClick={this.toggleShow} type="button" className="button-link">Hide All +/-</button>
                                    }
                                </div>
                                <hr />
                                <div className="vote-search-date-item inner-grid two-col-sidebar-backwards">
                                    <div className="date-column-header">
                                        <span className="txt-greyed">Date</span>
                                    </div>
                                    <div className="section-row inner-grid vote-search-grid-header">
                                        <span className="txt-greyed">Bill #</span>
                                        <span className="txt-greyed">Bill Title</span>
                                        <span className="txt-greyed">Vote</span>
                                        <span className="txt-greyed">Type</span>
                                        <span className="txt-greyed">Action</span>
                                        <span className="txt-greyed center">Vote Statement</span>
                                    </div>
                                </div>
                            </React.Fragment>
                        }
                        {organizedVotes.map((date, dateIndex) =>
                            <React.Fragment key={dateIndex}>
                                <hr />
                                {this.state.showVotes[dateIndex] ?
                                    <div className="vote-search-date-item flex-row" style={{ backgroundColor: '#f3f5f5', cursor: 'pointer' }} onClick={() => this.showVotes(dateIndex)}>
                                        <span style={{ fontWeight: 800 }} className="txt-greyed">{moment(date.Date).format("MM/DD/YYYY")}</span>
                                        <button aria-expanded={true} className="arrow-up">Hide bills</button>
                                    </div>
                                    :
                                    <div className="vote-search-date-item flex-row" style={{ cursor: 'pointer' }} onClick={() => this.showVotes(dateIndex)}>
                                        <span style={{ fontWeight: 800 }} className="txt-greyed">{moment(date.Date).format("MM/DD/YYYY")}</span>
                                        <button aria-expanded={false} className="arrow-down">Show bills</button>
                                    </div>
                                }
                                <div className="inner-grid two-col-sidebar-backwards">
                                    <div className="date-column-header"></div>
                                    {this.state.showVotes[dateIndex] ?
                                        <div>
                                            {date.Votes.map((vote, voteIndex) =>
                                                <React.Fragment key={voteIndex}>
                                                    <div className="inner-grid section-row vote-search-grid">
                                                        <span>{vote.LegislationNumber ? <Link to={`/bill-details/${selectedSession.SessionCode}/${vote.LegislationNumber}`}>{vote.LegislationNumber}</Link> : ""}</span>
                                                        <span title={vote.LegislationNumber ? vote.LegislationDescription : ""}>{vote.LegislationNumber ? vote.LegislationDescription : vote.ClassificationName}</span>
                                                        <span>{vote.ResponseCode}</span>
                                                        <span>{vote.VoteType}</span>
                                                        <Link to={`/vote-details/${vote.LegislationNumber ? vote.LegislationNumber : 0}/${selectedSession.SessionCode}/${vote.VoteNumber}`} title={vote.VoteDescription}>{vote.VoteDescription}</Link>
                                                        <span>{vote.VoteStatement && <Link to={`/vote-details/${vote.LegislationNumber ? vote.LegislationNumber : 0}/${selectedSession.SessionCode}/${vote.VoteNumber}`}>Vote Statement</Link>}</span>
                                                    </div>
                                                    {voteIndex !== date.Votes.length - 1 && <hr />}
                                                </React.Fragment>
                                            )}
                                        </div>
                                        :
                                        <div></div>
                                    }
                                </div>
                            </React.Fragment>
                        )}
                    </div>
                </div>
            </div>
        );
    }
}

export default connect(
    (state) => {
        const { members, session, votes } = state;
        return {
            members,
            session,
            votes
        }
    },
    (dispatch) => {
        return {
            actions: bindActionCreators(Object.assign({}, voteActionCreators, memberActionCreators, sessionActionCreators), dispatch)
        }
    }
)(PublicVoteSearch)
