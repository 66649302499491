import React from 'react';
import { Route } from 'react-router';
import DocketGrid from './lis-docket-grid';
import DocketForm from './lis-docket-form';

class DocketManagement extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            selectedSession: '',
            selectedCommittee: '',
            selectedSubcommittee: ''
        };
        this.handleDropdownChange = this.handleDropdownChange.bind(this);
    }

    handleDropdownChange(newState, callback) {
        this.setState({
            ...newState
        }, () => callback());
    }

    render() {
        return (
            <div>
                <Route exact path={["/docket-management", "/docket-management/:committeeid"]} render={props => <DocketGrid
                    {...props}
                    selectedSession={this.state.selectedSession}
                    selectedCommittee={this.state.selectedCommittee}
                    selectedSubcommittee={this.state.selectedSubcommittee}
                    handleDropdownChange={this.handleDropdownChange}
                />} />
                <Route path={`${this.props.match.path}/:committeeid/edit-docket/:docketid`} component={DocketForm} />
            </div>
        )
    }
}

export default DocketManagement;