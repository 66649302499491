import React from 'react';
import { Link } from 'react-router-dom';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { userActionCreators } from '../../../stores/lis-user-store';
import { cancelRequest } from '../../../services/request.service';

const emailRegex = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

class RegisterAccount extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            firstname: '',
            lastname: '',
            email: '',
            password: '',
            passwordconfirm: '',
            showPassword: false
        };

        this.handleTextChange = this.handleTextChange.bind(this);
        this.checkEmail = this.checkEmail.bind(this);
        this.registerAccount = this.registerAccount.bind(this);
    }

    handleTextChange(e, key) {
        const val = e.target.value;
        this.setState({
            [key]: val
        });
    }

    checkEmail() {
        const email = this.state.email.toLowerCase();

        if (!emailRegex.test(email)) {
            this.setState({
                emailError: 'A valid email is required'
            });
            return true;
        }
        this.setState({
            emailError: ''
        });
    }

    registerAccount(e) {
        e.preventDefault();
        let isError = this.checkEmail()
        if (isError) {
            return
        }

        this.setState({
            isCreating: true
        });
        const params = `?emailaddress=${encodeURIComponent(this.state.email)}`;
        this.props.actions.registerUser(params)
            .then(() => {
                this.setState({
                    isCreating: false,
                    accountCreated: true,
                    message: ''
                });
            }).catch(err => {
                if (err === 'Aborted') {
                    return;
                }
                this.setState({
                    message: err.toString(),
                    isCreating: false
                });
            })
    }

    componentWillUnmount() {
        cancelRequest();
    }

    render() {
        const { email, emailError, isCreating, accountCreated } = this.state;
        if (accountCreated) {
            return (
                <div className="center">
                    <h2>Please check your email to complete registration</h2>
                    <h3>Don't forget to check your spam folder</h3>
                </div>)
        }
        return (
            <div className="dlas-forms">
                <h1>Register for a Lobbyist-in-a-Box Account</h1>
                <hr className="faded-line" />
                <div className="inner-grid two multi-row" style={{ gridGap: '50px' }}>
                    <form>
                        <h3 htmlFor="sr-email">Email</h3>
                        <div className="inner-grid three-and-one">
                            <div>
                                <input id="sr-email" style={{ margin: "0" }} onBlur={this.checkEmail} value={email} onChange={e => this.handleTextChange(e, "email")} type="text" />
                                <span className="small-text fail">{emailError}</span>
                            </div>
                            <div>
                                {isCreating ?
                                    <div><span className="small-spinner"></span></div>
                                    :
                                    <button onClick={this.registerAccount} className="button" type="submit">Register Account</button>
                                }
                            </div>
                        </div>
                        <div className="small-text fail" style={{ marginTop: "10px" }}>
                            {this.state.message && this.state.message}
                        </div>
                        <div>
                            <Link to='/login' style={{ marginTop: "10px", textDecoration: "none" }}>
                                Already have an account? Log in.
                            </Link>
                        </div>
                    </form>
                    <div className="txt-blue">
                        <h3><b>Track up to 5 bills for free</b></h3>
                        <p>Your new free account will allow you to track up to 5 bills in one Watchlist and set up notifications.</p>
                        <p>Email notification triggers an email when bills are sponsored by a member of the General Assembly, referred to a House or Senate Committee or contain a specific key word(s), phrases, or Code section(s).</p>

                        <h3><b>Lobbyist-in-a-Box Subscription</b></h3>
                        <p>A subscription to Lobbyist-in-a-Box offers up to 100 Watchlists (previously called "Profiles") containing 300 bills each.</p>
                        <p>The cost of this service is $400 per user for state agencies, and $600 per user for all other subscribers.  More user accounts can be added, please refer to the Subscription Information on the <a style={{ textDecoration: "none" }} target="_blank" rel="noreferrer" href="http://help.lis.virginia.gov/liab/index.asp">LIS Learning Center</a> for additional options and fees, the 2-Step Registration and Payment process, and complete LIAB registration instructions.</p>
                        <p><span className="bold">Need additional information or assistance?</span> Please send an e-mail message to <a style={{ textDecoration: "none" }} href="mailto:lis@dlas.virginia.gov">lis@dlas.virginia.gov</a>.</p>
                        <p>For questions relating to LIAB Subscription payment procedures, please contact Virginia Interactive, LLC (VI) dba Tyler Virginia at <a style={{ textDecoration: "none" }} href="mailto:va-customerservice@tylertech.com">va-customerservice@tylertech.com</a> or (804) 318-4133.</p>
                    </div>
                </div>
            </div>)
    }
}

export default connect(
    (state) => {
        const { users } = state;
        return {
            users
        }
    },
    (dispatch) => {
        return {
            actions: bindActionCreators(Object.assign({}, userActionCreators), dispatch)
        }
    }
)(RegisterAccount)