import React from "react";
import { Link } from 'react-router-dom';
import ReactTable from "react-table";
import '../../../stylesheets/shared/react-table.css';
import '../../../stylesheets/lis-member-management/member-management.css';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { userActionCreators } from '../../../stores/lis-user-store';
import { memberActionCreators } from '../../../stores/lis-members-store';
import Select from 'react-select';
import { sessionActionCreators } from "../../../stores/lis-session-store";
import { cancelRequest } from '../../../services/request.service';
import moment from 'moment';

class MemberManagementGrid extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            sessionOptions: [],
            selectedSession: '',
            isLoaded: true,
            searchFilter: '',
            memberList: []
        };

        this.handleSessionChange = this.handleSessionChange.bind(this);
        this.handleFilterChange = this.handleFilterChange.bind(this);
        this.filterData = this.filterData.bind(this);
        this.getData = this.getData.bind(this);

    }

    handleSessionChange(val) {
        this.setState({
            selectedSession: val
        }, () => this.getData());
    }

    handleFilterChange(e) {
        const val = e.target.value;
        this.setState({
            searchFilter: val
        }, () => this.filterData());
    }

    filterData() {
        const filter = this.state.searchFilter;
        const formattedFilter = filter.toLowerCase().replace(/ /g, '');
        let memberContacts = [...this.props.members.memberListContactData];
        if (filter !== '') {
            memberContacts = memberContacts.filter(contact => {
                if (contact.MemberDisplayName && contact.MemberDisplayName.toLowerCase().includes(formattedFilter)) {
                    return true;
                }
                if (contact.MemberNumber && contact.MemberNumber.toLowerCase().includes(formattedFilter)) {
                    return true;
                }
                return false;
            });
        }
        this.setState({
            memberList: memberContacts.filter(member => {
                if (this.props.login.userClaims.claims.find(x => x.RoleName === "Admin")) {
                    return true;
                } else if (member.ChamberCode === "H" && this.props.login.userClaims.claims.find(x => x.RoleName === "HouseMemberEditor") ||
                    member.ChamberCode === "S" && this.props.login.userClaims.claims.find(x => x.RoleName === "SenateMemberEditor")) {
                    return true;
                }
                return false;
            })
        });
    }

    getData() {
        this.setState({
            isLoaded: false
        });
        let params = 'isPublic=false&sessionID=' + this.state.selectedSession.SessionID;
        this.props.actions.getMemberListContact(params)
            .then(() => {
                this.setState({
                    isLoaded: true,
                    checkedMembers: []
                });
                this.filterData();
            });

        //Get the member list so we can check for identical patron names on save on the member form
        this.props.actions.getMemberList(params);
    }

    componentDidMount() {
        this.props.actions.getSessionList()
            .then(() => {
                let sessionOptions = [...this.props.session.sessionList];
                let selectedSession = '';
                sessionOptions.forEach(session => {
                    session.label = session.SessionYear + ' ' + session.DisplayName;
                    session.value = session.SessionID;
                    if (session.IsDefault) {
                        selectedSession = session;
                    }
                });
                this.setState({
                    sessionOptions: sessionOptions,
                    selectedSession: selectedSession
                }, () => this.getData());
            });
    }

    componentWillUnmount() {
        cancelRequest();
    }

    render() {
        const { sessionOptions, selectedSession, memberList, checkedMembers, searchFilter } = this.state;

        const customStyles = {
            container: base => ({
                ...base,
                lineHeight: 'normal',
            }),
            option: (base, state) => ({
                ...base,
                fontSize: '0.8em',
            }),
            control: (base) => ({
                ...base,
                padding: '1px',
                margin: 0,
                minHeight: 0,
                fontSize: '0.8em',
            }),
            dropdownIndicator: base => ({
                ...base,
                padding: '0px 8px'
            })
        }
        return (
            <div>
                <h1>Member Management</h1>
                <div className="toolbar user-toolbar">
                    <div className="dlas-forms">
                        <div>
                            <label>Session</label>
                            <Select
                                options={sessionOptions}
                                value={selectedSession}
                                id="session-dropdown"
                                onChange={this.handleSessionChange}
                                styles={customStyles}
                            />
                        </div>
                        <div>
                            <label>Search Member</label>
                            <input
                                id='member-filter'
                                onChange={this.handleFilterChange}
                                value={searchFilter}
                            />
                        </div>
                        <div>
                            <Link className="button add-wide" to={`/member-management/${this.state.selectedSession.SessionCode}/create-member`}>Add Member</Link>
                        </div>
                    </div>
                </div>
                <ReactTable
                    loading={!this.state.isLoaded}
                    data={memberList}
                    columns={[
                        {
                            Header: "Member ID",
                            accessor: "MemberNumber",
                            width: 150
                        },
                        {
                            Header: "Name",
                            accessor: "MemberDisplayName",
                            Cell: row => {
                                return row.original.MemberDisplayName + (row.original.ServiceEndDate && row.original.ServiceEndReason ? " (" + row.original.ServiceEndReason + ")" : "")
                            }
                        },
                        {
                            Header: "Member Status",
                            accessor: "MemberStatus",
                            width: 200
                        },
                        {
                            Header: "Action",
                            headerClassName: "header-action",
                            accessor: "action",
                            className: "action-column",
                            width: 50,
                            expander: true,
                            Expander: ({ isExpanded, original }) => {
                                if (original.ContactInformation && original.ContactInformation.length > 0) {
                                    return (
                                        <button className="button view">View</button>
                                    );
                                } else {
                                    return (false);
                                }
                            }
                        },
                        {
                            Header: "",
                            accessor: "edit",
                            headerClassName: "all-column",
                            width: 40,
                            Cell: row => {
                                return (
                                    <Link to={`/member-management/${this.state.selectedSession.SessionCode}/edit-member/${row.original.MemberID}`} className="button edit">Edit</Link>
                                )
                            }
                        }
                    ]}
                    defaultPageSize={10}
                    className="-striped -highlight"
                    SubComponent={row => {
                        return (
                            <div>
                                <MemberInfo member={row.original} />
                            </div>
                        )
                    }}
                />
                <br />
            </div>
        );
    }
}

const MemberInfo = props => {

    return (
        <div className="flex-row space-around">
            {props.member.ContactInformation.map((contact, contactIndex) =>
                <div key={contactIndex} className="member-contact-container">
                    <span className="member-contact-type">{contact.ContactType}</span>
                    <div className="inner-grid two">
                        <div>
                            <div>
                                <span className="member-contact-title">Address:</span>
                            </div>
                            <div>
                                <span>{contact.Address1}</span>
                            </div>
                            <div>
                                <span>{contact.Address2}</span>
                            </div>
                            <div>
                                <span>{contact.City} {contact.StateCode}, {contact.ZipCode}</span>
                            </div>
                        </div>
                        {(contact.PhoneNumber || contact.EmailAddress) &&
                            <div>
                                <div>
                                    <span className="member-contact-title">Contact:</span>
                                </div>
                                <div>
                                    <span>{contact.PhoneNumber}</span>
                                </div>
                                <div>
                                    <span>{contact.EmailAddress}</span>
                                </div>
                            </div>
                        }
                    </div>
                </div>
            )}
        </div>
    );
}

export default connect(
    (state) => {
        const { users, members, session, login } = state;
        return {
            users,
            members,
            session,
            login
        }
    },
    (dispatch) => {
        return {
            actions: bindActionCreators(Object.assign({}, userActionCreators, memberActionCreators, sessionActionCreators), dispatch)
        }
    }
)(MemberManagementGrid)