import { partnerRequest, publicRequest } from '../services/request.service'

const API_URL = window.env ? window.env.API_SERVER : '';
const NO_CONTENT = 2;
const REQUEST_FAILURE = 1;
const REQUEST_SUCCESS = 0;

const getDefaultSessionAsyncRequest = 'GET_DEFAULT_SESSION_ASYNC_REQUEST';
const getDefaultSessionAsyncSuccess = 'GET_DEFAULT_SESSION_ASYNC_SUCCESS';
const getDefaultSessionAsyncFailure = 'GET_DEFAULT_SESSION_ASYNC_FAILURE';

const getSessionListRequest = 'GET_SESSION_LIST_REQUEST';
const getSessionListSuccess = 'GET_SESSION_LIST_SUCCESS';
const getSessionListFailure = 'GET_SESSION_LIST_FAILURE';

const getSessionShallowListSuccess = 'GET_SESSION_SHALLOW_LIST_SUCCESS';
const getSessionShallowListFailure = 'GET_SESSION_SHALLOW_LIST_FAILURE';

const getDefaultSessionSuccess = 'GET_DEFAULT_SESSION_SUCCESS';

const getSessionByIdRequest = 'GET_SESSION_BY_ID_REQUEST';
const getSessionByIdSuccess = 'GET_SESSION_BY_ID_SUCCESS';
const getSessionByIdFailure = 'GET_SESSION_BY_ID_FAILURE';

const getSessionEventTypesRequest = 'GET_SESSION_EVENT_TYPES_REQUEST';
const getSessionEventTypesSuccess = 'GET_SESSION_EVENT_TYPES_SUCCESS';
const getSessionEventTypesFailure = 'GET_SESSION_EVENT_TYPES_FAILURE';

const editSessionRequest = 'EDIT_SESSION_REQUEST';
const editSessionSuccess = 'EDIT_SESSION_SUCCESS';
const editSessionFailure = 'EDIT_SESSION_FAILURE';

const createSessionRequest = 'CREATE_SESSION_REQUEST';
const createSessionSuccess = 'CREATE_SESSION_SUCCESS';
const createSessionFailure = 'CREATE_SESSION_FAILURE';

const getSessionByCodeRequest = 'GET_SESSION_BY_CODE_REQUEST';

const initialState = {
    defaultSession: [], // sets default session from the session list
    defaultSessionAsync: [], // sets default session from a call to API to find the default
    sessionList: [],
    sessionListError: '',
    sessionShallowList: [],
    sessionEventTypes: [],
    sessionEvents: [],
    selectedSession: {
        SessionCode: '',
        DisplayName: '',
        SessionID: '',
        SessionYear: '',
        SessionType: { value: '', label: '' },
        IsDefault: false,
        SessionStart: null,
        BillCrossover: null,
        PrefileStart: null,
        BillCutoff: null,
        Adjournment: null,
        Reconvene: null,
        SessionEvents: []
    },
    getSessionError: '',
    saveSession: '',
    saveSessionError: '',
    createSession: '',
    createSessionError: ''
};

export const sessionActionCreators = {

    requestSessionEventTypes: () => ({
        type: getSessionEventTypesRequest,
        sessionEventTypes: [],
        isFetching: true
    }),

    receiveSessionEventTypes: (responseObj) => ({
        type: getSessionEventTypesSuccess,
        isFetching: false,
        sessionEventTypes: responseObj
    }),

    sessionEventTypesError: (message) => ({
        type: getSessionEventTypesFailure,
        isFetching: false,
        sessionEventTypes: [],
        sessionEventTypesError: message
    }),

    getSessionEventTypes: () => {
        return dispatch => {
            dispatch(sessionActionCreators.requestSessionEventTypes());

            return publicRequest((API_URL || process.env.REACT_APP_SESSION_API_URL) + '/Session/api/GetSessionEventTypeReferencesAsync/', 'GET', '', '', '')
                .then(result => {
                    if (result[0] === REQUEST_FAILURE) {
                        dispatch(sessionActionCreators.sessionEventTypesError(result[1]))
                        return Promise.reject(result[1])
                    } else if (result[0] === REQUEST_SUCCESS) {
                        // Dispatch the success action
                        dispatch(sessionActionCreators.receiveSessionEventTypes(result[1].SessionEventTypes))
                    } else if (result[0] === NO_CONTENT) {
                        dispatch(sessionActionCreators.sessionEventTypesError('No Content'))
                        return Promise.reject('No Content')
                    } else if (result.name === 'AbortError') {
                        return Promise.reject("Aborted");
                    }
                }).catch(err => Promise.reject(err))
        }
    },

    requestDefaultSessionAsync: () => ({
        type: getDefaultSessionAsyncRequest,
        defaultSessionAsync: [],
        isFetching: true
    }),

    receiveDefaultSessionAsync: (responseObj) => ({
        type: getDefaultSessionAsyncSuccess,
        isFetching: false,
        defaultSessionAsync: responseObj
    }),

    defaultSessionAsyncError: (message) => ({
        type: getDefaultSessionAsyncFailure,
        isFetching: false,
        defaultSessionAsync: [],
        defaultSessionAsyncError: message
    }),

    getDefaultSessionAsync: () => {
        return dispatch => {
            dispatch(sessionActionCreators.requestDefaultSessionAsync());

            return publicRequest((API_URL || process.env.REACT_APP_SESSION_API_URL) + '/Session/api/getDefaultSessionAsync', 'GET', '', '', '')
                .then(result => {
                    if (result[0] === REQUEST_FAILURE) {
                        dispatch(sessionActionCreators.defaultSessionAsyncError(result[1]))
                        return Promise.reject(result[1])
                    } else if (result[0] === REQUEST_SUCCESS) {
                        // Dispatch the success action
                        let session = result[1];
                        dispatch(sessionActionCreators.receiveDefaultSessionAsync(session))
                    } else if (result[0] === NO_CONTENT) {
                        dispatch(sessionActionCreators.defaultSessionAsyncError('No Content'))
                        return Promise.reject('No Content')
                    }
                }).catch(err => console.log(err))
        }
    },

    receiveDefaultSession: (sessiondata) => ({
        type: getDefaultSessionSuccess,
        defaultSession: sessiondata
    }),

    setDefaultSession: (defaultSessionObject) => {
        return dispatch => {
            dispatch(sessionActionCreators.receiveDefaultSession(defaultSessionObject));
        }
    },

    requestSessionList: () => ({
        type: getSessionListRequest,
        sessionList: [],
        isFetching: true
    }),

    receiveSessionList: (sessiondata) => ({
        type: getSessionListSuccess,
        isFetching: false,
        sessionList: sessiondata
    }),

    sessionListError: (message) => ({
        type: getSessionListFailure,
        isFetching: false,
        sessionList: [],
        sessionListError: message
    }),

    getSessionList: (year = '') => {
        return dispatch => {
            dispatch(sessionActionCreators.requestSessionList());
            let params = ''
            if (year) params += 'year=' + year

            return publicRequest((API_URL || process.env.REACT_APP_SESSION_API_URL) + '/Session/api/GetSessionListAsync/?', 'GET', '', '', params)
                .then(result => {
                    if (result[0] === REQUEST_FAILURE) {
                        dispatch(sessionActionCreators.sessionListError(result[1]))
                        return Promise.reject(result[1])
                    } else if (result[0] === REQUEST_SUCCESS) {
                        dispatch(sessionActionCreators.receiveSessionList(result[1].Sessions))
                    } else if (result[0] === NO_CONTENT) {
                        dispatch(sessionActionCreators.receiveSessionList([]))
                    } else if (result.name === 'AbortError') {
                        return Promise.reject("Aborted");
                    }
                }).catch(err => Promise.reject(err))
        }
    },

    receiveSessionShallowList: (sessiondata) => ({
        type: getSessionShallowListSuccess,
        sessionShallowList: sessiondata
    }),

    sessionShallowListError: (message) => ({
        type: getSessionShallowListFailure,
        sessionShallowList: [],
        sessionShallowListMessage: message
    }),

    getSessionShallowList: (params) => {
        return dispatch => {
            return publicRequest((API_URL || process.env.REACT_APP_SESSION_API_URL) + '/Session/api/GetSessionShallowListAsync/?', 'GET', '', '', params)
                .then(result => {
                    if (result[0] === REQUEST_FAILURE) {
                        dispatch(sessionActionCreators.sessionShallowListError(result[1]))
                        return Promise.reject(result[1])
                    } else if (result[0] === REQUEST_SUCCESS) {
                        // Dispatch the success action
                        let session = result[1];
                        dispatch(sessionActionCreators.receiveSessionShallowList(session.Sessions))
                    } else if (result[0] === NO_CONTENT) {
                        dispatch(sessionActionCreators.sessionShallowListError('No Content'))
                    } else if (result.name === 'AbortError') {
                        return Promise.reject("Aborted");
                    }
                }).catch(err => Promise.reject(err))
        }
    },

    requestSessionById: () => ({
        type: getSessionByIdRequest,
        selectedSession: '',
        isFetching: true
    }),

    receiveSessionById: (sessiondata, sessionEvents) => ({
        type: getSessionByIdSuccess,
        isFetching: false,
        selectedSession: sessiondata,
        sessionEvents: sessionEvents
    }),

    sessionByIdError: (message) => ({
        type: getSessionByIdFailure,
        isFetching: false,
        selectedSession: '',
        message
    }),

    getSessionById: (params, partner) => {
        return dispatch => {
            dispatch(sessionActionCreators.requestSessionById());
            let request;
            if (partner) {
                request = partnerRequest((API_URL || process.env.REACT_APP_SESSION_API_URL) + '/Session/api/GetPartnerSessionByIdAsync/', 'GET', '', '', params)    
            } else {
                request = publicRequest((API_URL || process.env.REACT_APP_SESSION_API_URL) + '/Session/api/GetSessionByIdAsync/', 'GET', '', '', params)
            }
            return request
                .then(result => {
                    if (result[0] === REQUEST_FAILURE) {
                        dispatch(sessionActionCreators.sessionByIdError(result[1]))
                        return Promise.reject(result[1])
                    } else if (result[0] === REQUEST_SUCCESS) {
                        // Dispatch the success action
                        const sessionData = result[1].Sessions[0];
                        dispatch(sessionActionCreators.receiveSessionById(sessionData, sessionData.SessionEvents))
                    } else if (result[0] === NO_CONTENT) {
                        dispatch(sessionActionCreators.sessionByIdError('No Content'))
                        return Promise.reject('No Content')
                    } else if (result.name === 'AbortError') {
                        return Promise.reject("Aborted");
                    }
                }).catch(err => Promise.reject(err))
        }
    },

    getSessionByCode: (params) => {
        return dispatch => {
            return publicRequest((API_URL || process.env.REACT_APP_SESSION_API_URL) + '/Session/api/GetSessionByCodeAsync/', 'GET', '', '', params)
            .then(result => {
                if (result[0] === REQUEST_FAILURE) {
                    dispatch({
                        type: getSessionByCodeRequest,
                        getSessionError: result[1]
                    });
                    return Promise.reject(result[1])
                } else if (result[0] === REQUEST_SUCCESS) {
                    dispatch({
                        type: getSessionByCodeRequest,
                        selectedSession: result[1].Sessions[0]
                    });
                } else if (result[0] === NO_CONTENT) {
                    dispatch({
                        type: getSessionByCodeRequest,
                        getSessionError: "No Content"
                    });
                    return Promise.reject('No Content')
                } else if (result.name === 'AbortError') {
                    return Promise.reject("Aborted");
                }
            }).catch(err => Promise.reject(err))
        }
    },

    setSelectedSession: (sessionObject, sessionEvents) => {
        return dispatch => {
            dispatch(sessionActionCreators.receiveSessionById(sessionObject, sessionEvents));
        }
    },

    receiveEditSession: (res) => ({
        type: editSessionSuccess,
        isFetching: false,
        saveSession: res
    }),
    editSessionError: (res) => ({
        type: editSessionFailure,
        isFetching: false,
        saveSessionError: res
    }),
    editSession: (body) => {
        return dispatch => {
            let headers = {
                'content-type': "application/json; charset=utf-8"
            }
            return partnerRequest((API_URL || process.env.REACT_APP_SESSION_API_URL) + '/Session/api/SaveSessionAsync/', 'PUT', headers, body, '')
                .then(result => {
                    if (result[0] === REQUEST_FAILURE) {
                        dispatch(sessionActionCreators.editSessionError(result[1]))
                        return Promise.reject(result[1])
                    } else if (result[0] === REQUEST_SUCCESS) {
                        // Dispatch the success action
                        dispatch(sessionActionCreators.receiveEditSession(result[1]))
                    } else if (result[0] === NO_CONTENT) {
                        dispatch(sessionActionCreators.editSessionError('No Content'))
                        return Promise.reject('No Content')
                    } else if (result.name === 'AbortError') {
                        return Promise.reject("Aborted");
                    }
                }).catch(err => Promise.reject(err))
        }
    },

    receiveCreateSession: (res) => ({
        type: createSessionSuccess,
        isFetching: false,
        createSession: res
    }),
    createSessionError: (res) => ({
        type: createSessionFailure,
        isFetching: false,
        createSessionError: res
    }),
    createSession: (body) => {
        return dispatch => {
            let headers = {
                'content-type': "application/json; charset=utf-8"
            }
            return partnerRequest((API_URL || process.env.REACT_APP_SESSION_API_URL) + '/Session/api/CreateSessionAsync/', 'POST', headers, body, '')
                .then(result => {
                    if (result[0] === REQUEST_FAILURE) {
                        dispatch(sessionActionCreators.createSessionError(result[1]))
                        return Promise.reject(result[1])
                    } else if (result[0] === REQUEST_SUCCESS) {
                        // Dispatch the success action
                        dispatch(sessionActionCreators.receiveCreateSession(result[1]))
                    } else if (result[0] === NO_CONTENT) {
                        dispatch(sessionActionCreators.createSessionError('No Content'))
                        return Promise.reject('No Content')
                    } else if (result.name === 'AbortError') {
                        return Promise.reject("Aborted");
                    }
                }).catch(err => Promise.reject(err))
        }
    }
};


export const reducer = (state, action) => {

    state = state || initialState;

    switch (action.type) {
        case getDefaultSessionAsyncRequest:
            return Object.assign({}, state, {
                isFetching: true
            })
        case getDefaultSessionAsyncSuccess:
            return Object.assign({}, state, {
                isFetching: false,
                defaultSessionAsync: action.defaultSessionAsync,
                defaultSessionAsyncMessage: ''
            })
        case getDefaultSessionAsyncFailure:
            return Object.assign({}, state, {
                isFetching: false,
                defaultSessionAsyncError: action.defaultSessionAsyncMessage
            })
        case getSessionEventTypesRequest:
            return Object.assign({}, state, {
                isFetching: true
            })
        case getSessionEventTypesSuccess:
            return Object.assign({}, state, {
                isFetching: false,
                sessionEventTypes: action.sessionEventTypes,
                sessionEventTypesMessage: ''
            })
        case getSessionEventTypesFailure:
            return Object.assign({}, state, {
                isFetching: false,
                sessionEventTypesMessage: action.sessionEventTypesMessage
            })
        case getSessionListRequest:
            return Object.assign({}, state, {
                isFetching: true
            })
        case getSessionListSuccess:
            return Object.assign({}, state, {
                isFetching: false,
                sessionList: action.sessionList,
                sessionListError: ''
            })
        case getSessionListFailure:
            return Object.assign({}, state, {
                isFetching: false,
                sessionListError: action.sessionListError
            })
        case getSessionShallowListSuccess:
            return Object.assign({}, state, {
                sessionShallowList: action.sessionShallowList,
                sessionShallowListMessage: ''
            })
        case getSessionShallowListFailure:
            return Object.assign({}, state, {
                sessionShallowListMessage: action.sessionShallowListMessage
            })
        case getDefaultSessionSuccess:
            return Object.assign({}, state, {
                defaultSession: action.defaultSession
            })
        case getSessionByIdRequest:
            return Object.assign({}, state, {
                isFetching: true
            })
        case getSessionByIdSuccess:
            return Object.assign({}, state, {
                isFetching: false,
                selectedSession: action.selectedSession,
                sessionEvents: action.sessionEvents,
                errorMessage: ''
            })
        case getSessionByIdFailure:
            return Object.assign({}, state, {
                isFetching: false,
                errorMessage: action.message
            })
        case editSessionRequest:
            return Object.assign({}, state, {
                isFetching: true
            })
        case editSessionSuccess:
            return Object.assign({}, state, {
                isFetching: false,
                saveSession: action.saveSession,
                saveSessionError: ''
            })
        case editSessionFailure:
            return Object.assign({}, state, {
                isFetching: false,
                saveSessionError: action.saveSessionError
            })
        case createSessionRequest:
            return Object.assign({}, state, {
                isFetching: true
            })
        case createSessionSuccess:
            return Object.assign({}, state, {
                createSession: action.createSession,
                createSessionError: ''
            })
        case createSessionFailure:
            return Object.assign({}, state, {
                createSessionError: action.createSessionError
            })
        case getSessionByCodeRequest:
            return Object.assign({}, state, {
                selectedSession: action.selectedSession || state.selectedSession,
                getSessionError: action.getSessionError || ''
            });
        default:
            return state
    }
};