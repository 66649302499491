import React from 'react';

class JournalDetails extends React.Component {

    render() {
        const { categories } = this.props;
        return (
            <div>
                {categories.filter(c => c.JournalEntries && c.JournalEntries.length).map((category, index) => {
                    return (<div key={index} className="preview-entry">
                        <div className="entry-index">
                            <p>{index + 1}</p>
                        </div>
                        <div className="entry-text">
                            <ul>
                                <li className={`category-heading`}>{category.CategoryDescription}</li>
                                {category.JournalEntries.sort((a, b) => a.Sequence - b.Sequence).map((entry, i) => (
                                    <li key={i}>
                                        {entry.EntryText}
                                    </li>
                                ))}
                            </ul>
                        </div>
                    </div>);
                })}
            </div>
        )
    }
}

export default JournalDetails;
