import React from 'react';
import { Route } from 'react-router';
import MemberManagementGrid from './lis-member-grid';
import MemberManagementForm from './lis-member-form';

const CreateMember = props => <div><h1>Create Member</h1><MemberManagementForm history={props.history} sessionCode={props.match.params.sessionCode} /></div>;
const EditMember = props => <div><h1>Edit Member</h1><MemberManagementForm history={props.history} sessionCode={props.match.params.sessionCode} memberId={props.match.params.memberid} /></div>;

class MemberManagement extends React.Component {

    render() {
        return (
            <div>
                <Route exact path={this.props.match.path} component={MemberManagementGrid} />
                <Route path={`${this.props.match.path}/:sessionCode/create-member`} component={CreateMember} />
                <Route path={`${this.props.match.path}/:sessionCode/edit-member/:memberid`} component={EditMember} />
            </div>
        )
    }
}

export default MemberManagement;