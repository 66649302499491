import React from 'react';
import renderHTML from 'react-render-html';

class PreviewSection extends React.Component {
    render() {
        const { billVersionRef, selectedLegType, editorContent, selectedBill, error } = this.props;
        const version = billVersionRef.find(version => version.LegislationVersionID === selectedLegType.LegislationVersionID);
        // A list of versions that get vertical text running down the side of the document
        const verticalTextVersionTypes = ['Substitute', 'Introduced', 'Engrossed', 'Enrolled'];

        return (
            <div className="inner-grid two-col-sidebar version-print-preview" style={{marginBottom: "45px"}}>
                {error ?
                    <div className="message-error">{error}</div>
                    :
                    <React.Fragment>
                        <div>
                            {editorContent ? renderHTML(editorContent.replaceAll("&nbsp;", "")) : ''}
                        </div>
                        {verticalTextVersionTypes.find(type => type === version.Name) &&
                            <div className="status-text">
                                <div className="vertical-text-lg">{version.Name}</div>
                                <div className="vertical-text-sm"><span>{selectedBill.LegislationNumber}{version ? version.Suffix : ''}</span></div>
                            </div>
                        }
                    </React.Fragment>
                }
            </div>
        );
    }
}

export default PreviewSection;