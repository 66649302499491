import React from 'react';
import { Route } from 'react-router';
import AdminDevsPortal from './lis-admin-developers-portal';
import AdminDocumentComponent from './lis-admin-document';

class AdminDocs extends React.Component {

    render() {       

        return (
            <div>                
                <Route exact path={`${this.props.match.path}`} component={AdminDevsPortal} />
                <Route exact path={`${this.props.match.path}/:docName`} component={AdminDocumentComponent} />
            </div>
        )
    }
}

export default AdminDocs;