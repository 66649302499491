import React from 'react';
import { bindActionCreators } from 'redux';
import DocketDetailsComponent from '../../../lis-shared/lis-dockets/lis-docket-details';
import ReactToPrint from 'react-to-print';
import { sessionActionCreators } from '../../../stores/lis-session-store';
import { calendarActionCreators } from '../../../stores/lis-calendar-store';
import { connect } from 'react-redux';
import { cancelRequest } from '../../../services/request.service';
import LoaderComponent from '../../../lis-shared/lis-layout/components/lis-loader-component'
import renderHTML from 'react-render-html';
import { navActionCreators } from '../../../stores/lis-nav-store';

class PublicDocketDetails extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            data: '',
            selectedSession: '',
            categories: '',
            hideClerk: false,
            hideStaff: false,
            hidePatron: false,
            hideSummary: false,
            hideTitle: false,
            hideLDNumber: false,
            hideCaption: false
        }
    }

    componentDidMount() {
        window.scrollTo(0, 0);
        //Imporant to make sure that things load in the correct order. 
        //First I get the docket data, then I get the session data (for the printoff header), followed by the room references (to display the VoteRoomID's name)
        const docketId = this.props.match.params.docketid;
        let committeeNumber = this.props.match.params.committeenumber;
        const chamberCode = this.props.match.url.includes("dockets") ? "S" : "H"
        const params = '?docketId=' + docketId
        this.props.actions.getDocketById(params, false, chamberCode)
            .then(() => {
                if (this.props.calendar.docketByIdData) {
                    const data = this.props.calendar.docketByIdData;
                    // Just in case that the committee number is not in the url
                    if (!committeeNumber) {
                        this.props.history.push(`/session-details/${data.SessionCode}/committee-information/${data.CommitteeNumber}/dockets/${data.DocketID}`);
                    }
                    this.props.actions.getSessionById(data.SessionID)
                        .then(() => {
                            this.setState({
                                selectedSession: this.props.session.selectedSession,
                                sessionIsLoaded: true
                            }, () => {
                                const htmlFile = this.props.calendar.docketByIdData.DocketFiles.find(file => file.FileURL.toUpperCase().includes('.HTM'));
                                if (htmlFile && !this.state.selectedSession.IsActive && !data.Schedules?.length) {
                                    this.props.actions.getFile(htmlFile.FileURL)
                                        .then(() => {
                                            let html = this.props.nav.file;
                                            if (html) {
                                                html = html.replaceAll('href="#', 'href="' + window.location.pathname + "#")
                                            }
                                            this.setState({
                                                docketFile: html,
                                                dataIsLoaded: true
                                            });
                                        }).catch(err => {
                                            if (err !== 'Aborted') {
                                                this.setState({
                                                    dataIsLoaded: true,
                                                    message: "Failed to get HTML file"
                                                });
                                                console.error(err);
                                            }
                                        });
                                } else {
                                    const categories = data.DocketCategories || data.HouseAgendaCategories;

                                    for (let i = 0; i < data.CalendarDisplay.length; i++) {
                                        switch (data.CalendarDisplay[i].DisplayColumn) {
                                            case ("Staffs Name"):
                                                this.setState({
                                                    hideClerk: !data.CalendarDisplay[i].IsDisplayed
                                                });
                                                break;
                                            case ("Clerk Name"):
                                                this.setState({
                                                    hideStaff: !data.CalendarDisplay[i].IsDisplayed
                                                });
                                                break;
                                            case ("Chief Patron"):
                                                this.setState({
                                                    hidePatron: !data.CalendarDisplay[i].IsDisplayed
                                                });
                                                break;
                                            case ("Summary"):
                                                this.setState({
                                                    hideSummary: !data.CalendarDisplay[i].IsDisplayed
                                                });
                                                break;
                                            case ("Title"):
                                                this.setState({
                                                    hideTitle: !data.CalendarDisplay[i].IsDisplayed
                                                });
                                                break;
                                            case ("LD Number"):
                                                this.setState({
                                                    hideLDNumber: !data.CalendarDisplay[i].IsDisplayed
                                                });
                                                break;
                                            case ("Caption"):
                                                this.setState({
                                                    hideCaption: !data.CalendarDisplay[i].IsDisplayed
                                                });
                                                break;
                                            default:
                                                break;
                                        }
                                    }
                                    this.setState({
                                        data: data,
                                        categories: categories,
                                        dataIsLoaded: true
                                    });
                                }
                            });
                        }).catch(err => {
                            if (err !== 'Aborted') {
                                this.setState({
                                    dataIsLoaded: true,
                                    message: "Failed to get HTML file"
                                });
                                console.error(err);
                            }
                        });
                } else {
                    this.setState({
                        dataIsLoaded: true,
                        message: "No docket data found"
                    });
                }
            }).catch(err => {
                if (err !== 'Aborted') {
                    this.setState({
                        dataIsLoaded: true,
                        message: "No docket data found"
                    });
                    console.error(err);
                }
            });
    }

    removeZeros(doc) {
        let dom = document.createElement('div');
        dom.innerHTML = doc;

        let all = document.getElementsByTagName("*");

        for (let i = 0; i < all.length; i++) {
            let trimmedUrl = ""
            //If it's an href with a bill or resolution number in it then slice off the end, remove the zeros, and append it back to the href
            if (all[i].href && all[i].href != "" && all[i].href.match(/[H|S][B|J|R][0-9]{4}/)) {
                trimmedUrl = all[i].href.slice(all[i].href.length - 7, all[i].href.length - 1)
                trimmedUrl = trimmedUrl.slice(0, 2) + parseInt(trimmedUrl.slice(2))
                all[i].href = all[i].href.slice(0, all[i].href.length - 7) + trimmedUrl + "/";
            }
        }

        return doc;
    }

    componentWillUnmount() {
        cancelRequest();
    }

    render() {
        if (this.state.message) {
            return (<div className="center">{this.state.message}</div>)
        }
        if (this.state.docketFile) {
            return (
                <div className='details-page-data-container'>
                    {renderHTML(this.removeZeros(this.state.docketFile))}
                </div>
            );
        }
        if (this.state.dataIsLoaded && this.state.sessionIsLoaded) {
            return (
                <div className='generic-details-page docket-details-page'>
                    <div className="flex-row">
                        <br />
                        <ReactToPrint
                            trigger={() => <button type="button" className="button print"> Print</button>}
                            content={() => this.componentRef}
                            pageStyle={"break-inside: avoid"}
                        />
                    </div>
                    <div ref={el => (this.componentRef = el)}>
                        <DocketDetailsComponent
                            data={this.state.data}
                            dockets={this.state.categories}
                            session={this.state.selectedSession}
                            hideClerk={this.state.hideClerk}
                            hideStaff={this.state.hideStaff}
                            hidePatron={this.state.hidePatron}
                            hideSummary={this.state.hideSummary}
                            hideTitle={this.state.hideTitle}
                            hideLDNumber={this.state.hideLDNumber}
                            hideCaption={this.state.hideCaption}
                        />
                    </div>
                </div>
            );
        } else {
            return (<LoaderComponent data={false} />)
        }
    }
}

export default connect(
    (state) => {
        const { nav, session, calendar } = state;
        return {
            nav,
            session,
            calendar
        }
    },
    (dispatch) => {
        return {
            actions: bindActionCreators(Object.assign({}, navActionCreators, sessionActionCreators, calendarActionCreators), dispatch)
        }
    }
)(PublicDocketDetails)