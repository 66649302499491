import { partnerRequest, publicRequest } from '../services/request.service'

const API_URL = window.env ? window.env.API_SERVER : '';
const NO_CONTENT = 2;
const REQUEST_FAILURE = 1;
const REQUEST_SUCCESS = 0;

const scheduleListSuccess = 'SCHEDULE_LIST_SUCCESS';
const scheduleListFailure = 'SCHEDULE_LIST_FAILURE';

const scheduleTypeListSuccess = 'SCHEDULE_TYPE_LIST_SUCCESS';
const scheduleTypeListFailure = 'SCHEDULE_TYPE_LIST_FAILURE';

const scheduleGetSuccess = 'SCHEDULE_GET_SUCCESS';
const scheduleGetFailure = 'SCHEDULE_GET_FAILURE';

const scheduleSaveSuccess = 'SCHEDULE_SAVE_SUCCESS';
const scheduleSaveFailure = 'SCHEDULE_SAVE_FAILURE';

const scheduleCreateSuccess = 'SCHEDULE_CREATE_SUCCESS';
const scheduleCreateFailure = 'SCHEDULE_CREATE_FAILURE';

const meetingsCalendarPreviewSuccess = "MEETINGS_CALENDAR_PREVIEW_SUCCESS";
const meetingsCalendarPreviewFailure = "MEETINGS_CALENDAR_PREVIEW_FAILURE";

const getMeetingRoomReferencesSuccess = "GET_MEETING_ROOM_REFERENCES_SUCCESS";
const getMeetingRoomReferencesFailure = "GET_MEETING_ROOM_REFERENCES_FAILURE";

const subcommitteeMembersRequest = "GET_SUBCOMMITTEE_MEMBERS_REQUEST";
const subcommitteeMembersSuccess = "GET_SUBCOMMITTEE_MEMBERS_SUCCESS";
const subcommitteeMembersFailure = "GET_SUBCOMMITTEE_MEMBERS_FAILURE";

const uploadMeetingPdfSuccess = "UPLOAD_MEETING_PDF_SUCCESS";
const uploadMeetingPdfFailure = "UPLOAD_MEETING_PDF_FAILURE";

const initialState = {
    scheduleList: [],
    schedule: {},
    scheduleListError: '',
    scheduleTypeListError: '',
    scheduleGetError: '',
    scheduleSaveError: '',
    scheduleCreateError: '',
    meetingRoomReferences: [],
    meetingRoomReferencesError: '',
    meetingsCalendarPreview: '',
    meetingsCalendarPreviewError: '',
    subcommitteeMembers: '',
    uploadMeetingPdf: '',
    uploadMeetingPdfError: ''
};

export const scheduleActionCreators = {

    receiveScheduleList: (content) => ({
        type: scheduleListSuccess,
        scheduleList: content
    }),

    scheduleListError: (message) => ({
        type: scheduleListFailure,
        scheduleListError: message
    }),

    getScheduleList: (params, parter) => {
        return dispatch => {
            let request = "";
            if (parter) {
                request = partnerRequest((API_URL || process.env.REACT_APP_SCHEDULE_API_URL) + '/Schedule/api/GetPartnerScheduleListAsync?', 'GET', '', '', params)
            } else {
                request = publicRequest((API_URL || process.env.REACT_APP_SCHEDULE_API_URL) + '/Schedule/api/GetScheduleListAsync?', 'GET', '', '', params)
            }
            return request
                .then(result => {
                    if (result[0] === REQUEST_FAILURE) {
                        dispatch(scheduleActionCreators.scheduleListError(result[1]));
                        return Promise.reject(result[1]);
                    } else if (result[0] === REQUEST_SUCCESS) {
                        dispatch(scheduleActionCreators.receiveScheduleList(result[1].Schedules));
                    } else if (result[0] === NO_CONTENT) {
                        dispatch(scheduleActionCreators.receiveScheduleList([]));
                    } else if (result.name === 'AbortError') {
                        return Promise.reject("Aborted");
                    }
                }).catch(err => Promise.reject(err));
        }
    },

    receiveScheduleTypeList: (content) => ({
        type: scheduleTypeListSuccess,
        scheduleTypeList: content
    }),

    scheduleTypeListError: (message) => ({
        type: scheduleTypeListFailure,
        scheduleTypeListError: message
    }),

    getScheduleTypeList: (params) => {
        return dispatch => {
            let headers = {
                'content-type': "application/json; charset=utf-8"
            }
            return publicRequest((API_URL || process.env.REACT_APP_SCHEDULE_API_URL) + '/Schedule/api/GetScheduleTypesReferenceAsync?', 'GET', headers, '', params)
                .then(result => {
                    if (result[0] === REQUEST_FAILURE) {
                        dispatch(scheduleActionCreators.scheduleTypeListError(result[1]));
                        return Promise.reject(result[1]);
                    } else if (result[0] === REQUEST_SUCCESS) {
                        dispatch(scheduleActionCreators.receiveScheduleTypeList(result[1].ScheduleTypes));
                    } else if (result[0] === NO_CONTENT) {
                        dispatch(scheduleActionCreators.receiveScheduleTypeList([]));
                    } else if (result.name === 'AbortError') {
                        return Promise.reject("Aborted");
                    }
                }).catch(err => Promise.reject(err));
        }
    },

    receiveSchedule: (content) => ({
        type: scheduleGetSuccess,
        schedule: content
    }),

    scheduleError: (message) => ({
        type: scheduleGetFailure,
        scheduleGetError: message
    }),

    getSchedule: (params) => {
        return dispatch => {

            return partnerRequest((API_URL || process.env.REACT_APP_SCHEDULE_API_URL) + '/Schedule/api/GetPartnerSchedulebyIdAsync?', 'GET', '', '', params)
                .then(result => {
                    if (result[0] === REQUEST_FAILURE) {
                        dispatch(scheduleActionCreators.scheduleError(result[1]));
                        return Promise.reject(result[1]);
                    } else if (result[0] === REQUEST_SUCCESS) {
                        dispatch(scheduleActionCreators.receiveSchedule(result[1].Schedules[0]));
                    } else if (result[0] === NO_CONTENT) {
                        dispatch(scheduleActionCreators.receiveSchedule([]));
                    } else if (result.name === 'AbortError') {
                        return Promise.reject("Aborted");
                    }
                }).catch(err => Promise.reject(err));
        }
    },

    receiveScheduleSave: (message) => ({
        type: scheduleSaveSuccess,
        scheduleSave: message
    }),

    scheduleSaveError: (message) => ({
        type: scheduleSaveFailure,
        scheduleSaveError: message
    }),

    saveSchedule: (body) => {
        return dispatch => {
            let headers = {
                'content-type': "application/json; charset=utf-8"
            }
            return partnerRequest((API_URL || process.env.REACT_APP_SCHEDULE_API_URL) + '/Schedule/api/SaveScheduleAsync/', 'PUT', headers, body, '')
                .then(result => {
                    if (result[0] === REQUEST_FAILURE) {
                        dispatch(scheduleActionCreators.scheduleSaveError(result[1]))
                        return Promise.reject(result[1])
                    } else if (result[0] === REQUEST_SUCCESS) {
                        // Dispatch the success action
                        dispatch(scheduleActionCreators.receiveScheduleSave(result[1]))
                        return Promise.resolve(result[1])
                    } else if (result[0] === NO_CONTENT) {
                        dispatch(scheduleActionCreators.receiveScheduleSave(""))
                        return Promise.reject('No Content')
                    } else if (result.name === 'AbortError') {
                        return Promise.reject("Aborted");
                    }
                }).catch(err => Promise.reject(err))
        }
    },

    receiveScheduleCreate: (message) => ({
        type: scheduleCreateSuccess,
        scheduleCreate: message
    }),

    scheduleCreateError: (message) => ({
        type: scheduleCreateFailure,
        scheduleCreateError: message
    }),

    createSchedule: (body) => {
        return dispatch => {
            let headers = {
                'content-type': "application/json; charset=utf-8"
            }
            return partnerRequest((API_URL || process.env.REACT_APP_SCHEDULE_API_URL) + '/Schedule/api/CreateScheduleAsync/', 'POST', headers, body, '')
                .then(result => {
                    if (result[0] === REQUEST_FAILURE) {
                        dispatch(scheduleActionCreators.scheduleCreateError(result[1]))
                        return Promise.reject(result[1])
                    } else if (result[0] === REQUEST_SUCCESS) {
                        // Dispatch the success action
                        dispatch(scheduleActionCreators.receiveScheduleCreate(result[1]))
                        return Promise.resolve(result[1])
                    } else if (result[0] === NO_CONTENT) {
                        dispatch(scheduleActionCreators.scheduleCreateError('No Content'))
                        return Promise.reject('No Content')
                    } else if (result.name === 'AbortError') {
                        return Promise.reject("Aborted");
                    }
                }).catch(err => Promise.reject(err))
        }
    },

    receiveMeetingRoomReferences: (responseObj) => ({
        type: getMeetingRoomReferencesSuccess,
        meetingRoomReferences: responseObj
    }),

    getMeetingRoomReferencesError: error => ({
        type: getMeetingRoomReferencesFailure,
        getMeetingRoomReferencesError: error
    }),

    getMeetingRoomReferences: (params) => {
        return dispatch => {
            let headers = {
                'content-type': "application/json; charset=utf-8"
            }
            return publicRequest((API_URL || process.env.REACT_APP_SCHEDULE_API_URL) + '/Schedule/api/GetMeetingRoomsReferenceAsync', 'GET', headers, '', params)
                .then(result => {
                    if (result[0] === REQUEST_FAILURE) {
                        dispatch(scheduleActionCreators.getMeetingRoomReferencesError(result[1]))
                        return Promise.reject(result[1])
                    } else if (result[0] === REQUEST_SUCCESS) {
                        // Dispatch the success action
                        dispatch(scheduleActionCreators.receiveMeetingRoomReferences(result[1].MeetingRooms))
                    } else if (result[0] === NO_CONTENT) {
                        dispatch(scheduleActionCreators.getMeetingRoomReferencesError('No Content'))
                    } else if (result.name === 'AbortError') {
                        return Promise.reject("Aborted");
                    }
                }).catch(err => Promise.reject(err));
        }
    },

    receiveMeetingsCalendarPreview: (responseObj) => ({
        type: meetingsCalendarPreviewSuccess,
        meetingsCalendarPreview: responseObj
    }),

    getMeetingsCalendarPreviewError: error => ({
        type: meetingsCalendarPreviewFailure,
        getMeetingsCalendarPreviewError: error
    }),

    getMeetingsCalendarPreview: (params) => {
        return dispatch => {
            let config = {
                method: 'GET',
                headers: {
                    "Content-Type": "application/json; charset=utf-8",
                    "authorization": "Bearer " + localStorage.getItem('LIS_ID_TOKEN'),
                    "WebAPIKey": process.env.REACT_APP_API_KEY
                },
            }

            let url = (API_URL || process.env.REACT_APP_LEGISLATION_FILE_GEN_API_URL) + '/Schedule/api/PreviewSchedulePDF' + params;

            return fetch(url, config)
                .then(result => {
                    if (result) {
                        dispatch(scheduleActionCreators.receiveMeetingsCalendarPreview(result))
                    } else if (!result) {
                        dispatch(scheduleActionCreators.getMeetingsCalendarPreviewError('No Content'))
                    } else if (result.name === 'AbortError') {
                        return Promise.reject("Aborted");
                    }
                }).catch(err => Promise.reject(err));
        }
    },

    requestSubcommitteeMembers: () => ({
        type: subcommitteeMembersRequest,
        isFetching: true
    }),

    receiveSubcommitteeMembers: (responseObj) => ({
        type: subcommitteeMembersSuccess,
        isFetching: false,
        subcommitteeMembers: responseObj
    }),

    subcommitteeMembersError: message => ({
        type: subcommitteeMembersFailure,
        isFetching: false,
        subcommitteeMembersError: message
    }),

    getSubcommitteeMembers: (params) => {
        return dispatch => {
            dispatch(scheduleActionCreators.requestSubcommitteeMembers());
            let headers = {
                'content-type': "application/json; charset=utf-8"
            }

            return partnerRequest(API_URL + '/Schedule/api/GetPartnerScheduleSubcommitteeMemberListAsync', 'GET', headers, '', params)
                .then(result => {
                    if (result[0] === REQUEST_FAILURE) {
                        dispatch(scheduleActionCreators.subcommitteeMembersError(result))
                        return Promise.reject(result)
                    } else if (result[0] === NO_CONTENT) {
                        dispatch(scheduleActionCreators.receiveSubcommitteeMembers(""))
                    } else if (result.name === 'AbortError') {
                        return Promise.reject("Aborted");
                    } else if (result[1]) {
                        // Dispatch the success action
                        dispatch(scheduleActionCreators.receiveSubcommitteeMembers(result[1].SubcomitteeMembers))
                    }
                }).catch(err => Promise.reject(err));
        }
    },

    receiveUploadMeetingPdf: (responseObj) => ({
        type: uploadMeetingPdfSuccess,
        isFetching: false,
        uploadMeetingPdf: responseObj
    }),

    uploadMeetingPdfError: message => ({
        type: uploadMeetingPdfFailure,
        isFetching: false,
        uploadMeetingPdfError: message
    }),

    uploadMeetingPdf: (params, file) => {
        return dispatch => {
            let headers = {
                'cache-control': 'no-cache'
            }
            let formData = new FormData();
            formData.append('pdfFile', file);
            return partnerRequest(API_URL + '/Schedule/api/UploadScheduleFileAsync', 'POST', headers, formData, params)
                .then(result => {
                    if (result[0] === REQUEST_FAILURE) {
                        dispatch(scheduleActionCreators.uploadMeetingPdfError(result[1]))
                        return Promise.reject(result[1])
                    } else if (result[0] === NO_CONTENT) {
                        dispatch(scheduleActionCreators.receiveUploadMeetingPdf([]))
                    } else if (result.name === 'AbortError') {
                        return Promise.reject("Aborted");
                    } else if (result[1]) {
                        // Dispatch the success action
                        dispatch(scheduleActionCreators.receiveUploadMeetingPdf(result[1]))
                    }
                }).catch(err => Promise.reject(err));
        }
    }
};

export const reducer = (state, action) => {

    state = state || initialState;
    switch (action.type) {
        case scheduleListSuccess:
            return Object.assign({}, state, {
                scheduleList: action.scheduleList,
                scheduleListError: ''
            })
        case scheduleListFailure:
            return Object.assign({}, state, {
                scheduleListError: action.scheduleListError
            })
        case scheduleTypeListSuccess:
            return Object.assign({}, state, {
                scheduleTypeList: action.scheduleTypeList,
                scheduleTypeListError: ''
            })
        case scheduleTypeListFailure:
            return Object.assign({}, state, {
                scheduleTypeListError: action.scheduleTypeListError
            })
        case scheduleGetSuccess:
            return Object.assign({}, state, {
                schedule: action.schedule,
                scheduleGetError: ''
            })
        case scheduleGetFailure:
            return Object.assign({}, state, {
                scheduleGetError: action.scheduleGetError
            })
        case scheduleSaveSuccess:
            return Object.assign({}, state, {
                scheduleSaveSuccess: action.scheduleSave,
                scheduleSaveError: ''
            })
        case scheduleSaveFailure:
            return Object.assign({}, state, {
                scheduleSaveError: action.scheduleSaveError
            })
        case scheduleCreateSuccess:
            return Object.assign({}, state, {
                scheduleCreateSuccess: action.scheduleCreate,
                scheduleCreateError: ''
            })
        case scheduleCreateFailure:
            return Object.assign({}, state, {
                scheduleCreateError: action.scheduleCreateError
            })
        case meetingsCalendarPreviewSuccess:
            return Object.assign({}, state, {
                meetingsCalendarPreview: action.meetingsCalendarPreview,
                meetingsCalendarPreviewError: ''
            });
        case meetingsCalendarPreviewFailure:
            return Object.assign({}, state, {
                meetingsCalendarPreviewError: action.meetingsCalendarPreviewError
            });
        case getMeetingRoomReferencesFailure:
            return Object.assign({}, state, {
                getMeetingRoomReferencesError: action.getMeetingRoomReferencesError
            });
        case getMeetingRoomReferencesSuccess:
            return Object.assign({}, state, {
                meetingRoomReferences: action.meetingRoomReferences,
                getMeetingRoomReferencesError: ''
            });
        case subcommitteeMembersRequest:
            return Object.assign({}, state, {
                isFetching: true
            });
        case subcommitteeMembersSuccess:
            return Object.assign({}, state, {
                isFetching: false,
                subcommitteeMembers: action.subcommitteeMembers,
                errorMessage: ''
            });
        case subcommitteeMembersFailure:
            return Object.assign({}, state, {
                isFetching: false,
                errorMessage: action.message
            });
        case uploadMeetingPdfSuccess:
            return Object.assign({}, state, {
                isFetching: false,
                uploadMeetingPdf: action.uploadMeetingPdf,
                uploadMeetingPdfError: ''
            });
        case uploadMeetingPdfFailure:
            return Object.assign({}, state, {
                isFetching: false,
                uploadMeetingPdfError: action.uploadMeetingPdfError
            });
        default:
            return state
    }
};