import React from "react";

class PrivacyComponent extends React.Component {
    render() {
        return (
            <div>

                <p className="bold">Virginia Division of Legislative Automated Systems</p>

                <p className="bold">Internet Privacy Policy Statement and Disclaimer</p>
                <p>(Last updated on September 3, 2024)</p>

                <p className="bold">Privacy Information</p>
                <p>It is the policy of the Commonwealth of Virginia that personal information about citizens will be collected only to the extent necessary to provide the service or benefit desired; that only appropriate information will be collected; that the citizen shall understand the reason the information is collected and be able to examine their personal record which is maintained by the Virginia Division of Legislative Automated Systems (DLAS).</p>

                <p>This document explains how DLAS gathers information on its web site (http://lis.virginia.gov), and with whom and under what conditions the agency shares this information. The following is intended to explain the agency's current Internet privacy practices but shall not be construed as a contractual promise. DLAS reserves the right to amend its Internet Privacy Policy Statement at any time without notice.</p>

                <p className="bold">Information Collection</p>
                <p>Virginia law requires that state bodies not collect personal information about citizens unless it is required to provide services for the citizen. When personal information is collected, it must be protected from unauthorized access, use or disclosure, and DLAS is required to provide a prescribed procedure by which you may learn the purpose for which information has been recorded and how the agency will use and disseminate it.</p>

                <p className="bold">In General</p>
                <p>DLAS does not capture personal information about any visitor to its site without their permission. When you browse the agency's site without logging on to any of the interactive online services, you do so anonymously. Personal information, such as your name, address, phone number, or e-mail address, is not collected as you browse. Information, such as your domain name, Internet Protocol number, browser type, and date and time of your visit, is collected, but not tied to your personal identity. DLAS uses this information to track the number of customers who visit the site and identify the most popular pages so that the agency can improve site content and accessibility.</p>

                <p className="bold">Online Transactions</p>
                <p>Though personal information may be required either by law or in order for DLAS to provide a requested service, in doing so the agency is subject to the requirements for administering information systems as established in the Government Data Collection and Dissemination Practices Act, Chapter 38 of Title 2.2 of the Code of Virginia. A different agency or entity, whose practices are not under the control of DLAS, may regulate pages that appear after you click on a link. When you log on to one of the agency's current online services, such as “Lobbyist in a Box”, personal information that you submit is captured to enable you to conduct business with DLAS. In addition, the agency collects the following information about your visit: Internet Protocol address, browser type, the name of the domain from which you access the Internet, and the date and time of your visit. This additional information allows the agency to track the number of customers who visit our site and identify the most popular pages so that it can improve site content and accessibility. The personal information you provide is required to enable you to complete various transactions with the agency online and for DLAS to deliver the requested services. Once registered with DLAS, you are eligible to request additional services that may require you to provide additional personal information. DLAS only collects, retains and uses personal information when it believes it is essential, and allowed by law, to conduct business and provide products and services. Should you not desire to provide the requested personal information, DLAS will not be able to provide you with online services.</p>

                <p>Personal information that you provide to the agency is safeguarded according to the laws of the Commonwealth of Virginia. Information that you provide on the online surveys is used to improve the online services that the agency offers and to determine your level of satisfaction with the DLAS site and services. This information is not stored with any other records DLAS may have of your transactions with the agency.</p>

                <p className="bold">Contacting Us</p>
                <p>When visiting the site, DLAS encourages you to contact us if you have any questions, comments, or concerns, or would like a return call. DLAS will request your name, the subject of your question or comment, and your e-mail address or telephone number. This information is used solely to respond to your inquiry. If you would like the agency to mail you materials, DLAS will request your mailing address so that it may provide the appropriate materials. Personal information that you provide to the DLAS is safeguarded according to the laws of the Commonwealth of Virginia.</p>

                <p className="bold">Information Access</p>
                <p>Any personal information that is collected and retained by DLAS is maintained in compliance with the Government Data Collection and Dissemination Practices Act, Chapter 38 of Title 2.2 of the Code of Virginia. The agency does not share, sell or trade e-mail addresses, information collected on surveys or any other information about its online customers to any entity except as provided for or required by Chapter 38 of Title 2.2 of the Code of Virginia, the Virginia Freedom of Information Act, or other law. Under FOIA, any records in DLAS’ possession at the time of a Freedom of Information Request might be subject to being inspected by or disclosed to members of the public. If necessary, DLAS may disclose user information if it has reason to believe that disclosing such information is necessary to identify, contact, or bring legal action against an individual who attempts, even unintentionally, to unauthorized access to DLAS information or to disrupt our web site.</p>

                <p className="bold">Use of "Cookies"</p>
                <p>In certain applications, user information is stored as "cookies," which are then sent back and stored on your hard drive. Cookies allow the agency to save the information that you input while progressing through an online transaction. If your browser is not configured to accept cookies, you will still be able to access the information on the web site, but you may not be able to complete many of the online transactions. No user information is gathered through cookies except for that needed to run the specific application.</p>

                <p className="bold">Security</p>
                <p>DLAS maintains security standards and procedures regarding unauthorized access to customer information to prevent unauthorized removal or alteration of data. When you browse public areas of the DLAS web site, none of your personal information is captured or stored by the agency. DLAS protects its records in accordance with its obligations as defined by applicable Virginia statues, including, but not limited to, the Government Data Collection and Dissemination Practices Act, the Virginia Freedom of Information Act, and by any applicable U.S. federal laws.</p>

                <p className="bold">Information Accuracy and Your Rights</p>
                <p>DLAS has established procedures to ensure that information is as accurate and current as is practical to enable us to conduct business with you. Although the agency strives to do things right the first time, it also responds to requests to correct inaccurate information in a timely manner. You have the right to review the record that DLAS maintains about you in order to verify its accuracy. You may do this by contacting the individual listed below. If you find errors in your personal record, or desire that the agency purge your data from its records, you must contact the individual listed below. DLAS employee access to personally identifiable information is restricted to only those employees who need access in order to perform their assigned duties.</p>

                <p className="bold">Contact Us</p>
                <p>DLAS welcomes your comments and invites you to contact us if you have any questions or concerns:
                    <br />Virginia Division of Legislative Automated Systems
                    <br />Old City Hall
                    <br />1001 East Broad Street, Suite 210
                    <br />Richmond, VA 23219
                    <br />email: <a href="mailto:LIS@dlas.virginia.gov">LIS@dlas.virginia.gov</a>
                </p>

                <p className="bold">Disclaimer</p>

                <p>The Virginia Division of Legislative Automated Systems (DLAS) provides links to web sites that are not controlled, maintained, or regulated by DLAS or any organization affiliated with DLAS, and as such DLAS is NOT responsible for the content of those web sites. Use of information obtained from those web sites is voluntary, and reliance on that information should only be undertaken after an independent review of its accuracy. Reference at those web sites to any specific commercial product, process, or service by trade name, trademark, or otherwise DOES NOT constitute or imply endorsement, recommendation, or preference by DLAS. The agency tries to screen all sites included on its web site, but cannot control the content they possess. Any inappropriate information or links they have on their site that pertains to DLAS, or related activities, should be reported to DLAS and can be emailed to <a href="mailto:LIS@dlas.virginia.gov">LIS@dlas.virginia.gov</a>.</p>

            </div>
        )
    }
}

export default PrivacyComponent