import React from "react";
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { Prompt } from 'react-router';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import { navActionCreators } from '../../../stores/lis-nav-store';
import { cancelRequest } from '../../../services/request.service';
import moment from 'moment'
import LoaderComponent from '../../../lis-shared/lis-layout/components/lis-loader-component';
import JournalDetails from './lis-journal-details';
import ReactTable from 'react-table';
import { journalActionCreators } from "../../../stores/lis-journal-store";
import JournalVote from "../forms/journal-vote";

class JournalManagementForm extends React.Component {

    constructor(props) {
        super(props)
        this.state = {
            activity: '',
            journal: {},
            currentActivitySequence: 0,
            isLoaded: false,
            disableChanges: false,
            //Fieldsets
            indexFieldsetIsActive: true,
            activityFieldsetIsActive: false,
            previewFieldsetIsActive: false,
            categoriesToMove: [],
            selectedCategoryToMoveTo: -1,
            saving: false,
            isDirty: false
        }

        //Ref used to scroll down to activity section
        this.activityRef = React.createRef();
        this.entryFormRef = React.createRef();

        this.entryChange = this.entryChange.bind(this);
        this.multipleEntryChange = this.multipleEntryChange.bind(this);
        this.toggleMoveEntry = this.toggleMoveEntry.bind(this);
        this.moveEntry = this.moveEntry.bind(this);
        this.handleSequenceChange = this.handleSequenceChange.bind(this);
        this.saveJournal = this.saveJournal.bind(this);
        this.generateMml = this.generateMml.bind(this);
        this.showActivity = this.showActivity.bind(this);
    }

    toggleFieldsetCollapse(isActive) {
        this.setState(state => ({
            [isActive]: !state[isActive]
        }));
    }

    entryChange(index, entry, sequence) {
        //Takes the sequence, which is always one more than the index, and change the values for that JournalEntry
        let newFormData = JSON.parse(JSON.stringify(this.state.journal));
        newFormData.JournalCategories[sequence - 1].JournalEntries[index] = entry;
        this.setState({
            journal: newFormData,
            isDirty: true
        });
    }

    multipleEntryChange(entries, sequence) {
        let newFormData = JSON.parse(JSON.stringify(this.state.journal));
        newFormData.JournalCategories[sequence - 1].JournalEntries = entries;
        this.setState({
            journal: newFormData,
            isDirty: true
        });
    }

    toggleMoveEntry(open, categoryIndex, entryIndex) {
        if (open) {
            const currentCategory = this.state.journal.JournalCategories[categoryIndex];
            const applicableCategories = this.state.journal.JournalCategories.filter(cat => cat.CategoryType === currentCategory.CategoryType && cat.JournalCategoryID !== currentCategory.JournalCategoryID);
            this.setState({
                movingCategorySource: categoryIndex,
                movingEntrySource: entryIndex,
                categoriesToMove: applicableCategories,
                showMoveEntryDialog: open
            });
        } else {
            this.setState({
                showMoveEntryDialog: false,
                categoriesToMove: [],
                selectedCategoryToMoveTo: -1,
                movingCategorySource: -1,
                movingEntrySource: -1,
            });
        }
    }

    moveEntry(destinationCategoryIndex) {
        let sourceCategory = { ...this.state.journal.JournalCategories[this.state.movingCategorySource] }
        let sourceEntry = { ...sourceCategory.JournalEntries[this.state.movingEntrySource] };
        let destinationCategory = this.state.journal.JournalCategories.find(cat => cat.JournalCategoryID === this.state.categoriesToMove[destinationCategoryIndex].JournalCategoryID);
        if (destinationCategory) {
            let newEntry = {
                ...sourceEntry,
                JournalCategoryID: destinationCategory.JournalCategoryID,
                Sequence: destinationCategory.JournalEntries && destinationCategory.JournalEntries.length > 0 ? destinationCategory.JournalEntries[destinationCategory.JournalEntries.length - 1].Sequence + 1 : 1
            };
            this.saveEntry(newEntry);
            this.showActivity(destinationCategory);
            this.toggleMoveEntry(false);

            //Remove the old entry from the state variable
            let journal = { ...this.state.journal };
            journal.JournalCategories[this.state.movingCategorySource].JournalEntries.splice(this.state.movingEntrySource, 1);
            this.setState({
                journal: journal,
                isDirty: true
            });
        }
    }

    handleSequenceChange(categories) {
        //The sequence change has been handled by the JournalIndex class. So the new list of Journal Categories is sent here to modify the journal
        let journal = this.state.journal;
        //Check and see if the currently opened activity has been affected by the sequence change. If so, then update the current activity state item 
        let newCategoryIndex = this.state.currentActivitySequence - 1;
        if (categories[this.state.currentActivitySequence - 1] !== this.state.journal.JournalCategories[this.state.currentActivitySequence - 1]) {
            newCategoryIndex = categories.findIndex(category => category === this.state.journal.JournalCategories[this.state.currentActivitySequence - 1])
        }
        journal.JournalCategories = categories;
        //currentActivitySequence is 0 by default so we don't want to set currentActivitySequence to an undefined value. 
        //So make a check to see if the user has clicked on a category item yet. If not then leave it at zero
        this.setState({
            journal: journal,
            isDirty: true,
            currentActivitySequence: categories[newCategoryIndex] ? categories[newCategoryIndex].Sequence : 0
        });
    }

    showActivity(category, e) {
        let journal = JSON.parse(JSON.stringify(this.state.journal));
        //Open the activity drawer
        this.setState({
            activityFieldsetIsActive: true,
            currentActivitySequence: category.Sequence,
            activity: category,
            journal: journal
        }, () => {
            //Get the size and position of the activity form and scroll down to it
            const activityRect = this.activityRef.current.getBoundingClientRect();
            const windowY = window.scrollY;
            window.scrollTo({
                top: activityRect.top + windowY,
                behavior: 'smooth'
            });

        });
    }

    saveJournal() {
        let journal = JSON.parse(JSON.stringify(this.state.journal));
        //Disable changes so the user doesn't try to save something else while this is being saved
        this.setState({ disableChanges: true })
        this.props.actions.saveJournal({ journals: [journal] })
            .then(() => {
                const newJournal = this.props.journal.journalSave;
                this.setState({
                    journal: newJournal,
                    disableChanges: false,
                    saving: false,
                    isDirty: false
                }, () => {
                    this.props.actions.makeToast([{ message: 'Save Success', type: "success" }])
                });
            }).catch(err => {
                if (err === 'Aborted') {
                    return;
                }
                console.log(err);
                this.setState({ saving: false })
                this.props.actions.makeToast([{ message: 'Save Failed', type: "failure" }])
            });
    }

    generateMml() {
        this.setState({ generatingMml: true, disableChanges: true })
        this.props.actions.generateJournal('?sessionID=' + this.state.journal.SessionID + '&journalDay=' + this.state.journal.JournalDate)
            .then(() => {
                this.setState({
                    disableChanges: false,
                    generatingMml: false
                }, () => {
                    const blob = new Blob([this.props.journal.journalGeneration], { type: 'text/html' });
                    const filename = `Journal ${moment(this.state.journal.JournalDate).format("MM/DD/yyyy")}.mml`;
                    const url = URL.createObjectURL(blob);

                    const link = document.createElement('a');
                    link.href = url;
                    link.download = filename;
                    document.body.appendChild(link);
                    link.click();

                    // Clean up
                    document.body.removeChild(link);
                    URL.revokeObjectURL(url);
                    this.props.actions.makeToast([{ message: 'Generation Success', type: "success" }])
                });
            }).catch(err => {
                if (err === 'Aborted') {
                    return;
                }
                console.log(err);
                this.setState({ generatingMml: false })
                this.props.actions.makeToast([{ message: 'Generation Failed', type: "failure" }])
            });
    }

    componentDidMount() {
        this.props.actions.getJournalById('journalID=' + this.props.match.params.journalid, true)
            .then(() => {
                if (!this.props.journal.journalById || !this.props.journal.journalById.length) { throw 'No Journals found' }
                let journal = this.props.journal.journalById[0];
                if (journal.JournalCategories) {
                    //Check if the Sequence for each category is linear
                    const sequences = journal.JournalCategories.map(mc => mc.Sequence);
                    if (journal.JournalCategories.length > 0 && (journal.JournalCategories[0].Sequence !== 1 || !sequences.every((seq, i) => i === sequences.length - 1 || seq + 1 === sequences[i + 1]))) {
                        //Get the sequence right. Sometimes the result back from the server has a lot of skipped indices
                        journal.JournalCategories.forEach((category, index) => {
                            category.Sequence = index + 1;
                        });
                        this.setState({
                            journal: journal,
                            isLoaded: true,
                            previewFieldsetIsActive: true
                        }, //Save the new sequence so we don't have to do this process again for this journal
                            () => { this.saveJournal(); });
                    } else { //It is in order so display the journal
                        this.setState({
                            journal: journal,
                            isLoaded: true,
                            previewFieldsetIsActive: true
                        });
                    }
                } else {
                    this.setState({
                        journal: '',
                        isLoaded: true,
                        previewFieldsetIsActive: false
                    });
                    this.props.actions.makeToast([{ message: "Loading Failed", type: "failure" }])
                }
            }).catch(err => {
                if (err === 'Aborted') {
                    return
                }
                console.log(err);
                this.setState({
                    journal: '',
                    isLoaded: true,
                });
            });

    }

    componentWillUnmount() {
        cancelRequest();
    }

    render() {
        if (this.state.isLoaded) {
            return (
                <div className="user-forms dlas-forms">
                    <div>
                        <Prompt
                            when={this.state.isDirty}
                            message={`You have unsaved changes. Are you sure you would like to leave?`}
                        />
                    </div>
                    <h2>{moment(this.state.journal.JournalDate).format("LL")}</h2>
                    {this.state.showMoveEntryDialog &&
                        <div className='popup' onClick={() => this.toggleMoveEntry(false)}>
                            <div className='popup-inner tall-content' onClick={e => e.stopPropagation()}>
                                {this.state.categoriesToMove.length === 0 ?
                                    <p>There are no categories where you can move this entry</p>
                                    :
                                    <p>Choose a category to move the entry to</p>
                                }
                                <div style={{ paddingBottom: '10px' }}>
                                    <ReactTable
                                        data={this.state.categoriesToMove}
                                        getTrProps={(_state, rowInfo) => {
                                            return {
                                                style: {
                                                    background: rowInfo && rowInfo.index === this.state.selectedCategoryToMoveTo ? '#89ff89' : '',
                                                    cursor: rowInfo ? 'pointer' : 'default'
                                                },
                                                onClick: () => this.setState({ selectedCategoryToMoveTo: rowInfo.index })
                                            }
                                        }}
                                        noDataText=""
                                        columns={[
                                            {
                                                id: "checkbox",
                                                accessor: "",
                                                className: "checkbox-column",
                                                Cell: ({ index }) => {
                                                    return <span className={index === this.state.selectedCategoryToMoveTo ? "icon checkmark" : ""}></span>;
                                                },
                                                sortable: false,
                                                filterable: false,
                                                width: 45
                                            },
                                            {
                                                Header: "Sequence",
                                                accessor: "Sequence",
                                                width: 50
                                            },
                                            {
                                                Header: "Type",
                                                accessor: "CategoryType"
                                            },
                                            {
                                                Header: "Description",
                                                accessor: "CategoryDescription"
                                            }
                                        ]}
                                        defaultPageSize={5}
                                        className="-striped -highlight"
                                    />
                                </div>
                                <div className="flex-row" sty>
                                    <button className="button secondary" onClick={() => this.toggleMoveEntry(false)}>Cancel</button>
                                    {this.state.categoriesToMove.length > 0 &&
                                        <button disabled={this.state.selectedCategoryToMoveTo === -1} onClick={() => this.moveEntry(this.state.selectedCategoryToMoveTo)} className="button">Move</button>
                                    }
                                </div>
                            </div>
                        </div>
                    }
                    <fieldset className={this.state.indexFieldsetIsActive ? 'fieldset-collapse fieldset-open' : 'fieldset-collapse fieldset-closed'} >
                        <legend onClick={this.toggleFieldsetCollapse.bind(this, 'indexFieldsetIsActive')}>Index</legend>
                        <div>
                            <JournalIndex
                                categories={this.state.journal.JournalCategories ? this.state.journal.JournalCategories : []}
                                onSequenceChange={this.handleSequenceChange}
                                showActivity={this.showActivity}
                                currentActivitySequence={this.state.currentActivitySequence}
                                disableChanges={this.state.disableChanges} />
                        </div>
                    </fieldset>
                    <fieldset ref={this.activityRef} className={this.state.activityFieldsetIsActive ? 'fieldset-collapse fieldset-open' : 'fieldset-collapse fieldset-closed'} >
                        <legend onClick={this.toggleFieldsetCollapse.bind(this, 'activityFieldsetIsActive')}>Activity</legend>
                        {this.state.currentActivitySequence && this.state.currentActivitySequence !== 0 ?
                            <TypeSwitcher
                                type={this.state.activity}
                                ref={this.entryFormRef}
                                onEntryChange={this.entryChange}
                                onMultipleEntryChange={this.multipleEntryChange}
                                currentActivitySequence={this.state.currentActivitySequence}
                                toggleMoveEntry={this.toggleMoveEntry}
                                formData={this.state.journal.JournalCategories ? this.state.journal.JournalCategories[this.state.currentActivitySequence - 1] : {}} />
                            : null}
                    </fieldset>
                    <fieldset className={this.state.previewFieldsetIsActive ? 'fieldset-collapse fieldset-open' : 'fieldset-collapse fieldset-closed'} >
                        <legend onClick={this.toggleFieldsetCollapse.bind(this, 'previewFieldsetIsActive')}>Preview</legend>
                        <Preview
                            categories={this.state.journal.JournalCategories ? this.state.journal.JournalCategories : []}
                            save={this.saveJournal}
                            saving={this.state.saving}
                            generateMml={this.generateMml}
                            generatingMml={this.state.generatingMml}
                            isDirty={this.state.isDirty}
                        />
                    </fieldset>
                </div>

            )
        } else {
            return (
                <div className="user-forms dlas-forms">
                    <LoaderComponent className="center-spinner" data={this.state.isLoaded} />
                </div>
            )
        }
    }
}

const getItemStyle = (isDisabled, isDragging, draggableStyle, pendingDeletion) => ({
    userSelect: 'none',
    background: isDisabled ? '#c7c7c7' : isDragging ? '#34495e' : 'white',
    color: isDisabled ? 'black' : isDragging ? 'white' : '#34495e',
    border: pendingDeletion ? 'red' : 'none',
    ...draggableStyle,
});

const getListStyle = isDraggingOver => ({
    background: isDraggingOver ? '#8b9fb3' : '#666666',
    width: '100%',
});

class JournalIndex extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
        }
    }

    onDragEnd(result) {
        // dropped outside the list
        if (!result.destination) {
            return;
        }
        const items = (() => {
            const list = Array.from(this.props.categories);
            const [removed] = list.splice(result.source.index, 1);

            list.splice(result.destination.index, 0, removed);
            // Reorder the Sequence value too because the sequence value is used to show what order the preview is in
            list.forEach(function (element, index) {
                element.Sequence = index + 1
            });
            return list;
        })();
        //Send the new values up to the parent to be set in the parent's state
        this.props.onSequenceChange(items);
    }


    render() {
        if (this.props.categories) {
            return (
                <div className={this.props.disableChanges ? 'dnd-disabled' : ''}>
                    <DragDropContext onDragEnd={this.onDragEnd.bind(this)}>
                        <Droppable droppableId="droppable">
                            {(provided, snapshot) => (
                                <table
                                    className="dnd-grid-table journal-categories-grid"
                                    ref={provided.innerRef}
                                    style={getListStyle(snapshot.isDraggingOver)}
                                >
                                    <thead className="dnd-grid-header" style={getListStyle(snapshot.isDraggingOver)}>
                                        <tr>
                                            <th scope="col" id={'-grabber-column'}></th>
                                            <th scope="col" id={'-display-column'}>Display #</th>
                                            <th scope="col" id={'-display-column'}>Section</th>
                                            <th scope="col" id={'-actions-column'}>Actions</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {this.props.categories.map((item, index) => (
                                            <Draggable key={`${item.Sequence}-${item.JournalCategoryID}`} draggableId={`${item.Sequence}-${item.JournalCategoryID}`} index={index}>
                                                {(provided, snapshot) => (
                                                    <tr
                                                        ref={provided.innerRef}
                                                        {...provided.draggableProps}
                                                        {...provided.dragHandleProps}
                                                        className={this.props.categories[this.props.currentActivitySequence - 1] === item ? 'selected' : 'not-selected'}
                                                        style={getItemStyle(
                                                            this.props.disableChanges,
                                                            snapshot.isDragging,
                                                            provided.draggableProps.style
                                                        )} >
                                                        <td><button id={item.JournalCategoryID + '-grabber-button'} className="button draggable">Drag and drop</button></td>
                                                        <td>{index + 1}</td>
                                                        <td className='category-desc' onClick={(e) => this.props.showActivity(this.props.categories[index], e)}>
                                                            {<button type="button">{item.CategoryDescription}</button>}
                                                        </td>
                                                        <td>
                                                            <button
                                                                type="button"
                                                                onClick={(e) => this.props.showActivity(this.props.categories[index], e)}
                                                                className={this.props.categories[this.props.currentActivitySequence - 1] === item
                                                                    ? 'icon edit-alt'
                                                                    : 'icon edit'}>
                                                            </button>
                                                        </td>
                                                    </tr>
                                                )}
                                            </Draggable>
                                        ))}
                                        {provided.placeholder}
                                    </tbody>
                                </table>
                            )}
                        </Droppable>
                    </DragDropContext>
                </div>
            )
        } else {
            return (<div></div>)
        }
    }

}

class Preview extends React.Component {
    render() {
        const previewLengthStyle = { height: '500px', overflowY: 'scroll', backgroundColor: 'white' }
        return (
            <React.Fragment>
                <div style={{ display: 'flex', gap: '5px', justifyContent: 'flex-end' }}>
                    <button type="button" disabled={this.props.isDirty || this.props.generatingMml} className="button" onClick={this.props.generateMml}>Generate MML</button>
                    <button type="button" disabled={this.props.saving || this.props.generatingMml} className="button" onClick={this.props.save}>Save</button>
                </div>
                <div style={previewLengthStyle}>
                    <JournalDetails
                        categories={this.props.categories}
                    />
                </div>
            </React.Fragment>
        )
    }
}

const TypeSwitcher = React.forwardRef((props, ref) => {
    switch (props.ObjectName) {
        case 'Vote':
        default:
            return <JournalVote {...props} />
    }
});

export default connect(
    (state) => {
        const { journal, nav } = state;
        return {
            journal,
            nav
        }
    },
    (dispatch) => {
        return {
            actions: bindActionCreators(Object.assign({}, journalActionCreators, navActionCreators), dispatch)
        }
    }
)(JournalManagementForm)