import React from 'react';
import { Link } from 'react-router-dom';
import ReactTable from 'react-table'

class BillGridSection extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
        }

        this.onExpandedChange = this.onExpandedChange.bind(this);
    }

    getAmendmentDescription(legislationText) {
        let descriptionAndDisposition = legislationText.Description;
        // Versions that are held have the text disposition hidden to match the public version table
        if (legislationText.TextDispositionID === 12) {
            return legislationText.Description + " Engrossed"
        }

        if (legislationText.TextDisposition === "Hold") {
            return descriptionAndDisposition;
        } else if (legislationText.TextDisposition !== "Posted") {
            descriptionAndDisposition = `${legislationText.Description} ${legislationText.TextDisposition || ''}`;
        }
        //If the IsReprint boolean is true and the service didn't append Reprint to the description already
        if (legislationText.IsReprint && !descriptionAndDisposition.includes("(Reprint)")) {
            descriptionAndDisposition = descriptionAndDisposition + " (Reprint)";
        }

        if (legislationText.LDNumber) {
            descriptionAndDisposition = descriptionAndDisposition + " - " + legislationText.LDNumber;
        }

        if (!legislationText.DocumentCode || legislationText.DocumentCode === "") {
            return legislationText.Description;
        }

        return descriptionAndDisposition;
    }

    onExpandedChange(newExpanded) {
        this.props.toggleExpandedBills(newExpanded, this.props.currentPage)
    }

    render() {
        return (
            <div>
                <ReactTable
                    resizable={false}
                    data={this.props.billList}
                    loading={!this.props.isLoaded}
                    ref={r => this.reactTable = r}
                    autoResetExpanded={false}
                    freezeWhenExpanded={false}
                    expanded={Object.keys(this.props.expandedBills).length > 0 ? this.props.expandedBills : undefined}
                    onExpandedChange={newExpanded => this.onExpandedChange(newExpanded)}
                    page={this.props.currentPage}
                    pages={Math.ceil((this.props.billList ? this.props.billList.length : 0) / this.props.pageSize)}
                    onPageChange={this.props.onPageChange}
                    SubComponent={({ original, index }) => {
                        return (
                            <div style={{ fontSize: "1.2em" }}>
                                <ul>
                                    {original.Amendments.filter((x) => this.props.selectedChamber ? x.ChamberCode === this.props.selectedChamber : x).sort((a, b) => a.DocumentCode && a.DocumentCode.localeCompare(b.DocumentCode)).map((amendment, amendmentIndex) => {
                                        return (
                                            <div key={amendmentIndex}>
                                                {amendment.IsPublic ?
                                                    <Link to={`/bill-details/${original.SessionCode}/${original.LegislationNumber}/text/${amendment.DocumentCode}`} target="_blank" rel="noreferrer" className="icon book" style={{ marginRight: '8px' }} />
                                                    :
                                                    <span className="icon flag" style={{ marginRight: '8px' }} />
                                                }
                                                <button onClick={() => this.props.selectAmendment(index, amendment)} className="button-link">{amendment.DocumentCode && amendment.DocumentCode + ' - '}{this.getAmendmentDescription(amendment)}</button>
                                            </div>
                                        )
                                    }
                                    )}
                                </ul>
                            </div>
                        );
                    }}
                    columns={[
                        {
                            Header: 'Bill #',
                            sortable: true,
                            filterable: false,
                            width: 90,
                            accessor: "",
                            Cell: ({ original, index }) => {
                                if (original && original.SessionCode) {
                                    return (<Link to={`/bill-details/${original.SessionCode}/${original.LegislationNumber}`} target="_blank" rel="noreferrer">{original.LegislationNumber}</Link >)
                                } else {
                                    return (<span>{original.LegislationNumber}</span>)
                                }
                            }
                        },
                        {
                            Header: 'Patron',
                            sortable: true,
                            filterable: false,
                            accessor: "Patrons",
                            width: 90,
                            Cell: row =>
                                <span>{row.value && row.value.map((patron, index) => <React.Fragment key={index}>{patron.PatronDisplayName}{index !== row.value.length - 1 && ", "}</React.Fragment>)}</span>
                        },
                        {
                            Header: 'Caption',
                            sortable: true,
                            filterable: false,
                            accessor: "Description",
                            Cell: row => <span>{row.value}</span>
                        },
                        {
                            Header: 'Actions',
                            sortable: false,
                            filterable: false,
                            accessor: "",
                            width: 145,
                            expander: true,
                            Expander: ({ isExpanded, index }) => {
                                return (
                                    <div className="committee-action-column">
                                        {this.props.billDataIsLoading === index ?
                                            <div><span className="small-spinner"></span></div>
                                            :
                                            <button className={`${isExpanded ? 'arrow-up' : 'arrow-down'}`} type="button"></button>
                                        }
                                    </div>
                                );
                            }
                        }
                    ]}
                    pageSize={this.props.pageSize}
                    onPageSizeChange={this.props.onPageSizeChange}
                    className="committee-table -striped -highlight react-table-hide-no-data"
                />
            </div>
        );
    }
}

export default BillGridSection;