import React from "react";
import moment from 'moment';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { developersActionCreators } from "../../../stores/lis-developers-store";
import { navActionCreators } from "../../../stores/lis-nav-store";

const phoneRegex = /^[\+]?[(]?[0-9]{3}[)]?[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{4,6}$/im;
const urlRegex = /((([A-Za-z]{3,9}:(?:\/\/)?)(?:[-;:&=\+\$,\w]+@)?[A-Za-z0-9.-]+|(?:www.|[-;:&=\+\$,\w]+@)[A-Za-z0-9.-]+)((?:\/[\+~%\/.\w-_]*)?\??(?:[-\+=&;%@.\w_]*)#?(?:[\w]*))?)/

class KeyConfirmation extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            isSubmitting: false,
            phoneError: '',
            urlError: '',
            name: '',
            organization: '',
            phone: '',
            url: '',
            agree: false
        }

        this.checkPhone = this.checkPhone.bind(this);
        this.checkUrl = this.checkUrl.bind(this);
        this.handleChange = this.handleChange.bind(this);
        this.submit = this.submit.bind(this);
    }

    checkPhone() {
        if (!phoneRegex.test(this.state.phone)) {
            this.setState({
                phoneError: 'Invalid Phone Number'
            }, () => {
                this.props.actions.makeToast([{ message: this.state.phoneError, type: "failure" }])
            });
            return true;
        }
        this.setState({
            phoneError: ''
        });
    }

    checkUrl() {
        if (!urlRegex.test(this.state.url)) {
            this.setState({
                urlError: 'Invalid URL'
            }, () => {
                this.props.actions.makeToast([{ message: this.state.urlError, type: "failure" }])
            });
            return true;
        }
        this.setState({
            urlError: ''
        });
    }

    handleChange(state, value) {
        this.setState({
            [state]: value
        })
    }

    submit() {
        this.setState({
            isSubmitting: true
        }, () => {
            let error = false
            if (this.state.phone.length > 0) {
                error = this.checkPhone();
            }
            if (this.state.url.length > 0) {
                error = this.checkUrl();
            }

            if (!error && this.state.name.length > 0 && this.state.phone.length > 0) {
                let body= {};

                body.ContactName = this.state.name;
                body.OrganizationName = this.state.organization;
                body.Phone = this.state.phone;
                body.Url = this.state.url;
                body.HashedWord = this.props.match.params.params.split("/")[0]
                body.EulaDate = moment(new Date()).format('MM/DD/YYYY')

                this.props.actions.submitConfirmKeyRegistration(body)
                    .then(() => {
                        //Don't need to reset isSubmitting because the user will be redirected
                        this.props.actions.makeToast([{ message: "Your registration is now complete. You will receive an email with more details shortly.", type: "success", long: true }])
                        //Give the user time to read the toast, then forward to the homepage
                        setTimeout(() => { window.location.href = "/home"; }, 5000);
                    })
                    .catch(() => {
                        this.setState({
                            isSubmitting: false
                        }, () => {
                            this.props.actions.makeToast([{ message: this.props.developers.keyRegistrationError, type: "failure" }])
                        })
                    })
            } else {
                this.setState({
                    isSubmitting: false
                })
            }
        })
    }

    render() {
        const { name, organization, phone, url, isSubmitting, agree } = this.state;

        return (
            <div>
                <form className="dlas-forms key-registration-form">
                    <h3>Complete LIS API Key Registration</h3>
                    <div className="inner-grid short-one-three">
                        <label htmlFor="name">Name*</label>
                        <input
                            id="name"
                            type="text"
                            value={name}
                            onChange={(e) => this.handleChange("name", e.target.value)}
                        />
                    </div>
                    <div className="inner-grid short-one-three">
                        <label htmlFor="organization">Organization</label>
                        <input
                            id="organization"
                            type="text"
                            value={organization}
                            onChange={(e) => this.handleChange("organization", e.target.value)}
                        />
                    </div>
                    <div className="inner-grid short-one-three">
                        <label htmlFor="url">Organization URL</label>
                        <input
                            id="url"
                            type="text"
                            value={url}
                            onChange={(e) => this.handleChange("url", e.target.value)}
                        />
                    </div>
                    <div className="inner-grid short-one-three">
                        <label htmlFor="phone">Phone Number*</label>
                        <input
                            id="phone"
                            type="text"
                            value={phone}
                            onChange={(e) => this.handleChange("phone", e.target.value)}
                        />
                    </div>
                    <div className="inner-grid reverse-short-one-three">
                        <div>
                            <label>By checking this box you agree to the <a href="/tos" className="doc-link">terms of service</a>.</label>
                            <input
                                type="checkbox"
                                checked={agree}
                                onClick={(e) => this.handleChange("agree", !agree)}
                            />
                        </div>
                        <div className="align-right">
                            <button onClick={this.submit} disabled={isSubmitting || (!name || !phone || !agree)} type="button" className="button">Submit</button>
                        </div>
                    </div>
                </form>
            </div>
        )
    }
}

export default connect(
    (state) => {
        const { nav, developers } = state;
        return {
            nav,
            developers
        }
    },
    (dispatch) => {
        return {
            actions: bindActionCreators(Object.assign({}, developersActionCreators, navActionCreators), dispatch)
        }
    }
)(KeyConfirmation)