import React from 'react';
import '../../../stylesheets/lis-public-view/public-view.css';
import { Link } from 'react-router-dom';
import moment from 'moment';
import renderHtml from 'react-render-html';

class Schedule extends React.Component {

    render() {
        return (
            <div>
                <h3 className="homepage-header">Upcoming Schedule</h3>
                <hr />
                {!this.props.isLoaded ?
                    <div className="center-spinner spinner small-height" />
                    :
                    <>
                        {this.props.schedule.length === 0 &&
                            <div className="center" style={{ fontFamily: "Lato" }}>
                                <span>Nothing Scheduled</span>
                            </div>
                        }
                        <div style={{ maxHeight: '370px', overflowY: 'auto' }}>
                            {this.props.schedule.map((date, dateIndex) =>
                                <div className="homepage-schedule-item" key={dateIndex}>
                                    <div className="homepage-schedule-date">
                                        <span>{moment(date.Date).format('MMMM D, YYYY')}</span>
                                    </div>
                                    {date.Times.map((time, timeIndex) =>
                                        <div>
                                            {time.Items.length > 0 &&
                                                <React.Fragment>
                                                    {time.Time !== "" &&
                                                        <span className="txt-greyed schedule-time">{time.Time}</span>
                                                    }
                                                    <ul className="small-text" key={timeIndex}>
                                                        {time.Items.map((item, itemIndex) =>
                                                            <li key={itemIndex}>
                                                                <div style={itemIndex !== 0 ? { marginTop: "5px" } : {}}>
                                                                    <span className="schedule-owner">{item.OwnerName} {item.IsCancelled && <span className="fail"> CANCELLED</span>}</span>
                                                                </div>
                                                                <div>
                                                                    <span style={{ whiteSpace: 'pre-wrap' }}>{item.Description && renderHtml(item.Description)}</span>
                                                                </div>
                                                            </li>
                                                        )}
                                                    </ul>
                                                </React.Fragment>
                                            }
                                        </div>
                                    )}
                                </div>
                            )}
                        </div>
                        <div className="homepage-links">
                            <Link to="/schedule">Full Schedule</Link>
                        </div>
                    </>
                }
            </div>
        );
    }
}

export default Schedule;
