import React, {useState} from 'react';
import tinymce from 'tinymce/tinymce';
import 'tinymce/themes/silver/theme';
import 'tinymce/icons/default'
import 'tinymce/plugins/paste/plugin';
import 'tinymce/plugins/link/plugin';
import 'tinymce/plugins/save/plugin';
import 'tinymce/plugins/lists/plugin';
import 'tinymce/plugins/code/plugin';
import 'tinymce/plugins/spellchecker/plugin';
import 'tinymce/plugins/preview/plugin';
import 'tinymce/plugins/searchreplace/plugin';
import 'tinymce/plugins/pagebreak/plugin';
import 'tinymce/plugins/table/plugin';
import '../../../../stylesheets/tinymce/oxide/skin.min.css';
import { Editor } from '@tinymce/tinymce-react';
import ReactTable from 'react-table';

const CommentBox = props => {
    const [selectedComment, selectComment] = useState(-1);
    const [showView, toggleViews] = useState(true);
    const handleChange = content => {
        let comments = [...props.comments];
        comments[selectedComment].MinutesSummary = content;
        props.handleCommentBoxChange(comments);
    }
    const addComment = () => {
        let comments = [...props.comments];
        comments.push({MinutesSummary: ''});
        props.handleCommentBoxChange(comments);
        selectComment(props.comments.length);
        toggleViews(false);
    };
    const getCommentPreview = (comment, index) => {
        // Shows the first paragraph of the comment to the user in the grid so they don't need to open the comment to know what its content is.
        // If a paragraph doesn't exist then the first element of the text is used
        // If there aren't any elements, then just put comment number so its not just a blank row.
        const container = document.createElement("DIV");
        container.innerHTML = comment.MinutesSummary;
        const paragraphs = container.getElementsByTagName("P")
        if(paragraphs.length > 0 && paragraphs[0].innerText) {
            return(<span>{paragraphs[0].innerText}</span>)
        } else {
            const firstElement = container.childNodes[0];
            if(firstElement && firstElement.innerText) {
                return(<span>{firstElement.innerText}</span>)
            } else {
                return <span>Comment {index + 1}</span>;
            }
        }
    }
    return(
        <div onClick={() => props.toggleBox(false)} className="overlay center-content">
            <div className="full">
                <div onClick={e => e.stopPropagation()} className="dlas-forms user-forms advanced-search overlay-content animated-fade-in-up">
                    <ReactTable
                        data={props.comments}
                        showPageSizeOptions={false}
                        showPagination={false}
                        showPageJump={false}
                        pageSize={props.comments.length}
                        getTrProps={(_state, rowInfo) => {
                            return {
                                style: {
                                    background: rowInfo && rowInfo.index === selectedComment ? '#89ff89' : '',
                                    cursor: rowInfo ? 'pointer' : 'default',
                                    display: showView ? 'inline-flex' : 'none'
                                },
                                onClick: () => {
                                    selectComment(rowInfo.index);
                                    toggleViews(false);
                                }
                            }
                        }}
                        noDataText=""
                        columns={[
                            {
                                id: "checkbox",
                                className: "checkbox-column",
                                Header: () => <button onClick={() => toggleViews(!showView)} className={`${showView ? 'arrow-up' : 'arrow-down'} white-chevron`}></button>,
                                Cell: ({ index }) => <span className={index === selectedComment ? "icon checkmark" : ""}></span>,
                                sortable: false,
                                filterable: false,
                                width: 45
                            },
                            {
                                Cell: ({original, index}) => getCommentPreview(original, index),
                                Footer: () => showView ? <button onClick={addComment} className="button" type="button">Add Comment</button> : null
                            },
                        ]}
                        className="-striped -highlight"
                    />
                    <div style={{display: showView ? 'none' : 'block'}}>
                        <Editor
                            value={props.comments[selectedComment] ? props.comments[selectedComment].MinutesSummary : ''}
                            init={{
                                height: window.innerHeight / 1.5,
                                plugins: 'link paste save lists spellchecker preview searchreplace pagebreak code table',
                                toolbar: 'undo redo | bold italic strikethrough | alignleft aligncenter alignright',
                                skin: false,
                                valid_children: '+body[style]',
                                content_css: false,
                                content_style: 'table { border-collapse: collapse; border: 1px solid #FFFFFF }' + 'td { padding: 12px 0px }',
                            }}
                            onEditorChange={content => handleChange(content)}
                        />
                    </div>
                    <div className="flex-row">
                        <br />
                        <div className="inline-list">
                            <button type="button" onClick={() => props.toggleBox(false)} className="button secondary">Cancel</button>
                            <button type="button" onClick={props.saveComment} className="button">Save</button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default CommentBox;