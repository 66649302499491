const getReferenceList = (eventCode, fullList) => {
    let filteredOptions;
    let otherVersions;
    switch (eventCode.substring(1)) {
        case '4210':
        case '4211':
        case '4212':
            //committee amendment
            filteredOptions = fullList.filter(text => text.LegislationVersion.toLowerCase() === 'amendment' && text.Sponsor && text.Sponsor.toLowerCase() === 'committee');
            const otherAmendments = fullList.filter(lt => lt.LegislationVersion.toLowerCase() === 'amendment' && !filteredOptions.map(o => o.LegislationTextID).includes(lt.LegislationTextID));
            otherVersions = fullList.filter(lt => !filteredOptions.concat(otherAmendments).map(o => o.LegislationTextID).includes(lt.LegislationTextID));
            return [
                { label: 'Committee Amendments', options: filteredOptions },
                { label: !filteredOptions.length ? 'Amendments' : 'Other Amendments', options: otherAmendments },
                { label: !filteredOptions.length && !otherAmendments.length ? 'All Versions' : 'Other Versions', options: otherVersions }
            ];
        case '4230':
        case '4232':
        case '4278':
        case '4280':
            //member amendment (S codes correlate to Senator amends, H codes correlate to Delegate amends)
            if (eventCode[0] === 'S') {
                filteredOptions = fullList.filter(text => text.LegislationVersion.toLowerCase() === 'amendment' && text.Sponsor &&
                    (text.Sponsor.toLowerCase() === 'senate' || (text.Sponsor.toLowerCase() === 'member' && text.ChamberCode === 'S')));
                const otherAmendments = fullList.filter(lt => lt.LegislationVersion.toLowerCase() === 'amendment' && !filteredOptions.map(o => o.LegislationTextID).includes(lt.LegislationTextID));
                otherVersions = fullList.filter(lt => !filteredOptions.concat(otherAmendments).map(o => o.LegislationTextID).includes(lt.LegislationTextID));
                return [
                    { label: 'Senator Amendments', options: filteredOptions },
                    { label: !filteredOptions.length ? 'Amendments' : 'Other Amendments', options: fullList.filter(lt => lt.LegislationVersion.toLowerCase() === 'amendment' && !filteredOptions.map(o => o.LegislationTextID).includes(lt.LegislationTextID)) },
                    { label: !filteredOptions.length && !otherAmendments.length ? 'All Versions' : 'Other Versions', options: otherVersions }
                ]
            } else {
                filteredOptions = fullList.filter(text => text.LegislationVersion.toLowerCase() === 'amendment' && text.Sponsor &&
                    (text.Sponsor.toLowerCase().includes('house') || (text.Sponsor.toLowerCase() === 'member' && text.ChamberCode === 'H')));
                const otherAmendments = fullList.filter(lt => lt.LegislationVersion.toLowerCase() === 'amendment' && !filteredOptions.map(o => o.LegislationTextID).includes(lt.LegislationTextID))
                otherVersions = fullList.filter(lt => !filteredOptions.concat(otherAmendments).map(o => o.LegislationTextID).includes(lt.LegislationTextID));
                return [
                    { label: 'Delegate Amendments', options: filteredOptions },
                    { label: !filteredOptions.length ? 'Amendments' : 'Other Amendments', options: otherAmendments },
                    { label: !filteredOptions.length && !otherAmendments.length ? 'All Versions' : 'Other Versions', options: otherVersions }
                ]
            }
        case '4410':
        case '4640':
            //committee sub
            filteredOptions = fullList.filter(text => text.LegislationVersion.toLowerCase() === 'substitute' && text.Sponsor && text.Sponsor.toLowerCase() === 'committee');
            const otherSubs = fullList.filter(lt => lt.LegislationVersion.toLowerCase() === 'substitute' && !filteredOptions.map(o => o.LegislationTextID).includes(lt.LegislationTextID));
            otherVersions = fullList.filter(lt => !filteredOptions.concat(otherSubs).map(o => o.LegislationTextID).includes(lt.LegislationTextID));
            return [
                { label: 'Committee Substitutes', options: filteredOptions },
                { label: !filteredOptions.length ? 'Substitutes' : 'Other Substitutes', options: otherSubs },
                { label: !filteredOptions.length && !otherSubs.length ? 'All Versions' : 'Other Versions', options: otherVersions }
            ]
        case '4420':
        case '4450':
            //member sub (S codes correlate to Senator subs, H codes correlate to Delegate subs)
            if (eventCode[0] === 'S') {
                filteredOptions = fullList.filter(text => text.LegislationVersion.toLowerCase() === 'substitute' && text.Sponsor &&
                    (text.Sponsor.toLowerCase() === 'senate' || (text.Sponsor.toLowerCase() === 'member' && text.ChamberCode === 'S')));
                const otherSubs = fullList.filter(lt => lt.LegislationVersion.toLowerCase() === 'substitute' && !filteredOptions.map(o => o.LegislationTextID).includes(lt.LegislationTextID));
                otherVersions = fullList.filter(lt => !filteredOptions.concat(otherSubs).map(o => o.LegislationTextID).includes(lt.LegislationTextID));
                return [
                    { label: 'Senator Substitutes', options: filteredOptions },
                    { label: !filteredOptions.length ? 'Substitutes' : 'Other Substitutes', options: otherSubs },
                    { label: !filteredOptions.length && !otherSubs.length ? 'All Versions' : 'Other Versions', options: otherVersions }
                ]
            } else {
                filteredOptions = fullList.filter(text => text.LegislationVersion.toLowerCase() === 'substitute' && text.Sponsor &&
                    (text.Sponsor.toLowerCase().includes('house') || (text.Sponsor.toLowerCase() === 'member' && text.ChamberCode === 'H')));
                const otherSubs = fullList.filter(lt => lt.LegislationVersion.toLowerCase() === 'substitute' && !filteredOptions.map(o => o.LegislationTextID).includes(lt.LegislationTextID));
                otherVersions = fullList.filter(lt => !filteredOptions.concat(otherSubs).map(o => o.LegislationTextID).includes(lt.LegislationTextID));
                return [
                    { label: 'Delegate Substitutes', options: filteredOptions },
                    { label: !filteredOptions.length ? 'Substitutes' : 'Other Substitutes', options: otherSubs },
                    { label: !filteredOptions.length && !otherSubs.length ? 'All Versions' : 'Other Versions', options: otherVersions }
                ]
            }
        case '4641':
            //floor sub
            return fullList.filter(text => text.LegislationVersion.toLowerCase() === 'substitute' && (!text.Sponsor || !['committee', 'governor', 'conference'].includes(text.Sponsor.toLowerCase())))
        case '5432':
        case '5433':
        case '5434':
            //senate/house specific sub (H codes correlate to Senate subs, S codes correlate to House subs)
            if (eventCode[0] === 'S') {
                filteredOptions = fullList.filter(text => text.LegislationVersion.toLowerCase() === 'substitute' && ((text.Sponsor && text.Sponsor.toLowerCase().includes('house')) || (!text.Sponsor && text.ChamberCode === 'H')));
                const otherSubs = fullList.filter(lt => lt.LegislationVersion.toLowerCase() === 'substitute' && !filteredOptions.map(o => o.LegislationTextID).includes(lt.LegislationTextID));
                otherVersions = fullList.filter(lt => !filteredOptions.concat(otherSubs).map(o => o.LegislationTextID).includes(lt.LegislationTextID));
                return [
                    { label: 'House Substitutes', options: filteredOptions },
                    { label: !filteredOptions.length ? 'Substitutes' : 'Other Substitutes', options: otherSubs },
                    { label: !filteredOptions.length && !otherSubs.length ? 'All Versions' : 'Other Versions', options: otherVersions }
                ]
            } else {
                filteredOptions = fullList.filter(text => text.LegislationVersion.toLowerCase() === 'substitute' && ((text.Sponsor && text.Sponsor.toLowerCase() === 'senate') || (!text.Sponsor && text.ChamberCode === 'S')));
                const otherSubs = fullList.filter(lt => lt.LegislationVersion.toLowerCase() === 'substitute' && !filteredOptions.map(o => o.LegislationTextID).includes(lt.LegislationTextID));
                otherVersions = fullList.filter(lt => !filteredOptions.concat(otherSubs).map(o => o.LegislationTextID).includes(lt.LegislationTextID));
                return [
                    { label: 'Senate Substitutes', options: filteredOptions },
                    { label: !filteredOptions.length ? 'Substitutes' : 'Other Substitutes', options: otherSubs },
                    { label: !filteredOptions.length && !otherSubs.length ? 'All Versions' : 'Other Versions', options: otherVersions }
                ]
            }
        case '4643':
            //governor's sub
            return fullList.filter(text => text.LegislationVersion.toLowerCase() === 'substitute' && text.Sponsor.toLowerCase().includes('governor'))
        case '5602':
            //reenrolled
            return fullList.filter(text => text.LegislationVersion.toLowerCase() === 'reenrolled')
        case '4010':
        case '4011':
            //member
            return fullList; //return all the members passed down
        case '6013':
        case '6113':
        case '6213':
            //delegate/senator (H codes correlate to Delegates, S codes correlate to Senators)
            return fullList; //return all the members passed down, already filtered down to delegates in history form
        case '4610':
        case '5300':
        case '5310':
        case '5330':
        case '5350':
        case '5360':
        case '5370':
        case '5380':
        case '5800':
        case '5810':
        case '6060':
        case '7304':
        case '9600':
            //event
            return fullList; //return all the events passed down, already filtered in history form
        //not 100% certain what 'printed as...' (4620, 4622, 4630) is supposed to return
        case '4620':
        case '4622':
        case '4630':
        //not sure what '... bill reprinted' (4644, 4645, 4646) is supposed to return; there aren't any pickers in UI despite them being in the spreadsheet
        case '4644':
        case '4645':
        case '4646':
        //not sure what 'Bill text as passed ...' (5601, 5603) is supposed to return
        case '5601':
        case '5603':
        default:
            return fullList;
    }
}

export default getReferenceList;