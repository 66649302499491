import { createElement } from "react";

const getJsx = (jsonObject) => {
    // Do sanity checks. Make sure that the the param is actually an object
    if (typeof jsonObject !== 'object' || jsonObject === null) {
        console.error("object sent to be turned into jsx is not an object")
        return null;
    }
    return mapJson(jsonObject);

}


const mapJson = (object) => {
    if (typeof object === "string") {
        return object;
    }
    let attributeObject = {};
    if (object["Attributes"]) {
        for (var key in object["Attributes"]) {
            if (object["Attributes"].hasOwnProperty(key)) {
                switch (key) {
                    case ("Id"):
                        attributeObject.id = object["Attributes"][key];
                        break;
                    case ("Class"):
                        attributeObject.className = object["Attributes"][key];
                        break;
                    case ("Style"):
                        attributeObject.style = processStyle(object["Attributes"][key]);
                        break;
                    case ("Url"):
                        attributeObject.href = object["Attributes"].Url;
                        break;
                    case ("cellspacing"):
                        attributeObject['cellSpacing'] = object["Attributes"][key];
                        break;
                    case ("cellpadding"):
                        attributeObject['cellPadding'] = object["Attributes"][key];
                        break;
                    case ("border"):
                    case ("align"):
                        attributeObject[key] = object["Attributes"][key];
                        break;
                }
            }
        }
    }

    let newElements = [];
    if (object["Content"]) {
        let nestedElements = [];
        for (const c of object["Content"]) {
            nestedElements.push(mapJson(c));
        }
        newElements.push(createElement(object["TagName"], { ...attributeObject }, nestedElements));
    } else {
        newElements.push(createElement(object["TagName"], { ...attributeObject }));
    }
    return newElements
}

// turns the inline style string into an object that jsx can understand.
const processStyle = cssText => {
    const rule = cssText.replace(/\/\*(.|\s)*?\*\//g, " ").replace(/\s+/g, " ");
    let style = {}
    const properties = rule.split(";").map(o => o.split(":").map(x => x && x.trim()));
    for (const [property, value] of properties) {
        const objectName = property.toLowerCase().replace(/\W+\w/g, match => match.slice(-1).toUpperCase());
        if (value) {
            const urlChecker = value.replace(/url\s*\(/g, "").replace(/\)\s*$/g, "");
            try {
                new URL(urlChecker);
                // a url does not need to be in a jsx attribute. Throw them out for safety's sake.
                continue;
            } catch {
                // This is not a url. Carry on
            }
        }
        style[objectName] = value;
    }
    return style;
}


export default getJsx;