import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { developersActionCreators } from '../../../stores/lis-developers-store';
import { navActionCreators } from '../../../stores/lis-nav-store';
import { Link } from 'react-router-dom';
import ReactTable from 'react-table';

class PartnerGridComponent extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            isLoaded: false,
            partnerList: [],
            filtered: [],
            nameSearch: "",
            orgSearch: ""
        }

        this.handleSearchChange = this.handleSearchChange.bind(this);
        this.onFilteredChange = this.onFilteredChange.bind(this);
    }

    componentDidMount() {
        this.props.actions.getPartnerList()
            .then(() => {
                this.setState({
                    isLoaded: true,
                    partnerList: this.props.developers.partnerList
                })
            })
    }

    handleSearchChange(state, value) {
        this.setState({
            [state]: value
        }, () => {
            let filtered = [...this.state.filtered];
            if (filtered.find(x => x.id === state)) {
                filtered.splice(filtered.indexOf(x => x.id === state), 1, { id: state, value: value })
            } else {
                filtered.push({ id: state, value: value })
            }
            this.onFilteredChange(filtered)
        })
    }

    onFilteredChange(filtered) {
        this.setState({ filtered });
    }

    render() {
        const { partnerList, nameSearch, orgSearch } = this.state;

        return (
            <div>
                <h1>Partner Management</h1>
                <form className='dlas-forms'>
                    <div className="inner-grid five">
                        <div>
                            <label>Search by Name: </label>
                            <input
                                type="text"
                                value={nameSearch}
                                onChange={(e) => this.handleSearchChange("nameSearch", e.target.value)}
                            />
                        </div>
                        <div>
                            <label>Search by Organization: </label>
                            <input
                                type="text"
                                value={orgSearch}
                                onChange={(e) => this.handleSearchChange("orgSearch", e.target.value)}
                            />
                        </div>
                    </div>
                </form>
                {<ReactTable
                    loading={!this.state.isLoaded}
                    data={partnerList}
                    filtered={this.state.filtered}
                    onFilteredChange={this.onFilteredChange.bind(this)}
                    columns={[
                        {
                            Header: "Partner Name",
                            accessor: "ContactName",
                            id: "nameSearch",
                            width: 250,
                            filterMethod: (filter, row, column) => {
                                // convert to lowercase before comparing
                                let partnerName = row._original.ContactName.toLowerCase();
                                let nameFilter = this.state.nameSearch.toLowerCase();
                                if (partnerName.includes(nameFilter)) {
                                    return true
                                }
                            }
                        },
                        {
                            Header: "Partner Email",
                            accessor: "EmailAddress",
                            width: 250
                        },
                        {
                            Header: "Partner Organization",
                            accessor: "OrganizationName",
                            id: "orgSearch",
                            filterMethod: (filter, row, column) => {
                                // convert to lowercase before comparing
                                let partnerOrg = row._original.OrganizationName.toLowerCase();
                                let orgFilter = this.state.orgSearch.toLowerCase();
                                if (partnerOrg.includes(orgFilter)) {
                                    return true
                                }
                            }
                        },
                        {
                            Header: "Is Active",
                            accessor: "IsActive",
                            width: 80,
                            Cell: row => (
                                row.original.IsActive ? "True" : "False"
                            )
                        },
                        {
                            Header: "Edit",
                            accessor: "edit",
                            width: 40,
                            Cell: row => {
                                return (
                                    <Link to={`/partner-management/edit-partner/${row.original.PartnerID}`} className="button edit">Edit</Link>
                                )
                            }
                        },
                        {
                            Header: "All",
                            id: 'all',
                            className: "all-column",
                            headerClassName: "all-header",
                            width: 0,
                            resizable: false,
                            sortable: false,
                            Filter: () => { },
                            getProps: () => {
                                return {
                                    style: { padding: "0px" }
                                }
                            },
                            filterMethod: (filter, row, column) => {
                                // convert to lowercase before comparing
                                let partnerName = row.ContactName.toLowerCase();
                                let partnerOrg = row.OrganizationName.toLowerCase();
                                let nameFilter = this.state.nameSearch.toLowerCase();
                                let orgFilter = this.state.orgSearch.toLowerCase();
                                console.log(partnerName, partnerOrg, nameFilter, orgFilter)
                                if (partnerName.includes(nameFilter) || partnerOrg.includes(orgFilter)) {
                                    return true
                                }
                            },
                        }
                    ]}
                    defaultPageSize={10}
                    className="-striped -highlight"
                />}
            </div>
        )
    }
}

export default connect(
    (state) => {
        const { nav, developers } = state;
        return {
            nav,
            developers
        }
    },
    (dispatch) => {
        return {
            actions: bindActionCreators(Object.assign({}, developersActionCreators, navActionCreators), dispatch)
        }
    }
)(PartnerGridComponent)