import tinymce from 'tinymce/tinymce';

tinymce.PluginManager.add('linenumbers', function (editor) {
    const width = '40px'
    let linePositions = [];
    let bodyHeight = 0;
    const container = document.createElement('div');
    const lineNumberContainer = document.createElement('div');
    // NodeChange stops firing when text start to wrap. input does not fire when font size is changed.
    // Neither of these fire when a the tinymce react component changes state so SetContent is used as well.
    // So this function is ran regardless of which event happens
    editor.on('NodeChange input SetContent', _e => {
        const root = editor.contentDocument.activeElement;
        // The line numbers only need to be updated when the vertical size of the content changes. Any more would be unnecessary since the line numbers wouldn't change
        if (bodyHeight !== root.scrollHeight) {
            update();
            bodyHeight = root.scrollHeight;
        }
    });
    // Where text wraps have a chance of changing when the windows resizes 
    editor.on('ResizeWindow', _e => {
        update();
    });
    editor.on('init', _e => {
        editor.contentWindow.onscroll = () => scrollNumbers();
        //Style the tinymce body to allow room for the line numbers on the left side
        const root = editor.contentDocument.activeElement;
        root.style.margin = 0;
        root.style.marginLeft = width;
        bodyHeight = root.scrollHeight;
        // Style the line number container and container to allow for the content to scroll
        container.style.width = width;
        container.style.overflow = 'hidden';
        lineNumberContainer.style.position = 'relative'; // Needs to be relative or else the line numbers will not scroll
        container.appendChild(lineNumberContainer);
        editor.contentAreaContainer.appendChild(container);
        //Account for content already in the editor on initiation
        update();
    });
    editor.on('ScrollContent', _e => {
        update();
    });

    // Scroll the collection of line numbers with the tinymce window
    // This is so the line numbers will stay next to their line
    const scrollNumbers = () => {
        const contentWindow = editor.contentWindow;
        container.scrollTop = contentWindow.scrollY;
    }

    // Updates the line numbers to the correct amount and position
    // Only runs at tinymce initiation and when the tinymce body changes height
    const update = () => {
        const root = editor.contentDocument.activeElement;
        linePositions = [];

        //If line numbering is disabled by the user then escape and clear line numbers
        if (localStorage.getItem("showLineNumbers") === "false") {
            lineNumberContainer.innerText = '';
            return;
        }

        root.childNodes.forEach(rootChild => {
            // Since the content of the html is going to be changed to find where the lines end it is safer to make a copy of the editor body
            let clonedRootChild = rootChild.cloneNode(true);
            root.insertBefore(clonedRootChild, rootChild);
            // Hide the original editor body so that its height does not offset the line positions
            const oldDisplay = rootChild.style.display;
            rootChild.style.display = 'none';
            if(clonedRootChild.style.display != "none") {
                tagNewLines(clonedRootChild, root.scrollHeight * -1);
            }
            // Remove the node used to find line positions and show the original editor body again
            root.removeChild(clonedRootChild);
            rootChild.style.display = oldDisplay;
        });
        lineNumberContainer.style.height = editor.contentDocument.scrollingElement.scrollHeight + 'px';
        // Get the font size. Most browsers default it to 16px
        const bodyFontSize = window.getComputedStyle(root).getPropertyValue('font-size') || "16px";
        lineNumberContainer.innerText = ''

        let beginDrawing = false;

        for (let i = 0; i < linePositions.length; i++) {
            //Attempt to limit the amount of line numbers being drawn at any given time based on the scroll position of the window
            if ((editor.contentWindow.scrollY === 0 && i < 100)
                || (i < 225 && editor.contentWindow.scrollY < 7500)
                || (editor.contentWindow.scrollY / i > 28.5 && editor.contentWindow.scrollY / i < 33.5)) {
                let lineNumber = document.createElement('div');
                lineNumber.style.position = 'absolute'
                lineNumber.style.fontWeight = '700';
                lineNumber.style.fontSize = bodyFontSize;
                //Set the position of the line number. Need to account for how the position changes with the scroll height
                lineNumber.style.top = linePositions[i].top + editor.contentWindow.scrollY + 'px';
                lineNumber.innerText = i + 1;
                lineNumberContainer.appendChild(lineNumber)
                beginDrawing = true;
            } else if (beginDrawing) {
                //Exit the loop now that we're outside of the drawing range
                return;
            }
        }
    }

    // This is a recursive function.
    // It takes text nodes and converts them to an element that can be used to find the node's position
    // The function calls itself when reaching a non-text node so that it can traverse that node's children until it reaches a text node
    const tagNewLines = (node, oldLocation) => {
        let atBillNumber = false;
        let atGovernorsActions = false;
        node.childNodes.forEach(child => {
            if (["#text", "U", "S", "LI", "STRONG", "EM", "SPAN"].includes(child.nodeName)) {
                let testNode = document.createElement('span');
                //Surround each word with an <n> tag
                //There is not a way to find the location of each seperate word
                //So an element is created with the word as the content. That way the location of the element can be found
                let nodeVal = child.nodeValue || child.innerText;
                testNode.innerHTML = nodeVal.replace(/[^\s-]+/g, '<n>$&</n>');
                //Get rid of the text node and replace it with the new element 
                node.insertBefore(testNode, child);
                node.removeChild(child);
                testNode.childNodes.forEach(testChild => {
                    if (testChild.nodeName === "#text") {
                        // Leftover text nodes will always be either the whitespace between the words or dashes between the words
                        // Their location does not need to be checked because text is wrapped on words
                        return;
                    }
                    const rect = testChild.getBoundingClientRect()
                    const location = rect.bottom;
                    const height = rect.height;
                    if (testChild.nodeName === 'N') {
                        // Account for the height of an element because an element that is taller then the last element doesn't mean it is on a new line
                        if (location >= oldLocation + height) {
                            // This word is on a different line then the previous. Save the position so that it can be referenced later when placing the line numbers
                            linePositions.push(testChild.getBoundingClientRect());
                        }
                    }
                    oldLocation = location;
                });
            } else if (child.nodeName === "TR") {
                //Add a line for each table row
                linePositions.push(child.getBoundingClientRect());
            } else if (child.nodeName === 'BR') {
                // BR tags also count as new lines only when they are by themselves on a line
                if (child.parentNode.childNodes.length === 1) {
                    linePositions.push(child.getBoundingClientRect());
                }
            } else {
                //Continuously check for the bill number or the VA acts of assembly line until we find it
                //Don't start tagging lines until we reach one of these lines
                if (child.classList && child.classList.value == "billnumber" || child.innerText === "VIRGINIA ACTS OF ASSEMBLY -- CHAPTER") {
                    atBillNumber = true;
                    oldLocation = tagNewLines(child, oldLocation);
                    //Skip tagging the patron line
                } else if (atBillNumber === true && child.classList.value != "patronLine") {
                    //Traverse through this element's children until a text node is reached
                    oldLocation = tagNewLines(child, oldLocation)
                }
                //Continuously check for governor actions lines until we find it
                //Don't start tagging lines until after these lines
                if (child.classList && child.classList.value == "governorsactions") {
                    atGovernorsActions = true;
                } else if (atGovernorsActions === true && child.classList.value != "governorsactions") {
                    //Traverse through this element's children until a text node is reached
                    oldLocation = tagNewLines(child, oldLocation)
                }
                //Go into the table so we can tag the child <tr>s  
                if (child.nodeName === 'TBODY') {
                    oldLocation = tagNewLines(child, oldLocation)
                }
            }
        });
        // Return the last element's location to be compared against the next element's location
        return oldLocation;
    }
});