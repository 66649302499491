import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { billActionCreators } from '../../../stores/lis-legislation-store';
import { collectionActionCreators } from '../../../stores/lis-collection-store';
import queryString from 'query-string';
import moment from 'moment';
import Select from 'react-select';
import { voteActionCreators } from '../../../stores/lis-votes-store';
import { cancelRequest } from '../../../services/request.service';
import { navActionCreators } from '../../../stores/lis-nav-store';
import { sessionActionCreators } from '../../../stores/lis-session-store';

const criteriaTypeWeights = {
    "Introduced": 1,
    "Patron": 2,
    "Keyword": 3,
    "Committee": 4,
    "Status Change": 5,
    "Docket/Agenda": 6,
    "New Version": 7,
    "Impact Statements": 8,
}

const sortLegNumbers = (a, b) => {
    if (a && !b) {
        return 1;
    }

    if (!a && b) {
        return -1;
    }

    //chamber, type, number
    return a[0].localeCompare(b[0]) || a[1].localeCompare(b[1]) || parseInt(a.substr(2) - b.substr(2));
}

const LegEvent = props => {
    const { item, bill } = props;
    const [showLinks, toggleLinks] = useState(false);
    const [isLoading, toggleLoading] = useState(false);
    const [voteLinks, setVoteLinks] = useState('');
    const getVoteLinks = () => {
        if (voteLinks) {
            toggleLinks(!showLinks);
        } else {
            toggleLoading(true);
            props.getVoteLinks(item, res => {
                setVoteLinks(res);
                toggleLoading(false);
                toggleLinks(true);
            })
        }
    }
    const linkStyle = {
        fontSize: '1em',
        margin: '0 5px 0 0'
    };
    return <tr className='parent-tr slidedown-closed bill-details-table-row'>
        <td className="left">{moment(item.EventDate).format('l')}</td>
        <td className="left">{item.ChamberCode === 'H' && 'House'} {item.ChamberCode === "S" && "Senate"}</td>
        <td className="left">
            {item.Description}
            {voteLinks && showLinks &&
                <div className="link-row">
                    {voteLinks.map((link, index) =>
                        <div key={index}>
                            {link.VoteID ? link.IsPublic ? <Link to={`/vote-details/${bill.LegislationNumber}/${bill.SessionCode}/${link.VoteNumber}`}>Vote</Link> : <span>Vote Detail Pending</span> : ''}
                            {link.CommitteeID ? <Link to={`/session-details/${bill.SessionCode}/committee-information/${link.CommitteeNumber}/committee-details`}>{link.ParentCommitteeID ? 'Subcommittee' : 'Committee'}</Link> : ''}
                            {link.LegislationNumber && (link.SessionCode || item.SessionCode) ? <Link to={`/bill-details/${link.SessionCode || item.SessionCode}/${link.LegislationNumber}`}>Legislation</Link> : ''}
                        </div>
                    )}
                </div>
            }
        </td>
        <td className="button-container">
            {item.ReferenceID &&
                <React.Fragment>
                    {isLoading ?
                        <React.Fragment>
                            <label className="arrow-down-label"></label>
                            <span className="small-spinner"></span>
                        </React.Fragment>
                        :
                        <React.Fragment>
                            <button className={`${showLinks ? "arrow-up" : "arrow-down"}`} onClick={getVoteLinks}></button>
                        </React.Fragment>
                    }
                </React.Fragment>
            }
        </td>
    </tr>
}

const BillInfo = props => {
    const { reasonGrouping, bill, checked, isAdding, allBillsExpanded } = props;
    const [showInfo, toggleInfo] = useState(false);

    useEffect(() => {
        toggleInfo(allBillsExpanded);
    }, [allBillsExpanded])

    return (
        <div className="flex-row flex-start bill-theme">
            {!props.selectedCollection ? <div></div> :
                <div className="collection-buttons">
                    {collectionBillIndex !== -1 ?
                        <button type="button" onClick={deleteBillFromCollection} className='button remove'></button>
                        :
                        <button type="button" onClick={addBillToCollection} className='button add'></button>
                    }
                </div>
            }
            <div className='parent-li se-bill-search-result'>
                <div className="small-gap-grid two-col-sidebar-alt">
                    <div className="description notification">
                        <input disabled={isAdding} checked={checked || false} onChange={props.handleCheckbox} type="checkbox" />
                        <div className="docket-agenda-link-container">
                            <Link to={`/bill-details/${bill.SessionCode}/${bill.LegislationNumber}`}>{bill.LegislationNumber}</Link>
                            {reasonGrouping?.CriteriaType === "Docket/Agenda" && bill.CommitteeNumber && bill.CommitteeNumber.startsWith('H') && <a className="docket-agenda-link" href={`https://virginiageneralassembly.gov/house/agendas/agendaDates.php?id=${bill.CommitteeNumber}&ses=${bill.SessionCode.substr(2)}`}>(Agenda)</a>}
                            {reasonGrouping?.CriteriaType === "Docket/Agenda" && bill.CommitteeNumber && bill.CommitteeNumber.startsWith('S') && bill.ReferenceID && <Link className="docket-agenda-link" to={`/session-details/${bill.SessionCode}/committee-information/${bill.CommitteeNumber}/dockets/${bill.ReferenceID}`}>(Docket)</Link>}
                        </div>
                        <h3 className="small-text">{bill.LegislationDescription}</h3>
                    </div>
                    <div className="button-container">
                        <button className={showInfo ? 'arrow-up' : 'arrow-down'} onClick={() => toggleInfo(!showInfo)}>More info</button>
                    </div>
                    {showInfo &&
                        <div>
                            {bill.LegislationTextID && reasonGrouping?.CriteriaType === "Keyword" ?
                                <button
                                    className="button-link"
                                    onClick={() => props.pushWithKeywords(bill.SessionCode, bill.LegislationNumber, bill.DocumentCode, bill.WatchListID)}>
                                    View Keyword Hits for {bill.DocumentCode}</button>
                                : null}
                            <table className="line-table">
                                <tbody>
                                    {bill.LegislationEvents.map((item, index) =>
                                        <LegEvent
                                            key={index}
                                            item={item}
                                            bill={bill}
                                            getVoteLinks={props.getVoteLinks}
                                        />
                                    )}
                                </tbody>
                            </table>
                        </div>
                    }
                </div>
                <hr className="faded-line" />
            </div>
        </div >
    );
}

class NotificationManagement extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            isLoaded: false,
            errorMessage: '',
            notifications: [],
            watchlists: [],
            selectedWatchlists: [],
            checkedBills: [],
            isAdding: false,
            expandLatestHistory: false
        }

        this.getWatchlists = this.getWatchlists.bind(this);
        this.getVoteLinks = this.getVoteLinks.bind(this);
        this.handleCheckbox = this.handleCheckbox.bind(this);
        this.selectWatchlist = this.selectWatchlist.bind(this);
        this.addBillsToWatchlist = this.addBillsToWatchlist.bind(this);
        this.pushWithKeywords = this.pushWithKeywords.bind(this);
        this.expandLatestHistory = this.expandLatestHistory.bind(this);

        this.stickyHeader = React.createRef();
        this.notificationsRef = React.createRef();
    }

    getVoteLinks(item, callback) {
        const params = '?legislationEventID=' + item.LegislationEventID;
        this.props.actions.getVoteLink(params).then(() => {
            let links = [...this.props.votes.voteLinkResponse];
            let linkPromises = [];
            links.forEach(link => {
                if (link.CommitteeID) {
                    const cmtePromise = this.props.actions.getCommitteeById("?id=" + link.CommitteeID + "&sessionCode=" + vote.SessionCode)
                        .then(() => {
                            link.CommitteeNumber = this.props.committee.committeeByIdData.CommitteeNumber;
                        }).catch((err) => {
                            console.error(err);
                            // Don't care if it fails. That committee won't be visible
                            return Promise.resolve();
                        })
                    linkPromises.push(cmtePromise);
                }
                // If there are any vote links of type 'Legislation' then the legislation ID needs to be turned into a legislation number to form the link
                if (link.ReferenceType === "Legislation") {
                    const promise = this.props.actions.getBill(link.ReferenceID)
                        .then(() => {
                            link.LegislationNumber = this.props.bills.bill.LegislationNumber;
                            link.SessionCode = link.SessionCode || vote.SessionCode;
                            return Promise.resolve();
                        }).catch(err => {
                            console.error(err);
                            // Don't care if it fails. That legislation won't be visible
                            return Promise.resolve();
                        });
                    linkPromises.push(promise);
                }
            });
            Promise.all(linkPromises)
                .then(() => {
                    callback(this.props.votes.voteLinkResponse);
                });

        }).catch(err => {
            if (err === 'Aborted') {
                return;
            }
            console.error(err);
            callback(null);
        });
    }

    handleCheckbox(notificationIDs, selectAll) {
        let checkedBills = [...this.state.checkedBills];
        notificationIDs.forEach(notificationID => {
            let checkedBillIdx = checkedBills.findIndex(b => b.NotificationID === notificationID);
            if (checkedBillIdx > -1) {
                checkedBills[checkedBillIdx].checked = selectAll === true || !Boolean(checkedBills[checkedBillIdx].checked);
            } else {
                checkedBills.push({ NotificationID: notificationID, checked: true })
            }
        })
        this.setState({
            checkedBills: checkedBills
        });
    }

    selectWatchlist(val) {
        this.setState({
            selectedWatchlists: val
        });
    }

    addBillsToWatchlist() {
        this.setState({
            isAdding: true
        });
        let selectedWatchlists = [...this.state.selectedWatchlists];
        selectedWatchlists.forEach(wl => wl.WatchListLegislation = wl.WatchListLegislation || []);
        let billsFound = false;
        let duplicate = false;
        [...this.state.checkedBills].filter(c => c.checked).forEach((c) => {
            const bill = this.state.notifications.flatMap(n => n.Committees).flatMap(c => c.Reasons).flatMap(r => r.Bills).find(b => b.NotificationID === c.NotificationID);
            if (bill) {
                selectedWatchlists.forEach(wl => {
                    if (!wl.WatchListLegislation.find(b => b.LegislationID === bill.LegislationID && b.WatchListLegislationID && !b.DeletionDate)) {
                        billsFound = true;
                        wl.WatchListLegislation.push({
                            LegislationID: bill.LegislationID,
                            WatchListID: wl.WatchListID
                        });
                    } else {
                        duplicate = true;
                    }
                });
            }
        })
        if (!billsFound && !duplicate) {
            this.setState({
                isAdding: false
            });
            return;
        } else if (!billsFound && duplicate) {
            this.props.actions.makeToast([{ message: ([...this.state.checkedBills].filter(c => c.checked).length > 1 ? "These bills already exist" : "This bill already exists") + " in " + (selectedWatchlists.length > 1 ? "these watchlists" : "this watchlist"), type: "failure" }]);
            this.setState({
                isAdding: false
            });
            return;
        }

        this.props.actions.saveCollections({ WatchLists: selectedWatchlists })
            .then(() => {
                let selectedWatchlists = [...this.props.collection.collectionSave];
                let watchlists = [...this.state.watchlists];
                selectedWatchlists.forEach(wl => {
                    const savedWatchlistIndex = watchlists.findIndex(w => w.WatchListID === wl.WatchListID);
                    watchlists[savedWatchlistIndex] = wl;
                    wl.WatchListLegislation = wl.WatchListLegislation || [];
                })
                this.setState({
                    isAdding: false,
                    selectedWatchlists: selectedWatchlists,
                    watchlists: watchlists
                });
                this.props.actions.makeToast([{ message: "Save Successful", type: "success" }]);
            }).catch(err => {
                if (err === 'Aborted') {
                    return;
                }
                this.setState({
                    isAdding: false
                });
                console.error(err);
                this.props.actions.makeToast([{ message: "Save Failed", type: "failure" }]);
            });
    }

    organizeNotifications(notifications) {
        notifications.sort(a => a.CommitteeName === undefined ? -1 : 1);
        notifications.sort((a, b) => a.WatchListID - b.WatchListID || (a.CriteriaType !== b.CriteriaType && criteriaTypeWeights[a.CriteriaType] && criteriaTypeWeights[b.CriteriaType] && criteriaTypeWeights[a.CriteriaType] - criteriaTypeWeights[b.CriteriaType]) || sortLegNumbers(a.LegislationNumber, b.LegislationNumber));
        let organizedNotifications = new Notifications();
        let committeesArr = new Committees();
        let previousNotification = notifications[0];
        notifications.forEach(notification => {
            if (previousNotification.WatchListID !== notification.WatchListID) {
                const newNotificationContainer = new Notification(
                    [...committeesArr.NotificationGroupings],
                    previousNotification.Name,
                    previousNotification.Description,
                    previousNotification.WatchListID
                );
                organizedNotifications.notifications.push(newNotificationContainer);
                committeesArr = new Committees();
                const objName = notification.CommitteeName || "nonCommitteeBills";
                committeesArr.NotificationGroupings.push(new NotificationGrouping(objName, notification.CommitteeNumber, [new NotificationReason(notification.CriteriaType, notification.NotificationReason, [notification])]));
            } else {
                // Notification items are organized by watchlist but also organized by committee. The object name is the committee name which is used to display to the user what committee the notification comes from
                const objName = notification.CommitteeName || "nonCommitteeBills";
                if (!committeesArr.NotificationGroupings.find(cmte => cmte.CommitteeName === objName)) {
                    committeesArr.NotificationGroupings.push(new NotificationGrouping(objName, notification.CommitteeNumber, []));
                }
                let reasonObjIdx = committeesArr.NotificationGroupings.find(group => group.CommitteeName === objName).Reasons.findIndex(legListing => legListing.CriteriaType === notification.CriteriaType);
                if (reasonObjIdx < 0) {
                    committeesArr.NotificationGroupings.find(cmte => cmte.CommitteeName === objName).Reasons.push(new NotificationReason(notification.CriteriaType, notification.NotificationReason, [notification]));
                } else if (!committeesArr.NotificationGroupings.find(cmte => cmte.CommitteeName === objName).Reasons[reasonObjIdx].Bills.find(b => b.LegislationNumber === notification.LegislationNumber)) { //don't insert duplicate leg numbers
                    committeesArr.NotificationGroupings.find(cmte => cmte.CommitteeName === objName).Reasons[reasonObjIdx].Bills.push(notification)
                }
            }
            previousNotification = notification;
        });
        //If the object is not empty then that means not every element in the results have been added to a container
        //So, check to see if the object has keys and if so create another container
        if (committeesArr.NotificationGroupings.length > 0) {
            const newNotificationContainer = new Notification(
                [...committeesArr.NotificationGroupings],
                previousNotification.Name,
                previousNotification.Description,
                previousNotification.WatchListID
            );
            organizedNotifications.notifications.push(newNotificationContainer);
        }

        return organizedNotifications.notifications;
    }

    pushWithKeywords(sessionCode, legislationNumber, documentCode, watchlistId) {
        const selectedWatchlist = this.state.watchlists.find(w => w.WatchListID === watchlistId);
        if (selectedWatchlist) {
            const keywordsList = selectedWatchlist.NotificationCriterias.filter(c => c.CriteriaType === "Keyword");
            // Need to seperate each criteria value into an array of words. That is the purpose of the join followed by the split
            const keywordExpression = keywordsList.map(k => k.CriteriaValue).join(" ");
            const partsRegex = /(\().*?(\)+)|(").*?("+)|(').*?('+)|['a-zA-Z]*/g;
            const keywordParts = keywordExpression.match(partsRegex);
            let foundAnOperator = false;
            for (const keywordPart of keywordParts) {
                if (keywordPart && (keywordPart.toLowerCase() === "and" || keywordPart.toLowerCase() === "or")) { foundAnOperator = true; break; }
            }

            let sanitizedKeywords = [];
            var chars = { "(": "", ")": "", '"': "" };
            if (!foundAnOperator) {
                //need to behave like API to keep it consistent:
                //per API, no operators so just clean expression from single quote operators and treat it as single keyword
                let phraseToInsert = keywordExpression.replace(/[()"]/g, m => chars[m]);
                if (phraseToInsert.startsWith("'") && phraseToInsert.endsWith("'")) { phraseToInsert = phraseToInsert.substr(1, phraseToInsert.length - 2) }
                if (phraseToInsert) { sanitizedKeywords.push(phraseToInsert); }
            } else {
                for (const keywordPart of keywordParts) {
                    if (keywordPart && keywordPart.toLowerCase() != "and" && keywordPart.toLowerCase() != "or") {
                        let wordToInsert = keywordPart.replace(/[()"]/g, m => chars[m]);
                        if (wordToInsert.startsWith("'") && wordToInsert.endsWith("'")) { wordToInsert = wordToInsert.substr(1, wordToInsert.length - 2) }
                        if (wordToInsert) { sanitizedKeywords.push(wordToInsert); }
                    }
                }
            }
            window.open(`/bill-details/${sessionCode}/${legislationNumber}/text/${documentCode}?keywords=${encodeURIComponent(sanitizedKeywords.join(" "))}`, '_blank');
        }
    }

    componentDidMount() {
        const parsed = queryString.parse(this.props.location.search, { decode: true });
        if (parsed.date) {
            this.props.actions.getNotifications(`?notificationDate=${encodeURIComponent(parsed.date)}`)
                .then(() => {
                    const organizedNotifications = this.organizeNotifications(this.props.collection.notifications)
                    this.setState({
                        notifications: organizedNotifications,
                        isLoaded: true
                    }, () => {
                        if (this.stickyHeader.current && this.notificationsRef.current) {
                            const scrollTop = this.notificationsRef.current.getBoundingClientRect().top;
                            const self = this;
                            window.onscroll = function () {
                                if (window.scrollY >= scrollTop) {
                                    self.stickyHeader.current?.classList?.add('background-color');
                                } else {
                                    self.stickyHeader.current?.classList?.remove('background-color');
                                }
                            }
                        }
                    });
                }).catch(err => {
                    if (err === 'Aborted') {
                        return;
                    }
                    this.setState({
                        errorMessage: err.toString(),
                        isLoaded: true
                    });
                });
            // Get the user's watchlists
            if (!this.props.session.sessionList || !this.props.session.sessionList.length) {
                this.props.actions.getSessionList()
                    .then(() => {
                        this.setState({
                            sessionOptions: this.props.session.sessionList
                        }, () => {
                            this.getWatchlists();
                        })
                    }).catch(err => {
                        if (err === 'Aborted') {
                            return;
                        }
                        console.log(err)
                    });
            } else {
                this.setState({
                    sessionOptions: this.props.session.sessionList
                }, () => {
                    this.getWatchlists();
                })
            }
        } else {
            this.setState({ errorMessage: 'No date has been provided.', isLoaded: true })
        }
    }

    componentDidUpdate(prevProps) {
        if (prevProps.nav.session != this.props.nav.session && this.state.sessionOptions) {
            this.getWatchlists();
        }
    }

    getWatchlists() {
        const selectedSession = this.state.sessionOptions.find(s => s.SessionCode === this.props.nav.session);
        if (selectedSession) {
            this.props.actions.getCollections("?SessionID=" + selectedSession.SessionID)
                .then(() => {
                    this.setState({
                        watchlists: this.props.collection.collections
                    });
                }).catch(err => {
                    if (err === 'Aborted') {
                        return
                    }
                });
        }
    }

    componentWillUnmount() {
        cancelRequest();
    }

    expandLatestHistory() {
        this.setState({ expandLatestHistory: !this.state.expandLatestHistory })
    }

    render() {
        const { isLoaded, errorMessage, notifications, watchlists, selectedWatchlists, checkedBills, isAdding } = this.state;
        return (<div>
            {!isLoaded && !errorMessage &&
                <div className="center-spinner spinner"></div>
            }
            {isLoaded &&
                <div>
                    <h2>{Boolean(this.props.login.userClaims.claims.find(claim => claim.Scope === "Paid" && claim.Resource === "LegislationCollections" && claim.RoleName === "PaidLegislationCollectionsAuthor")) || Boolean(this.props.login.userClaims.resources.find(resource => resource === "PaidLegislationCollectionsAuthor" || resource === "All")) ? 'Watchlists - Notifications' : 'Watchlist - Notification'}</h2>
                    {
                        errorMessage ?
                            <h4>{errorMessage}</h4>
                            :
                            !notifications.length ?
                                <h4>You do not have any notifications for the provided date.</h4>
                                :
                                <>
                                    <div className="sticky" ref={this.stickyHeader}>
                                        <div className="inner-grid two align-center padding">
                                            <div>
                                                <button onClick={this.expandLatestHistory} type="button" className="button mobile-w-100 mobile-mb-10">{this.state.expandLatestHistory ? 'Hide' : 'Show'} Latest History</button>
                                            </div>
                                            <div className="inner-grid three-and-one">
                                                <Select
                                                    options={watchlists}
                                                    value={selectedWatchlists}
                                                    onChange={this.selectWatchlist}
                                                    isClearable
                                                    isMulti
                                                    getOptionLabel={opt => opt.Name}
                                                    getOptionValue={opt => opt.WatchListID}
                                                />
                                                <button onClick={this.addBillsToWatchlist} disabled={!selectedWatchlists || !selectedWatchlists.length || checkedBills.every(b => !b) || isAdding} type="button" className="button">{isAdding ? "Adding" : "Add"} Bills To Watchlist(s)</button>
                                            </div>
                                        </div>
                                    </div>
                                    <div ref={this.notificationsRef}>
                                        {notifications.map((notification, notificationIndex) =>
                                            <div key={notificationIndex}>
                                                {notification.Name &&
                                                    <Link className="notification-watchlist-name" to={`/bill-search?collection=${notification.WatchListID}`}><h2>{notification.Name}{notification.Description ? <small> - {notification.Description}</small> : ''}</h2></Link>
                                                }
                                                <ul className="slidedown-list">
                                                    {notification.Committees.map((committeeGrouping, committeeGroupingIndex) =>
                                                        <React.Fragment key={committeeGroupingIndex}>
                                                            {committeeGrouping.CommitteeName !== "nonCommitteeBills" &&
                                                                <h3>{committeeGrouping.CommitteeNumber ? '(' + committeeGrouping.CommitteeNumber[0] + ') ' : ''}{committeeGrouping.CommitteeName}</h3>
                                                            }
                                                            <div style={committeeGrouping.CommitteeName != "nonCommitteeBills" ? { paddingLeft: '1em' } : {}}>
                                                                {committeeGrouping?.Reasons?.map((reasonGrouping, reasonGroupingIndex) => {
                                                                    const selectAll = !reasonGrouping.Bills.map(b => b.NotificationID).find(n => !checkedBills.filter(c => c.checked).map(b => b.NotificationID).includes(n));
                                                                    return (
                                                                        <React.Fragment key={reasonGroupingIndex}>
                                                                            {reasonGrouping.Reason &&
                                                                                <h4>{reasonGrouping.Reason}</h4>
                                                                            }
                                                                            <div className="small-gap-grid two-col-sidebar-alt" style={{ marginBottom: '5px' }}>
                                                                                <div className="description notification" style={{ alignItems: 'center' }}>
                                                                                    <input disabled={isAdding} checked={selectAll} onChange={() => this.handleCheckbox(reasonGrouping.Bills.map(b => b.NotificationID), !selectAll)} type="checkbox" />
                                                                                    <span style={{ fontSize: '0.75em' }}>Select all</span>
                                                                                </div>
                                                                            </div>
                                                                            {reasonGrouping.Bills.map(((bill, billIndex) =>
                                                                                <BillInfo
                                                                                    reasonGrouping={reasonGrouping}
                                                                                    key={billIndex}
                                                                                    bill={bill}
                                                                                    checked={checkedBills.find(b => b.NotificationID === bill.NotificationID) && checkedBills.find(b => b.NotificationID === bill.NotificationID).checked}
                                                                                    isAdding={isAdding}
                                                                                    handleCheckbox={() => this.handleCheckbox([bill.NotificationID])}
                                                                                    getVoteLinks={this.getVoteLinks}
                                                                                    pushWithKeywords={this.pushWithKeywords}
                                                                                    allBillsExpanded={this.state.expandLatestHistory}
                                                                                />
                                                                            ))}
                                                                        </React.Fragment>
                                                                    )
                                                                }
                                                                )}
                                                            </div>
                                                        </React.Fragment>
                                                    )}
                                                </ul>
                                            </div>
                                        )}
                                    </div>
                                </>
                    }
                </div>
            }
        </div>)
    }
}

class Notifications {
    constructor(notifications) {
        this.notifications = notifications || [];
    }

    notifications;
}

class Notification {
    constructor(committees, name, description, watchlistID) {
        this.Committees = committees;
        this.Name = name;
        this.Description = description;
        this.WatchListID = watchlistID;
    }

    Committees;
    Name;
    Description;
    WatchListID;
}

class Committees {
    constructor(notificationGroupings) {
        this.NotificationGroupings = notificationGroupings || [];
    }

    NotificationGroupings;
}

class NotificationGrouping {
    constructor(committee, committeeNumber, reasons) {
        this.CommitteeName = committee;
        this.CommitteeNumber = committeeNumber;
        this.Reasons = reasons;
    }

    CommitteeName;
    CommitteeNumber;
    Reasons;
}

class NotificationReason {
    constructor(criteriaType, reason, bills) {
        this.CriteriaType = criteriaType;
        this.Reason = reason;
        this.Bills = bills;
    }

    CriteriaType;
    Reason;
    Bills;
}

export default connect(
    (state) => {
        const { collection, bills, votes, nav, session, login } = state;
        return {
            collection,
            bills,
            votes,
            nav,
            session,
            login
        }
    },
    (dispatch) => {
        return {
            actions: bindActionCreators(Object.assign({}, billActionCreators, collectionActionCreators, voteActionCreators, navActionCreators, sessionActionCreators), dispatch)
        }
    }
)(NotificationManagement)