import tinymce from 'tinymce/tinymce';

tinymce.PluginManager.add('agreedbutton', function (editor) {
    let intialValue = "AGREED";
    let hasFocus = false;
    editor.on("contextmenu", e => {
        // Right clicking the tinymce editor when the editor is not in focus does not add the cursor in the right area.
        // This is used to counteract that and place the cursor in the about the right spot
        // At the front of the clicked element
        if(!hasFocus) {
            const points = editor.contentDocument.elementsFromPoint(e.x, e.y);
            if(points.length > 0) {
                editor.selection.select(points[0], true);
                editor.selection.collapse(true);
            }
            hasFocus = true;
        }
    });
    // Since the contextmenu event fires after focus is gained the state of focus has to be manually controlled here.
    editor.on("click", () => hasFocus = true);
    editor.on("blur", () => hasFocus = false);

     // Create buttons in the toolbar to add the agreed to text
    editor.ui.registry.addButton('agreedbutton', {
        text:"Agreed",
        onAction: () => {
            editor.fire("contexttoolbar-show", {toolbarKey:"agreedinput"});
        }
    });
    // Create button in the right-click context menu to add the agreed to text
    editor.ui.registry.addMenuItem('agreedbuttoncontext', {
        text:"Agreed",
        onAction: () => {
            editor.fire("contexttoolbar-show", {toolbarKey:"agreedinput"});
        }
    });

    editor.ui.registry.addContextForm("agreedinput", {
        position: "selection",
        scope: "node",
        initValue: () => intialValue,
        launch: {
            type: 'contextformbutton',
            text: 'Submit'
        },
        commands: [{
            type: "contextformbutton",
            text: 'Submit',
            onAction: formApi => {
                const value = formApi.getValue();
                intialValue = value
                editor.insertContent(`<span class="agreed"> [${value}] </span>`);
                formApi.hide();
            }
        }]
    })
});