import tinymce from 'tinymce/tinymce';

tinymce.PluginManager.add('rejectedbutton', function (editor) {
    let intialValue = "REJECTED";
    let hasFocus = false;
    editor.on("contextmenu", e => {
        // Right clicking the tinymce editor when the editor is not in focus does not add the cursor in the right area.
        // This is used to counteract that and place the cursor in the about the right spot
        // At the front of the clicked element
        if(!hasFocus) {
            const points = editor.contentDocument.elementsFromPoint(e.x, e.y);
            if(points.length > 0) {
                editor.selection.select(points[0], true);
                editor.selection.collapse(true);
            }
            hasFocus = true;
        }
    });
    // Since the contextmenu event fires after focus is gained the state of focus has to be manually controlled here.
    editor.on("click", () => hasFocus = true);
    editor.on("blur", () => hasFocus = false);

    const getButton = (buttonText, intialText) => {
        return {
            type: "menuitem",
            text: buttonText,
            onAction: () => {
                intialValue = intialText;
                editor.fire("contexttoolbar-show", {toolbarKey:"rejectedinput"});
            }
        }
    }
    const items = [
        getButton("Rejected", "REJECTED"),
        getButton("Withdrawn", "WITHDRAWN"),
        getButton("Ruled Out Of Order", "RULED OUT OF ORDER"),
        getButton("Passed By", "PASSED BY"),
        getButton("Not Specific Or Severable", "NOT SPECIFIC OR SEVERABLE"),
        getButton("Ruled Not Germane", "RULED NOT GERMANE"),
    ]
     // Create buttons in the toolbar to add the rejected text
    editor.ui.registry.addMenuButton('rejectedbuttons', {
        text:"Rejected",
        fetch: callback => {
            callback(items)
        }
    });
    // Create button in the right-click context menu to add the rejected text
    editor.ui.registry.addNestedMenuItem('rejectedbuttonscontext', {
        text: "Rejected",
        getSubmenuItems: () => {
            return items;
        }
    });

    editor.ui.registry.addContextForm("rejectedinput", {
        position: "selection",
        scope: "node",
        initValue: () => intialValue,
        launch: {
            type: 'contextformbutton',
            text: 'Submit'
        },
        commands: [{
            type: "contextformbutton",
            text: 'Submit',
            onAction: formApi => {
                const value = formApi.getValue();
                editor.insertContent(`<span class="rejected"> [${value}] </span>`);
                formApi.hide();
            }
        }]
    })
});