import React from "react";
import ReactTable from "react-table";
import { Link } from 'react-router-dom';
import '../../../stylesheets/shared/react-table.css';
import moment from 'moment';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { committeeActionCreators } from '../../../stores/lis-committee-store';
import { authActionCreators } from '../../../stores/lis-auth-store';
import { navActionCreators } from '../../../stores/lis-nav-store';
import { cancelRequest } from '../../../services/request.service';

class CommitteeGridComponent extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            showCreateSuccess: false,
            showCreateFailure: false,
            isSubcommittee: false,
            error: null,
            isLoaded: false,
            disableChamberSelector: false,
            selectedError: null,
            filtered: [],
            currentDate: new Date(),
            filterChamber: '',
            filterAll: '',
            filterName: '',
            committeeNameInput: '',
            committeeNumberInput: '',
            chamberOptions: [
                { label: 'House', value: 'H' },
                { label: 'Senate', value: 'S' }
            ]
        };

        this.filterAll = this.filterAll.bind(this);
        this.committeeNameChange = this.committeeNameChange.bind(this);
        this.committeeNumberChange = this.committeeNumberChange.bind(this);
        this.filterChamber = this.filterChamber.bind(this);
        this.sendCreateDetails = this.sendCreateDetails.bind(this);
    };

    getCommittees() {
        var params = "PendingChange=" + true;
        this.props.actions.getPartnerCommittees(params).then(() => {
            //Get rid of the tables loading screen because the data is loaded
            this.setState({
                isLoaded: true
            })
            //set the default chamber to house or senate depending on the user's role
            let filterChamber = '';
            let disableChamberSelector = false;
            const scopes = this.props.login.userClaims.scopes;
            const houseScope = scopes.find(scope => scope === "House");
            const senateScope = scopes.find(scope => scope === "Senate");
            //Checks to see if the user doesn't have both roles (like if they weren't admin). If they have both roles then leave the preselected chamber as all
            if ((houseScope && !senateScope) || (!houseScope && senateScope)) {
                //Find which role the user has
                const scope = scopes.find(scope => scope === "House" || scope === "Senate");
                filterChamber = this.state.chamberOptions.find(chamber => chamber.label === scope).value;
                disableChamberSelector = true;
            } else {
                filterChamber = this.props.committee.committeeChamber;
            }
            this.setState({
                filterChamber: filterChamber,
                disableChamberSelector: disableChamberSelector
            });
        });
    }

    sendCreateDetails() {
        var newCommittee = {
            Name: this.state.committeeNameInput,
            CommitteeNumber: this.state.committeeNumberInput,
            ChamberCode: this.state.filterChamber,
            IsPublic: 0
        }
        this.props.actions.createNewCommittee(newCommittee).then(() => {
            this.setState({
                showCreateSuccess: true,
                showCreateFailure: false
            });
            setTimeout(function () {
                this.setState({
                    showCreateSuccess: false,
                    showCreateFailure: false
                });
            }.bind(this), 5000);
            this.getCommittees()
        }).catch(err => {
            if (err === 'Aborted') {
                return;
            }
            this.setState({
                showCreateSuccess: false,
                showCreateFailure: true
            })
            setTimeout(function () {
                this.setState({
                    showCreateSuccess: false,
                    showCreateFailure: false
                });
            }.bind(this), 5000);
            this.getCommittees()
        })
    }

    onFilteredChange(filtered) {
        if (filtered.length > 1 && this.state.filterAll.length) {
            // NOTE: this removes any FILTER ALL filter
            const filterAll = '';
            this.setState({ filtered: filtered.filter((item) => item.id !== 'all'), filterAll })
        }
        else
            this.setState({ filtered });
    }

    committeeNameChange(e) {
        const { value } = e.target;
        const committeeName = value;
        // NOTE: this completely clears any COLUMN filters
        this.setState({ committeeNameInput: committeeName });
    }

    committeeNumberChange(e) {
        const { value } = e.target;
        const committeeNumber = value;
        // NOTE: this completely clears any COLUMN filters
        this.setState({ committeeNumberInput: committeeNumber });
    }

    componentDidMount() {
        this.getCommittees()
    }

    componentWillUnmount() {
        cancelRequest();
    }

    filterAll(e) {
        const { value } = e.target;
        const filterAll = value;
        const filtered = [{ id: 'all', value: filterAll }];
        // NOTE: this completely clears any COLUMN filters
        this.setState({ filterAll, filtered });
    }

    filterChamber(e) {
        const { value } = e.target;
        const filterChamber = value;
        this.setState({ filterChamber }, () => {
            this.props.actions.toggleChamber(filterChamber);
        });
    }

    render() {
        const { committee } = this.props;
        //Filter the table if the user has selected a chamber
        const tableData = this.state.filterChamber ?
            this.props.committee.partnerCommittees.filter(committee => committee.ChamberCode === this.state.filterChamber)
            :
            this.props.committee.partnerCommittees;
        return (
            <div>
                <div className="header-half">
                    <h1>Committee Management</h1>
                </div>
                <div className="toolbar committee-bar">
                    <div className="dlas-forms">
                        {this.props.login.userClaims.claims.find(x => x.RoleName === "Admin") &&
                            <div>
                                <label>Chamber</label>
                                <select value={this.state.filterChamber} onChange={this.filterChamber} disabled={this.state.disableChamberSelector}>
                                    <option value="">All</option>
                                    <option value="S">Senate</option>
                                    <option value="H">House</option>
                                </select>
                            </div>
                        }
                        <div>
                            <label>Committee Name</label>
                            <input value={this.state.filterAll} onChange={this.filterAll} />
                        </div>
                    </div>
                </div>
                {committee.partnerCommitteesError &&
                    <p className="small-text message-error">{committee.partnerCommitteesError}</p>
                }
                {committee.createCommitteeMessage &&
                    <p className="small-text message-error">{committee.createCommitteeMessage}</p>
                }
                <ReactTable
                    filtered={this.state.filtered}
                    resizable={false}
                    defaultFilterMethod={(filter, row) =>
                        String(row[filter.id]) === filter.value}
                    defaultSorted={[
                        {
                            id: "Name"
                        }
                    ]}
                    data={tableData}
                    noDataText={committee.committeePartnerListError}
                    loading={!this.state.isLoaded}
                    ref={r => this.reactTable = r}
                    onFilteredChange={this.onFilteredChange.bind(this)}
                    columns={[
                        {
                            Header: "Committee Name",
                            columns: [
                                {
                                    Header: (
                                        <div className="dlas-forms">
                                            {!this.state.showCreateSuccess && !this.state.showCreateFailure &&
                                                <div className="committee-grid-header-inputs">
                                                    <input id="insert-new-committee" type="text" value={this.state.committeeNameInput} placeholder="Type new committee name and committee number, click create" onChange={this.committeeNameChange} disabled={this.state.isSubcommittee} />
                                                    <input id="insert-new-committee-number" type="text" value={this.state.committeeNumberInput} placeholder="Committee number" onChange={this.committeeNumberChange} />
                                                </div>
                                            }
                                            {this.state.showCreateSuccess &&
                                                <span className="insert-message">Committee was created successfully!</span>
                                            }
                                            {this.state.showCreateFailure &&
                                                <span className="insert-message fail">Committee creation failed</span>
                                            }
                                        </div>
                                    ),
                                    headerClassName: "header-insert",
                                    accessor: "Name",
                                    sortable: false,
                                    filterAll: true,
                                    Cell: row => {
                                        return (
                                            <span id={'cm-namecell-' + row.index}>{row.original.Name}</span>
                                        )
                                    }
                                }
                            ]
                        },
                        {
                            Header: "Service Start Date",
                            columns: [
                                {
                                    headerClassName: "header-insert",
                                    accessor: "PendingChange",
                                    sortable: false,
                                    filterAll: true,
                                    width: 150,
                                    Cell: row => {
                                        if (row.original.ServiceBeginDate === '0001-01-01T00:00:00') {
                                            return <span className="txt-greyed" id={'cm-pending-' + row.index}>Pending</span>
                                        }
                                        else {
                                            return <span id={'cm-pending-' + row.index}>{row.original.ServiceBeginDate ? moment(row.original.ServiceBeginDate).format('L') : ''}</span>
                                        }
                                    }
                                }
                            ]
                        },
                        {
                            Header: "Actions",
                            headerClassName: "submit-action",
                            columns: [

                                {
                                    Header: (<span><button id="submit-new-committee" onClick={this.sendCreateDetails} disabled={!this.state.filterChamber || !this.state.committeeNameInput || !this.state.committeeNumberInput} className="button">Create</button></span>),
                                    headerClassName: "header-action",
                                    accessor: "action",
                                    sortable: false,
                                    className: "action-column",
                                    width: 120,
                                    Cell: row => {
                                        return (
                                            <div className="committee-action-column">
                                                <Link id={'cm-edit-' + row.index} to={`/committee-management/edit-committee/${row.original.CommitteeID}`} className="button edit">Edit</Link>
                                                <Link id={'cm-flag-' + row.index} to={`/docket-management/${row.original.CommitteeID}`} className="button paper">Docket</Link>
                                                {row.original.PendingChange ? <span id={'cm-flag-' + row.index} className="button flag">Flag</span> : <span></span>}
                                            </div>
                                        )
                                    }
                                },
                                {
                                    Header: "All",
                                    id: 'all',
                                    className: "all-column",
                                    headerClassName: "all-header",
                                    width: 0,
                                    resizable: false,
                                    sortable: false,
                                    Filter: () => { },
                                    getProps: () => {
                                        return {
                                            style: { padding: "0px" }
                                        }
                                    },
                                    filterMethod: (filter, row, column) => {
                                        // convert to lowercase before comparing
                                        var lowercaseRow = row.Name.toLowerCase();
                                        var lowercaseFilter = filter.value.toLowerCase();
                                        if (lowercaseRow.includes(lowercaseFilter)) {
                                            return true
                                        }
                                    },
                                }
                            ]
                        },
                    ]}
                    defaultPageSize={15}
                    pageSizeOptions={[5, 10, 15, 20, 25, 50, 100]}
                    className="committee-table -striped -highlight"
                />
                <br />

            </div>
        );
    }
}

const CommitteeManagementGrid = connect(
    (state) => {
        const { login, committee, nav } = state;
        return {
            login,
            committee,
            nav
        }
    },
    (dispatch) => {
        return {
            actions: bindActionCreators(Object.assign({}, authActionCreators, committeeActionCreators, navActionCreators), dispatch)
        }
    }
)(CommitteeGridComponent)


export default CommitteeManagementGrid;