import { partnerRequest, publicRequest } from '../services/request.service';

const API_URL = window.env ? window.env.API_SERVER : '';
const NO_CONTENT = 2;
const REQUEST_FAILURE = 1;
const REQUEST_SUCCESS = 0;

const patronListRequest = "GET_PATRON_LIST_REQUEST";
const patronListSuccess = "GET_PATRON_LIST_SUCCESS";
const patronListFailure = "GET_PATRON_LIST_FAILURE";

const patronRolesSuccess = "GET_PATRON_ROLES_SUCCESS"
const patronRolesFailure = "GET_PATRON_ROLES_FAILURE"

const patronTypesSuccess = "GET_PATRON_TYPES_SUCCESS";
const patronTypesFailure = "GET_PATRON_TYPES_FAILURE";

const patronSaveSuccess = "PATRON_SAVE_SUCCESS";
const patronSaveFailure = "PATRON_SAVE_FAILURE";
const patronSaveRequest = "PATRON_SAVE_REQUEST";

const initialState = {
    senatePatronsList: [],
    housePatronsList: [],
    chiefPatronsList: [],
    patronList: [],
    patronRoles: [],
    patronRolesError: '',
    patronTypes: [],
    patronTypesError: '',
    patronSaveList: [],
    patronSaveError: ''
};

export const patronActionCreators = {
    requestPatronList: () => ({
        type: patronListRequest,
        isFetching: true
    }),

    receivePatronList: (housePatrons, senatePatrons, chiefPatrons, patronList) => ({
        type: patronListSuccess,
        isFetching: false,
        senatePatronsList: senatePatrons,
        housePatronsList: housePatrons,
        chiefPatronsList: chiefPatrons,
        patronList: patronList
    }),

    patronListError: message => ({
        type: patronListFailure,
        isFetching: false,
        message
    }),

    getPatronList: (params) => {
        return dispatch => {
            dispatch(patronActionCreators.requestPatronList());

            return publicRequest((API_URL || process.env.REACT_APP_LEGISLATION_PATRON_SERVICE) + '/LegislationPatron/api/GetLegislationPatronsByIdAsync/', 'GET', '', '', params)
                .then(result => {
                    if (result[0] === REQUEST_FAILURE) {
                        dispatch(patronActionCreators.patronsListError(result[1]))
                        return Promise.reject(result[1])
                    } else if (result[0] === REQUEST_SUCCESS) {
                        // Dispatch the success action
                        let patrons = result[1];
                        var housePatrons = [];
                        var senatePatrons = [];
                        var chiefPatrons = [];
                        let patronList = [];
                        for (var i = 0; i < patrons.Patrons.length; i++) {
                            if (patrons.Patrons[i].ChamberCode === 'H' && patrons.Patrons[i].DisplayName !== "(Chief Patron)") {
                                housePatrons.push(patrons.Patrons[i])
                            }
                            if (patrons.Patrons[i].ChamberCode === 'S' && patrons.Patrons[i].DisplayName !== "(Chief Patron)") {
                                senatePatrons.push(patrons.Patrons[i])
                            }
                            if (patrons.Patrons[i].DisplayName === "(Chief Patron)") {
                                chiefPatrons.push(patrons.Patrons[i])
                            }
                            patronList.push(patrons.Patrons[i])
                        }

                        dispatch(patronActionCreators.receivePatronList(housePatrons, senatePatrons, chiefPatrons, patronList))
                    } else if (result[0] === NO_CONTENT) {
                        var housePatrons = [];
                        var senatePatrons = [];
                        var chiefPatrons = [];
                        let patronList = [];
                        dispatch(patronActionCreators.receivePatronList(housePatrons, senatePatrons, chiefPatrons, patronList))
                    } else if (result.name === 'AbortError') {
                        return Promise.reject("Aborted");
                    }
                }).catch(err => Promise.reject(err));
        }
    },

    receivePatronRoles: responseObj => ({
        type: patronRolesSuccess,
        patronRoles: responseObj
    }),

    patronRolesError: message => ({
        type: patronRolesFailure,
        patronRolesError: message
    }),

    getPatronRoles: () => {
        return dispatch => {

            return publicRequest((API_URL || process.env.REACT_APP_LEGISLATION_PATRON_SERVICE) + '/LegislationPatron/api/getPatronRoleTypeListAsync/', 'GET', '', '', '')
                .then(result => {
                    if (result[0] === REQUEST_FAILURE) {
                        dispatch(patronActionCreators.patronRolesError(result[1]))
                        return Promise.reject(result[1])
                    } else if (result[0] === REQUEST_SUCCESS) {
                        dispatch(patronActionCreators.receivePatronRoles(result[1].PatronRolesList))
                    } else if (result[0] === NO_CONTENT) {
                        dispatch(patronActionCreators.patronRolesError('No Content'))
                        return Promise.reject('No Content')
                    } else if (result.name === 'AbortError') {
                        return Promise.reject("Aborted");
                    }
                }).catch(err => Promise.reject(err))
        }
    },

    receivePatronTypes: responseObj => ({
        type: patronTypesSuccess,
        patronTypes: responseObj
    }),

    patronTypesError: message => ({
        type: patronTypesFailure,
        patronTypesError: message
    }),

    getPatronTypes: (params) => {
        return dispatch => {

            return publicRequest((API_URL || process.env.REACT_APP_LEGISLATION_PATRON_SERVICE) + '/LegislationPatron/api/GetMemberPatronTypeListAsync/', 'GET', '', '', params)
                .then(result => {
                    if (result[0] === REQUEST_FAILURE) {
                        dispatch(patronActionCreators.patronTypesError(result[1]))
                        return Promise.reject(result[1])
                    } else if (result[0] === REQUEST_SUCCESS) {
                        dispatch(patronActionCreators.receivePatronTypes(result[1].MemberPatronTypes))
                    } else if (result[0] === NO_CONTENT) {
                        dispatch(patronActionCreators.patronTypesError([]))
                    } else if (result.name === 'AbortError') {
                        return Promise.reject("Aborted");
                    }
                }).catch(err => Promise.reject(err))
        }
    },

    requestPatronSave: () => ({
        type: patronSaveRequest,
        isFetching: true
    }),

    receivePatronSave: responseObj => ({
        type: patronSaveSuccess,
        isFetching: false,
        patronSaveList: responseObj
    }),

    patronSaveError: message => ({
        type: patronSaveFailure,
        isFetching: false,
        patronSaveError: message
    }),

    savePatrons: (body) => {
        return dispatch => {
            dispatch(patronActionCreators.requestPatronSave());
            let headers = {
                'content-type': "application/json; charset=utf-8"
            };

            return partnerRequest((API_URL || process.env.REACT_APP_LEGISLATION_PATRON_SERVICE) + '/LegislationPatron/api/SaveLegislationPatronAsync/', 'PUT', headers, body, '')
                .then(result => {
                    if (result[0] === REQUEST_FAILURE) {
                        dispatch(patronActionCreators.patronSaveError(result[1]))
                        return Promise.reject(result[1])
                    } else if (result[0] === REQUEST_SUCCESS) {
                        dispatch(patronActionCreators.receivePatronSave(result[1]))
                    } else if (result[0] === NO_CONTENT) {
                        dispatch(patronActionCreators.patronSaveError([]))
                    } else if (result.name === 'AbortError') {
                        return Promise.reject("Aborted");
                    }
                }).catch(err => Promise.reject(err))
        }
    }
};

export const reducer = (state, action) => {
    state = state || initialState;

    switch (action.type) {
        case patronListRequest:
            return Object.assign({}, state, {
                isFetching: true
            });
        case patronListSuccess:
            return Object.assign({}, state, {
                isFetching: false,
                senatePatronsList: action.senatePatronsList,
                housePatronsList: action.housePatronsList,
                chiefPatronsList: action.chiefPatronsList,
                patronList: action.patronList
            });
        case patronListFailure:
            return Object.assign({}, state, {
                isFetching: false,
                errorMessage: action.message
            });
        case patronRolesSuccess:
            return Object.assign({}, state, {
                patronRoles: action.patronRoles,
                patronRolesError: ''
            });
        case patronRolesFailure:
            return Object.assign({}, state, {
                patronRolesError: action.patronRolesError
            });
        case patronTypesSuccess:
            return Object.assign({}, state, {
                patronTypes: action.patronTypes,
                patronTypesError: ''
            });
        case patronTypesFailure:
            return Object.assign({}, state, {
                patronTypesError: action.patronTypesError
            });
        case patronSaveSuccess:
            return Object.assign({}, state, {
                patronSave: action.patronSave,
                patronSaveList: action.patronSaveList,
                patronTypesError: ''
            });
        case patronSaveFailure:
            return Object.assign({}, state, {
                patronSaveError: action.patronSaveError
            });

        default:
            return state;
    }
};
