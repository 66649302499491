import { partnerRequest, publicRequest } from '../services/request.service'

const API_URL = window.env ? window.env.API_SERVER : '';
const NO_CONTENT = 2;
const REQUEST_FAILURE = 1;
const REQUEST_SUCCESS = 0;

const getCollectionFailure = "GET_COLLECTION_FAILURE"
const getCollectionSuccess = "GET_COLLECTION_SUCCESS"

const getCollectionsFailure = "GET_COLLECTIONS_FAILURE"
const getCollectionsSuccess = "GET_COLLECTIONS_SUCCESS"

const saveCollectionsFailure = "SAVE_COLLECTIONS_FAILURE"
const saveCollectionsSuccess = "SAVE_COLLECTIONS_SUCCESS"

const getCriteriaTypesSuccess = "GET_CRITERIA_TYPES_SUCCESS"
const getCriteriaTypesFailure = "GET_CRITERIA_TYPES_FAILURE"

const notificationListSuccess = "NOTIFICATIONS_LIST_SUCCESS"
const notificationListFailure = "NOTIFICATIONS_LIST_FAILURE"

const initialState = {
    collection: '',
    collections: [],
    collectionsError: '',
    collectionSave: '',
    collectionSaveError: '',
    criteriaTypes: [],
    criteriaTypesError: '',
    notifications: [],
    notificationsError: ''
};

export const collectionActionCreators = {

    receiveCollection: resObj => ({
        type: getCollectionSuccess,
        collection: resObj
    }),

    collectionError: message => ({
        type: getCollectionFailure,
        collectionError: message
    }),

    getCollection: (params) => {
        return dispatch => {
            return partnerRequest((API_URL || process.env.REACT_APP_LEGISLATION_COLLECTION_API_URL) + '/LegislationCollections/api/GetLegislationCollectionByIdAsync', 'GET', '', '', params)
                .then(result => {
                    if (result[0] === REQUEST_FAILURE) {
                        dispatch(collectionActionCreators.collectionError(result[1]))
                        return Promise.reject(result[1])
                    } else if (result[0] === REQUEST_SUCCESS) {
                        dispatch(collectionActionCreators.receiveCollection(result[1].WatchLists))
                    } else if (result[0] === NO_CONTENT) {
                        dispatch(collectionActionCreators.receiveCollection([]))
                    } else if (result.name === 'AbortError') {
                        return Promise.reject("Aborted");
                    }
                }).catch(err => Promise.reject(err));
        }
    },

    receiveCollections: resObj => ({
        type: getCollectionsSuccess,
        collections: resObj
    }),

    collectionsError: message => ({
        type: getCollectionsFailure,
        collectionsError: message
    }),

    getCollections: (params) => {
        return dispatch => {
            return partnerRequest((API_URL || process.env.REACT_APP_LEGISLATION_COLLECTION_API_URL) + '/LegislationCollections/api/GetLegislationCollectionsAsync', 'GET', '', '', params)
                .then(result => {
                    if (result[0] === REQUEST_FAILURE) {
                        dispatch(collectionActionCreators.collectionsError(result[1]))
                        return Promise.reject(result[1])
                    } else if (result[0] === REQUEST_SUCCESS) {
                        dispatch(collectionActionCreators.receiveCollections(result[1].WatchLists.sort((a, b) => new Date(b.ModificationDate) - new Date(a.ModificationDate))))
                    } else if (result[0] === NO_CONTENT) {
                        dispatch(collectionActionCreators.receiveCollections([]))
                    } else if (result.name === 'AbortError') {
                        return Promise.reject("Aborted");
                    }
                }).catch(err => Promise.reject(err));
        }
    },

    receiveCollectionSave: resObj => ({
        type: saveCollectionsSuccess,
        collectionSave: resObj
    }),

    collectionSaveError: message => ({
        type: saveCollectionsFailure,
        collectionSaveError: message
    }),

    saveCollections: (body, useCreate) => {
        return dispatch => {
            let request = '';
            if (useCreate) {
                request = partnerRequest((API_URL || process.env.REACT_APP_LEGISLATION_COLLECTION_API_URL) + '/LegislationCollections/api/CreateLegislationCollectionAsync', 'POST', '', body, '')
            } else {
                request = partnerRequest((API_URL || process.env.REACT_APP_LEGISLATION_COLLECTION_API_URL) + '/LegislationCollections/api/SaveLegislationCollectionAsync', 'PUT', '', body, '')
            }
            return request
                .then(result => {
                    if (result[0] === REQUEST_FAILURE) {
                        dispatch(collectionActionCreators.collectionSaveError(result[1]))
                        return Promise.reject(result[1])
                    } else if (result[0] === REQUEST_SUCCESS) {
                        dispatch(collectionActionCreators.receiveCollectionSave(result[1]))
                    } else if (result[0] === NO_CONTENT) {
                        dispatch(collectionActionCreators.collectionSaveError('No Content'));
                        Promise.reject('No Content')
                    } else if (result.name === 'AbortError') {
                        return Promise.reject("Aborted");
                    }
                }).catch(err => Promise.reject(err));
        }
    },

    receiveCriteriaTypes: resObj => ({
        type: getCriteriaTypesSuccess,
        criteriaTypes: resObj
    }),

    criteriaTypesError: message => ({
        type: getCriteriaTypesFailure,
        criteriaTypesError: message
    }),

    getCriteriaTypes() {
        return dispatch => {
            return partnerRequest((API_URL || process.env.REACT_APP_LEGISLATION_COLLECTION_API_URL) + '/LegislationCollections/api/GetCriteriaTypesAsync', 'GET', '', '', '')
                .then(result => {
                    if (result[0] === REQUEST_FAILURE) {
                        dispatch(collectionActionCreators.criteriaTypesError(result[1]))
                        return Promise.reject(result[1])
                    } else if (result[0] === REQUEST_SUCCESS) {
                        dispatch(collectionActionCreators.receiveCriteriaTypes(result[1].CriteriaTypes))
                    } else if (result[0] === NO_CONTENT) {
                        dispatch(collectionActionCreators.criteriaTypesError('No Content'))
                    } else if (result.name === 'AbortError') {
                        return Promise.reject("Aborted");
                    }
                }).catch(err => Promise.reject(err));
        }
    },

    receiveNotifications: resObj => ({
        type: notificationListSuccess,
        notifications: resObj
    }),

    notificationsError: message => ({
        type: notificationListFailure,
        notificationsError: message
    }),

    getNotifications(params) {
        return dispatch => {
            return partnerRequest((API_URL || process.env.REACT_APP_NOTIFICATIONS_API_URL) + '/Notification/api/GetNotificationsAsync', 'GET', '', '', params)
                .then(result => {
                    if (result[0] === REQUEST_FAILURE) {
                        dispatch(collectionActionCreators.notificationsError(result[1]))
                        return Promise.reject(result[1])
                    } else if (result[0] === REQUEST_SUCCESS) {
                        dispatch(collectionActionCreators.receiveNotifications(result[1].Notifications))
                    } else if (result[0] === NO_CONTENT) {
                        dispatch(collectionActionCreators.receiveNotifications([]))
                    } else if (result.name === 'AbortError') {
                        return Promise.reject("Aborted");
                    }
                }).catch(err => Promise.reject(err));
        }
    }

};


export const reducer = (state, action) => {

    state = state || initialState;

    switch (action.type) {
        case getCollectionSuccess:
            return Object.assign({}, state, {
                collection: action.collection,
                collectionError: ''
            });
        case getCollectionFailure:
            return Object.assign({}, state, {
                collectionError: action.collectionError
            });
        case getCollectionsSuccess:
            return Object.assign({}, state, {
                collections: action.collections,
                collectionsError: ''
            });
        case getCollectionsFailure:
            return Object.assign({}, state, {
                collectionsError: action.collectionError
            });
        case saveCollectionsSuccess:
            return Object.assign({}, state, {
                collectionSave: action.collectionSave,
                collectionSaveError: ''
            })
        case saveCollectionsFailure:
            return Object.assign({}, state, {
                collectionSaveError: action.collectionSaveError
            })
        case getCriteriaTypesSuccess:
            return Object.assign({}, state, {
                criteriaTypes: action.criteriaTypes,
                criteriaTypesError: ''
            })
        case getCriteriaTypesFailure:
            return Object.assign({}, state, {
                criteriaTypesError: action.criteriaTypesError
            })
        case notificationListSuccess:
            return Object.assign({}, state, {
                notifications: action.notifications,
                notificationsError: ''
            })
        case notificationListFailure:
            return Object.assign({}, state, {
                notificationsError: action.notificationsError
            })
        default:
            return state
    }
};