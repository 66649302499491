import React from 'react';
import * as Fields from '../../lis-layout/components/lis-forms-component';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { userActionCreators } from '../../../stores/lis-user-store';
import { cancelRequest } from '../../../services/request.service';

class ForgotPassword extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            errorMessage: '',
            isFetching: false,
            paswordResetRequested: false,
            email: ''
        };

        this.requestPasswordReset = this.requestPasswordReset.bind(this);
        this.handleChange = this.handleChange.bind(this);
    }

    requestPasswordReset(e) {
        e.preventDefault();
        this.setState({
            errorMessage: '',
            isFetching: true
        });
        this.props.actions.requestPasswordReset('?emailAddress=' + encodeURIComponent(this.state.email))
            .then(() => {
                this.setState({
                    isFetching: false,
                    paswordResetRequested: true
                });
            }).catch(err => {
                if (err === 'Aborted') {
                    return;
                }
                this.setState({
                    isFetching: false,
                    errorMessage: err.toString()
                });
            })
    }

    handleChange(e) {
        this.setState({
            email: e.target.value
        });
    }

    componentWillUnmount() {
        cancelRequest();
    }

    render() {
        const { errorMessage, isFetching, paswordResetRequested, email } = this.state;
        if (paswordResetRequested) {
            return (
                <div className="center">
                    <h2>An email has been sent to your inbox with information on how to reset your password</h2>
                    <h3>Please check your email to complete password reset</h3>
                    <div className="small-text">Don't forget to check your spam folder</div>
                </div>)
        }
        return (
            <div className="dlas-forms">
                <h1>Request Lobbyist-in-a-Box Password Reset</h1>
                <hr className="faded-line" />
                <form>
                    <div>
                        <span className="error-message">{errorMessage}</span>
                        <div className="inner-grid three">
                            <div className="single-row">
                                <Fields.TextInput
                                    id="email"
                                    label="Email"
                                    placeholder="Email"
                                    onChange={this.handleChange}
                                    value={email}
                                />
                            </div>
                        </div>
                        <div>
                            {isFetching ?
                                <div><span className="spinner small-spinner"></span></div>
                                :
                                <button disabled={!email} onClick={this.requestPasswordReset} className="button" type="submit">Request password reset</button>
                            }
                        </div>
                    </div>
                </form>
            </div>
        );
    }
};

export default connect(
    (state) => {
        const { users } = state;
        return {
            users
        }
    },
    (dispatch) => {
        return {
            actions: bindActionCreators(Object.assign({}, userActionCreators), dispatch)
        }
    }
)(ForgotPassword);






