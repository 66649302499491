import React from 'react';
import renderHTML from 'react-render-html';

class AmendmentSection extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            shown: [],
            showAll: true,
            showInactiveAmendments: true
        }

        this.getAmendmentDescription = this.getAmendmentDescription.bind(this);
        this.showAmendment = this.showAmendment.bind(this);
        this.showAllAmendments = this.showAllAmendments.bind(this);
        this.toggleInactiveAmendments = this.toggleInactiveAmendments.bind(this);
    }

    getAmendmentDescription(amendment) {
        let descriptionAndDisposition = amendment.Description;

        if (!amendment.DocumentCode || amendment.DocumentCode === "") {
            return descriptionAndDisposition;
        }

        if (amendment.TextDispositionID === 12) {
            return descriptionAndDisposition + " Engrossed"
        }        

        if (amendment.TextDisposition === "Hold") {
            return descriptionAndDisposition;
        } else if (amendment.TextDisposition !== "Posted") {
            descriptionAndDisposition = `${amendment.Description} ${amendment.TextDisposition || ''}`;
        }

        if (amendment.IsReprint && !descriptionAndDisposition.includes("(Reprint)")) {
            descriptionAndDisposition = descriptionAndDisposition + " (Reprint)";
        }

        if (amendment.LDNumber) {
            descriptionAndDisposition = descriptionAndDisposition + " - " + amendment.LDNumber;
        }

        if(!amendment.IsActive) {
            descriptionAndDisposition = descriptionAndDisposition + " (INACTIVE)"
        }        

        return descriptionAndDisposition;
    }

    showAmendment(e, index) {
        if(e) {
            e.stopPropagation();
        }
        let shown = [...this.state.shown];
        shown[index] = !shown[index];
        this.setState({
            shown: shown
        });
    }

    showAllAmendments(e) {
        if(e) {
            e.stopPropagation();
        }
        const opposite = !this.state.showAll;
        let shown = new Array(this.props.amendments.length).fill(opposite);
        this.setState({
            showAll: opposite,
            shown: shown
        });
    }

    toggleInactiveAmendments() {
        this.setState(state => ({
            showInactiveAmendments: !state.showInactiveAmendments
        }));
    }

    componentDidUpdate(prevProps) {
        if(this.props.amendments !== prevProps.amendments) {
            this.setState({
                shown: [],
		        showAll: true,
                showInactiveAmendments: true
            });
        }
    }

    render() {
        const {shown, showAll, showInactiveAmendments} = this.state;
        let amendments = this.props.amendments;
        if(showInactiveAmendments) {
            amendments = amendments.filter(amendment => amendment.IsActive);
        }
        return (
            <div style={{ backgroundColor: 'white', padding: "10px" }}>
                <div className="flex-row">
                    <div className="flex-row flex-vertical-center">
                        {amendments.find(amendment => !amendment.IsActive) && 
                        <React.Fragment>
                            <input style={{margin: '0px 5px'}} type="checkbox" onChange={this.toggleInactiveAmendments} checked={!showInactiveAmendments}/>
                            <label>Show Inactive Amendments</label>
                        </React.Fragment>
                        }
                    </div>
                    <div>
                        {this.props.amendments.length > 0 &&
                            <button onClick={this.showAllAmendments} className="button-link" type="button">{showAll ? "Expand All +/-" : "Collapse All +/-"}</button>
                        }
                    </div>
                </div>
                {amendments.map((amendment, index) =>
                    <div key={index}>
                        <div>                            
                            <button onClick={e => this.showAmendment(e, index)} className={`data-url ${shown[index] ? "arrow-up" : "arrow-down"}`}></button>
                            <label className="small-text">{this.getAmendmentDescription(amendment)}</label>
                            {!amendment.IsActive && <label>{" (INACTIVE)"}</label>} 
                        </div>
                        {shown[index] &&
                            <div className="dlas-forms amendments-review">
                                {amendment.DraftText ? renderHTML(amendment.DraftText) : ''}       
                            </div>
                        }
                        {index !== amendments.length - 1 && <hr />}
                    </div>
                    )}
            </div>
        );
    }
}

export default AmendmentSection;
