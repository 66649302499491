import React from "react";
import { useMsal } from "@azure/msal-react";
import { loginRequest } from "../../../authConfig";

const HouseLoginButton = (props) => {
    const { instance } = useMsal();
    const user = props.username;
    const handleLogin = (loginType) => {
        if (loginType === "redirect") {
            loginRequest.loginHint = user
            instance.loginRedirect(loginRequest).catch(e => {
                console.log(e);
            });
        }
    }
    return (
        <button className="button secondary" type="submit" onClick={() => handleLogin("redirect")}>Login</button>
    );
}

export default HouseLoginButton;