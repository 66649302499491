import React from 'react';
import ReactTable from "react-table";

class TextList extends React.Component {

    render() {
        let nonAmendments = [];
        this.props.originalBillText.forEach((text, index) => {
            if (!["Amendment", "Conference Report"].includes(text.LegislationVersion) && (text.LegislationVersion !== "Gov Recommendation" || this.props.selectedLegType.Name === "Gov Recommendation")) {
                nonAmendments.push({ ...text, originalIndex: index })
            }
        });
        return (
            <div>
                <ReactTable
                    data={nonAmendments}
                    showPageSizeOptions={false}
                    showPagination={false}
                    showPageJump={false}
                    pageSize={nonAmendments.length}
                    getTrProps={(_state, rowInfo) => {
                        const original = rowInfo ? rowInfo.original : null;
                        return {
                            style: {
                                background: original && original.originalIndex === this.props.selectedBillTextIndex ? '#89ff89' : '',
                                cursor: 'pointer'
                            },
                            onClick: () => {
                                this.props.selectBillIndex(original.originalIndex);
                            }
                        }
                    }}
                    noDataText=""
                    columns={[
                        {
                            id: "checkbox",
                            className: "checkbox-column",
                            Cell: ({ original }) => this.props.changingBillIndex !== false && this.props.changingBillIndex === original.originalIndex ? <span className="small-spinner"></span> : <span className={original.originalIndex === this.props.selectedBillTextIndex ? "icon checkmark" : ""}></span>,
                            sortable: false,
                            filterable: false,
                            width: 45
                        },
                        {
                            Cell: ({ original }) => <span>{`${original.DocumentCode} ${!original.IsActive ? "INACTIVE" : ''} ${!original.IsPublic ? "NOT PUBLISHED" : ''}`}</span>
                        }
                    ]}
                    className="-striped -highlight"
                />
            </div>
        );

    }
}
export default TextList;