import React from "react";
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { navActionCreators } from "../../stores/lis-nav-store";
import { Link } from "react-router-dom";

class Unauthorized extends React.Component {
    render() {
        return (
            <div className="center">
                <h2>You do not have permission to view this page.</h2>
                <Link to="/home">Home</Link>
            </div>
        )
    }
}

export default connect(
    (state) => {
        const { nav } = state;
        return {
            nav
        }
    },
    (dispatch) => {
        return {
            actions: bindActionCreators(Object.assign({}, navActionCreators), dispatch)
        }
    }
)(Unauthorized)