import React from 'react';
import moment from 'moment';
import * as Fields from '../../../lis-shared/lis-layout/components/lis-forms-component';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';


// THE REORDER FUNCTION THAT FIRES WHEN ITEM IS DROPPED
const reorder = (list, startIndex, endIndex) => {
    const result = Array.from(list);
    const [removed] = result.splice(startIndex, 1);

    result.splice(endIndex, 0, removed);
    // Reorder the Sequence value too!
    result.forEach(function (element, index) {
        element.Sequence = index + 1
    });
    return result;
};

// STYLE TO SHOW WHEN ITEM IS BEING DRAGGED
const getItemStyle = (isDragging, draggableStyle, pendingDeletion) => ({
    // some basic styles to make the items look a bit nicer
    userSelect: 'none',
    // change background colour if dragging
    background: isDragging ? '#34495e' : 'white',
    color: isDragging ? 'white' : '#34495e',
    border: pendingDeletion ? 'red' : 'none',
    // styles we need to apply on draggables
    ...draggableStyle,
});

const getListStyle = isDraggingOver => ({
    background: isDraggingOver ? '#8b9fb3' : '#666666',
    width: '100%',
});


class PersonnelDraggableComponent extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            selectedPersonnel: ''
        };
        this.onDragEnd = this.onDragEnd.bind(this);
        this.onInsert = this.onInsert.bind(this);
        this.handleDropDownChange = this.handleDropDownChange.bind(this);
    }

    onInsert(staffRoleTypeID) {
        let selectedPersonnel = this.state.selectedPersonnel
        //Make sure that a member is selected before trying to add 
        if (selectedPersonnel.IdentityID) {
            delete selectedPersonnel.EffectiveEndDate;
            //set isPublic to false by default just in case the user doesn't want to publish just yet
            selectedPersonnel.IsPublic = false;
            //Set the correct committee ID
            selectedPersonnel.AffiliationID = this.props.affiliation;
            //Set the correct StaffRoleTypeID (1 = clerk, 2 = staff)
            selectedPersonnel.StaffRoleTypeID = staffRoleTypeID
            selectedPersonnel.Sequence = this.props.items.length + 1;
            this.props.onPersonnelChange([...this.props.items, selectedPersonnel]);
            this.setState({
                selectedPersonnel: ''
            })
        }
    }

    onRemove(rowID) {
        var tempArray = this.props.items

        tempArray.forEach(function (element) {
            if (element.StaffID === rowID) {
                //the element has already been marked to be deleted. un-mark it
                if (element.EffectiveEndDate) {
                    delete element.EffectiveEndDate;
                } else {
                    element.EffectiveEndDate = moment(new Date()).format("YYYY-MM-DDTHH:mm:ss.SS[Z]");
                }
            }
        })
        this.props.onPersonnelChange(tempArray)

    }

    handleDropDownChange = value => {
        //this function runs again after the member is inserted. It does not have a value so the dropdown text is replaced with the word 'Select'
        if (value) {
            var personnel = value;
            var personnelArray = {
                ...personnel,
                Sequence: this.props.items.length
            }
            this.setState({
                selectedPersonnel: personnelArray
            });
        } else {
            this.setState({
                selectedPersonnel: 'Select...'
            })
        }
    }

    onDragEnd(result) {
        // dropped outside the list
        if (!result.destination) {
            return;
        }
        const items = reorder(
            this.props.items,
            result.source.index,
            result.destination.index
        );

        this.props.onPersonnelChange(items);
    }


    render() {
        const { items, dropdownData, name } = this.props;
        const { selectedPersonnel } = this.state;
        const headers = ['Name', 'Phone', 'Email'];
        const keys = ['FullName', 'PhoneNumber', 'Email']
        return (
            <DragDropContext onDragEnd={this.onDragEnd}>
                <Droppable droppableId={`${name}-droppable`}>
                    {(provided, snapshot) => (
                        <table
                            className="dnd-grid-table personnel-grid"
                            ref={provided.innerRef}
                            style={getListStyle(snapshot.isDraggingOver)}
                        >
                            <thead className="dnd-grid-header" style={getListStyle(snapshot.isDraggingOver)}>
                                <tr>
                                    <th scope="col" id={name + '-grabber-column'}></th>
                                    <th scope="col" id={name + '-display-column'}>Display #</th>
                                    {headers.map((item, index) => (
                                        <th key={index} scope="col" id={name + '-' + item + '-column'} >{item}</th>
                                    ))}
                                    <th scope="col" id={name + '-actions-column'}>Actions</th>
                                </tr>
                            </thead>
                            <tbody>
                                <SelectMenu
                                    items={items}
                                    name={name}
                                    dropdownData={dropdownData}
                                    handleDropDownChange={this.handleDropDownChange}
                                    selectedPersonnel={selectedPersonnel}
                                    onInsert={this.onInsert.bind(this, this.props.staffRoleTypeID)}
                                />

                                {this.props.items.map((item, index) => (
                                    <Draggable key={`${item.StaffID}-${item.Sequence}`} draggableId={`${item.StaffID}-${item.Sequence}`} index={index}>
                                        {(provided, snapshot) => (
                                            <tr
                                                className={item.EffectiveEndDate ? 'pending-delete' : ''}
                                                id={name + '-' + item.StaffID}
                                                ref={provided.innerRef}
                                                {...provided.draggableProps}
                                                {...provided.dragHandleProps}
                                                style={getItemStyle(
                                                    snapshot.isDragging,
                                                    provided.draggableProps.style
                                                )}
                                            >
                                                <td><button id={name + '-' + item.StaffID + '-grabber-button'} className="button draggable">Drag and drop</button></td>
                                                <td id={name + '-' + item.StaffID + '-index'}>{index + 1}</td>

                                                {keys.map((key, index) => (
                                                    <td key={index} id={name + '-' + item.StaffID + '-' + key}>{item[key]}</td>
                                                ))}
                                                <td><button id={name + '-' + item.StaffID + '-delete-button'} type="button" onClick={this.onRemove.bind(this, item.StaffID)} className={item.EffectiveEndDate ? "button add" : "button remove"}>Delete</button></td>
                                            </tr>
                                        )}
                                    </Draggable>
                                ))}

                                {provided.placeholder}
                            </tbody>
                        </table>
                    )}
                </Droppable>
            </DragDropContext>
        );
    }
}

function SelectMenu(props) {

    let dropdown = [];
    //Remove personnel from dropdown that already belong to the committee
    props.dropdownData.forEach(function (element) {
        let isDuplicate = false;
        props.items.forEach(function (item) {
            if (item.IdentityID === element.IdentityID) {
                isDuplicate = true;
            }
        });
        if (!isDuplicate) {
            dropdown.push(element);
        }
    })

    return (
        <tr id={props.name + '-insert-row'} className="dnd-insert-row">
            <td></td>
            <td></td>
            <td><Fields.Dropdown
                className="grid-selector-dropdown"
                classNamePrefix="grid-selector-dropdown"
                value={props.selectedPersonnel}
                options={dropdown}
                id={props.name + '-dropdown'}
                onChange={props.handleDropDownChange} />
            </td>
            <td id={props.name + '-phone-td'}>{props.selectedPersonnel.PhoneNumber}</td>
            <td id={props.name + '-email-td'}>{props.selectedPersonnel.Email}</td>
            <td><button type="button" id={props.name + '-insert-button'} className="button" onClick={props.onInsert}>Insert</button></td>
        </tr>
    )
}

export default PersonnelDraggableComponent;