import React from 'react';

class ToastContainer extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            toasts: this.props.toasts,
            visible: false
        }

        this.dismiss = this.dismiss.bind(this);
    }

    componentDidUpdate(prevProps) {
        if (prevProps.toasts !== this.props.toasts) {
            this.setState({
                visible: true,
                dismissed: false
            }, function () {
                setTimeout(() => {
                    this.setState({
                        visible: false
                    })
                }, 3000)
            })
        }
    }

    dismiss() {
        this.setState({
            visible: false,
            dismissed: true
        })
    }

    render() {

        const { toasts } = this.props
        const visible = this.state.visible
        return (
            <div className={"toast-container " + (
                visible
                    ? this.props.toasts[0] && this.props.toasts[0].long
                        ? "show-long"
                        : 'show'
                    : this.props.toasts[0] && this.props.toasts[0].long
                        ? 'hide-long'
                        : 'hide'
            )}>
                <Toast toasts={toasts} dismiss={this.dismiss} visible={visible} dismissed={this.state.dismissed} />
            </div>
        )
    }
}
class Toast extends React.Component {
    render() {
        const items = this.props.toasts.map((item, index) => {
            return (
                <div key={index} className={`toast ${item.type}${this.props.visible ? '' : ` hide${item.long ? '-long' : ''}`}${this.props.dismissed ? ' dismissed' : ''}`}>
                    <div className='toast-inner'>
                        <p>{item.message}</p>
                        {item.long && <button className="icon cross" onClick={() => this.props.dismiss()} style={{ margin: "30px 0px 0px 15px" }} />}
                    </div>
                </div>
            );
        })

        return (<React.Fragment>{items}</React.Fragment>);
    }
}

export default ToastContainer;