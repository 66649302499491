import React from 'react';
import '../../../stylesheets/lis-member-management/member-management.css';
import Select from 'react-select';

const customStyles = {
    option: (base, state) => ({
        ...base,
        fontSize: '0.8em',
    }),
    control: (base) => ({
        ...base,
        padding: '1px',
        margin: 0,
        minHeight: 0,
        fontSize: '0.8em',
    }),
    singleValue: (base, state) => {
        return { ...base, };
    }
}

class UserForm extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            username: '',
            firstName: '',
            lastName: '',
            selectedDomain: '',
        }
        this.handleTextChange = this.handleTextChange.bind(this);
        this.handleDomainChange = this.handleDomainChange.bind(this);
        this.saveUser = this.saveUser.bind(this);
    }

    handleTextChange(key, val) {
        this.setState({
            [key]: val
        });
    }

    handleDomainChange(val) {
        this.setState({
            selectedDomain: val
        });
    }

    saveUser() {
        // Create the request object. The actual request is made in the parent component
        // The email address will be used as the username
        const userObj = {
            LoginID: this.state.username + "@" + this.state.selectedDomain.DomainName,
            FirstName: this.state.firstName,
            LastName: this.state.lastName,
            DomainID: this.state.selectedDomain.DomainID,
            IdentityID: this.props.personData.IdentityID,
            IsActive: true
        }
        this.props.saveUser(userObj);
    }

    componentDidMount() {
        if (this.props.personData.FirstName) {
            this.setState({
                firstName: this.props.personData.FirstName
            });
        }
        if (this.props.personData.LastName) {
            this.setState({
                lastName: this.props.personData.LastName
            });
        }
    }

    render() {
        const { domainOptions, isSaving } = this.props;
        const { username, selectedDomain, firstName, lastName } = this.state;
        return (
            <div>
                <h3>Connect a User</h3>
                <form className="dlas-forms user-forms">
                    <div className="multi-row fifth-even no-margin">
                        <div>
                            <label>Username</label>
                            <input type="text"
                                placeholder="Username"
                                value={username}
                                onChange={(e) => this.handleTextChange("username", e.target.value)}
                            />
                        </div>
                        <div>
                            <label>Directory</label>
                            <Select
                                styles={customStyles}
                                options={domainOptions}
                                value={selectedDomain}
                                onChange={this.handleDomainChange}
                            />
                        </div>
                    </div>
                    <div className="multi-row fifth-even no-margin">
                        <div>
                            <label>First Name</label>
                            <input type="text"
                                placeholder="First Name"
                                value={firstName}
                                onChange={(e) => this.handleTextChange("firstName", e.target.value)}
                            />
                        </div>
                        <div>
                            <label>Last Name</label>
                            <input type="text"
                                placeholder="Last Name"
                                value={lastName}
                                onChange={(e) => this.handleTextChange("lastName", e.target.value)}
                            />
                        </div>
                    </div>
                </form>
                <div className="button-bar">
                    <div>
                        <button onClick={this.props.toggleUserCreation} disabled={isSaving} type="button" className="button secondary">Close</button>
                    </div>
                    <div className="align-right">
                        <button onClick={this.saveUser} disabled={isSaving || (!username || !selectedDomain)} type="button" className="button">Save & Close</button>
                    </div>
                </div>
                <div>
                    <div className="input-feedback">{this.props.userError}</div>
                </div>
            </div>
        );
    }
}

export default UserForm;








