import React from 'react';
import '../../../stylesheets/lis-public-view/public-view.css';
import { Link } from 'react-router-dom';
import moment from 'moment';
import { withRouter } from 'react-router';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { navActionCreators } from '../../../stores/lis-nav-store';

class SessionInfo extends React.Component {
    constructor(props) {
        super(props);
        this.state = {};
        this.handleSessionChange = this.handleSessionChange.bind(this);
    }

    handleSessionChange(value) {
        if (window.env && window.env.HISTORICAL_DATA_REDIRECT && value && !value.IsDefault && value.LegacySessionID) {
            this.setState({
                historicalSessionRedirect: (redirect) => {
                    if (redirect) {
                        let a = document.createElement('a');
                        a.target = '_blank';
                        a.href = `https://legacylis.virginia.gov/cgi-bin/legp604.exe?ses=${value.SessionCode.substring(2)}&typ=lnk&val=${value.LegacySessionID}`;
                        document.body.appendChild(a);
                        a.click();
                        document.body.removeChild(a);
                    }
                    this.setState({ historicalSessionRedirect: false })
                }
            })
            return;
        }
        if (value && value.SessionCode) {
            this.props.actions.changeSession(value.SessionCode);
        }
    }

    render() {
        const session = this.props.selectedSession;
        const adjournment = session ? session.SessionEvents.find(event => event.DisplayName === "Adjournment") : null;
        const reconvene = session ? session.SessionEvents.find(event => event.DisplayName === "Reconvene") : null;
        const start = session ? session.SessionEvents.find(event => event.DisplayName === "Session Start") : null;

        let activeSessions = [];
        if (session.IsActive) {
            activeSessions = this.props.sessionList.filter(s => s.IsActive && s.SessionID !== session.SessionID);
        }
        return (
            <div>
                {this.state.historicalSessionRedirect &&
                    <div className="schedule-modal">
                        <div className="schedule-modal-content fit-content center-content">
                            <p>For historical data, please use <b>Legacy LIS</b>, available at <b><a href="https://legacylis.virginia.gov" target="_blank" rel="noreferrer">https://legacylis.virginia.gov</a></b>.</p>
                            <p>Click 'Go' to be taken to Legacy LIS, or 'Close' to remain here.</p>
                            <div className="inline-list">
                                <button className="button primary float-right" onClick={() => this.state.historicalSessionRedirect(true)}>Go</button>
                                <button className="button secondary float-right" onClick={() => this.state.historicalSessionRedirect(false)}>Close</button>
                            </div>
                            <br />
                        </div>
                    </div>
                }
                <h3 className="homepage-header">{session.SessionYear} {session.SessionType === "Special" ? session.DisplayName : "Session"} Information</h3>
                <hr />
                {!this.props.isLoaded ?
                    <div className="center-spinner spinner small-height" />
                    :
                    <div className="homepage-links">
                        <div className="homepage-content-group">
                            <span>Session dates: {moment(start ? start.ActualDate : '').format('MMMM D, YYYY')}</span>
                            {adjournment &&
                                <span> - {moment(adjournment ? adjournment.ActualDate : '').format('MMMM D, YYYY')}</span>
                            }
                        </div>
                        <div>
                            {reconvene &&
                                <React.Fragment>
                                    <span>Reconvened: </span>
                                    <span>{moment(reconvene.ActualDate).format('MMMM D, YYYY')}</span>
                                </React.Fragment>
                            }
                        </div>
                        <div>
                            <div className="homepage-content-group">
                                <Link to={`/session-details/${session.SessionCode}/member-information`}>Delegates & Senators</Link>
                            </div>
                            <div className="homepage-content-group">
                                <Link to={`/session-details/${session.SessionCode}/committee-information`}>Standing Committees</Link>
                            </div>
                            <div className="homepage-content-group">
                                <Link to={`/session-details/${session.SessionCode}/calendar`}>Daily Floor Calendars</Link>
                            </div>
                            <div className="homepage-content-group">
                                <Link to={`/session-details/${session.SessionCode}/communications`}>Communications</Link>
                            </div>
                        </div>
                        <div>
                            <div className="homepage-content-group">
                                <span>Minutes: </span>
                                {session.SessionYear < 2010
                                    ? <Link to={`/session-details/${session.SessionCode}/minutes-list/house`}>House</Link>
                                    : <a href={"https://virginiageneralassembly.gov/house/minutes/list.php?ses=" + session.SessionCode.toString().substr(2, 3)}>House</a>}
                                <span style={{ margin: '0px 5px' }}>|</span>
                                <Link to={`/session-details/${session.SessionCode}/minutes-list/senate`}>Senate</Link>
                            </div>
                            <div className="homepage-content-group">
                                <span>Video: </span>
                                <span>
                                    <a href="https://virginiageneralassembly.gov/house/chamber/chamberstream.php">House</a>
                                    <span style={{ margin: '0px 5px' }}>|</span>
                                    <a href="https://virginia-senate.granicus.com/ViewPublisher.php?view_id=3">Senate</a>
                                </span>
                            </div>
                            <div>
                                <div className="homepage-content-group">
                                    <span>Committee Statistics: </span>
                                    <Link to={`/session-details/${session.SessionCode}/statistics/committee/h`}>House</Link>
                                    <span> | </span>
                                    <Link to={`/session-details/${session.SessionCode}/statistics/committee/s`}>Senate</Link>
                                </div>
                                <div className="homepage-content-group">
                                    <span>Session Statistics: </span>
                                    <Link to={`/session-details/${session.SessionCode}/statistics/status`}>By Status</Link>
                                </div>
                            </div>

                        </div>
                    </div>
                }
                {this.props.isLoaded && activeSessions.length > 0 &&
                    <div className="homepage-content-group">
                        <span className="homepage-links">Concurrent Sessions:</span>
                        <div className="homepage-links">
                            {activeSessions.map(activeSession =>
                                <div key={activeSession.SessionID}>
                                    <a style={{ cursor: 'pointer' }} href={void (0)} onClick={() => this.handleSessionChange(activeSession)}>{activeSession.SessionYear} {activeSession.DisplayName}</a>
                                </div>
                            )}
                        </div>
                    </div>
                }
            </div>
        );
    }
}

export default connect(
    (state) => {
        const { nav } = state;
        return {
            nav
        }
    },
    (dispatch) => {
        return {
            actions: bindActionCreators(Object.assign({}, navActionCreators), dispatch)
        }
    }
)(withRouter(SessionInfo))