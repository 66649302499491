import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import { calendarActionCreators } from '../../../stores/lis-calendar-store';
import { navActionCreators } from '../../../stores/lis-nav-store';
import HouseForm from './lis-house-calendar-form';
import SenateForm from './lis-senate-calendar-form';

class CalendarFormContainerComponent extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            isLoading: true
        }

        this.navigate = this.navigate.bind(this);
    }

    componentDidMount() {
        let calendarBillParams = '?calendarId=' + this.props.match.params.calendarid;

        this.props.actions.getCalendarById(calendarBillParams, true)
            .then(() => {
                this.setState({
                    isLoading: false
                })
            })
            .catch(err => {
                if (err === 'Aborted') {
                    return;
                }
                this.setState({
                    isLoading: false,
                    message: "Failed to get data from server"
                })
                this.props.actions.makeToast([{ message: "Load Failed", type: "failure" }])
            });
    }

    navigate(path) {
        this.props.history.push(path);
    }

    render() {
        return (
            <div>
                {!this.state.isLoading ?
                    <React.Fragment>
                        {this.props.calendar.calendarGet.ChamberCode === "H"
                            ? <HouseForm
                                calendarData={this.props.calendar.calendarGet}
                                navigate={this.navigate}
                            />
                            : <SenateForm
                                calendarData={this.props.calendar.calendarGet}
                                navigate={this.navigate}
                            />
                        }
                    </React.Fragment>
                    : <div className="center-spinner spinner">Loading results...</div>
                }
            </div>
        )
    }
}

const CalendarFormContainer = connect(
    (state) => {
        const { calendar, nav } = state;
        return {
            calendar,
            nav
        }
    },
    (dispatch) => {
        return {
            actions: bindActionCreators(Object.assign({}, calendarActionCreators, navActionCreators), dispatch)
        }
    }
)(CalendarFormContainerComponent)

export default CalendarFormContainer;