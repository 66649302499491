import { partnerRequest, publicRequest } from '../services/request.service';

const API_URL = window.env ? window.env.API_SERVER : '';
const NO_CONTENT = 2;
const REQUEST_FAILURE = 1;
const REQUEST_SUCCESS = 0;
const graphToken = 'GRAPH_ID_TOKEN';

const contactReferencesSuccess = 'CONTACT_REF_LIST_SUCCESS';
const contactReferencesFailure = 'CONTACT_REF_LIST_FAILURE';

const contactSaveSuccess = 'CONTACT_SAVE_SUCCESS';
const contactSaveFailure = 'CONTACT_SAVE_FAILURE';

const personListSuccess = 'PERSON_LIST_SUCCESS';
const personListFailure = 'PERSON_LIST_FAILURE';

const personSuccess = 'PERSON_SUCCESS';
const personFailure = 'PERSON_FAILURE';

const personContactRequest = 'PERSON_CONTACT_REQUEST';
const personContactSuccess = 'PERSON_CONTACT_SUCCESS';
const personContactFailure = 'PERSON_CONTACT_FAILURE';

const userListSuccess = 'USER_LIST_SUCCESS';
const userListFailure = 'USER_LIST_FAILURE';

const userSuccess = 'USER_SUCCESS';
const userFailure = 'USER_FAILURE';

const userByEmailSuccess = 'USER_BY_EMAIL_SUCCESS';
const userByEmailFailure = 'USER_BY_EMAIL_FAILURE';

const userSaveSuccess = 'USER_SAVE_SUCCESS';
const userSaveFailure = 'USER_SAVE_FAILURE';

const userCreateSuccess = 'USER_CREATE_SUCCESS';
const userCreateFailure = 'USER_CREATE_FAILURE';

const newUserCreateSuccess = 'NEW_USER_CREATE_SUCCESS';
const newUserCreateFailure = 'NEW_USER_CREATE_FAILURE';

const personCreateSuccess = 'PERSON_CREATE_SUCCESS';
const personCreateFailure = 'PERSON_CREATE_FAILURE';

const personSaveSuccess = 'PERSON_SAVE_SUCCESS';
const personSaveFailure = 'PERSON_SAVE_FAILURE';

const dirListSuccess = 'DIRECTORIES_LIST_SUCCESS';
const dirListFailure = 'DIRECTORIES_LIST_FAILURE';

const userRegisterSuccess = 'USER_REGISTER_SUCCESS';
const userRegisterFailure = 'USER_REGISTER_FAILURE';

const userCreatePublicSuccess = 'USER_CREATE_PUBLIC_SUCCESS';
const userCreatePublicFailure = 'USER_CREATE_PUBLIC_FAILURE';

const requestPasswordResetSuccess = 'REQUEST_PASSWORD_RESET_SUCCESS';
const requestPasswordResetFailure = 'REQUEST_PASSWORD_RESET_FAILURE';

const passwordResetSuccess = 'PASSWORD_RESET_SUCCESS';
const passwordResetFailure = 'PASSWORD_RESET_FAILURE';

const changePasswordSuccess = 'CHANGE_PASSWORD_SUCCESS';
const changePasswordFailure = 'CHANGE_PASSWORD_FAILURE';

const initialState = {
    contactReferences: [],
    contactReferencesError: '',
    contactSave: '',
    contactSaveError: null,
    userList: [],
    userListError: '',
    personList: [],
    personListError: '',
    personContact: [],
    user: '',
    userError: null,
    userByEmail: '',
    userByEmailError: null,
    personCreate: '',
    personCreateError: null,
    dirList: [],
    dirListError: '',
    userSave: '',
    userSaveError: null,
    userCreate: '',
    userCreateError: null,
    newUserCreate: '',
    newUserCreateError: null,
    userCreatePublic: '',
    userCreatePublicError: '',
    userRegister: '',
    userRegisterError: '',
    requestPasswordReset: '',
    requestPasswordResetError: '',
    passwordReset: '',
    passwordResetError: '',
    changePassword: '',
    changePasswordError: ''
};

export const userActionCreators = {

    receiveContactReferences: (resObj) => ({
        type: contactReferencesSuccess,
        contactReferences: resObj,
    }),

    contactReferencesError: (message) => ({
        type: contactReferencesFailure,
        contactReferencesError: message
    }),

    getContactReferences: () => {
        return dispatch => {
            return publicRequest((API_URL || process.env.REACT_APP_CONTACT_API_URL) + '/Contact/api/getcontacttypereferencesasync/', 'GET', '', '', '')
                .then(result => {
                    if (result[0] === REQUEST_FAILURE) {
                        dispatch(userActionCreators.contactReferencesError(result[1]))
                        return Promise.reject(result[1])
                    } else if (result[0] === REQUEST_SUCCESS) {
                        dispatch(userActionCreators.receiveContactReferences(result[1].ContactTypeReferences))
                    } else if (result[0] === NO_CONTENT) {
                        dispatch(userActionCreators.contactReferencesError('No Content'))
                    } else if (result.name === 'AbortError') {
                        return Promise.reject("Aborted");
                    }
                }).catch(err => Promise.reject(err))
        }
    },

    receiveContactSave: (resObj) => ({
        type: contactSaveSuccess,
        contactSave: resObj,
    }),

    contactSaveError: (message) => ({
        type: contactSaveFailure,
        contactSaveError: message
    }),

    saveContact: (body) => {
        return dispatch => {
            return partnerRequest((API_URL || process.env.REACT_APP_CONTACT_API_URL) + '/Contact/api/savecontactasync/', 'POST', '', body, '')
                .then(result => {
                    if (result[0] === REQUEST_FAILURE) {
                        dispatch(userActionCreators.contactSaveError(result[1]))
                        return Promise.reject(result[1])
                    } else if (result[0] === REQUEST_SUCCESS) {
                        dispatch(userActionCreators.receiveContactSave(result[1]))
                    } else if (result[0] === NO_CONTENT) {
                        dispatch(userActionCreators.contactSaveError('No Content'))
                        return Promise.reject('No Content')
                    } else if (result.name === 'AbortError') {
                        return Promise.reject("Aborted");
                    }
                }).catch(err => Promise.reject(err))
        }
    },

    requestPersonContact: () => ({
        type: personContactRequest,
        personContact: [],
        isFetching: true
    }),

    receivePersonContact: (contact, message) => ({
        type: personContactSuccess,
        isFetching: false,
        personContact: contact,
        message
    }),

    personContactError: (message) => ({
        type: personContactFailure,
        isFetching: false,
        personConact: [],
        message
    }),

    getPersonContact: (params) => {
        return dispatch => {
            dispatch(userActionCreators.requestPersonContact());

            return publicRequest((API_URL || process.env.REACT_APP_CONTACT_API_URL) + '/Contact/api/getContactsByOwnerAsync/', 'GET', '', '', params)
                .then(result => {
                    if (result[0] === REQUEST_FAILURE) {
                        dispatch(userActionCreators.personContactError(result[1]))
                        return Promise.reject(result[1])
                    } else if (result[0] === REQUEST_SUCCESS) {
                        dispatch(userActionCreators.receivePersonContact(result[1]))
                    } else if (result[0] === NO_CONTENT) {
                        dispatch(userActionCreators.personContactError('No Content'))
                        return Promise.reject('No Content')
                    } else if (result.name === 'AbortError') {
                        return Promise.reject("Aborted");
                    }
                }).catch(err => Promise.reject(err))
        }
    },

    receivePersonList: (resObj) => ({
        type: personListSuccess,
        personList: resObj
    }),

    personListError: (message) => ({
        type: personListFailure,
        personListError: message
    }),

    getPersonList: (params) => {
        return dispatch => {
            let headers = {
                'content-type': "application/json; charset=utf-8"
            }
            return partnerRequest((API_URL || process.env.REACT_APP_PERSON_API_URL) + '/Person/api/getPartnerPersonListAsync?', 'GET', headers, '', params)
                .then(result => {
                    if (result[0] === REQUEST_FAILURE) {
                        dispatch(userActionCreators.personListError(result[1]))
                        return Promise.reject(result[1])
                    } else if (result[0] === REQUEST_SUCCESS) {
                        dispatch(userActionCreators.receivePersonList(result[1].Persons))
                    } else if (result[0] === NO_CONTENT) {
                        dispatch(userActionCreators.personListError('No Content'))
                        return Promise.reject('No Content')
                    } else if (result.name === 'AbortError') {
                        return Promise.reject("Aborted");
                    }
                }).catch(err => Promise.reject(err))
        }
    },

    receivePerson: (resObj) => ({
        type: personSuccess,
        person: resObj
    }),

    personError: (message) => ({
        type: personFailure,
        personError: message
    }),

    getPerson: (params) => {
        return dispatch => {
            let headers = {
                'content-type': "application/json; charset=utf-8"
            }
            return partnerRequest((API_URL || process.env.REACT_APP_PERSON_API_URL) + '/Person/api/getPartnerPersonByIdAsync/', 'GET', headers, '', params)
                .then(result => {
                    if (result[0] === REQUEST_FAILURE) {
                        dispatch(userActionCreators.personError(result[1]))
                        return Promise.reject(result[1])
                    } else if (result[0] === REQUEST_SUCCESS) {
                        dispatch(userActionCreators.receivePerson(result[1].Persons[0]))
                    } else if (result[0] === NO_CONTENT) {
                        dispatch(userActionCreators.personError('No Content'))
                        return Promise.reject('No Content')
                    } else if (result.name === 'AbortError') {
                        return Promise.reject("Aborted");
                    }
                }).catch(err => Promise.reject(err))
        }
    },

    receiveUserList: (resObj) => ({
        type: userListSuccess,
        userList: resObj,
    }),

    userListError: (message) => ({
        type: userListFailure,
        userListError: message
    }),

    getUserList: () => {
        return dispatch => {
            let headers = {
                'content-type': "application/json; charset=utf-8"
            }
            return partnerRequest((API_URL || process.env.REACT_APP_USER_API_URL) + '/User/api/ListUsersAsync/', 'GET', headers, '', '')
                .then(result => {
                    if (result[0] === REQUEST_FAILURE) {
                        dispatch(userActionCreators.userListError(result[1]))
                        return Promise.reject(result[1])
                    } else if (result[0] === REQUEST_SUCCESS) {
                        dispatch(userActionCreators.receiveUserList(result[1].UserProfiles))
                    } else if (result[0] === NO_CONTENT) {
                        dispatch(userActionCreators.userListError('No Content'))
                    } else if (result.name === 'AbortError') {
                        return Promise.reject("Aborted");
                    }
                }).catch(err => Promise.reject(err))
        }
    },

    receiveUser: (userdata) => ({
        type: userSuccess,
        user: userdata
    }),

    userError: (message) => ({
        type: userFailure,
        userError: message
    }),

    getUser: (params) => {
        return dispatch => {
            let headers = {
                'content-type': "application/json; charset=utf-8"
            }
            return partnerRequest((API_URL || process.env.REACT_APP_USER_API_URL) + '/User/api/GetUserAsync/', 'GET', headers, '', params)
                .then(result => {
                    if (result[0] === REQUEST_FAILURE) {
                        dispatch(userActionCreators.userError(result[1]))
                        return Promise.reject(result[1])
                    } else if (result[0] === REQUEST_SUCCESS) {
                        // Dispatch the success action
                        dispatch(userActionCreators.receiveUser(result[1].UserProfiles[0]))
                    } else if (result[0] === NO_CONTENT) {
                        dispatch(userActionCreators.userError('No Content'))
                        return Promise.reject('No Content')
                    } else if (result.name === 'AbortError') {
                        return Promise.reject("Aborted");
                    }
                }).catch(err => Promise.reject(err))
        }
    },

    receiveUserByEmail: (userByEmaildata) => ({
        type: userByEmailSuccess,
        userByEmail: userByEmaildata
    }),

    userByEmailError: (message) => ({
        type: userByEmailFailure,
        userByEmailError: message
    }),

    getUserByEmail: (params) => {
        return dispatch => {
            let headers = {
                'content-type': "application/json; charset=utf-8"
            }
            return partnerRequest((API_URL || process.env.REACT_APP_USER_API_URL) + '/User/api/GetUserByEmailAddressAsync/', 'GET', headers, '', params)
                .then(result => {
                    if (result[0] === REQUEST_FAILURE) {
                        dispatch(userActionCreators.userByEmailError(result[1]))
                        return Promise.reject(result[1])
                    } else if (result[0] === REQUEST_SUCCESS) {
                        // Dispatch the success action
                        dispatch(userActionCreators.receiveUserByEmail(result[1].UserProfiles[0]))
                    } else if (result[0] === NO_CONTENT) {
                        dispatch(userActionCreators.receiveUserByEmail(""))
                    } else if (result.name === 'AbortError') {
                        return Promise.reject("Aborted");
                    }
                }).catch(err => Promise.reject(err))
        }
    },

    receiveUserSave: (message) => ({
        type: userSaveSuccess,
        userSave: message
    }),

    userErrorSave: (message) => ({
        type: userSaveFailure,
        userSaveError: message
    }),

    saveUser: (body) => {
        return dispatch => {
            let headers = {
                'content-type': "application/json; charset=utf-8",
                'GraphAuthorization': 'Bearer ' + localStorage.getItem(graphToken)

            }
            return partnerRequest((API_URL || process.env.REACT_APP_USER_API_URL) + '/User/api/SaveUserAsync/', 'PUT', headers, body, '')
                .then(result => {
                    if (result[0] === REQUEST_FAILURE) {
                        dispatch(userActionCreators.userErrorSave(result[1]))
                        return Promise.reject(result[1])
                    } else if (result[0] === REQUEST_SUCCESS) {
                        dispatch(userActionCreators.receiveUserSave(result[1]))
                    } else if (result[0] === NO_CONTENT) {
                        dispatch(userActionCreators.userErrorSave('No Content'))
                        return Promise.reject('No Content')
                    } else if (result.name === 'AbortError') {
                        return Promise.reject("Aborted");
                    }
                }).catch(err => Promise.reject(err))
        }
    },

    receiveUserCreate: (message) => ({
        type: userCreateSuccess,
        userCreate: message
    }),

    userErrorCreate: (message) => ({
        type: userCreateFailure,
        userCreateError: message
    }),


    createUser: (body) => {
        return dispatch => {
            let headers = {
                'content-type': "application/json; charset=utf-8",
                'GraphAuthorization': 'Bearer ' + localStorage.getItem(graphToken)
            }
            return partnerRequest((API_URL || process.env.REACT_APP_USER_API_URL) + '/User/api/CreateUserAsync/', 'POST', headers, body, '')
                .then(result => {
                    if (result[0] === REQUEST_FAILURE) {
                        dispatch(userActionCreators.userErrorCreate(result[1]))
                        return Promise.reject(result[1])
                    } else if (result[0] === REQUEST_SUCCESS) {
                        // Dispatch the success action
                        dispatch(userActionCreators.receiveUserCreate(result[1]))
                    } else if (result[0] === NO_CONTENT) {
                        dispatch(userActionCreators.userErrorCreate('No Content'))
                        return Promise.reject('No Content')
                    } else if (result.name === 'AbortError') {
                        return Promise.reject("Aborted");
                    }
                }).catch(err => Promise.reject(err))
        }
    },

    receiveNewUserCreate: (message) => ({
        type: newUserCreateSuccess,
        newUserCreate: message
    }),

    newUserErrorCreate: (message) => ({
        type: newUserCreateFailure,
        newUserCreateError: message
    }),


    createNewUser: (body) => {
        return dispatch => {
            let headers = {
                'content-type': "application/json; charset=utf-8",
                'GraphAuthorization': 'Bearer ' + localStorage.getItem(graphToken)
            }
            return partnerRequest((API_URL || process.env.REACT_APP_USER_API_URL) + '/User/api/CreateNewUserAsync/', 'PUT', headers, body, '')
                .then(result => {
                    if (result[0] === REQUEST_FAILURE) {
                        dispatch(userActionCreators.newUserErrorCreate(result[1]))
                        return Promise.reject(result[1])
                    } else if (result[0] === REQUEST_SUCCESS) {
                        // Dispatch the success action
                        dispatch(userActionCreators.receiveNewUserCreate(result[1]))
                    } else if (result[0] === NO_CONTENT) {
                        dispatch(userActionCreators.newUserErrorCreate('No Content'))
                        return Promise.reject('No Content')
                    } else if (result.name === 'AbortError') {
                        return Promise.reject("Aborted");
                    }
                }).catch(err => Promise.reject(err))
        }
    },

    receivePersonCreate: (resObj) => ({
        type: personCreateSuccess,
        personCreate: resObj
    }),

    personCreateError: (message) => ({
        type: personCreateFailure,
        personCreateError: message
    }),


    createPerson: (body) => {
        return dispatch => {
            let headers = {
                'content-type': "application/json; charset=utf-8"
            }
            return partnerRequest((API_URL || process.env.REACT_APP_PERSON_API_URL) + '/Person/api/createpersonasync/', 'POST', headers, body, '')
                .then(result => {
                    if (result[0] === REQUEST_FAILURE) {
                        dispatch(userActionCreators.personCreateError(result[1]))
                        return Promise.reject(result[1])
                    } else if (result[0] === REQUEST_SUCCESS) {
                        // Dispatch the success action
                        dispatch(userActionCreators.receivePersonCreate(result[1]))
                    } else if (result[0] === NO_CONTENT) {
                        dispatch(userActionCreators.personCreateError('No Content'))
                        return Promise.reject('No Content')
                    } else if (result.name === 'AbortError') {
                        return Promise.reject("Aborted");
                    }
                }).catch(err => Promise.reject(err))
        }
    },

    receivePersonSave: (resObj) => ({
        type: personSaveSuccess,
        personSave: resObj
    }),

    personSaveError: (message) => ({
        type: personSaveFailure,
        personSaveError: message
    }),


    savePerson: (body) => {
        return dispatch => {
            let headers = {
                'content-type': "application/json; charset=utf-8"
            }
            return partnerRequest((API_URL || process.env.REACT_APP_PERSON_API_URL) + '/Person/api/savepersonasync/', 'POST', headers, body, '')
                .then(result => {
                    if (result[0] === REQUEST_FAILURE) {
                        dispatch(userActionCreators.personSaveError(result[1]))
                        return Promise.reject(result[1])
                    } else if (result[0] === REQUEST_SUCCESS) {
                        // Dispatch the success action
                        dispatch(userActionCreators.receivePersonSave(result[1]))
                    } else if (result[0] === NO_CONTENT) {
                        dispatch(userActionCreators.personSaveError('No Content'))
                        return Promise.reject('No Content')
                    } else if (result.name === 'AbortError') {
                        return Promise.reject("Aborted");
                    }
                }).catch(err => Promise.reject(err))
        }
    },

    receiveDirList: (resObj) => ({
        type: dirListSuccess,
        dirList: resObj
    }),

    dirListError: (message) => ({
        type: dirListFailure,
        dirListError: message
    }),


    getDirReferences: () => {
        return dispatch => {
            let headers = {
                'content-type': "application/json; charset=utf-8"
            }
            return publicRequest((API_URL || process.env.REACT_APP_USER_API_URL) + '/User/api/GetDirectoryReferencesAsync/', 'GET', headers, '', '')
                .then(result => {
                    if (result[0] === REQUEST_FAILURE) {
                        dispatch(userActionCreators.dirListError(result[1]))
                        return Promise.reject(result[1])
                    } else if (result[0] === REQUEST_SUCCESS) {
                        // Dispatch the success action
                        dispatch(userActionCreators.receiveDirList(result[1].Directories))
                    } else if (result[0] === NO_CONTENT) {
                        dispatch(userActionCreators.dirListError('No Content'))
                        return Promise.reject('No Content')
                    } else if (result.name === 'AbortError') {
                        return Promise.reject("Aborted");
                    }
                }).catch(err => Promise.reject(err))
        }
    },

    receiveRegisterUser: (message) => ({
        type: userRegisterSuccess,
        userRegister: message
    }),

    userErrorRegister: (message) => ({
        type: userRegisterFailure,
        userRegisterError: message
    }),

    registerUser: (params) => {
        return dispatch => {
            let headers = {
                'content-type': "application/json; charset=utf-8"
            }
            return publicRequest((API_URL || process.env.REACT_APP_USER_API_URL) + '/User/api/registerpublicuserasync/', 'POST', headers, '', params)
                .then(result => {
                    if (result[0] === REQUEST_FAILURE) {
                        dispatch(userActionCreators.userErrorRegister(result[1]))
                        return Promise.reject(result[1])
                    } else if (result[0] === REQUEST_SUCCESS) {
                        // Dispatch the success action
                        dispatch(userActionCreators.receiveRegisterUser(result[1]))
                    } else if (result[0] === NO_CONTENT) {
                        dispatch(userActionCreators.userErrorRegister('No Content'))
                        return Promise.reject('No Content')
                    } else if (result.name === 'AbortError') {
                        return Promise.reject("Aborted");
                    }
                }).catch(err => Promise.reject(err))
        }
    },

    receiveCreatePublicUser: (message) => ({
        type: userCreatePublicSuccess,
        userCreatePublic: message
    }),

    userErrorCreatePublic: (message) => {
        let error = message;
        try {
            error = JSON.parse(message);
            if (error.Value) { error = error.Value }
        } catch (err) {
            error = message;
        }
        if (typeof error === 'string' && error.startsWith('"') && error.endsWith('"')) {
            error = error.substr(1, error.length - 2);
        }

        return ({
            type: userCreatePublicFailure,
            userCreatePublicError: error
        })
    },

    createPublicUser: (body) => {
        return dispatch => {
            let headers = {
                'content-type': "application/json; charset=utf-8"
            }
            return publicRequest((API_URL || process.env.REACT_APP_USER_API_URL) + '/User/api/createpublicuserasync/', 'POST', headers, body, '')
                .then(result => {
                    if (result[0] === REQUEST_FAILURE) {
                        dispatch(userActionCreators.userErrorCreatePublic(result[1]))
                        return Promise.reject(result[1])
                    } else if (result[0] === REQUEST_SUCCESS) {
                        // Dispatch the success action
                        dispatch(userActionCreators.receiveCreatePublicUser(result[1]))
                    } else if (result[0] === NO_CONTENT) {
                        dispatch(userActionCreators.userErrorCreatePublic('No Content'))
                        return Promise.reject('No Content')
                    } else if (result.name === 'AbortError') {
                        return Promise.reject("Aborted");
                    }
                }).catch(err => Promise.reject(err))
        }
    },

    receiveRequestPasswordReset: (message) => ({
        type: requestPasswordResetSuccess,
        requestPasswordReset: message
    }),

    requestPasswordResetError: (message) => ({
        type: requestPasswordResetFailure,
        requestPasswordResetError: message
    }),

    requestPasswordReset: (params) => {
        return dispatch => {
            let headers = {
                'content-type': "application/json; charset=utf-8"
            }
            return publicRequest((API_URL || process.env.REACT_APP_USER_API_URL) + '/User/api/RequestResetUserPasswordAsync/', 'POST', headers, '', params)
                .then(result => {
                    if (result[0] === REQUEST_FAILURE) {
                        dispatch(userActionCreators.requestPasswordResetError(result[1]))
                        return Promise.reject(result[1])
                    } else if (result[0] === REQUEST_SUCCESS) {
                        // Dispatch the success action
                        dispatch(userActionCreators.receiveRequestPasswordReset(result[1]))
                    } else if (result[0] === NO_CONTENT) {
                        dispatch(userActionCreators.requestPasswordResetError('No Content'))
                        return Promise.reject('No Content')
                    } else if (result.name === 'AbortError') {
                        return Promise.reject("Aborted");
                    }
                }).catch(err => Promise.reject(err))
        }
    },

    receivePasswordReset: (message) => ({
        type: passwordResetSuccess,
        passwordReset: message
    }),

    passwordResetError: (message) => {
        let error = message;
        try {
            error = JSON.parse(message);
        } catch (err) {
            error = message;
        }
        return ({
            type: passwordResetFailure,
            passwordResetError: error
        })
    },

    resetPassword: (body) => {
        return dispatch => {
            let headers = {
                'content-type': "application/json; charset=utf-8"
            }
            return publicRequest((API_URL || process.env.REACT_APP_USER_API_URL) + '/User/api/ResetUserPasswordAsync/', 'POST', headers, body, '')
                .then(result => {
                    if (result[0] === REQUEST_FAILURE) {
                        dispatch(userActionCreators.passwordResetError(result[1]))
                        return Promise.reject(result[1])
                    } else if (result[0] === REQUEST_SUCCESS) {
                        // Dispatch the success action
                        dispatch(userActionCreators.receivePasswordReset(result[1]))
                    } else if (result[0] === NO_CONTENT) {
                        dispatch(userActionCreators.passwordResetError('No Content'))
                        return Promise.reject('No Content')
                    } else if (result.name === 'AbortError') {
                        return Promise.reject("Aborted");
                    }
                }).catch(err => Promise.reject(err))
        }
    },

    receiveChangePassword: (message) => ({
        type: changePasswordSuccess,
        changePassword: message
    }),

    changePasswordError: (message) => {
        let error = message;
        try {
            error = JSON.parse(message);
            if (error.Value) { error = error.Value }
        } catch (err) {
            error = message;
        }
        if (typeof error === 'string' && error.startsWith('"') && error.endsWith('"')) {
            error = error.substr(1, error.length - 2);
        }
        if (error === "Login failed (150)") {
            error = "Invalid current password.";
        }
        return ({
            type: changePasswordFailure,
            changePasswordError: error
        })
    },

    changePassword: (body) => {
        return dispatch => {
            let headers = {
                'content-type': "application/json; charset=utf-8"
            }
            return publicRequest((API_URL || process.env.REACT_APP_AUTH_API_URL) + '/Authentication/api/ChangePasswordAsync/', 'PUT', headers, body, '')
                .then(result => {
                    if (result[0] === REQUEST_FAILURE) {
                        dispatch(userActionCreators.changePasswordError(result[1]))
                        return Promise.reject(result[1])
                    } else if (result[0] === REQUEST_SUCCESS) {
                        // Dispatch the success action
                        dispatch(userActionCreators.receiveChangePassword(result[1]))
                    } else if (result[0] === NO_CONTENT) {
                        dispatch(userActionCreators.changePasswordError('No Content'))
                        return Promise.reject('No Content')
                    } else if (result.name === 'AbortError') {
                        return Promise.reject("Aborted");
                    }
                }).catch(err => Promise.reject(err))
        }
    }
};


export const reducer = (state, action) => {

    state = state || initialState;

    switch (action.type) {
        case contactReferencesSuccess:
            return Object.assign({}, state, {
                contactReferences: action.contactReferences,
                contactReferencesError: ''
            })
        case contactReferencesFailure:
            return Object.assign({}, state, {
                contactReferencesError: action.contactReferencesError
            })
        case contactSaveSuccess:
            return Object.assign({}, state, {
                contactSave: action.contactSave,
                contactSaveError: null
            })
        case contactSaveFailure:
            return Object.assign({}, state, {
                contactSaveError: action.contactSaveError
            })
        case personContactRequest:
            return Object.assign({}, state, {
                isFetching: true
            })
        case personContactSuccess:
            return Object.assign({}, state, {
                isFetching: false,
                personContact: action.personContact,
                message: ''
            })
        case personContactFailure:
            return Object.assign({}, state, {
                isFetching: false,
                personContact: action.personContact,
                message: action.message
            })
        case personListSuccess:
            return Object.assign({}, state, {
                personList: action.personList,
                personListError: ''
            })
        case personListFailure:
            return Object.assign({}, state, {
                personListError: action.personListError
            })
        case personSuccess:
            return Object.assign({}, state, {
                person: action.person,
                personError: ''
            })
        case personFailure:
            return Object.assign({}, state, {
                personError: action.personError
            })
        case userListSuccess:
            return Object.assign({}, state, {
                userList: action.userList,
                userListError: ''
            })
        case userListFailure:
            return Object.assign({}, state, {
                userListError: action.userListError
            })
        case userSuccess:
            return Object.assign({}, state, {
                user: action.user,
                userError: null
            })
        case userFailure:
            return Object.assign({}, state, {
                userError: action.userError
            })
        case userByEmailSuccess:
            return Object.assign({}, state, {
                userByEmail: action.userByEmail,
                userByEmailError: null
            })
        case userByEmailFailure:
            return Object.assign({}, state, {
                userByEmailError: action.userByEmailError
            })
        case userSaveSuccess:
            return Object.assign({}, state, {
                userSave: action.userSave,
                userSaveError: null
            })
        case userSaveFailure:
            return Object.assign({}, state, {
                userSaveError: action.userSaveError
            })
        case userCreateSuccess:
            return Object.assign({}, state, {
                userCreate: action.userCreate,
                userCreateError: null
            })
        case userCreateFailure:
            return Object.assign({}, state, {
                userCreateError: action.userCreateError
            })
        case newUserCreateSuccess:
            return Object.assign({}, state, {
                newUserCreate: action.newUserCreate,
                newUserCreateError: null
            })
        case newUserCreateFailure:
            return Object.assign({}, state, {
                newUserCreateError: action.newUserCreateError
            })
        case personCreateSuccess:
            return Object.assign({}, state, {
                personCreate: action.personCreate,
                personCreateError: null
            })
        case personCreateFailure:
            return Object.assign({}, state, {
                personCreateError: action.personCreateError
            })
        case personSaveSuccess:
            return Object.assign({}, state, {
                personSave: action.personSave,
                personSaveError: null
            })
        case personSaveFailure:
            return Object.assign({}, state, {
                personSaveError: action.personSaveError
            })
        case dirListSuccess:
            return Object.assign({}, state, {
                dirList: action.dirList,
                dirListError: ''
            })
        case dirListFailure:
            return Object.assign({}, state, {
                dirListError: action.dirListError
            })
        case userRegisterSuccess:
            return Object.assign({}, state, {
                userRegister: action.userRegister,
                userRegisterError: ''
            })
        case userRegisterFailure:
            return Object.assign({}, state, {
                userRegisterError: action.userRegisterError
            })
        case userCreatePublicSuccess:
            return Object.assign({}, state, {
                userCreatePublic: action.userCreatePublic,
                userCreatePublicError: ''
            })
        case userCreatePublicFailure:
            return Object.assign({}, state, {
                userCreatePublicError: action.userCreatePublicError
            })
        case requestPasswordResetSuccess:
            return Object.assign({}, state, {
                requestPasswordReset: action.requestPasswordReset,
                requestPasswordResetError: ''
            })
        case requestPasswordResetFailure:
            return Object.assign({}, state, {
                requestPasswordResetError: action.requestPasswordResetError
            })
        case passwordResetSuccess:
            return Object.assign({}, state, {
                passwordReset: action.passwordReset,
                passwordResetError: ''
            })
        case passwordResetFailure:
            return Object.assign({}, state, {
                passwordResetError: action.passwordResetError
            })
        case changePasswordSuccess:
            return Object.assign({}, state, {
                changePassword: action.changePassword,
                changePasswordError: ''
            })
        case changePasswordFailure:
            return Object.assign({}, state, {
                changePasswordError: action.changePasswordError
            })
        default:
            return state
    }
};