import { partnerRequest, publicRequest } from '../services/request.service'

const API_URL = window.env ? window.env.API_SERVER : '';
const NO_CONTENT = 2;
const REQUEST_FAILURE = 1;
const REQUEST_SUCCESS = 0;

const legislationIdsListRequest = "GET_LEGISLATION_IDS_LIST_REQUEST";
const legislationIdsListSuccess = "GET_LEGISLATION_IDS_LIST_SUCCESS";
const legislationIdsListFailure = "GET_LEGISLATION_IDS_LIST_FAILURE";

const billListByIdsRequest = "GET_BILL_LIST_BY_IDS_REQUEST";
const billListByIdsSuccess = "GET_BILL_LIST_BY_IDS_SUCCESS";
const billListByIdsFailure = "GET_BILL_LIST_BY_IDS_FAILURE";

const legislationTextsHitCountsRequest = "GET_LEGISLATION_TEXTS_HIT_COUNTS_REQUEST";
const legislationTextsHitCountsSuccess = "GET_LEGISLATION_TEXTS_HIT_COUNTS_SUCCESS";
const legislationTextsHitCountsFailure = "GET_LEGISLATION_TEXTS_HIT_COUNTS_FAILURE";

const billListRequest = "GET_BILL_LIST_REQUEST";
const billListSuccess = "GET_BILL_LIST_SUCCESS";
const billListFailure = "GET_BILL_LIST_FAILURE";

const mostFrequentBillListRequest = "GET_MOST_FREQUENT_BILL_LIST_REQUEST";
const mostFrequentBillListSuccess = "GET_MOST_FREQUENT_BILL_LIST_SUCCESS";
const mostFrequentBillListFailure = "GET_MOST_FREQUENT_BILL_LIST_FAILURE";

const billSummaryRequest = "GET_BILL_SUMMARY_REQUEST";
const billSummarySuccess = "GET_BILL_SUMMARY_SUCCESS";
const billSummaryFailure = "GET_BILL_SUMMARY_FAILURE";

const saveBillSummaryRequest = "SAVE_BILL_SUMMARY_REQUEST";
const saveBillSummarySuccess = "SAVE_BILL_SUMMARY_SUCCESS";
const saveBillSummaryFailure = "SAVE_BILL_SUMMARY_FAILURE";

const billHistorySuccess = "GET_BILL_HISTORY_SUCCESS";
const billHistoryFailure = "GET_BILL_HISTORY_FAILURE";

const billVersionRequest = "GET_BILL_VERSION_REQUEST";
const billVersionSuccess = "GET_BILL_VERSION_SUCCESS";
const billVersionFailure = "GET_BILL_VERSION_FAILURE";

const billNumbersSuccess = "GET_BILL_NUMBERS_SUCCESS";
const billNumbersFailure = "GET_BILL_NUMBERS_FAILURE";

const legislationClassesSuccess = "GET_LEGISLATION_CLASSES_SUCCESS";
const legislationClassesFailure = "GET_LEGISLATION_CLASSES_FAILURE";

const billStatusRefSuccess = "GET_BILL_STATUS_REF_SUCCESS";
const billStatusRefFailure = "GET_BILL_STATUS_REF_FAILURE";

const billStatusCategoryRefSuccess = "GET_BILL_STATUS_CATEGORY_REF_SUCCESS";
const billStatusCategoryRefFailure = "GET_BILL_STATUS_CATEGORY_REF_FAILURE";

const billEventRefSuccess = "GET_BILL_EVENT_REF_SUCCESS";
const billEventRefFailure = "GET_BILL_EVENT_REF_FAILURE";

const legislationEventsByIdSuccess = "GET_LEGISLATION_EVENTS_BY_ID_SUCCESS";
const legislationEventsByIdFailure = "GET_LEGISLATION_EVENTS_BY_ID_FAILURE";

const billTextSuccess = "GET_BILL_TEXT_SUCCESS";
const billTextFailure = "GET_BILL_TEXT_FAILURE";

const ihodAmendmentSaveSuccess = "IHOD_AMENDMENT_SAVE_SUCCESS";
const ihodAmendmentSaveFailure = "IHOD_AMENDMENT_SAVE_FAILURE";

const billVersionRefSuccess = "GET_BILL_VERSION_REF_SUCCESS";
const billVersionRefFailure = "GET_BILL_VERSION_REF_FAILURE";

const sponsorRefListSuccess = "GET_SPONSOR_REF_LIST_SUCCESS";
const sponsorRefListFailure = "GET_SPONSOR_REF_LIST_FAILURE";

const billSummaryRefSuccess = "GET_BILL_SUMMARY_REF_SUCCESS";
const billSummaryRefFailure = "GET_BILL_SUMMARY_REF_FAILURE";

const batchPublishSuccess = "GET_BATCH_PUBLISH_SUCCESS";
const batchPublishFailure = "GET_BATCH_PUBLISH_FAILURE";

const batchCompleteSuccess = "GET_BATCH_COMPLETE_SUCCESS";
const batchCompleteFailure = "GET_BATCH_COMPLETE_FAILURE";

const billTextSaveSuccess = "GET_BILL_TEXT_SAVE_SUCCESS";
const billTextSaveFailure = "GET_BILL_TEXT_SAVE_FAILURE";

const jsonHeadersSuccess = "GET_JSON_HEADERS_SUCCESS";
const jsonHeadersFailure = "GET_JSON_HEADERS_FAILURE";

const pdfPreviewSuccess = "GET_VERSION_PREVIEW_SUCCESS";
const pdfPreviewFailure = "GET_VERSION_PREVIEW_FAILURE";

const previewPdfFileSuccess = "PREVIEW_PDF_FILE_SUCCESS";
const previewPdfFileFailure = "PREVIEW_PDF_FILE_FAILURE";

const htmlPreviewSuccess = "GET_HTML_PREVIEW_SUCCESS";
const htmlPreviewFailure = "GET_HTML_PREVIEW_FAILURE";

const htmlFilePreviewSuccess = "GET_HTML_FILE_PREVIEW_SUCCESS";
const htmlFilePreviewFailure = "GET_HTML_FILE_PREVIEW_FAILURE";

const billSaveSuccess = "GET_BILL_SAVE_SUCCESS";
const billSaveFailure = "GET_BILL_SAVE_FAILURE";

const billFileGenSuccess = "GET_BILL_FILE_GEN_SUCCESS";
const billFileGenFailure = "GET_BILL_FILE_GEN_FAILURE";

const batchBillFileGenSuccess = "GET_BATCH_BILL_FILE_GEN_SUCCESS";
const batchBillFileGenFailure = "GET_BATCH_BILL_FILE_GEN_FAILURE";

const billManagementListSuccess = "GET_BILL_MANAGEMENT_LIST_SUCCESS";
const billManagementListFailure = "GET_BILL_MANAGEMENT_LIST_FAILURE";

const sessionBillsSuccess = "GET_BILL_BY_SESSION_SUCCESS";
const sessionBillsFailure = "GET_BILL_BY_SESSION_FAILURE";

const billEventsSuccess = "GET_BILL_EVENT_TYPES_SUCCESS";
const billEventsFailure = "GET_BILL_EVENT_TYPES_FAILURE";

const billEventSaveSuccess = "GET_BILL_EVENT_SAVE_SUCCESS";
const billEventSaveFailure = "GET_BILL_EVENT_SAVE_FAILURE";

const textListSuccess = "GET_TEXT_LIST_SUCCESS";
const textListFailure = "GET_TEXT_LIST_FAILURE";

const subjectListSuccess = "GET_SUBJECT_LIST_SUCCESS";
const subjectListFailure = "GET_SUBJECT_LIST_FAILURE";

const introDateListSuccess = "GET_INTRO_DATE_LIST_SUCCESS";
const introDateListFailure = "GET_INTRO_DATE_LIST_FAILURE";

const legislationAmendmentListSuccess = "GET_LEGISLATION_AMENDMENT_LIST_SUCCESS";
const legislationAmendmentListFailure = "GET_LEGISLATION_AMENDMENT_LIST_FAILURE";

const legislationDispositionsSuccess = "GET_LEGISLATION_DISPOSITION_LIST_SUCCESS";
const legislationDispositionsFailure = "GET_LEGISLATION_DISPOSITION_LIST_FAILURE";

const legislationStatusHistorySuccess = "GET_LEGISLATION_STATUS_HISTORY_SUCCESS";
const legislationStatusHistoryFailure = "GET_LEGISLATION_STATUS_HISTORY_FAILURE";

const deleteBlobFileSuccess = "DELETE_BLOB_FILE_SUCCESS";
const deleteBlobFileFailure = "DELETE_BLOB_FILE_FAILURE";

const sasURLSuccess = "GET_SAS_URL_SUCCESS";
const sasURLFailure = "GET_SAS_URL_FAILURE";

const billSuccess = "GET_BILL_BY_ID_SUCCESS";
const billFailure = "GET_BILL_BY_ID_FAILURE";

const uploadImpactStatementRequest = "UPLOAD_IMPACT_STATEMENT_REQUEST";

const initialState = {
    legislationIdsList: [],
    legislationIdsListPagination: null,
    billListByIds: [],
    billListByIdsPagination: null,
    legislationTextsHitCounts: [],
    billList: [],
    billListPagination: null,
    mostFrequentBillList: [],
    billManagementList: [],
    billManagementListError: '',
    searchCriteria: [],
    billDetails: [],
    billsByMember: [],
    billHistory: [],
    billVersion: [],
    billNumbers: [],
    billSummary: [],
    billSummarySave: [],
    billVersionRef: [],
    billVersionRefError: '',
    sponsorRefList: [],
    sponsorRefListError: '',
    billEventRef: '',
    billEventRefError: '',
    billText: [],
    billTextError: '',
    batchPublishSave: [],
    batchPublishError: '',
    batchCompleteSave: [],
    batchCompleteError: '',
    billTextSave: [],
    billTextSaveError: '',
    jsonHeaders: [],
    jsonHeadersError: '',
    pdfPreview: '',
    pdfPreviewError: '',
    sasUrl: '',
    sasUrlError: '',
    htmlPreview: '',
    htmlPreviewError: '',
    htmlFilePreview: '',
    htmlFilePreviewError: '',
    billFileGen: '',
    billFileGenError: '',
    batchBillFileGen: '',
    batchBillFileGenError: '',
    billStatusReferences: [],
    legislationClasses: [],
    billStatusCategoryReferences: [],
    sessionBills: [],
    sessionBillsError: '',
    billEvents: [],
    billEventsError: '',
    billEventSave: '',
    billEventSaveError: '',
    textList: [],
    textListError: '',
    subjectList: [],
    subjectListError: '',
    introDateList: [],
    introDateListError: '',
    legislationAmendmentList: [],
    legislationAmendmentListError: '',
    legislationDispositions: [],
    legislationDispositionsError: '',
    legislationStatusHistory: [],
    legislationStatusHistoryError: '',
    sasURLLink: '',
    sasURLLinkError: '',
    bill: '',
    billError: '',
    uploadImpactStatementResponse: '',
    uploadImpactStatementError: '',
    deleteBlobFileResponse: '',
    deleteBlobFileError: '',
};

export const billActionCreators = {

    receiveBill: responseObj => ({
        type: billSuccess,
        bill: responseObj
    }),

    billError: message => ({
        type: billFailure,
        billError: message
    }),

    getBill: (params, partner) => {
        return dispatch => {
            let request = '';
            if (partner) {
                request = partnerRequest((API_URL || process.env.REACT_APP_LEGISLATION_API_URL) + '/Legislation/api/GetPartnerLegislationByIdAsync/', 'GET', '', '', params)
            } else {
                request = publicRequest((API_URL || process.env.REACT_APP_LEGISLATION_API_URL) + '/Legislation/api/GetLegislationByIdAsync/', 'GET', '', '', params)
            }
            return request
                .then(result => {
                    if (result[0] === REQUEST_FAILURE) {
                        dispatch(billActionCreators.billError(result[1]))
                        return Promise.reject(result[1])
                    } else if (result[0] === REQUEST_SUCCESS) {
                        dispatch(billActionCreators.receiveBill(result[1].Legislations[0]))
                    } else if (result[0] === NO_CONTENT) {
                        dispatch(billActionCreators.billError('No Content'))
                        return Promise.reject('No Content')
                    } else if (result.name === 'AbortError') {
                        return Promise.reject("Aborted");
                    }
                }).catch(err => Promise.reject(err));
        }
    },

    receiveBillHistory: responseObj => ({
        type: billHistorySuccess,
        billHistory: responseObj
    }),

    billHistoryError: message => ({
        type: billHistoryFailure,
        billHistoryError: message
    }),

    getBillHistoryByBillID: (params, partner) => {
        return dispatch => {
            let request = '';

            let headers = {
                'cache-control': 'no-cache'
            }

            if (partner) {
                request = partnerRequest((API_URL || process.env.REACT_APP_LEGISLATION_EVENT_API_URL) + '/LegislationEvent/api/GetLegislationEventListAsync/', 'GET', headers, '', params)
            } else {
                request = publicRequest((API_URL || process.env.REACT_APP_LEGISLATION_EVENT_API_URL) + '/LegislationEvent/api/GetLegislationEventByLegislationIDAsync/', 'GET', headers, '', params)
            }
            return request
                .then(result => {
                    if (result[0] === REQUEST_FAILURE) {
                        dispatch(billActionCreators.billHistoryError(result[1]))
                        return Promise.reject(result[1])
                    } else if (result[0] === REQUEST_SUCCESS) {
                        // Dispatch the success action
                        dispatch(billActionCreators.receiveBillHistory(result[1].LegislationEvents))
                    } else if (result[0] === NO_CONTENT) {
                        dispatch(billActionCreators.receiveBillHistory([]))
                    } else if (result.name === 'AbortError') {
                        return Promise.reject("Aborted");
                    }
                }).catch(err => Promise.reject(err));
        }
    },

    //BILL VERSIONS
    requestBillVersion: () => ({
        type: billVersionRequest,
        isFetching: true
    }),

    receiveBillVersion: responseObj => ({
        type: billVersionSuccess,
        isFetching: false,
        billVersion: responseObj
    }),

    billVersionError: message => ({
        type: billVersionFailure,
        isFetching: false,
        errorMessage: message
    }),

    getBillVersionByBillNumber: (params) => {
        return dispatch => {
            dispatch(billActionCreators.requestBillVersion());

            return publicRequest((API_URL || process.env.REACT_APP_LEGISLATION_VERSION_API_URL) + '/LegislationVersion/api/GetLegislationVersionbyBillNumberAsync', 'GET', '', '', params)
                .then(result => {
                    if (result[0] === REQUEST_FAILURE) {
                        dispatch(billActionCreators.billVersionError(result[1]))
                        return Promise.reject(result[1])
                    } else if (result[0] === REQUEST_SUCCESS) {
                        // Dispatch the success action
                        dispatch(billActionCreators.receiveBillVersion(result[1].LegislationsVersion))
                    } else if (result[0] === NO_CONTENT) {
                        dispatch(billActionCreators.billVersionError('No Content'))
                    } else if (result.name === 'AbortError') {
                        return Promise.reject("Aborted");
                    }
                }).catch(err => Promise.reject(err));
        }
    },

    getBillVersionsByBillID: (params) => {
        return dispatch => {
            dispatch(billActionCreators.requestBillVersion());

            return publicRequest((API_URL || process.env.REACT_APP_LEGISLATION_VERSION_API_URL) + '/LegislationVersion/api/GetLegislationVersionbyLegislationIDAsync', 'GET', '', '', params)
                .then(result => {
                    if (result[0] === REQUEST_FAILURE) {
                        dispatch(billActionCreators.billVersionError(result[1]))
                        return Promise.reject(result[1])
                    } else if (result[0] === REQUEST_SUCCESS) {
                        // Dispatch the success action
                        dispatch(billActionCreators.receiveBillVersion(result[1].LegislationsVersion))
                    } else if (result[0] === NO_CONTENT) {
                        dispatch(billActionCreators.billVersionError('No Content'))
                    } else if (result.name === 'AbortError') {
                        return Promise.reject("Aborted");
                    }
                }).catch(err => Promise.reject(err));
        }
    },

    //BILL SUMMARIES
    requestBillSummary: () => ({
        type: billSummaryRequest,
        isFetching: true
    }),

    receiveBillSummary: responseObj => ({
        type: billSummarySuccess,
        isFetching: false,
        billSummary: responseObj
    }),

    billSummaryError: message => ({
        type: billSummaryFailure,
        isFetching: false,
        billSummary: [],
        errorMessage: message
    }),

    getBillSummary: (params, partner) => {

        return dispatch => {
            dispatch(billActionCreators.requestBillSummary());

            let request;
            if (partner) {
                request = partnerRequest((API_URL || process.env.REACT_APP_LEGISLATION_SUMMARY_SERVICE) + '/LegislationSummary/api/GetLegislationSummaryByIDAsync', 'GET', '', '', params);
            } else {
                request = publicRequest((API_URL || process.env.REACT_APP_LEGISLATION_SUMMARY_SERVICE) + '/LegislationSummary/api/GetLegislationSummaryListAsync', 'GET', '', '', params);
            }

            return request
                .then(result => {
                    if (result[0] === REQUEST_FAILURE) {
                        dispatch(billActionCreators.billSummaryError(result[1]))
                        return Promise.reject(result[1])
                    } else if (result[0] === REQUEST_SUCCESS) {
                        // Dispatch the success action
                        dispatch(billActionCreators.receiveBillSummary(partner ? result[1].SummaryList : result[1].LegislationSummaries))
                    } else if (result[0] === NO_CONTENT) {
                        dispatch(billActionCreators.billSummaryError('No Content'))
                    } else if (result.name === 'AbortError') {
                        return Promise.reject("Aborted");
                    }
                }).catch(err => Promise.reject(err));
        }
    },

    //SAVE BILL SUMMARY
    requestSaveBillSummary: () => ({
        type: saveBillSummaryRequest,
        isFetching: true
    }),

    receiveSaveBillSummary: responseObj => ({
        type: saveBillSummarySuccess,
        isFetching: false,
        billSummarySave: responseObj
    }),

    saveBillSummaryError: message => ({
        type: saveBillSummaryFailure,
        isFetching: false,
        billSummarySave: [],
        errorMessage: message
    }),

    saveBillSummary: (body) => {
        return dispatch => {
            dispatch(billActionCreators.requestSaveBillSummary());
            let headers = {
                'content-type': "application/json; charset=utf-8"
            }
            return partnerRequest((API_URL || process.env.REACT_APP_LEGISLATION_TEXT_API_URL) + '/LegislationSummary/api/SaveLegislationSummaryAsync', 'PUT', headers, body, '')
                .then(result => {
                    if (result[0] === REQUEST_FAILURE) {
                        dispatch(billActionCreators.saveBillSummaryError(result[1]));
                        return Promise.reject(result[1])
                    } else if (result[0] === REQUEST_SUCCESS) {
                        dispatch(billActionCreators.receiveSaveBillSummary(result[1]));
                    } else if (result[0] === NO_CONTENT) {
                        dispatch(billActionCreators.receiveSaveBillSummary([]));
                    } else if (result.name === 'AbortError') {
                        return Promise.reject("Aborted");
                    }
                }).catch(err => Promise.reject(err));
        }
    },

    //BILL STATUS REFERENCES
    receiveBillStatusRefs: responseObj => ({
        type: billStatusRefSuccess,
        isFetching: false,
        billStatusReferences: responseObj
    }),

    billStatusRefsError: message => ({
        type: billStatusRefFailure,
        isFetching: false,
        errorMessage: message
    }),

    getBillStatusReferences: () => {
        return dispatch => {
            return publicRequest((API_URL || process.env.REACT_APP_LEGISLATION_ADVANCED_SEARCH_API_URL) + '/AdvancedLegislationSearch/api/GetStatusReferencesAsync', 'GET', '', '', '')
                .then(result => {
                    if (result[0] === REQUEST_FAILURE) {
                        dispatch(billActionCreators.billStatusRefsError(result[1]))
                        return Promise.reject(result[1])
                    } else if (result[0] === REQUEST_SUCCESS) {
                        // Dispatch the success action
                        dispatch(billActionCreators.receiveBillStatusRefs(result[1].References))
                    } else if (result[0] === NO_CONTENT) {
                        dispatch(billActionCreators.billStatusRefsError('No Content'))
                        return Promise.reject('No Content')
                    } else if (result.name === 'AbortError') {
                        return Promise.reject("Aborted");
                    }
                }).catch(err => Promise.reject(err))
        }
    },

    //LEGISLATION CLASSES
    receiveLegislationClasses: responseObj => ({
        type: legislationClassesSuccess,
        isFetching: false,
        legislationClasses: responseObj
    }),

    legislationClassesError: message => ({
        type: legislationClassesFailure,
        isFetching: false,
        errorMessage: message
    }),

    getLegislationClasses: () => {
        return dispatch => {
            return publicRequest((API_URL || process.env.REACT_APP_LEGISLATION_API_URL) + '/Legislation/api/GetLegislationClassesAsync', 'GET', '', '', '')
                .then(result => {
                    if (result[0] === REQUEST_FAILURE) {
                        dispatch(billActionCreators.legislationClassesError(result[1]))
                        return Promise.reject(result[1])
                    } else if (result[0] === REQUEST_SUCCESS) {
                        // Dispatch the success action
                        dispatch(billActionCreators.receiveLegislationClasses(result[1].LegislationClasses))
                    } else if (result[0] === NO_CONTENT) {
                        dispatch(billActionCreators.legislationClassesError('No Content'))
                        return Promise.reject('No Content')
                    } else if (result.name === 'AbortError') {
                        return Promise.reject("Aborted");
                    }
                }).catch(err => Promise.reject(err))
        }
    },

    //BILL STATUS CATEGORY REFERENCES
    receiveBillStatusCategoryRefs: responseObj => ({
        type: billStatusCategoryRefSuccess,
        isFetching: false,
        billStatusCategoryReferences: responseObj
    }),

    billStatusCategoryRefsError: message => ({
        type: billStatusCategoryRefFailure,
        isFetching: false,
        errorMessage: message
    }),

    getBillStatusCategoryReferences: () => {
        return dispatch => {
            return publicRequest((API_URL || process.env.REACT_APP_LEGISLATION_ADVANCED_SEARCH_API_URL) + '/AdvancedLegislationSearch/api/GetLegislationCategoryReferencesAsync', 'GET', '', '', '')
                .then(result => {
                    if (result[0] === REQUEST_FAILURE) {
                        dispatch(billActionCreators.billStatusCategoryRefsError(result[1]))
                        return Promise.reject(result[1])
                    } else if (result[0] === REQUEST_SUCCESS) {
                        // Dispatch the success action
                        dispatch(billActionCreators.receiveBillStatusCategoryRefs(result[1].LegislationCategoriesList))
                    } else if (result[0] === NO_CONTENT) {
                        dispatch(billActionCreators.billStatusCategoryRefsError('No Content'))
                        return Promise.reject('No Content')
                    } else if (result.name === 'AbortError') {
                        return Promise.reject("Aborted");
                    }
                }).catch(err => Promise.reject(err))
        }
    },

    receiveBillEventRefs: responseObj => ({
        type: billEventRefSuccess,
        billEventRef: responseObj
    }),

    billEventRefsError: message => ({
        type: billEventRefFailure,
        billEventRefError: message
    }),

    getBillEventReferences: (params) => {
        return dispatch => {

            return publicRequest((API_URL || process.env.REACT_APP_LEGISLATION_EVENT_API_URL) + '/LegislationEvent/api/getLegislationEventTypeReferencesAsync', 'GET', '', '', params)
                .then(result => {
                    if (result[0] === REQUEST_FAILURE) {
                        dispatch(billActionCreators.billEventRefsError(result[1]))
                        return Promise.reject(result[1])
                    } else if (result[0] === REQUEST_SUCCESS) {
                        // Dispatch the success action
                        dispatch(billActionCreators.receiveBillEventRefs(result[1].EventTypes))
                    } else if (result[0] === NO_CONTENT) {
                        dispatch(billActionCreators.billEventRefsError('No Content'))
                        return Promise.reject('No Content')
                    } else if (result.name === 'AbortError') {
                        return Promise.reject("Aborted");
                    }
                }).catch(err => Promise.reject(err))
        }
    },

    receiveLegislationEventsById: responseObj => ({
        type: legislationEventsByIdSuccess,
        legislationEventsById: responseObj
    }),

    legislationEventsByIdError: message => ({
        type: legislationEventsByIdFailure,
        legislationEventsByIdError: message
    }),

    //Events for reconsideration
    getLegislationEventsByLegislationId: (params) => {
        return dispatch => {

            return publicRequest((API_URL || process.env.REACT_APP_LEGISLATION_EVENT_API_URL) + '/LegislationEvent/api/GetLegislationEventByLegislationIDAsync', 'GET', '', '', params)
                .then(result => {
                    if (result[0] === REQUEST_FAILURE) {
                        dispatch(billActionCreators.legislationEventsByIdError(result[1]))
                        return Promise.reject(result[1])
                    } else if (result[0] === REQUEST_SUCCESS) {
                        // Dispatch the success action
                        dispatch(billActionCreators.receiveLegislationEventsById(result[1].LegislationEvents))
                    } else if (result[0] === NO_CONTENT) {
                        dispatch(billActionCreators.legislationEventsByIdError('No Content'))
                        return Promise.reject('No Content')
                    } else if (result.name === 'AbortError') {
                        return Promise.reject("Aborted");
                    }
                }).catch(err => Promise.reject(err))
        }
    },

    // ADVANCED BILL SEARCH
    requestLegislationIdsList: () => ({
        type: legislationIdsListRequest,
        isFetching: true
    }),

    receiveLegislationIdsList: (responseObj, pagination) => ({
        type: legislationIdsListSuccess,
        isFetching: false,
        legislationIdsList: responseObj,
        legislationIdsListPagination: pagination,
        errorMessage: responseObj.FailureMessage
    }),

    legislationIdsListError: message => ({
        type: legislationIdsListFailure,
        isFetching: false,
        errorMessage: message
    }),

    getLegislationIdsList: (body, pagination) => {
        return dispatch => {
            dispatch(billActionCreators.requestLegislationIdsList());
            let headers = {
                'content-type': "application/json; charset=utf-8"
            }
            if (pagination) { headers['X-Pagination'] = JSON.stringify(pagination) }
            let request = publicRequest((API_URL || process.env.REACT_APP_LEGISLATION_API_URL) + '/Legislation/api/GetLegislationIdsListAsync', 'POST', headers, body, '');
            return request
                .then(result => {
                    if (result[0] === REQUEST_FAILURE) {
                        dispatch(billActionCreators.legislationIdsListError(result[1]))
                        return Promise.reject(result[1])
                    } else if (result[0] === REQUEST_SUCCESS) {
                        // Dispatch the success action
                        dispatch(billActionCreators.receiveLegislationIdsList(result[1], result[2] && result[2].get('X-Pagination') ? JSON.parse(result[2].get('X-Pagination')) : null))
                    } else if (result[0] === NO_CONTENT) {
                        dispatch(billActionCreators.receiveLegislationIdsList([]));
                    } else if (result.name === 'AbortError') {
                        return Promise.reject("Aborted");
                    }
                }).catch(err => Promise.reject(err));
        }
    },

    requestBillListByIds: () => ({
        type: billListByIdsRequest,
        isFetching: true
    }),

    receiveBillListByIds: (responseObj, pagination) => ({
        type: billListByIdsSuccess,
        isFetching: false,
        billListByIds: responseObj,
        billListByIdsPagination: pagination,
        errorMessage: responseObj.FailureMessage
    }),

    billListByIdsError: message => ({
        type: billListByIdsFailure,
        isFetching: false,
        errorMessage: message
    }),

    getBillListByIds: (body, pagination) => {
        return dispatch => {
            dispatch(billActionCreators.requestBillListByIds());
            let headers = {
                'content-type': "application/json; charset=utf-8"
            }
            if (pagination) { headers['X-Pagination'] = JSON.stringify(pagination) }
            let request = publicRequest((API_URL || process.env.REACT_APP_LEGISLATION_API_URL) + '/Legislation/api/GetLegislationByLegislationIDsAsync', 'POST', headers, body, '');
            return request
                .then(result => {
                    if (result[0] === REQUEST_FAILURE) {
                        dispatch(billActionCreators.billListByIdsError(result[1]))
                        return Promise.reject(result[1])
                    } else if (result[0] === REQUEST_SUCCESS) {
                        // Dispatch the success action
                        dispatch(billActionCreators.receiveBillListByIds(result[1].Legislations, result[2] && result[2].get('X-Pagination') ? JSON.parse(result[2].get('X-Pagination')) : null))
                    } else if (result[0] === NO_CONTENT) {
                        dispatch(billActionCreators.receiveBillListByIds([]));
                    } else if (result.name === 'AbortError') {
                        return Promise.reject("Aborted");
                    }
                }).catch(err => Promise.reject(err));
        }
    },

    requestLegislationTextsHitCounts: () => ({
        type: legislationTextsHitCountsRequest,
        isFetching: true
    }),

    receiveLegislationTextsHitCounts: (responseObj) => ({
        type: legislationTextsHitCountsSuccess,
        isFetching: false,
        legislationTextsHitCounts: responseObj,
        errorMessage: responseObj.FailureMessage
    }),

    legislationTextsHitCountsError: message => ({
        type: legislationTextsHitCountsFailure,
        isFetching: false,
        errorMessage: message
    }),

    getLegislationTextsHitCounts: (body) => {
        return dispatch => {
            dispatch(billActionCreators.requestLegislationTextsHitCounts());
            let headers = {
                'content-type': "application/json; charset=utf-8"
            }
            let request = publicRequest((API_URL || process.env.REACT_APP_LEGISLATION_TEXT_API_URL) + '/LegislationText/api/GetLegislationTextsHitCountsAsync', 'POST', headers, body, '');
            return request
                .then(result => {
                    if (result[0] === REQUEST_FAILURE) {
                        dispatch(billActionCreators.legislationTextsHitCountsError(result[1]))
                        return Promise.reject(result[1])
                    } else if (result[0] === REQUEST_SUCCESS) {
                        // Dispatch the success action
                        dispatch(billActionCreators.receiveLegislationTextsHitCounts(result[1].HitCounts))
                    } else if (result[0] === NO_CONTENT) {
                        dispatch(billActionCreators.receiveLegislationTextsHitCounts([]));
                    } else if (result.name === 'AbortError') {
                        return Promise.reject("Aborted");
                    }
                }).catch(err => Promise.reject(err));
        }
    },

    requestBillList: () => ({
        type: billListRequest,
        isFetching: true
    }),

    receiveBillList: (responseObj, pagination) => ({
        type: billListSuccess,
        isFetching: false,
        billList: responseObj,
        billListPagination: pagination,
        errorMessage: responseObj.FailureMessage
    }),

    billListError: message => ({
        type: billListFailure,
        isFetching: false,
        errorMessage: message
    }),

    getBillList: (body, partner, pagination) => {
        return dispatch => {
            dispatch(billActionCreators.requestBillList());
            let headers = {
                'content-type': "application/json; charset=utf-8"
            }
            if (pagination) { headers['X-Pagination'] = JSON.stringify(pagination) }
            let request = '';
            if (partner) {
                // THIS REQUEST NOT CURRENTLY IN USE. ADD THE SERVICE NAME TO THE REQUEST URL ONCE AVAILABLE
                request = partnerRequest((API_URL || process.env.REACT_APP_LEGISLATION_ADVANCED_SEARCH_API_URL) + '/AdvancedLegislationSearch/api/GetPartnerLegislationListAsync', 'GET', '', '', '');
            } else {
                request = publicRequest((API_URL || process.env.REACT_APP_LEGISLATION_ADVANCED_SEARCH_API_URL) + '/AdvancedLegislationSearch/api/GetLegislationListAsync', 'POST', headers, body, '');
            }
            return request
                .then(result => {
                    if (result[0] === REQUEST_FAILURE) {
                        dispatch(billActionCreators.billListError(result[1]))
                        return Promise.reject(result[1])
                    } else if (result[0] === REQUEST_SUCCESS) {
                        // Dispatch the success action
                        dispatch(billActionCreators.receiveBillList(result[1].Legislations, result[2] && result[2].get('X-Pagination') ? JSON.parse(result[2].get('X-Pagination')) : null))
                    } else if (result[0] === NO_CONTENT) {
                        dispatch(billActionCreators.receiveBillList([]));
                    } else if (result.name === 'AbortError') {
                        return Promise.reject("Aborted");
                    }
                }).catch(err => Promise.reject(err));
        }
    },

    requestMostFrequentBillList: () => ({
        type: mostFrequentBillListRequest,
        isFetching: true
    }),

    receiveMostFrequentBillList: responseObj => ({
        type: mostFrequentBillListSuccess,
        isFetching: false,
        mostFrequentBillList: responseObj,
        errorMessage: responseObj.FailureMessage
    }),

    mostFrequentBillListError: message => ({
        type: mostFrequentBillListFailure,
        isFetching: false,
        errorMessage: message
    }),

    getMostFrequentBillList: params => {
        return dispatch => {
            dispatch(billActionCreators.requestMostFrequentBillList());
            let headers = {
                'content-type': "application/json; charset=utf-8"
            }
            return publicRequest((API_URL || process.env.REACT_APP_LEGISLATION_ADVANCED_SEARCH_API_URL) + '/AdvancedLegislationSearch/api/GetMostFrequentLegislationsAsync', 'GET', headers, '', params)
                .then(result => {
                    if (result[0] === REQUEST_FAILURE) {
                        dispatch(billActionCreators.mostFrequentBillListError(result[1]))
                        return Promise.reject(result[1])
                    } else if (result[0] === REQUEST_SUCCESS) {
                        // Dispatch the success action
                        dispatch(billActionCreators.receiveMostFrequentBillList(result[1].Legislations))
                    } else if (result[0] === NO_CONTENT) {
                        dispatch(billActionCreators.receiveMostFrequentBillList([]));
                    } else if (result.name === 'AbortError') {
                        return Promise.reject("Aborted");
                    }
                }).catch(err => Promise.reject(err));
        }
    },

    receiveBillNumbers: responseObj => ({
        type: billNumbersSuccess,
        billNumbers: responseObj
    }),

    billNumbersError: message => ({
        type: billNumbersFailure,
        isFetching: false,
        billNumberMessage: message
    }),

    getBillNumbers: (params) => {
        return dispatch => {
            return publicRequest((API_URL || process.env.REACT_APP_LEGISLATION_ADVANCED_SEARCH_API_URL) + '/AdvancedLegislationSearch/api/GetLegislativeNumbersAsync', 'GET', '', '', params)
                .then(result => {
                    if (result[0] === REQUEST_FAILURE) {
                        dispatch(billActionCreators.billNumbersError(result[1]))
                        return Promise.reject(result[1])
                    } else if (result[0] === REQUEST_SUCCESS) {
                        dispatch(billActionCreators.receiveBillNumbers(result[1].LegislationNumbersList))
                    } else if (result[0] === NO_CONTENT) {
                        dispatch(billActionCreators.receiveBillNumbers([]))
                    } else if (result.name === 'AbortError') {
                        return Promise.reject("Aborted");
                    }
                }).catch(err => Promise.reject(err));
        }
    },

    receiveBillVersionRef: responseObj => ({
        type: billVersionRefSuccess,
        billVersionRef: responseObj
    }),

    billVersionRefError: message => ({
        type: billVersionRefFailure,
        billVersionRefError: message
    }),

    getBillVersionRef: () => {
        return dispatch => {
            let headers = {
                'content-type': "application/json; charset=utf-8"
            }
            return publicRequest((API_URL || process.env.REACT_APP_LEGISLATION_TEXT_API_URL) + '/LegislationText/api/GetLegislationVersionListAsync', 'GET', headers, '', '')
                .then(result => {
                    if (result[0] === REQUEST_FAILURE) {
                        dispatch(billActionCreators.billVersionRefError(result[1]));
                        return Promise.reject(result[1])
                    } else if (result[0] === REQUEST_SUCCESS) {
                        dispatch(billActionCreators.receiveBillVersionRef(result[1].LegislationVersionList));
                    } else if (result[0] === NO_CONTENT) {
                        dispatch(billActionCreators.billVersionRefError('No Content'));
                    } else if (result.name === 'AbortError') {
                        return Promise.reject("Aborted");
                    }
                }).catch(err => Promise.reject(err));
        }
    },

    receiveSponsorRefList: responseObj => ({
        type: sponsorRefListSuccess,
        sponsorRefList: responseObj
    }),

    sponsorRefListError: message => ({
        type: sponsorRefListFailure,
        sponsorRefListError: message
    }),

    getSponsorRefList: () => {
        return dispatch => {
            let headers = {
                'content-type': "application/json; charset=utf-8"
            }
            return publicRequest((API_URL || process.env.REACT_APP_LEGISLATION_TEXT_API_URL) + '/LegislationText/api/GetSponsorTypeListAsync', 'GET', headers, '', '')
                .then(result => {
                    if (result[0] === REQUEST_FAILURE) {
                        dispatch(billActionCreators.sponsorRefListError(result[1]));
                        return Promise.reject(result[1])
                    } else if (result[0] === REQUEST_SUCCESS) {
                        dispatch(billActionCreators.receiveSponsorRefList(result[1].SponsorTypes));
                    } else if (result[0] === NO_CONTENT) {
                        dispatch(billActionCreators.sponsorRefListError('No Content'));
                    } else if (result.name === 'AbortError') {
                        return Promise.reject("Aborted");
                    }
                }).catch(err => Promise.reject(err));
        }
    },

    receiveBillSummaryRef: responseObj => ({
        type: billSummaryRefSuccess,
        billSummaryRef: responseObj
    }),

    billSummaryRefError: message => ({
        type: billSummaryRefFailure,
        billSummaryRefError: message
    }),

    getBillSummaryRef: () => {
        return dispatch => {
            let headers = {
                'content-type': "application/json; charset=utf-8"
            }
            return publicRequest((API_URL || process.env.REACT_APP_LEGISLATION_SUMMARY_SERVICE) + '/LegislationSummary/api/GetSummaryVersionsReferenceAsync', 'GET', headers, '', '')
                .then(result => {
                    if (result[0] === REQUEST_FAILURE) {
                        dispatch(billActionCreators.billSummaryRefError(result[1]));
                        return Promise.reject(result[1])
                    } else if (result[0] === REQUEST_SUCCESS) {
                        dispatch(billActionCreators.receiveBillSummaryRef(result[1].SummaryVersions));
                    } else if (result[0] === NO_CONTENT) {
                        dispatch(billActionCreators.billSummaryRefError('No Content'));
                    } else if (result.name === 'AbortError') {
                        return Promise.reject("Aborted");
                    }
                }).catch(err => Promise.reject(err));
        }
    },

    receiveBillText: responseObj => ({
        type: billTextSuccess,
        billText: responseObj
    }),

    billTextError: message => ({
        type: billTextFailure,
        billTextError: message
    }),

    getBillText: (params, partner) => {
        return dispatch => {
            let headers = {
                'content-type': "application/json; charset=utf-8"
            }
            let request = '';
            if (partner) {
                request = partnerRequest((API_URL || process.env.REACT_APP_LEGISLATION_TEXT_API_URL) + '/LegislationText/api/GetPartnerLegislationTextByIDAsync', 'GET', headers, '', params)
            } else {
                request = publicRequest((API_URL || process.env.REACT_APP_LEGISLATION_TEXT_API_URL) + '/LegislationText/api/GetLegislationTextByIDAsync', 'GET', headers, '', params)
            }
            return request
                .then(result => {
                    if (result[0] === REQUEST_FAILURE) {
                        dispatch(billActionCreators.billTextError(result[1]));
                        return Promise.reject(result[1])
                    } else if (result[0] === REQUEST_SUCCESS) {
                        dispatch(billActionCreators.receiveBillText(result[1].TextsList));
                    } else if (result[0] === NO_CONTENT) {
                        dispatch(billActionCreators.receiveBillText([]));
                    } else if (result.name === 'AbortError') {
                        return Promise.reject("Aborted");
                    }
                }).catch(err => Promise.reject(err));
        }
    },

    receiveBillTextSave: responseObj => ({
        type: billTextSaveSuccess,
        billTextSave: responseObj
    }),

    billTextSaveError: message => ({
        type: billTextSaveFailure,
        billTextSaveError: message
    }),

    saveBillText: (body) => {
        return dispatch => {
            let headers = {
                'content-type': "application/json; charset=utf-8"
            }
            return partnerRequest((API_URL || process.env.REACT_APP_LEGISLATION_TEXT_API_URL) + '/LegislationText/api/SaveLegislationTextAsync', 'PUT', headers, body, '')
                .then(result => {
                    if (result[0] === REQUEST_FAILURE) {
                        dispatch(billActionCreators.billTextSaveError(result[1]));
                        return Promise.reject(result[1])
                    } else if (result[0] === REQUEST_SUCCESS) {
                        dispatch(billActionCreators.receiveBillTextSave(result[1]));
                    } else if (result[0] === NO_CONTENT) {
                        dispatch(billActionCreators.receiveBillTextSave([]));
                    } else if (result.name === 'AbortError') {
                        return Promise.reject("Aborted");
                    }
                }).catch(err => Promise.reject(err));
        }
    },

    receiveIhodAmendmentSave: responseObj => ({
        type: ihodAmendmentSaveSuccess,
        ihodAmendmentSave: responseObj
    }),

    ihodAmendmentSaveError: message => ({
        type: ihodAmendmentSaveFailure,
        ihodAmendmentSaveError: message
    }),

    saveIhodAmendment: (params) => {
        let config = {
            method: 'POST',
            headers: {
                "content-type": "application/json; charset=utf-8",
            }
        }

        return dispatch => {
            return fetch("https://ihod-lisva.herokuapp.com/floor_amendments/create_amendment" + params, config)
                .then(() => {
                    dispatch(billActionCreators.receiveIhodAmendmentSave([]));
                }).catch(err => Promise.reject(err));
        }
    },

    receiveBatchPublish: responseObj => ({
        type: batchPublishSuccess,
        batchPublishSave: responseObj
    }),

    batchPublishError: message => ({
        type: batchPublishFailure,
        batchPublishError: message
    }),

    batchPublish: (body) => {
        return dispatch => {
            let headers = {
                'content-type': "application/json; charset=utf-8"
            }
            return partnerRequest((API_URL || process.env.REACT_APP_LEGISLATION_TEXT_API_URL) + '/LegislationText/api/PublishLegislationTextsAsync', 'PUT', headers, body, '')
                .then(result => {
                    if (result[0] === REQUEST_FAILURE) {
                        dispatch(billActionCreators.batchPublishError(result[1]));
                        return Promise.reject(result[1])
                    } else if (result[0] === REQUEST_SUCCESS) {
                        dispatch(billActionCreators.receiveBatchPublish(result[1]));
                    } else if (result[0] === NO_CONTENT) {
                        dispatch(billActionCreators.receiveBatchPublish([]));
                    } else if (result.name === 'AbortError') {
                        return Promise.reject("Aborted");
                    }
                }).catch(err => Promise.reject(err));
        }
    },

    receiveCompletePublish: responseObj => ({
        type: batchCompleteSuccess,
        batchCompleteSave: responseObj
    }),

    batchCompleteError: message => ({
        type: batchCompleteFailure,
        batchCompleteError: message
    }),

    batchComplete: (body) => {
        return dispatch => {
            let headers = {
                'content-type': "application/json; charset=utf-8"
            }
            return partnerRequest((API_URL || process.env.REACT_APP_LEGISLATION_TEXT_API_URL) + '/LegislationText/api/CompletePrintLegislationTextsAsync', 'PUT', headers, body, '')
                .then(result => {
                    if (result[0] === REQUEST_FAILURE) {
                        dispatch(billActionCreators.batchCompleteError(result[1]));
                        return Promise.reject(result[1])
                    } else if (result[0] === REQUEST_SUCCESS) {
                        dispatch(billActionCreators.receiveCompletePublish(result[1]));
                    } else if (result[0] === NO_CONTENT) {
                        dispatch(billActionCreators.receiveCompletePublish([]));
                    } else if (result.name === 'AbortError') {
                        return Promise.reject("Aborted");
                    }
                }).catch(err => Promise.reject(err));
        }
    },

    receiveJsonHeaders: responseObj => ({
        type: jsonHeadersSuccess,
        jsonHeaders: responseObj
    }),

    jsonHeadersError: message => ({
        type: jsonHeadersFailure,
        jsonHeadersError: message
    }),

    getJsonHeaders: (params) => {
        return dispatch => {
            let headers = {
                'content-type': "application/json; charset=utf-8"
            }
            return partnerRequest((API_URL || process.env.REACT_APP_LEGISLATION_FILE_GEN_API_URL) + '/LegislationFileGeneration/api/PreviewJSONById', 'POST', headers, '', params)
                .then(result => {
                    if (result[0] === REQUEST_FAILURE) {
                        dispatch(billActionCreators.jsonHeadersError(result[1]));
                        return Promise.reject(result[1])
                    } else if (result[0] === REQUEST_SUCCESS) {
                        dispatch(billActionCreators.receiveJsonHeaders(result[1]));
                    } else if (result[0] === NO_CONTENT) {
                        dispatch(billActionCreators.receiveJsonHeaders([]));
                    } else if (result.name === 'AbortError') {
                        return Promise.reject("Aborted");
                    }
                }).catch(err => Promise.reject(err));
        }
    },

    receivePdfPreview: responseObj => ({
        type: pdfPreviewSuccess,
        pdfPreview: responseObj
    }),

    pdfPreviewError: message => ({
        type: pdfPreviewFailure,
        pdfPreviewError: message
    }),

    getPdfPreview: (body) => {
        return dispatch => {
            let config = {
                method: 'POST',
                headers: {
                    "Content-Type": "application/json; charset=utf-8",
                    "authorization": "Bearer " + localStorage.getItem('LIS_ID_TOKEN'),
                    "WebAPIKey": process.env.REACT_APP_API_KEY
                },
                body: JSON.stringify(body)
            }

            let url = (API_URL || process.env.REACT_APP_LEGISLATION_FILE_GEN_API_URL) + '/LegislationFileGeneration/api/PreviewPDFFile';

            return fetch(url, config)
                .then(result => {
                    if (result) {
                        dispatch(billActionCreators.receivePdfPreview(result));
                    } else if (result.name === 'AbortError') {
                        return Promise.reject("Aborted");
                    } else {
                        dispatch(billActionCreators.pdfPreviewError("No preview file"));
                    }
                }).catch(err => Promise.reject(err));
        }
    },

    receivePreviewPdfFile: responseObj => ({
        type: previewPdfFileSuccess,
        sasUrl: responseObj
    }),

    previewPdfFileError: message => ({
        type: previewPdfFileFailure,
        sasUrlFailure: message
    }),

    previewPdfFile: (body) => {
        return dispatch => {
            let headers = {
                "Content-Type": "application/json; charset=utf-8"
            }

            return partnerRequest((API_URL || process.env.REACT_APP_LEGISLATION_FILE_GEN_API_URL) + '/LegislationFileGeneration/api/PreviewStoredPDFFileAsync', 'POST', headers, body, '')
                .then(result => {
                    if (result[0] === REQUEST_FAILURE) {
                        dispatch(billActionCreators.previewPdfFileError(result[1]));
                        return Promise.reject(result[1])
                    } else if (result[0] === REQUEST_SUCCESS) {
                        dispatch(billActionCreators.receivePreviewPdfFile(result[1].sasTokenUrl));
                    } else if (result[0] === NO_CONTENT) {
                        dispatch(billActionCreators.previewPdfFileError([]));
                    } else if (result.name === 'AbortError') {
                        return Promise.reject("Aborted");
                    }
                }).catch(err => Promise.reject(err));
        }
    },

    receiveHtmlPreview: responseObj => ({
        type: htmlPreviewSuccess,
        htmlPreview: responseObj
    }),

    htmlPreviewError: message => ({
        type: htmlPreviewFailure,
        htmlPreviewError: message
    }),

    getHtmlPreview: (body) => {
        return dispatch => {
            let config = {
                method: "POST",
                headers: {
                    "Content-Type": "application/json; charset=utf-8",
                    "authorization": "Bearer " + localStorage.getItem('LIS_ID_TOKEN'),
                    "WebAPIKey": process.env.REACT_APP_API_KEY
                },
                body: JSON.stringify(body)
            }

            let url = (API_URL || process.env.REACT_APP_LEGISLATION_FILE_GEN_API_URL) + '/LegislationFileGeneration/api/PreviewHTMLFile';

            return fetch(url, config)
                .then(async result => {
                    if (!result) {
                        dispatch(billActionCreators.htmlPreviewError("Preview file not generated"));
                    } else if (result.status === 400) {
                        await result.body.getReader().read().then(({ value }) => {
                            const error = JSON.parse(Buffer.from(value.buffer).toString())
                            dispatch(billActionCreators.htmlPreviewError(error.Name || "Unknown Error"));
                        })
                    } else if (result.name === 'AbortError') {
                        return Promise.reject("Aborted");
                    } else {
                        dispatch(billActionCreators.receiveHtmlPreview(result));
                    }
                }).catch(err => Promise.reject(err));
        }
    },

    receiveHtmlFilePreview: responseObj => ({
        type: htmlFilePreviewSuccess,
        htmlFilePreview: responseObj
    }),

    htmlFilePreviewError: message => ({
        type: htmlFilePreviewFailure,
        htmlFilePreviewError: message
    }),

    getHtmlFilePreview: (params) => {
        return dispatch => {
            let config = {
                method: "POST",
                headers: {
                    "Content-Type": "application/json; charset=utf-8",
                    "authorization": "Bearer " + localStorage.getItem('LIS_ID_TOKEN'),
                    "WebAPIKey": process.env.REACT_APP_API_KEY
                }
            }

            let url = (API_URL || process.env.REACT_APP_LEGISLATION_FILE_GEN_API_URL) + '/LegislationFileGeneration/api/PreviewLegislationHTMLFileAsync' + params;

            return fetch(url, config)
                .then(async result => {
                    if (!result) {
                        dispatch(billActionCreators.htmlFilePreviewError("Preview file not generated"));
                    } else if (result.status === 400) {
                        await result.body.getReader().read().then(({ value }) => {
                            const error = JSON.parse(Buffer.from(value.buffer).toString())
                            dispatch(billActionCreators.htmlFilePreviewError(error.Name || "Unknown Error"));
                        })
                    } else if (result.name === 'AbortError') {
                        return Promise.reject("Aborted");
                    } else {
                        await result.body.getReader().read().then(({ value }) => {
                            let decoder = new TextDecoder;
                            dispatch(billActionCreators.receiveHtmlFilePreview(decoder.decode(value)));
                        })
                    }
                }).catch(err => Promise.reject(err));
        }
    },

    receiveBillSave: responseObj => ({
        type: billSaveSuccess,
        billSave: responseObj
    }),

    billSaveError: message => ({
        type: billSaveFailure,
        billSaveError: message
    }),

    saveBill: (body) => {
        return dispatch => {
            let headers = {
                'content-type': "application/json; charset=utf-8"
            }
            return partnerRequest((API_URL || process.env.REACT_APP_LEGISLATION_API_URL) + '/Legislation/api/SaveLegislationAsync', 'PUT', headers, body, '')
                .then(result => {
                    if (result[0] === REQUEST_FAILURE) {
                        dispatch(billActionCreators.billSaveError(result[1]));
                        return Promise.reject(result[1])
                    } else if (result[0] === REQUEST_SUCCESS) {
                        dispatch(billActionCreators.receiveBillSave(result[1]));
                    } else if (result[0] === NO_CONTENT) {
                        dispatch(billActionCreators.receiveBillSave([]));
                    } else if (result.name === 'AbortError') {
                        return Promise.reject("Aborted");
                    }
                }).catch(err => Promise.reject(err));
        }
    },

    receiveBillFileGen: responseObj => ({
        type: billFileGenSuccess,
        billFileGen: responseObj
    }),

    billFileGenError: message => ({
        type: billFileGenFailure,
        billFileGenError: message
    }),

    genBillFile: (params) => {
        return dispatch => {
            let headers = {
                'content-type': "application/json; charset=utf-8"
            }
            return partnerRequest((API_URL || process.env.REACT_APP_LEGISLATION_FILE_GEN_API_URL) + '/LegislationFileGeneration/api/ProcessLegislationFilesAsync', 'POST', headers, '', params, true)
                .then(result => {
                    if (result[0] === REQUEST_FAILURE) {
                        dispatch(billActionCreators.billFileGenError(result[1]));
                        return Promise.reject(result[1])
                    } else if (result[0] === REQUEST_SUCCESS) {
                        dispatch(billActionCreators.receiveBillFileGen(result[1]));
                    } else if (result[0] === NO_CONTENT) {
                        dispatch(billActionCreators.billFileGenError('No Content'));
                        return Promise.reject('No Content')
                    }
                }).catch(err => console.log(err))
        }
    },

    receiveBatchBillFileGen: responseObj => ({
        type: batchBillFileGenSuccess,
        batchBillFileGen: responseObj
    }),

    batchBillFileGenError: message => ({
        type: batchBillFileGenFailure,
        batchBillFileGenError: message
    }),

    batchGenBillFile: (params, body) => {
        return dispatch => {
            let headers = {
                'content-type': "application/json; charset=utf-8"
            }
            return partnerRequest((API_URL || process.env.REACT_APP_LEGISLATION_FILE_GEN_API_URL) + '/LegislationFileGeneration/api/ProcessBatchLegislationFilesAsync', 'POST', headers, body, params, true)
                .then(result => {
                    if (result[0] === REQUEST_FAILURE) {
                        dispatch(billActionCreators.batchBillFileGenError(result[1]));
                        return Promise.reject(result[1])
                    } else if (result[0] === REQUEST_SUCCESS) {
                        dispatch(billActionCreators.receiveBatchBillFileGen(result[1]));
                    } else if (result[0] === NO_CONTENT) {
                        dispatch(billActionCreators.batchBillFileGenError('No Content'));
                        return Promise.reject('No Content')
                    }
                }).catch(err => Promise.reject(err));
        }
    },

    receiveBillManagementList: responseObj => ({
        type: billManagementListSuccess,
        billManagementList: responseObj,
    }),

    billManagementListError: message => ({
        type: billManagementListFailure,
        billManagementListError: message
    }),

    getBillManagementList: (params) => {
        return dispatch => {
            let headers = {
                'content-type': "application/json; charset=utf-8"
            }
            return partnerRequest((API_URL || process.env.REACT_APP_LEGISLATION_API_URL) + '/Legislation/api/getLegislationManagementListAsync', 'GET', headers, '', params)
                .then(result => {
                    if (result[0] === REQUEST_FAILURE) {
                        dispatch(billActionCreators.billManagementListError(result[1]))
                        return Promise.reject(result[1])
                    } else if (result[0] === REQUEST_SUCCESS) {
                        // Dispatch the success action
                        dispatch(billActionCreators.receiveBillManagementList(result[1].Legislations))
                    } else if (result[0] === NO_CONTENT) {
                        dispatch(billActionCreators.receiveBillManagementList([]))
                    } else if (result.name === 'AbortError') {
                        return Promise.reject("Aborted");
                    }
                }).catch(err => Promise.reject(err));
        }
    },

    receiveSessionBills: responseObj => ({
        type: sessionBillsSuccess,
        sessionBills: responseObj,
    }),

    sessionBillsError: message => ({
        type: sessionBillsFailure,
        sessionBillsError: message
    }),

    getSessionBills: (params) => {
        return dispatch => {
            let headers = {
                'content-type': "application/json; charset=utf-8"
            }
            return publicRequest((API_URL || process.env.REACT_APP_LEGISLATION_API_URL) + '/Legislation/api/getLegislationSessionListAsync/', 'GET', headers, '', params)
                .then(result => {
                    if (result[0] === REQUEST_FAILURE) {
                        dispatch(billActionCreators.sessionBillsError(result[1]))
                        return Promise.reject(result[1])
                    } else if (result[0] === REQUEST_SUCCESS) {
                        // Dispatch the success action
                        dispatch(billActionCreators.receiveSessionBills(result[1].Legislations))
                    } else if (result[0] === NO_CONTENT) {
                        dispatch(billActionCreators.receiveSessionBills([]))
                    } else if (result.name === 'AbortError') {
                        return Promise.reject("Aborted");
                    }
                }).catch(err => Promise.reject(err));
        }
    },

    receiveBillEvents: responseObj => ({
        type: billEventsSuccess,
        billEvents: responseObj,
    }),

    billEventsError: message => ({
        type: billEventsFailure,
        billEventsError: message
    }),

    getBillEvents: (params = '') => {
        return dispatch => {
            let headers = {
                'content-type': "application/json; charset=utf-8"
            }
            return publicRequest((API_URL || process.env.REACT_APP_LEGISLATION_EVENT_API_URL) + '/LegislationEvent/api/getlegislationeventtypereferencesasync/', 'GET', headers, '', params)
                .then(result => {
                    if (result[0] === REQUEST_FAILURE) {
                        dispatch(billActionCreators.billEventsError(result[1]))
                        return Promise.reject(result[1])
                    } else if (result[0] === REQUEST_SUCCESS) {
                        // Dispatch the success action
                        dispatch(billActionCreators.receiveBillEvents(result[1].EventTypes))
                    } else if (result[0] === NO_CONTENT) {
                        dispatch(billActionCreators.billEventsError('No Content'))
                    } else if (result.name === 'AbortError') {
                        return Promise.reject("Aborted");
                    }
                }).catch(err => Promise.reject(err));
        }
    },

    receiveBillEventSave: responseObj => ({
        type: billEventSaveSuccess,
        billEventSave: responseObj,
    }),

    billEventSaveError: message => ({
        type: billEventSaveFailure,
        billEventSaveError: message
    }),

    saveBillEvent: (body) => {
        return dispatch => {
            let headers = {
                'content-type': "application/json; charset=utf-8"
            }
            return partnerRequest((API_URL || process.env.REACT_APP_LEGISLATION_EVENT_API_URL) + '/LegislationEvent/api/savelegislationeventasync/', 'POST', headers, body, '')
                .then(result => {
                    if (result[0] === REQUEST_FAILURE) {
                        dispatch(billActionCreators.billEventSaveError(result[1]))
                        return Promise.reject(result[1])
                    } else if (result[0] === REQUEST_SUCCESS) {
                        // Dispatch the success action
                        dispatch(billActionCreators.receiveBillEventSave(result[1]))
                    } else if (result[0] === NO_CONTENT) {
                        dispatch(billActionCreators.billEventSaveError('No Content'))
                        return Promise.reject('No Content')
                    } else if (result.name === 'AbortError') {
                        return Promise.reject("Aborted");
                    }
                }).catch(err => Promise.reject(err));
        }
    },

    receiveTextList: responseObj => ({
        type: textListSuccess,
        textList: responseObj,
    }),

    textListError: message => ({
        type: textListFailure,
        textListError: message
    }),

    getTextList: (params) => {
        return dispatch => {
            let headers = {
                'content-type': "application/json; charset=utf-8"
            }
            return publicRequest((API_URL || process.env.REACT_APP_LEGISLATION_TEXT_API_URL) + '/LegislationText/api/getlegislationtextlistasync/', 'GET', headers, '', params)
                .then(result => {
                    if (result[0] === REQUEST_FAILURE) {
                        dispatch(billActionCreators.textListError(result[1]))
                        return Promise.reject(result[1])
                    } else if (result[0] === REQUEST_SUCCESS) {
                        dispatch(billActionCreators.receiveTextList(result[1].LegislationTextList))
                    } else if (result[0] === NO_CONTENT) {
                        dispatch(billActionCreators.receiveTextList([]))
                    } else if (result.name === 'AbortError') {
                        return Promise.reject("Aborted");
                    }
                }).catch(err => Promise.reject(err));
        }
    },

    receiveSubjectList: responseObj => ({
        type: subjectListSuccess,
        subjectList: responseObj,
    }),

    subjectListError: message => ({
        type: subjectListFailure,
        subjectListError: message
    }),

    getSubjectList: (params) => {
        return dispatch => {
            let headers = {
                'content-type': "application/json; charset=utf-8"
            }
            return publicRequest((API_URL || process.env.REACT_APP_LEGISLATION_SUBJECT_SERVICE) + '/LegislationSubject/api/GetSubjectReferencesAsync/', 'GET', headers, '', params)
                .then(result => {
                    if (result[0] === REQUEST_FAILURE) {
                        dispatch(billActionCreators.subjectListError(result[1]))
                        return Promise.reject(result[1])
                    } else if (result[0] === REQUEST_SUCCESS) {
                        const subjectList = result[1].SubjectsReference;
                        subjectList.forEach(subject => {
                            subject.label = subject.Subject;
                            subject.value = subject.SubjectIndexID;
                        })
                        dispatch(billActionCreators.receiveSubjectList(subjectList))
                    } else if (result[0] === NO_CONTENT) {
                        dispatch(billActionCreators.receiveSubjectList([]))
                    } else if (result.name === 'AbortError') {
                        return Promise.reject("Aborted");
                    }
                }).catch(err => Promise.reject(err));
        }
    },

    receiveIntroDateList: responseObj => ({
        type: introDateListSuccess,
        introDateList: responseObj,
    }),

    introDateListError: message => ({
        type: introDateListFailure,
        introDateListError: message
    }),

    getIntroDateList: (params) => {
        return dispatch => {
            let headers = {
                'content-type': "application/json; charset=utf-8"
            }
            return publicRequest((API_URL || process.env.REACT_APP_LEGISLATION_ADVANCED_SEARCH_API_URL) + '/AdvancedLegislationSearch/api/GetIntroductionDateListAsync/', 'GET', headers, '', params)
                .then(result => {
                    if (result[0] === REQUEST_FAILURE) {
                        dispatch(billActionCreators.introDateListError(result[1]))
                        return Promise.reject(result[1])
                    } else if (result[0] === REQUEST_SUCCESS) {
                        dispatch(billActionCreators.receiveIntroDateList(result[1].LegislationDatesList))
                    } else if (result[0] === NO_CONTENT) {
                        dispatch(billActionCreators.receiveIntroDateList([]))
                    } else if (result.name === 'AbortError') {
                        return Promise.reject("Aborted");
                    }
                }).catch(err => Promise.reject(err));
        }
    },

    receiveLegislationAmendmentList: responseObj => ({
        type: legislationAmendmentListSuccess,
        legislationAmendmentList: responseObj,
    }),

    legislationAmendmentListError: message => ({
        type: legislationAmendmentListFailure,
        legislationAmendmentListError: message
    }),

    getLegislationAmendmentList: (params) => {
        return dispatch => {
            let headers = {
                'content-type': "application/json; charset=utf-8"
            }
            return publicRequest((API_URL || process.env.REACT_APP_LEGISLATION_API_URL) + '/Legislation/api/GetLegislationAmendmentListAsync/', 'GET', headers, '', params)
                .then(result => {
                    if (result[0] === REQUEST_FAILURE) {
                        dispatch(billActionCreators.legislationAmendmentListError(result[1]))
                        return Promise.reject(result[1])
                    } else if (result[0] === REQUEST_SUCCESS) {
                        dispatch(billActionCreators.receiveLegislationAmendmentList(result[1].LegislationAmendmentList))
                    } else if (result[0] === NO_CONTENT) {
                        dispatch(billActionCreators.receiveLegislationAmendmentList([]))
                    } else if (result.name === 'AbortError') {
                        return Promise.reject("Aborted");
                    }
                }).catch(err => Promise.reject(err));
        }
    },

    receiveLegislationDispositions: responseObj => ({
        type: legislationDispositionsSuccess,
        legislationDispositions: responseObj,
    }),

    legislationDispositionsError: message => ({
        type: legislationDispositionsFailure,
        legislationDispositionsError: message
    }),

    getLegislationDispositions: () => {
        return dispatch => {
            let headers = {
                'content-type': "application/json; charset=utf-8"
            }
            return publicRequest((API_URL || process.env.REACT_APP_LEGISLATION_TEXT_API_URL) + '/LegislationText/api/GetTextDispositionsReferenceAsync/', 'GET', headers, '', '')
                .then(result => {
                    if (result[0] === REQUEST_FAILURE) {
                        dispatch(billActionCreators.legislationDispositionsError(result[1]))
                        return Promise.reject(result[1])
                    } else if (result[0] === REQUEST_SUCCESS) {
                        dispatch(billActionCreators.receiveLegislationDispositions(result[1].TextDispositionList))
                    } else if (result[0] === NO_CONTENT) {
                        dispatch(billActionCreators.receiveLegislationDispositions([]))
                    } else if (result.name === 'AbortError') {
                        return Promise.reject("Aborted");
                    }
                }).catch(err => Promise.reject(err));
        }
    },

    receiveLegislationStatusHistory: responseObj => ({
        type: legislationStatusHistorySuccess,
        legislationStatusHistory: responseObj,
    }),

    legislationStatusHistoryError: message => ({
        type: legislationStatusHistoryFailure,
        legislationStatusHistoryError: message
    }),

    getBillStatusHistory: (body) => {
        return dispatch => {
            let headers = {
                'content-type': "application/json; charset=utf-8"
            }
            return publicRequest((API_URL || process.env.REACT_APP_LEGISLATION_API_URL) + '/Legislation/api/GetLegislationStatusHistoryByLegislationIDAsync/', 'POST', headers, body, '')
                .then(result => {
                    if (result[0] === REQUEST_FAILURE) {
                        dispatch(billActionCreators.legislationStatusHistoryError(result[1]))
                        return Promise.reject(result[1])
                    } else if (result[0] === REQUEST_SUCCESS) {
                        dispatch(billActionCreators.receiveLegislationStatusHistory(result[1].LegislationsStatusHistory))
                    } else if (result[0] === NO_CONTENT) {
                        dispatch(billActionCreators.receiveLegislationStatusHistory([]))
                    } else if (result.name === 'AbortError') {
                        return Promise.reject("Aborted");
                    }
                }).catch(err => Promise.reject(err));
        }
    },

    receiveSasURL: responseObj => ({
        type: sasURLSuccess,
        sasURLLink: responseObj,
    }),

    sasURLError: message => ({
        type: sasURLFailure,
        sasURLLinkError: message
    }),

    getSasURL: (params) => {
        return dispatch => {
            let headers = {
                'content-type': "application/json; charset=utf-8"
            }
            return partnerRequest((API_URL || process.env.REACT_APP_LEGISLATION_FILE_GEN_API_URL) + '/LegislationFileGeneration/api/GetSASUrlForPreviewAsync/', 'GET', headers, '', params)
                .then(result => {
                    if (result[0] === REQUEST_FAILURE) {
                        dispatch(billActionCreators.sasUrlError(result[1]))
                        return Promise.reject(result[1])
                    } else if (result[0] === REQUEST_SUCCESS) {
                        dispatch(billActionCreators.receiveSasURL(result[1].sasTokenUrl))
                    } else if (result[0] === NO_CONTENT) {
                        dispatch(billActionCreators.receiveSasURL([]))
                    } else if (result.name === 'AbortError') {
                        return Promise.reject("Aborted");
                    }
                }).catch(err => Promise.reject(err));
        }
    },

    uploadImpactStatement: (params, file) => {
        return dispatch => {
            const headers = {
                'cache-control': 'no-cache'
            }
            let formData = new FormData();
            formData.append('fiscalImpactPDF', file);
            return partnerRequest((API_URL || process.env.REACT_APP_LEGISLATION_FILE_GEN_API_URL) + '/LegislationFileGeneration/api/SaveImpactStatementFileAsync', 'POST', headers, formData, params)
                .then(result => {
                    if (result[0] === REQUEST_FAILURE) {
                        dispatch({
                            type: uploadImpactStatementRequest,
                            uploadImpactStatementError: result[1]
                        });
                        return Promise.reject(result[1])
                    } else if (result[0] === REQUEST_SUCCESS) {
                        dispatch({
                            type: uploadImpactStatementRequest,
                            uploadImpactStatementResponse: result[1]
                        });
                    } else if (result[0] === NO_CONTENT) {
                        dispatch({
                            type: uploadImpactStatementRequest,
                            uploadImpactStatementError: "No Content"
                        });
                        return Promise.reject('No Content')
                    } else if (result.name === 'AbortError') {
                        return Promise.reject("Aborted");
                    }
                }).catch(err => Promise.reject(err))
        }
    },

    receiveDeleteBlobFile: responseObj => ({
        type: deleteBlobFileSuccess,
        deleteBlobFileResponse: responseObj,
    }),

    deleteBlobFileError: message => ({
        type: deleteBlobFileFailure,
        deleteBlobFileError: message
    }),

    deleteBlobFileStatement: (params) => {
        return dispatch => {
            const headers = {
                'cache-control': 'no-cache'
            }
            return partnerRequest((API_URL || process.env.REACT_APP_LEGISLATION_EVENT_API_URL) + '/LegislationEvent/api/DeleteFileFromBlobStorageAsync', 'DELETE', headers, null, params)
                .then(result => {
                    if (result[0] === REQUEST_FAILURE) {
                        dispatch(billActionCreators.deleteBlobFileError(result[1]))
                        return Promise.reject(result[1])
                    } else if (result[0] === REQUEST_SUCCESS) {
                        dispatch(billActionCreators.receiveDeleteBlobFile(result[1].FilesDeleted))
                    } else if (result[0] === NO_CONTENT) {
                        dispatch(billActionCreators.receiveDeleteBlobFile([]))
                    } else if (result.name === 'AbortError') {
                        return Promise.reject("Aborted");
                    }
                }).catch(err => Promise.reject(err))
        }
    }
};

export const reducer = (state, action) => {
    state = state || initialState;

    switch (action.type) {
        case billFailure:
            return Object.assign({}, state, {
                billError: action.billError
            });
        case billSuccess:
            return Object.assign({}, state, {
                bill: action.bill,
                billError: ''
            });
        case billStatusRefFailure:
            return Object.assign({}, state, {
                isFetching: false,
                errorMessage: action.message
            });
        case billStatusRefSuccess:
            return Object.assign({}, state, {
                isFetching: false,
                billStatusReferences: action.billStatusReferences,
                errorMessage: ''
            });
        case legislationClassesFailure:
            return Object.assign({}, state, {
                isFetching: false,
                errorMessage: action.message
            });
        case legislationClassesSuccess:
            return Object.assign({}, state, {
                isFetching: false,
                legislationClasses: action.legislationClasses,
                errorMessage: ''
            });
        case billStatusCategoryRefFailure:
            return Object.assign({}, state, {
                isFetching: false,
                errorMessage: action.message
            });
        case billStatusCategoryRefSuccess:
            return Object.assign({}, state, {
                isFetching: false,
                billStatusCategoryReferences: action.billStatusCategoryReferences,
                errorMessage: ''
            });
        case billNumbersFailure:
            return Object.assign({}, state, {
                isFetching: false,
                billNumberMessage: action.billNumberMessage
            });
        case billNumbersSuccess:
            return Object.assign({}, state, {
                isFetching: false,
                billNumbers: action.billNumbers,
                billNumberMessage: ''
            });
        case legislationIdsListRequest:
            return Object.assign({}, state, {
                isFetching: true
            });
        case legislationIdsListSuccess:
            return Object.assign({}, state, {
                isFetching: false,
                legislationIdsList: action.legislationIdsList,
                legislationIdsListPagination: action.legislationIdsListPagination,
                errorMessage: ''
            });
        case legislationIdsListFailure:
            return Object.assign({}, state, {
                isFetching: false,
                errorMessage: action.errorMessage
            });
        case billListByIdsRequest:
            return Object.assign({}, state, {
                isFetching: true
            });
        case billListByIdsSuccess:
            return Object.assign({}, state, {
                isFetching: false,
                billListByIds: action.billListByIds,
                billListByIdsPagination: action.billListByIdsPagination,
                errorMessage: ''
            });
        case billListByIdsFailure:
            return Object.assign({}, state, {
                isFetching: false,
                errorMessage: action.errorMessage
            });
        case legislationTextsHitCountsRequest:
            return Object.assign({}, state, {
                isFetching: true
            });
        case legislationTextsHitCountsSuccess:
            return Object.assign({}, state, {
                isFetching: false,
                legislationTextsHitCounts: action.legislationTextsHitCounts,
                errorMessage: ''
            });
        case legislationTextsHitCountsFailure:
            return Object.assign({}, state, {
                isFetching: false,
                errorMessage: action.errorMessage
            });
        case billListRequest:
            return Object.assign({}, state, {
                isFetching: true
            });
        case billListSuccess:
            return Object.assign({}, state, {
                isFetching: false,
                billList: action.billList,
                billListPagination: action.billListPagination,
                errorMessage: ''
            });
        case billListFailure:
            return Object.assign({}, state, {
                isFetching: false,
                errorMessage: action.errorMessage
            });
        case mostFrequentBillListSuccess:
            return Object.assign({}, state, {
                isFetching: false,
                mostFrequentBillList: action.mostFrequentBillList,
                errorMessage: ''
            });
        case mostFrequentBillListFailure:
            return Object.assign({}, state, {
                isFetching: false,
                errorMessage: action.errorMessage
            });
        case billSummaryRequest:
            return Object.assign({}, state, {
                isFetching: true
            });
        case billSummarySuccess:
            return Object.assign({}, state, {
                isFetching: false,
                billSummary: action.billSummary,
                errorMessage: ''
            });
        case billSummaryFailure:
            return Object.assign({}, state, {
                isFetching: false,
                errorMessage: action.errorMessage
            });
        case saveBillSummaryRequest:
            return Object.assign({}, state, {
                isFetching: true
            });
        case saveBillSummarySuccess:
            return Object.assign({}, state, {
                isFetching: false,
                billSummarySave: action.billSummarySave,
                errorMessage: ''
            });
        case saveBillSummaryFailure:
            return Object.assign({}, state, {
                isFetching: false,
                errorMessage: action.errorMessage
            });
        case billHistorySuccess:
            return Object.assign({}, state, {
                billHistory: action.billHistory,
                billHistoryError: ''
            });
        case billHistoryFailure:
            return Object.assign({}, state, {
                billHistoryError: action.errorMessage
            });
        case billVersionRequest:
            return Object.assign({}, state, {
                isFetching: true
            });
        case billVersionSuccess:
            return Object.assign({}, state, {
                isFetching: false,
                billVersion: action.billVersion,
                errorMessage: ''
            });
        case billVersionFailure:
            return Object.assign({}, state, {
                isFetching: false,
                errorMessage: action.errorMessage
            });
        case sponsorRefListSuccess:
            return Object.assign({}, state, {
                sponsorRefList: action.sponsorRefList,
                sponsorRefListError: ''
            });
        case sponsorRefListFailure:
            return Object.assign({}, state, {
                sponsorRefListError: action.sponsorRefListError
            });
        case billVersionRefSuccess:
            return Object.assign({}, state, {
                billVersionRef: action.billVersionRef,
                billVersionRefError: ''
            });
        case billVersionRefFailure:
            return Object.assign({}, state, {
                billVersionRefError: action.billVersionRefError
            });
        case billSummaryRefSuccess:
            return Object.assign({}, state, {
                billSummaryRef: action.billSummaryRef,
                billSummaryRefError: ''
            });
        case billSummaryRefFailure:
            return Object.assign({}, state, {
                billSummaryRefError: action.billSummaryRefError
            });
        case billEventRefSuccess:
            return Object.assign({}, state, {
                billEventRef: action.billEventRef,
                billEventRefError: ''
            });
        case billEventRefFailure:
            return Object.assign({}, state, {
                billEventRefError: action.billEventRefError
            });
        case legislationEventsByIdSuccess:
            return Object.assign({}, state, {
                legislationEventsById: action.legislationEventsById,
                legislationEventsByIdError: ''
            });
        case legislationEventsByIdFailure:
            return Object.assign({}, state, {
                legislationEventsByIdError: action.legislationEventsByIdError
            });
        case billTextSuccess:
            return Object.assign({}, state, {
                billText: action.billText,
                billTextError: ''
            });
        case billTextFailure:
            return Object.assign({}, state, {
                billTextError: action.billTextError
            });
        case batchPublishSuccess:
            return Object.assign({}, state, {
                batchPublishSave: action.batchPublishSave,
                batchPublishSaveError: ''
            });
        case batchPublishFailure:
            return Object.assign({}, state, {
                batchPublishError: action.batchPublishError
            });
        case batchCompleteSuccess:
            return Object.assign({}, state, {
                batchCompleteSave: action.batchCompleteSave,
                batchCompleteSaveError: ''
            });
        case batchCompleteFailure:
            return Object.assign({}, state, {
                batchCompleteError: action.batchCompleteError
            });
        case billTextSaveSuccess:
            return Object.assign({}, state, {
                billTextSave: action.billTextSave,
                billTextSaveError: ''
            });
        case billTextSaveFailure:
            return Object.assign({}, state, {
                billTextSaveError: action.billTextSaveError
            });
        case ihodAmendmentSaveSuccess:
            return Object.assign({}, state, {
                ihodAmendmentSave: action.ihodAmendmentSave,
                ihodAmendmentSaveError: ''
            });
        case ihodAmendmentSaveFailure:
            return Object.assign({}, state, {
                ihodAmendmentSaveError: action.ihodAmendmentSaveError
            });
        case jsonHeadersSuccess:
            return Object.assign({}, state, {
                jsonHeaders: action.jsonHeaders,
                jsonHeadersError: ''
            });
        case jsonHeadersFailure:
            return Object.assign({}, state, {
                jsonHeadersError: action.jsonHeadersError
            });
        case pdfPreviewSuccess:
            return Object.assign({}, state, {
                pdfPreview: action.pdfPreview,
                pdfPreviewError: ''
            });
        case pdfPreviewFailure:
            return Object.assign({}, state, {
                pdfPreviewError: action.pdfPreviewError
            });
        case previewPdfFileSuccess:
            return Object.assign({}, state, {
                sasUrl: action.sasUrl,
                sasUrlError: ''
            });
        case previewPdfFileFailure:
            return Object.assign({}, state, {
                sasUrlError: action.sasUrlError
            });
        case htmlPreviewSuccess:
            return Object.assign({}, state, {
                htmlPreview: action.htmlPreview,
                htmlPreviewError: ''
            });
        case htmlPreviewFailure:
            return Object.assign({}, state, {
                htmlPreviewError: action.htmlPreviewError
            });
        case htmlFilePreviewSuccess:
            return Object.assign({}, state, {
                htmlFilePreview: action.htmlFilePreview,
                htmlFilePreviewError: ''
            });
        case htmlFilePreviewFailure:
            return Object.assign({}, state, {
                htmlFilePreviewError: action.htmlFilePreviewError
            });
        case billSaveSuccess:
            return Object.assign({}, state, {
                billSave: action.billSave,
                billSaveError: ''
            });
        case billSaveFailure:
            return Object.assign({}, state, {
                billSaveError: action.billSaveError
            });
        case billFileGenSuccess:
            return Object.assign({}, state, {
                billFileGen: action.billFileGen,
                billFileGenError: ''
            });
        case billFileGenFailure:
            return Object.assign({}, state, {
                billFileGenError: action.billFileGenError
            });
        case batchBillFileGenSuccess:
            return Object.assign({}, state, {
                batchBillFileGen: action.batchBillFileGen,
                batchBillFileGenError: ''
            });
        case batchBillFileGenFailure:
            return Object.assign({}, state, {
                batchBillFileGenError: action.batchBillFileGenError
            });
        case billManagementListSuccess:
            return Object.assign({}, state, {
                billManagementList: action.billManagementList,
                billManagementListError: ''
            });
        case billManagementListFailure:
            return Object.assign({}, state, {
                billManagementListError: action.billManagementListError
            });
        case sessionBillsSuccess:
            return Object.assign({}, state, {
                sessionBills: action.sessionBills,
                sessionBillsError: ''
            });
        case sessionBillsFailure:
            return Object.assign({}, state, {
                sessionBillsError: action.sessionBillsError
            });
        case billEventsSuccess:
            return Object.assign({}, state, {
                billEvents: action.billEvents,
                billEventsError: ''
            });
        case billEventsFailure:
            return Object.assign({}, state, {
                billEventsError: action.billEventsError
            });
        case billEventSaveSuccess:
            return Object.assign({}, state, {
                billEventSave: action.billEventSave,
                billEventSaveError: ''
            });
        case billEventSaveFailure:
            return Object.assign({}, state, {
                billEventSaveError: action.billEventSaveError
            });
        case textListSuccess:
            return Object.assign({}, state, {
                textList: action.textList,
                textListError: ''
            });
        case textListFailure:
            return Object.assign({}, state, {
                textListError: action.textListError
            });
        case subjectListSuccess:
            return Object.assign({}, state, {
                subjectList: action.subjectList,
                subjectListError: ''
            });
        case subjectListFailure:
            return Object.assign({}, state, {
                subjectListError: action.subjectListError
            });
        case introDateListSuccess:
            return Object.assign({}, state, {
                introDateList: action.introDateList,
                introDateListError: ''
            });
        case introDateListFailure:
            return Object.assign({}, state, {
                introDateListError: action.introDateListError
            });
        case legislationAmendmentListSuccess:
            return Object.assign({}, state, {
                legislationAmendmentList: action.legislationAmendmentList,
                legislationAmendmentListError: ''
            });
        case legislationAmendmentListFailure:
            return Object.assign({}, state, {
                legislationAmendmentListError: action.legislationAmendmentListError
            });
        case legislationDispositionsSuccess:
            return Object.assign({}, state, {
                legislationDispositions: action.legislationDispositions,
                legislationDispositionsError: ''
            });
        case legislationDispositionsFailure:
            return Object.assign({}, state, {
                legislationDispositionsError: action.legislationDispositionsError
            });
        case legislationStatusHistorySuccess:
            return Object.assign({}, state, {
                legislationStatusHistory: action.legislationStatusHistory,
                legislationStatusHistoryError: ''
            });
        case legislationStatusHistoryFailure:
            return Object.assign({}, state, {
                legislationStatusHistoryError: action.legislationStatusHistoryError
            });
        case sasURLSuccess:
            return Object.assign({}, state, {
                sasURLLink: action.sasURLLink,
                sasURLLinkError: ''
            });
        case sasURLFailure:
            return Object.assign({}, state, {
                sasURLLinkError: action.sasURLLinkError
            });
        case uploadImpactStatementRequest:
            return Object.assign({}, state, {
                uploadImpactStatementResponse: action.uploadImpactStatementResponse || state.uploadImpactStatementResponse,
                uploadImpactStatementError: action.uploadImpactStatementError || ''
            });
        case deleteBlobFileSuccess:
            return Object.assign({}, state, {
                deleteBlobFileResponse: action.deleteBlobFileResponse,
                deleteBlobFileError: ''
            });
        case deleteBlobFileFailure:
            return Object.assign({}, state, {
                deleteBlobFileError: action.deleteBlobFileError
            });
        default:
            return state;
    }
};
