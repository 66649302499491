import * as Login from './stores/lis-auth-store';
import * as Users from './stores/lis-user-store';
import * as Session from './stores/lis-session-store';
import * as Committee from './stores/lis-committee-store';
import * as Calendar from './stores/lis-calendar-store';
import * as Minutes from './stores/lis-minutes-store';
import * as Members from './stores/lis-members-store';
import * as Bills from './stores/lis-legislation-store';
import * as Communications from './stores/lis-communications-store';
import * as Patrons from './stores/lis-patron-store';
import * as Personnel from './stores/lis-personnel-store';
import * as Votes from './stores/lis-votes-store';
import * as Print from './stores/lis-print-store';
import * as Schedule from './stores/lis-schedule-store';
import * as Report from './stores/lis-report-store';
import * as Collection from './stores/lis-collection-store';
import * as Stat from './stores/lis-statistics-store';
import * as Nav from './stores/lis-nav-store';
import * as Org from './stores/lis-org-store';
import * as Dev from './stores/lis-developers-store';
import * as Journal from './stores/lis-journal-store';
import { combineReducers } from 'redux';
import { connectRouter } from 'connected-react-router';

export default (history) => combineReducers({
    router: connectRouter(history),
    login: Login.reducer,
    users: Users.reducer,
    session: Session.reducer,
    committee: Committee.reducer,
    calendar: Calendar.reducer,
    members: Members.reducer,
    bills: Bills.reducer,
    nav: Nav.reducer,
    patrons: Patrons.reducer,
    minutes: Minutes.reducer,
    personnel: Personnel.reducer,
    votes: Votes.reducer,
    communications: Communications.reducer,
    schedule: Schedule.reducer,
    print: Print.reducer,
    report: Report.reducer,
    collection: Collection.reducer,
    stats: Stat.reducer,
    organizations: Org.reducer,
    developers: Dev.reducer,
    journal: Journal.reducer
});