import React from "react";
import { Link } from "react-router-dom"
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { developersActionCreators } from "../../../stores/lis-developers-store";

class AdminDevsPortal extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      docList: ''
    }
  }

  componentDidMount() {
    this.props.actions.getDocList("?IsPartner=true");
  }

  render() {
    return (
      <div>
        <h1>LIS Admin Developers Portal</h1>        
        {this.props.developers.docList.length > 0 && this.props.developers.docList.map((b, i) => {
          return (
            <React.Fragment key={i}><Link to={{ pathname: "/admin-developers/" + b.ApiName }} className="doc-link">{b.ApiName}</Link><br /></React.Fragment>
          )
        })}
      </div>
    )
  }
}

export default connect(
  (state) => {
    const { developers } = state;
    return {
      developers
    }
  },
  (dispatch) => {
    return {
      actions: bindActionCreators(Object.assign({}, developersActionCreators), dispatch)
    }
  }
)(AdminDevsPortal)