import { publicRequest, partnerRequest } from '../services/request.service'

const API_URL = window.env ? window.env.API_SERVER : '';
const NO_CONTENT = 2;
const REQUEST_FAILURE = 1;
const REQUEST_SUCCESS = 0;

const voteLinkRequest = "GET_VOTE_LINK_REQUEST";
const voteLinkSuccess = "GET_VOTE_LINK_SUCCESS";
const voteLinkFailure = "GET_VOTE_LINK_FAILURE";

const voteDetailsRequest = "GET_VOTE_DETAILS_REQUEST";
const voteDetailsSuccess = "GET_VOTE_DETAILS_SUCCESS";
const voteDetailsFailure = "GET_VOTE_DETAILS_FAILURE";

const voteActionListRequest = "GET_VOTE_ACTION_LIST_REQUEST";
const voteActionListSuccess = "GET_VOTE_ACTION_LIST_SUCCESS";
const voteActionListFailure = "GET_VOTE_ACTION_LIST_FAILURE";

const voteListSuccess = "GET_VOTE_LIST_SUCCESS";
const voteListFailure = "GET_VOTE_LIST_FAILURE";

const voteTypesSuccess = "GET_VOTE_TYPE_SUCCESS";
const voteTypesFailure = "GET_VOTE_TYPE_FAILURE";

const voteSaveSuccess = "GET_VOTE_SAVE_SUCCESS";
const voteSaveFailure = "GET_VOTE_SAVE_FAILURE";

const voteSaveBulkSuccess = "GET_VOTE_SAVE_BULK_SUCCESS";
const voteSaveBulkFailure = "GET_VOTE_SAVE_BULK_FAILURE";

const voteFileSuccess = "GET_VOTE_FILE_GEN_SUCCESS";
const voteFileFailure = "GET_VOTE_FILE_GEN_FAILURE";

const voteSaveMotionSuccess = "SAVE_VOTE_MOTION_SUCCESS";
const voteSaveMotionFailure = "SAVE_VOTE_MOTION_FAILURE";

const initialState = {
    voteLinkResponse: [],
    voteLinkError: '',
    voteDetailsResponse: [],
    voteDetailsError: '',
    voteList: [],
    voteListError: '',
    voteTypes: [],
    voteTypesError: '',
    voteSave: [],
    voteSaveError: '',
    voteSaveBulk: '',
    voteSaveBulkError: '',
    voteFile: '',
    voteFileError: '',
    voteSaveMotion: '',
    voteSaveMotionError: '',
};

export const voteActionCreators = {
    // GET VOTE DETAILS
    requestVoteDetails: () => ({
        type: voteDetailsRequest,
        isFetching: true
    }),

    receiveVoteDetails: (response) => ({
        type: voteDetailsSuccess,
        isFetching: false,
        voteDetailsResponse: response
    }),

    voteDetailsError: message => ({
        type: voteDetailsFailure,
        isFetching: false,
        voteDetailsError: message
    }),

    getVoteDetails: (params, partner) => {
        return dispatch => {
            dispatch(voteActionCreators.requestVoteDetails());

            let request = '';
            if (partner) {
                request = partnerRequest((API_URL || process.env.REACT_APP_VOTE_API_URL) + '/Vote/api/GetPartnerVoteByIdAsync/', 'GET', '', '', params)
            } else {
                request = publicRequest((API_URL || process.env.REACT_APP_VOTE_API_URL) + '/Vote/api/GetVoteByIdAsync/', 'GET', '', '', params);
            }
            return request
                .then(result => {
                    if (result[0] === REQUEST_FAILURE) {
                        dispatch(voteActionCreators.voteDetailsError(result[1]))
                        return Promise.reject(result[1])
                    } else if (result[0] === REQUEST_SUCCESS) {
                        dispatch(voteActionCreators.receiveVoteDetails(result[1].Votes))
                    } else if (result[0] === NO_CONTENT) {
                        dispatch(voteActionCreators.voteDetailsError('No Vote Data'))
                        return Promise.reject('No Vote Data')
                    } else if (result.name === 'AbortError') {
                        return Promise.reject("Aborted");
                    }
                }).catch(err => Promise.reject(err))
        }
    },

    // VOTE LINKS CALL
    requestVoteLink: () => ({
        type: voteLinkRequest,
        isFetching: true
    }),

    receiveVoteLink: (response) => ({
        type: voteLinkSuccess,
        isFetching: false,
        voteLinkResponse: response
    }),

    voteLinkError: message => ({
        type: voteLinkFailure,
        isFetching: false,
        voteLinkError: message
    }),

    getVoteLink: (params) => {
        return dispatch => {
            dispatch(voteActionCreators.requestVoteLink());

            return publicRequest((API_URL || process.env.REACT_APP_VOTE_API_URL) + '/Vote/api/GetVoteLinksAsync/', 'GET', '', '', params)
                .then(result => {
                    if (result[0] === REQUEST_FAILURE) {
                        dispatch(voteActionCreators.voteLinkError(result[1]))
                        return Promise.reject(result[1])
                    } else if (result[0] === REQUEST_SUCCESS) {
                        dispatch(voteActionCreators.receiveVoteLink(result[1].VoteLinks))
                    } else if (result[0] === NO_CONTENT) {
                        dispatch(voteActionCreators.receiveVoteLink([]))
                        return Promise.reject('No Content')
                    }
                }).catch(err => console.log(err))
        }
    },

    // VOTE ACTION LIST CALL
    requestVoteActionList: () => ({
        type: voteActionListRequest,
        isFetching: true
    }),

    receiveVoteActionList: (response) => ({
        type: voteActionListSuccess,
        isFetching: false,
        voteActionList: response
    }),

    voteActionListError: message => ({
        type: voteActionListFailure,
        isFetching: false,
        voteActionListError: message
    }),

    getVoteActionList: (params) => {
        return dispatch => {
            dispatch(voteActionCreators.requestVoteActionList());

            return partnerRequest((API_URL || process.env.REACT_APP_VOTE_API_URL) + '/Vote/api/GetVoteActionListAsync/', 'GET', '', '', params)
                .then(result => {
                    if (result[0] === REQUEST_FAILURE) {
                        dispatch(voteActionCreators.voteActionListError(result[1]))
                        return Promise.reject(result[1])
                    } else if (result[0] === REQUEST_SUCCESS) {
                        dispatch(voteActionCreators.receiveVoteActionList(result[1].VoteActions))
                    } else if (result[0] === NO_CONTENT) {
                        dispatch(voteActionCreators.receiveVoteActionList([]))
                        return Promise.reject('No Content')
                    }
                }).catch(err => console.log(err))
        }
    },

    receiveVoteList: (response) => ({
        type: voteListSuccess,
        voteList: response
    }),

    voteListError: message => ({
        type: voteListFailure,
        voteListError: message
    }),

    getVoteList: (params) => {
        return dispatch => {
            return partnerRequest((API_URL || process.env.REACT_APP_VOTE_API_URL) + '/Vote/api/GetPartnerVoteListAsync/', 'GET', '', '', params)
                .then(result => {
                    if (result[0] === REQUEST_FAILURE) {
                        dispatch(voteActionCreators.voteListError(result[1]))
                        return Promise.reject(result[1])
                    } else if (result[0] === REQUEST_SUCCESS) {
                        dispatch(voteActionCreators.receiveVoteList(result[1].ShallowVotes))
                    } else if (result[0] === NO_CONTENT) {
                        dispatch(voteActionCreators.receiveVoteList([]))
                    } else if (result.name === 'AbortError') {
                        return Promise.reject("Aborted");
                    }
                }).catch(err => Promise.reject(err))
        }
    },

    receiveVoteTypes: (response) => ({
        type: voteTypesSuccess,
        voteTypes: response
    }),

    voteTypesError: message => ({
        type: voteTypesFailure,
        voteTypesError: message
    }),

    getVoteTypes: () => {
        return dispatch => {

            return publicRequest((API_URL || process.env.REACT_APP_VOTE_API_URL) + '/Vote/api/GetVoteTypeReferencesAsync/', 'GET', '', '', '')
                .then(result => {
                    if (result[0] === REQUEST_FAILURE) {
                        dispatch(voteActionCreators.voteTypesError(result[1]));
                        return Promise.reject(result[1]);
                    } else if (result[0] === REQUEST_SUCCESS) {
                        dispatch(voteActionCreators.receiveVoteTypes(result[1].VoteTypes));
                    } else if (result[0] === NO_CONTENT) {
                        dispatch(voteActionCreators.voteTypesError('No Content'));
                        return Promise.reject('No Content');
                    } else if (result.name === 'AbortError') {
                        return Promise.reject("Aborted");
                    }
                }).catch(err => Promise.reject(err))
        }
    },

    receiveVoteSave: (response) => ({
        type: voteSaveSuccess,
        voteSave: response
    }),

    voteSaveError: message => ({
        type: voteSaveFailure,
        voteSaveError: message
    }),

    saveVote: (body) => {
        return dispatch => {

            return partnerRequest((API_URL || process.env.REACT_APP_VOTE_API_URL) + '/Vote/api/SaveVoteAsync/', 'POST', '', body, '')
                .then(result => {
                    if (result[0] === REQUEST_FAILURE) {
                        dispatch(voteActionCreators.voteSaveError(result[1]));
                        return Promise.reject(result[1]);
                    } else if (result[0] === REQUEST_SUCCESS) {
                        dispatch(voteActionCreators.receiveVoteSave(result[1]));
                    } else if (result[0] === NO_CONTENT) {
                        dispatch(voteActionCreators.voteSaveError('No Content'));
                        return Promise.reject('No Content');
                    } else if (result.name === 'AbortError') {
                        return Promise.reject("Aborted");
                    }
                }).catch(err => Promise.reject(err))
        }
    },

    receiveVoteSaveBulk: (response) => ({
        type: voteSaveBulkSuccess,
        voteSaveBulk: response
    }),

    voteSaveBulkError: message => ({
        type: voteSaveBulkFailure,
        voteSaveBulkError: message
    }),

    saveVoteBulk: (body) => {
        return dispatch => {

            return partnerRequest((API_URL || process.env.REACT_APP_VOTE_API_URL) + '/Vote/api/PublishVotesAsync/', 'PUT', '', body, '')
                .then(result => {
                    if (result[0] === REQUEST_FAILURE) {
                        dispatch(voteActionCreators.voteSaveBulkError(result[1]));
                        return Promise.reject(result[1]);
                    } else if (result[0] === REQUEST_SUCCESS) {
                        dispatch(voteActionCreators.receiveVoteSaveBulk(result[1]));
                    } else if (result[0] === NO_CONTENT) {
                        dispatch(voteActionCreators.voteSaveBulkError('No Content'));
                        return Promise.reject('No Content');
                    } else if (result.name === 'AbortError') {
                        return Promise.reject("Aborted");
                    }
                }).catch(err => Promise.reject(err))
        }
    },

    receiveVoteFile: (response) => ({
        type: voteFileSuccess,
        voteFile: response
    }),

    voteFileError: message => ({
        type: voteFileFailure,
        voteFileError: message
    }),

    voteFileGen: (params) => {
        return dispatch => {
            return partnerRequest((API_URL || process.env.REACT_APP_VOTE_FILE_GEN_URL) + '/VoteFileGeneration/api/ProcessTallySheetFileAsync/', 'POST', '', '', params, true)
                .then(result => {
                    if (result[0] === REQUEST_FAILURE) {
                        dispatch(voteActionCreators.voteFileError(result[1]));
                        return Promise.reject(result[1]);
                    } else if (result[0] === REQUEST_SUCCESS) {
                        dispatch(voteActionCreators.receiveVoteFile(result[1]));
                    } else if (result[0] === NO_CONTENT) {
                        dispatch(voteActionCreators.voteFileError('No Content'));
                        return Promise.reject('No Content');
                    }
                }).catch(err => console.log(err))
        }
    },

    receiveVoteSaveMotion: (response) => ({
        type: voteSaveMotionSuccess,
        voteSaveMotion: response
    }),

    voteSaveMotionError: message => ({
        type: voteSaveMotionFailure,
        voteSaveMotionError: message
    }),

    saveVoteMotion: (body) => {
        return dispatch => {
            return partnerRequest((API_URL || process.env.REACT_APP_VOTE_API_URL) + '/Vote/api/VoteMotionSaveAsync/', 'POST', '', body, '')
                .then(result => {
                    if (result[0] === REQUEST_FAILURE) {
                        dispatch(voteActionCreators.voteSaveMotionError(result[1]));
                        return Promise.reject(result[1]);
                    } else if (result[0] === REQUEST_SUCCESS) {
                        dispatch(voteActionCreators.receiveVoteSaveMotion(result[1]));
                    } else if (result[0] === NO_CONTENT) {
                        dispatch(voteActionCreators.voteSaveMotionError('No Content'));
                        return Promise.reject('No Content');
                    } else if (result.name === 'AbortError') {
                        return Promise.reject("Aborted");
                    }
                }).catch(err => Promise.reject(err))
        }
    },
};

export const reducer = (state, action) => {
    state = state || initialState;

    switch (action.type) {
        case voteDetailsRequest:
            return Object.assign({}, state, {
                isFetching: true
            });
        case voteDetailsSuccess:
            return Object.assign({}, state, {
                isFetching: false,
                voteDetailsResponse: action.voteDetailsResponse,
                voteDetailsError: ''
            });
        case voteDetailsFailure:
            return Object.assign({}, state, {
                isFetching: false,
                voteDetailsError: action.voteDetailsError
            });
        case voteLinkRequest:
            return Object.assign({}, state, {
                isFetching: true
            });
        case voteLinkSuccess:
            return Object.assign({}, state, {
                isFetching: false,
                voteLinkResponse: action.voteLinkResponse,
                voteLinkError: ''
            });
        case voteLinkFailure:
            return Object.assign({}, state, {
                isFetching: false,
                voteLinkError: action.voteLinkError
            });
        case voteActionListRequest:
            return Object.assign({}, state, {
                isFetching: true
            });
        case voteActionListSuccess:
            return Object.assign({}, state, {
                isFetching: false,
                voteActionList: action.voteActionList,
                voteActionListError: ''
            });
        case voteActionListFailure:
            return Object.assign({}, state, {
                isFetching: false,
                voteActionListError: action.voteActionListError
            });
        case voteListSuccess:
            return Object.assign({}, state, {
                voteList: action.voteList,
                voteListError: ''
            });
        case voteListFailure:
            return Object.assign({}, state, {
                voteListError: action.voteListError
            });
        case voteTypesSuccess:
            return Object.assign({}, state, {
                voteTypes: action.voteTypes,
                voteTypesError: ''
            });
        case voteTypesFailure:
            return Object.assign({}, state, {
                voteTypesError: action.voteTypesError
            });
        case voteSaveSuccess:
            return Object.assign({}, state, {
                voteSave: action.voteSave,
                voteSaveError: ''
            });
        case voteSaveFailure:
            return Object.assign({}, state, {
                voteSaveError: action.voteSaveError
            });
        case voteSaveSuccess:
            return Object.assign({}, state, {
                voteSaveBulk: action.voteSaveBulk,
                voteSaveBulkError: ''
            });
        case voteSaveFailure:
            return Object.assign({}, state, {
                voteSaveBulkError: action.voteSaveBulkError
            });
        case voteFileSuccess:
            return Object.assign({}, state, {
                voteFile: action.voteFile,
                voteFileError: ''
            });
        case voteFileFailure:
            return Object.assign({}, state, {
                voteFileError: action.voteFileError
            });
        case voteSaveMotionSuccess:
            return Object.assign({}, state, {
                voteSaveMotion: action.voteSaveMotion,
                voteSaveMotionError: ''
            });
        case voteSaveMotionFailure:
            return Object.assign({}, state, {
                voteSaveMotionError: action.voteSaveMotionError
            });
        default:
            return state;
    }
};
