import React from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { Route } from 'react-router';
import VoteGrid from './lis-vote-grid';
import VoteForm from './lis-vote-form'
import moment from 'moment';
import { communicationsActionCreators } from '../../../stores/lis-communications-store';
import { minutesActionCreators } from '../../../stores/lis-minutes-store';
import { voteActionCreators } from '../../../stores/lis-votes-store';

class VoteManagement extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            startDate: moment(),
            endDate: moment(),
            chamber: null,
            committeeVal: null,
            voteOrigin: null,
            organizedVotes: [],
            isLoaded: true
        };
        this.handleDateChange = this.handleDateChange.bind(this);
        this.handleChange = this.handleChange.bind(this);
        this.getVotes = this.getVotes.bind(this);
        this.showVotes = this.showVotes.bind(this);
        this.createChairmansReportSummarizedOnMinutes = this.createChairmansReportSummarizedOnMinutes.bind(this);
    }

    handleDateChange(key, value, callback) {
        this.setState({
            [key]: value
        }, () => callback());
    }

    handleChange(key, value, callback) {
        this.setState({
            [key]: value
        }, () => {
            if (key === 'voteOrigin' || key === 'chamber') {
                this.setState({ committee: null }, () => {
                    if (callback) {
                        callback();
                    }
                })
            } else {
                if (callback) {
                    callback();
                }
            }
        });
    }

    showVotes(dateIndex, value) {
        let organizedVotes = [...this.state.organizedVotes];
        organizedVotes[dateIndex].show = typeof value === 'boolean' ? value : !organizedVotes[dateIndex].show;
        this.setState({
            organizedVotes: organizedVotes
        });
    }

    async createChairmansReportSummarizedOnMinutes(sessionID, chamberCode, committeeID, commDate, votedLegislation) {
        let success = false;
        let createObj = {
            CommunicationDate: commDate,
            IsPublic: false,
            ChamberCode: chamberCode,
            CommunicationType: 'Committee',
            CommitteeID: committeeID,
            SessionID: sessionID,
        };

        await this.props.actions.createComm({ CommunicationResponse: [createObj] })
            .then(async () => {
                success = true; //as long as it got here, succeed because it may be valid/expected for below checks to not pass
                let communication = this.props.communications.commCreate;
                if (communication.CommunicationCategories && communication.CommunicationCategories.length) {
                    const votedLegislationNumbers = votedLegislation.map(vl => vl.LegislationNumber);
                    const commCategoriesWithVotedBills = communication.CommunicationCategories.filter(cat => cat.CommunicationLegislation && cat.CommunicationLegislation.map(cl => cl.LegislationNumber).some(cl => votedLegislationNumbers.includes(cl)));
                    if (commCategoriesWithVotedBills.length) {
                        commCategoriesWithVotedBills.forEach(cat => {
                            cat.CommunicationLegislation.filter(cl => votedLegislationNumbers.includes(cl.LegislationNumber)).forEach(cl => {
                                cl.IsActive = true;
                                //set suffix of communication legislation (indicating presence of amendment or substitute) based on event code
                                const vote = votedLegislation.find(vl => vl.LegislationNumber === cl.LegislationNumber);
                                if (vote.EventCode) {
                                    if (vote.EventCode.endsWith('06') || vote.EventCode.endsWith('07')) { //reported with amendment
                                        cl.Suffix = 'A'
                                    } else if (vote.EventCode.endsWith('08')) { //reported with substitute
                                        cl.Suffix = 'S'
                                    }
                                }
                            })
                        })
                        communication.IsPublic = true;
                        await this.props.actions.saveComm({ CommunicationResponse: [communication] })
                            .then(async () => {
                                const commId = this.props.communications.commSave.CommunicationID;
                                await this.props.actions.saveCommFile("?communicationID=" + commId);
                            })
                    } else {
                        console.log("No communication categories in created communication with voted on legislation")
                    }
                } else {
                    console.log("No communication categories in created communication")
                }
            }).catch(err => {
                if (err === 'Aborted') {
                    return;
                }
                console.log(err)
                success = false;
            }).catch(err => {
                if (err === 'Aborted') {
                    return;
                }
                console.log(err)
                success = false;
            });
        return success;
    }

    getVotes() {
        const { startDate, endDate, chamber, committeeVal, voteOrigin } = this.state;
        if (startDate && endDate && chamber && voteOrigin) {
            this.setState({ isLoaded: false });
            let voteParams = `?startDate=${moment(startDate).startOf('day').format('YYYY-MM-DDTHH:mm:ss')}&endDate=${moment(endDate).endOf('day').format('YYYY-MM-DDTHH:mm:ss')}`;
            voteParams += '&chamberCode=' + chamber.value;
            voteParams += '&voteTypeID=' + voteOrigin.value;
            if (voteOrigin.label !== "Floor" && committeeVal && committeeVal.CommitteeID > 0) {
                voteParams += '&committeeID=' + committeeVal.CommitteeID
            }
            this.props.actions.getVoteList(voteParams)
                .then(() => {
                    if (this.props.votes.voteListError) {
                        throw this.props.votes.voteListError;
                    }

                    let voteList = [...this.props.votes.voteList];
                    voteList = voteList.filter(v => !v.IsVoice);
                    if (this.props.votes.voteList.length === 0) {
                        throw 'No Votes in list';
                    }
                    // Turn the list of votes into a list of dates each containing a list of votes
                    // This formatting is done so the votes can be listed in the table with a date as a container
                    voteList.sort((a, b) => {
                        return new Date(b.VoteDate) - new Date(a.VoteDate);
                    });
                    let organizedVotes = [];
                    let voteArray = [];
                    let previousVote = voteList[0];
                    voteList.forEach((vote, index) => {
                        if (moment(previousVote.VoteDate).format('L') !== moment(vote.VoteDate).format('L')) {
                            //Add the list of legislation to the category
                            let newDateContainer = {
                                Votes:
                                    chamber.value === "H" ? [...voteArray].sort((a, b) => {
                                        if (a.LegislationNumber && b.LegislationNumber && (a.FinalVote === b.FinalVote || voteOrigin.label === "Floor")) {
                                            let legislationKeyA = a.LegislationNumber.match(/\d+/);
                                            let legislationKeyB = b.LegislationNumber.match(/\d+/);
                                            return a.LegislationNumber[0].localeCompare(b.LegislationNumber[0]) ||
                                                a.LegislationNumber[1].localeCompare(b.LegislationNumber[1]) ||
                                                (legislationKeyA && legislationKeyA.length && legislationKeyB && legislationKeyB.length && parseInt(legislationKeyA[0]) - parseInt(legislationKeyB[0])) ||
                                                new Date(a.VoteDate) - new Date(b.VoteDate)
                                        } else if (a.FinalVote) {
                                            return 1
                                        } else {
                                            return -1
                                        }
                                    })
                                        :
                                        [...voteArray].sort((a, b) => {
                                            if (a.FinalVote === b.FinalVote || voteOrigin.label === "Floor") {
                                                return a.VoteNumber.localeCompare(b.VoteNumber)
                                            } else if (a.FinalVote) {
                                                return 1
                                            } else {
                                                return -1
                                            }
                                        }),
                                Date: previousVote.VoteDate,
                                sortTimeDesc: false
                            };
                            organizedVotes.push(newDateContainer);
                            //Empty out the leg array so we can use it for another category
                            voteArray = [];
                            //Add the first item to the next category
                            voteArray.push(vote);
                        } else {
                            voteArray.push(vote);
                        }
                        //Set this variable so the next iteration will know what the vote date of the previous iteration was
                        previousVote = vote;
                    });
                    //If the vote array still has elements then that means not every vote in the results have been added to a container
                    //So, check to see if the vote array has elements and if so create another container
                    if (voteArray.length > 0) {
                        let newDateContainer = {
                            Votes:
                                chamber.value === "H" ? [...voteArray].sort((a, b) => {
                                    if (a.FinalVote === b.FinalVote || voteOrigin.label === "Floor") {
                                        let legislationKeyA = a.LegislationNumber.match(/\d+/);
                                        let legislationKeyB = b.LegislationNumber.match(/\d+/);
                                        return a.LegislationNumber[0].localeCompare(b.LegislationNumber[0]) ||
                                            a.LegislationNumber[1].localeCompare(b.LegislationNumber[1]) ||
                                            (legislationKeyA && legislationKeyA.length && legislationKeyB && legislationKeyB.length && parseInt(legislationKeyA[0]) - parseInt(legislationKeyB[0])) ||
                                            new Date(a.VoteDate) - new Date(b.VoteDate)
                                    } else if (a.FinalVote) {
                                        return 1
                                    } else {
                                        return -1
                                    }
                                })
                                    :
                                    [...voteArray].sort((a, b) => {
                                        if (a.FinalVote === b.FinalVote || voteOrigin.label === "Floor") {
                                            return a.VoteNumber.localeCompare(b.VoteNumber)
                                        } else if (a.FinalVote) {
                                            return 1
                                        } else {
                                            return -1
                                        }
                                    }),
                            Date: previousVote.VoteDate,
                            sortTimeDesc: false
                        };
                        organizedVotes.push(newDateContainer);
                    }

                    const currentlyShownOrganizedVotes = [...this.state.organizedVotes].filter(date => date.show);
                    currentlyShownOrganizedVotes.forEach(date => {
                        const newOrganizedVoteDate = organizedVotes.find(newDate => newDate.Date === date.Date);
                        if (newOrganizedVoteDate) {
                            newOrganizedVoteDate.show = true;
                        }
                    });
                    this.setState({
                        isLoaded: true,
                        organizedVotes: organizedVotes
                    });
                })
                .catch((err) => {
                    if (err === 'Aborted') {
                        return;
                    }
                    console.log(err);
                    this.setState({
                        isLoaded: true,
                        organizedVotes: []
                    });
                });
        }
    }

    render() {
        const { startDate, endDate, voteOrigin, committee, chamber, organizedVotes, isLoaded } = this.state;
        return (
            <div>
                <Route exact path={this.props.match.path} render={props => <VoteGrid
                    {...props}
                    startDate={startDate}
                    endDate={endDate}
                    voteOrigin={voteOrigin}
                    committeeVal={committee}
                    chamber={chamber}
                    handleDateChange={this.handleDateChange}
                    handleChange={this.handleChange}
                    getVotes={this.getVotes}
                    organizedVotes={organizedVotes}
                    isLoaded={isLoaded}
                    showVotes={this.showVotes}
                    createChairmansReportSummarizedOnMinutes={this.createChairmansReportSummarizedOnMinutes}
                />} />
                <Route path={`${this.props.match.path}/edit-vote/:voteid`} render={props => <VoteForm
                    {...props}
                    createChairmansReportSummarizedOnMinutes={this.createChairmansReportSummarizedOnMinutes}
                />} />
            </div>
        )
    }
}

export default connect(
    (state) => {
        const { votes, communications, minutes } = state;
        return {
            votes,
            communications,
            minutes
        }
    },
    (dispatch) => {
        return {
            actions: bindActionCreators(Object.assign({}, voteActionCreators, communicationsActionCreators, minutesActionCreators), dispatch)
        }
    }
)(VoteManagement)