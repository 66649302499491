import React from 'react';
import { Link } from 'react-router-dom';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { authActionCreators } from '../../../stores/lis-auth-store';
import { developersActionCreators } from '../../../stores/lis-developers-store';
import { sessionActionCreators } from '../../../stores/lis-session-store';
import { navActionCreators } from '../../../stores/lis-nav-store';
import WatchlistsComponent from '../../../lis-public/components/lis-watchlists';

class ProfileForm extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            apiKeys: [],
            apiKeyIsLoading: true
        };

        this.getApiKeys = this.getApiKeys.bind(this);
        this.handleCopy = this.handleCopy.bind(this);
    }

    componentDidMount() {
        this.getApiKeys();
    }

    getApiKeys() {
        this.setState({ apiKeyIsLoading: true })
        this.props.actions.getPartnerKey()
            .then(() => {
                this.setState({
                    apiKeys: this.props.developers.partnerKey,
                    apiKeyIsLoading: false
                });
            }).catch(err => {
                if (err === 'Aborted') {
                    return
                }
                this.setState({
                    apiKeyIsLoading: false
                });
            });
    }

    handleCopy(key, element) {
        navigator.clipboard.writeText(key);
        element.disabled = true;
        const delay = ms => new Promise(res => setTimeout(res, ms));
        element.textContent = 'Copied to clipboard!'
        delay(3000).then(() => {
            element.textContent = "Copy"
            element.disabled = false;
        });
    }

    render() {
        const { userProfile } = this.props.login;
        let email = userProfile.email || "";
        if (email.includes("#")) {
            email = email.replace(/#([^#]*)$/, '@$1');
            email = email.replace("@dlasva.onmicrosoft.com", '');
        }
        const canCreateMultipleWatchlists = Boolean(this.props.login.userClaims.claims.find(claim => claim.Scope === "Paid" && claim.Resource === "LegislationCollections" && claim.RoleName === "PaidLegislationCollectionsAuthor")) ||  Boolean(this.props.login.userClaims.resources.find(resource => resource === "PaidLegislationCollectionsAuthor" || resource === "All"));

        return (
            <div className="dlas-forms">
                <div className="public-details">
                    <div>
                        <h2>My Profile</h2>
                    </div>
                    <div>
                        <h3>Name</h3>
                        <p>{userProfile.name}</p>
                        <br />
                        <h3>Email</h3>
                        <p>{email}</p>
                        <br />
                        <h3>{canCreateMultipleWatchlists ? "Watchlists" : "Watchlist"}</h3>
                        {
                            <WatchlistsComponent isProfilePage />
                        }
                        <br />
                        <h3>{this.state.apiKeys.length > 1 ? 'API Keys' : 'API Key'}</h3>
                        {this.state.apiKeyIsLoading ?
                            <div className="spinner"></div>
                            :
                            this.state.apiKeys.length > 0 ?
                                this.state.apiKeys.map((key, idx) =>
                                    <div key={idx}>
                                        <span style={{ fontSize: '14px', marginRight: '5px' }}>{key.APIKey}</span>
                                        <button className="button" type="button" style={{ padding: '3px 5px' }} onClick={(e) => this.handleCopy(key.APIKey, e.target)}>Copy</button>
                                    </div>
                                )
                                :
                                <p>Your email ({`${email}`}) is not currently associated with an API key. Learn more about API keys <a target="blank" rel="noreferrer" style={{ fontSize: '14px' }} href={'/developers'}>here</a>.</p>
                        }
                    </div>
                    <br />
                    <div>
                        <h3>Account Settings</h3>
                        <Link to={'/change-password'} style={{ fontSize: '14px', textDecoration: "none" }}>Change Password</Link>
                    </div>
                </div>
            </div>
        );

    }
};

export default connect(
    (state) => {
        const { login, developers, session, nav } = state;
        return {
            login,
            developers,
            session,
            nav
        }
    },
    (dispatch) => {
        return {
            actions: bindActionCreators(Object.assign({}, authActionCreators, developersActionCreators, sessionActionCreators, navActionCreators), dispatch)
        }
    }
)(ProfileForm);





