import React from 'react';
import CreatableSelect from 'react-select/creatable';

const MERGE = "MERGE";
const DELETE = "DELETE";

class CollectionControls extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            showPopup: { DELETE: false, MERGE: false },
            selectedWatchlists: [],
            selectedMergeWatchlists: [],
            editWatchlistName: false,
            newName: '',
            newDescription: ''
        };
        this.togglePopup = this.togglePopup.bind(this);
        this.handleTextChange = this.handleTextChange.bind(this);
        this.deleteCollection = this.deleteCollection.bind(this);
        this.handleWatchlistChange = this.handleWatchlistChange.bind(this);
        this.handleMergeWatchlistChange = this.handleMergeWatchlistChange.bind(this);
        this.toggleEditWatchlistName = this.toggleEditWatchlistName.bind(this);
        this.saveWatchlistName = this.saveWatchlistName.bind(this);
        this.addToWatchlists = this.addToWatchlists.bind(this);
    }


    togglePopup(type) {
        let showPopup = { ...this.state.showPopup };
        showPopup[type] = !showPopup[type];
        this.setState({
            showPopup
        });
    }

    handleTextChange(key, val) {
        this.setState({
            [key]: val
        })
    }

    deleteCollection() {
        let showPopup = { ...this.state.showPopup };
        showPopup[DELETE] = !showPopup[DELETE];
        this.setState({
            showPopup
        });
        this.props.deleteCollection();
    }

    handleWatchlistChange(val) {
        this.setState({
            selectedWatchlists: val
        });
    }

    handleMergeWatchlistChange(val) {
        this.setState({
            selectedMergeWatchlists: val
        });
    }

    toggleEditWatchlistName() {
        this.setState(state => ({
            editWatchlistName: !state.editWatchlistName,
            newName: this.props.selectedCollection.Name || '',
            newDescription: this.props.selectedCollection.Description || ''
        }));
    }

    saveWatchlistName() {
        let selectedCollection = { ...this.props.selectedCollection };
        selectedCollection.Name = this.state.newName;
        selectedCollection.Description = this.state.newDescription;
        this.props.handleCollectionChange(selectedCollection);
        this.setState({
            editWatchlistName: false
        });
    }

    addToWatchlists(merge) {
        let watchlists = merge ? JSON.parse(JSON.stringify(this.state.selectedMergeWatchlists)) : JSON.parse(JSON.stringify(this.state.selectedWatchlists));
        this.props.addToWatchlists(watchlists, merge, (saveResultCollections) => {
            let showPopup = { ...this.state.showPopup };
            if (!merge) {
                // they are adding bills to one or more watchlist(s) - we need to update the selectedWatchlists state values because their mod dates have now changed
                for (let i = 0; i < watchlists.length; i++) {
                    const associatedWatchlist = saveResultCollections.find(c => c.WatchListID === watchlists[i].WatchListID);
                    if (associatedWatchlist && associatedWatchlist.ModificationDate !== watchlists[i].ModificationDate) {
                        watchlists[i] = associatedWatchlist
                    };
                }
            } else {
                // they are merging - we need to close the popup, and we also set the selectedMergeWatchlists state value to empty array because --
                // there's no need to go through the above loop just to be able to keep the same watchlists selected considering they've already been merged and it's pointless to do it again
                showPopup[MERGE] = !showPopup[MERGE];
            }
            this.setState({ selectedWatchlists: !merge ? watchlists : [], selectedMergeWatchlists: [], showPopup })
        });
    }

    componentDidUpdate(prevProps) {
        if (this.props.selectedCollection.WatchListID !== prevProps.selectedCollection.WatchListID) {
            this.setState({
                editWatchlistName: false
            });
        }
    }

    render() {
        const { selectedCollection, selectedCollectionIndex, calendarHeader, collections, searchMade } = this.props;
        const { selectedWatchlists, selectedMergeWatchlists } = this.state;
        if (!selectedCollection && !calendarHeader && (this.props.isBillManagement || !searchMade)) {
            return null;
        }
        const buttonDisabled = !selectedWatchlists || !selectedWatchlists.length || this.props.checkedBills.every(c => !c || this.props.collectionIsSaving);
        const mergeButtonDisabled = !selectedMergeWatchlists || !selectedMergeWatchlists.length;
        if (!selectedCollection && !calendarHeader) {
            return (
                <div className="dlas-forms collection-controls" style={{ marginBottom: "5px" }}>
                    <h4 style={{ margin: 0 }}>Add bills from search results to one or more watchlists</h4>
                    <div className="inner-grid two">
                        <div>
                            <label htmlFor="sr-watchlist-name-control">Watchlist Name</label>
                            <CreatableSelect
                                id="sr-watchlist-name-control"
                                styles={selectStyle}
                                options={collections}
                                getOptionLabel={opt => opt.label || `${opt.Name} ${opt.Description && `- ${opt.Description}`}`}
                                getOptionValue={opt => opt.value || opt.WatchListID}
                                value={selectedWatchlists}
                                onChange={this.handleWatchlistChange}
                                createOptionPosition="first"
                                formatCreateLabel={input => <span>Create new: {input}</span>}
                                isMulti
                            />
                        </div>
                        <div className="inline-list">
                            <br />
                            {this.props.collectionIsSaving ?
                                <div><span className="small-spinner"></span></div>
                                :
                                <React.Fragment>
                                    <button disabled={buttonDisabled} style={{ marginLeft: "0px" }} className="button" type="button" onClick={() => this.addToWatchlists(false)}>Add</button>
                                </React.Fragment>
                            }
                        </div>
                    </div>
                </div>
            );
        }
        return (
            <div>
                {this.state.showPopup[DELETE] &&
                    <div className='popup'>
                        <div className='popup-inner'>
                            <p>Are you sure you want to delete? Once a Watchlist is deleted it cannot be restored.</p>
                            <div className="inline-list">
                                <button className="button danger" disabled={this.props.collectionIsSaving} onClick={this.deleteCollection}>Yes</button>
                                <button className="button secondary" disabled={this.props.collectionIsSaving} onClick={() => this.togglePopup(DELETE)}>No</button>
                            </div>
                        </div>
                    </div>
                }
                {this.state.showPopup[MERGE] &&
                    <div className='popup'>
                        <div className='popup-inner'>
                            <p>Select the watchlist(s) into which you want to merge the current watchlist.</p>
                            <div className="inner-grid" style={{ gridTemplateColumns: '50% 50%' }}>
                                <div>
                                    <CreatableSelect
                                        id="sr-watchlist-name-control"
                                        styles={selectStyle}
                                        options={collections.filter(c => c.WatchListID !== selectedCollection.WatchListID)}
                                        getOptionLabel={opt => opt.label || `${opt.Name}`}
                                        getOptionValue={opt => opt.value || opt.WatchListID}
                                        value={selectedMergeWatchlists}
                                        onChange={this.handleMergeWatchlistChange}
                                        createOptionPosition="first"
                                        formatCreateLabel={input => <span>Create new: {input}</span>}
                                        isMulti
                                    />
                                </div>
                                <div className="inline-list">
                                    {this.props.collectionIsSaving ?
                                        <div><span className="small-spinner"></span></div>
                                        :
                                        <React.Fragment>
                                            <button disabled={mergeButtonDisabled} style={{ marginLeft: "0px" }} className="button" type="button" onClick={() => this.addToWatchlists(true)}>Merge</button>
                                            <button className="button secondary" onClick={() => this.togglePopup(MERGE)}>Cancel</button>
                                        </React.Fragment>
                                    }
                                </div>
                            </div>
                        </div>
                    </div>
                }
                <div className="collection-controls inner-grid" style={{ gridTemplateColumns: 'auto max-content' }}>
                    {this.state.editWatchlistName ?
                        <div className="dlas-forms flex-row flex-start inline-list edit-watchlist-name">
                            <div className="label-above-input">
                                <label htmlFor="sr-selected-watchlist-name" className="label">Name {!this.state.newName && <span className="fail">required</span>}</label>
                                <input
                                    id="sr-selected-watchlist-name"
                                    value={this.state.newName}
                                    placeholder="Name"
                                    onChange={e => this.handleTextChange('newName', e.target.value)}
                                />
                            </div>
                            <div className="label-above-input">
                                <label htmlFor="sr-selected-watchlist-desc" className="label">Description</label>
                                <input
                                    id="sr-selected-watchlist-desc"
                                    value={this.state.newDescription}
                                    placeholder="Description"
                                    onChange={e => this.handleTextChange('newDescription', e.target.value)}
                                />
                            </div>
                            {this.props.collectionIsSaving ?
                                <div className="small-spinner"></div>
                                :
                                <div className="inline-list save-or-delete">
                                    <button aria-label="save" onClick={this.saveWatchlistName} disabled={!this.state.newName} type="button" className="icon save"></button>
                                    <button aria-label="cancel" onClick={this.toggleEditWatchlistName} type="button" className="icon delete"></button>
                                </div>
                            }
                        </div>
                        :
                        <div className="watchlist-info">
                            <div className="inline-list">
                                <span className="name">{selectedCollection ? selectedCollection.Name : calendarHeader ? calendarHeader.Name : ''}</span>
                                {!this.props.isBillManagement && <div role="button" aria-label="edit watchlist name and description" className="icon edit" onClick={() => !this.props.collectionIsSaving && this.toggleEditWatchlistName()}></div>}
                            </div>
                            <span className="description">{selectedCollection ? selectedCollection.Description : calendarHeader ? calendarHeader.Description : ''}</span>
                        </div>
                    }
                    <div className="close-or-delete-watchlist align-right inline-list">
                        <br />
                        {!this.props.isBillManagement && !searchMade &&
                            <React.Fragment>
                                <button type="button" disabled={this.props.collectionIsSaving} onClick={() => this.togglePopup(MERGE)} className="button primary">Merge watchlist</button>
                                <button type="button" disabled={this.props.collectionIsSaving} onClick={() => this.togglePopup(DELETE)} className="button danger">Delete watchlist</button>
                            </React.Fragment>
                        }
                        {searchMade && !calendarHeader ?
                            <button type="button" disabled={this.props.collectionIsSaving} onClick={() => this.props.selectCollection(selectedCollectionIndex, true)} className="button secondary">Back to watchlist</button>
                            :
                            <button type="button" disabled={this.props.collectionIsSaving} onClick={() => this.props.selectCollection(-1)} className="button secondary" style={{ marginRight: '0px' }}>Close watchlist</button>
                        }
                    </div>
                </div>
                <br />
            </div>
        )
    }
}

const selectStyle = {
    control: styles => ({
        ...styles,
        minHeight: '0px',
        padding: '0.12em 0.6em !important',
    }),
    valueContainer: styles => ({
        ...styles,
        padding: 0,
    }),
    input: styles => ({
        ...styles,
        fontSize: '0.8em',
        lineHeight: 'normal',
    }),
    singleValue: styles => ({
        ...styles,
        fontSize: '0.8em'
    }),
    placeholder: styles => ({
        ...styles,
        fontSize: '0.8em'
    }),
    option: styles => ({
        ...styles,
        fontSize: '0.8em'
    }),
    dropdownIndicator: styles => ({
        ...styles,
        padding: '1px'
    }),
}

export default CollectionControls;
