import React from 'react';
import moment from 'moment';


class SearchSelections extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
        };
    };

    render() {
        return (
            <div className="search-selections">
                <span className="txt-greyed small-text">Your Search</span>
                <div>
                    <Selection
                        value={this.props.selectedBillNumbers}
                        name={"selectedBillNumbers"}
                        clear={this.props.handleSelectionClear}
                    />
                    <Selection
                        value={this.props.selectedBillRangeBeginning}
                        name={"selectedBillRangeBeginning"}
                        title="Bill Range Start"
                        clear={this.props.handleSelectionClear}
                    />
                    <Selection
                        value={this.props.selectedBillRangeEnd}
                        name={"selectedBillRangeEnd"}
                        title="Bill Range End"
                        clear={this.props.handleSelectionClear}
                    />
                    <Selection
                        value={this.props.selectedChapterNumber}
                        name={"selectedChapterNumber"}
                        clear={this.props.handleSelectionClear}
                    />
                    <Selection
                        value={this.props.selectedKeywords}
                        name={"selectedKeywords"}
                        clear={this.props.handleSelectionClear}
                    />
                    <Selection
                        value={this.props.currentStatus && this.props.selectedBillStatusCategory ? this.props.selectedSession && !this.props.selectedSession.IsActive ? 'Final Status' : 'Current Status' : null}
                        name={"currentStatus"}
                        clear={this.props.handleSelectionClear}
                    />
                    <Selection
                        value={this.props.selectedBillStatusCategory ? this.props.selectedBillStatusCategory.label : null}
                        name={"selectedBillStatusCategory"}
                        clear={this.props.handleSelectionClear}
                    />
                    <Selection
                        value={this.props.billStatusStartDate ? moment(this.props.billStatusStartDate).format("MM/DD/YYYY") : null}
                        name={"billStatusStartDate"}
                        title="Status Start Date"
                        clear={null}
                    />
                    <Selection
                        value={this.props.billStatusEndDate ? moment(this.props.billStatusEndDate).format("MM/DD/YYYY") : null}
                        name={"billStatusEndDate"}
                        title="Status End Date"
                        clear={null}
                    />
                    <Selection
                        value={this.props.selectedCommittee ? this.props.selectedCommittee.label : null}
                        name={"selectedCommittee"}
                        clear={this.props.handleSelectionClear}
                    />
                    <Selection
                        value={this.props.selectedSubcommittee ? this.props.selectedSubcommittee.label : null}
                        name={"selectedSubcommittee"}
                        clear={this.props.handleSelectionClear}
                    />
                    <Selection
                        value={this.props.selectedPatron ? this.props.selectedPatron.label : null}
                        name={"selectedPatron"}
                        clear={this.props.handleSelectionClear}
                    />
                    {this.props.selectedPatronType.map((type, typeIndex) =>
                        <Selection
                            key={typeIndex}
                            index={typeIndex}
                            value={type.label}
                            name={"selectedPatronType"}
                            clear={this.props.handleMultiSelectionClear}
                        />
                    )}
                    <Selection
                        value={this.props.selectedChamber ? this.props.selectedChamber.label : null}
                        name={"selectedChamber"}
                        clear={this.props.handleSelectionClear}
                    />
                    <Selection
                        value={this.props.selectedBillType ? this.props.selectedBillType.label : null}
                        name={"selectedBillType"}
                        clear={this.props.handleSelectionClear}
                    />
                    <Selection
                        value={this.props.selectedSubject ? this.props.selectedSubject.label : null}
                        name={"selectedSubject"}
                        clear={this.props.handleSelectionClear}
                    />
                    <Selection
                        value={this.props.excludeFailed ? "Exclude Failed" : null}
                        name={"excludeFailed"}
                        clear={this.props.handleSelectionClear}
                    />
                    {this.props.updateSearch &&
                        <UpdateSearch
                            updateSearch={this.props.updateSearch}
                        />
                    }
                </div>
                <hr className="faded-line" />
            </div>
        )
    }
}

const Selection = props => {
    const { title, value, name, index, clear } = props;
    if (value) {
        return (<div className="selection-bubble">
            <div className="flex-row">
                <span>{title ? `${title}: ${value.length > 100 ? value.substring(0, 100) + '...' : value}` : value.length > 100 ? value.substring(0, 100) + '...' : value}</span>
                {clear && <button aria-label="remove item" onClick={() => clear(name, index)} className="icon cross-blue"></button>}
            </div>
        </div>);
    } else {
        return (false);
    }

}

const UpdateSearch = props => {
    const { updateSearch } = props;
    return (
        <div className="selection-bubble button">
            <div className="flex-row" onClick={() => updateSearch()}>
                <button className="button small">Update Search</button>
            </div>
        </div>);
}

export default SearchSelections;
