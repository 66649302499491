import React from 'react';
import ReactDOM from 'react-dom';
import tinymce from 'tinymce/tinymce';

function copyStyles(sourceDoc, targetDoc) {
    Array.from(sourceDoc.styleSheets).forEach(styleSheet => {
        if (styleSheet.cssRules) { // true for inline styles
            const newStyleEl = sourceDoc.createElement('style');

            Array.from(styleSheet.cssRules).forEach(cssRule => {
                newStyleEl.appendChild(sourceDoc.createTextNode(cssRule.cssText));
            });

            targetDoc.head.appendChild(newStyleEl);
        } else if (styleSheet.href) { // true for stylesheets loaded from a URL
            const newLinkEl = sourceDoc.createElement('link');

            newLinkEl.rel = 'stylesheet';
            newLinkEl.href = styleSheet.href;
            targetDoc.head.appendChild(newLinkEl);
        }
    });
}

class Popout extends React.Component {
    constructor(props) {
        super(props);

        this.containerEl = document.createElement('div'); 
        
        // open a new browser window and store a reference to it
        const width = this.props.windowWidth || 900;
        const height = this.props.windowHeight || 500;
        this.externalWindow = window.open('', '', `width=${width},height=${height},left=200,top=200`);

        // append the container <div> (that has props.children appended to it) to the body of the new window
        this.externalWindow.document.body.appendChild(this.containerEl);

        this.externalWindow.document.title = this.props.windowTitle;
        copyStyles(document, this.externalWindow.document);

    }

    componentDidMount() {
        // update the state in the parent component if the user closes the new window
        this.externalWindow.addEventListener('beforeunload', () => {
            this.props.togglePortal(true);
        });
    }

    
    componentWillUnmount() {
        this.externalWindow.close();
    }

    render() {
        return ReactDOM.createPortal(this.props.children, this.containerEl);
    }
}

export default Popout;