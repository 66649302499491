import React from 'react';
import { Link } from 'react-router-dom';

class ConfigurationsGrid extends React.Component {
    render() {
        return (
            <div>
                <div className="configurations configurations-icon-links">
                    <div className="grid-wrapper three">
                        <div className="configurations">
                            <Link className="configurations info-link calendar" to="/configurations-management/calendar"></Link>
                            <Link to="/configurations-management/calendar">Calendar</Link>
                        </div>
                        <div className="configurations">
                            <Link className="configurations info-link communications" to="/configurations-management/communications"></Link>
                            <Link to="/configurations-management/communications">Communications</Link>
                        </div>
                        <div className="configurations">
                            <a className="configurations info-link category-action"></a>
                            <a>Category Action<br/>(Coming Soon)</a>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default ConfigurationsGrid