import React from 'react';
import ReactTooltip from 'react-tooltip';
import Select from 'react-select';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { calendarActionCreators } from "../../stores/lis-calendar-store";
import { navActionCreators } from "../../stores/lis-nav-store";
import ConfigurationsTable from './lis-configurations-table';
import { billActionCreators } from '../../stores/lis-legislation-store';

const EVENT = 0;
const CHAMBER = 1;
const TYPE = 2;
const CLASS = 3;
const PASSED = 4;
const UNCONTESTED = 5;
const CATEGORY = 6;

const uniqueIdentifier = "CalendarAssignmentID";
const uniqueCategoryIdentifier = "CalendarCategoryTypeID";
const categoryChamberIdentifier = "calendarCategoryChamber";

const requiredFields = ["EventCode", "LegislationChamberCode", "LegislationTypeCode", "CategoryCode"];

const newAssignmentFreshValues = {
    CategoryCode: null,
    EventCode: null,
    p_LegislationDescription: null,
    LegislationClass: null,
    LegislationTypeCode: null,
    LegislationChamberCode: null,
    IsPassed: null,
    IsUncontested: null,
    [categoryChamberIdentifier]: null,
    new: true
};

class CalendarConfigurations extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            assignments: [],
            adminAssignments: [],
            categories: [],
            legislationClassOptions: [],
            loadedCategories: false,
            loadedAssignments: false,
            loadedAdminAssignments: false,
            loadedLegislationClassOptions: false,
            categoriesError: false,
            assignmentsError: false,
            legislationClassOptionsError: false,
            adminAssignmentsError: false,
            newAssignment: newAssignmentFreshValues,
            duplicates: [],
            showDifferences: {},
            saving: {},
            editing: {},
            showAllDifferences: false,
            filteringMismatches: false,
            assignmentMismatches: []
        };

        this.getCategories = this.getCategories.bind(this);
        this.getAssignments = this.getAssignments.bind(this);
        this.getAdminAssignments = this.getAdminAssignments.bind(this);
        this.getLegislationClasses = this.getLegislationClasses.bind(this);
        this.handleAssignmentChange = this.handleAssignmentChange.bind(this);
        this.sort = this.sort.bind(this);
        this.handleSave = this.handleSave.bind(this);
        this.showDifferences = this.showDifferences.bind(this);
        this.buttonText = this.buttonText.bind(this);
        this.checkDuplicates = this.checkDuplicates.bind(this);
        this.toggleEdit = this.toggleEdit.bind(this);
        this.getUneditedAssignment = this.getUneditedAssignment.bind(this);
        this.toggleDifferences = this.toggleDifferences.bind(this);
        this.findDifferences = this.findDifferences.bind(this);
    }

    componentDidMount() {
        this.getCategories();
        this.getAssignments();
        this.getAdminAssignments();
        this.getLegislationClasses();
    }

    getCategories() {
        this.setState({ loadedCategories: false }, () => {
            this.props.actions.getCalendarCategoriesReferences('')
                .then(() => {
                    this.setState({
                        loadedCategories: true,
                        categories: [...this.props.calendar.calendarCategoriesReferences].sort((a, b) => a.ChamberCode.localeCompare(b.ChamberCode) || a.CategoryCode.localeCompare(b.CategoryCode))
                    });
                }).catch(err => {
                    if (err === "Aborted") {
                        return;
                    }
                    console.error(err);
                    this.setState({ loadedCategories: true, categories: [], categoriesError: true })
                });
        });
    }

    getAssignments() {
        this.setState({ loadedAssignments: false }, () => {
            this.props.actions.getCalendarAssignments().then(() => {
                let assignments = JSON.parse(JSON.stringify(this.props.calendar.calendarAssignments));
                assignments.sort((a, b) => a.CategoryCode.localeCompare(b.CategoryCode))

                this.setState({
                    loadedAssignments: true,
                    assignments
                })
            }).catch(err => {
                if (err === "Aborted") {
                    return;
                }
                console.error(err);
                this.setState({ loadedAssignments: true, assignments: [], assignmentsError: true })
            })
        });
    }

    getAdminAssignments() {
        this.setState({ loadedAdminAssignments: false }, () => {
            this.props.actions.getAdminCalendarAssignments().then(() => {
                let adminAssignments = JSON.parse(JSON.stringify(this.props.calendar.adminCalendarAssignments));

                this.setState({
                    loadedAdminAssignments: true,
                    adminAssignments
                })
            }).catch(err => {
                if (err === "Aborted") {
                    return;
                }
                console.error(err);
                this.setState({ loadedAdminAssignments: true, adminAssignments: [], adminAssignmentsError: true })
            })
        });
    }

    getLegislationClasses() {
        this.setState({ loadedLegislationClassOptions: false }, () => {
            this.props.actions.getLegislationClasses().then(() => {
                let legislationClassOptions = [...this.props.bills.legislationClasses];
                legislationClassOptions.forEach(legClass => {
                    legClass.label = legClass.LegislationClass;
                    legClass.value = legClass.LegislationClassID;
                });

                this.setState({ loadedLegislationClassOptions: true, legislationClassOptions });
            }).catch(err => {
                if (err === 'Aborted') {
                    return;
                }
                console.log(err);
                this.setState({ loadedLegislationClassOptions: true, legislationClassOptions: [], legislationClassOptionsError: true })
            });
        })
    }

    handleAssignmentChange(uid, key, value) {
        let assignments = [...this.state.assignments];
        let assignment;
        if (uid) {
            assignment = assignments.find(ca => ca[uniqueIdentifier] === uid);
        } else {
            assignment = { ...this.state.newAssignment }
        }
        switch (key) {
            case EVENT:
                assignment.EventCode = value.label;
                break;
            case CHAMBER:
                assignment.LegislationChamberCode = value ? value.label : null;
                break;
            case TYPE:
                assignment.LegislationTypeCode = value.label;
                break;
            case PASSED:
                assignment.IsPassed = value.label;
                break;
            case CLASS:
                assignment.LegislationClass = value ? value.LegislationClass : null;
                assignment.LegislationClassID = value ? value.LegislationClassID : null;
                break;
            case UNCONTESTED:
                assignment.IsUncontested = value.label;
                break;
            case CATEGORY:
                assignment[uniqueCategoryIdentifier] = value[uniqueCategoryIdentifier];
                assignment.CategoryCode = value.CategoryCode;
                assignment[categoryChamberIdentifier] = value.ChamberCode;
                break;
        }

        let duplicates = [...this.state.duplicates];
        //if there is an event code and legislationchambercode selected, this is enough to show the user the associated legislationdescription
        if (assignment.EventCode && assignment.LegislationChamberCode !== null) {
            const referenceEvent = [...this.props.billEventOptions].find(b => b.EventCode === assignment.EventCode && b.LegislationChamberCode === assignment.LegislationChamberCode && (assignment.IsPassed === null || assignment.IsPassed === b.IsPassed));
            assignment.p_LegislationDescription = referenceEvent ? referenceEvent.LegislationDescription : null;

            //if they have chosen an option for every required field (except category code), check to see if this is a duplicate entry, in which case prevent saving
            if (requiredFields.every(val => assignment[val] !== null) && this.checkDuplicates(assignment)) {
                duplicates.push({ uid: assignment[uniqueIdentifier] })
            } else {
                //there isn't a duplicate or there isn't enough values yet to determine; remove it from the duplicates if it's there
                duplicates = duplicates.filter(d => d.uid !== assignment[uniqueIdentifier])
            }
        } else {
            //there isn't enough info to determine the legislation description of the event, so set to null
            assignment.p_LegislationDescription = null
            //can't determine if there's a duplicate since we don't have enough values; remove it from the list of duplicates if it's there
            duplicates = duplicates.filter(d => d.uid !== assignment[uniqueIdentifier])
        }

        if (uid) {
            this.setState({ assignments, duplicates })
        } else {
            this.setState({ newAssignment: assignment, duplicates })
        }
    }

    checkDuplicates(editedAssignment) {
        const assignments = [...this.state.assignments];
        if (!editedAssignment[categoryChamberIdentifier]) {
            //assign the categoryChamberIdentifier if it hasn't been yet
            editedAssignment[categoryChamberIdentifier] = this.state.categories.find(c => c[uniqueCategoryIdentifier] === editedAssignment[uniqueCategoryIdentifier]).ChamberCode;
        }

        //legislation class is not required, but we must include it in our logic for duplicates
        return assignments.find(a => a[uniqueIdentifier] !== editedAssignment[uniqueIdentifier] &&
            ((!a.LegislationClass || !editedAssignment.LegislationClass || a.LegislationClass === editedAssignment.LegislationClass) &&
                (!a.LegislationType || !editedAssignment.LegislationType || a.LegislationType === editedAssignment.LegislationType) &&
                ((!a.IsPassed && !editedAssignment.IsPassed) || a.IsPassed === editedAssignment.IsPassed) &&
                ((!a.IsUncontested && !editedAssignment.IsUncontested) || a.IsUncontested === editedAssignment.IsUncontested) &&
                requiredFields.every(val => (val === "CategoryCode" && editedAssignment[categoryChamberIdentifier] === this.state.categories.find(c => c[uniqueCategoryIdentifier] === a[uniqueCategoryIdentifier]).ChamberCode) || (val !== "CategoryCode" && a[val] === editedAssignment[val]))))
    }

    sort(column) {
        if (column.sortable === false) { return; }
        let assignments = [...this.state.assignments];
        assignments = this.props.sort(assignments, column);
        this.setState({ assignments })
    }

    handleSave(assignment) {
        let saving = { ...this.state.saving };
        saving[assignment[uniqueIdentifier]] = true;
        this.setState({ saving }, () => {
            const preSaveEventControlID = assignment.EventControlID;
            //nullify EventControlID and let DB resolve it based on other values, but if it fails then we need to put it back for comparison checks in the table between admin/new
            assignment.EventControlID = null;
            this.props.actions.saveCalendarAssignment(assignment)
                .then(() => {
                    this.props.actions.makeToast([{ message: "Save Successful", type: "success" }]);

                    //though the api is saving to both the current env and the admin db, the response is the one from the current env db. Update the assignments state value with this response
                    const assignmentSave = JSON.parse(JSON.stringify(this.props.calendar.calendarAssignmentSave))[0];
                    let assignments = [...this.state.assignments];
                    const existingAssignmentIndex = assignments.findIndex(ca => ca[uniqueIdentifier] === assignmentSave[uniqueIdentifier]);
                    if (existingAssignmentIndex > -1) {
                        assignments[existingAssignmentIndex] = assignmentSave;
                    } else {
                        assignments.splice(0, 0, assignmentSave);
                    }

                    //reset editing and saving values
                    let editing = { ...this.state.editing };
                    editing[assignment[uniqueIdentifier]] = false;
                    saving[assignment[uniqueIdentifier]] = false;
                    this.setState({
                        assignments,
                        saving,
                        editing,
                        newAssignment: assignment.new ? newAssignmentFreshValues : this.state.newAssignment
                    }, () => {
                        this.getAdminAssignments(); //don't just assume the admin db save worked; refresh the admin assignments
                    })
                }).catch(err => {
                    if (err === 'Aborted') {
                        return;
                    }
                    this.props.actions.makeToast([{ message: "Save Failed", type: "failure" }]);
                    console.log(err);

                    //reset EventControlID since it was removed
                    let assignments = [...this.state.assignments];
                    const associatedAssignment = assignments.find(a => a[uniqueIdentifier] === assignment[uniqueIdentifier]);
                    if (associatedAssignment) {
                        assignment.EventControlID = preSaveEventControlID;
                    }
                    //reset the saving state value but keep in edit mode by not resetting the editing state value
                    saving[assignment[uniqueIdentifier]] = false;
                    this.setState({ assignments, saving });
                })
        })
    }

    showDifferences(assignment) {
        let showDifferences = { ...this.state.showDifferences };
        showDifferences[assignment[uniqueIdentifier]] = !showDifferences[assignment[uniqueIdentifier]];
        this.setState({ showDifferences });
    }

    getAssociatedAdminAssignmentWithoutModDate = (assignment) => {
        const associatedAdminAssignment = this.state.adminAssignments.find(aca => aca[uniqueIdentifier] === assignment[uniqueIdentifier]);
        if (!associatedAdminAssignment) { return false; }
        //remove the categoryChamberIdentifier and ModificationDate from the object so we can compare objects between the current env and the admin db
        const { ModificationDate, [categoryChamberIdentifier]: value, ...associatedAdminAssignmentWithoutModificationDate } = associatedAdminAssignment;
        return associatedAdminAssignmentWithoutModificationDate;
    }

    toggleEdit(original) {
        let editing = { ...this.state.editing };
        editing[original[uniqueIdentifier]] = !editing[original[uniqueIdentifier]];

        //if they're exiting edit mode for this row, reset the values to what they were before going into edit mode, and re-check duplicates
        let assignments = [...this.state.assignments];
        let duplicates = [...this.state.duplicates];
        if (!editing[original[uniqueIdentifier]]) {
            const assignmentIndex = assignments.findIndex(c => c[uniqueIdentifier] === original[uniqueIdentifier]);
            const uneditedAssignment = JSON.parse(JSON.stringify(this.getUneditedAssignment(original)));
            assignments[assignmentIndex] = uneditedAssignment;

            if (!this.checkDuplicates(assignments[assignmentIndex])) {
                duplicates = duplicates.filter(d => d.uid !== assignments[assignmentIndex][uniqueIdentifier])
            }
        }

        this.setState({ editing, assignments, duplicates });
    }

    buttonText = (pendingSave, original) => {
        if (pendingSave || original[uniqueIdentifier]) {
            return this.state.saving[original[uniqueIdentifier]] ? "Saving" : "Save";
        } else {
            return "Insert";
        }
    }

    getUneditedAssignment(assignment) {
        //if we've saved the assignment, that's the latest/unedited version; otherwise, the Get should be the latest/unedited version
        return [...this.props.calendar.calendarAssignmentSave] && [...this.props.calendar.calendarAssignmentSave].find(cas => cas[uniqueIdentifier] === assignment[uniqueIdentifier]) ? [...this.props.calendar.calendarAssignmentSave].find(cas => cas[uniqueIdentifier] === assignment[uniqueIdentifier]) : [...this.props.calendar.calendarAssignments].find(ca => ca[uniqueIdentifier] === assignment[uniqueIdentifier]);
    }

    toggleDifferences() {
        this.setState({
            showAllDifferences: !this.state.showAllDifferences
        }, () => {
            if (this.state.showAllDifferences) {
                this.findDifferences();
            }
        })
    }

    findDifferences() {
        this.setState({ filteringMismatches: true }, () => {
            const assignmentMismatches = this.props.findDifferences([...this.state.assignments], uniqueIdentifier, categoryChamberIdentifier, this.getAssociatedAdminAssignmentWithoutModDate)
            this.setState({ assignmentMismatches, filteringMismatches: false })
        })
    }

    render() {
        const { loadedAssignments, loadedAdminAssignments, loadedCategories, loadedLegislationClassOptions,
            assignments, adminAssignments, newAssignment, categories, legislationClassOptions,
            duplicates, showDifferences, showAllDifferences, assignmentMismatches, filteringMismatches,
            categoriesError, assignmentsError, legislationClassOptionsError, adminAssignmentsError,
            saving, editing } = this.state;
        const { billEventOptions, billEventOptionsError, loadedBillEventOptions, customStyles } = this.props;

        const isLoaded = loadedAssignments && loadedAdminAssignments && loadedCategories && loadedBillEventOptions && loadedLegislationClassOptions;

        const columns =
            [
                {
                    Header: "Category Code",
                    accessor: "CategoryCode",
                    width: 125,
                    Placeholder: "Filter...",
                    Cell: ({ original }) => {
                        const associatedAdminAssignmentWithoutModDate = this.getAssociatedAdminAssignmentWithoutModDate(original);
                        const { ModificationDate, [categoryChamberIdentifier]: value, ...originalWithoutModificationDate } = original;
                        const differentInAdminDB = associatedAdminAssignmentWithoutModDate && JSON.stringify(associatedAdminAssignmentWithoutModDate) !== JSON.stringify(originalWithoutModificationDate);
                        const doesNotExistInAdminDB = original[uniqueIdentifier] && !associatedAdminAssignmentWithoutModDate;
                        const showTooltip = differentInAdminDB || doesNotExistInAdminDB || showDifferences[original[uniqueIdentifier]]
                        const tooltip = showTooltip && (
                            <React.Fragment>
                                {differentInAdminDB && <i data-tip={`This assignment is different in the admin database.${showDifferences[original[uniqueIdentifier]] ? " The assignment in the admin database is displayed on the bottom below." : ""} Click ${showDifferences[original[uniqueIdentifier]] ? 'again to hide the admin assignment' : 'to show the admin assignment below the currently displayed assignment'}.`} className="icon notification calendar-assignment" onClick={() => this.showDifferences(original)} />}
                                {doesNotExistInAdminDB && <i data-tip="This assignment does not exist in the admin database." className="icon notification calendar-assignment" />}
                                {!differentInAdminDB && !doesNotExistInAdminDB && showDifferences[original[uniqueIdentifier]] && <i data-tip="This assignment is no longer different than in the admin database. Click again to hide the admin assignment." className="icon notification calendar-assignment" onClick={() => this.showDifferences(original)} />}
                                {showTooltip && <ReactTooltip key={new Date()} />} {/*unique key is needed because otherwise, if the user hovers over a tooltip on one page of the table, if they hover over another on a different page, it will not work*/}
                            </React.Fragment>
                        )

                        let originalCategoryDisplayCode = original.CategoryCode;
                        if (categories.find(c => c[uniqueCategoryIdentifier] === original[uniqueCategoryIdentifier]) && categories.find(c => c[uniqueCategoryIdentifier] === original[uniqueCategoryIdentifier]).ChamberCode) {
                            originalCategoryDisplayCode += " (" + categories.find(c => c[uniqueCategoryIdentifier] === original[uniqueCategoryIdentifier]).ChamberCode + ")";
                        }
                        let associatedAdminCategoryDisplayCode = associatedAdminAssignmentWithoutModDate.CategoryCode;
                        if (categories.find(c => c[uniqueCategoryIdentifier] === associatedAdminAssignmentWithoutModDate[uniqueCategoryIdentifier]) && categories.find(c => c[uniqueCategoryIdentifier] === associatedAdminAssignmentWithoutModDate[uniqueCategoryIdentifier]).ChamberCode) {
                            associatedAdminCategoryDisplayCode += " (" + categories.find(c => c[uniqueCategoryIdentifier] === associatedAdminAssignmentWithoutModDate[uniqueCategoryIdentifier]).ChamberCode + ")";
                        }

                        return (
                            <React.Fragment>
                                {original[uniqueIdentifier] && isLoaded &&
                                    <div className={`calendar-assignment icons${showTooltip ? ' double' : ''}`}>
                                        {<button className={`icon ${editing && editing[original[uniqueIdentifier]] ? 'delete' : 'edit'}`} onClick={() => this.toggleEdit(original)} />}
                                        {tooltip}
                                    </div>
                                }
                                {!original[uniqueIdentifier] || (editing && editing[original[uniqueIdentifier]]) ?
                                    <Select
                                        options={categories}
                                        onChange={(val) => this.handleAssignmentChange(original[uniqueIdentifier], CATEGORY, val)}
                                        getOptionLabel={opt => (opt.ChamberCode ? "(" + opt.ChamberCode + ") " : "") + opt.CategoryCode + " - " + opt.Description}
                                        getOptionValue={opt => opt[uniqueCategoryIdentifier]}
                                        value={categories.find(c => c[uniqueCategoryIdentifier] === original[uniqueCategoryIdentifier])}
                                        styles={customStyles(true)}
                                    />
                                    :
                                    <span title={categories.find(c => c[uniqueCategoryIdentifier] === original[uniqueCategoryIdentifier]) ? categories.find(c => c[uniqueCategoryIdentifier] === original[uniqueCategoryIdentifier]).Description : ''}>{String(originalCategoryDisplayCode || '')}</span>
                                }
                                {showDifferences[original[uniqueIdentifier]] &&
                                    <React.Fragment>
                                        {!editing || !editing[original[uniqueIdentifier]] && <br />}
                                        <span title={categories.find(c => c[uniqueCategoryIdentifier] === associatedAdminAssignmentWithoutModDate[uniqueCategoryIdentifier]) ? categories.find(c => c[uniqueCategoryIdentifier] === associatedAdminAssignmentWithoutModDate[uniqueCategoryIdentifier]).Description : ''}>{String(associatedAdminCategoryDisplayCode || '')}</span>
                                    </React.Fragment>
                                }
                            </React.Fragment>
                        )
                    },
                    style: { textAlign: 'center', overflow: 'visible' }
                },
                {
                    Header: "Event",
                    accessor: "EventCode",
                    width: 90,
                    style: { textAlign: 'center', overflow: 'visible' },
                    Placeholder: "Filter...",
                    Cell: ({ original }) => {
                        const associatedAdminAssignmentWithoutModDate = this.getAssociatedAdminAssignmentWithoutModDate(original);
                        return (
                            <div>
                                {!original[uniqueIdentifier] || (editing && editing[original[uniqueIdentifier]]) ?
                                    <Select
                                        options={[...billEventOptions].filter((value, index, self) =>
                                            index === self.findIndex((t) => (
                                                t.EventCode === value.EventCode
                                            ))
                                        )}
                                        onChange={(val) => this.handleAssignmentChange(original[uniqueIdentifier], EVENT, val)}
                                        value={billEventOptions.find(b => b.label === original.EventCode)}
                                        styles={customStyles(false)}
                                    />
                                    :
                                    String(original.EventCode)
                                }
                                {showDifferences[original[uniqueIdentifier]] &&
                                    <React.Fragment>
                                        {!editing || !editing[original[uniqueIdentifier]] && <br />}
                                        {String(associatedAdminAssignmentWithoutModDate.EventCode || '')}
                                    </React.Fragment>
                                }
                            </div>
                        )
                    }
                },
                {
                    Header: "Event Description",
                    accessor: "p_LegislationDescription",
                    filter: 'includesValue',
                    Placeholder: "Filter...",
                    Cell: ({ original }) => {
                        const associatedAdminAssignment = showDifferences[original[uniqueIdentifier]] && adminAssignments.find(aca => aca[uniqueIdentifier] === original[uniqueIdentifier]);
                        return (
                            <React.Fragment>
                                <span title={String(original.p_LegislationDescription || '')}>{String(original.p_LegislationDescription || '')}</span>
                                {showDifferences[original[uniqueIdentifier]] &&
                                    <React.Fragment>
                                        <br />
                                        <span title={String(associatedAdminAssignment.p_LegislationDescription || '')}>{String(associatedAdminAssignment.p_LegislationDescription || '')}</span>
                                    </React.Fragment>
                                }
                            </React.Fragment>
                        )
                    }
                },
                {
                    Header: "Leg Chamber",
                    accessor: "LegislationChamberCode",
                    Placeholder: "Filter...",
                    width: 100,
                    style: { textAlign: 'center', overflow: 'visible' },
                    Cell: ({ original }) => {
                        const associatedAdminAssignment = showDifferences[original[uniqueIdentifier]] && adminAssignments.find(aca => aca[uniqueIdentifier] === original[uniqueIdentifier]);
                        const options = [{ label: "H" }, { label: "S" }];
                        return (
                            <div>
                                {!original[uniqueIdentifier] || (editing && editing[original[uniqueIdentifier]]) ?
                                    <Select
                                        options={options}
                                        onChange={(val) => this.handleAssignmentChange(original[uniqueIdentifier], CHAMBER, val)}
                                        getOptionValue={opt => opt.label}
                                        value={options.find(b => b.label === original.LegislationChamberCode)}
                                        styles={customStyles(false)}
                                    />
                                    :
                                    String(original.LegislationChamberCode || '')
                                }
                                {showDifferences[original[uniqueIdentifier]] &&
                                    <React.Fragment>
                                        {!editing || !editing[original[uniqueIdentifier]] && <br />}
                                        {String(associatedAdminAssignment.LegislationChamberCode || '')}
                                    </React.Fragment>
                                }
                            </div>
                        )
                    }
                },
                {
                    Header: "Leg Type",
                    accessor: "LegislationTypeCode",
                    Placeholder: "Filter...",
                    width: 80,
                    style: { textAlign: 'center', overflow: 'visible' },
                    Cell: ({ original }) => {
                        const options = [{ label: "B" }, { label: "J" }, { label: "R" }]
                        const associatedAdminAssignment = showDifferences[original[uniqueIdentifier]] && adminAssignments.find(aca => aca[uniqueIdentifier] === original[uniqueIdentifier]);
                        return (
                            <div>
                                {!original[uniqueIdentifier] || (editing && editing[original[uniqueIdentifier]]) ?
                                    <Select
                                        options={options}
                                        onChange={(val) => this.handleAssignmentChange(original[uniqueIdentifier], TYPE, val)}
                                        getOptionValue={opt => opt.label}
                                        value={options.find(b => b.label === original.LegislationTypeCode)}
                                        styles={customStyles(false)}
                                    />
                                    :
                                    String(original.LegislationTypeCode || '')
                                }
                                {showDifferences[original[uniqueIdentifier]] &&
                                    <React.Fragment>
                                        {!editing || !editing[original[uniqueIdentifier]] && <br />}
                                        {String(associatedAdminAssignment.LegislationTypeCode || '')}
                                    </React.Fragment>
                                }
                            </div>
                        )
                    }
                },
                {
                    Header: "Leg Class",
                    accessor: "LegislationClass",
                    Placeholder: "Filter...",
                    width: 160,
                    style: { textAlign: 'center', overflow: 'visible' },
                    Cell: ({ original }) => {
                        const associatedAdminAssignment = showDifferences[original[uniqueIdentifier]] && adminAssignments.find(aca => aca[uniqueIdentifier] === original[uniqueIdentifier]);
                        return (
                            <div>
                                {!original[uniqueIdentifier] || (editing && editing[original[uniqueIdentifier]]) ?
                                    <Select
                                        options={legislationClassOptions}
                                        onChange={(val) => this.handleAssignmentChange(original[uniqueIdentifier], CLASS, val)}
                                        value={legislationClassOptions.find(b => b.LegislationClassID === original.LegislationClassID)}
                                        styles={customStyles(false)}
                                        isClearable
                                    />
                                    :
                                    String(original.LegislationClass || '')
                                }
                                {showDifferences[original[uniqueIdentifier]] &&
                                    <React.Fragment>
                                        {!editing || !editing[original[uniqueIdentifier]] && <br />}
                                        {String(associatedAdminAssignment.LegislationClass || '')}
                                    </React.Fragment>
                                }
                            </div>
                        )
                    }
                },
                {
                    Header: "Passed",
                    id: "IsPassed",
                    accessor: cm => cm.IsPassed && cm.IsPassed.toString(),
                    Placeholder: "Filter...",
                    width: 75,
                    style: { textAlign: 'center', overflow: 'visible' },
                    Cell: ({ original }) => {
                        const associatedAdminAssignment = showDifferences[original[uniqueIdentifier]] && adminAssignments.find(aca => aca[uniqueIdentifier] === original[uniqueIdentifier]);
                        const options = [{ label: true }, { label: false }];
                        return (
                            <div>
                                {!original[uniqueIdentifier] || (editing && editing[original[uniqueIdentifier]]) ?
                                    <Select
                                        options={options}
                                        onChange={(val) => this.handleAssignmentChange(original[uniqueIdentifier], PASSED, val)}
                                        getOptionLabel={opt => opt.label.toString()}
                                        getOptionValue={opt => opt.label.toString()}
                                        value={options.find(b => b.label === original.IsPassed)}
                                        styles={customStyles(false)}
                                    />
                                    :
                                    String(original.IsPassed !== undefined ? original.IsPassed : '')
                                }
                                {showDifferences[original[uniqueIdentifier]] &&
                                    <React.Fragment>
                                        {!editing || !editing[original[uniqueIdentifier]] && <br />}
                                        {String(associatedAdminAssignment.IsPassed !== undefined ? associatedAdminAssignment.IsPassed : '')}
                                    </React.Fragment>
                                }
                            </div>
                        )
                    }
                },
                {
                    Header: "Uncontested",
                    id: "IsUncontested",
                    Placeholder: "Filter...",
                    accessor: cm => cm.IsUncontested && cm.IsUncontested.toString(),
                    width: 110,
                    style: { textAlign: 'center', overflow: 'visible' },
                    Cell: ({ original }) => {
                        const associatedAdminAssignment = showDifferences[original[uniqueIdentifier]] && adminAssignments.find(aca => aca[uniqueIdentifier] === original[uniqueIdentifier]);
                        const options = [{ label: true }, { label: false }];
                        return (
                            <div>
                                {!original[uniqueIdentifier] || (editing && editing[original[uniqueIdentifier]]) ?
                                    <Select
                                        options={options}
                                        onChange={(val) => this.handleAssignmentChange(original[uniqueIdentifier], UNCONTESTED, val)}
                                        getOptionLabel={opt => opt.label.toString()}
                                        getOptionValue={opt => opt.label.toString()}
                                        value={options.find(b => b.label === original.IsUncontested)}
                                        styles={customStyles(false)}
                                    />
                                    :
                                    String(original.IsUncontested !== undefined ? original.IsUncontested : '')
                                }
                                {showDifferences[original[uniqueIdentifier]] &&
                                    <React.Fragment>
                                        {!editing || !editing[original[uniqueIdentifier]] && <br />}
                                        {String(associatedAdminAssignment.IsUncontested !== undefined ? associatedAdminAssignment.IsUncontested : '')}
                                    </React.Fragment>
                                }
                            </div>
                        )
                    }
                },
                {
                    Header: "Actions",
                    width: 100,
                    sortable: false,
                    filterable: false,
                    Cell: ({ original }) => {
                        if (duplicates.find(d => d.uid === original[uniqueIdentifier])) {
                            return <span className="input-feedback" style={{ fontWeight: 'bold', fontSize: '90%' }}>DUPLICATE</span>
                        } else {
                            const requiredFieldsNotCompleted = requiredFields.some(val => original[val] === null);
                            //to compare between unedited and current, remove the categoryChamberIdentifier since the unedited will not have it
                            //to compare between current and admin, remove the ModificationDate and categoryChamberIdentifier
                            const uneditedAssignment = original[uniqueIdentifier] && this.getUneditedAssignment(original);
                            const key = 'ModificationDate';
                            //get unedited without category chamber identifier in order to use later to compare between unedited and current
                            const { [categoryChamberIdentifier]: value, ...uneditedWithoutCategoryChamberIdentifier } = uneditedAssignment || {};
                            //now get associated admin assignment without category chamber identifier and mod date in order to use later to compare between current and admin
                            const associatedAdminAssignmentWithoutModDate = this.getAssociatedAdminAssignmentWithoutModDate(original);
                            //now get current assignment without category chamber identifier in order to use later to compare between unedited and current
                            const { [categoryChamberIdentifier]: value3, ...originalWithoutCategoryChamberIdentifier } = original;
                            //now get current assignment without category chamber identifier and mod date in order to use later to compare between current and admin
                            const { [key]: value4, ...originalWithoutCategoryChamberIdentifierAndModificationDate } = originalWithoutCategoryChamberIdentifier;
                            //check if it's different or inexistent in admin db
                            const differentOrNonExistentInAdminDB = original[uniqueIdentifier] && this.props.isDifferentOrNonExistentInAdminDB(originalWithoutCategoryChamberIdentifierAndModificationDate, associatedAdminAssignmentWithoutModDate)
                            //if it's different from admin or different from the unedited version, set it as saveable
                            const pendingSave = original[uniqueIdentifier] && (differentOrNonExistentInAdminDB || (uneditedWithoutCategoryChamberIdentifier && JSON.stringify(uneditedWithoutCategoryChamberIdentifier) !== JSON.stringify(originalWithoutCategoryChamberIdentifier)));
                            return (
                                <button className="button"
                                    disabled={!saving || Object.values(saving).indexOf(true) > -1 || requiredFieldsNotCompleted || (original[uniqueIdentifier] && !pendingSave)}
                                    onClick={() => this.handleSave(original)}>{this.buttonText(pendingSave, original)}</button>
                            )
                        }
                    },
                    style: { textAlign: 'center' }
                }
            ]

        return (
            <div>
                <div>
                    {(billEventOptionsError || categoriesError || assignmentsError || adminAssignmentsError || legislationClassOptionsError) ?
                        <React.Fragment>
                            {billEventOptionsError && <p>Error loading legislation event options</p>}
                            {categoriesError && <p>Error loading calendar category options</p>}
                            {legislationClassOptionsError && <p>Error loading legislation class options</p>}
                            {assignmentsError && <p>Error loading calendar assignments</p>}
                            {adminAssignmentsError && <p>Error loading admin calendar assignments</p>}
                        </React.Fragment>
                        :
                        <React.Fragment>
                            <button className="button" style={{ float: 'right' }} disabled={!isLoaded || filteringMismatches} onClick={this.toggleDifferences}>{showAllDifferences ? 'Show All' : 'Show Admin Mismatches'}</button>
                            <ConfigurationsTable header={"Calendar Assignments"} assignments={showAllDifferences ? [...assignments].filter(ca => assignmentMismatches.includes(ca[uniqueIdentifier])) : assignments} adminAssignments={adminAssignments} newAssignment={newAssignment} categories={categories} uniqueCategoryIdentifier={uniqueCategoryIdentifier} isLoaded={isLoaded} columns={columns} sort={this.sort} showDifferences={showDifferences} uniqueIdentifier={uniqueIdentifier} />
                        </React.Fragment>
                    }
                </div>
            </div>
        );
    }
}

export default connect(
    (state) => {
        const { nav, bills, calendar } = state;
        return {
            nav,
            bills,
            calendar
        }
    },
    (dispatch) => {
        return {
            actions: bindActionCreators(Object.assign({}, navActionCreators, billActionCreators, calendarActionCreators), dispatch)
        }
    }
)(CalendarConfigurations)







