import React from 'react';
import * as Fields from '../../../lis-shared/lis-layout/components/lis-forms-component';

class SelectorSection extends React.Component {

    render() {
        return (
            <div className="inner-grid four">
                <div>
                    <Fields.Dropdown
                        value={this.props.selectedSession}
                        options={this.props.sessionOptions}
                        id="sessionDropdown"
                        onChange={this.props.onSessionChange}
                    />
                </div>
                <div>
                    <Fields.Dropdown
                        value={this.props.selectedChamber}
                        options={this.props.chamberOptions}
                        id="chamberDropdown"
                        onChange={this.props.onChamberChange}
                        isDisabled={this.props.isDisabled || this.props.chamberIsDisabled}
                    />
                </div>
            </div>
        );
    }
}

export default SelectorSection;