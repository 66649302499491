import React from "react"
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { Link, withRouter } from 'react-router-dom';
import { navActionCreators } from '../../stores/lis-nav-store';
import moment from 'moment';

class Footer extends React.Component {
    render() {
        return (
            <div className={this.props.location.pathname === "/" || this.props.location.pathname.includes("/home") ? "footer-wrapper home" : "footer-wrapper"} >
                <div className="footer">
                    <div>
                        <Link to="/home">LIS Home</Link>
                        <a href="mailto:LIS@dlas.virginia.gov">Website Feedback</a>
                        {this.props.login && this.props.login.userClaims.roles.find(x => x === "Admin"/* "APIDocumentation" */) ? <a href="/admin-developers">For Developers</a> : <a href="/developers">For Developers</a>}
                        <Link to="/privacy">Privacy Policy</Link>
                        <Link to="/data-files">Data Files</Link>
                    </div>
                    <div>
                        <span>{"© Copyright Commonwealth of Virginia " + moment().format("YYYY") + ". All rights reserved. Site developed by the "}<a href="http://dlas.virginia.gov/">Division of Legislative Automated Systems (DLAS)</a></span>
                    </div>
                </div>
            </div>
        )
    }
}

const FooterWrapper = connect(
    (state) => {
        const { nav, login } = state;
        return {
            nav,
            login
        }
    },
    (dispatch) => {
        return {
            actions: bindActionCreators(Object.assign({}, navActionCreators), dispatch)
        }
    }
)(Footer)

export default withRouter(FooterWrapper)