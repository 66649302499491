import React, { useState } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import renderHTML from 'react-render-html';
import { Link } from 'react-router-dom';
import { billActionCreators } from '../../stores/lis-legislation-store';
import moment from 'moment';
import { voteActionCreators } from '../../stores/lis-votes-store';
import VersionList from '../lis-bills/lis-bill-version-list';
import { committeeActionCreators } from '../../stores/lis-committee-store';

const HistoryList = props => {
    const [filterAmount, setFilterAmount] = useState(5);
    const filteredBillHistory = props.billHistory.slice(Math.max(props.billHistory.length - (parseInt(filterAmount) || 0), 0))
    const filterOptions = () => {
        let options = []
        // Allow the user to show a variable amount of bill history items
        for (let i = 5; i < props.billHistory.length; i = i + 5) {
            options.push(<option key={i} value={i}>Show Last {i}</option>)
        }
        return options;
    }
    return (
        <div>
            {props.billHistory.length > 5 &&
                <div className="align-right">
                    <select value={filterAmount} onChange={e => setFilterAmount(e.target.value)}>
                        {filterOptions()}
                        <option value={props.billHistory.length}>Show All</option>
                    </select>
                </div>
            }
            <table className="line-table">
                <tbody>
                    {filteredBillHistory.filter(item => !item.DeletionDate).map((item, hIndex) => (
                        <tr key={hIndex} className='parent-tr bill-details-table-row'>
                            <td className="left">{moment(item.EventDate).format('l')}</td>
                            <td className="left">{item.ChamberCode === 'H' ? 'House' : 'Senate'}</td>
                            <td className="left">
                                {item.Description}
                                {item.VoteLinks && item.opened &&
                                    <div className="link-row">
                                        {item.VoteLinks.map((link, vIndex) =>
                                            <div key={vIndex}>
                                                {link.VoteID ? link.IsPublic ? <Link to={`/vote-details/${props.bill.LegislationNumber}/${item.SessionCode}/${link.VoteNumber}`}>Vote</Link> : <span>Vote Detail Pending</span> : ''}
                                                {link.CommitteeNumber ? <Link to={`/session-details/${item.SessionCode}/committee-information/${link.CommitteeNumber}/committee-details`}>{link.ParentCommitteeID ? 'Subcommittee' : 'Committee'}</Link> : ''}
                                                {link.LegislationNumber && link.SessionCode ? <Link to={`/bill-details/${link.SessionCode}/${link.LegislationNumber}`}>Legislation</Link> : ''}
                                            </div>
                                        )}
                                    </div>
                                }
                            </td>
                            <td className="button-container">
                                {item.ReferenceID &&
                                    <React.Fragment>
                                        {item.isLoading ?
                                            <span className="small-spinner"></span>
                                            :
                                            <button aria-expanded={item.opened} className={item.opened ? 'arrow-up' : 'arrow-down'} onClick={() => props.toggleShowLinks(item)}></button>
                                        }
                                    </React.Fragment>
                                }
                            </td>
                        </tr>
                    ))}
                </tbody>
            </table>
        </div>
    );
}

class BillInfoComponent extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            bill: [],
            billHistoryIsLoading: false,
            showInfo: props.isBillManagement ? false : true,
            billHistory: [],
            showBillHistory: false,
            showBillVersions: false,
            newBillNote: '',
            showBillNote: false
        };
        this.handleNoteChange = this.handleNoteChange.bind(this);
        this.toggleShowInfo = this.toggleShowInfo.bind(this);
        this.getHistory = this.getHistory.bind(this);
        this.toggleShowLinks = this.toggleShowLinks.bind(this);
        this.toggleVersions = this.toggleVersions.bind(this);
        this.toggleBillNote = this.toggleBillNote.bind(this);
        this.toggleBillNotesFromParent = this.toggleBillNotesFromParent.bind(this);
        this.saveNote = this.saveNote.bind(this);
    }

    componentDidMount() {
        if (this.props.selectedLocation === "Bill Text" && this.props.selectedKeywords)
            this.props.toggleShowData(this.props.bill.LegislationID, true)
    }

    handleNoteChange(e) {
        this.setState({
            newBillNote: e.target.value
        })
    }


    toggleShowInfo() {
        this.setState(prevState => ({
            showInfo: !prevState.showInfo
        }));
    }

    getHistory() {
        //Bill history is already loaded so just toggle the view
        if (this.state.billHistory.length > 0) {
            this.setState(state => ({
                showBillHistory: !state.showBillHistory
            }));
            return;
        }
        this.setState({
            billHistoryIsLoading: true
        });
        this.props.actions.getBillHistoryByBillID("?legislationID=" + this.props.bill.LegislationID)
            .then(() => {
                let billHistory = [...this.props.bills.billHistory];
                if (billHistory.length === 0) {
                    throw 'No Bill History Found'
                }
                this.setState({
                    billHistory: this.props.bills.billHistory,
                    billHistoryIsLoading: false,
                    showBillHistory: true
                });
            }).catch(err => {
                if (err === "Aborted") {
                    return;
                }
                this.setState({
                    billHistoryMessage: err.toString(),
                    billHistoryIsLoading: false
                });
            });
    }

    toggleShowLinks(vote) {
        let billHistory = [...this.state.billHistory];
        const historyIndex = billHistory.findIndex(item => item.LegislationEventID == vote.LegislationEventID);
        billHistory[historyIndex].opened = !billHistory[historyIndex].opened;

        if (vote.VoteLinks) {
            this.setState({
                billHistory: billHistory
            });
        } else {
            billHistory[historyIndex].isLoading = true;
            this.setState({ billHistory: billHistory });
            let params = '?legislationEventID=' + vote.LegislationEventID;
            this.props.actions.getVoteLink(params).then(() => {
                let links = [...this.props.votes.voteLinkResponse];
                let linkPromises = [];
                links.forEach(link => {
                    if (link.CommitteeID) {
                        const cmtePromise = this.props.actions.getCommitteeById("?id=" + link.CommitteeID + "&sessionCode=" + vote.SessionCode)
                            .then(() => {
                                link.CommitteeNumber = this.props.committee.committeeByIdData.CommitteeNumber;
                            }).catch((err) => {
                                console.error(err);
                                // Don't care if it fails. That committee won't be visible
                                return Promise.resolve();
                            })
                        linkPromises.push(cmtePromise);
                    }
                    // If there are any vote links of type 'Legislation' then the legislation ID needs to be turned into a legislation number to form the link
                    if (link.ReferenceType === "Legislation") {
                        const promise = this.props.actions.getBill(link.ReferenceID)
                            .then(() => {
                                link.LegislationNumber = this.props.bills.bill.LegislationNumber;
                                link.SessionCode = link.SessionCode || vote.SessionCode;
                                return Promise.resolve();
                            }).catch(err => {
                                console.error(err);
                                // Don't care if it fails. That legislation won't be visible
                                return Promise.resolve();
                            });
                        linkPromises.push(promise);
                    }
                });
                Promise.all(linkPromises)
                    .then(() => {
                        billHistory[historyIndex].isLoading = false;
                        billHistory[historyIndex].VoteLinks = this.props.votes.voteLinkResponse;
                        this.setState({
                            billHistory: billHistory
                        });
                    });
            });
        }
    }

    toggleVersions() {
        this.setState({
            showBillVersions: !this.state.showBillVersions
        })
    }

    toggleBillNote() {
        const { bill, selectedCollection } = this.props;
        const collectionBillIndex = selectedCollection.WatchListLegislation.findIndex(cBill => bill.LegislationID === cBill.LegislationID && !cBill.DeletionDate);
        this.setState(state => ({
            newBillNote: selectedCollection.WatchListLegislation[collectionBillIndex].Note || '',
            showBillNote: !state.showBillNote
        }));
    }

    toggleBillNotesFromParent(show) {
        // This function is called by the parent to show/hide all the notes in a watchlist.
        const { bill, selectedCollection } = this.props;
        const collectionBillIndex = selectedCollection.WatchListLegislation.findIndex(cBill => bill.LegislationID === cBill.LegislationID && !cBill.DeletionDate);
        if (selectedCollection.WatchListLegislation[collectionBillIndex].Note) {
            this.setState({
                newBillNote: selectedCollection.WatchListLegislation[collectionBillIndex].Note || '',
                showBillNote: show
            });
        }
    }

    saveNote() {
        const bill = this.props.bill;
        let selectedCollection = { ...this.props.selectedCollection };
        const collectionBillIndex = selectedCollection.WatchListLegislation.findIndex(cBill => bill.LegislationID === cBill.LegislationID && !cBill.DeletionDate);
        selectedCollection.WatchListLegislation[collectionBillIndex].Note = this.state.newBillNote;
        this.props.handleCollectionChange(selectedCollection, () => {
            this.setState({
                showBillNote: false
            });
        });
    }

    render() {
        const { bill, billIndex, selectedCollection, userCanUseCollections, checked, selectedKeywords, sessionCode, selectedLocation, session, displayCrossSession, newSession } = this.props;
        const { showInfo, billHistoryIsLoading, billHistory, showBillHistory, billHistoryMessage, showBillVersions, showBillNote, newBillNote } = this.state;
        let collectionBillIndex = -1;
        if (selectedCollection && !this.props.isBillManagement && !this.props.searchMade) {
            collectionBillIndex = selectedCollection.WatchListLegislation.findIndex(cBill => bill.LegislationID === cBill.LegislationID && !cBill.DeletionDate);
        }
        return (
            <div className="flex-row flex-start bill-info" style={{ paddingTop: '0.5em' }}>
                <div className='parent-li se-bill-search-result'>
                    <div className="toggle-row description legislation-search-results" style={bill.eventsChanged ? { background: '#f1f1f1', fontStyle: 'italic' } : {}}>
                        <div>
                            {userCanUseCollections || this.props.isBillManagement ?
                                <div className="collection-buttons">
                                    <label className="screen-reader-only" htmlFor={`sr-select-bill${billIndex}`}>Select bill</label>
                                    <input type="checkbox"
                                        id={`sr-select-bill${billIndex}`}
                                        checked={checked || false}
                                        onChange={this.props.handleBillCheckbox} />
                                </div>
                                :
                                <div></div>
                            }
                            <div className="bill-result-link">
                                <Link to={`/bill-details/${sessionCode}/${bill.LegislationNumber}`} style={{ pointerEvents: sessionCode ? null : 'none' }} target="_blank">{bill.LegislationNumber}</Link>
                                {this.props.displayChapterNumber && bill.ChapterNumber &&
                                    <div className="bill-additional-info">
                                        <p className={`${this.props.isBillManagement || userCanUseCollections ? 'margin-left' : ''}`}>({bill.ChapterNumber})</p>
                                    </div>
                                }
                                {displayCrossSession &&
                                    <div className="bill-additional-info">
                                        <p className={`${userCanUseCollections ? ' margin-left' : ''}`}>{session.SessionYear + " " + session.DisplayName}</p>
                                    </div>
                                }
                                {collectionBillIndex !== -1 &&
                                    <div
                                        role="button"
                                        aria-label={!selectedCollection.WatchListLegislation[collectionBillIndex].Note ? "Edit notes" : 'Add notes'}
                                        className={`icon bubble${!selectedCollection.WatchListLegislation[collectionBillIndex].Note ? "-hollow" : ''}`}
                                        onClick={() => !this.props.collectionIsSaving && this.toggleBillNote()}
                                        title={selectedCollection.WatchListLegislation[collectionBillIndex].Note ? "Edit notes" : "Add notes"}>
                                    </div>
                                }
                            </div>
                        </div>
                        <span style={{ paddingRight: '25px' }}>{renderHTML(bill.Description || '')}</span>
                        <div className="button-container">
                            <span>{bill.LegislationStatus}</span>
                            <button aria-expanded={!!this.props.expanded} className={this.props.expanded ? 'arrow-up' : 'arrow-down'} onClick={() => this.props.toggleShowData(bill.LegislationID)}>More info</button>
                        </div>
                    </div>
                    {(this.state[bill.LegislationID] || this.props.expanded) &&
                        <div className="list-desc">
                            {!this.props.isBillManagement && (selectedLocation != "Bill Text" || !selectedKeywords) &&
                                <div style={{ paddingBottom: showInfo ? '20px' : '0px', position: 'relative', overflow: 'hidden', height: showInfo ? 'auto' : '100px' }}>
                                    {bill.Patrons &&
                                        <span>Introduced by: {bill.Patrons.map((patron, patronIndex) => <React.Fragment key={patronIndex}>{patron.PatronDisplayName}{patronIndex !== bill.Patrons.length - 1 && ", "}</React.Fragment>)}</span>
                                    }
                                    <div><strong>{bill.SummaryVersion}:</strong>{renderHTML(bill.LegislationSummary)}</div>
                                    <div className="content-blocker" style={showInfo ? { background: 'none' } : {}}>
                                        <button onClick={this.toggleShowInfo} className="expander button-link" type="button">Show {showInfo ? "Less" : ""} Info</button>
                                    </div>
                                </div>
                            }
                            {this.props.children || null}
                            {billHistoryIsLoading ?
                                <div><span className="small-spinner"></span></div>
                                :
                                bill.SearchText.length > 0 && (selectedLocation != "Bill Text" || !selectedKeywords) &&
                                <div className="bill-search-bill-history">
                                    {billHistoryMessage &&
                                        <div className="fail small-text">{billHistoryMessage}</div>
                                    }
                                    {billHistory.length > 0 && showBillHistory &&
                                        <div>
                                            <HistoryList
                                                billHistory={billHistory}
                                                toggleShowLinks={this.toggleShowLinks}
                                                bill={bill}
                                            />
                                        </div>
                                    }
                                    {!this.props.isBillManagement && <button onClick={this.getHistory} className="bill-history-button button-link" type="button">{showBillHistory ? 'Hide' : 'Show'} Latest History</button>}
                                </div>
                            }
                            {bill.SearchText.length > 0 && selectedLocation === "Bill Text" && selectedKeywords &&
                                <div>
                                    <VersionList
                                        version={bill.SearchText}
                                        legislationNumber={bill.LegislationNumber}
                                        legislationClass={bill.LegislationClass}
                                        sessionCode={sessionCode}
                                        selectedKeywords={selectedKeywords}
                                    />
                                </div>
                            }
                        </div>
                    }
                    {showBillNote &&
                        <div className="dlas-forms flex-row note-area-container">
                            <label className="screen-reader-only" htmlFor={`sr-bill-note${billIndex}`}>Bill note</label>
                            <textarea
                                id={`sr-bill-note${billIndex}`}
                                value={newBillNote}
                                onChange={this.handleNoteChange}
                                style={{ width: '90%', marginBottom: '5px', fontSize: '1em', resize: "vertical" }}
                                placeholder={`${bill.LegislationNumber} Note`}
                                className="note-area-input"
                            />
                            {this.props.collectionIsSaving ?
                                <div className="small-spinner"></div>
                                :
                                <div className="inline-list">
                                    <button aria-label="save" onClick={this.saveNote} type="button" className="icon save"></button>
                                    <button aria-label="cancel" onClick={this.toggleBillNote} type="button" className="icon delete"></button>
                                </div>
                            }
                        </div>
                    }
                    {!newSession && <hr className="faded-line" style={{ marginBlockEnd: '0px' }} />}
                </div>
            </div>
        )
    }
}

export default connect(
    (state) => {
        const { bills, votes, committee } = state;
        return {
            bills,
            votes,
            committee
        }
    },
    (dispatch) => {
        return {
            actions: bindActionCreators(Object.assign({}, billActionCreators, voteActionCreators, committeeActionCreators), dispatch)
        }
    },
    null,
    {
        forwardRef: true
    }
)(BillInfoComponent);