import React from 'react';
import queryString from 'query-string';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { userActionCreators } from '../../../stores/lis-user-store';
import { cancelRequest } from '../../../services/request.service';
import { authActionCreators } from '../../../stores/lis-auth-store';
import { Link } from 'react-router-dom';

const houseInstructions = "Please contact the House of Delegates administrator at (804) 698-1560.";
const senateInstructions = "Please email Senate Technology at sis@senate.virginia.gov or call 804-698-7470.";
const legislativeInstructions = "Please call the DLAS help desk at 804-786-9631 or email LIS@dlas.virginia.gov.";

const houseDomains = [
    "house.virginia.gov"
];

const senateDomains = [
    "senate.virginia.gov"
];

const legislativeDomains = [
    "dlas.virginia.gov",
    "dls.virginia.gov",
    "sfac.virginia.gov",
    "hac.virginia.gov",
    "vcoy.virginia.gov",
    "jchc.virginia.gov",
    "vscc.virginia.gov",
    "dcp.virginia.gov",
    "vcf.virginia.gov",
    "jlarc.virginia.gov"
];

const calculateNumberOfCriteriaMet = (password) => {
    let criteriaMet = 0;
    if (password.search(/[a-z]/) > -1) { criteriaMet++; }
    if (password.search(/[A-Z]/) > -1) { criteriaMet++; }
    if (password.search(/\d/) > -1) { criteriaMet++; }
    if (password.search(/[@^$!%*?=\-_+&\[\]{}|\\:'\,\.\?\/`~""\(\);<>]/) > -1) { criteriaMet++; }

    return criteriaMet;
}

class ChangePassword extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            errorMessage: '',
            isChanging: false,
            passwordReset: false,
            confirmPassword: '',
            passwordChangeFinished: false,
            password: '',
            passwordconfirm: '',
            currentpassword: '',
            resettingPassword: false,
            passwordResetError: '',
            showPassword: false,
            passwordError: false,
            passwordConfirmError: false
        };

        this.changePassword = this.changePassword.bind(this);
        this.handleChange = this.handleChange.bind(this);
        this.toggleShowPassword = this.toggleShowPassword.bind(this);
        this.checkPassword = this.checkPassword.bind(this);
        this.checkPasswordConfirm = this.checkPasswordConfirm.bind(this);
        this.toggleShowTooltip = this.toggleShowTooltip.bind(this);
        this.handleClickOutside = this.handleClickOutside.bind(this);

        this.infoRef = React.createRef();
        this.infoButtonRef = React.createRef();
    }

    changePassword(e) {
        e.preventDefault();
        let isError = false;
        isError = this.checkPassword() || isError;
        isError = this.checkPasswordConfirm() || isError;
        if (isError) {
            return
        }

        this.setState({
            isChanging: true,
            errorMessage: ''
        });
        const parsed = queryString.parse(this.props.location.search); //note this will replace all '+' signs with ' ' spaces, which we have to account for below
        const emailaddress = parsed.emailaddress ? parsed.emailaddress.replaceAll(" ", "+") : this.props.login.userProfile.email; //email address if registration or temp/expired password, userProfile prop if logged in and changing
        const body = {
            username: emailaddress,
            password: this.state.currentpassword,
            newpassword: this.state.password
        };
        this.props.actions.changePassword(body)
            .then(() => {
                this.setState({
                    isChanging: false,
                    passwordChangeFinished: true
                });
                //Login to get access token and refresh token
                const loginObj = { userId: emailaddress, userPassword: this.state.password };
                this.props.actions.loginUser(loginObj);
            }).catch(err => {
                if (err === 'Aborted') {
                    return;
                }
                this.setState({
                    errorMessage: this.props.users.changePasswordError ? this.props.users.changePasswordError : err.toString(),
                    isChanging: false
                });
            })
    }

    handleChange(key, val) {
        this.setState({
            [key]: val
        });
    }

    toggleShowPassword() {
        this.setState(state => ({
            showPassword: !state.showPassword
        }));
    }

    checkPassword() {
        this.setState({ showHelp: false })
        if (this.state.password.length < 10) {
            this.setState({ passwordError: 'password must be at least 10 characters long' });
            return true;
        }
        if (this.state.password.length > 256) {
            this.setState({ passwordError: 'password must be 256 characters or fewer' });
            return true;
        }
        if (this.state.password.search(/^(?:(?=.*[a-z])(?:(?=.*[A-Z])(?=.*[\d@$!%*?=\-_+&\[\]{}|\\:'\,\.\?\/`~""\(\);<>])|(?=.*[@^$!%*?=\-_+&\[\]{}|\\:'\,\.\?\/`~""\(\);<>])(?=.*\d))|(?=.*[@^$!%*?=\-_+&\[\]{}|\\:'\,\.\?\/`~""\(\);<>])(?=.*[A-Z])(?=.*\d)).{10,256}$/) === -1) {
            this.setState({ passwordError: 'invalid password', showHelp: true });
            return true;
        } else {
            this.setState({ showHelp: false })
        }
        if (this.state.password === this.state.currentpassword) {
            this.setState({ passwordError: 'new password cannot be the same as your current password' });
            return true;
        }
        this.setState({
            passwordError: ''
        });
    }

    checkPasswordConfirm() {
        if (this.state.password && this.state.passwordconfirm !== this.state.password) {
            this.setState({
                passwordConfirmError: 'passwords do not match'
            });
            return true;
        }
        this.setState({
            passwordConfirmError: ''
        });
    }

    toggleShowTooltip() {
        this.setState(state => ({
            toolTip: !state.toolTip
        }), () => {
            if (this.state.toolTip) {
                document.addEventListener('mousedown', this.handleClickOutside);
            }
        });
    }

    handleClickOutside(event) {
        if (this.infoRef && !this.infoRef.current.contains(event.target) && this.infoButtonRef && !this.infoButtonRef.current.contains(event.target)) {
            document.removeEventListener('mousedown', this.handleClickOutside);
            this.setState({ toolTip: false })
        }
    }

    componentDidMount() {
        if (this.props.location.pathname.includes('passwordreset')) {
            this.setState({
                resettingPassword: true
            });
            const resetHash = this.props.match.params.resethash;
            let emailAddress;
            if (this.props.location.search) {
                emailAddress = queryString.parse(this.props.location.search).emailaddress;
                if (emailAddress) { emailAddress = emailAddress.replaceAll(" ", "+") }
            }
            this.props.actions.resetPassword({ HashedWord: resetHash, EmailAddress: emailAddress })
                .then(() => {
                    this.setState({
                        resettingPassword: false
                    });
                    this.props.history.push('/change-password' + this.props.location.search);
                }).catch(err => {
                    if (err === 'Aborted') {
                        return
                    }
                    this.setState({
                        passwordResetError: this.props.users.passwordResetError.Value ? this.props.users.passwordResetError.Value : err.toString()
                    });
                })
        }
    }

    componentWillUnmount() {
        cancelRequest();
    }

    render() {
        const { errorMessage, isChanging, passwordChangeFinished, password, passwordconfirm, currentpassword, resettingPassword, passwordResetError, showPassword, passwordError, passwordConfirmError } = this.state;
        const passwordHasBeenResetCheckEmail = this.props.location.search && queryString.parse(this.props.location.search).emailaddress && !queryString.parse(this.props.location.search).passwordistemporaryorexpired;
        const passwordIsTemporaryOrExpired = this.props.location.search && queryString.parse(this.props.location.search).emailaddress && queryString.parse(this.props.location.search).passwordistemporaryorexpired;
        const emailSplit = this.props.login.userProfile.email.split("@");

        const passwordHelp = (indicate) => (
            <React.Fragment>
                <span>Your password must meet the following criteria:</span>
                <ul>
                    <li> Must be between 10 and 256 characters  {indicate && <span style={{ verticalAlign: 'middle' }} className={`icon ${password.length < 10 || password.length > 256 ? 'delete' : 'save'}`} />}</li>
                    <li> Must contain at least three of the following four types of characters: {indicate && <span style={{ verticalAlign: 'middle' }} className={`icon ${calculateNumberOfCriteriaMet(password) < 3 ? 'delete' : 'save'}`} />}</li>
                    <ol>
                        <li> lowercase letter {indicate && <span style={{ verticalAlign: 'middle' }} className={`icon ${password.search(/[a-z]/) === -1 ? 'delete' : 'save'}`} />}</li>
                        <li> uppercase letter {indicate && <span style={{ verticalAlign: 'middle' }} className={`icon ${password.search(/[A-Z]/) === -1 ? 'delete' : 'save'}`} />}</li>
                        <li> number {indicate && <span style={{ verticalAlign: 'middle' }} className={`icon ${password.search(/\d/) === -1 ? 'delete' : 'save'}`} />}</li>
                        <li> special character {indicate && <span style={{ verticalAlign: 'middle' }} className={`icon ${password.search(/[@^$!%*?=\-_+&\[\]{}|\\:'\,\.\?\/`~""\(\);<>]/) === -1 ? 'delete' : 'save'}`} />}</li>
                    </ol>
                </ul>
            </React.Fragment>
        )

        if (resettingPassword) {
            if (passwordResetError) {
                return (<div className="center">
                    <div className="fail">There was a problem resetting your password</div>
                    <div>{passwordResetError.toLowerCase().includes('information could not be found in cache') ? <Link to="/forgot-password">Try again</Link> : passwordResetError}</div>
                </div>)
            }
            return (<div className="spinner center-spinner">Resetting Password</div>)
        }
        if (passwordChangeFinished) {
            return (
                <div className="center">
                    <h2>Your password has been changed</h2>
                    <Link to="/home">Home</Link>
                </div>)
        }
        return (
            <div className="dlas-forms change-password">
                {/* Find out if a reserved domain is present on either side of the @ in the event that the login email is formatted as user#domain@dlasva.onmicrosoft.com instead of user@domain*/}
                {legislativeDomains.find(x => x === emailSplit[1]) || legislativeDomains.findIndex(x => emailSplit[0].includes(x)) >= 0
                    ? <div className="homepage-links"><p>{legislativeInstructions}</p> <Link to="/profile">Return to Profile</Link></div>
                    : houseDomains.find(x => x === emailSplit[1]) || houseDomains.findIndex(x => emailSplit[0].includes(x)) >= 0
                        ? <div className="homepage-links"><p>{houseInstructions}</p> <Link to="/profile">Return to Profile</Link></div>
                        : senateDomains.find(x => x === emailSplit[1]) || senateDomains.findIndex(x => emailSplit[0].includes(x)) >= 0
                            ? <div className="homepage-links"><p>{senateInstructions}</p> <Link to="/profile">Return to Profile</Link></div>
                            : <React.Fragment>
                                <h1>New Password</h1>
                                <hr className="faded-line" />
                                <div>
                                    {passwordHasBeenResetCheckEmail ?
                                        <p>Your password has been reset. Check your email for your temporary password.</p>
                                        : passwordIsTemporaryOrExpired ?
                                            <p>Your password is temporary or expired. Please change your password below.</p>
                                            :
                                            null
                                    }
                                </div>
                                <div className="inner-grid two multi-row" style={{ gridGap: '50px' }}>
                                    <form>
                                        <div>
                                            <label htmlFor="sr-current-password">{passwordHasBeenResetCheckEmail ? "Temporary" : "Current"} Password</label>
                                            <input disabled={isChanging} id="sr-current-password" value={currentpassword} onChange={e => this.handleChange("currentpassword", e.target.value)} type={showPassword ? 'text' : 'password'} />
                                        </div>
                                        <div>
                                            <label htmlFor="sr-new-password">New Password</label>
                                            <button onClick={this.toggleShowTooltip} type="button" className="button info" ref={this.infoButtonRef} > Info</button>
                                            <div className="info-box" ref={this.infoRef} style={{ display: this.state.toolTip ? 'block' : 'none' }}>
                                                {passwordHelp(false)}
                                            </div>
                                            <input disabled={isChanging} id="sr-new-password" autoComplete="false" onBlur={this.checkPassword} value={password} onChange={e => this.handleChange("password", e.target.value)} type={showPassword ? 'text' : 'password'} />
                                            <span className="small-text fail">{passwordError}</span>
                                            {this.state.showHelp && <div style={{ fontSize: '0.8em' }}>{passwordHelp(true)}</div>}
                                        </div>
                                        <div>
                                            <label htmlFor="sr-password-confirm">New Password Confirmation</label>
                                            <input disabled={isChanging} id="sr-password-confirm" autoComplete="false" onBlur={this.checkPasswordConfirm} onChange={e => this.handleChange("passwordconfirm", e.target.value)} value={passwordconfirm} type={showPassword ? 'text' : 'password'} />
                                            <span className="small-text fail">{passwordConfirmError}</span>
                                        </div>
                                        <div className="checkbox flex-row flex-vertical-center flex-start">
                                            <div className="toggle-switch" onClick={this.toggleShowPassword}>
                                                <input id="sr-show-password" checked={showPassword} type="checkbox" style={{ width: "inherit" }} />
                                                <span className="slider"></span>
                                            </div>
                                            <label htmlFor="sr-show-password" className="checkbox-label no-background">Show password</label>
                                        </div>
                                        <div>
                                            {isChanging ?
                                                <div><span className="spinner small-spinner"></span></div>
                                                :
                                                <button onClick={this.changePassword} className="button" type="submit">Change password</button>
                                            }
                                        </div>
                                        <span className="small-text fail">{errorMessage}</span>
                                    </form>
                                </div>
                            </React.Fragment>
                }
            </div>
        );
    }
};

export default connect(
    (state) => {
        const { users, login } = state;
        return {
            users,
            login
        }
    },
    (dispatch) => {
        return {
            actions: bindActionCreators(Object.assign({}, userActionCreators, authActionCreators), dispatch)
        }
    }
)(ChangePassword);






