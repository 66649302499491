import React from 'react';
import ReactTable from "react-table";
import { Link } from 'react-router-dom';
import { bindActionCreators } from 'redux';
import * as Fields from '../../../lis-shared/lis-layout/components/lis-forms-component';
import { committeeActionCreators } from '../../../stores/lis-committee-store';
import { sessionActionCreators } from '../../../stores/lis-session-store';
import { calendarActionCreators } from '../../../stores/lis-calendar-store';
import { authActionCreators } from '../../../stores/lis-auth-store';
import { connect } from 'react-redux';
import moment from 'moment';
import DatePicker from "react-datepicker";
import { cancelRequest } from '../../../services/request.service';

class DocketGrid extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            isLoaded: false,
            sessionOptions: [],
            committeeOptions: [],
            subcommitteeOptions: [],
            docketList: [],
            docketDate: moment().utc().startOf('day').hour(16).minute(30),
            showCreateSuccess: false,
            createFailureMessage: '',
            isAdding: false,
            showDeleteModal: false,
            docketToDeleteIndex: ""
        }

        this.getCommittees = this.getCommittees.bind(this);
        this.getSubcommittees = this.getSubcommittees.bind(this);
        this.getDockets = this.getDockets.bind(this);
        this.createDocket = this.createDocket.bind(this);
        this.deleteDocket = this.deleteDocket.bind(this);
        this.checkDeleteDocket = this.checkDeleteDocket.bind(this);
        this.cancelDeleteDocket = this.cancelDeleteDocket.bind(this);

        this.handleDateChange = this.handleDateChange.bind(this);
        this.handleSessionSelectChange = this.handleSessionSelectChange.bind(this);
        this.handleCommitteeSelectChange = this.handleCommitteeSelectChange.bind(this);
        this.handleSubcommitteeSelectChange = this.handleSubcommitteeSelectChange.bind(this);
    }

    handleSessionSelectChange(value) {
        //The session selectors value has been changed. Remove the committee and subcommittee selections as their dropdown options are going to change
        const newState = {
            selectedSession: value,
            selectedCommittee: '',
            selectedSubcommittee: ''
        };
        this.props.handleDropdownChange(newState, () => {
            this.props.history.push(`/docket-management`);
            //Get the new list of committees that belong to the selected session
            this.getCommittees(value);
        });
        this.setState({
            docketList: []
        });
    }

    handleCommitteeSelectChange(value) {
        //A committee has been selected. Remove the selected subcommittee because the sucommittee dropdown options are going to change
        const newState = {
            selectedCommittee: value,
            selectedSubcommittee: ''
        };
        this.props.handleDropdownChange(newState, () => {
            this.props.history.push(`/docket-management/${value.CommitteeID}`);
            //Get the new list of available subcommittees
            this.getSubcommittees(value.CommitteeID);
            //Get the dockets for the committee to be displayed in the grid
            this.getDockets();
        });
    }

    handleSubcommitteeSelectChange(value) {
        const newState = {
            selectedSubcommittee: value || ''
        };
        this.props.handleDropdownChange(newState, () => {
            this.getDockets();
        });
    }

    handleDateChange(value) {
        if (value) {
            //Default docket/schedule to 4:30pm
            value.utc().startOf('day').hour(16).minute(30);
        }
        //Used when inserting a new docket
        this.setState({
            docketDate: value
        });
    }

    createDocket() {
        if (this.props.selectedSession && this.props.selectedCommittee) {
            this.setState({
                isAdding: true
            }, () => {
                const committeeId = this.props.selectedSubcommittee ? this.props.selectedSubcommittee.CommitteeID : this.props.selectedCommittee.CommitteeID;
                let createDocketObj = {
                    Dockets: [{
                        DocketDate: this.state.docketDate,
                        IsPublic: false,
                        DocketType: "Committee",
                        DocketTypeID: 2,
                        ChamberCode: this.props.selectedCommittee.ChamberCode,
                        CommitteeID: committeeId,
                        SessionID: this.props.selectedSession.SessionID,
                        VoteRoomID: 1,
                        Schedules: [
                            {
                                ScheduleDate: this.state.docketDate,
                                ScheduleTypeID: 6
                            }
                        ]
                    }]
                };
                this.props.actions.createDocket(createDocketObj)
                    .then(() => {
                        this.setState({ showCreateSuccess: true });
                        this.getDockets(committeeId)
                        setTimeout(() => {
                            this.setState({
                                showCreateSuccess: false,
                                isAdding: false,
                                createFailureMessage: ''
                            })
                        }, 3000);
                    }).catch(err => {
                        if (err === 'Aborted') {
                            return;
                        }
                        this.setState({
                            createFailureMessage: this.props.calendar.docketCreateError
                        });
                        setTimeout(() => {
                            this.setState({
                                showCreateSuccess: false,
                                isAdding: false,
                                createFailureMessage: ''
                            })
                        }, 3000);
                    });
            });
        } else {
            this.setState({
                createFailureMessage: "Please select a committee"
            });
            setTimeout(() => {
                this.setState({
                    createFailureMessage: '',
                });
            }, 3000);
        }
    }

    deleteDocket(rowIndex) {
        this.setState({
            docketBeingDeleted: rowIndex
        });
        // Get docket to be deleted
        this.props.actions.getDocketById('?docketId=' + this.state.docketList[rowIndex].DocketID, true)
            .then(() => {
                let docketData = this.props.calendar.docketByIdData;
                docketData.DocketCategories.forEach(x => x.DocketItems.forEach(y => y.IsActive = false));
                docketData.DeletionDate = moment();
                return this.props.actions.saveDocket({ Dockets: [docketData] })
            }).then(() => {
                this.setState({
                    showDeleteSuccess: true,
                    docketBeingDeleted: -1,
                    showDeleteModal: false,
                    docketToDeleteIndex: ""
                });
                this.getDockets();
                setTimeout(() => {
                    this.setState({
                        showDeleteSuccess: false
                    })
                }, 3000);
            }).catch(err => {
                if (err === 'Aborted') {
                    return;
                }
                this.setState({
                    docketBeingDeleted: -1,
                    deleteFailureMessage: 'Docket failed to be deleted',
                    showDeleteModal: false,
                    docketToDeleteIndex: ""
                });
                console.log(err)
                setTimeout(() => {
                    this.setState({
                        deleteFailureMessage: '',
                    });
                }, 3000);
            });
    }

    checkDeleteDocket(docketIndex) {
        this.setState({
            showDeleteModal: true,
            docketToDeleteIndex: docketIndex
        })
    }

    cancelDeleteDocket() {
        this.setState({
            showDeleteModal: false,
            docketToDeleteIndex: ""
        })
    }

    componentDidMount() {
        let selectedSession = this.props.selectedSession;
        this.props.actions.getSessionList().then(() => {
            //Format data for dropdown
            const sessionList = [...this.props.session.sessionList];
            sessionList.forEach(session => {
                session.value = session.SessionID;
                session.label = session.SessionYear + ' ' + session.DisplayName;
                // Select default session if one is not already selected
                if (session.IsDefault && !selectedSession) {
                    selectedSession = session;
                }
            });
            this.setState({
                sessionOptions: sessionList.reverse()
            });
            const newState = {
                selectedSession: selectedSession,
            }
            this.props.handleDropdownChange(newState, () => {
                //Checks to see if the committeeId is in the url, if so then get the subcommittees for that committee if one has not already been selected
                let committeeId = parseInt(this.props.match.params.committeeid, 10);
                if (this.props.selectedCommittee) {
                    this.getCommittees(selectedSession, this.props.selectedCommittee.CommitteeID);
                    this.getSubcommittees(this.props.selectedCommittee.CommitteeID);
                } else if (!isNaN(committeeId)) {
                    this.getCommittees(selectedSession, committeeId);
                    this.getSubcommittees(committeeId);
                } else {
                    this.getCommittees(selectedSession)
                }
            });
        });
    }

    componentWillUnmount() {
        cancelRequest();
    }

    getCommittees(session, committeeId) {
        //Get the committee list based off the session data
        this.props.actions.getCommitteeList('sessionID=' + session.SessionID)
            .then(() => {
                // If the user is a senate user then only show the senate committees
                // If the user is a house user then only show the house committees
                // If the user is both then show all committees
                let committees = [...this.props.committee.committeeList]
                let filteredCommittees = [];
                committees.forEach(committee => {
                    if (committee.ChamberCode === 'S') {
                        committee.label = committee.Name;
                        committee.value = committee.CommitteeID;
                        if (committee.CommitteeID === committeeId) {
                            this.getSubcommittees(committeeId);
                            this.props.handleDropdownChange({ selectedCommittee: committee }, () => {
                                // Don't want to get the dockets for the parent committee if a subcommittee is selected.
                                if (!this.props.selectedSubcommittee) {
                                    this.getDockets();
                                }
                            })
                        }
                        filteredCommittees.push(committee);
                    }
                });

                this.setState({
                    committeeOptions: filteredCommittees,
                    isLoaded: true
                });
            });
    }

    getSubcommittees(committeeId) {
        let listParams = 'sessionID=' + this.props.selectedSession.SessionID + '&parentCommitteeID=' + committeeId
        this.props.actions.getCommitteeList(listParams)
            .then(() => {
                let committees = [...this.props.committee.committeeList]
                committees.forEach(committee => {
                    committee.label = committee.Name;
                    committee.value = committee.CommitteeID;
                    if (this.props.selectedSubcommittee.CommitteeID === committee.CommitteeID) {
                        this.getDockets();
                    }
                });
                this.setState({
                    subcommitteeOptions: committees
                });
            });
    }

    getDockets() {
        const { selectedSession, selectedCommittee, selectedSubcommittee } = this.props;
        this.setState({
            isLoaded: false
        });
        //Get the dockets
        let committeeId = selectedSubcommittee ? selectedSubcommittee.CommitteeID : selectedCommittee.CommitteeID;
        const docketParams = "?sessionID=" + selectedSession.SessionID + "&chamberCode=" + selectedCommittee.ChamberCode + "&committeeID=" + committeeId + "&isPublic=false";
        //The second parameter lets the store know to use the partner request instead of the public
        this.props.actions.getDocketList(docketParams, true)
            .then(() => {
                this.setState({
                    isLoaded: true,
                    docketList: this.props.calendar.docketList
                });
            });
    }

    render() {
        return (
            <div>
                {this.state.showDeleteModal &&
                    <div className='schedule-modal'>
                        <div className='schedule-modal-content'>
                            <div className='grid-wrapper two'>
                                <div className='inner-grid eight-one-and-one'>
                                    <span>Are you sure you want to delete docket {moment(this.state.docketList[this.state.docketToDeleteIndex].DocketDate).format("MM/DD/YYYY") + (this.state.docketList[this.state.docketToDeleteIndex].DocketNumber > 1 ? " (" + this.state.docketList[this.state.docketToDeleteIndex].DocketNumber + ")" : "")}?</span>
                                    {this.state.docketBeingDeleted === this.state.docketToDeleteIndex
                                        ? <div className="small-spinner"></div>
                                        : <button className='button primary' onClick={() => this.deleteDocket(this.state.docketToDeleteIndex)}>Yes</button>
                                    }
                                    <button className='button secondary' onClick={() => this.cancelDeleteDocket()}>No</button>
                                </div>
                            </div>
                        </div>
                    </div>
                }
                <div className="header-half">
                    <h1>Docket Management</h1>
                </div>

                <div className="toolbar inner-grid four">
                    <div>
                        <Fields.Dropdown
                            value={this.props.selectedSession}
                            options={this.state.sessionOptions}
                            id="sessionDropdown"
                            onChange={this.handleSessionSelectChange}
                        />
                    </div>
                    <div>
                        <Fields.Dropdown
                            value={this.props.selectedCommittee}
                            placeholder="Committee"
                            options={this.state.committeeOptions}
                            id="committeeDropdown"
                            onChange={this.handleCommitteeSelectChange}
                        />
                    </div>
                    <div>
                        <Fields.Dropdown
                            value={this.props.selectedSubcommittee}
                            placeholder="Subcommittee?"
                            options={this.state.subcommitteeOptions}
                            id="subcommitteeDropdown"
                            isClearable
                            onChange={this.handleSubcommitteeSelectChange}
                        />
                    </div>
                </div>
                <ReactTable
                    resizable={false}
                    data={this.state.docketList}
                    loading={!this.state.isLoaded}
                    ref={r => this.reactTable = r}
                    columns={[
                        {
                            Header: 'Dockets',
                            width: 'auto',
                            sortable: false,
                            filterable: false,
                            columns: [
                                {
                                    headerClassName: "docket-datepicker-container",
                                    accessor: "DocketDate",
                                    sortable: false,
                                    Header: (
                                        <div className="dlas-forms datepicker-container">
                                            {!this.state.showCreateSuccess && !this.state.createFailureMessage && !this.state.showDeleteSuccess && !this.state.deleteFailureMessage &&
                                                <DatePicker
                                                    className="dnd-field"
                                                    selected={this.state.docketDate}
                                                    onChange={this.handleDateChange}
                                                    placeholderText="Docket Date"
                                                />
                                            }
                                            {this.state.showCreateSuccess &&
                                                <span className="insert-message">Docket was created successfully!</span>
                                            }
                                            {this.state.createFailureMessage &&
                                                <span className="insert-message fail">{this.state.createFailureMessage}</span>
                                            }
                                            {this.state.showDeleteSuccess &&
                                                <span className="insert-message">Docket was deleted successfully!</span>
                                            }
                                            {this.state.deleteFailureMessage &&
                                                <span className="insert-message fail">{this.state.deleteFailureMessage}</span>
                                            }
                                        </div>
                                    ),

                                    Cell: ({ original }) => {
                                        return <span>{moment(original.DocketDate).format("MM/DD/YYYY")}{original.DocketNumber > 1 ? ` (${original.DocketNumber})` : ''}</span>
                                    }
                                }
                            ]
                        },
                        {
                            Header: 'Actions',
                            headerClassName: "submit-action",
                            sortable: false,
                            filterable: false,
                            columns: [
                                {
                                    Header: () => {
                                        return <span><button className="button" onClick={this.createDocket} disabled={this.state.isAdding}>Create</button></span>
                                    },
                                    headerClassName: "header-action",
                                    accessor: "action",
                                    sortable: false,
                                    className: "action-column",
                                    width: 90,
                                    Cell: row => {
                                        if (this.state.docketBeingDeleted === row.index) {
                                            return (
                                                <div className="small-spinner"></div>
                                            )
                                        }
                                        return (
                                            <div className="committee-action-column-two">
                                                <Link to={`/docket-management/${row.original.CommitteeID}/edit-docket/${row.original.DocketID}`} className="icon edit"></Link>
                                                {row.original.IsPublic
                                                    ? <Link target="_blank" to={`/session-details/${row.original.SessionCode}/committee-information/${this.props.selectedSubcommittee ? this.props.selectedSubcommittee.CommitteeNumber : this.props.selectedCommittee.CommitteeNumber}/dockets/${row.original.DocketID}`} className="icon book"></Link>
                                                    : <span onClick={() => this.checkDeleteDocket(row.index)} className="icon delete"></span>
                                                }
                                            </div>
                                        );
                                    }
                                }
                            ]
                        }
                    ]}
                    defaultPageSize={15}
                    pageSizeOptions={[5, 10, 15, 20, 25, 50, 100]}
                    className="committee-table -striped -highlight"
                />
                <br />

            </div>
        );
    }
}

export default connect(
    (state) => {
        const { committee, session, calendar, login } = state;
        return {
            committee,
            session,
            calendar,
            login
        }
    },
    (dispatch) => {
        return {
            actions: bindActionCreators(Object.assign({}, committeeActionCreators, sessionActionCreators, calendarActionCreators, authActionCreators), dispatch)
        }
    }
)(DocketGrid)