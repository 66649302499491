import React from 'react';
import classnames from 'classnames';
import Select, { createFilter } from 'react-select';
import DatePicker from 'react-datepicker';
import { FixedSizeList as List } from "react-window";

const InputFeedback = ({ error }) =>
    error ? (
        <div className="input-feedback">{error}</div>
    ) : null;

const Label = ({
    error,
    className,
    children,
    ...props
}) => {
    return (
        <label className="label" {...props}>
            {children}
        </label>
    );
};


//ADVANCED SEARCH OPTIONS

export const patronTypeOptions = [
    { value: '1', label: 'Chief Patron' },
    { value: '2', label: 'Co-Patron' },
    { value: '3', label: 'Other' }
];

export const searchTypeOptions = [
    { value: 'all', label: 'All' },
    { value: 'billNumber', label: 'Bill Number' },
    { value: 'catchline', label: 'Catchline' }
];

export const billActionsOptions = [
    { value: 'introduced', label: 'Introduced' },
    { value: 'passedHouse', label: 'Passed House' },
    { value: 'passedSenate', label: 'Passed Senate' },
    { value: 'passedBoth', label: 'Passed Both' },
    { value: 'approvedEnacted', label: 'Approved/Enacted' }
];

export const originatingChamberOptions = [
    { value: 'H', label: 'House' },
    { value: 'S', label: 'Senate' }
];

//EDIT/CREATE SESSION OPTIONS

export const sessionOptions = [
    { value: 'Current Biennium', label: 'Current Biennium' },
    { value: 'All Sessions', label: 'All Sessions' }
];

export const districtOptions = [
    { value: 'District 1', label: 'District 1' },
    { value: 'District 2', label: 'District 2' }
];

export const activeSessionOptions = [
    { value: 'primary', label: 'Primary' },
    { value: 'secondary', label: 'Secondary' },
    { value: 'none', label: 'None' }
];

export const sessionTypeOptions = [
    { value: 1, label: 'Regular' },
    { value: 2, label: 'Special' }
];

//EDIT/CREATE USER OPTIONS

export const orgOptions = [
    { value: 'OrgName1', label: 'OrgName1' },
    { value: 'OrgName2', label: 'OrgName2' },
    { value: 'OrgName4', label: 'OrgName4' },
    { value: 'OrgName5', label: 'OrgName5' },
    { value: 'OrgName6', label: 'OrgName6' }
];


export const stateOptions = [
    {
        "value": "Alabama",
        "label": "AL"
    },
    {
        "value": "Alaska",
        "label": "AK"
    },
    {
        "value": "American Samoa",
        "label": "AS"
    },
    {
        "value": "Arizona",
        "label": "AZ"
    },
    {
        "value": "Arkansas",
        "label": "AR"
    },
    {
        "value": "California",
        "label": "CA"
    },
    {
        "value": "Colorado",
        "label": "CO"
    },
    {
        "value": "Connecticut",
        "label": "CT"
    },
    {
        "value": "Delaware",
        "label": "DE"
    },
    {
        "value": "District Of Columbia",
        "label": "DC"
    },
    {
        "value": "Federated States Of Micronesia",
        "label": "FM"
    },
    {
        "value": "Florida",
        "label": "FL"
    },
    {
        "value": "Georgia",
        "label": "GA"
    },
    {
        "value": "Guam",
        "label": "GU"
    },
    {
        "value": "Hawaii",
        "label": "HI"
    },
    {
        "value": "Idaho",
        "label": "ID"
    },
    {
        "value": "Illinois",
        "label": "IL"
    },
    {
        "value": "Indiana",
        "label": "IN"
    },
    {
        "value": "Iowa",
        "label": "IA"
    },
    {
        "value": "Kansas",
        "label": "KS"
    },
    {
        "value": "Kentucky",
        "label": "KY"
    },
    {
        "value": "Louisiana",
        "label": "LA"
    },
    {
        "value": "Maine",
        "label": "ME"
    },
    {
        "value": "Marshall Islands",
        "label": "MH"
    },
    {
        "value": "Maryland",
        "label": "MD"
    },
    {
        "value": "Massachusetts",
        "label": "MA"
    },
    {
        "value": "Michigan",
        "label": "MI"
    },
    {
        "value": "Minnesota",
        "label": "MN"
    },
    {
        "value": "Mississippi",
        "label": "MS"
    },
    {
        "value": "Missouri",
        "label": "MO"
    },
    {
        "value": "Montana",
        "label": "MT"
    },
    {
        "value": "Nebraska",
        "label": "NE"
    },
    {
        "value": "Nevada",
        "label": "NV"
    },
    {
        "value": "New Hampshire",
        "label": "NH"
    },
    {
        "value": "New Jersey",
        "label": "NJ"
    },
    {
        "value": "New Mexico",
        "label": "NM"
    },
    {
        "value": "New York",
        "label": "NY"
    },
    {
        "value": "North Carolina",
        "label": "NC"
    },
    {
        "value": "North Dakota",
        "label": "ND"
    },
    {
        "value": "Northern Mariana Islands",
        "label": "MP"
    },
    {
        "value": "Ohio",
        "label": "OH"
    },
    {
        "value": "Oklahoma",
        "label": "OK"
    },
    {
        "value": "Oregon",
        "label": "OR"
    },
    {
        "value": "Palau",
        "label": "PW"
    },
    {
        "value": "Pennsylvania",
        "label": "PA"
    },
    {
        "value": "Puerto Rico",
        "label": "PR"
    },
    {
        "value": "Rhode Island",
        "label": "RI"
    },
    {
        "value": "South Carolina",
        "label": "SC"
    },
    {
        "value": "South Dakota",
        "label": "SD"
    },
    {
        "value": "Tennessee",
        "label": "TN"
    },
    {
        "value": "Texas",
        "label": "TX"
    },
    {
        "value": "Utah",
        "label": "UT"
    },
    {
        "value": "Vermont",
        "label": "VT"
    },
    {
        "value": "Virgin Islands",
        "label": "VI"
    },
    {
        "value": "Virginia",
        "label": "VA"
    },
    {
        "value": "Washington",
        "label": "WA"
    },
    {
        "value": "West Virginia",
        "label": "WV"
    },
    {
        "value": "Wisconsin",
        "label": "WI"
    },
    {
        "value": "Wyoming",
        "label": "WY"
    }
]

// Styles for select dropdowns to make them same size as other fields
const customStyles = {
    option: (base, state) => ({
        ...base,
        fontSize: '0.8em',
    }),
    control: (base) => ({
        ...base,
        padding: '1px',
        margin: 0,
        minHeight: 0,
        fontSize: '0.8em',
    }),
    menu: (base) => ({
        ...base,
        zIndex: '102' //show over fixed floating save bars
    }),
    singleValue: (base, state) => {
        return { ...base, };
    }
}

export class CustomSelect extends React.Component {
    handleChange = value => {
        this.props.onChange(this.props.id, value);
    };
    handleBlur = () => {
        this.props.onBlur(this.props.id, true);
    };

    render() {
        return (
            <div>
                <label>{this.props.label}</label>
                <Select
                    className={this.props.className}
                    placeholder={this.props.placeholder}
                    styles={customStyles}
                    options={this.props.options}
                    isMulti={this.props.isMulti}
                    isDisabled={this.props.isDisabled}
                    onChange={this.handleChange}
                    onBlur={this.handleBlur}
                    value={this.props.value}
                    filterOption={this.props.filterOption}
                />
            </div>
        );
    }
}

export class CustomDatepicker extends React.Component {
    handleChange = value => {
        this.props.onChange(this.props.id, value);
    };
    handleBlur = () => {
        this.props.onBlur(this.props.id, true);
    };

    render() {
        return (
            <div>
                <label>{this.props.label}</label>
                <DatePicker
                    id={this.props.id}
                    className={this.props.className}
                    selected={this.props.value}
                    onChange={this.handleChange}
                    disabled={this.props.disabled}
                    placeholderText={this.props.placeholderText}
                    popperPlacement={this.props.popperPlacement}
                    withPortal={this.props.withPortal}
                    filterDate={this.props.filterDate}
                />
            </div>
        );
    }
}

class MenuList extends React.Component {
    render() {
        const { options, children, maxHeight, getValue } = this.props;
        const [value] = getValue();
        const height = this.props.height || 35;
        const initialOffset = options.indexOf(value) * height;
        return (
            <List
                height={maxHeight}
                itemCount={children.length}
                itemSize={height}
                initialScrollOffset={initialOffset}
            >
                {({ index, style }) => <div style={style}>{children[index]}</div>}
            </List>
        );
    }
}

//React-select does not work well with large lists greater than 1000 items. This component utilizes react-window to only render a portion of the list so perfomance is not degraded.
export class LargeListSelect extends React.Component {
    render() {
        return (<Select
            {...this.props}
            filterOption={createFilter({ ignoreCase: true, ignoreAccents: false, matchFrom: 'any', stringify: option => `${option.label}`, trim: true, })}
            components={{ MenuList }}
        />)
    }
}

export const Dropdown = ({
    options,
    styles,
    value,
    onChange,
    multi,
    onBlur,
    ...props
}) => {
    return (
        <Select
            options={options}
            styles={customStyles}
            multi={multi}
            onChange={onChange}
            onBlur={onBlur}
            value={value}
            {...props}
        />
    );
};

export const TextInput = ({
    type,
    id,
    label,
    error,
    value,
    onChange,
    className,
    defaultValue,
    ...props
}) => {
    return (
        <div>
            <Label htmlFor={id} error={error}>
                {label}
            </Label>
            <input
                id={id}
                className={className}
                type={type}
                value={value}
                defaultValue={defaultValue}
                onChange={onChange}
                {...props}
            />
            <InputFeedback error={error} />
        </div>
    );
};

export const TextArea = ({
    id,
    label,
    error,
    value,
    onChange,
    className,
    ...props
}) => {
    return (
        <div>
            <Label htmlFor={id} error={error}>
                {label}
            </Label>
            <textarea
                id={id}
                className={className}
                value={value}
                onChange={onChange}
                {...props}
            />
            <InputFeedback error={error} />
        </div>
    );
};

export const CheckboxInput = ({
    type,
    id,
    label,
    error,
    value,
    onChange,
    className,
    ...props
}) => {
    const classes = classnames(
        'input-group',
        {
            'animated shake error': !!error,
        },
        className
    );
    return (
        <div className={classes}>
            <Label htmlFor={id} error={error}>
                {label}
            </Label>
            <label className="checkbox-label">
                <input
                    id={id}
                    className="formCheckbox"
                    type={type}
                    checked={value}
                    value={value}
                    onChange={onChange}
                    {...props}
                />
                <span>
                    {value ? 'Yes' : 'No'}
                </span>
            </label>
            <InputFeedback error={error} />
        </div>
    );
};