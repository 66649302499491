import React from 'react';
import { Route } from 'react-router';
import PartnerManagementGrid from './lis-partner-grid';
import PartnerManagementForm from './lis-partner-form';

class PartnerManagement extends React.Component {

    render() {
        return (
            <div>
                <Route exact path={this.props.match.path} component={PartnerManagementGrid} />
                <Route exact path={`${this.props.match.path}/edit-partner/:partnerId`} component={PartnerManagementForm} />
            </div>
        )
    }
}

export default PartnerManagement;