import React from 'react';
import renderHTML from 'react-render-html';
import moment from 'moment';
import { Link } from 'react-router-dom';


const Docket = props => {
    const patronList = props.docket.Patrons.map((patron, index) => {
        return (<React.Fragment key={patron.MemberID}>{patron.PatronDisplayName}{index !== props.docket.Patrons.length - 1 && ", "}</React.Fragment>)
    });
    return (
        <div>
            <div className="details-header parent-li" style={{ display: "inline-flex" }}>
                <h4 className="bilnum"><Link to={`/bill-details/${props.data.SessionCode}/${props.docket.LegislationNumber}`} target="_blank" rel="noreferrer">{props.docket.LegislationNumber}</Link>{!props.hideCaption && " - " + props.docket.LegislationDescription}</h4>
            </div>
            <div className="list-desc">
                <div className="bold">
                    {!props.hidePatron &&
                        patronList.length > 1
                        ? <p className="patalt">Patrons: {patronList}</p>
                        : !props.hidePatron && <p className="patalt">Patron: {patronList}</p>
                    }
                </div>
                <div style={{ marginBottom: '10px' }}>
                    {!props.hideSummary &&
                        <React.Fragment>
                            {props.docket.Summary ? renderHTML(props.docket.Summary) : ''}
                        </React.Fragment>
                    }
                </div>
                <div>
                    {!props.hideTitle &&
                        <p>{props.docket.DraftTitle ? renderHTML(props.docket.DraftTitle) : ''}</p>
                    }
                </div>
                <div>
                    {!props.hideLDNumber &&
                        <p className="ldrt">{props.docket.LDNumber}</p>
                    }
                </div>
            </div>
        </div>
    )
}

class DocketDetailsComponent extends React.Component {
    render() {
        const { data, session } = this.props;
        const categories = data.DocketCategories || data.HouseAgendaCategories;
        const clerks = data.Staff.filter(obj => obj.StaffRoleTypeID === 1);
        const staff = data.Staff.filter(obj => obj.StaffRoleTypeID === 2);
        const committeeHeader = (data.ChamberCode === "S" ? "Senate" : "House") + " Committee " + (data.ParentCommitteeName
            ? data.ParentCommitteeName === "Judiciary"
                ? "on the " + data.ParentCommitteeName
                : data.ParentCommitteeName === "Courts of Justice"
                    ? "for " + data.ParentCommitteeName
                    : "on " + data.ParentCommitteeName
            : data.CommitteeName === "Judiciary"
                ? "on the " + data.CommitteeName
                : data.CommitteeName === "Courts of Justice"
                    ? "for " + data.CommitteeName
                    : "on " + data.CommitteeName
        );
        const subcommitteeHeader = data.ParentCommitteeName && "Subcommittee on " + data.CommitteeName;
        const sessionHeader = session.SessionYear + " " + session.DisplayName;
        const memberNames = data.CommitteeMember.map((member, index) => {
            //Display Co-chair instead of Chair if there is more than one chairman on the committee
            return (<React.Fragment key={member.MemberID}>
                {data.CommitteeMember.length === 1 ? "Chair: " : "Co-Chair: "} {member.MemberDisplayName}{index !== data.CommitteeMember.length - 1 && ", "}
            </React.Fragment>);
        });
        const clerkNames = clerks.map((clerk, index) => {
            //Display Co-chair instead of Chair if there is more than one chairman on the committee
            return (<React.Fragment key={clerk.StaffID}>
                {clerk.FullName}{index !== clerks.length - 1 && ", "}
            </React.Fragment>);
        });
        const staffNames = staff.map((staf, index) => {
            //Display Co-chair instead of Chair if there is more than one chairman on the committee
            return (<React.Fragment key={staf.StaffID}>
                {staf.FullName}{index !== staff.length - 1 && ", "}
            </React.Fragment>);
        });
        return (
            <div className="bill-theme public-details inner-grid docket-details-wrapper">
                <div>
                    <h2>{sessionHeader}</h2>
                </div>
                <hr />
                <div>
                    <div className="details-header parent-li">
                        <h3 className="center">{committeeHeader}</h3>
                    </div>
                    {subcommitteeHeader &&
                        <div className="details-header parent-li">
                            <h3 className="center">{subcommitteeHeader}</h3>
                        </div>
                    }
                    <div className="list-desc">
                        <p>{memberNames}</p>
                        <br />
                        {!this.props.hideClerk &&
                            <p className="i0">{clerks.length === 1 ? "Clerk: " : "Clerks: "} {clerkNames}</p>
                        }
                        {!this.props.hideStaff && staffNames.length > 0 &&
                            <p className="i0">Staff: {staffNames}</p>
                        }
                        {data.Schedules.map((schedule, scheduleIndex) =>
                            <React.Fragment key={scheduleIndex}>
                                <p className="i0">Date of Meeting: {moment(data.DocketDate).format("MM/DD/YYYY")}</p>
                                <p className="i0">Time: {schedule.ScheduleTime ? schedule.ScheduleTime : "None"}</p>
                                <p className="i0">Room: {schedule.RoomDescription ? schedule.RoomDescription : "None"}</p>
                                <p className="i0" style={{whiteSpace: 'pre-wrap'}}>Comments: {schedule.Comments ? schedule.Comments : "None"}</p>
                            </React.Fragment>
                        )}
                    </div>
                </div>
                {categories.map((category, categoryIndex) => {
                    let items = category.DocketItems || category.HouseAgendaItems;
                    items = items.filter(item => item.IsActive);
                    if (category.IsLegislationCategory) {
                        return (
                            <div key={categoryIndex}>
                                {items.length > 0 && items.map(item => <Docket key={item.LegislationID} docket={item} {...this.props} />)}
                            </div>
                        );
                    } else {
                        return (
                            <div>
                                {items.length > 0 &&
                                    <React.Fragment>
                                        <div className="details-header parent-li">
                                            <h4>Notes</h4>
                                        </div>
                                        {items.map((item, index) =>
                                            <div key={index}>
                                                <div className="list-desc">
                                                    <div>
                                                        <p>{item.Description}</p>
                                                    </div>
                                                </div>
                                            </div>
                                        )}
                                    </React.Fragment>
                                }
                            </div>);
                    }
                })}
            </div>
        )
    }
}

export default DocketDetailsComponent;
