import { partnerRequest, publicRequest } from '../services/request.service'

const API_URL = window.env ? window.env.API_SERVER : '';
const NO_CONTENT = 2;
const REQUEST_FAILURE = 1;
const REQUEST_SUCCESS = 0;

const printDocumentsSuccess = "PRINT_DOCUMENTS_SUCCESS";
const printDocumentsFailure = "PRINT_DOCUMENTS_FAILURE";

const printCreateSuccess = "PRINT_CREATE_SUCCESS";
const printCreateFailure = "PRINT_CREATE_FAILURE";

const printSaveSuccess = "PRINT_SAVE_SUCCESS";
const printSaveFailure = "PRINT_SAVE_FAILURE";

const batchItemCompleteSuccess = "BATCH_ITEM_SUCCESS";
const batchItemCompleteFailure = "BATCH_ITEM_FAILURE";

const printAllEnrolledSuccess = "PRINT_ALL_ENROLLED_SUCCESS";
const printAllEnrolledFailure = "PRINT_ALL_ENROLLED_FAILURE";

const initialState = {
    printDocuments: [],
    printDocumentsError: '',
    printCreate: '',
    printCreateError: '',
    printSave: '',
    printSaveError: '',
    batchItemComplete: '',
    batchItemCompleteError: '',
    printAllEnrolledError: ''
};

export const printActionCreators = {

    receivePrintDocuments: resObj => ({
        type: printDocumentsSuccess,
        printDocuments: resObj
    }),

    printDocumentsError: (message) => ({
        type: printDocumentsFailure,
        printDocumentsError: message
    }),

    getPrintDocuments: (params) => {
        return dispatch => {
            let headers = {
                'content-type': "application/json; charset=utf-8"
            }
            return partnerRequest((API_URL || process.env.REACT_APP_PRINT_DOCUMENTS_SERVICE) + '/PrintDocuments/api/getprintdocumentsasync', 'GET', headers, '', params)
                .then(result => {
                    if (result[0] === REQUEST_FAILURE) {
                        dispatch(printActionCreators.printDocumentsError(result[1]))
                        return Promise.reject(result[1])
                    } else if (result[0] === REQUEST_SUCCESS) {
                        dispatch(printActionCreators.receivePrintDocuments(result[1].PrintBatches))
                    } else if (result[0] === NO_CONTENT) {
                        dispatch(printActionCreators.receivePrintDocuments([]))
                        return Promise.reject('No documents found');
                    } else if (result.name === 'AbortError') {
                        return Promise.reject("Aborted");
                    }
                }).catch(err => Promise.reject(err));
        }
    },

    receivePrintCreate: resObj => ({
        type: printCreateSuccess,
        printCreate: resObj
    }),

    printCreateError: (message) => ({
        type: printCreateFailure,
        printCreateError: message
    }),

    createPrintDocument: (body) => {
        return dispatch => {
            let headers = {
                'content-type': "application/json; charset=utf-8"
            }
            return partnerRequest((API_URL || process.env.REACT_APP_PRINT_DOCUMENTS_SERVICE) + '/printdocuments/api/createprintdocumentasync', 'POST', headers, body, '')
                .then(result => {
                    if (result[0] === REQUEST_FAILURE) {
                        dispatch(printActionCreators.printCreateError(result[1]))
                        return Promise.reject(result[1])
                    } else if (result[0] === REQUEST_SUCCESS) {
                        dispatch(printActionCreators.receivePrintCreate(result[1][0]))
                    } else if (result[0] === NO_CONTENT) {
                        dispatch(printActionCreators.printCreateError('No Content'))
                        return Promise.reject('No Content');
                    } else if (result.name === 'AbortError') {
                        return Promise.reject("Aborted");
                    }
                }).catch(err => Promise.reject(err));
        }
    },

    receivePrintSave: resObj => ({
        type: printSaveSuccess,
        printSave: resObj
    }),

    printSaveError: (message) => ({
        type: printSaveFailure,
        printSaveError: message
    }),

    savePrintDocument: (body) => {
        return dispatch => {
            let headers = {
                'content-type': "application/json; charset=utf-8"
            }
            return partnerRequest((API_URL || process.env.REACT_APP_PRINT_DOCUMENTS_SERVICE) + '/printdocuments/api/saveprintdocumentasync', 'PUT', headers, body, '')
                .then(result => {
                    if (result[0] === REQUEST_FAILURE) {
                        dispatch(printActionCreators.printSaveError(result[1]))
                        return Promise.reject(result[1])
                    } else if (result[0] === REQUEST_SUCCESS) {
                        dispatch(printActionCreators.receivePrintSave(result[1][0]))
                    } else if (result[0] === NO_CONTENT) {
                        dispatch(printActionCreators.printSaveError('No Content'))
                        return Promise.reject('No Content');
                    } else if (result.name === 'AbortError') {
                        return Promise.reject("Aborted");
                    }
                }).catch(err => Promise.reject(err));
        }
    },

    receiveBatchItemComplete: resObj => ({
        type: batchItemCompleteSuccess,
        batchItemComplete: resObj
    }),

    batchItemCompleteError: (message) => ({
        type: batchItemCompleteFailure,
        batchItemCompleteError: message
    }),

    completeBatchItem: (body) => {
        return dispatch => {
            let headers = {
                'content-type': "application/json; charset=utf-8"
            }
            return partnerRequest((API_URL || process.env.REACT_APP_PRINT_DOCUMENTS_SERVICE) + '/printdocuments/api/CompletePrintDocumentAsync', 'PUT', headers, body, '')
                .then(result => {
                    if (result[0] === REQUEST_FAILURE) {
                        dispatch(printActionCreators.batchItemCompleteError(result[1]))
                        return Promise.reject(result[1])
                    } else if (result[0] === REQUEST_SUCCESS) {
                        dispatch(printActionCreators.receiveBatchItemComplete(result[1][0]))
                    } else if (result[0] === NO_CONTENT) {
                        dispatch(printActionCreators.batchItemCompleteError('No Content'))
                        return Promise.reject("No Content");
                    } else if (result.name === 'AbortError') {
                        return Promise.reject("Aborted");
                    }
                }).catch(err => Promise.reject(err));
        }
    },

    receivePrintAllEnrolled: resObj => ({
        type: printAllEnrolledSuccess
    }),

    printAllEnrolledError: (message) => ({
        type: printAllEnrolledFailure,
        printAllEnrolledError: message
    }),

    printAllEnrolled: (params) => {
        return dispatch => {
            let headers = {
                'content-type': "application/json; charset=utf-8"
            }
            return partnerRequest((API_URL || process.env.REACT_APP_PRINT_DOCUMENTS_SERVICE) + '/printdocuments/api/ProcessAllEnrolledBillsAsync', 'POST', headers, '', params)
                .then(result => {
                    if (result[0] === REQUEST_FAILURE) {
                        dispatch(printActionCreators.printAllEnrolledError(result[1]))
                        return Promise.reject(result[1])
                    } else if (result[0] === REQUEST_SUCCESS) {
                        dispatch(printActionCreators.receivePrintAllEnrolled(result[1][0]))
                    } else if (result[0] === NO_CONTENT) {
                        dispatch(printActionCreators.printAllEnrolledError('No Content'))
                        return Promise.reject("No Content");
                    } else if (result.name === 'AbortError') {
                        return Promise.reject("Aborted");
                    }
                }).catch(err => Promise.reject(err));
        }
    }
};


export const reducer = (state, action) => {

    state = state || initialState;

    switch (action.type) {
        case printDocumentsSuccess:
            return Object.assign({}, state, {
                printDocuments: action.printDocuments,
                printDocumentsError: action.printDocumentsError
            })
        case printDocumentsFailure:
            return Object.assign({}, state, {
                printDocumentsError: action.printDocumentsError
            })
        case printCreateSuccess:
            return Object.assign({}, state, {
                printCreate: action.printCreate,
                printCreateError: action.printCreateError
            })
        case printCreateFailure:
            return Object.assign({}, state, {
                printCreateError: action.printCreateError
            })
        case printSaveSuccess:
            return Object.assign({}, state, {
                printSave: action.printSave,
                printSaveError: action.printSaveError
            })
        case printSaveFailure:
            return Object.assign({}, state, {
                printSaveError: action.printSaveError
            })
        case batchItemCompleteSuccess:
            return Object.assign({}, state, {
                batchItemComplete: action.batchItemComplete,
                batchItemCompleteError: action.batchItemCompleteError
            })
        case batchItemCompleteFailure:
            return Object.assign({}, state, {
                batchItemCompleteError: action.batchItemCompleteError
            })
        case printAllEnrolledSuccess:
            return Object.assign({}, state, {
                printAllEnrolledError: action.printAllEnrolledError
            })
        case printAllEnrolledFailure:
            return Object.assign({}, state, {
                printAllEnrolledError: action.printAllEnrolledError
            })
        default:
            return state
    }
};