import { partnerRequest, publicRequest } from '../services/request.service'

const API_URL = window.env ? window.env.API_SERVER : '';
const NO_CONTENT = 2;
const REQUEST_FAILURE = 1;
const REQUEST_SUCCESS = 0;

const committeeListRequest = "GET_COMMITTEE_LIST_REQUEST";
const committeeListSuccess = "GET_COMMITTEE_LIST_SUCCESS";
const committeeListFailure = "GET_COMMITTEE_LIST_FAILURE";

const createCommitteeRequest = "CREATE_COMMITTEE_REQUEST";
const createCommitteeSuccess = "CREATE_COMMITTEE_SUCCESS";
const createCommitteeFailure = "CREATE_COMMITTEE_FAILURE";

const saveCommitteeRequest = "SAVE_COMMITTEE_REQUEST";
const saveCommitteeSuccess = "SAVE_COMMITTEE_SUCCESS";
const saveCommitteeFailure = "SAVE_COMMITTEE_FAILURE";

const partnerCommitteeListRequest = "GET_PARTNER_COMMITTEE_LIST_REQUEST";
const partnerCommitteeListSuccess = "GET_PARTNER_COMMITTEE_LIST_SUCCESS";
const partnerCommitteeListFailure = "GET_PARTNER_COMMITTEE_LIST_FAILURE";

const partnerCommitteesRequest = "GET_PARTNER_COMMITTEES_REQUEST";
const partnerCommitteesSuccess = "GET_PARTNER_COMMITTEES_SUCCESS";
const partnerCommitteesFailure = "GET_PARTNER_COMMITTEES_FAILURE";

const committeeByIdRequest = "GET_COMMITTEE_BY_ID_REQUEST";
const committeeByIdSuccess = "GET_COMMITTEE_BY_ID_SUCCESS";
const committeeByIdFailure = "GET_COMMITTEE_BY_ID_FAILURE";

const committeeByNumberRequest = "GET_COMMITTEE_BY_NUMBER_REQUEST";
const committeeByNumberSuccess = "GET_COMMITTEE_BY_NUMBER_SUCCESS";
const committeeByNumberFailure = "GET_COMMITTEE_BY_NUMBER_FAILURE";

const committeePartnerByIdRequest = "GET_PARTNER_COMMITTEE_BY_ID_REQUEST";
const committeePartnerByIdSuccess = "GET_PARTNER_COMMITTEE_BY_ID_SUCCESS";
const committeePartnerByIdFailure = "GET_PARTNER_COMMITTEE_BY_ID_FAILURE";

const committeesByMemberRequest = "GET_COMMITTEES_BY_MEMBER_REQUEST";
const committeesByMemberSuccess = "GET_COMMITTEES_BY_MEMBER_SUCCESS";
const committeesByMemberFailure = "GET_COMMITTEES_BY_MEMBER_FAILURE";

const toggleChamber = "COMMITTEES_TOGGLE_CHAMBER";

const initialState = {
    committeeList: [],
    savedCommittee: '',
    partnerCommitteeList: [],
    partnerCommitteeListError: 'Could not retrieve subcommittees.',
    partnerCommittees: [],
    membersList: '',
    committeeByIdData: [],
    committeeByNumberData: [],
    committeesByMember: [],
    createdCommittee: '',
    partnerCommitteeByIdData: {},
    createCommitteeMessage: '',
    saveCommitteeError: '',
    committeeChamber: ''
};



export const committeeActionCreators = {

    // SAVE AN EXISTING COMMITTEE
    saveCommitteeRequest: () => ({
        type: saveCommitteeRequest,
        isFetching: true
    }),

    receiveSaveCommittee: (responseObj) => ({
        type: saveCommitteeSuccess,
        isFetching: false,
        savedCommittee: responseObj
    }),

    saveCommitteeError: saveCommitteeError => ({
        type: saveCommitteeFailure,
        isFetching: false,
        saveCommitteeError: saveCommitteeError
    }),

    saveCommittee: (body) => {
        return dispatch => {
            dispatch(committeeActionCreators.saveCommitteeRequest());
            let headers = {
                'content-type': "application/json; charset=utf-8"
            }
            return partnerRequest((API_URL || process.env.REACT_APP_COMMITTEE_API_URL) + '/Committee/api/saveCommitteeAsync', 'PUT', headers, body, '')
                .then(result => {
                    if (result[0] === REQUEST_FAILURE) {
                        dispatch(committeeActionCreators.saveCommitteeError(result[1]))
                        return Promise.reject(result[1])
                    } else if (result[0] === REQUEST_SUCCESS) {
                        // Dispatch the success action
                        dispatch(committeeActionCreators.receiveSaveCommittee(result[1][0]))
                    } else if (result[0] === NO_CONTENT) {
                        dispatch(committeeActionCreators.saveCommitteeError('No Content'))
                        return Promise.reject('No Content')
                    } else if (result.name === 'AbortError') {
                        return Promise.reject("Aborted");
                    }
                }).catch(err => Promise.reject(err));
        }
    },

    // CREATE A NEW COMMITTEE
    createCommitteeRequest: () => ({
        type: createCommitteeRequest,
        isFetching: true
    }),

    receiveCreateCommittee: (responseObj) => ({
        type: createCommitteeSuccess,
        isFetching: false,
        createdCommittee: responseObj,
    }),

    createCommitteeError: message => ({
        type: createCommitteeFailure,
        isFetching: false,
        createCommitteeMessage: message
    }),

    createNewCommittee: (body) => {
        return dispatch => {
            dispatch(committeeActionCreators.createCommitteeRequest());
            let headers = {
                'content-type': "application/json; charset=utf-8"
            }
            return partnerRequest((API_URL || process.env.REACT_APP_COMMITTEE_API_URL) + '/Committee/api/createCommitteeAsync', 'POST', headers, body, '')
                .then(result => {
                    if (result[0] === REQUEST_FAILURE) {
                        dispatch(committeeActionCreators.createCommitteeError('Fail ' + result[1]))
                        return Promise.reject(result[1])
                    } else if (result[0] === REQUEST_SUCCESS) {
                        // Dispatch the success action
                        dispatch(committeeActionCreators.receiveCreateCommittee(result[1]))
                    } else if (result[0] === NO_CONTENT) {
                        dispatch(committeeActionCreators.createCommitteeError('No Content'))
                        return Promise.reject('No Content')
                    } else if (result.name === 'AbortError') {
                        return Promise.reject("Aborted");
                    }
                }).catch(err => Promise.reject(err));
        }
    },

    // GET PARTNER COMMITTEE LIST FOR COMMITTEE MANAGEMENT SUBCOMMITTEES
    requestPartnerCommitteeList: () => ({
        type: partnerCommitteeListRequest,
        isFetching: true
    }),

    receivePartnerCommitteeList: responseObj => ({
        type: partnerCommitteeListSuccess,
        isFetching: false,
        partnerCommitteeList: responseObj
    }),

    partnerCommitteeListError: message => ({
        type: partnerCommitteeListFailure,
        isFetching: false,
        partnerCommitteeListError: message
    }),

    getPartnerCommitteeList: (params) => {
        return dispatch => {
            dispatch(committeeActionCreators.requestPartnerCommitteeList());
            let headers = {
                'content-type': "application/json; charset=utf-8"
            }
            return partnerRequest((API_URL || process.env.REACT_APP_COMMITTEE_API_URL) + '/Committee/api/GetPartnerCommitteeListAsync?', 'GET', headers, '', params)
                .then(result => {
                    if (result[0] === 1) {
                        dispatch(committeeActionCreators.partnerCommitteeListError('Could not retrieve subcommittees.'))
                        return Promise.reject(result[1])
                    } else if (result[0] === 0) {
                        // Dispatch the success action
                        dispatch(committeeActionCreators.receivePartnerCommitteeList(result[1].Committees))
                    } else if (result[0] === 2) {
                        dispatch(committeeActionCreators.receivePartnerCommitteeList([]))
                    } else if (result.name === 'AbortError') {
                        return Promise.reject("Aborted");
                    }
                }).catch(err => Promise.reject(err));
        }
    },

    // GET PARTNER COMMITTEES FOR COMMITTEE MANAGEMENT GRID
    requestPartnerCommittees: () => ({
        type: partnerCommitteesRequest,
        isFetching: true
    }),

    receivePartnerCommittees: responseObj => ({
        type: partnerCommitteesSuccess,
        isFetching: false,
        partnerCommittees: responseObj
    }),

    partnerCommitteesError: message => ({
        type: partnerCommitteesFailure,
        isFetching: false,
        partnerCommitteesError: message
    }),

    getPartnerCommittees: (params) => {
        return dispatch => {
            dispatch(committeeActionCreators.requestPartnerCommittees());
            let headers = {
                'content-type': "application/json; charset=utf-8"
            }
            return partnerRequest((API_URL || process.env.REACT_APP_COMMITTEE_API_URL) + '/Committee/api/GetPartnerCommitteesAsync?', 'GET', headers, '', params)
                .then(result => {
                    if (result[0] === REQUEST_FAILURE) {
                        dispatch(committeeActionCreators.partnerCommitteesError(result[1]))
                        return Promise.reject(result[1])
                    } else if (result[0] === REQUEST_SUCCESS) {
                        // Dispatch the success action
                        dispatch(committeeActionCreators.receivePartnerCommittees(result[1].Committees))
                    } else if (result[0] === NO_CONTENT) {
                        dispatch(committeeActionCreators.partnerCommitteesError('No Content'))
                    } else if (result.name === 'AbortError') {
                        return Promise.reject("Aborted");
                    }
                }).catch(err => Promise.reject(err))
        }
    },

    requestCommitteesByMember: () => ({
        type: committeesByMemberRequest,
        isFetching: true
    }),

    receiveCommitteesByMember: responseObj => ({
        type: committeesByMemberSuccess,
        isFetching: false,
        committeesByMember: responseObj
    }),

    committeesByMemberError: message => ({
        type: committeesByMemberFailure,
        isFetching: false,
        message
    }),

    // GET ALL COMMITTEES BY MEMBER ID
    getCommitteesByMember: (params) => {
        return dispatch => {
            dispatch(committeeActionCreators.requestCommitteesByMember());

            return publicRequest((API_URL || process.env.REACT_APP_MEMBERS_API_URL) + '/Member/api/GetCommitteesListAsync/?', 'GET', '', '', params)
                .then(result => {
                    if (result[0] === REQUEST_FAILURE) {
                        dispatch(committeeActionCreators.committeesByMemberError(result[1]))
                        return Promise.reject(result[1])
                    } else if (result[0] === REQUEST_SUCCESS) {
                        // Dispatch the success action
                        dispatch(committeeActionCreators.receiveCommitteesByMember(result[1].Committees))
                    } else if (result[0] === NO_CONTENT) {
                        dispatch(committeeActionCreators.receiveCommitteesByMember([]))
                    } else if (result.name === 'AbortError') {
                        return Promise.reject("Aborted");
                    }
                }).catch(err => Promise.reject(err))
        }
    },

    requestCommitteeList: () => ({
        type: committeeListRequest,
        isFetching: true
    }),

    receiveCommitteeList: responseObj => ({
        type: committeeListSuccess,
        isFetching: false,
        committeeList: responseObj
    }),

    committeeListError: message => ({
        type: committeeListFailure,
        isFetching: false,
        committeeList: [],
        message
    }),

    // Get all committees for a session start date & chamber
    getCommitteeList: (params) => {
        //Common params:
        //effectiveDate (must be URI encoded ISO date string),
        //chamberCode, 
        //parentCommitteeID

        return dispatch => {
            dispatch(committeeActionCreators.requestCommitteeList());

            return publicRequest((API_URL || process.env.REACT_APP_COMMITTEE_API_URL) + '/Committee/api/GetCommitteeListAsync?', 'GET', '', '', params)
                .then(result => {
                    if (result[0] === REQUEST_FAILURE) {
                        dispatch(committeeActionCreators.committeeListError(result[1]))
                        return Promise.reject(result[1])
                    } else if (result[0] === REQUEST_SUCCESS) {
                        // Dispatch the success action
                        dispatch(committeeActionCreators.receiveCommitteeList(result[1].Committees))
                        return (result[1])
                    } else if (result[0] === NO_CONTENT) {
                        dispatch(committeeActionCreators.receiveCommitteeList([]))
                    } else if (result.name === 'AbortError') {
                        return Promise.reject("Aborted");
                    }
                }).catch(err => Promise.reject(err))
        }
    },

    // GET COMMITTEE BY ID FOR PUBLIC VIEWS
    requestCommitteeById: () => ({
        type: committeeByIdRequest,
        isFetching: true
    }),

    receiveCommitteeById: responseObj => ({
        type: committeeByIdSuccess,
        isFetching: false,
        committeeByIdData: responseObj
    }),

    committeeByIdError: message => ({
        type: committeeByIdFailure,
        isFetching: false,
        message
    }),

    // GET COMMITTEE DETAILS BY COMMITTEE ID
    getCommitteeById: (params) => {

        return dispatch => {
            dispatch(committeeActionCreators.requestCommitteeById());

            return publicRequest((API_URL || process.env.REACT_APP_COMMITTEE_API_URL) + '/Committee/api/getCommitteeByIdAsync/', 'GET', '', '', params)
                .then(result => {
                    if (result[0] === REQUEST_FAILURE) {
                        dispatch(committeeActionCreators.committeeByIdError(result[1]))
                        return Promise.reject(result[1])
                    } else if (result[0] === REQUEST_SUCCESS) {
                        // Dispatch the success action
                        dispatch(committeeActionCreators.receiveCommitteeById(result[1].Committees[0]))
                    } else if (result[0] === NO_CONTENT) {
                        dispatch(committeeActionCreators.committeeByIdError('No Content'))
                        return Promise.reject('No Content')
                    } else if (result.name === 'AbortError') {
                        return Promise.reject("Aborted");
                    }
                }).catch(err => Promise.reject(err))
        }
    },

    // GET COMMITTEE BY NUMBER FOR PUBLIC VIEWS
    requestCommitteeByNumber: () => ({
        type: committeeByNumberRequest,
        isFetching: true
    }),

    receiveCommitteeByNumber: responseObj => ({
        type: committeeByNumberSuccess,
        isFetching: false,
        committeeByNumberData: responseObj
    }),

    committeeByNumberError: message => ({
        type: committeeByNumberFailure,
        isFetching: false,
        message
    }),

    // GET COMMITTEE DETAILS BY COMMITTEE NUMBER
    getCommitteeByNumber: (params) => {

        return dispatch => {
            dispatch(committeeActionCreators.requestCommitteeByNumber());

            return publicRequest((API_URL || process.env.REACT_APP_COMMITTEE_API_URL) + '/Committee/api/GetCommitteesAsync/', 'GET', '', '', params)
                .then(result => {
                    if (result[0] === REQUEST_FAILURE) {
                        dispatch(committeeActionCreators.committeeByNumberError(result[1]))
                        return Promise.reject(result[1])
                    } else if (result[0] === REQUEST_SUCCESS) {
                        // Dispatch the success action
                        dispatch(committeeActionCreators.receiveCommitteeByNumber(result[1].Committees[0]))
                    } else if (result[0] === NO_CONTENT) {
                        dispatch(committeeActionCreators.committeeByNumberError('No Content'))
                        return Promise.reject('No Content')
                    } else if (result.name === 'AbortError') {
                        return Promise.reject("Aborted");
                    }
                }).catch(err => Promise.reject(err))
        }
    },

    // GET COMMITTEE BY ID FOR COMMITTEE MANAGEMENT (W/ PARTNER AUTH)
    requestPartnerCommitteeById: () => ({
        type: committeePartnerByIdRequest,
        isFetching: true
    }),

    receivePartnerCommitteeById: responseObj => ({
        type: committeePartnerByIdSuccess,
        isFetching: false,
        partnerCommitteeByIdData: responseObj
    }),

    committeePartnerByIdError: message => ({
        type: committeePartnerByIdFailure,
        isFetching: false,
        message
    }),

    getPartnerCommitteeById: (params) => {

        return dispatch => {
            dispatch(committeeActionCreators.requestPartnerCommitteeById());
            let headers = {
                'content-type': "application/json; charset=utf-8"
            }
            return partnerRequest((API_URL || process.env.REACT_APP_COMMITTEE_API_URL) + '/Committee/api/GetPartnerCommitteeByIdAsync/', 'GET', headers, '', params)
                .then(result => {
                    if (result[0] === REQUEST_FAILURE) {
                        dispatch(committeeActionCreators.committeePartnerByIdError(result[1]))
                        return Promise.reject(result[1])
                    } else if (result[0] === REQUEST_SUCCESS) {
                        // Dispatch the success action
                        dispatch(committeeActionCreators.receivePartnerCommitteeById(result[1].Committees[0]))
                        return result[1].Committees[0];
                    } else if (result[0] === NO_CONTENT) {
                        dispatch(committeeActionCreators.committeePartnerByIdError('No Content'))
                        return Promise.reject('No Content')
                    } else if (result.name === 'AbortError') {
                        return Promise.reject("Aborted");
                    }
                }).catch(err => Promise.reject(err))
        }
    },

    toggleChamber: (value) => ({
        type: toggleChamber,
        committeeChamber: value
    })
};

export const reducer = (state, action) => {
    state = state || initialState;

    switch (action.type) {
        case createCommitteeRequest:
            return Object.assign({}, state, {
                isFetching: true
            });
        case createCommitteeSuccess:
            return Object.assign({}, state, {
                isFetching: false,
                createdCommittee: action.createdCommittee,
                createCommitteeMessage: ''
            });
        case createCommitteeFailure:
            return Object.assign({}, state, {
                isFetching: false,
                createCommitteeMessage: action.createCommitteeMessage
            });
        case partnerCommitteeListRequest:
            return Object.assign({}, state, {
                isFetching: true
            });
        case partnerCommitteeListSuccess:
            return Object.assign({}, state, {
                isFetching: false,
                partnerCommitteeList: action.partnerCommitteeList,
                partnerCommitteeListError: ''
            });
        case partnerCommitteeListFailure:
            return Object.assign({}, state, {
                isFetching: false,
                partnerCommitteeListError: action.partnerCommitteeListError
            });
        case partnerCommitteesRequest:
            return Object.assign({}, state, {
                isFetching: true
            });
        case partnerCommitteesSuccess:
            return Object.assign({}, state, {
                isFetching: false,
                partnerCommittees: action.partnerCommittees,
                partnerCommitteesError: ''
            });
        case partnerCommitteesFailure:
            return Object.assign({}, state, {
                isFetching: false,
                partnerCommitteesError: action.partnerCommitteesError
            });
        case committeesByMemberRequest:
            return Object.assign({}, state, {
                isFetching: true
            });
        case committeesByMemberSuccess:
            return Object.assign({}, state, {
                isFetching: false,
                committeesByMember: action.committeesByMember,
                errorMessage: ''
            });
        case committeesByMemberFailure:
            return Object.assign({}, state, {
                isFetching: false,
                errorMessage: action.message
            });
        case committeeListRequest:
            return Object.assign({}, state, {
                isFetching: true
            });
        case committeeListSuccess:
            return Object.assign({}, state, {
                isFetching: false,
                committeeList: action.committeeList,
                errorMessage: ''
            });
        case committeeListFailure:
            return Object.assign({}, state, {
                isFetching: false,
                errorMessage: action.message
            });
        case committeeByIdRequest:
            return Object.assign({}, state, {
                isFetching: true
            });
        case committeeByIdSuccess:
            return Object.assign({}, state, {
                isFetching: false,
                committeeByIdData: action.committeeByIdData,
                errorMessage: ''
            });
        case committeeByIdFailure:
            return Object.assign({}, state, {
                isFetching: false,
                errorMessage: action.message
            });
        case committeeByNumberRequest:
            return Object.assign({}, state, {
                isFetching: true
            });
        case committeeByNumberSuccess:
            return Object.assign({}, state, {
                isFetching: false,
                committeeByNumberData: action.committeeByNumberData,
                errorMessage: ''
            });
        case committeeByNumberFailure:
            return Object.assign({}, state, {
                isFetching: false,
                errorMessage: action.message
            });
        case committeePartnerByIdRequest:
            return Object.assign({}, state, {
                isFetching: true
            });
        case committeePartnerByIdSuccess:
            return Object.assign({}, state, {
                isFetching: false,
                partnerCommitteeByIdData: action.partnerCommitteeByIdData,
                errorMessage: ''
            });
        case committeePartnerByIdFailure:
            return Object.assign({}, state, {
                isFetching: false,
                errorMessage: action.message
            });
        case saveCommitteeRequest:
            return Object.assign({}, state, {
                isFetching: true
            });
        case saveCommitteeSuccess:
            return Object.assign({}, state, {
                isFetching: false,
                savedCommittee: action.savedCommittee,
                saveCommitteeError: ''
            });
        case saveCommitteeFailure:
            return Object.assign({}, state, {
                isFetching: false,
                saveCommitteeError: action.saveCommitteeError
            });
        case toggleChamber:
            return Object.assign({}, state, {
                committeeChamber: action.committeeChamber
            });
        default:
            return state;
    }
};
