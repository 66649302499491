import React from "react";
import Select from 'react-select';
import ReactTooltip from 'react-tooltip';
import renderHTML from 'react-render-html';
import { Link } from 'react-router-dom';

class BillsGrid extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            selectAllActive: false,
            selectAllInactive: false,
            newDescriptions: [],
            lastCheckedBills: [],
            textHeight: []
        };
        this.toggleSelectAll = this.toggleSelectAll.bind(this);
        this.toggleRow = this.toggleRow.bind(this);
        this.toggleBill = this.toggleBill.bind(this);
        this.toggleEditCategoryDescription = this.toggleEditCategoryDescription.bind(this);
        this.handleDescriptionChange = this.handleDescriptionChange.bind(this);
        this.toggleEdit = this.toggleEdit.bind(this);
        this.handleChange = this.handleChange.bind(this);
    }

    componentDidMount() {
        this.props.organizedComms.forEach((_, index) => {
            this.getCommunicationCategoryHeight(index)
        })
    }

    componentDidUpdate(prevProps) {
        if (this.props.organizedComms.map(c => c.CategoryCode) !== prevProps.organizedComms.map(c => c.CategoryCode)) {
            this.props.organizedComms.forEach((_, index) => {
                this.getCommunicationCategoryHeight(index)
            })
        }
    }

    toggleSelectAll(categoryIndex) {
        let organizedComms = [...this.props.organizedComms];
        const opposite = !organizedComms[categoryIndex].selectAll;
        organizedComms[categoryIndex].selectAll = opposite
        organizedComms[categoryIndex].CommunicationLegislation.forEach(leg => leg.checked = opposite);
        this.props.updateAgenda(organizedComms);

        let lastCheckedBills = this.state.lastCheckedBills;
        lastCheckedBills[categoryIndex] = -1;
        this.setState({
            lastCheckedBills: lastCheckedBills
        });
    }

    toggleRow(categoryIndex, itemIndex, e) {
        let organizedComms = [...this.props.organizedComms];
        let lastCheckedBills = this.state.lastCheckedBills;
        let lastCheckedBill = lastCheckedBills[categoryIndex];
        if (e.nativeEvent.shiftKey && !isNaN(lastCheckedBill) && lastCheckedBill !== -1) {
            organizedComms[categoryIndex].CommunicationLegislation.forEach(item => item.checked = false);
            for (let i = 0; i < Math.abs(itemIndex - lastCheckedBill); i++) {
                const index = lastCheckedBill + i * (itemIndex - lastCheckedBill) / Math.abs(itemIndex - lastCheckedBill);
                organizedComms[categoryIndex].CommunicationLegislation[index].checked = true;
            }
        } else {
            lastCheckedBill = itemIndex;
        }
        organizedComms[categoryIndex].CommunicationLegislation[itemIndex].checked = !organizedComms[categoryIndex].CommunicationLegislation[itemIndex].checked;
        this.props.updateAgenda(organizedComms);

        lastCheckedBills[categoryIndex] = lastCheckedBill;
        this.setState({
            lastCheckedBills: lastCheckedBills
        });
    }

    toggleBill(categoryIndex, itemIndex) {
        let organizedComms = [...this.props.organizedComms];
        organizedComms[categoryIndex].CommunicationLegislation[itemIndex].IsActive = !organizedComms[categoryIndex].CommunicationLegislation[itemIndex].IsActive;
        organizedComms[categoryIndex].CommunicationLegislation[itemIndex].checked = false;
        this.props.updateAgenda(organizedComms, true);
    }

    toggleBillBulk(activate, categoryIndex) {
        let organizedComms = [...this.props.organizedComms];
        organizedComms[categoryIndex].selectAll = false;
        organizedComms[categoryIndex].CommunicationLegislation.forEach(leg => {
            if (leg.checked) {
                leg.IsActive = activate;
                leg.checked = false;
            }
        });
        this.props.updateAgenda(organizedComms, true);
    }

    toggleEditCategoryDescription(categoryIndex, save) {
        const categories = [...this.props.organizedComms];
        let category = categories.filter(c => !c.DeletionDate)[categoryIndex];
        category.editing = !category.editing;
        if (save) {
            category.Description = category.tempDescription;
        } else {
            category.tempDescription = category.Description || (category.CommunicationLegislation.filter(leg => leg.IsActive).length > 1 ? category.PluralDescription : category.CategoryDescription);
        }
        this.props.updateAgenda(categories, save);
    }

    handleDescriptionChange(categoryIndex, val) {
        let organizedComms = [...this.props.organizedComms];
        organizedComms[categoryIndex].tempDescription = val;
        this.props.updateAgenda(organizedComms);
    }

    getCommunicationCategoryHeight(category) {
        const el = document.getElementsByClassName("category-description-" + category);
        if (el && el.length) {
            //setting the overflow to scroll is used to accommodate firefox 
            //from firefox documentation: "When an element's content does not generate a vertical scrollbar, then its scrollHeight property is equal to its clientHeight property."
            const initialOverflow = el[0].style.overflow;
            el[0].style.overflow = "scroll";
            el[0].style.height = el[0].scrollHeight + "px";
            el[0].style.overflow = initialOverflow;
        }
    }

    toggleEdit(categoryIndex, agendaIndex) {
        let categories = [...this.props.organizedComms];
        //This key is used to tell the DOM whether to display an input or a span
        categories.filter(c => !c.DeletionDate)[categoryIndex].CommunicationLegislation[agendaIndex].isEditing = !categories.filter(c => !c.DeletionDate)[categoryIndex].CommunicationLegislation[agendaIndex].isEditing;
        //Create a key called newDraftTitle that will hold the text that  the user types in
        categories.filter(c => !c.DeletionDate)[categoryIndex].CommunicationLegislation[agendaIndex].newDraftTitle = categories.filter(c => !c.DeletionDate)[categoryIndex].CommunicationLegislation[agendaIndex].DraftTitle;
        this.props.updateAgenda(categories);
    }

    handleChange(categoryIndex, agendaIndex, e) {
        let textHeight = [...this.state.textHeight];

        //Init the nested height value if undefined at the category or agenda index
        if (!textHeight[categoryIndex]) {
            textHeight[categoryIndex] = {};
            textHeight[categoryIndex].CommunicationLegislation = [];
            textHeight[categoryIndex].CommunicationLegislation[agendaIndex] = {};
            textHeight[categoryIndex].CommunicationLegislation[agendaIndex].height = '70px';
        } else if (!textHeight[categoryIndex].CommunicationLegislation[agendaIndex]) {
            textHeight[categoryIndex].CommunicationLegislation[agendaIndex] = {};
            textHeight[categoryIndex].CommunicationLegislation[agendaIndex].height = '70px';
        }

        let height = (Math.round(e.target.value.length / 95) * 20 + 40) || 70;

        textHeight[categoryIndex].CommunicationLegislation[agendaIndex].height = height.toString() + "px";

        let categories = [...this.props.organizedComms];

        categories.filter(c => !c.DeletionDate)[categoryIndex].CommunicationLegislation[agendaIndex].newDraftTitle = e.target.value;

        this.props.updateAgenda(categories);

        this.setState({
            textHeight: textHeight
        })
    }

    saveChange(categoryIndex, agendaIndex) {
        //Turn input back into span
        let categories = [...this.props.organizedComms];
        categories.filter(c => !c.DeletionDate)[categoryIndex].CommunicationLegislation[agendaIndex].isEditing = false;
        //Set description to the text that the user has typed in. If new description is null then set it to the current description
        categories.filter(c => !c.DeletionDate)[categoryIndex].CommunicationLegislation[agendaIndex].DraftTitle = categories.filter(c => !c.DeletionDate)[categoryIndex].CommunicationLegislation[agendaIndex].newDraftTitle;
        this.props.updateAgenda(categories, true);
    }

    render() {
        // The button will read 'Add' if the bill is not already part of a category. The button will read 'Move' if it is already part of a category.
        const billButtonText = (index) => {
            let billExists = false;
            if (this.props.selectedBill[index]) {
                this.props.organizedComms.some(category => {
                    if (category.CommunicationLegislation.findIndex(bill => bill.LegislationID === this.props.selectedBill[index].LegislationID) >= 0) {
                        billExists = true;
                        return true;
                    }
                });
                if (billExists) {
                    return ("Move");
                }
            }
            return ("Add");
        }

        const customFilter = (option, searchText) => {
            // regex that matches all zeros and white space that is preceded by two letters (e.g. SC) and followed by a group of numbers.
            // The purpose is to remove all leading zeros and white space from the user types in and the option label
            try {
                const regexp = new RegExp("(?<=[a-z]{2})[0\s]+(?=[1-9][0-9]*)", "ig")
                const newLabel = option.label.toLowerCase().replace(regexp, "");
                const newSearchText = searchText.toLowerCase().replace(regexp, "");
                if (option.label.toLowerCase().includes(searchText.toLowerCase()) || newLabel.includes(newSearchText)) {
                    return true;
                }
            }
            catch (e) {
                // Safari does not allow lookback regex (i.e. the '?<=' above), so we must handle it differently
                // however, the below code will remove trailing zeros (i.e. searching 'HC11' will filter both 'HC0011' and 'HC1001'), so we want to at least try the above code first
                if (e instanceof SyntaxError) {
                    const regexp = new RegExp("(?![a-z]{2})[0\s]+(?=[1-9][0-9]*)", "ig")
                    const newLabel = option.label.toLowerCase().replace(regexp, "");
                    const newSearchText = searchText.toLowerCase().replace(regexp, "");
                    if (option.label.toLowerCase().includes(searchText.toLowerCase()) || newLabel.includes(newSearchText)) {
                        return true;
                    }
                } else {
                    console.log(e.message)
                }
            }
        };

        const removeLDNum = (draftTitle) => draftTitle.replace(/<p class=ldrt>\d+D<\/p>/, "");

        return (
            <div className="drag-container">
                <div className="align-right grid-wrapper eight-and-one" style={{ alignItems: 'end' }}>
                    <Select
                        className="grid-selector-dropdown inline-dropdown"
                        classNamePrefix="grid-selector-dropdown"
                        options={this.props.communicationCategories}
                        value={this.props.selectedCategory}
                        onChange={this.props.changeCategoryDropdown}
                        styles={{ menu: provided => ({ ...provided, whiteSpace: 'nowrap', right: '0', zIndex: 3 }), option: provided => ({ ...provided, whiteSpace: 'pre-wrap', overflow: 'hidden', textOverflow: 'ellipsis', borderBottom: '1px solid #DDD' }) }}
                        getOptionLabel={(option) => option.CategoryCode + ": " + option.label}
                        filterOption={customFilter}
                    />
                    <button type="button" className="button m-left" disabled={!this.props.selectedCategory} onClick={this.props.addCategory}>Add Category</button>
                </div>
                <div style={{ marginTop: 15 }}>
                    <div className="bill-grid communications-category-grid">
                        <div className="bill-grid-header">
                            <span></span>
                        </div>
                        <div className="bill-grid-header">
                            <span>Bill #</span>
                        </div>
                        <div className="bill-grid-header">
                            <span>Document Label</span>
                        </div>
                        <div className="bill-grid-header">
                            <span>Patron</span>
                        </div>
                        <div className="bill-grid-header">
                            <span>Draft Title</span>
                        </div>
                        <div className="bill-grid-header">
                            <span>Status</span>
                        </div>
                        <div className="bill-grid-header">
                            <span>Action</span>
                        </div>
                    </div>
                    <div>
                        {this.props.organizedComms.sort((a, b) => a.Sequence - b.Sequence).map((category, categoryIndex) =>
                            <div>
                                <form key={categoryIndex}>
                                    <div className="bill-grid communications-category-grid communications-category-grid-header">
                                        <div>
                                            <button onClick={() => this.props.toggleHideCategory(categoryIndex)} type="button" className={`${this.props.hideCategories[categoryIndex] ? "arrow-down" : "arrow-up"}`}></button>
                                            <input
                                                type="checkbox"
                                                className="checkbox"
                                                checked={category.selectAll || ''}
                                                onChange={() => this.toggleSelectAll(categoryIndex)}
                                            />
                                        </div>
                                        <div>
                                            <span>
                                                {category.editing ?
                                                    <React.Fragment>
                                                        <textarea
                                                            className={`category-description-${categoryIndex}`}
                                                            style={{ width: '90%', fontSize: '0.9em' }}
                                                            type="text"
                                                            onChange={(e) => this.handleDescriptionChange(categoryIndex, e.target.value)}
                                                            value={category.tempDescription}
                                                        />
                                                        <a className="icon save" style={{ marginLeft: '7px' }} onClick={() => this.toggleEditCategoryDescription(categoryIndex, true)} />
                                                        <a className="icon delete" style={{ marginLeft: '7px' }} onClick={() => this.toggleEditCategoryDescription(categoryIndex)} />
                                                    </React.Fragment>
                                                    :
                                                    <React.Fragment>
                                                        <b>{category.CategoryCode + ": " + (category.Description ? category.Description : category.CommunicationLegislation.filter(leg => leg.IsActive).length > 1 ? category.PluralDescription : category.CategoryDescription)}</b>
                                                        <a className="icon edit" style={{ marginLeft: '7px' }} onClick={() => this.toggleEditCategoryDescription(categoryIndex)} />
                                                    </React.Fragment>
                                                }
                                            </span>
                                        </div>
                                        <div>
                                            <button type="button" className="button small-button" onClick={() => this.toggleBillBulk(false, categoryIndex)}>Exclude Selected</button>
                                        </div>
                                        <div>
                                            <button type="button" className="button small-button" onClick={() => this.toggleBillBulk(true, categoryIndex)}>Include Selected</button>
                                        </div>
                                        <div />
                                        <div />
                                    </div>
                                    {!this.props.hideCategories[categoryIndex] && <div className="bill-grid communications-category-grid">
                                        <div></div>
                                        <div>
                                            <Select
                                                className="grid-selector-dropdown"
                                                classNamePrefix="grid-selector-dropdown"
                                                options={this.props.billList}
                                                onChange={(value) => this.props.changeBillDropdown(categoryIndex, value)}
                                                value={this.props.selectedBill[categoryIndex]}
                                                styles={{ menu: provided => ({ ...provided, zIndex: 3 }) }}
                                                filterOption={(bill, inputValue) => {
                                                    if (category.CommunicationLegislation.findIndex(agenda => agenda.LegislationID === bill.value) < 0) {
                                                        return bill.label.toLowerCase().includes(inputValue.toLowerCase());
                                                    } else { return false; }
                                                }}
                                            />
                                        </div>
                                        <div className="center">
                                            <button
                                                className="button"
                                                type="button"
                                                disabled={!this.props.selectedBill[categoryIndex]}
                                                onClick={() => this.props.addBill(categoryIndex)}>
                                                {billButtonText(categoryIndex)}
                                            </button>
                                        </div>
                                        <div></div>
                                        <div></div>
                                        <div></div>
                                        <div></div>
                                    </div>
                                    }
                                    {
                                        !this.props.hideCategories[categoryIndex] && category.CommunicationLegislation.sort((a, b) => a.LegislationNumber.localeCompare(b.LegislationNumber, 'en', { numeric: true })).map((item, itemIndex) => {
                                            const showNewIndicator = !item.CommunicationLegislationID && category.CommunicationCategoryID;
                                            const existsInMultipleCategories = this.props.organizedComms.find(c => c.CommunicationCategoryTypeID !== category.CommunicationCategoryTypeID && c.CommunicationLegislation.find(a => a.LegislationNumber === item.LegislationNumber));
                                            return (
                                                <div style={!item.IsActive ? { color: '#8b8b8b' } : {}} className="bill-grid communications-category-grid" key={itemIndex}>
                                                    <React.Fragment>
                                                        <div>
                                                            <input
                                                                type="checkbox"
                                                                className="checkbox"
                                                                checked={item.checked || ''}
                                                                onChange={e => this.toggleRow(categoryIndex, itemIndex, e)} />
                                                        </div>
                                                        <div className="new-container">
                                                            {existsInMultipleCategories &&
                                                                <React.Fragment>
                                                                    <i data-tip="This legislation exists in multiple categories." className={`icon notification communication-authoring${showNewIndicator ? ' new' : ''}`} />
                                                                    <ReactTooltip key={new Date()} />
                                                                </React.Fragment>
                                                            }
                                                            <Link to={`/bill-details/${this.props.sessionCode}/${item.LegislationNumber}`} target="_blank" rel="noreferrer" style={!item.IsActive ? { color: 'inherit' } : {}}>{item.LegislationNumber}</Link>
                                                            {showNewIndicator && <span className='corner-new'>NEW</span>}
                                                            {!item.IsCandidate && <i data-tip="This legislation is not currently a candidate for this category." className={`icon notification non-candidate communication-authoring${showNewIndicator ? ' new' : ''}${existsInMultipleCategories ? ' duplicate' : ''}`} />}
                                                            <ReactTooltip />
                                                        </div>
                                                        <div>
                                                            <span>{item.DocumentCode}</span>
                                                        </div>
                                                        <div>
                                                            <span>{item.Patrons && item.Patrons[0] && item.Patrons[0].PatronDisplayName}</span>
                                                        </div>
                                                        <div>
                                                            <div className="flex-row" style={{ display: 'flex' }}>
                                                                <div className="flex-row flex-vertical-center" style={{ width: "100%", display: "inline-flex" }}>
                                                                    {item.isEditing ?
                                                                        <textarea value={item.newDraftTitle} style={{
                                                                            resize: 'vertical', width: '100%', height: this.state.textHeight[categoryIndex]
                                                                                && this.state.textHeight[categoryIndex].CommunicationLegislation[itemIndex]
                                                                                && this.state.textHeight[categoryIndex].CommunicationLegislation[itemIndex].height
                                                                                ? this.state.textHeight[categoryIndex].CommunicationLegislation[itemIndex].height
                                                                                : "70px"
                                                                        }}
                                                                            cols="2" onChange={(e) => this.handleChange(categoryIndex, itemIndex, e)}></textarea>
                                                                        :
                                                                        <span>{renderHTML(item.Description ? item.Description : item.DraftTitle ? removeLDNum(item.DraftTitle) : "")}</span>}
                                                                </div>
                                                                {(item.isEditing || (!item.Description && item.DraftTitle)) &&
                                                                    <React.Fragment>
                                                                        <div className="bill-grid-actions">
                                                                            {item.isEditing
                                                                                ?
                                                                                <div className="bill-grid-actions" style={{ display: "inline-flex" }}>
                                                                                    <button onClick={() => this.saveChange(categoryIndex, itemIndex)} type="button" className="icon save float-right"></button>
                                                                                    <button onClick={() => this.toggleEdit(categoryIndex, itemIndex)} type="button" className="icon delete float-right"></button>
                                                                                </div>
                                                                                :
                                                                                <div className="bill-grid-actions">
                                                                                    {/*Allow edit if the item IsActive*/}
                                                                                    {item.IsActive &&
                                                                                        <button onClick={() => this.toggleEdit(categoryIndex, itemIndex)} type="button" className="icon edit float-right"></button>
                                                                                    }
                                                                                </div>
                                                                            }
                                                                        </div>
                                                                    </React.Fragment>
                                                                }
                                                            </div>
                                                        </div>
                                                        <div>
                                                            <span className={item.IsActive ? 'message-success' : ''}>{item.IsActive ? 'Included' : 'Not Included'}</span>
                                                        </div>
                                                        <div className="communication-bill-grid-actions">
                                                            <div className="toggle-switch" onClick={() => this.toggleBill(categoryIndex, itemIndex)}>
                                                                <input id="sr-status-change" checked={item.IsActive} type="checkbox" />
                                                                <span className="slider"></span>
                                                            </div>
                                                        </div>
                                                    </React.Fragment>
                                                </div>
                                            )
                                        }
                                        )
                                    }
                                </form>
                            </div>
                        )}
                    </div>
                </div>
            </div >
        );
    }
}

export default BillsGrid;