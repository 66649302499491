import React from "react";
import { useMsal } from "@azure/msal-react";

const graphToken = 'GRAPH_ID_TOKEN';
const idToken = 'LIS_ID_TOKEN';
const refreshToken = 'LIS_REFRESH_TOKEN';
const refreshTokenExpiration = 'LIS_REFRESH_TOKEN_EXPIRATION';
const claims = "LIS_CLAIMS";

const HouseLogoutButton = (props) => {
    const { instance } = useMsal();

    const handleLogout = (logoutType) => {
        if (logoutType === "redirect") {
            localStorage.removeItem(idToken);
            localStorage.removeItem(refreshToken);
            localStorage.removeItem(refreshTokenExpiration);
            localStorage.removeItem(claims);
            localStorage.removeItem(graphToken);
            instance.logoutRedirect()
                .then(() => {
                    props.logout();
                })
        }
    }

    return (
        <button type="button" className="button profile-action danger" onClick={() => handleLogout("redirect")}>Logout</button>
    );
}

export default HouseLogoutButton