import React from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import {reportActionCreators} from '../../../stores/lis-report-store';
import { navActionCreators } from '../../../stores/lis-nav-store';

class ShareReport extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            emailAddresses: '',
            isSharing: false
        };
        this.handleEmailAddressChange = this.handleEmailAddressChange.bind(this);
        this.sendReport = this.sendReport.bind(this);
    }

    
    handleEmailAddressChange(e) {
        this.setState({
            emailAddresses: e.target.value
        });
    }

    sendReport() {
        this.setState({
            isSharing: true
        });
        const url = encodeURIComponent(window.location.href);
        const emailAddresses = encodeURIComponent(this.state.emailAddresses);
        this.props.actions.shareReport(`?link=${url}&addresses=${emailAddresses}`)
        .then(() => {
            this.props.actions.makeToast([{ message: "Share Successful", type: "success" }]);
            this.setState({
                isSharing: false,
                emailAddresses: ''
            })
        }).catch(err => {
            if(err === "Aborted") {
                return
            }
            this.setState({
                isSharing: false
            })
            this.props.actions.makeToast([{ message: "Share Failed", type: "failure" }]);
        })
    }

    render() {
        return(<div onClick={!this.state.isSharing ? this.props.toggleShareForm : null} className="overlay center-content">
            <div className="full">
            <div onClick={e => e.stopPropagation()} className="dlas-forms user-forms advanced-search overlay-content animated-fade-in-up">
                <div>
                    <label htmlFor="email-addresses-to-send-to" className="label">Share link to report. (Seperate email addresses by a comma)</label>
                    <div className="inline-list">
                        <input id="email-addresses-to-send-to" aria-label="Email addresses to send to" style={{width: "90%"}}onChange={this.handleEmailAddressChange} value={this.state.emailAddresses} placeholder="Email addresses to send to"/>
                    </div>
                </div>
                <br />
                <hr className="faded-line" />
                <div className="align-right inline-list">
                    {this.state.isSharing &&
                        <div className="small-spinner"></div>
                    }
                    <button type="button" disabled={this.state.isSharing} className="button secondary" onClick={this.props.toggleShareForm}>Close</button>
                    <button type="button" disabled={this.state.isSharing || !this.state.emailAddresses} className="button" onClick={this.sendReport}>Share</button>
                </div>
            </div>
            </div>
        </div>);
    }
}

export default connect(
    (state) => {
        const { report, nav } = state;
        return {
            report,
            nav
            
        }
    },
    (dispatch) => {
        return {
            actions: bindActionCreators(Object.assign({}, reportActionCreators, navActionCreators), dispatch)
        }
    }
)(ShareReport);