import React from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { billActionCreators } from '../../stores/lis-legislation-store';
import { sessionActionCreators } from '../../stores/lis-session-store';
import { voteActionCreators } from '../../stores/lis-votes-store';
import VoteDetails from '../../lis-shared/lis-votes/lis-vote-details';

import LoaderComponent from '../../lis-shared/lis-layout/components/lis-loader-component';
import { cancelRequest } from '../../services/request.service';

class VoteDetailsComponent extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            voteData: []
        };
    }

    componentDidMount() {
        const legislationNumber = this.props.match.params.billnumber;
        const voteNumber = this.props.match.params.votenumber;
        const sessionCode = this.props.match.params.sessioncode;
        const params = '?legislationNumber=' + legislationNumber + '&sessionCode=' + sessionCode + '&voteNumber=' + voteNumber;

        // GET VOTE DETAILS DATA
        this.props.actions.getVoteDetails(params).then(() => {
            this.setState({ voteDataIsLoaded: true });
            if (this.props.votes.voteDetailsError) {
                throw this.props.votes.voteDetailsError;
            }
            if (this.props.votes.voteDetailsResponse.length === 0) {
                throw "Cannot get vote data";
            }
            const pdfFile = this.props.votes.voteDetailsResponse[0].VoteFiles.find(file => file.FileURL.toUpperCase().includes(".PDF"));
            this.setState({
                voteData: this.props.votes.voteDetailsResponse[0],
                voteFileUrl: pdfFile || ''
            });
            this.props.actions.getSessionById(this.props.votes.voteDetailsResponse[0].SessionID)
                .then(() => this.setState({ isLoaded: true }));
        }).catch(err => {
            if (err === 'Aborted') {
                return;
            }
            console.log(err.toString());
            this.setState({
                isLoaded: true,
                message: err.toString()
            });
        });
    }

    componentWillUnmount() {
        cancelRequest();
    }

    render() {
        const { voteData } = this.state;
        if (this.state.message) {
            return (<p>{this.state.message}</p>);
        }
        if (!this.state.isLoaded) {
            return (
                <div><LoaderComponent className="center-spinner" data={false} /></div>
            )
        }
        return (
            <div className="bill-theme public-details inner-grid">
                <div>
                    <div>
                        <VoteDetails
                            sessionData={this.props.session.selectedSession}
                            billData={voteData.VoteLegislation ? voteData.VoteLegislation.filter(vl => !vl.DeletionDate) : []}
                            voteMembers={voteData.VoteMember || []}
                            voteData={voteData}
                            voteFileUrl={this.state.voteFileUrl}
                            componentRef={this.componentRef}
                            isPublic
                        />
                    </div>
                </div>
            </div>
        )
    }
}

const PublicVoteDetails = connect(
    (state) => {
        const { bills, session, votes } = state;
        return {
            bills,
            session,
            votes
        }
    },
    (dispatch) => {
        return {
            actions: bindActionCreators(Object.assign({}, voteActionCreators, billActionCreators, sessionActionCreators), dispatch)
        }
    }
)(VoteDetailsComponent)

export default PublicVoteDetails;
