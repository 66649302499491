import React from 'react';
import { Link } from 'react-router-dom';
import { Prompt } from 'react-router';
import * as Fields from '../../../lis-shared/lis-layout/components/lis-forms-component';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import moment from 'moment';
import ReactToPrint from "react-to-print";
import { calendarActionCreators } from '../../../stores/lis-calendar-store';
import BillsGrid from './lis-house-bills-grid';
import CalendarDetailsComponent from '../../../lis-shared/lis-calendar/lis-calendar-details'
import { sessionActionCreators } from '../../../stores/lis-session-store';
import { navActionCreators } from '../../../stores/lis-nav-store';
import { billActionCreators } from '../../../stores/lis-legislation-store';
import { cancelRequest } from '../../../services/request.service';
import Popout from '../../../lis-shared/lis-layout/components/lis-popout-component';
import DatePicker from 'react-datepicker';
import CalendarDetailsPrintout from '../../../lis-shared/lis-calendar/lis-calendar-details-printout';
import { committeeActionCreators } from '../../../stores/lis-committee-store';

const PUBLISH_CALENDAR = true;
const SAVE_CALENDAR = false;
const lisDocDomain = window.env?.STORAGE_ACCOUNT_DOMAIN;

const NUMERIC_SORT_CATEGORIES = [2, 3, 4, 26, 27, 5, 41, 6, 7, 28, 29, 30, 31, 32, 57, 33, 35, 45, 9, 10, 8, 11, 13, 37, 38, 44, 46];

const sortAgendas = (a, b, calendarChamber) => {
    return !a.IsActive && b.IsActive
        ? 1
        : a.IsActive && !b.IsActive
            ? -1
            : (!a.Ranking || a.Ranking === "") && (b.Ranking && b.Ranking !== "")
                ? -1
                : (a.Ranking && a.Ranking !== "") && (!b.Ranking || b.Ranking === "")
                    ? 1
                    : a.Ranking > b.Ranking
                        ? 1
                        : a.Ranking < b.Ranking
                            ? -1
                            : moment(a.CalendarDate).isAfter(b.CalendarDate, "day")
                                ? 1
                                : moment(b.CalendarDate).isAfter(a.CalendarDate, "day")
                                    ? -1
                                    : moment(a.CandidateDate).isAfter(b.CandidateDate, "day")
                                        ? 1
                                        : moment(b.CandidateDate).isAfter(a.CandidateDate, "day")
                                            ? -1
                                            : a.LegislationNumber && a.LegislationNumber[0].localeCompare(b.LegislationNumber[0]) !== 0 ?
                                                a.LegislationNumber[0].localeCompare(calendarChamber) !== 0
                                                    ? -1 : 1
                                                : a.LegislationNumber && a.LegislationNumber[1].localeCompare(b.LegislationNumber[1]) > 0
                                                    ? 1
                                                    : a.LegislationNumber && a.LegislationNumber[1].localeCompare(b.LegislationNumber[1]) < 0
                                                        ? -1
                                                        : a.LegislationKey > b.LegislationKey
                                                            ? 1
                                                            : -1
}

const sortAgendasNumerically = (a, b, calendarChamber) => {
    return !a.IsActive && b.IsActive
        ? 1
        : a.IsActive && !b.IsActive
            ? -1
            : (!a.Ranking || a.Ranking === "") && (b.Ranking && b.Ranking !== "")
                ? -1
                : (a.Ranking && a.Ranking !== "") && (!b.Ranking || b.Ranking === "")
                    ? 1
                    : a.Ranking > b.Ranking
                        ? 1
                        : a.Ranking < b.Ranking
                            ? -1
                            : a.LegislationNumber && a.LegislationNumber[0].localeCompare(b.LegislationNumber[0]) !== 0 ?
                                a.LegislationNumber[0].localeCompare(calendarChamber) !== 0
                                    ? -1 : 1
                                : a.LegislationNumber && a.LegislationNumber[1].localeCompare(b.LegislationNumber[1]) > 0
                                    ? 1
                                    : a.LegislationNumber && a.LegislationNumber[1].localeCompare(b.LegislationNumber[1]) < 0
                                        ? -1
                                        : a.LegislationKey > b.LegislationKey
                                            ? 1
                                            : -1
}

const sortInactiveAgendas = (a, b, calendarChamber) => {
    return (!a.Ranking || a.Ranking === "") && (b.Ranking && b.Ranking !== "")
        ? -1
        : (a.Ranking && a.Ranking !== "") && (!b.Ranking || b.Ranking === "")
            ? 1
            : a.Ranking > b.Ranking
                ? 1
                : a.Ranking < b.Ranking
                    ? -1
                    : moment(a.CalendarDate).isAfter(b.CalendarDate, "day")
                        ? 1
                        : moment(b.CalendarDate).isAfter(a.CalendarDate, "day")
                            ? -1
                            : moment(a.CandidateDate).isAfter(b.CandidateDate, "day")
                                ? 1
                                : moment(b.CandidateDate).isAfter(a.CandidateDate, "day")
                                    ? -1
                                    : a.LegislationNumber && a.LegislationNumber[0].localeCompare(b.LegislationNumber[0]) !== 0 ?
                                        a.LegislationNumber[0].localeCompare(calendarChamber) !== 0
                                            ? -1 : 1
                                        : a.LegislationNumber && a.LegislationNumber[1].localeCompare(b.LegislationNumber[1]) > 0
                                            ? 1
                                            : a.LegislationNumber && a.LegislationNumber[1].localeCompare(b.LegislationNumber[1]) < 0
                                                ? -1
                                                : a.LegislationKey > b.LegislationKey
                                                    ? 1
                                                    : -1
}

const sortInactiveAgendasNumerically = (a, b, calendarChamber) => {
    return (!a.Ranking || a.Ranking === "") && (b.Ranking && b.Ranking !== "")
        ? -1
        : (a.Ranking && a.Ranking !== "") && (!b.Ranking || b.Ranking === "")
            ? 1
            : a.Ranking > b.Ranking
                ? 1
                : a.Ranking < b.Ranking
                    ? -1
                    : a.LegislationNumber && a.LegislationNumber[0].localeCompare(b.LegislationNumber[0]) !== 0 ?
                        a.LegislationNumber[0].localeCompare(calendarChamber) !== 0
                            ? -1 : 1
                        : a.LegislationNumber && a.LegislationNumber[1].localeCompare(b.LegislationNumber[1]) > 0
                            ? 1
                            : a.LegislationNumber && a.LegislationNumber[1].localeCompare(b.LegislationNumber[1]) < 0
                                ? -1
                                : a.LegislationKey > b.LegislationKey
                                    ? 1
                                    : -1
}

const findLastByIsActiveThenCalendarDateThenCandidateDateThenLegChamberTypeKey = (a, b, calendarChamber, ignoreCalendarDate, ignoreCandidateDate) => (
    a.IsActive && findLastByCalendarDateThenCandidateDateThenLegChamberTypeKey(a, b, calendarChamber, ignoreCalendarDate, ignoreCandidateDate)
)

const findLastByCalendarDateThenCandidateDateThenLegChamberTypeKey = (a, b, calendarChamber, ignoreCalendarDate, ignoreCandidateDate) => (
    (((ignoreCalendarDate || (a.CalendarDate && b.CalendarDate && moment(a.CalendarDate).isSame(b.CalendarDate, "day"))) && findLastByLegChamberTypeKey(a, b, calendarChamber)) ||
        (!ignoreCalendarDate && a.CalendarDate && b.CalendarDate && moment(a.CalendarDate).isBefore(b.CalendarDate, "day"))) ||
    (((ignoreCandidateDate || (a.CandidateDate && b.CandidateDate && moment(a.CandidateDate).isSame(b.CandidateDate, "day"))) && findLastByLegChamberTypeKey(a, b, calendarChamber)) ||
        (!ignoreCandidateDate && a.CandidateDate && b.CandidateDate && moment(a.CandidateDate).isBefore(b.CandidateDate, "day"))) ||
    ((ignoreCalendarDate || (!a.CalendarDate && !b.CalendarDate)) && (ignoreCandidateDate || (!a.CandidateDate && !b.CandidateDate)) &&
        findLastByLegChamberTypeKey(a, b, calendarChamber))
    || (!ignoreCalendarDate && !b.CalendarDate && a.CandidateDate)
    || !ignoreCandidateDate && !b.CandidateDate && a.CandidateDate
)

const findLastByLegChamberTypeKey = (a, b, calendarChamber) => (
    (a.LegislationNumber && a.LegislationNumber[0].localeCompare(b.LegislationNumber[0]) === (calendarChamber === "H" ? 1 : -1)) ||
    (a.LegislationNumber && a.LegislationNumber && a.LegislationNumber[0].localeCompare(b.LegislationNumber[0]) === 0 && a.LegislationNumber[1].localeCompare(b.LegislationNumber[1]) === -1) ||
    (a.LegislationNumber && a.LegislationNumber[0].localeCompare(b.LegislationNumber[0]) === 0 && a.LegislationNumber[1].localeCompare(b.LegislationNumber[1]) === 0 && a.LegislationKey && a.LegislationKey < parseInt(b.LegislationKey))
)

class HouseCalendarFormComponent extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            isLoaded: false,
            calendarData: '',
            hideCategories: [],
            showPreview: false,
            isSaving: false,
            pendingSave: false,
            comments: [],
            message: '',
            selectedSession: '',
            selectAllActive: false,
            selectAllInactive: false,
            calendarCategories: [],
            selectedCategory: '',
            selectedBill: [],
            billList: [],
            showCategoryPreview: -1,
            selectedFile: '',
            calendatDate: "",
            commentEdit: [],
            tempComments: [],
            showTitles: false,
            selectedDate: "",
            selectedCommittee: "",
            dateList: [],
            committeeList: [],
            hideAllNonIncludedBills: false
        };

        this.initializeForm = this.initializeForm.bind(this);
        this.handleAgendaSort = this.handleAgendaSort.bind(this);
        this.handleBulkAgendaSort = this.handleBulkAgendaSort.bind(this);
        this.togglePreview = this.togglePreview.bind(this);
        this.updateAgenda = this.updateAgenda.bind(this);
        this.updateAgendaItems = this.updateAgendaItems.bind(this);
        this.changeCategoryDropdown = this.changeCategoryDropdown.bind(this);
        this.addCategory = this.addCategory.bind(this);
        this.toggleHideCategory = this.toggleHideCategory.bind(this);
        this.toggleHideAllCategories = this.toggleHideAllCategories.bind(this);
        this.toggleHideAllNonIncludedBills = this.toggleHideAllNonIncludedBills.bind(this);
        this.changeBillDropdown = this.changeBillDropdown.bind(this);
        this.changeDateDropdown = this.changeDateDropdown.bind(this);
        this.changeCommitteeDropdown = this.changeCommitteeDropdown.bind(this);
        this.addBill = this.addBill.bind(this);
        this.addComment = this.addComment.bind(this);
        this.removeComment = this.removeComment.bind(this);
        this.commentShift = this.commentShift.bind(this);
        this.handleProFormaChange = this.handleProFormaChange.bind(this);
        this.toggleCalendarPreview = this.toggleCalendarPreview.bind(this);
        this.togglePortal = this.togglePortal.bind(this);
        this.saveCalendar = this.saveCalendar.bind(this);
        this.handlePdfFile = this.handlePdfFile.bind(this);
        this.submitPdfFile = this.submitPdfFile.bind(this);
        this.changeConveneTime = this.changeConveneTime.bind(this);
        this.toggleAgenda = this.toggleAgenda.bind(this);
        this.toggleCommentEdit = this.toggleCommentEdit.bind(this);
        this.confirmCommentEdit = this.confirmCommentEdit.bind(this);
        this.toggleShowTitles = this.toggleShowTitles.bind(this);
        this.setPending = this.setPending.bind(this);
        this.deleteCategory = this.deleteCategory.bind(this);

        this.pdfRef = React.createRef();
        this.fileInput = React.createRef();
    }

    componentDidMount() {
        this.initializeForm();
    }

    initializeForm() {
        let calendarData = { ...this.props.calendarData };
        let noSavedItems = true;
        let comments = [];
        let dateList = [];
        let committeeList = [];

        // For the 'add category' dropdown
        this.props.actions.getCalendarCategoriesReferences('?chamberCode=' + calendarData.ChamberCode)
            .then(() => {
                let calendarCategories = this.props.calendar.calendarCategoriesReferences;
                calendarCategories.forEach(category => {
                    category.label = category.DisplayType ? `${category.Description} - ${category.CategoryType}` : category.Description;
                    category.value = category.CalendarCategoryTypeID;
                });
                this.setState({
                    calendarCategories: calendarCategories
                }, () => {
                    this.props.actions.getCommitteeList("sessionID=" + calendarData.SessionID)
                        .then(() => {
                            calendarData.CalendarCategories.forEach((category, j) => {
                                const referenceCategory = this.state.calendarCategories.find(c => c.CalendarCategoryTypeID === category.CalendarCategoryTypeID);
                                const response = this.handleAgendaSort(category.Agendas, category.CategoryType, category.CalendarCategoryTypeID, calendarData.ChamberCode)
                                category.Agendas = response;
                                category.Agendas.forEach((agenda, i) => {
                                    //If a bill is on first reading capture its committee and candidate date to use in the filters
                                    if (referenceCategory && referenceCategory.OnDemand) {
                                        if (agenda.CommitteeID && this.props.committee.committeeList.find(x => x.ChamberCode === calendarData.ChamberCode && x.CommitteeID === agenda.CommitteeID)) {
                                            committeeList.push({ label: this.props.committee.committeeList.find(x => x.ChamberCode === calendarData.ChamberCode && x.CommitteeID === agenda.CommitteeID).Name, value: agenda.CommitteeID, date: agenda.CandidateDate });
                                        }

                                        if (agenda.CandidateDate && this.props.committee.committeeList.find(x => x.ChamberCode === calendarData.ChamberCode && x.CommitteeID === agenda.CommitteeID)) {
                                            let thisDate = dateList.findIndex(x => moment(x.value).isSame(agenda.CandidateDate, "day"));
                                            if (thisDate > -1) {
                                                if (!dateList[thisDate].committee.includes(agenda.CommitteeID)) {
                                                    dateList[thisDate].committee.push(agenda.CommitteeID)
                                                }
                                            } else {
                                                dateList.push({ label: moment(agenda.CandidateDate).format("MM/DD/YYYY"), value: agenda.CandidateDate, committee: [agenda.CommitteeID] });
                                            }
                                        }
                                    }

                                    //Item has an agenda ID so this means that a bill has been previously saved to this calendar
                                    if (agenda.AgendaID) {
                                        noSavedItems = false;
                                    }

                                    //If we've parsed the last agenda of the last category then set the reference lists to be used in the dropdowns
                                    if (j === calendarData.CalendarCategories.length - 1 && i === category.Agendas.length - 1) {
                                        dateList = dateList.sort((a, b) => { return moment(a.value) - moment(b.value) })
                                        committeeList = committeeList.sort((a, b) => a.label.localeCompare(b.label));

                                        this.setState({
                                            dateList: [...new Map(dateList.map(x => [x["label"], x])).values()],
                                            committeeList: [...new Map(committeeList.map(x => [x["value"], x])).values()]
                                        })
                                    }
                                });
                                if ([98, 102].includes(category.CalendarCategoryTypeID)) {
                                    category.showAmendments = !!category.Agendas.flatMap(agenda => agenda.AgendaItems).find(agendaItem => agendaItem.IsActive && agendaItem.LDNumber);
                                }
                            })

                            let commentEdit = [];
                            let tempComments = [];

                            for (let i = 0; i < calendarData.CalendarComments.length; i++) {
                                let comment = calendarData.CalendarComments[i];
                                comment.Sequence = i + 1;
                                comments.push(comment);
                                commentEdit.push(false);
                                //I kid you not if I don't make a separate tempComment object here and push it into the tempComment array instead of inserting the entire comment
                                //then this.state.comments gets mutated in the handleCommentChange function even though it would never get explicitly changed -JGB                    
                                let tempComment = {
                                    Sequence: comment.Sequence,
                                    Comment: comment.Comment
                                }
                                tempComments.push(tempComment)
                            }

                            calendarData.CalendarCategories.forEach(category => this.handleAgendaSort(category.Agendas, category.CategoryType, category.CalendarCategoryTypeID, calendarData.ChamberCode));

                            this.toggleHideAllCategories(true, calendarData.CalendarCategories);

                            this.setState({
                                calendarData: calendarData,
                                showTitles: calendarData.ChamberCode === "H",
                                pendingSave: noSavedItems,
                                comments: comments,
                                tempComments: tempComments,
                                isLoaded: true,
                                calendarDate: calendarData.CalendarDate,
                                commentEdit: commentEdit,
                            }, () => {
                                //Get bills in the session for the bill dropdown
                                this.props.actions.getSessionBills('?sessionID= ' + this.state.calendarData.SessionID).then(() => {
                                    let billList = [...this.props.bills.sessionBills];
                                    billList.forEach(bill => {
                                        bill.label = bill.LegislationNumber
                                        bill.value = bill.LegislationID
                                    });
                                    this.setState({
                                        billList: billList,
                                    })
                                })
                                this.props.actions.getSessionById(this.state.calendarData.SessionID)
                                    .then(() =>
                                        this.setState({ selectedSession: this.props.session.selectedSession })
                                    );
                            });
                        })
                });
            });
    }

    togglePreview() {
        const opposite = !this.state.showPreview;
        this.setState({
            showPreview: opposite
        });
    }

    handleCommentChange(e, sequence) {
        let tempComments = [...this.state.tempComments];

        tempComments.find(x => x.Sequence === sequence).Comment = e.target.value;

        this.setState({
            tempComments: tempComments
        });
    }

    updateAgenda(organizedBills, saveRequired) {
        let calendarData = { ...this.state.calendarData };
        calendarData.CalendarCategories = organizedBills;

        this.setState({
            calendarData: calendarData,
            pendingSave: saveRequired === undefined ? this.state.pendingSave : saveRequired
        }, () => {
            this.forceUpdate();
        });
    }

    updateAgendaItems(agendaItems, categoryIndex, agendaIndex) {
        let calendarData = { ...this.state.calendarData };
        calendarData.CalendarCategories.filter(c => !c.DeletionDate)[categoryIndex].Agendas[agendaIndex].AgendaItems = agendaItems;

        this.setState({
            calendarData: calendarData,
            pendingSave: true
        });
    }

    changeCategoryDropdown(value) {
        this.setState({
            selectedCategory: value
        });
    }

    addCategory() {
        const selectedCategory = this.state.selectedCategory;
        //Used to find the new index of the category so the hidden categories indices can be updated
        const categoryFinder = new Date().getTime();
        let organizedBills = [...this.state.calendarData.CalendarCategories];
        let newCalendarCategory = {
            IsLegislationCategory: selectedCategory.IsLegislationCategory,
            Agendas: [],
            CalendarCategoryTypeID: selectedCategory.CalendarCategoryTypeID,
            CategoryCode: selectedCategory.CategoryCode,
            Description: selectedCategory.Description,
            PluralDescription: selectedCategory.PluralDescription,
            DisplayType: selectedCategory.DisplayType,
            CategoryType: selectedCategory.CategoryType,
            Sequence: selectedCategory.Sequence,
            isNewCategory: categoryFinder
        }
        organizedBills.push(newCalendarCategory);
        //Sort the calendar by smallest to greatest sequence
        organizedBills.sort((a, b) => (a.Sequence > b.Sequence) ? 1 : -1)
        //Update the categories that are hidden since the array indices have changed
        const newCategoryIndex = organizedBills.filter(c => !c.DeletionDate).findIndex(cat => cat.isNewCategory === categoryFinder);
        if (newCategoryIndex !== -1) {
            let hideCategories = this.state.hideCategories;
            hideCategories.splice(newCategoryIndex, 0, false);
            this.setState({
                hideCategories: hideCategories
            });
        }
        //Remove the selected category so the user doesn't accidentally add it twice
        this.setState({
            selectedCategory: ''
        });
        this.updateAgenda(organizedBills, true);

    }

    deleteCategory(category) {
        let calendarData = { ...this.state.calendarData };
        const selectedCategoryIndex = calendarData.CalendarCategories.filter(c => !c.DeletionDate).findIndex(c => c.CategoryCode === category.CategoryCode);
        calendarData.CalendarCategories.filter(c => !c.DeletionDate)[selectedCategoryIndex].DeletionDate = new Date();

        let hideCategories = this.state.hideCategories;
        if (hideCategories[selectedCategoryIndex] !== null && hideCategories[selectedCategoryIndex] !== undefined) {
            hideCategories.splice(selectedCategoryIndex, 1);
        }
        this.setState({ calendarData, hideCategories })
    }

    toggleHideCategory(catIndex) {
        let hideCategories = this.state.hideCategories;
        hideCategories[catIndex] = !hideCategories[catIndex]
        this.setState({
            hideCategories: hideCategories
        });
    }

    toggleHideAllCategories(hide, calendarCategories) {
        let hideCategories = this.state.hideCategories;
        calendarCategories = calendarCategories || this.state.calendarData.CalendarCategories;
        calendarCategories.forEach((cat, catIndex) => {
            hideCategories[catIndex] = hide
        })
        this.setState({
            hideCategories: hideCategories
        });
    }

    toggleHideAllNonIncludedBills() {
        this.setState({
            hideAllNonIncludedBills: !this.state.hideAllNonIncludedBills
        });
    }

    changeBillDropdown(index, value) {
        let selectedBill = [...this.state.selectedBill];
        selectedBill[index] = value;
        this.setState({
            selectedBill: selectedBill
        });
    }

    changeDateDropdown(value, callback) {
        this.setState({
            selectedDate: value
        }, () => {
            if (this.state.selectedDate && this.state.selectedCommittee)
                this.setPending();
            if (callback) {
                callback();
            }
        });
    }

    changeCommitteeDropdown(value, callback) {
        this.setState({
            selectedCommittee: value
        }, () => {
            if (this.state.selectedDate && this.state.selectedCommittee)
                this.setPending();
            if (callback) {
                callback();
            }
        });
    }

    setPending() {
        let calendarData = { ...this.state.calendarData };
        calendarData.CalendarCategories.forEach((category, i) => {
            category.Agendas.forEach((agenda, j) => {
                //If a given agenda item matches one filter (if one filter is selected) or both filters (if both are selected) 
                //Then mark it isActive and give it the pending value so we know to deactivate it if the user changes the filter selections   
                //Otherwise if it's active, pending, and it no longer matches the filters then remove the pending value and deactivate it so it's hidden
                if (((!this.state.selectedDate || this.state.selectedDate === "") && this.state.selectedCommittee && this.state.selectedCommittee.value === agenda.CommitteeID && !agenda.IsHidden && agenda.pending !== false)
                    || ((!this.state.selectedCommittee || this.state.selectedCommittee === "") && this.state.selectedDate && this.state.selectedDate.value === agenda.CandidateDate && !agenda.IsHidden && agenda.pending !== false)
                    || (this.state.selectedDate && this.state.selectedDate !== "" && this.state.selectedCommittee && this.state.selectedCommittee !== "" && this.state.selectedCommittee.value === agenda.CommitteeID && this.state.selectedDate.value === agenda.CandidateDate && !agenda.IsHidden && agenda.pending !== false)) {
                    agenda.IsActive = true;
                    //Disabling the pending feature which removes bills from the calendar if they've been included but not saved. I feel like there's a chance they'll want it back -JGB
                    //agenda.pending = true; 
                } else if (agenda.IsActive === true && agenda.pending === true) {
                    agenda.IsActive = false;
                    delete agenda.pending;
                }
            })

            category.Agendas = this.handleAgendaSort(category.Agendas, category.CategoryType, category.CalendarCategoryTypeID);
        })

        this.setState({
            calendarData
        })
    }

    addBill(index, callback) {
        let calendarCategories = [...this.state.calendarData.CalendarCategories];
        let newAgenda = {};

        //Check whether to move the bill from another category or add the bill
        let billExists = false;
        if (this.state.selectedBill[index]) {
            let associatedCategory = calendarCategories.filter(c => !c.DeletionDate)[index];
            calendarCategories.some((category, categoryIndex) => {
                const duplicateBillIndex = category.Agendas.findIndex(bill => bill.LegislationID === this.state.selectedBill[index].LegislationID);
                if (duplicateBillIndex >= 0) {
                    billExists = true;
                    newAgenda = { ...calendarCategories[categoryIndex].Agendas[duplicateBillIndex] };
                    newAgenda.IsActive = true;
                    newAgenda.CalendarCategoryTypeID = associatedCategory.CalendarCategoryTypeID;
                    newAgenda.CalendarCategoryID = associatedCategory.CalendarCategoryID;
                    calendarCategories[categoryIndex].Agendas.splice(duplicateBillIndex, 1);
                    return true;
                }
            });

            if (!billExists) {
                newAgenda = {
                    CalendarCategoryTypeID: associatedCategory.CalendarCategoryTypeID,
                    CalendarCategoryID: associatedCategory.CalendarCategoryID,
                    IsActive: true,
                    LegislationID: this.state.selectedBill[index].LegislationID,
                    LegislationNumber: this.state.selectedBill[index].LegislationNumber,
                    LegislationKey: this.state.selectedBill[index].LegislationKey,
                    Patrons: this.state.selectedBill[index].Patrons,
                    LegislationDescription: this.state.selectedBill[index].Description,
                    LegislationTitle: this.state.selectedBill[index].LegislationTitle,
                    Ranking: null
                }
            }

            associatedCategory.Agendas.splice(0, 0, newAgenda);
            associatedCategory.Agendas = this.handleAgendaSort(associatedCategory.Agendas, associatedCategory.CategoryType, associatedCategory.CalendarCategoryTypeID)

            this.updateAgenda(calendarCategories, true);

            //Remove object so the bill is no longer currently selected in the dropdown
            let selectedBill = [...this.state.selectedBill];
            selectedBill[index] = '';
            this.setState({
                selectedBill: selectedBill
            });
        }

        if (callback) {
            callback();
        }
    }

    handleBulkAgendaSort(calendar) {
        calendar.CalendarCategories.forEach((category, i) => {
            if (category.Agendas) {
                const response = this.handleAgendaSort(category.Agendas, category.CategoryType, category.CalendarCategoryTypeID)
                category.Agendas = response;
            }

            //Persist old showAmendments after save or unpublish
            if ([98, 102].includes(category.CalendarCategoryTypeID)) {
                category.showAmendments = !!category.Agendas.flatMap(agenda => agenda.AgendaItems).find(agendaItem => agendaItem.IsActive && agendaItem.LDNumber);
            }
        })

        return calendar
    }

    handleAgendaSort(agendas, categoryType, categoryTypeId, chamberCode) {
        let unrankedAgendas = [];
        let rankedAgendas = [];
        let inactiveAgendas = [];
        let rankings = [];

        if (agendas && categoryType != "Order") {
            agendas.forEach((agenda) => {
                if (!agenda.Ranking && agenda.IsActive) {
                    unrankedAgendas.push(agenda);
                } else if (agenda.IsActive) {
                    rankedAgendas.push(agenda);
                    rankings.push(agenda.Ranking);
                } else {
                    inactiveAgendas.push(agenda)
                }
            })

            rankings.sort((a, b) => a - b);

            let gaps = [];
            for (let i = 1; i < rankings.length; i++) {
                gaps.push(rankings[i] - rankings[i - 1]);
            }

            if (NUMERIC_SORT_CATEGORIES.includes(categoryTypeId)) {
                rankedAgendas.sort((a, b) => sortAgendasNumerically(a, b, this.state.calendarData.ChamberCode ?? chamberCode));
            } else {
                rankedAgendas.sort((a, b) => sortAgendas(a, b, this.state.calendarData.ChamberCode));
            }
            if (NUMERIC_SORT_CATEGORIES.includes(categoryTypeId)) {
                unrankedAgendas.sort((a, b) => sortAgendasNumerically(a, b, this.state.calendarData.ChamberCode ?? chamberCode));
            } else {
                unrankedAgendas.sort((a, b) => sortAgendas(a, b, this.state.calendarData.ChamberCode));
            }
            if (NUMERIC_SORT_CATEGORIES.includes(categoryTypeId)) {
                inactiveAgendas.sort((a, b) => sortInactiveAgendasNumerically(a, b, this.state.calendarData.ChamberCode ?? chamberCode));
            } else {
                inactiveAgendas.sort((a, b) => sortInactiveAgendas(a, b, this.state.calendarData.ChamberCode ?? chamberCode));
            }

            //If there are multiple ranks with a gap larger than 1 place all unranked agendas between them
            if (new Set(rankings).size > 1 && Math.max(...gaps) > 1) {
                let insertPoint = gaps.findIndex(x => x === Math.max(...gaps)) + 1

                for (let i = 0; i < unrankedAgendas.length; i++) {
                    rankedAgendas.splice(insertPoint, 0, unrankedAgendas[i]);
                    insertPoint++;
                }

                return rankedAgendas.concat(inactiveAgendas);
            } else {
                //If the largest rank is greater than 50 put all unranked agendas above the ranked agendas, otherwise put them all at the bottom
                if (Math.max(...rankings) >= 50) {
                    return unrankedAgendas.concat(rankedAgendas).concat(inactiveAgendas);
                } else {
                    return rankedAgendas.concat(unrankedAgendas).concat(inactiveAgendas);
                }
            }
        } else {
            return agendas;
        }
    }

    genCalendarPreview(category) {
        this.setState({
            gettingPdf: true
        });
        // This endpoint returns a pdf document and not json so the partnerRequest function will not work
        // It doesn't need to be saved to the redux store because it will not be referenced after the user sees it. So it is called here within the component.
        const API_URL = window.env ? window.env.API_SERVER : '';
        const idToken = 'LIS_ID_TOKEN';
        let signal = new window.AbortController().signal;
        const config = {
            headers: {
                'content-type': "application/json; charset=utf-8",
                Authorization: "Bearer " + localStorage.getItem(idToken),
                'WebAPIKey': process.env.REACT_APP_API_KEY
            },
            method: "GET",
            signal: signal
        };
        let params = `?calendarId=${this.state.calendarData.CalendarID}`
        if (category) {
            params += `&calendarCategoryID=${category.CalendarCategoryID}`
        }
        fetch((API_URL || process.env.REACT_APP_CALENDAR_FILE_API_URL) + '/CalendarFileGeneration/api/PreviewCalendarFileAsync' + params, config)
            .then(response => {
                if (response.status === 204) {
                    throw 'No Content';
                } else if (response.status > 399) {
                    throw response;
                } else {
                    response.blob()
                        .then(res => {
                            if (this.pdfRef && this.pdfRef.current) {
                                let pdfRef = this.pdfRef.current;
                                pdfRef.setAttribute('src', URL.createObjectURL(res));
                                pdfRef.onload = () => {
                                    this.setState({
                                        gettingPdf: false
                                    });
                                };
                            }
                        })
                }
            }).catch(err => {
                if (err.name === 'AbortError') {
                    return;
                }
                this.setState({
                    gettingPdf: false
                });
                //This is a response and the text needs to be extracted
                if (err.text) {
                    err.text().then(err => console.log(err.toString()));
                } else {
                    console.log(err.toString())
                }
                this.props.actions.makeToast([{ message: "Failed To Get Data", type: "failure" }]);
            })
    }

    addComment() {
        let newComments = this.state.comments;
        let newTempComments = this.state.tempComments

        newComments.push(
            {
                CalendarID: this.state.calendarData.CalendarID,
                Comment: "",
                Sequence: this.state.comments.length > 0 ? this.state.comments[this.state.comments.length - 1].Sequence + 1 : 1
            }
        )

        newTempComments.push(
            {
                Comment: "",
                Sequence: newComments[newComments.length - 1].Sequence
            }
        )

        this.setState({
            comments: newComments,
            tempComments: newTempComments
        }, () => {
            this.toggleCommentEdit(newComments[newComments.length - 1].Sequence)
        })
    }

    removeComment(i) {
        let newComments = this.state.comments;
        if (newComments[i].CalendarCommentID != undefined) {
            newComments[i].DeletionDate = moment().toISOString();
            newComments[i].Sequence = 0;
            newComments.sort((a, b) => a.Sequence - b.Sequence);
        } else {
            newComments.splice(i, 1);
        }

        let newSequence = 1;

        for (let j = 0; j < newComments.length; j++) {
            if (!newComments[j].DeletionDate) {
                newComments[j].Sequence = newSequence;
                newSequence++;
            }
        }

        this.setState({
            comments: newComments
        })
    }

    commentShift(i, shiftUp) {
        let newComments = [...this.state.comments];
        let newTempComments = [...this.state.tempComments];
        let newSequence = shiftUp ? newComments[i - 1].Sequence : newComments[i + 1].Sequence;

        let commentEdit = [...this.state.commentEdit];
        commentEdit[i] = commentEdit[shiftUp ? i - 1 : i + 1] == true ? true : false;
        commentEdit[shiftUp ? i - 1 : i + 1] = true;

        newComments[i].Sequence = newSequence;
        newTempComments[i].Sequence = newSequence

        if (shiftUp) {
            newComments[i - 1].Sequence = newSequence + 1;
            newTempComments[i - 1].Sequence = newSequence + 1;
        } else {
            newComments[i + 1].Sequence = newSequence - 1;
            newTempComments[i + 1].Sequence = newSequence - 1;
        }

        newComments.sort((a, b) => a.Sequence - b.Sequence);
        newTempComments.sort((a, b) => a.Sequence - b.Sequence);

        this.setState({
            comments: newComments,
            tempComments: newTempComments,
            commentEdit: commentEdit
        })
    }

    handleProFormaChange() {
        let calendarData = { ...this.state.calendarData };
        calendarData.IsProforma = !calendarData.IsProforma
        this.setState({
            calendarData: calendarData
        });
    }

    toggleCalendarPreview(categoryIndex) {
        this.setState({
            showCategoryPreview: categoryIndex
        });
    }

    togglePortal(state, category) {
        this.setState({
            showPortal: !state
        }, () => {
            if (this.state.showPortal && category) {
                this.genCalendarPreview(category === 'All' ? '' : category);
            }
        });
    }

    saveCalendar(publishFlag) {
        this.setState({ isSaving: true });
        let calendarSave = { ...this.state.calendarData };
        //Add new comments
        calendarSave.CalendarComments = this.state.comments;

        //If saving or publishing set isActive off showAmendments. If unpublishing, set showAmendments off isActive. 
        calendarSave.CalendarCategories.forEach(category => {
            if ([98, 102].includes(category.CalendarCategoryTypeID)) {
                if (publishFlag || (!calendarSave.IsPublic && !publishFlag)) {
                    category.Agendas.forEach(agenda => {
                        agenda.AgendaItems?.forEach(item => {
                            if (item.LDNumber) {
                                item.IsActive = category.showAmendments;
                            }
                        });
                    });
                } else if (!publishFlag) {
                    category.showAmendments = !!category.Agendas.flatMap(agenda => agenda.AgendaItems).find(agendaItem => agendaItem.IsActive && agendaItem.LDNumber);
                }
            }
        })

        calendarSave.CalendarCategories.forEach(category => {
            const response = this.handleAgendaSort(category.Agendas, category.CategoryType, category.CalendarCategoryTypeID)
            response.forEach((agenda, i) => {
                if (agenda.IsActive) {
                    agenda.Sequence = i + 1
                }
            })
            category.Agendas = response;
        });

        //Set whether it should be published or not. The published value depends on what button the user clicked
        calendarSave.IsPublic = publishFlag;
        this.props.actions.saveCalendar({ Calendars: [calendarSave] })
            .then(failureMessage => {
                if (this.state.showPreview || calendarSave.IsPublic) {
                    let content = this.componentRef.innerHTML.replace(/\\"/g, '');
                    this.props.actions.sendCalendarHtml({ Chamber: calendarSave.ChamberCode, CalendarDate: calendarSave.CalendarDate, CalendarNumber: calendarSave.CalendarNumber, ContentHTML: content });
                }
                if (publishFlag) {
                    //Calendars cannot be edited once published so send the user back to the grid
                    const calendarId = this.props.calendar.calendarSave.CalendarID;
                    this.props.navigate('/calendar-management');
                    this.props.actions.saveCalendarFile("?calendarID=" + calendarId)
                } else {
                    //If the form was not published then get the new mod dates and update the React state variables
                    let calendarSave = { ...this.props.calendar.calendarSave };
                    let comments = [];

                    for (let i = 0; i < calendarSave.CalendarComments.length; i++) {
                        let comment = calendarSave.CalendarComments[i];
                        comment.Sequence = i + 1;
                        comments.push(comment);
                    }

                    const response = this.handleBulkAgendaSort(calendarSave)
                    this.setState({
                        calendarData: response,
                        comments: comments,
                        isSaving: false,
                        pendingSave: false,
                        calendarDate: calendarSave.CalendarDate
                    }, () => {
                        this.forceUpdate();
                    });
                }

                let toasts = [];
                if (failureMessage && failureMessage.includes("Minutes")) {
                    toasts.push({ message: "Problem with Minutes Creation", type: "failure" });
                }
                toasts.push({ message: `Calendar ${publishFlag ? 'Published' : 'Saved'}`, type: "success" });
                this.props.actions.makeToast(toasts)
            }).catch(err => {
                if (err === 'Aborted') {
                    return;
                }
                this.setState({
                    isSaving: false
                });
                console.log(err)
                this.props.actions.makeToast([{ message: "Save Failed", type: "failure" }]);
            });
    }

    handlePdfFile(e) {
        this.setState({
            selectedFile: e.target.files[0]
        });
    }

    submitPdfFile() {
        this.setState({
            isSubmittingFile: true
        });
        this.props.actions.replacePdf('?calendarID=' + this.state.calendarData.CalendarID, this.state.selectedFile)
            .then(() => {
                this.setState({
                    isSubmittingFile: false,
                    selectedFile: ''
                });
                this.props.actions.makeToast([{ message: "PDF Replacement Success", type: "success" }]);
            }).catch(err => {
                if (err === 'Aborted') {
                    return;
                }
                this.setState({
                    isSubmittingFile: false
                });
                this.props.actions.makeToast([{ message: "PDF Replacement Failed", type: "failure" }]);
            })
    }

    changeConveneTime(date) {
        let calendarData = { ...this.state.calendarData };
        calendarData.CalendarDate = date.toISOString();
        this.setState({
            calendarData: calendarData
        });
    }

    toggleAgenda(catIndex, agendaIndex) {
        let calendar = { ...this.state.calendarData };
        let agenda = calendar.CalendarCategories.filter(c => !c.DeletionDate)[catIndex].Agendas[agendaIndex];
        agenda.show = !agenda.show;
        if (agenda.IsActive && agenda.LegislationNumber && !agenda.Versions) {
            agenda.Versions = []
            this.props.actions.getBillVersionByBillNumber('?BillNumber=' + calendar.CalendarCategories.filter(c => !c.DeletionDate)[catIndex].Agendas[agendaIndex].LegislationNumber + '&SessionID=' + calendar.SessionID)
                .then(() => {
                    // Get the calendar data again just in case the user toggled the agenda items
                    calendar = { ...this.state.calendarData };
                    let versions = this.props.bills.billVersion.filter(ver => ver.Version !== "Amendment" && moment(ver.DraftDate).isSameOrBefore(calendar.CalendarDate))
                    // Most recent versions first
                    versions = versions.sort((a, b) => moment(a.DraftDate).isSameOrBefore(b.DraftDate))
                    calendar.CalendarCategories.filter(c => !c.DeletionDate)[catIndex].Agendas[agendaIndex].Versions = versions
                    this.setState({
                        calendarData: calendar
                    });
                }).catch(err => {
                    if (err === "Aborted") {
                        return
                    }
                    calendar = { ...this.state.calendarData };
                    calendar.CalendarCategories.filter(c => !c.DeletionDate)[catIndex].Agendas[agendaIndex].Versions = "fail";
                    this.setState({
                        calendarData: calendar
                    });
                })
        }
        this.setState({
            calendarData: calendar
        });
    }

    toggleCommentEdit(sequence) {
        let commentEdit = [...this.state.commentEdit];
        let tempComments = [...this.state.tempComments];

        commentEdit[sequence - 1] = !commentEdit[sequence - 1];
        //Reset the temporary comment value
        tempComments.find(x => x.Sequence === sequence).Comment = this.state.comments.find(x => x.Sequence === sequence).Comment;

        this.setState({
            commentEdit: commentEdit,
            tempComments: tempComments
        })
    }

    confirmCommentEdit(sequence) {
        let comments = [...this.state.comments];

        comments.find(x => x.Sequence === sequence).Comment = this.state.tempComments.find(x => x.Sequence === sequence).Comment;

        this.setState({
            comments: comments
        }, () => {
            this.toggleCommentEdit(sequence)
        })
    }

    toggleShowTitles() {
        this.setState({
            showTitles: !this.state.showTitles
        })
    }

    componentWillUnmount() {
        cancelRequest();
    }

    render() {
        const { message } = this.state;

        let conveneTime = moment.utc(this.state.calendarData.CalendarDate);
        let timeChecker = conveneTime.clone().startOf('day');
        // By default created calendars have a time of 00:00. If it is midnight then display it as 12:00 pm instead
        if (conveneTime.isSame(timeChecker)) {
            conveneTime.hour(12).startOf('hour');
        }
        if (message) {
            return (
                <div className="dlas-forms">
                    <span>{message}</span>
                </div>
            );
        }
        else {
            let calendarFileUrl = '';
            if (this.state.calendarData.CalendarFiles) {
                const pdfFile = this.state.calendarData.CalendarFiles.find(file => file.FileURL.toUpperCase().includes('PDF'));
                if (pdfFile) {
                    const url = new URL(pdfFile.FileURL);
                    calendarFileUrl = lisDocDomain + (url.pathname[0] === '/' ? url.pathname : '/' + url.pathname);
                }
            }

            //This calendarTime logic is also set in lis-public-calendar-details, if changed here, change there as well
            const calendarTime = moment.utc(this.state.calendarData.CalendarDate).isSame(moment.utc(this.state.calendarData.CalendarDate).hour(0), "minute")
                || moment.utc(this.state.calendarData.CalendarDate).isSame(moment.utc(this.state.calendarData.CalendarDate).hour(12), "minute")
                ? "12 noon"
                : moment.utc(this.state.calendarData.CalendarDate).format("h:mm a");

            if (this.state.showPreview) {
                return (
                    <div style={{ paddingBottom: '75px' }}>
                        {this.state.showPortal &&
                            <Popout togglePortal={this.togglePortal} windowTitle="PDF Preview">
                                {this.state.gettingPdf &&
                                    <div className="center-spinner spinner">You must save before seeing changes in this PDF preview</div>
                                }
                                <iframe style={{ display: this.state.gettingPdf ? 'none' : 'block', width: '100%', height: '100%' }} ref={this.pdfRef}></iframe>
                            </Popout>
                        }
                        <div className="flex-row">
                            <h2>Calendar Preview</h2>
                            <ReactToPrint
                                trigger={() => <a type="button" className="button print"> Print</a>}
                                content={() => this.componentRef}
                                pageStyle={"break-inside: avoid"}
                            />
                        </div>
                        <CalendarDetailsComponent
                            calendar={this.state.calendarData}
                            calendarTime={calendarTime}
                            session={this.state.selectedSession}
                            comments={this.state.comments}
                            updateAgendaItems={this.updateAgendaItems}
                            isCalendarForm={true}
                            toggleAgenda={this.toggleAgenda}
                            sessionList={this.props.session.sessionList}
                            calendarCategoryReferences={this.state.calendarCategories}
                        />
                        <div style={{ display: "none" }}>
                            <div ref={el => (this.componentRef = el)}>
                                <CalendarDetailsPrintout
                                    calendar={this.state.calendarData}
                                    session={this.state.selectedSession}
                                    comments={this.state.comments}
                                />
                            </div>
                        </div>
                        <div className="align-right floating-button-bar inline-list">
                            <button onClick={() => this.saveCalendar(PUBLISH_CALENDAR)} disabled={this.state.isSaving} className="button">{this.state.isSaving ? "Publishing..." : "Publish"}</button>
                            <button onClick={this.togglePreview} className="button secondary">Edit</button>
                            <button disabled={this.state.pendingSave || this.state.calendarData.IsPublic} onClick={() => this.togglePortal(this.state.showPortal, 'All')} className="button" type="button">PDF Preview</button>
                        </div>
                    </div>
                );
            } else {
                return (
                    <React.Fragment>
                        <div>
                            <Prompt
                                when={this.state.pendingSave === true}
                                message={`You have unsaved changes. Are you sure you would like to leave?`}
                            />
                        </div>
                        {this.state.calendarData ? <h1>{(this.state.calendarData.ChamberCode === "H" ? "House " : "Senate ") + "Calendar"}</h1> : <h1>&nbsp;</h1>}
                        {this.state.showPortal &&
                            <Popout togglePortal={this.togglePortal} windowTitle="Preview">
                                {this.state.gettingPdf &&
                                    <div className="center-spinner spinner">You must save before seeing changes in this PDF preview</div>
                                }
                                <iframe style={{ display: this.state.gettingPdf ? 'none' : 'block', width: '100%', height: '100%' }} ref={this.pdfRef}></iframe>
                            </Popout>
                        }
                        {this.state.showCategoryPreview !== -1 &&
                            <div onClick={() => this.toggleCalendarPreview(-1)} className="overlay center-content">
                                <div className="full">
                                    <div onClick={e => e.stopPropagation()} className="dlas-forms user-forms advanced-search overlay-content animated-fade-in-up" ref={el => (this.componentRef = el)}>
                                        <CalendarDetailsComponent
                                            calendar={{ ...this.state.calendarData, CalendarCategories: [this.state.calendarData.CalendarCategories[this.state.showCategoryPreview]] }}
                                            calendarTime={calendarTime}
                                            session={this.state.selectedSession}
                                            comments={[]}
                                            userClaims={{ resources: [] }}
                                            toggleAgenda={this.toggleAgenda}
                                            sessionList={this.props.session.sessionList}
                                            isCalendarForm={true}
                                            calendarCategoryReferences={this.state.calendarCategories}
                                        />
                                        <button type="button" onClick={() => this.toggleCalendarPreview(-1)} className="button secondary">Close</button>
                                    </div>
                                </div>
                            </div>
                        }
                        <div className="user-forms">
                            {!this.state.isLoaded &&
                                <div className="center-spinner spinner">Loading results...</div>
                            }
                            {this.state.isLoaded &&
                                <div className="dlas-forms">
                                    <span className="error-message">{message}</span>

                                    <fieldset style={{ paddingBottom: '50px' }} className="fieldset-collapse fieldset-open">
                                        <legend>Calendar Information</legend>
                                        <div>
                                            <div className="multi-row four no-margin">
                                                <div>
                                                    <Fields.TextInput
                                                        value={this.state.selectedSession ? this.state.selectedSession.SessionYear + ' ' + this.state.selectedSession.DisplayName : ''}
                                                        disabled={true}
                                                    />
                                                </div>
                                                <div>
                                                    <Fields.TextInput
                                                        value={this.state.calendarData ? moment(this.state.calendarData.CalendarDate).format("L") + (this.state.calendarData.CalendarNumber > 1 ? [' (', ')'].join('Supp. ' + (this.state.calendarData.CalendarNumber - 1)) : '') : ''}
                                                        disabled
                                                    />
                                                </div>
                                                <div>
                                                    {/* Supplemental calendars do not get this date picker */}
                                                    {this.state.calendarData.CalendarNumber < 2 &&
                                                        <DatePicker
                                                            selected={conveneTime}
                                                            showTimeSelect
                                                            showTimeSelectOnly
                                                            dateFormat="h:mm a"
                                                            timeFormat="h:mm a"
                                                            onChange={this.changeConveneTime}
                                                        />
                                                    }
                                                </div>
                                                <div className="flex-row flex-end flex-vertical-center">
                                                    {calendarFileUrl && !this.state.isSubmittingFile &&
                                                        <>
                                                            <button style={{ marginRight: 5 }} type="button" className="button small-button file-button"><input type="file" accept=".pdf" onChange={this.handlePdfFile} />{this.state.selectedFile ? this.state.selectedFile.name : "Replace PDF"}</button>
                                                            {this.state.selectedFile ?
                                                                <button onClick={this.submitPdfFile} type="button" className="button small-button">Submit</button>
                                                                :
                                                                <React.Fragment>
                                                                    <a target="_blank" className="icon pdf" href={calendarFileUrl}></a>
                                                                    {this.state.calendarData.CalendarNumber < 2 &&
                                                                        <a target="_blank" className="icon file-empty" style={{ marginLeft: "5px" }} href={calendarFileUrl.replace(".PDF", "-padded.PDF")}></a>}
                                                                </React.Fragment>
                                                            }
                                                        </>
                                                    }
                                                    {calendarFileUrl && this.state.isSubmittingFile &&
                                                        <div className="small-spinner"></div>
                                                    }
                                                </div>
                                            </div>
                                            <div className="flex-row">
                                                <label className="label-and-checkbox pro-forma"><input type="checkbox" checked={this.state.calendarData.IsProforma} onChange={this.handleProFormaChange} /> Create a Pro Forma Calendar</label>
                                                <button type="button" className="button float-right title-toggle" onClick={() => this.toggleShowTitles()}>{this.state.showTitles ? "Show Bill Captions" : "Show Bill Titles"}</button>
                                            </div>
                                            {this.state.comments.map((comment, i) => {
                                                return (
                                                    comment.DeletionDate === undefined &&
                                                    <div className="grid-wrapper calendar-comment-row" key={i}>
                                                        {this.state.commentEdit[comment.Sequence - 1] ?
                                                            <React.Fragment>
                                                                <div>
                                                                    <Fields.TextArea
                                                                        label={"Comment " + comment.Sequence}
                                                                        type="text"
                                                                        style={{ resize: 'vertical', minHeight: 65, width: "100%" }}
                                                                        placeholder="Comments"
                                                                        value={this.state.tempComments.find(x => x.Sequence === comment.Sequence).Comment}
                                                                        onChange={(e) => this.handleCommentChange(e, comment.Sequence)}
                                                                    />
                                                                </div>
                                                                <div className="grid-wrapper two comment-controls">
                                                                    <div style={{ paddingTop: "25px" }}>
                                                                        <button className="icon save" style={{ marginRight: "10px" }} onClick={() => this.confirmCommentEdit(comment.Sequence)} />
                                                                        <button className="icon delete" onClick={() => this.toggleCommentEdit(comment.Sequence)} />
                                                                    </div>
                                                                    <div className="arrows">
                                                                        {i !== 0 && this.state.comments.length > 1 &&
                                                                            <button className="arrow-up" onClick={() => this.commentShift(i, true)} />
                                                                        }
                                                                        {i !== this.state.comments.length - 1 &&
                                                                            <button className="arrow-down" onClick={() => this.commentShift(i, false)} />
                                                                        }
                                                                    </div>
                                                                </div>
                                                            </React.Fragment>
                                                            :
                                                            <React.Fragment>
                                                                <div>
                                                                    <label>{"Comment " + comment.Sequence}</label>
                                                                    <p class="calendar-comment">{comment.Comment}</p>
                                                                </div>
                                                                <div className="grid-wrapper two">
                                                                    <div style={{ paddingTop: "30px", display: "grid", gridTemplateColumns: "1fr 1fr", gridGap: "10px" }}>
                                                                        <button className="button edit" onClick={() => this.toggleCommentEdit(comment.Sequence)} />
                                                                        <button className="icon bin" onClick={() => this.removeComment(i)} />
                                                                    </div>
                                                                    <div />
                                                                </div>
                                                            </React.Fragment>
                                                        }
                                                    </div>
                                                )
                                            })
                                            }
                                            <div className="add-comment">
                                                <button className="button float-right" onClick={() => this.addComment()}>Add Comment</button>
                                            </div>
                                        </div>
                                        <BillsGrid
                                            organizedBills={this.state.calendarData.CalendarCategories}
                                            updateAgenda={this.updateAgenda}
                                            calendarCategories={this.state.calendarCategories}
                                            hideCategories={this.state.hideCategories}
                                            selectAllActive={this.state.selectAllActive}
                                            selectAllInactive={this.state.selectAllInactive}
                                            changeCategoryDropdown={this.changeCategoryDropdown}
                                            selectedCategory={this.state.selectedCategory}
                                            addCategory={this.addCategory}
                                            deleteCategory={this.deleteCategory}
                                            selectedBill={this.state.selectedBill}
                                            changeBillDropdown={this.changeBillDropdown}
                                            addBill={this.addBill}
                                            billList={this.state.billList}
                                            toggleCalendarPreview={this.toggleCalendarPreview}
                                            pendingSave={this.state.pendingSave}
                                            toggleHideCategory={this.toggleHideCategory}
                                            toggleHideAllCategories={this.toggleHideAllCategories}
                                            toggleHideAllNonIncludedBills={this.toggleHideAllNonIncludedBills}
                                            hideAllNonIncludedBills={this.state.hideAllNonIncludedBills}
                                            togglePortal={this.togglePortal}
                                            showPortal={this.state.showPortal}
                                            updateAgendaItems={this.updateAgendaItems}
                                            calendarTime={calendarTime}
                                            showTitles={this.state.showTitles}
                                            changeDateDropdown={this.changeDateDropdown}
                                            changeCommitteeDropdown={this.changeCommitteeDropdown}
                                            dateList={this.state.selectedCommittee && this.state.selectedCommittee !== "" ? this.state.dateList.filter(x => x.committee.includes(this.state.selectedCommittee.value)) : this.state.dateList}
                                            committeeList={this.state.selectedDate && this.state.selectedDate !== "" ? this.state.committeeList.filter(x => this.state.selectedDate.committee.includes(x.value)) : this.state.committeeList}
                                            selectedCommittee={this.state.selectedCommittee}
                                            selectedDate={this.state.selectedDate}
                                            fullCommitteeList={this.props.committee.committeeList}
                                            chamberCode={this.state.calendarData.ChamberCode}
                                            sessionCode={this.state.calendarData.SessionCode}
                                            userRoles={this.props.login.userClaims.roles}
                                        />
                                    </fieldset>
                                    <div className="align-right floating-button-bar inline-list">
                                        <button disabled={this.state.isSaving} onClick={() => this.saveCalendar(SAVE_CALENDAR)} className="button" type="submit">{this.state.calendarData.IsPublic ? "Unpublish" : this.state.isSaving ? "Saving..." : "Save"}</button>
                                        <button disabled={this.state.pendingSave || this.state.isSaving || this.state.calendarData.IsPublic} onClick={this.togglePreview} className="button" type="button">Preview</button>
                                        <button disabled={this.state.pendingSave || this.state.isSaving || this.state.calendarData.IsPublic} onClick={() => this.togglePortal(this.state.showPortal, 'All')} className="button" type="button">PDF Preview</button>
                                    </div>
                                </div>
                            }
                        </div>
                    </React.Fragment>
                );
            }
        }
    }
};

const CalendarManagementForm = connect(
    (state) => {
        const { calendar, session, bills, nav, committee, login } = state;
        return {
            calendar,
            session,
            bills,
            nav,
            committee,
            login
        }
    },
    (dispatch) => {
        return {
            actions: bindActionCreators(Object.assign({}, calendarActionCreators, sessionActionCreators, billActionCreators, navActionCreators, committeeActionCreators), dispatch)
        }
    }
)(HouseCalendarFormComponent)

export default CalendarManagementForm;
