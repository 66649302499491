import moment from 'moment';

const SearchTranslator = props => {
    const sessionCode = parseInt(props.match.params.id);
    const wordToTranslate = props.match.params.word;
    const param = props.match.params.wordparam;
    let url = '/bill-search';
    if(isNaN(sessionCode) || !wordToTranslate) {
        props.history.push(url);
        return(null);
    }

    let billSearchParams = '';
    switch(wordToTranslate.toUpperCase()) {
        case("ALL"):
            billSearchParams = { selectedBillStatus: 1 }
            break;
        case("TOP"):
            billSearchParams = { mostFrequent: true }
            break;
        case("INT"):
            const date = moment(param);
            if(param && date.isValid()) {
                billSearchParams = {
                    selectedBillStatus: 1,
                    billStatusStartDate: date.startOf('day'),
                    billStatusEndDate: date.endOf('day')
                };  
            }
            break;
        case("PSH"):
            billSearchParams = { selectedBillStatus: 4 }
            break;
        case("PSS"):
            billSearchParams = { selectedBillStatus: 5 }
            break;
        case("PAS"):
            billSearchParams = { selectedBillStatus: 6 }
            break;
        case("ENR"):
            billSearchParams = { selectedBillStatus: 14 }
            break;
        case("SGN"):
            billSearchParams = { selectedBillStatus: 21 }
            break;
        case("FAI"):
            billSearchParams = { selectedBillStatus: 9 }
            break;
        case("PND"):
            billSearchParams = { isPending: true }
            break;
        case("CAR"):
            billSearchParams = { selectedBillStatus: 10 }
            break;
        case("CIN"):
            billSearchParams = { selectedBillStatus: 16 }
            break;
        case("CRA"):
            billSearchParams = { selectedBillStatus: 31 }
            break;
        case("CRR"):
            billSearchParams = { selectedBillStatus: 32 }
            break;
        case("REC"):
            billSearchParams = { selectedBillStatus: 27 }
            break;
        case("GAS"):
            billSearchParams = { selectedBillStatus: 33 }
            break;
        case("APP"):
            billSearchParams = { selectedBillStatus: 8 }
            break;
        case("VET"):
            billSearchParams = { selectedBillStatus: 26 }
            break;
        case("CHP"):
            billSearchParams = { selectedBillStatus: 25 }
            break;
    }

    if(billSearchParams) {
        billSearchParams.selectedSession = sessionCode;
        const b64Params = window.btoa(JSON.stringify(billSearchParams));
        url += '?q=' + b64Params;
    }
    props.history.push(url);

    return(null);
}

export default SearchTranslator;