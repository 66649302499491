import React from "react";
import { connect } from 'react-redux';
import { withRouter } from "react-router";
import { bindActionCreators } from 'redux';
import { developersActionCreators } from "../../../stores/lis-developers-store";
import { navActionCreators } from "../../../stores/lis-nav-store";

const emailRegex = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
const phoneRegex = /^[\+]?[(]?[0-9]{3}[)]?[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{4,6}$/im;

class PartnerForm extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            isSaving: false,
            isLoading: true,
            partner: {},
            initIsActive: false
        }

        this.checkEmail = this.checkEmail.bind(this);
        this.checkPhone = this.checkPhone.bind(this);
        this.handleChange = this.handleChange.bind(this);
        this.save = this.save.bind(this);
    }

    componentDidMount() {
        //Fetch the partner by the ID
        this.props.actions.getPartner("?partnerID=" + this.props.match.params.partnerId)
            .then(() => {
                this.setState({
                    partner: this.props.developers.partner,
                    initIsActive: this.props.developers.partner.IsActive,
                    isLoading: false
                })
            })
    }

    checkEmail() {
        const email = this.state.partner.EmailAddress.toLowerCase();

        if (!emailRegex.test(email)) {
            this.setState({
                emailError: 'Invalid email address'
            }, () => {
                this.props.actions.makeToast([{ message: this.state.emailError, type: "failure" }])
            });
            return true;
        }
        this.setState({
            emailError: ''
        });
    }

    checkPhone() {
        if (!phoneRegex.test(this.state.partner.PhoneNumber)) {
            this.setState({
                phoneError: 'Invalid phone number'
            }, () => {
                this.props.actions.makeToast([{ message: this.state.phoneError, type: "failure" }])
            });
            return true;
        }
        this.setState({
            phoneError: ''
        });
    }

    handleChange(state, value) {
        let partner = { ...this.state.partner };
        partner[state] = value;
        this.setState({
            partner
        });
    }

    save() {
        this.setState({
            isSaving: true
        }, () => {
            let error = false
            error = this.checkEmail();
            error = this.checkPhone();

            if (!error) {
                let params = "";

                params = params + "?PartnerID=" + this.state.partner.PartnerID
                params = params + "&contactName=" + this.state.partner.ContactName;
                params = params + "&organizationName=" + this.state.partner.OrganizationName;
                params = params + "&phone=" + this.state.partner.PhoneNumber;
                params = params + "&email=" + this.state.partner.EmailAddress;
                params = params + "&identityId=" + this.state.partner.IdentityID;
                params = params + "&isActive=" + this.state.partner.IsActive;
                params = params + "&isActiveChanged=" + (this.state.partner.IsActive !== this.state.initIsActive ? "true" : "false");

                this.props.actions.savePartner(params)
                    .then(() => {
                        this.setState({
                            isSaving: false
                        }, () => {
                            this.props.actions.makeToast([{ message: "Partner Saved", type: "success" }])
                            this.props.history.push("/partner-management")
                        })
                    })
                    .catch((err) => {
                        this.props.actions.makeToast([{ message: this.props.developers.savePartnerError, type: "failure" }])
                    })
            }
        })
    }

    render() {
        const { partner, isSaving, isLoading } = this.state;

        return (
            <div>
                <form className="dlas-forms key-registration-form">
                    <h3>Manage Partner</h3>
                    <div className="inner-grid short-one-three">
                        <label htmlFor="name">Name*</label>
                        <input
                            id="name"
                            type="text"
                            value={partner.ContactName}
                            onChange={(e) => this.handleChange("ContactName", e.target.value)}
                            disabled={isLoading || isSaving}
                        />
                    </div>
                    <div className="inner-grid short-one-three">
                        <label htmlFor="organization">Organization</label>
                        <input
                            id="organization"
                            type="text"
                            value={partner.OrganizationName}
                            onChange={(e) => this.handleChange("OrganizationName", e.target.value)}
                            disabled={isLoading || isSaving}
                        />
                    </div>
                    <div className="inner-grid short-one-three">
                        <label htmlFor="email">Email*</label>
                        <input
                            id="email"
                            type="text"
                            value={partner.EmailAddress}
                            onChange={(e) => this.handleChange("EmailAddress", e.target.value)}
                            disabled={isLoading || isSaving}
                        />
                    </div>
                    <div className="inner-grid short-one-three">
                        <label htmlFor="phone">Phone Number*</label>
                        <input
                            id="phone"
                            type="text"
                            value={partner.PhoneNumber}
                            onChange={(e) => this.handleChange("PhoneNumber", e.target.value)}
                            disabled={isLoading || isSaving}
                        />
                    </div>
                    <div className="inner-grid reverse-short-one-three">
                        <div className="checkbox flex-row flex-vertical-center flex-start">
                            <div className="toggle-switch" onClick={() => this.handleChange("IsActive", !partner.IsActive)}>
                                <input id="is-active-button" checked={partner.IsActive} type="checkbox" style={{ width: "auto" }} />
                                <span className="slider"></span>
                            </div>
                            <label htmlFor="is-active-button" className="checkbox-label no-background">Is Active?</label>
                        </div>
                        <div className="align-right">
                            <button onClick={() => this.save()} disabled={isSaving || isLoading || (!partner.ContactName || !partner.EmailAddress || !partner.PhoneNumber)} type="button" className="button">Submit</button>
                        </div>
                    </div>
                </form>
            </div>
        )
    }
}

const PartnerFormWrapper = connect(
    (state) => {
        const { nav, developers } = state;
        return {
            nav,
            developers
        }
    },
    (dispatch) => {
        return {
            actions: bindActionCreators(Object.assign({}, developersActionCreators, navActionCreators), dispatch)
        }
    }
)(PartnerForm)

export default withRouter(PartnerFormWrapper)