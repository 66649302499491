import React from 'react';

import renderHTML from 'react-render-html';

class MinutesDetails extends React.Component {

    render() {
        const { categories, publicPage } = this.props;
        return (
            <div>
                {categories.filter(c => c.MinutesEntries && c.MinutesEntries.length).map((category, index) => {
                    //Used to grey out the categories that have no published entries
                    const nonPublicEntries = category.MinutesEntries.find(entry => !entry.IsPublic);
                    return (<div key={index} className={!publicPage && "preview-entry"}>
                        {!publicPage &&
                            <div className="entry-index">
                                <p>{index + 1}</p>
                            </div>
                        }
                        <div className="entry-text">
                            <ul>
                                {!category.CategoryDescription.toLowerCase().includes('communication') && category.CategoryDescription.toLowerCase() !== 'order of business' ?
                                    category.CategoryText ?
                                        category.CategoryText.split(/%+/).map(categoryTextPiece =>
                                            <li className={`${(nonPublicEntries || category.MinutesEntries.length === 0) && !publicPage ? "inactive " : ""}category-heading`}>{categoryTextPiece}</li>
                                        )
                                        :
                                        <React.Fragment>
                                            {category.DisplayType && <li className={`${(nonPublicEntries || category.MinutesEntries.length === 0) && !publicPage ? "inactive " : ""}category-heading`}>{category.CategoryType}</li>}
                                            <li className={`${(nonPublicEntries || category.MinutesEntries.length === 0) && !publicPage ? "inactive " : ""}category-heading`}>{category.CategoryDescription}</li>
                                        </React.Fragment>
                                    : null
                                }
                                {category.MinutesEntries.sort((a, b) => (a.IsOOB === b.IsOOB ? 0 : a.IsOOB ? 1 : -1) || a.Sequence - b.Sequence).map((entry, i) => (
                                    <React.Fragment key={i}>
                                        <li className={!entry.IsPublic && !publicPage ? "inactive" : ""} key={i} style={!entry.EntryText ? entry.CommunicationID && category.CategoryType !== 'Chairmans Report' && !category.MinutesEntries[i - 1] ? {} : { padding: "0px" } : {}}>
                                            {entry.MinutesActivities && entry.MinutesActivities.filter(activity => !activity.DeletionDate && (activity.InPreview || !entry.LegislationNumber)).length > 0 ?
                                                <React.Fragment>
                                                    {entry.MinutesActivities.filter(activity => !activity.DeletionDate && (activity.InPreview || !entry.LegislationNumber)).map((act, actIndex) =>
                                                        <span key={actIndex} className={!act.IsPublic && !publicPage ? "inactive" : ""}>
                                                            {actIndex === 0 ?
                                                                (entry.LegislationNumber ? `${entry.LegislationNumber} - ` : '') + act.Description : `, ${act.Description}`
                                                            }
                                                        </span>
                                                    )}
                                                </React.Fragment>
                                                :
                                                <React.Fragment>
                                                    {(entry.LegislationNumber || category.CategoryType === "Chairmans Report") && entry.EntryText &&
                                                        <React.Fragment>{`${entry.LegislationNumber ? entry.LegislationNumber + ' - ' : ''}${entry.EntryText}`}</React.Fragment>
                                                    }
                                                </React.Fragment>
                                            }
                                        </li>
                                        {!entry.EntryText && (!entry.MinutesActivities || entry.MinutesActivities.filter(activity => !activity.DeletionDate && (activity.InPreview || !entry.LegislationNumber)).length === 0) &&
                                            <React.Fragment>{entry.MinutesSummaries && entry.MinutesSummaries.length > 0 && entry.LegislationNumber &&
                                                <li className={!entry.IsPublic && !publicPage ? "inactive" : ""}>{entry.LegislationNumber}</li>}
                                            </React.Fragment>
                                        }
                                        {category.CategoryType !== 'Chairmans Report' && entry.MinutesSummaries && entry.MinutesSummaries.map((summary, summaryIndex) =>
                                            <li key={summaryIndex} className={!entry.IsPublic && !publicPage ? "inactive" : ""}>
                                                {renderHTML(summary.MinutesSummary || '')}
                                            </li>
                                        )}
                                    </React.Fragment>
                                ))}
                            </ul>
                        </div>
                    </div>);
                })}
            </div>
        )
    }
}

export default MinutesDetails;
