import React from 'react';
import { Link } from 'react-router-dom';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import '../../../stylesheets/lis-public-view/public-view.css';
import { committeeActionCreators } from '../../../stores/lis-committee-store';
import { memberActionCreators } from '../../../stores/lis-members-store';
import { personnelActionCreators } from '../../../stores/lis-personnel-store';

function SubCommitteesList(props) {
    const listCommittees = props.committees.map(committee => {
        let key = Math.random().toString();
        return (
            <div key={key} className="inner-grid two" >
                <div>
                    <Link to="/committee-details">{committee.Name}</Link>
                </div>
                <div>
                    {committee.PendingChange && <p>Not Published</p>}
                </div>
            </div>
            )
    })
    return (
        <React.Fragment>
            {listCommittees}
        </React.Fragment>
    )
}

function MemberListComponent(props) {
    let key = '';
    const names = props.members.map(member => {
        //If the member is to be removed on the next save then don't show them in the preview
        if (!member.EffectiveEndDate) {
            key = Math.random().toString();
            //Add the role label to the display, unless the role is 'Member' to keep things less redundant
            let role = props.roles.find(role => role.value === member.CommitteeRoleID)
            if (role && role.label !== 'Member') {
                return (
                    <div key={key} className="inner-grid two">
                        <div>
                            <p><b>{role.label}</b>: {member.MemberDisplayName} </p>
                        </div>
                        <div>
                            <p>{member.Seniority}</p>
                        </div>
                    </div>
                )
            } else {
                return (
                    <div key={key} className="inner-grid two">
                        <div>
                            <p>{member.MemberDisplayName}</p>
                        </div>
                        <div>
                            <p>{member.Seniority}</p>
                        </div>
                    </div>
                )
            }
        }
    });
    return (
        <React.Fragment>
            {names}
        </React.Fragment>
    );
}

function PersonnelListComponent(props) {
    let key = '';
    //Creates the list of names
    const names = props.personnel.map(personnel => {
        key = Math.random().toString();
        return (
            <div key={key} className="inner-grid two">
                <div>
                    <p>{personnel.FullName}</p>
                </div>
                <div>
                    <p>{personnel.PhoneNumber}</p>
                </div>
            </div>)
    });
    return (
        <React.Fragment>
            {names}
        </React.Fragment>
    );
}

class CommitteeDetailsComponent extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            roles: [],
        };
    }

    render() {
        const { committeeData, clerkList, staffList, memberList, subcommitteesList } = this.props;

        return (
            <div className="partner-details">
                <div>
                    <h3>{committeeData.Name}</h3>
                </div>
                <div className="details-section inner-grid three">
                    <div className="details-category">
                        <h4>COMMITTEE INFORMATION</h4>
                    </div>
                    <div className="span-two">
                        <div className="inner-grid two">
                            <div>
                                <p>Abbreviation:</p>
                            </div>
                            <div>
                                <p>{committeeData.Abbreviation}</p>
                            </div>
                        </div>
                        <div className="inner-grid two">
                            <div>
                                <p>Committee Number:</p>
                            </div>
                            <div>
                                <p>{committeeData.CommitteeNumber}</p>
                            </div>
                        </div>
                        <div className="inner-grid two">
                            <div>
                                <p>Chamber:</p>
                            </div>
                            <div>
                                <p>{(committeeData.ChamberCode === 'S') ? 'Senate' : 'House'}</p>
                            </div>
                        </div>
                        <div className="inner-grid two">
                            <div>
                                <p>Description:</p>
                            </div>
                            <div>
                                <p>{committeeData.Description}</p>
                            </div>
                        </div>
                        <div className="inner-grid two">
                            <div>
                                <p>Meeting Notes:</p>
                            </div>
                            <div>
                                <p>{committeeData.MeetingNote}</p>
                            </div>
                        </div>
                        <div className="inner-grid two">
                            <div>
                                <p>Twitter Handle:</p>
                            </div>
                            <div>
                                <p>{committeeData.TwitterHandle}</p>
                            </div>
                        </div>
                        <div className="inner-grid two">
                            <div>
                                <p>Twitter Email:</p>
                            </div>
                            <div>
                                <p>{committeeData.TwitterEmail}</p>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="details-section inner-grid three">
                    <div className="details-category">
                        <h4>CLERK INFORMATION</h4>
                    </div>
                    {clerkList === undefined || clerkList.length === 0 ? (
                        <div className="span-two"><p>N/A</p></div>
                    ) : (
                        <div className="span-two"><PersonnelListComponent personnel={clerkList} /></div>
                    )}
                </div>
                <div className="details-section inner-grid three">
                    <div className="details-category">
                        <h4>STAFF INFORMATION</h4>
                    </div>
                    {staffList === undefined || staffList.length === 0 ? (
                    <div className="span-two"><p>N/A</p></div>
                    ) : (
                        <div className="span-two"><PersonnelListComponent personnel={staffList} /></div>
                    )}
                </div>
                <div className="details-section inner-grid three">
                    <div className="details-category">
                        <h4>Member Information</h4>
                    </div>
                    {memberList === undefined || memberList.length === 0 ? (
                        <div className="span-two"><p>{this.props.members.membersByCommitteeError}</p></div>
                    ) : (
                        <div className="span-two"><MemberListComponent members={memberList} roles={this.props.memberRoles} /></div>
                    )}
                </div>
                {subcommitteesList !== undefined && subcommitteesList.length > 0 &&
                    <div className="details-section inner-grid three">
                        <div>
                            <h4>Subcommittees</h4>
                        </div>
                        {subcommitteesList === undefined || subcommitteesList.length === 0 ? (
                            <div className="span-two"><p>{this.props.committee.partnerCommitteeListError}</p></div>
                        ) : (
                                <div className="span-two"><SubCommitteesList committees={subcommitteesList} /></div>
                            )}
                    </div>
                }
                </div>
        )
    }
}

const PartnerCommitteeDetails = connect(
    (state) => {
        const { committee, members, personnel } = state;
        return {
            committee,
            members,
            personnel
        }
    },
    (dispatch) => {
        return {
            actions: bindActionCreators(Object.assign({}, committeeActionCreators, memberActionCreators, personnelActionCreators), dispatch)
        }
    }
)(CommitteeDetailsComponent)

export default PartnerCommitteeDetails;
