import React from "react";
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import Select from "react-select";
import { Link } from 'react-router-dom';

const getItemStyle = (isDragging, draggableStyle, pendingDeletion) => ({
    userSelect: 'none',
    background: isDragging ? '#34495e' : 'white',
    color: isDragging ? 'white' : '#34495e',
    border: pendingDeletion ? 'red' : 'none',
    ...draggableStyle
});

const getListStyle = isDraggingOver => ({
    background: isDraggingOver ? '#8b9fb3' : '#666666',
    width: '100%'
});

class CommitteeBillsGrid extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            lastCheckedBills: [],
            showAuxBillForm: false,
            auxBill: "",
            savingAuxBill: false
        }

        this.toggleRow = this.toggleRow.bind(this);
        this.toggleBill = this.toggleBill.bind(this);
        this.toggleBillBulk = this.toggleBillBulk.bind(this);
        this.onDragEnd = this.onDragEnd.bind(this);
        this.handleChange = this.handleChange.bind(this);
        this.toggleEdit = this.toggleEdit.bind(this);
        this.saveChange = this.saveChange.bind(this);
        this.toggleAuxBillAdd = this.toggleAuxBillAdd.bind(this);
        this.addAuxBill = this.addAuxBill.bind(this);
        this.setAuxBill = this.setAuxBill.bind(this);
    }

    toggleRow(LegislationID, categorySequence, e) {
        //the categories array is split up in the view into non-legislative and active/inactive sections.
        //So to find the correct array item to update the category's and item's Sequence property is used
        let categories = [...this.props.categories];
        const catIndex = categories.findIndex(category => category.Sequence === categorySequence);
        let items = categories[catIndex].DocketItems;
        let itemIndex = items.findIndex(bill => bill.LegislationID === LegislationID);

        let lastCheckedBills = this.state.lastCheckedBills;
        let lastCheckedBill = lastCheckedBills[categorySequence];
        if (e.nativeEvent.shiftKey && !isNaN(lastCheckedBill) && lastCheckedBill !== -1) {
            for (let i = 0; i < Math.abs(itemIndex - lastCheckedBill); i++) {
                const index = lastCheckedBill + i * (itemIndex - lastCheckedBill) / Math.abs(itemIndex - lastCheckedBill);
                items[index].checked = true;
            }
        } else {
            lastCheckedBill = itemIndex;
        }

        items[itemIndex].checked = !items[itemIndex].checked;
        this.props.updateDocket(categories);

        lastCheckedBills[categorySequence] = lastCheckedBill;
        this.setState({
            lastCheckedBills: lastCheckedBills
        });
    }

    toggleBill(legislationID, categorySequence) {
        let categories = [...this.props.categories];
        const catIndex = categories.findIndex(category => category.Sequence === categorySequence);
        let items = categories[catIndex].DocketItems;
        const itemIndex = items.findIndex(bill => bill.LegislationID === legislationID);

        //Check the IsActive before setting it to see if we're going from inactive to active.
        //If so then assign the sequence as the last sequence in the category.
        //Otherwise remove the sequence and resequence existing included items from 1
        if (items[itemIndex].IsActive) {
            items[itemIndex].Sequence = null;
            items[itemIndex].IsActive = false;
            let sequence = 1;
            for (let i = 0; i < items.length; i++) {
                if (items[i].IsActive === true) {
                    items[i].Sequence = sequence;
                    sequence = sequence + 1;
                }
            }
        } else {
            items[itemIndex].IsActive = true;
            //insert the newly included bill alphabetically
            const nextHighestActiveLegislationNumber = items.filter(i => i.IsActive && i.Sequence).find(i => i.LegislationNumber.substr(0, 2).localeCompare(items[itemIndex].LegislationNumber.substr(0, 2)) === 1 || (i.LegislationNumber.substr(0, 2).localeCompare(items[itemIndex].LegislationNumber.substr(0, 2)) === 0 && i.LegislationKey > items[itemIndex].LegislationKey));
            if (nextHighestActiveLegislationNumber) {
                const sequence = nextHighestActiveLegislationNumber.Sequence
                items.filter(i => i.IsActive && i.Sequence >= nextHighestActiveLegislationNumber.Sequence).forEach(i => i.Sequence++)
                items[itemIndex].Sequence = sequence;
            } else {
                const currentSequences = items.filter(i => i.Sequence).map(i => { return i.Sequence; });
                if (currentSequences.length) {
                    items[itemIndex].Sequence = (Math.max.apply(Math, currentSequences) + 1) || 1;
                } else {
                    items[itemIndex].Sequence = 1;
                }
            }
        }

        items[itemIndex].checked = false;
        //Must resort on the Sequence to prevent the index-based reordering in onDragEnd from breaking after bills are included or unincluded
        items.sort((a, b) => (a.Sequence != null ? a.Sequence : Infinity) - (b.Sequence != null ? b.Sequence : Infinity))

        this.props.updateDocket(categories);
    }

    toggleBillBulk(activate) {
        let categories = [...this.props.categories];
        categories.forEach(category => {
            category.DocketItems.forEach(bill => {
                if (bill.checked) {
                    bill.IsActive = activate;
                    bill.checked = false;

                    if (activate) {
                        //insert the newly included bill alphabetically
                        const nextHighestActiveLegislationNumber = category.DocketItems.filter(i => i.IsActive && i.Sequence).find(i => i.LegislationNumber.substr(0, 2).localeCompare(bill.LegislationNumber.substr(0, 2)) === 1 || (i.LegislationNumber.substr(0, 2).localeCompare(bill.LegislationNumber.substr(0, 2)) === 0 && i.LegislationKey > bill.LegislationKey));
                        if (nextHighestActiveLegislationNumber) {
                            const sequence = nextHighestActiveLegislationNumber.Sequence
                            category.DocketItems.filter(i => i.IsActive && i.Sequence >= nextHighestActiveLegislationNumber.Sequence).forEach(i => i.Sequence++)
                            bill.Sequence = sequence;
                        } else {
                            const currentSequences = category.DocketItems.filter(i => i.Sequence).map(i => { return i.Sequence; });
                            if (currentSequences.length) {
                                bill.Sequence = (Math.max.apply(Math, currentSequences) + 1) || 1;
                            } else {
                                bill.Sequence = 1;
                            }
                        }
                    } else {
                        bill.Sequence = null;
                        let sequence = 1;
                        for (let i = 0; i < category.DocketItems.length; i++) {
                            if (category.DocketItems[i].IsActive === true) {
                                category.DocketItems[i].Sequence = sequence;
                                sequence = sequence + 1;
                            }
                        }
                    }
                }
            });

            //Must resort on the Sequence to prevent the index-based reordering in onDragEnd from breaking after bills are included or unincluded
            category.DocketItems.sort((a, b) => (a.Sequence != null ? a.Sequence : Infinity) - (b.Sequence != null ? b.Sequence : Infinity))
        });

        this.props.updateDocket(categories);
    }

    onDragEnd(result, categorySequence) {
        // dropped outside the list
        if (!result.destination) {
            return;
        }
        // Only active bills can be dragged
        let categories = [...this.props.categories];
        const catIndex = categories.findIndex(category => category.Sequence === categorySequence);
        let items = categories[catIndex].DocketItems;
        items.sort((a, b) => (a.Sequence != null ? a.Sequence : Infinity) - (b.Sequence != null ? b.Sequence : Infinity))
        let [removed] = items.splice(result.source.index, 1);
        items.splice(result.destination.index, 0, removed);

        // Reorder the Sequence value too because the sequence value is used to show what order the preview is in
        items.forEach(function (element, index) {
            if (element.IsActive) {
                element.Sequence = index + 1;
            }
        });

        //Send the new values up to the parent to be set in the parent's state
        this.props.updateDocket(categories);
    }

    toggleEdit(categorySequence, legislationID) {
        let categories = [...this.props.categories];
        const catIndex = categories.findIndex(category => category.Sequence === categorySequence);
        let items = categories[catIndex].DocketItems;
        const itemIndex = items.findIndex(bill => bill.LegislationID === legislationID);
        //This key is used to tell the DOM whether to display an input or a span
        items[itemIndex].isEditing = !items[itemIndex].isEditing;
        //Create a key called newDescription that will hold the text that  the user types in
        items[itemIndex].newDescription = items[itemIndex].Description;
        this.props.updateDocket(categories);
    }

    handleChange(categorySequence, legislationID, e) {
        let categories = [...this.props.categories];
        const catIndex = categories.findIndex(category => category.Sequence === categorySequence);
        let items = categories[catIndex].DocketItems;
        const itemIndex = items.findIndex(bill => bill.LegislationID === legislationID);
        items[itemIndex].newDescription = e.target.value;
        this.props.updateDocket(categories);
    }

    saveChange(categorySequence, legislationID) {
        //Turn input back into span
        let categories = [...this.props.categories];
        const catIndex = categories.findIndex(category => category.Sequence === categorySequence);
        let items = categories[catIndex].DocketItems;
        const itemIndex = items.findIndex(bill => bill.LegislationID === legislationID);
        items[itemIndex].isEditing = false;
        // since the user saved changes they probably want to add it to the docket
        items[itemIndex].IsActive = true;
        //Set description to the text that the user has typed in. If new description is null then set it to the current description
        items[itemIndex].Description = items[itemIndex].newDescription;
        this.props.updateDocket(categories);
    }

    toggleAuxBillAdd() {
        this.setState({
            showAuxBillForm: !this.state.showAuxBillForm,
            auxBill: ''
        })
    }

    setAuxBill(value) {
        this.setState({
            auxBill: value
        })
    }

    addAuxBill() {
        this.setState({
            savingAuxBill: true
        }, () => {
            this.props.getBill(this.state.auxBill.LegislationID, this.state.auxBill.LegislationKey);

            this.setState({
                savingAuxBill: false,
                auxBill: '',
                showAuxBillForm: false
            })
        })
    }

    render() {
        const notesCategories = this.props.categories.filter(category => !category.IsLegislationCategory);
        const categories = this.props.categories.filter(category => category.IsLegislationCategory);
        const docketBills = categories.flatMap(c => c.DocketItems.map(d => d.LegislationNumber));
        return (
            <div className="drag-container">
                <div className="inner-grid three-and-two" style={{ gap: '50px' }}>
                    <div>
                        <button type="button" className="button m-right" onClick={() => this.toggleBillBulk(false)} >Remove from Docket</button>
                        <button type="button" className="button m-right" onClick={() => this.toggleBillBulk(true)} >Add to Docket</button>
                        <div style={{ display: 'inline-block' }}>
                            <label htmlFor="allActive">Select All Active</label>
                            <input type="checkbox" name="allActive" checked={this.props.selectAllActive} onChange={() => this.props.toggleAllActive(true)} />
                            <label htmlFor="allInActive">Select All Inactive</label>
                            <input type="checkbox" name="allInActive" checked={this.props.selectAllInactive} onChange={() => this.props.toggleAllActive(false)} />
                        </div>
                    </div>
                    {this.state.showAuxBillForm
                        ?
                        <div style={{ display: "grid", gridTemplateRows: "1fr 1fr", marginTop: "6px" }}>
                            <div className="inner-grid" style={{ gridTemplateColumns: "25px 1fr" }}>
                                <a className="icon delete" onClick={this.toggleAuxBillAdd} />
                                <span style={{ fontSize: "14px" }}>This form may be used to add any bill in the session to this docket. Use with caution.</span>
                            </div>
                            <div className="inner-grid" style={{ gridTemplateColumns: "1fr 150px" }}>
                                <Select
                                    options={this.props.sessionBills.filter(bill => !docketBills.includes(bill.LegislationNumber))}
                                    onChange={this.setAuxBill}
                                    isSearchable={true}
                                />
                                <button type="button" className="button" onClick={this.addAuxBill} disabled={this.state.savingAuxBill}>Add Bill to List</button>
                            </div>
                        </div>
                        :
                        <a className="icon warning" onClick={this.toggleAuxBillAdd} style={{ marginTop: "6px" }} />
                    }
                </div>
                {notesCategories.map((category, categoryIndex) => {
                    const dataActive = category.DocketItems.filter(note => note.IsActive);
                    const dataInactive = category.DocketItems.filter(note => !note.IsActive);
                    return (<DragDropContext key={categoryIndex} onDragEnd={(result) => this.onDragEnd(result, category.Sequence)}>
                        <Droppable droppableId="droppable1">
                            {(provided, snapshot) => (
                                <table
                                    className="dnd-grid-table margin dockets-agenda-notes-grid"
                                    ref={provided.innerRef}

                                >
                                    <thead className="dnd-grid-header" style={getListStyle(snapshot.isDraggingOver)}>
                                        <tr>
                                            <th scope="col" id={'-grabber-column'}></th>
                                            <th scope="col" id={'-display-column'}></th>
                                            <th scope="col" id={'-sequence-column'}>
                                            </th>
                                            <th scope="col" id={'-bill-column'}>Notes</th>
                                            <th scope="col">Status</th>
                                            <th scope="col" id={'-actions-column'}>Action</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {dataActive.map((item, index) => (
                                            <Draggable key={index} draggableId={"notes" + index} index={index}>
                                                {(provided, snapshot) => (
                                                    <tr
                                                        ref={provided.innerRef}
                                                        {...provided.draggableProps}
                                                        {...provided.dragHandleProps}
                                                        className={item.IsActive ? 'selected' : 'not-selected'}
                                                        style={getItemStyle(
                                                            snapshot.isDragging,
                                                            provided.draggableProps.style
                                                        )}>
                                                        <td><button id={item.LegislationNumber + '-grabber-button'} className="button draggable">Drag and drop</button></td>
                                                        <td>{index + 1}</td>
                                                        <td>
                                                            <input
                                                                type="checkbox"
                                                                className="checkbox"
                                                                checked={item.checked || ''}
                                                                onChange={(e) => this.toggleRow(item.LegislationID, category.Sequence, e)} />
                                                        </td>
                                                        <td>
                                                            {item.isEditing ?
                                                                <div style={{ width: '100%' }}>
                                                                    <div className="bill-grid-actions">
                                                                        <button onClick={() => this.saveChange(category.Sequence, item.LegislationID)} type="button" className="icon save float-right"></button>
                                                                        <button onClick={() => this.toggleEdit(category.Sequence, item.LegislationID)} type="button" className="icon delete float-right"></button>
                                                                    </div>
                                                                    <div>
                                                                        <textarea value={item.newDescription || item.Description} style={{ resize: 'vertical', width: '75%' }} cols="2" onChange={(e) => this.handleChange(category.Sequence, item.LegislationID, e)}></textarea>
                                                                    </div>
                                                                </div>
                                                                :
                                                                <div style={{ width: '100%' }}>
                                                                    <span>{item.Description || item.LegislationDescription}</span>
                                                                    <button onClick={() => this.toggleEdit(category.Sequence, item.LegislationID)} type="button" className="icon edit float-right"></button>
                                                                </div>
                                                            }
                                                        </td>
                                                        <td>
                                                            <span className="message-success">Included</span>
                                                        </td>
                                                        <td>
                                                            <div onClick={() => this.toggleBill(item.LegislationID, category.Sequence)} className="toggle-switch">
                                                                <input id="sr-status-change" checked={item.IsActive} type="checkbox" />
                                                                <span className="slider"></span>
                                                            </div>
                                                        </td>
                                                    </tr>
                                                )}
                                            </Draggable>
                                        ))}
                                        {provided.placeholder}
                                        {dataInactive.map((item, index) => (
                                            <tr key={index} className="tr-inactive">
                                                <td></td>
                                                <td>{/* index + 1 */}</td>
                                                <td>
                                                    <input
                                                        type="checkbox"
                                                        className="checkbox"
                                                        checked={item.checked || ''}
                                                        onChange={(e) => this.toggleRow(item.LegislationID, category.Sequence, e)} />
                                                </td>
                                                <td>
                                                    {item.isEditing ?
                                                        <div style={{ width: '100%' }}>
                                                            <div className="bill-grid-actions">
                                                                <button onClick={() => this.saveChange(category.Sequence, item.LegislationID)} type="button" className="icon save float-right"></button>
                                                                <button onClick={() => this.toggleEdit(category.Sequence, item.LegislationID)} type="button" className="icon delete float-right"></button>
                                                            </div>
                                                            <div>
                                                                <textarea value={item.newDescription || item.Description} style={{ resize: 'vertical', width: '75%' }} cols="2" onChange={(e) => this.handleChange(category.Sequence, item.LegislationID, e)}></textarea>
                                                            </div>
                                                        </div>
                                                        :
                                                        <div style={{ width: '100%' }}>
                                                            <span>{item.Description || item.LegislationDescription}</span>
                                                            <button onClick={() => this.toggleEdit(category.Sequence, item.LegislationID)} type="button" className="icon edit float-right"></button>
                                                        </div>
                                                    }
                                                </td>
                                                <td>
                                                    <span>Not Included</span>
                                                </td>
                                                <td>
                                                    <div onClick={() => this.toggleBill(item.LegislationID, category.Sequence)} className="toggle-switch">
                                                        <input id="sr-status-change" checked={item.IsActive} type="checkbox" />
                                                        <span className="slider"></span>
                                                    </div>
                                                </td>
                                            </tr>
                                        ))}
                                    </tbody>
                                </table>
                            )}
                        </Droppable>
                    </DragDropContext>)
                })}

                {categories.map((category, categoryIndex) => {
                    const dataActive = category.DocketItems.filter(bill => bill.IsActive);
                    const dataInactive = category.DocketItems.filter(bill => !bill.IsActive);
                    return (<DragDropContext key={categoryIndex} onDragEnd={(result) => this.onDragEnd(result, category.Sequence)}>
                        <Droppable droppableId="droppable">
                            {(provided, snapshot) => (
                                <table
                                    className="dnd-grid-table margin dockets-agenda-grid"
                                    ref={provided.innerRef}

                                >
                                    <thead className="dnd-grid-header" style={getListStyle(snapshot.isDraggingOver)}>
                                        <tr>
                                            <th scope="col" id={'-grabber-column'}></th>
                                            <th scope="col" id={'-display-column'}></th>
                                            <th scope="col" id={'-sequence-column'}>
                                            </th>
                                            <th scope="col" id={'-bill-column'}>Bill #</th>
                                            <th scope="col" id={'-patron-column'}>Patron</th>
                                            <th scope="col" id={'-caption-column'}>Caption</th>
                                            <th scope="col">Status</th>
                                            <th scope="col" id={'-actions-column'}>Action</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {dataActive.map((item, index) => (
                                            <Draggable key={index} draggableId={"bills" + index} index={index}>
                                                {(provided, snapshot) => (
                                                    <tr
                                                        ref={provided.innerRef}
                                                        {...provided.draggableProps}
                                                        {...provided.dragHandleProps}
                                                        className={item.IsActive ? 'selected' : 'not-selected'}
                                                        style={getItemStyle(
                                                            snapshot.isDragging,
                                                            provided.draggableProps.style
                                                        )}>
                                                        <td><button id={item.LegislationNumber + '-grabber-button'} className="button draggable">Drag and drop</button></td>
                                                        <td>{index + 1}</td>
                                                        <td>
                                                            <input
                                                                type="checkbox"
                                                                className="checkbox"
                                                                checked={item.checked || ''}
                                                                onChange={(e) => this.toggleRow(item.LegislationID, category.Sequence, e)} />
                                                        </td>
                                                        <td>
                                                            <Link to={`/bill-details/${this.props.sessionCode}/${item.LegislationNumber}`} target="_blank" rel="noreferrer">{item.LegislationNumber}</Link>
                                                        </td>
                                                        <td>
                                                            {item.Patrons[0] && item.Patrons[0].PatronDisplayName}
                                                        </td>
                                                        <td>
                                                            {item.LegislationDescription}
                                                        </td>
                                                        <td>
                                                            <span className="message-success">Included</span>
                                                        </td>
                                                        <td>
                                                            <div onClick={() => this.toggleBill(item.LegislationID, category.Sequence)} className="toggle-switch">
                                                                <input id="sr-status-change" checked={item.IsActive} type="checkbox" />
                                                                <span className="slider"></span>
                                                            </div>
                                                        </td>
                                                    </tr>
                                                )}
                                            </Draggable>
                                        ))}
                                        {provided.placeholder}
                                        {dataInactive.map((item, index) => (
                                            <tr key={index} className="tr-inactive">
                                                <td></td>
                                                <td>{/* index + 1 */}</td>
                                                <td>
                                                    <input
                                                        type="checkbox"
                                                        className="checkbox"
                                                        checked={item.checked || ''}
                                                        onChange={(e) => this.toggleRow(item.LegislationID, category.Sequence, e)} />
                                                </td>
                                                <td className="new-container">
                                                    <Link to={`/bill-details/${this.props.sessionCode}/${item.LegislationNumber}`} target="_blank" rel="noreferrer" style={{ color: 'inherit' }}>{item.LegislationNumber}</Link>
                                                    {!item.DocketItemID && !item.HouseAgendaItemID &&
                                                        <span className="corner-new">NEW</span>
                                                    }
                                                </td>
                                                <td>
                                                    {item.Patrons[0] && item.Patrons[0].PatronDisplayName}
                                                </td>
                                                <td>
                                                    {item.LegislationDescription}
                                                </td>
                                                <td>
                                                    <span>Not Included</span>
                                                </td>
                                                <td>
                                                    <div onClick={() => this.toggleBill(item.LegislationID, category.Sequence)} className="toggle-switch">
                                                        <input id="sr-status-change" checked={item.IsActive} type="checkbox" />
                                                        <span className="slider"></span>
                                                    </div>
                                                </td>
                                            </tr>
                                        ))}
                                    </tbody>
                                </table>
                            )}
                        </Droppable>
                    </DragDropContext>)
                })}
            </div>
        );
    }
}

export default CommitteeBillsGrid;