import React from 'react';
import { Route } from 'react-router';
import CommunicationsManagementGrid from './lis-communications-grid';
import CommunicationsForm from './lis-communications-form'

class CommunicationsManagement extends React.Component {

    render() {

        return (
            <div>
                <Route exact path={this.props.match.path} component={CommunicationsManagementGrid} />
                <Route path={`${this.props.match.path}/edit-communication/:communicationid`} component={CommunicationsForm} />
            </div>
        )
    }
}

export default CommunicationsManagement;