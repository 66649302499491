import tinymce from 'tinymce/tinymce';

tinymce.PluginManager.add('copyheadstyle', function (editor) {
    // Since TinyMCE is in an iframe the <style> tags and <link> tags from the root document need to be copied into the iframe
    editor.on('init', _e => {
        const styleTags = document.head.querySelectorAll('style');
        styleTags.forEach(element => {
            const newEl = element.cloneNode(true);
            editor.contentDocument.head.appendChild(newEl);
        });
        const linkTags = document.head.querySelectorAll('link');
        linkTags.forEach(element => {
            if(element.attributes.rel && element.attributes.rel.nodeValue === "stylesheet") {
                const newEl = element.cloneNode(true);
                editor.contentDocument.head.appendChild(newEl);
            }
        });

        let addedStyles = document.createElement('style');
        //This is a fix for firefox since the body element's height is 100% be default
        addedStyles.innerHTML += 'body {height: auto;}';
        //They do not want links to be seen in the editor.
        addedStyles.innerHTML += "a, a:link, a:hover, a:link:hover {color: #333333; text-decoration: none;}"
        editor.contentDocument.head.appendChild(addedStyles);
    });
    
});