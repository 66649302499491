import React from 'react';
import { Prompt } from 'react-router';
import ReactToPrint from 'react-to-print';
import * as Fields from '../../../lis-shared/lis-layout/components/lis-forms-component';
import DatePicker from 'react-datepicker';
import PersonnelDraggableComponent from './lis-committee-personnel-dnd';
import MembersDraggableComponent from './lis-committee-members-dnd';
import SubcommitteeManagementGrid from './lis-subcommittee-grid';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { committeeActionCreators } from '../../../stores/lis-committee-store';
import { personnelActionCreators } from '../../../stores/lis-personnel-store';
import { memberActionCreators } from '../../../stores/lis-members-store';
import { navActionCreators } from '../../../stores/lis-nav-store';
import CommitteeDetailsComponent from './lis-partner-committee-details';
import { sessionActionCreators } from '../../../stores/lis-session-store';
import { cancelRequest } from '../../../services/request.service';
import * as moment from 'moment';

class NewCommitteeFormComponent extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            infoFieldsetIsActive: true, // SET FIELDSET SECTIONS TO BE VISIBLE ON LOAD (OR NOT)
            clerkFieldsetIsActive: true,
            staffFieldsetIsActive: true,
            membershipFieldsetIsActive: true,
            subcommFieldsetIsActive: true,
            isSaving: false,
            selectedCommittee: {},
            members: [],
            clerks: [],
            staff: [],
            personnelReferences: [],
            committeeMemberRoles: [],
            committeeMemberNames: [],
            subcommitteeIsLoaded: false,
            subcommitteeData: [],
            showPreview: false,
            ascending: false,
            isFormDirty: false
        };
        this.createSubcommittee = this.createSubcommittee.bind(this);
        this.togglePreview = this.togglePreview.bind(this);
        this.getSubcommittees = this.getSubcommittees.bind(this);
        this.handleSave = this.handleSave.bind(this);
        this.handleMemberChange = this.handleMemberChange.bind(this);
        this.handleClerkChange = this.handleClerkChange.bind(this);
        this.handleStaffChange = this.handleStaffChange.bind(this);
        this.handleDateChange = this.handleDateChange.bind(this);
        this.sortByVotingSequence = this.sortByVotingSequence.bind(this);
        this.handleClose = this.handleClose.bind(this);
    }

    togglePreview() {
        this.setState(state => ({
            showPreview: !state.showPreview
        }));
    }

    // TOGGLE FIELDSET VISIBILITY
    toggleFieldsetCollapse(isActive) {
        this.setState(state => ({
            [isActive]: !state[isActive]
        }));
    };

    // CALLBACK FOR CREATING NEW SUBCOMMITTEE
    createSubcommittee(params) {
        params = {
            ...params,
            ChamberCode: this.state.selectedCommittee.ChamberCode,
            ParentCommitteeID: this.props.match.params.committeeid,
            IsPublic: 0
        };
        this.props.actions.createNewCommittee(params).then(() => {
            this.setState({
                showCreateSuccess: true
            });
            setTimeout(function () {
                this.setState({ showCreateSuccess: false });
            }.bind(this), 5000);
            this.getSubcommittees();
        }).catch(err => {
            if (err === 'Aborted') {
                return;
            }
            this.setState({ showCreateFailure: true });
            setTimeout(function () {
                this.setState({ showCreateFailure: false });
            }.bind(this), 5000);
        })
    }

    getSubcommittees() {
        if (!this.props.match.params.subcommitteeid) {
            var params = 'parentCommitteeID=' + this.props.match.params.committeeid + '&isPublic=false';
            this.props.actions.getPartnerCommitteeList(params).then(() => {
                this.setState({
                    subcommitteeData: this.props.committee.partnerCommitteeList,
                    subcommitteeMessage: this.props.committee.partnerCommitteeListError,
                    subcommitteeIsLoaded: true
                })
            });
        }

    }

    handleMemberChange(members) {
        this.setState({
            members: members,
            isFormDirty: true
        })
    }

    handleClerkChange(clerks) {
        this.setState({
            clerks: clerks,
            isFormDirty: true
        })
    }

    handleStaffChange(staff) {
        this.setState({
            staff: staff,
            isFormDirty: true
        })
    }

    handleDateChange(val, serviceEndDate) {
        let selectedCommittee = { ...this.state.selectedCommittee };
        if (serviceEndDate) {
            selectedCommittee.ServiceEndDate = moment(val);
        } else {
            selectedCommittee.ServiceBeginDate = moment(val);
        }
        this.setState({
            selectedCommittee: selectedCommittee,
            isFormDirty: true
        });
    }

    handleChange(key, value) {
        if (this.state.selectedCommittee[key] !== undefined || key === 'AgendaURL') {
            let selectedCommittee = { ...this.state.selectedCommittee };
            selectedCommittee[key] = value;
            this.setState({
                selectedCommittee: selectedCommittee,
                isFormDirty: true
            });
        }
    }

    handleSave(publish) {
        //Turn off save/publish button
        this.setState({ isSaving: true });
        //Check to see that the committee has a name.
        if (!this.state.selectedCommittee.Name) {
            this.setState({
                committeeNameMissing: true
            });
            return;
        }
        let members = [...this.state.members];
        //Combine the personnel list back together so we do not have to make an extra request
        let personnelList = [...this.state.clerks, ...this.state.staff];
        let selectedCommittee = { ...this.state.selectedCommittee };
        personnelList.forEach(personnel => personnel.AffiliationID = selectedCommittee.CommitteeID);
        selectedCommittee.IsPublic = publish;
        if (publish) {
            members.forEach(member => { member.IsPublic = true })
            personnelList.forEach(personnel => { personnel.IsPublic = true })
        }
        const memberObject = { MemberList: members }
        const personnelObject = { Personnels: personnelList };
        //Save committee details
        const saveCommittee = this.props.actions.saveCommittee(selectedCommittee)
            .then(() => {
                if (this.props.committee.saveCommitteeError) {
                    return Promise.reject(this.props.committee.saveCommitteeError);
                } else {
                    const commData = this.props.committee.savedCommittee
                    this.setState({
                        selectedCommittee: {
                            ...commData,
                            TwitterHandle: commData.TwitterHandle ? commData.TwitterHandle : '',
                            TwitterEmail: commData.TwitterEmail ? commData.TwitterEmail : '',
                            Description: commData.Description ? commData.Description : '',
                            MeetingNote: commData.MeetingNote ? commData.MeetingNote : '',
                            Abbreviation: commData.Abbreviation ? commData.Abbreviation : '',
                            ServiceEndDate: commData.ServiceEndDate ? commData.ServiceEndDate : null
                        }
                    });
                    return Promise.resolve();
                }
            });
        //Save personnel to committee
        const savePersonnel = personnelObject.Personnels.length > 0 ? this.props.actions.savePersonnel(personnelObject)
            .then(() => {
                if (this.props.personnel.personnelErrorSave) {
                    return Promise.reject(this.props.personnel.personnelErrorSave);
                } else {
                    this.setState({
                        clerks: this.props.personnel.personnelClerkSave,
                        staff: this.props.personnel.personnelStaffSave
                    });
                    return Promise.resolve();
                }
            })
            : Promise.resolve(true);
        //Save members to committee
        const saveMember = memberObject.MemberList.length > 0 ? this.props.actions.saveMemberCommittee(memberObject)
            .then(() => {
                if (this.props.members.memberCommitteeSaveError) {
                    return Promise.reject(this.props.members.memberCommitteeSaveError);
                } else {
                    this.setState({
                        members: this.props.members.memberCommitteeSave
                    });
                    return Promise.resolve();
                }
            })
            : Promise.resolve(true);

        //Save the data then grab the new list of personnel and members and update the view. This is done because if the user wants to save again they need updated mod dates
        Promise.all([
            saveMember,
            savePersonnel,
            saveCommittee
        ])
            //Will only run if the 3 promises above resolve
            .then(() => {
                this.props.actions.makeToast([{ message: "Committee Saved", type: "success" }])
                this.setState({ isSaving: false, saved: true, isFormDirty: false }, () => {
                    if (publish === true) {
                        this.props.history.push("/committee-management");
                    }
                });
            })
            .catch(err => {
                if (err === 'Aborted') {
                    return;
                }
                //Part or all of the save has failed so send a mean red bar to the user
                this.props.actions.makeToast([{ message: "Committee Save Failed", type: "failure" }])
                this.setState({ isSaving: false });
            });
    }

    sortByVotingSequence() {
        let members = [...this.state.members];
        if (this.state.ascending) {
            members.sort((a, b) => b.VotingSequence - a.VotingSequence);
        } else {
            members.sort((a, b) => a.VotingSequence - b.VotingSequence);
        }

        this.setState({
            members: members,
            ascending: !this.state.ascending
        })
    }

    handleClose() {
        this.props.history.goBack();
    }

    componentDidMount() {
        const committeeId = this.props.match.params.subcommitteeid ? this.props.match.params.subcommitteeid : this.props.match.params.committeeid
        const committeeParams = committeeId + '?isPublic=0';
        this.props.actions.getPartnerCommitteeById(committeeParams)
            .then(() => {
                // Checking to see if keys exist and setting initial value if not
                // otherwise react throws an error in the form fields
                const commData = this.props.committee.partnerCommitteeByIdData
                this.setState({
                    selectedCommittee: {
                        ...this.props.committee.partnerCommitteeByIdData,
                        TwitterHandle: commData.TwitterHandle ? commData.TwitterHandle : '',
                        TwitterEmail: commData.TwitterEmail ? commData.TwitterEmail : '',
                        Description: commData.Description ? commData.Description : '',
                        MeetingNote: commData.MeetingNote ? commData.MeetingNote : '',
                        Abbreviation: commData.Abbreviation ? commData.Abbreviation : '',
                        ServiceEndDate: commData.ServiceEndDate ? commData.ServiceEndDate : null
                    },
                    committeeLoaded: true
                })
                return commData
            })
            .then((commData) => {
                // GET MEMBER ROLES FOR THE MEMBERSHIP DROPDOWN
                this.props.actions.getCommitteeMemberRoles().then(() => {
                    this.setState({
                        committeeMemberRoles: commData.ChamberCode === 'H' ? this.props.members.committeeHouseRoles : this.props.members.committeeSenateRoles
                    })
                })
                const chamberCode = 'chamberCode=' + commData.ChamberCode;

                //Get members list and set it to the committeeMemberNames key
                const organizeMembers = members => {
                    if (members) {
                        members.forEach(member => {
                            member.label = member.MemberDisplayName;
                            member.value = member.MemberID;
                        });
                        this.setState({
                            committeeMemberNames: members
                        })
                    }
                }

                //Get the session list so the default session ID can be found
                this.props.actions.getSessionList()
                    .then(() => {
                        let defaultSession = null;
                        this.props.session.sessionList.forEach(session => {
                            if (session.IsDefault) {
                                defaultSession = '&sessionID=' + session.SessionID;
                            }
                        });
                        //Get list of current members of committee
                        this.props.actions.getMembersByCommittee(`isPublic=false&CommitteeID=${committeeId}${defaultSession}`, true)
                            .then(() => {
                                // Set the DisplaySequence to the correct order. It should be linear.
                                let members = [...this.props.members.membersByCommittee];
                                members.forEach((member, index) => member.DisplaySequence = index + 1)
                                this.setState({
                                    members: members,
                                    membersLoaded: true
                                });
                            });
                        if (this.props.match.params.subcommitteeid) {
                            //Since this is a subcommittee get the name of the parent committee and put it in the form
                            this.props.actions.getPartnerCommitteeById(`${this.props.match.params.committeeid}?IsPublic=0`).then(() => {
                                const parentCommData = this.props.committee.partnerCommitteeByIdData;
                                this.setState({
                                    parentCommitteeName: parentCommData.Name
                                });
                            });
                            //Also only include the list of members in the parent committee for the member list
                            this.props.actions.getMembersByCommittee(`isPublic=false&CommitteeID=${this.props.match.params.committeeid}${defaultSession}`, true)
                                .then(() => organizeMembers(this.props.members.membersByCommittee));
                        } else {
                            //If this is not a subcommittee then get the full member list
                            this.props.actions.getMemberList(chamberCode + defaultSession)
                                .then(() => organizeMembers(this.props.members.memberList));
                        }
                    })


                // Get personnel list for staff and clerk dropdowns
                this.props.actions.getPersonnelReferences('')
                    .then(() => {
                        const filteredStaffAndClerkReferences = this.props.personnel.personnelStaffReferences.concat(this.props.personnel.personnelClerkReferences).filter(personnel => personnel.OrganizationName && personnel.OrganizationName.toLowerCase() === (chamberCode === "H" ? "house" : "senate"))
                        this.setState({
                            personnelReferences: filteredStaffAndClerkReferences
                        });
                    });


            });

        // GET STAFF AND CLERK PERSONNEL BY COMMITTEE ID
        const personnelParams = 'affiliationID=' + committeeId + '&isPublic=false';
        this.props.actions.getPersonnelList(personnelParams)
            .then(() => {
                // Set the Sequence to the correct order. It should be linear.
                let clerks = [];
                let staff = [];
                this.props.personnel.personnelClerkList.forEach((clerk, index) => clerks.push({ ...clerk, Sequence: index + 1 }));
                this.props.personnel.personnelStaffList.forEach((staf, index) => staff.push({ ...staf, Sequence: index + 1 }));
                this.setState({
                    clerks: clerks,
                    staff: staff,
                    personnelLoaded: true
                })
            });
        this.getSubcommittees();
    }

    componentWillUnmount() {
        cancelRequest();
    }

    render() {
        const {
            Abbreviation,
            ChamberCode,
            CommitteeID,
            CommitteeNumber,
            Description,
            ServiceBeginDate,
            ServiceEndDate,
            MeetingNote,
            Name,
            TwitterHandle,
            TwitterEmail,
            CommitteeFiles,
            AgendaURL
        } = this.state.selectedCommittee;

        const { isSaving, committeeLoaded, personnelLoaded, membersLoaded, saved, isFormDirty } = this.state;

        let filterStartDate = (date) => {
            let result = true;            
            if (moment(ServiceEndDate).isBefore(date, "days")) {
                result = false;
            }
            return result;
        }

        let filterEndDate = (date) => {
            let result = true;
            if (date >= new Date()) {
                result = false;
            }
            if (moment(ServiceBeginDate).isAfter(date, "days")) {
                result = false;
            }
            return result;
        }

        if (!committeeLoaded || !personnelLoaded || !membersLoaded) {
            return (<div>
                <div className="center-spinner spinner"></div>
            </div>)
        }
        if (this.state.showPreview) {
            return (
                <div>
                    <div className="flex-row">
                        <h2>Committee Management Review</h2>
                        <ReactToPrint
                            trigger={() => <a type="button" className="button print"> Print</a>}
                            content={() => this.componentRef}
                            pageStyle={"break-inside: avoid"}
                        />
                    </div>
                    <div ref={el => (this.componentRef = el)}>
                        <CommitteeDetailsComponent
                            committeeData={this.state.selectedCommittee}
                            clerkList={this.state.clerks}
                            staffList={this.state.staff}
                            memberList={this.state.members}
                            memberRoles={this.state.committeeMemberRoles}
                            subcommitteesList={this.state.subcommitteeData}
                        />
                    </div>
                    <div className="button-bar">
                        <div></div>
                        <div className="align-right">
                            <button onClick={this.togglePreview} className="button secondary">Edit</button>
                            <button
                                onClick={() => this.handleSave(true)}
                                disabled={isSaving || !saved}
                                className="button">Publish
                            </button>
                        </div>
                    </div>
                </div>)
        } else {
            return (
                <React.Fragment>
                    <div>
                        <Prompt
                            when={isFormDirty === true}
                            message={`You have unsaved changes. Are you sure you would like to leave?`}
                        />
                    </div>
                    <h1>{this.props.match.params.subcommitteeid ? 'Edit Subcommittee' : 'Edit Committee'} </h1>
                    <div className="user-forms" style={{ paddingBottom: this.props.match.params.subcommitteeid ? "150px" : "75px" }}>
                        <div className="dlas-forms">
                            <form>
                                <fieldset className={this.state.infoFieldsetIsActive ? 'fieldset-collapse fieldset-open' : 'fieldset-collapse fieldset-closed'} >
                                    <legend onClick={this.toggleFieldsetCollapse.bind(this, 'infoFieldsetIsActive')}>{!this.props.match.params.subcommitteeid ? 'Committee Information' : 'Subcommittee Information'}</legend>
                                    <div className={!this.props.match.params.subcommitteeid ? 'multi-row sixth-40-1 no-margin' : 'multi-row sixth-20-1 no-margin'}>
                                        {this.props.match.params.subcommitteeid &&
                                            <div>
                                                <Fields.TextInput
                                                    id="Name"
                                                    label="SubCommittee Name"
                                                    type="text"
                                                    placeholder="SubCommittee Name"
                                                    value={Name}
                                                    onChange={(val) => this.handleChange("Name", val.target.value)}
                                                    onBlur={(val) => {
                                                        if (!val.target.value) {
                                                            this.setState({ committeeNameMissing: true });
                                                        } else {
                                                            this.setState({ committeeNameMissing: false });
                                                        }
                                                    }}
                                                />
                                                {this.state.committeeNameMissing && <div className="input-feedback">Committee Name is required</div>}
                                            </div>
                                        }
                                        <div>
                                            <Fields.TextInput
                                                id="Name"
                                                label="Committee Name"
                                                type="text"
                                                placeholder="Committee Name"
                                                value={this.props.match.params.subcommitteeid ? this.state.parentCommitteeName : Name}
                                                onChange={(val) => this.handleChange("Name", val.target.value)}
                                                disabled={this.props.match.params.subcommitteeid}
                                                onBlur={(val) => {
                                                    if (!val.target.value) {
                                                        this.setState({ committeeNameMissing: true });
                                                    } else {
                                                        this.setState({ committeeNameMissing: false });
                                                    }
                                                }}
                                            />
                                            {this.state.committeeNameMissing && !this.props.match.params.subcommitteeid && <div className="input-feedback">Committee Name is required</div>}
                                        </div>

                                        <div>
                                            <Fields.TextInput
                                                id="Abbreviation"
                                                label="Abbreviation"
                                                type="text"
                                                placeholder="ABRV"
                                                value={Abbreviation}
                                                onChange={(val) => this.handleChange("Abbreviation", val.target.value)}
                                            />
                                        </div>
                                        <div>
                                            <label>Committee #</label>
                                            <input type="text" id="CommitteeNumber" value={CommitteeNumber} disabled />
                                        </div>
                                        <div>
                                            <label>Chamber</label>
                                            <input type="text" id="ChamberCode" value={ChamberCode} disabled />
                                        </div>
                                        <div>
                                            <label>Service Start Date</label>
                                            <DatePicker
                                                selected={ServiceBeginDate ? moment(ServiceBeginDate) : null}
                                                onChange={(val) => this.handleDateChange(val, false)}
                                                popperPlacement="top-start"
                                                placeholderText="Never"
                                                isClearable
                                                filterDate={filterStartDate}
                                            />
                                        </div>
                                        <div>
                                            <label>Service End Date</label>
                                            <DatePicker
                                                selected={ServiceEndDate ? moment(ServiceEndDate) : null}
                                                onChange={(val) => this.handleDateChange(val, true)}
                                                popperPlacement="top-start"
                                                placeholderText="Never"
                                                isClearable
                                                filterDate={filterEndDate}
                                            />
                                        </div>
                                    </div>
                                    <div className="single-row no-margin">
                                        <div>
                                            <Fields.TextInput
                                                id="MeetingNote"
                                                label="Meeting Notes"
                                                type="text"
                                                placeholder="Meeting Notes"
                                                value={MeetingNote}
                                                onChange={(val) => this.handleChange("MeetingNote", val.target.value)}
                                            />
                                        </div>
                                    </div>
                                        <div className="single-row no-margin">
                                            <div>
                                                <Fields.TextInput
                                                    id="AgendaURL"
                                                    label="Agenda URL"
                                                    type="text"
                                                    placeholder="Agenda URL"
                                                    value={AgendaURL}
                                                    onChange={(val) => this.handleChange("AgendaURL", val.target.value)}
                                                />
                                            </div>
                                        </div>
                                </fieldset>
                                <fieldset className={this.state.clerkFieldsetIsActive ? 'fieldset-collapse fieldset-open' : 'fieldset-collapse fieldset-closed'}>
                                    <legend onClick={this.toggleFieldsetCollapse.bind(this, 'clerkFieldsetIsActive')}>{!this.props.match.params.subcommitteeid ? 'Committee Clerk' : 'Subcommittee Clerk'}</legend>
                                    <div className="draggable-grid comm-mgmt-clerk">
                                        <div>
                                            <PersonnelDraggableComponent
                                                onPersonnelChange={this.handleClerkChange}
                                                items={this.state.clerks}
                                                name="ClerkGrid"
                                                affiliation={this.props.match.params.subcommitteeid ? this.props.match.params.committeeid : this.props.match.params.committeeid}
                                                dropdownData={this.state.personnelReferences}
                                                staffRoleTypeID={1}
                                            />
                                        </div>
                                    </div>
                                </fieldset>
                                <fieldset className={this.state.staffFieldsetIsActive ? 'fieldset-collapse fieldset-open' : 'fieldset-collapse fieldset-closed'}>
                                    <legend onClick={this.toggleFieldsetCollapse.bind(this, 'staffFieldsetIsActive')}>{!this.props.match.params.subcommitteeid ? 'Committee Staff' : 'Subcommittee Staff'}</legend>
                                    <div className="draggable-grid comm-mgmt-staff">
                                        <div>
                                            <PersonnelDraggableComponent
                                                onPersonnelChange={this.handleStaffChange}
                                                items={this.state.staff}
                                                name="StaffGrid"
                                                affiliation={this.props.match.params.subcommitteeid ? this.props.match.params.subcommitteeid : this.props.match.params.committeeid}
                                                dropdownData={this.state.personnelReferences}
                                                staffRoleTypeID={2}
                                            />
                                        </div>
                                    </div>
                                </fieldset>
                                <fieldset className={this.state.membershipFieldsetIsActive ? 'fieldset-collapse fieldset-open' : 'fieldset-collapse fieldset-closed'}>
                                    <legend onClick={this.toggleFieldsetCollapse.bind(this, 'membershipFieldsetIsActive')}>{!this.props.match.params.subcommitteeid ? 'Committee Membership' : 'Subcommittee Membership'}</legend>
                                    <div className="draggable-grid comm-mgmt-staff">
                                        <div>
                                            <MembersDraggableComponent
                                                name="MembersGrid"
                                                affiliation={this.props.match.params.subcommitteeid ? this.props.match.params.subcommitteeid : this.props.match.params.committeeid}
                                                dropdownDataRoles={this.state.committeeMemberRoles}
                                                dropdownDataMembers={this.state.committeeMemberNames}
                                                onMemberChange={this.handleMemberChange}
                                                items={this.state.members}
                                                isSubcommittee={this.props.match.params.subcommitteeid ? true : false}
                                                sortByVotingSequence={this.sortByVotingSequence}
                                                ascending={this.state.ascending}
                                            />
                                        </div>
                                    </div>
                                </fieldset>
                                {!this.props.match.params.subcommitteeid &&
                                    <fieldset className={this.state.subcommFieldsetIsActive ? 'fieldset-collapse fieldset-open' : 'fieldset-collapse fieldset-closed'}>
                                        <legend onClick={this.toggleFieldsetCollapse.bind(this, 'subcommFieldsetIsActive')}>Subcommittee</legend>
                                        <SubcommitteeManagementGrid
                                            showCreateSuccess={this.state.showCreateSuccess}
                                            showCreateFailure={this.state.showCreateFailure}
                                            parentCommitteeID={CommitteeID}
                                            parentCommitteeNumber={CommitteeNumber}
                                            subcommitteeData={this.state.subcommitteeData}
                                            isLoaded={this.state.subcommitteeIsLoaded}
                                            subcommitteeMessage={this.state.subcommitteeMessage}
                                            createSubcommittee={this.createSubcommittee}
                                        />
                                    </fieldset>
                                }
                                <div className="button-bar floating-button-bar" style={{ paddingBottom: "15px", paddingRight: "15px" }}>
                                    <button className="button secondary" type="button" onClick={() => this.handleClose()} style={{ width: "100px" }}>Close</button>
                                    <div className="align-right">
                                        <button className="button" onClick={() => this.handleSave(false)} type="button" disabled={isSaving}>Save</button>
                                        <button className="button" type="button" disabled={isSaving || !saved} onClick={this.togglePreview}>Preview</button>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                </React.Fragment>
            );
        }
    }
};

export default connect(
    (state) => {
        const { session, committee, personnel, members, nav } = state;
        return {
            session,
            committee,
            personnel,
            members,
            nav
        }
    },
    (dispatch) => {
        return {
            actions: bindActionCreators(Object.assign({}, sessionActionCreators, committeeActionCreators, personnelActionCreators, memberActionCreators, navActionCreators), dispatch)
        }
    }
)(NewCommitteeFormComponent)








