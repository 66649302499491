import React from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { committeeActionCreators } from '../../../stores/lis-committee-store';
import { sessionActionCreators } from '../../../stores/lis-session-store';
import { navActionCreators } from '../../../stores/lis-nav-store';
import PublicCommitteeList from './lis-public-committee-list';
import PublicCommitteeDetails from './lis-public-committee-details';
import PublicMeetingDetails from './lis-public-meeting-details';
import PublicDocketDetails from './lis-public-docket-details';
import { Route } from 'react-router';
import { memberActionCreators } from '../../../stores/lis-members-store';

class CommitteeInformationComponent extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            slideDockets: false,
        }

        this.toggleShowData = this.toggleShowData.bind(this);
    }

    toggleShowData(slide) {
        this.setState(prevState => ({
            [slide]: !prevState[slide]
        }));
    }

    render() {
        return             <div>
        <Route exact path={this.props.match.path} render={props => <PublicCommitteeList {...props} />} />
        <Route path='/session-details/:sessioncode/committee-information/:committeenumber/committee-details' render={props => <PublicCommitteeDetails {...props} slideDockets={this.state.slideDockets} toggleShowData={this.toggleShowData} />} />
        <Route path='/session-details/:sessioncode/committee-information/:committeenumber/meetings/:meetingid' component={PublicMeetingDetails} />
        <Route path='/session-details/:sessioncode/committee-information/:committeenumber/dockets/:docketid' component={PublicDocketDetails} />
        <Route path='/session-details/:sessioncode/committee-information/:committeenumber/agendas/:docketid' component={PublicDocketDetails} />
    </div>

    }
}

export default connect(
    (state) => {
        const { committee, members, session, nav } = state;
        return {
            committee,
            members,
            session,
            nav
        }
    },
    (dispatch) => {
        return {
            actions: bindActionCreators(Object.assign({}, committeeActionCreators, memberActionCreators, sessionActionCreators, navActionCreators), dispatch)
        }
    }
)(CommitteeInformationComponent)

